import Loader from 'components/Loader';
import { getCities, getCountries, getRegions, getSubAreas } from 'containers/school/actions';
import { citiesSelector, countriesSelector, regionsSelector, subAreasSelector } from 'containers/school/selectors';
import { useFormik } from 'formik';
import { useRouter } from 'hooks/useRouter';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { SMKLinks } from 'utils/links';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
});

const UpdateHealthCareFacility = () => {
  const { id: facilityId } = useParams();

  const router = useRouter();
  const history = useHistory();
  const dispatch = useDispatch();
  const { countries, regions, cities, subAreas } = useSelector(stateSelector);
  toast.configure();

  const [facilityDetails, setfacilitysDetails] = useState({});

  const [getFacilityRequest, { loading: getFacilityLoading }] = useRequest({
    url: `/doctor/admin/healthcareFacility/${facilityId}`,
    method: 'GET',
    onSuccess: (data) => {
      setfacilitysDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addFacilityRequest, { loading: addFacilityLoading }] = useRequest({
    url: `/doctor/admin/healthcareFacility`,
    method: 'POST',
    onSuccess: (data) => {
      history.replace(SMKLinks.HEALTH_CARE_LISTING);
      toast.success('Success! Health care facility has been Added');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [updateFacilityRequest, { loading: updateFacilityLoading }] = useRequest({
    url: `/doctor/admin/healthcareFacility/${facilityId}`,
    method: 'PUT',
    onSuccess: (data) => {
      history.replace(SMKLinks.HEALTH_CARE_LISTING);
      toast.success('Success! Health care facility has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));
  }, []);

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      formik.setFieldValue('country', country);
      formik.setFieldValue('region', {});
      formik.setFieldValue('city', {});
      formik.setFieldValue('subArea', {});
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );

      formik.setFieldValue('region', region);
      formik.setFieldValue('city', {});
      formik.setFieldValue('subArea', {});
    }
  };
  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      dispatch(getSubAreas.trigger({ level: 4, city: city.name, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));

      formik.setFieldValue('city', city);
      formik.setFieldValue('subArea', {});
    }
  };
  const onSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      if (!subArea.id && !subArea.name) {
        subArea.name = subArea.label;
      }

      formik.setFieldValue('subArea', subArea);
    }
  };

  const editInitialValues = () => {
    const countryObj = countries?.find((e) => e?.id === facilityDetails?.address?.countryId);
    const regionObj = regions?.find((e) => e?.id === facilityDetails?.address?.regionId);
    const cityObj = cities?.find((e) => e?.id === facilityDetails?.address?.cityId);
    const subAreasObj = subAreas?.find((e) => e?.id === facilityDetails?.address?.subAreaId);

    return {
      id: facilityDetails?.id,
      active: facilityDetails?.active,
      deleted: facilityDetails?.deleted,
      name: facilityDetails?.name,
      email: facilityDetails?.email,
      phoneNumber: facilityDetails?.phoneNumber,
      landmark: facilityDetails?.address?.landmark,
      postalCode: facilityDetails?.address?.postalCode,
      notes: facilityDetails?.notes,
      country: { ...countryObj, value: countryObj?.id },
      region: { ...regionObj, value: regionObj?.id },
      city: { ...cityObj, value: cityObj?.id },
      subArea: { ...subAreasObj, value: subAreasObj?.id },
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    name: '',
    email: '',
    phoneNumber: '',
    landmark: '',
    postalCode: '',
    notes: '',
    country: null,
    region: null,
    city: null,
    subArea: null,
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(facilityId) ? getInitialValues() : editInitialValues(),
    onSubmit: async (values) => {
      const payload = {
        active: values?.active,
        deleted: values?.deleted,
        name: values?.name,
        phoneNumber: values?.phoneNumber,
        email: values?.email,
        notes: values?.notes,
        address: {
          country: values?.country,
          region: values?.region,
          city: values?.city,
          subArea: values?.subArea,
          landmark: values?.landmark,
          postalCode: values?.postalCode,
        },
      };

      isEmpty(facilityId)
        ? addFacilityRequest({
            body: payload,
          })
        : updateFacilityRequest({
            body: payload,
          });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Please provide name')
        .min(3, 'Title should be at least 3 characters long')
        .max(50, 'Title should be at most 50 characters long'),
      email: Yup.string().required('Please provide email'),
      phoneNumber: Yup.string().required('Please provide phone number'),
      landmark: Yup.string().required('Please provide landmark'),
      postalCode: Yup.string().required('Please provide postal code'),
      country: Yup.object().test('countryTest', 'Please select country', (val) => !isEmpty(val)),
      region: Yup.object().test('regionTest', 'Please select region/state', (val) => !isEmpty(val)),
      city: Yup.object().test('cityTest', 'Please select city', (val) => !isEmpty(val)),
      subArea: Yup.object().test('subAreaTest', 'Please select subarea', (val) => !isEmpty(val)),
    }),
  });

  useEffect(() => {
    if (!isEmpty(facilityId)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      dispatch(
        getCities.trigger({
          level: 3,
          page: 1,
          size: 500,
          cityOnly: true,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      dispatch(getSubAreas.trigger({ level: 4, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));
      getFacilityRequest();
    }
  }, [facilityId]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Loader isActive={addFacilityLoading || getFacilityLoading || updateFacilityLoading} />
          <Breadcrumbs
            title="Health care facility"
            breadcrumbItem={`${isEmpty(facilityId) ? 'Add' : 'Edit'} Health care facility`}
          />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="name" className="form-label  ">
                            Name
                          </Label>
                          <Input
                            id="name"
                            type="text"
                            className="form-control"
                            placeholder="Name..."
                            invalid={!!(formik.touched.name && formik.errors.name)}
                            {...formik.getFieldProps('name')}
                            error={!isEmpty(formik.errors.name)}
                          />
                          <FormFeedback>{formik.errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="email" className="form-label  ">
                            Email
                          </Label>
                          <Input
                            id="email"
                            type="text"
                            className="form-control"
                            placeholder="Email..."
                            invalid={!!(formik.touched.email && formik.errors.email)}
                            {...formik.getFieldProps('email')}
                          />
                          <FormFeedback>{formik.errors.email}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="phoneNumber" className="form-label  ">
                            Phone Number
                          </Label>
                          <Input
                            id="phoneNumber"
                            type="number"
                            className="form-control"
                            placeholder="Phone Number..."
                            invalid={!!(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                            {...formik.getFieldProps('phoneNumber')}
                          />
                          <FormFeedback>{formik.errors.phoneNumber}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="school_country" className="form-label">
                            Country
                          </Label>
                          <Select
                            id="school_country"
                            options={countries}
                            value={formik.values.country}
                            placeholder="Please select a country"
                            onChange={onCountrySelect}
                          />
                          {formik.touched.country && (
                            <div className="invalid-feedback d-block">{formik.errors.country}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="school_state" className="form-label  ">
                            Region/State
                          </Label>

                          <Select
                            id="school_state"
                            placeholder="Select..."
                            value={formik.values.region}
                            onChange={onRegionSelect}
                            options={regions}
                          />
                          {formik.touched.region && (
                            <div className="invalid-feedback d-block">{formik.errors.region}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="school_city" className="form-label  ">
                            City
                          </Label>
                          <Creatable
                            id="school_city"
                            placeholder="Select..."
                            value={formik.values.city}
                            onChange={onCitySelect}
                            options={cities}
                          />
                          {formik.touched.city && <div className="invalid-feedback d-block">{formik.errors.city}</div>}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="school_area" className="form-label  ">
                            SubArea
                          </Label>
                          <Select
                            id="school_area"
                            placeholder="Select..."
                            value={formik.values.subArea}
                            onChange={onSubAreaSelect}
                            options={subAreas}
                          />
                          {formik.touched.subArea && (
                            <div className="invalid-feedback d-block">{formik.errors.subArea}</div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="landmark" className="form-label  ">
                            landmark
                          </Label>
                          <Input
                            id="landmark"
                            type="text"
                            className="form-control"
                            placeholder="Landmark..."
                            invalid={!!(formik.touched.landmark && formik.errors.landmark)}
                            {...formik.getFieldProps('landmark')}
                          />
                          <FormFeedback>{formik.errors.landmark}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="postalCode" className="form-label  ">
                            Postal code
                          </Label>
                          <Input
                            id="postalCode"
                            type="number"
                            className="form-control"
                            placeholder="Postal code..."
                            invalid={!!(formik.touched.postalCode && formik.errors.postalCode)}
                            {...formik.getFieldProps('postalCode')}
                          />
                          <FormFeedback>{formik.errors.postalCode}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="schooldesc" className="form-label  ">
                            Notes
                          </Label>
                          <Input
                            type="textarea"
                            className="form-control"
                            id="schooldesc"
                            rows="8"
                            placeholder="Notes..."
                            invalid={!!(formik.touched.notes && formik.errors.notes)}
                            {...formik.getFieldProps('notes')}
                          />
                          <FormFeedback>{formik.errors.notes}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100 mb-3">
                      {`${isEmpty(facilityId) ? 'Add' : 'Update'}`} Health care facility
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.HEALTH_CARE_LISTING);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div>
                      <CardTitle className="mb-3">Active</CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateHealthCareFacility;
