import { USER_ROLE } from '../../utils/constants';
import AddUpdateFAQ from './faq/AddUpdateFAQ';
import FAQListing from './faq/FaqList';
import CMS_LINKS from './links';
import StaticFAQMetadata from './metadata/StaticFAQMetadata';
import StaticPageMetadata from './metadata/StaticPageMetadata';
import AddUpdateStaticPage from './staticPage/AddUpdateStaticPage';
import StaticPagesList from './staticPage/StaticPagesList';

const CMS_ROUTES = [
  // --------  FAQ ------
  {
    path: CMS_LINKS.FAQ_METADATA_LIST,
    exact: true,
    component: StaticFAQMetadata,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: CMS_LINKS.FAQ_LIST,
    exact: true,
    component: FAQListing,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: CMS_LINKS.FAQ_ADD,
    exact: true,
    component: AddUpdateFAQ,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: CMS_LINKS.FAQ_EDIT,
    exact: true,
    component: AddUpdateFAQ,
    role: [USER_ROLE.ADMIN],
  },

  // --------  PAGES ------
  {
    path: CMS_LINKS.STATIC_METADATA_PAGES_LIST,
    exact: true,
    component: StaticPageMetadata,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: CMS_LINKS.STATIC_PAGES_LIST,
    exact: true,
    component: StaticPagesList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: CMS_LINKS.STATIC_PAGES_ADD,
    exact: true,
    component: AddUpdateStaticPage,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: CMS_LINKS.STATIC_PAGES_EDIT,
    exact: true,
    component: AddUpdateStaticPage,
    role: [USER_ROLE.ADMIN],
  },
];

export { CMS_LINKS, CMS_ROUTES };
