import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// styles
import '../style.scss';
// actions
import { useHistory } from 'react-router-dom';
import { SMKLinks } from 'utils/links';
import { schoolClearCache } from 'containers/school/schoolDetailsHelper';
import { getSchoolsInfraList, updateSchoolFacilityDetails } from '../../actions';
// selectors
import { selectSchoolsInfraList, schoolDetailSelector } from '../../selectors';
// components
import TextEditor from '../../../../components/TextEditor';
import ToggleButton from '../../../../components/ToggleButton';
import useRequest from '../../../../hooks/useRequest';
import Loader from '../../../../components/Loader';
import { userSelector } from '../../../../common/globalComponents/selectors';

const stateSelector = createStructuredSelector({
  schoolsInfraList: selectSchoolsInfraList,
  schoolDetail: schoolDetailSelector,
  user: userSelector,
});

const FacilityInfraDetails = () => {
  const { user, schoolsInfraList, schoolDetail } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const [categoryWiseInfraList, setCategoryWiseInfraList] = useState({});

  useEffect(() => {
    if (!isEmpty(schoolsInfraList)) {
      const infraList = {};
      schoolsInfraList.forEach((i) => {
        if (i.infraType) {
          if (!infraList[i.infraType.name]) {
            infraList[i.infraType.name] = [];
          }
          infraList[i.infraType.name].push(i);
        }
      });
      setCategoryWiseInfraList(infraList);
    }
  }, [schoolsInfraList]);

  const [updateFacilityDetailsRequest, { loading: updateFacilityDetailsLoading }] = useRequest({
    url: '/school/admin/edit/updateFacilityDetails',
    method: 'POST',
    onSuccess: (data) => {
      schoolClearCache({
        urlSlug: schoolDetail?.slug,
        schoolId: schoolDetail?.id,
      });

      window.location.reload();
    },
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      selectedInfraList: {},
      facilityDetails: schoolDetail.facilitiesInfo?.facilityDetails,
      safetyDetails: schoolDetail.facilitiesInfo?.safetyDetails,
    },
    onSubmit(values) {
      const { selectedInfraList, facilityDetails, safetyDetails } = values;

      let selectedArray = [];
      Object.values(selectedInfraList).forEach((array) => {
        selectedArray = [...selectedArray, ...array];
      });

      updateFacilityDetailsRequest({
        data: {
          schoolId: schoolDetail.id,
          facilityDetails,
          safetyDetails,
          selectedInfraList: selectedArray,
        },
      });
    },
    validationSchema: Yup.object().shape({
      selectedInfraList: Yup.object(),
      facilityDetails: Yup.string().nullable(),
      safetyDetails: Yup.string().nullable(),
    }),
  });

  useEffect(() => {
    dispatch(getSchoolsInfraList.trigger());
    const { infra } = schoolDetail;
    if (!isEmpty(infra)) {
      const selectedInfra = {};
      infra.forEach((i) => {
        if (i.infraType) {
          if (!selectedInfra[i.infraType.name]) {
            selectedInfra[i.infraType.name] = [];
          }
          selectedInfra[i.infraType.name].push(i);
        }
      });
      formik.setFieldValue('selectedInfraList', selectedInfra);
    }
  }, []);

  const handleToggleFacility = (checked, category, facility) => {
    let array = formik.values.selectedInfraList[category] || [];
    if (checked) {
      array = [...array, facility];
    } else {
      array = array.filter((f) => f.id !== facility.id);
    }
    formik.setFieldValue('selectedInfraList', {
      ...formik.values.selectedInfraList,
      [category]: array,
    });
  };

  const renderFacilities = () => {
    if (!isEmpty(categoryWiseInfraList)) {
      return Object.entries(categoryWiseInfraList).map(([category, facilities]) => (
        <Card key={category}>
          <CardBody>
            <div className="d-flex justify-content-between mb-4 border_bottom">
              <h5 className="font-size-14 ">{category} Facilities</h5>
              <span>{`(${formik.values.selectedInfraList[category]?.length || 0}/${facilities.length})`}</span>
            </div>

            <Row>
              {facilities?.map((facility) => {
                return (
                  <Col sm={3} key={`fac_${facility.id}`}>
                    <ToggleButton
                      label={facility.name}
                      checked={
                        !isEmpty(formik.values.selectedInfraList[category]) &&
                        formik.values.selectedInfraList[category].some((f) => facility.id === f.id)
                      }
                      onChange={(e) => handleToggleFacility(e.target.checked, category, facility)}
                    />
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>
      ));
    }
    return null;
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateFacilityDetailsLoading} />

      <h5 className="mb-4">Facilities and Infrastructure Details</h5>

      {renderFacilities()}

      <Card>
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="schooldesc" className="form-label  ">
              Facility Details
            </Label>

            <TextEditor
              simple
              initialValue={formik.values.facilityDetails}
              onChange={(val) => formik.setFieldValue('facilityDetails', val)}
            />
            {formik.touched.facilityDetails && (
              <div className="invalid-feedback d-block">{formik.errors.facilityDetails}</div>
            )}
          </FormGroup>

          <FormGroup className="mb-4">
            <Label htmlFor="schooldesc" className="form-label  ">
              Safety Details
            </Label>
            <TextEditor
              simple
              initialValue={formik.values.safetyDetails}
              onChange={(val) => formik.setFieldValue('safetyDetails', val)}
            />
            {formik.touched.safetyDetails && (
              <div className="invalid-feedback d-block">{formik.errors.safetyDetails}</div>
            )}
          </FormGroup>
        </CardBody>
      </Card>

      {(user?.entityPermissions?.school.admin ||
        (schoolDetail.editable &&
          (schoolDetail?.createdBy?.id === user.id || schoolDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Information
          </Button>
          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.SCHOOL_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

export default FacilityInfraDetails;
