import { createRoutine } from 'redux-saga-routines';
import {
  FORUM_POST,
  FORUM_TOPIC,
  FORUM_TOPIC_DETAIL,
  SET_FORUM_POST_ACTIVE,
  SET_FORUM_TOPIC_ACTIVE,
  FORUM_TOPIC_SEARCH,
  FORUM_SUB_CATEGORIES,
  SET_FORUM_TOPIC_DELETED,
  SET_FORUM_POST_DELETED,
  SET_FORUM_TOPIC_UNPUBLISH,
  FORUM_POST_DETAIL,
  SET_FORUM_POST_UNPUBLISH,
  FORUM_TOPIC_UPDATE,
  FORUM_POST_UPDATE,
  FORUM_CATEGORIES,
} from './actionTypes';

export const getForumPost = createRoutine(FORUM_POST);
export const getForumTopic = createRoutine(FORUM_TOPIC);
export const getForumTopicDetail = createRoutine(FORUM_TOPIC_DETAIL);
export const getForumTopicUpdate = createRoutine(FORUM_TOPIC_UPDATE);
export const getForumPostDetail = createRoutine(FORUM_POST_DETAIL);
export const getForumPostUpdate = createRoutine(FORUM_POST_UPDATE);
export const getForumTopicSearch = createRoutine(FORUM_TOPIC_SEARCH);
export const getForumSubCategories = createRoutine(FORUM_SUB_CATEGORIES);
export const getForumCategories = createRoutine(FORUM_CATEGORIES);

export const setForumPostApprove = createRoutine(SET_FORUM_POST_ACTIVE);
export const setForumTopicDeleted = createRoutine(SET_FORUM_TOPIC_DELETED);
export const setForumTopicApprove = createRoutine(SET_FORUM_TOPIC_ACTIVE);
export const setForumPostDeleted = createRoutine(SET_FORUM_POST_DELETED);

export const setForumPostUnPublish = createRoutine(SET_FORUM_POST_UNPUBLISH);
export const setForumTopicUnPublish = createRoutine(SET_FORUM_TOPIC_UNPUBLISH);
