import Breadcrumbs from 'components/Common/Breadcrumb';
import Paginate from 'components/Paginate';
import TableError from 'components/TableError';
import { useFormik } from 'formik';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Badge, Card, CardBody, Col, Container, Form, Input, Label, Row, Table } from 'reactstrap';
import { ACTIVE_AND_DELETED_ARRAY, SortOrder, YES_AND_NO_ARRAY } from 'utils/constants';
import { checkIfValueExist, printDateTime } from 'utils/helper';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';

const SitemapValidatorList = ({ showSitemapFilter, showSitemapList, currentModule, source }) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [sitemapValidatorPagination, setsitemapValidatorPagination] = useState({});
  const [sitemapValidatorList, setsitemapValidatorList] = useState();

  const [sitemapValidatorListRequest, { loading: sitemapValidatorListLoading }] = useRequest({
    url: '/admin-tools/sitemap/sitemap-url/search',
    method: 'POST',
    onSuccess: (data) => {
      setsitemapValidatorList(data?.results);
      setsitemapValidatorPagination(data?.pagination || data?.paginator);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const defaultPayload = {
    page: 1,
    size: 20,
    sortBy: 'id',
    sortOrder: 0,
    source,
    module: currentModule,
  };
  const handleChangePage = (page) => {
    setPageInUrl(page);
    sitemapValidatorListRequest({
      body: {
        ...defaultPayload,
        ...(showSitemapFilter ? showSitemapFilter : {}),
        page: page,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      active: { value: null, label: 'All' },
      deleted: { value: null, label: 'All' },
      mappedWithSitemap: { value: null, label: 'All' },
      name: '',
      sitemap: '',
    },
    onSubmit: (values) => {
      setPageInUrl(1);

      sitemapValidatorListRequest({
        body: {
          page: +router?.query?.page || 1,
          size: 20,
          sortBy: 'id',
          sortOrder: 0,
          source,
          module: currentModule,
          active: checkIfValueExist(values?.active?.value),
          deleted: checkIfValueExist(values?.deleted?.value),
          mappedWithSitemap: checkIfValueExist(values?.mappedWithSitemap?.value),
          name: !isEmpty(values?.name) ? values?.name : undefined,
          sitemap: !isEmpty(values?.sitemap) ? values?.sitemap : undefined,
        },
      });
    },
  });

  const clearFilters = () => {
    setPageInUrl(1);
    formik.resetForm();

    sitemapValidatorListRequest({
      body: {
        ...defaultPayload,
      },
    });
  };

  useEffect(() => {
    if (showSitemapList) {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
    }
  }, [showSitemapFilter, showSitemapList]);

  return (
    <div className="mt-4">
      <Loader isActive={sitemapValidatorListLoading} />
      <Card>
        <CardBody>
          <Form onSubmit={formik.handleSubmit}>
            <div className="mt-4">
              <div className="row">
                <div className="col-lg-2 mb-4">
                  <Label className="form-label">Active</Label>
                  <Select
                    options={ACTIVE_AND_DELETED_ARRAY}
                    value={formik?.values?.active}
                    onChange={(value) => formik.setFieldValue('active', value)}
                  />
                </div>

                <div className="col-lg-2 mb-4">
                  <Label className="form-label">Deleted</Label>
                  <Select
                    options={ACTIVE_AND_DELETED_ARRAY}
                    value={formik?.values?.deleted}
                    onChange={(value) => formik.setFieldValue('deleted', value)}
                  />
                </div>
                <div className="col-lg-2 mb-4">
                  <Label className="form-label">Mapped With Sitemap</Label>
                  <Select
                    options={ACTIVE_AND_DELETED_ARRAY}
                    value={formik?.values?.mappedWithSitemap}
                    onChange={(value) => formik.setFieldValue('mappedWithSitemap', value)}
                  />
                </div>
                <div className="col-lg-3 mb-4">
                  <label htmlFor="name" className="form-label">
                    Name
                  </label>
                  <Input
                    type="text"
                    id="name"
                    placeholder="Name..."
                    value={formik?.values?.name}
                    onChange={(e) => formik.setFieldValue('name', e.target.value)}
                  />
                </div>
                <div className="col-lg-3 mb-4">
                  <label htmlFor="sitemap" className="form-label">
                    Sitemap Url
                  </label>
                  <Input
                    type="text"
                    id="sitemap"
                    placeholder="Sitemap..."
                    value={formik?.values?.sitemap}
                    onChange={(e) => formik.setFieldValue('sitemap', e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <button className="btn btn-primary me-4" type="submit">
                Apply Filters
              </button>
              <a className="" type="reset" onClick={clearFilters}>
                Clear
              </a>
            </div>
          </Form>
        </CardBody>
      </Card>

      <Row className="mb-2  align-items-center" id="show_sitemaps">
        <Col sm="4">
          <div className="search-box me-2 mb-2">
            <div className="position-relative font-bold">
              {sitemapValidatorPagination?.totalCount || 0} Sitemap links Found
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <div>
            <div className="table-responsive" style={{ minHeight: 300 }}>
              <Table className="project-list-table table-borderless">
                <thead>
                  <tr className="table-warning">
                    <th scope="col">Id</th>
                    <th scope="col">Module</th>
                    <th scope="col">Sitemap</th>
                    <th scope="col">Url</th>
                    <th scope="col">Last modification date</th>
                    <th scope="col">Language</th>
                    <th scope="col">Mapped With Sitemap</th>
                    <th scope="col">Active</th>
                    <th scope="col">Deleted</th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(sitemapValidatorList) ? (
                    sitemapValidatorList?.map((data, index) => (
                      <tr key={index} className={`${data?.deleted ? 'bg-danger' : ''}`}>
                        <td>{data?.id}</td>
                        <td>{data?.module}</td>
                        <td>{data?.sitemap}</td>
                        <td>
                          <a target="_blank" href={data?.url}>
                            {data?.url}
                          </a>
                        </td>
                        <td>{printDateTime(data?.lastmod)}</td>
                        <td>
                          <Badge className={data?.language === 'en' ? 'bg-primary' : 'bg-warning'}>
                            {data?.language == 'en' ? 'English' : 'Hindi'}
                          </Badge>
                        </td>
                        <td>
                          <Badge className={data?.mappedWithSitemap == '200' ? 'bg-success' : 'bg-danger'}>
                            {data?.mappedWithSitemap ? 'Yes' : 'No'}
                          </Badge>
                        </td>
                        <td>
                          <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                            {data?.active ? 'Yes' : 'No'}
                          </Badge>
                        </td>
                        <td>
                          <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                            {data?.deleted ? 'Yes' : 'No'}
                          </Badge>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <TableError />
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>

      <Paginate pageInfo={sitemapValidatorPagination} onChangePage={handleChangePage} />
    </div>
  );
};

export default SitemapValidatorList;
