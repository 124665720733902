import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { urlSlugify } from 'utils/helper';

const StaticFAQMetadata = () => {
  const [faqModulesList, setFaqModulesList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [getFaqCategoriesRequest] = useRequest({
    method: 'GET',
  });

  const [getFaqSubModulesList] = useRequest({
    method: 'GET',
  });

  const [getFaqModuleList] = useRequest({
    url: '/cms/admin/faq/modules',
    method: 'GET',
  });

  const fetchData = async () => {
    try {
      const modules = await getFaqModuleList();

      const allModulesData = await Promise.all(
        modules?.map(async (module) => {
          const subModules = await getFaqSubModulesList({
            url: `/cms/admin/faq/${module?.moduleSlug}/sub-modules`,
          });

          const categories = await getFaqCategoriesRequest({
            url: `/cms/admin/faq/${module?.moduleSlug}/categories`,
          });

          return {
            ...module,
            subModules,
            categories,
          };
        })
      );

      setFaqModulesList(allModulesData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={loading} />
        <Container fluid>
          <Breadcrumbs title="FAQ" breadcrumbItem="FAQ Metadata List" />

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{faqModulesList?.length || 0} FAQ Metadata Found</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '60px' }}>
                          S.No.
                        </th>
                        <th scope="col">Module</th>
                        <th scope="col">Sub Modules</th>
                        <th scope="col">Categories</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(faqModulesList) ? (
                        faqModulesList.map((moduleElem, index) => (
                          <tr key={moduleElem?.module}>
                            <td>{index + 1}</td>
                            <td>
                              {moduleElem?.module} <br /> {moduleElem?.moduleSlug}
                            </td>
                            <td>
                              {moduleElem?.subModules?.length > 0 ? (
                                moduleElem.subModules.map((elem, i) => (
                                  <div className="mb-1" key={i}>
                                    {i + 1}. {elem?.subModule} - ( {elem?.subModuleSlug} )
                                  </div>
                                ))
                              ) : (
                                <div>No Submodules</div>
                              )}
                            </td>
                            <td>
                              {moduleElem?.categories?.length > 0 ? (
                                moduleElem.categories.map((elem, i) => (
                                  <div className="mb-1" key={i}>
                                    {i + 1}. {elem?.category} - ( {elem?.categorySlug} )
                                  </div>
                                ))
                              ) : (
                                <div>No Categories</div>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default StaticFAQMetadata;
