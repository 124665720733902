import { useFormik } from 'formik';
// actions
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { SMKLinks } from 'utils/links';
import * as Yup from 'yup';
import { userSelector } from '../../../../common/globalComponents/selectors';
import Loader from '../../../../components/Loader';
// components
import ToggleButton from '../../../../components/ToggleButton';
import useRequest from '../../../../hooks/useRequest';
// constants
import { CollegeMasterType, CURRENT_YEAR } from '../../../../utils/constants';
// helpers
import {
  getCollegeCoursesSearchData,
  getCollegesCoursesMasterData,
  getCollegesGroups,
  getCollegesMasterData,
} from '../../actions';
// selectors
import classNames from 'classnames';
import {
  collegeDetailSelector,
  selectCollegesCoursesMasterData,
  selectCollegesCoursesSearchData,
  selectCollegesMasterData,
} from '../../selectors';

const stateSelector = createStructuredSelector({
  collegesMasterData: selectCollegesMasterData,
  collegeDetail: collegeDetailSelector,
  collegesCoursesMasterData: selectCollegesCoursesMasterData,
  collegesCoursesSearchData: selectCollegesCoursesSearchData,
  user: userSelector,
});

const HospitalAdministrationCourseType = () => {
  const { user, collegeDetail, collegesCoursesMasterData, collegesCoursesSearchData, collegesMasterData } = useSelector(
    stateSelector
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const [coursesDegrees, setCoursesDegrees] = useState([]);
  const [coursesTypeActiveTab, setCoursesTypectiveTab] = useState('0');
  const [coursesLevelsActiveTab, setCoursesLevelsActiveTab] = useState('0');
  const [coursesDegreesActiveTab, setCoursesDegreesActiveTab] = useState('0');
  const courseType = 'Hospital Administration';

  const getInitialValues = () => {
    const { academicDetails, basicDetails } = collegeDetail;
    const values = {
      collegeShift: academicDetails?.shift || [],
      seatSummary: basicDetails?.seatSummary || [],
      courseTypes: [],
      courseLevels: [],
      courseDegrees: [],
      courseDegreesList: [],
      languages: academicDetails?.languages || [],
      curriculumDetails: academicDetails?.curriculumDetails || [],
      syllabusDetails: academicDetails?.syllabusDetails,
      collegeGroups: academicDetails?.collegeGroups || [],
    };
    return values;
  };

  const [updateCollegeAcademicDetailsRequest, { loading: updateCollegeAcademicDetailsLoading }] = useRequest({
    url: '/college/admin/edit/updateAcademicDetails',
    method: 'POST',
    onSuccess: (data) => {
      window.location.reload();
    },
  });

  const isEmptyValue = (val) => {
    return val || undefined;
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit(values) {
      updateCollegeAcademicDetailsRequest({
        data: {
          collegeId: isEmptyValue(collegeDetail?.id),
          courses: isEmptyValue(values.courseDegreesList),
          collegeGroups: isEmptyValue(values.collegeGroups),
          seatSummary: isEmptyValue(values.seatSummary),
          academicYear: CURRENT_YEAR,
          academicDetails: {
            shift: isEmptyValue(values.collegeShift),
            languages: isEmptyValue(values.languages),
            syllabusDetails: isEmptyValue(values.syllabusDetails),
            collegeHours: isEmptyValue(values.collegeHours),
            curriculumDetails: isEmptyValue(values.curriculumDetails),
          },
        },
      });
    },
    validationSchema: Yup.object().shape({
      // name: Yup.string().required('Please provide college name'),
    }),
  });

  const collegeGroupsPayload = {
    page: 1,
    size: 500,
    sortBy: 'id',
    sortOrder: 0,
  };

  useEffect(() => {
    dispatch(getCollegesGroups.trigger(collegeGroupsPayload));
    dispatch(getCollegesCoursesMasterData.trigger({}));
    dispatch(
      getCollegesMasterData.trigger({
        page: 1,
        size: 500,
        sortBy: 'id',
        sortOrder: 0,
      })
    );
  }, []);

  const toggleCoursesTypesTabs = (tab) => {
    if (coursesTypeActiveTab !== tab) {
      setCoursesTypectiveTab(tab);
    }
  };

  const toggleCoursesLevelsTabs = (tab) => {
    if (coursesLevelsActiveTab !== tab) {
      setCoursesLevelsActiveTab(tab);
    }
  };
  const toggleCoursesDegreesTabs = (tab) => {
    if (coursesDegreesActiveTab !== tab) {
      setCoursesDegreesActiveTab(tab);
    }
  };

  useEffect(() => {
    if (!isEmpty(formik.values.courseDegrees)) {
      dispatch(
        getCollegeCoursesSearchData.trigger({
          page: 1,
          size: 500,
          sortBy: 'id',
          sortOrder: 0,
          typeIds: formik.values.courseTypes?.map((e) => e?.id),
          levelIds: formik.values.courseLevels?.map((e) => e?.id),
          degreeTypeIds: formik.values.courseDegrees?.map((e) => e?.id),
        })
      );
    } else {
      setCoursesDegrees([]);
    }
  }, [formik.values.courseDegrees]);

  useEffect(() => {
    if (!isEmpty(collegesCoursesSearchData?.results)) {
      setCoursesDegrees(collegesCoursesSearchData);
      const courseDegreesList = collegesCoursesSearchData?.results?.filter((elem) =>
        collegeDetail?.coursesOffered?.some((e) => e?.course?.id === elem?.id)
      );
      formik.setFieldValue('courseDegreesList', courseDegreesList);
    } else {
      setCoursesDegrees([]);
    }
  }, [collegesCoursesSearchData]);

  useEffect(() => {
    const processCourseData = async () => {
      if (!isEmpty(collegesCoursesMasterData)) {
        const { coursesOffered } = collegeDetail;
        const courseTypeId = collegesMasterData
          .filter((t) => t.type === CollegeMasterType.COURSE_TYPE)
          .find((t) => t.displayName === courseType)?.id;
        const uniqueListByCourseType = (type) =>
          coursesOffered?.filter((t) => t.course.typeId === courseTypeId)?.map((elem) => elem?.course[type]);
        const filterList = (data1, data2) => data1?.filter((elem) => data2?.some((e) => e === elem?.id));

        // Course Type
        const courseTypesList = uniqueListByCourseType('typeId');
        const courseTypeData = filterList(collegesCoursesMasterData, courseTypesList).filter(
          (e) => e?.displayName === courseType
        );

        if (courseTypeData.map((e) => e?.displayName).includes(courseType)) {
          // Course Level and Degrees
          const courseLevels = courseTypeData.flatMap((element) => element.course_level);
          const courseDegrees = courseLevels.flatMap((level) => level.course_degree);
          // Course Level
          const courseLevelsList = uniqueListByCourseType('levelId');
          // Course Degrees
          const courseDegreesIdList = uniqueListByCourseType('degreeTypeId');

          formik.setFieldValue('courseTypes', courseTypeData);
          formik.setFieldValue('courseLevels', filterList(courseLevels, courseLevelsList));
          formik.setFieldValue('courseDegrees', filterList(courseDegrees, courseDegreesIdList));
        }
      }
    };

    processCourseData();
  }, [collegesCoursesMasterData, collegeDetail]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateCollegeAcademicDetailsLoading} />
      <h5 className="mb-4">CourseType List</h5>

      <Card>
        <CardBody>
          <CardTitle className="mb-4">Course Type</CardTitle>
          <Row>
            {!isEmpty(collegesCoursesMasterData) &&
              collegesCoursesMasterData
                ?.filter((course) => course?.displayName === 'Hospital Administration')
                ?.map((course, i) => {
                  return (
                    <Col key={`course_${i}`} md={4}>
                      <ToggleButton
                        label={course?.displayName}
                        onChange={(e) => {
                          const selectedCourseTypes = formik.values.courseTypes || [];
                          if (e.target.checked) {
                            if (!selectedCourseTypes.includes(course)) {
                              formik.setFieldValue('courseTypes', [...selectedCourseTypes, course]);
                            }
                          } else {
                            setCoursesDegrees([]);
                            formik.setFieldValue(
                              'courseTypes',
                              selectedCourseTypes.filter((c) => c !== course)
                            );
                          }
                        }}
                        checked={formik.values.courseTypes.map((t) => t.id)?.includes(course.id)}
                      />
                    </Col>
                  );
                })}
          </Row>
        </CardBody>
      </Card>

      {!isEmpty(formik.values.courseTypes) && (
        <>
          <Nav tabs>
            {formik.values.courseTypes?.map((elem, index) => (
              <NavItem key={`tab_${index}`}>
                <NavLink
                  className={classNames({ active: coursesTypeActiveTab === `${index}` })}
                  onClick={() => toggleCoursesTypesTabs(`${index}`)}>
                  {elem?.displayName}
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <Card>
            <CardBody>
              {formik.values.courseTypes?.map((element, index) => (
                <TabContent key={`tabContentOne_${index}`} activeTab={coursesTypeActiveTab}>
                  <TabPane key={`tabPane_${index}`} tabId={`${index}`}>
                    {formik.values.courseTypes
                      ?.filter((data) => data?.displayName === element?.displayName)
                      ?.map((elem) => (
                        <div key={`tabs_${elem.displayName}`}>
                          <CardTitle className="mb-2">Course Level - {elem?.displayName}</CardTitle>

                          <Row className="bg-light px-2 py-4 mx-0 rounded-2">
                            {elem?.course_level?.map((level, i) => {
                              return (
                                <Col key={`level_${i}`} md={4}>
                                  <ToggleButton
                                    label={level?.displayName}
                                    onChange={(e) => {
                                      const selectedCourseLevels = formik.values.courseLevels || [];

                                      if (e.target.checked) {
                                        if (!selectedCourseLevels.includes(level)) {
                                          formik.setFieldValue('courseLevels', [...selectedCourseLevels, level]);
                                        }
                                      } else {
                                        formik.setFieldValue(
                                          'courseLevels',
                                          selectedCourseLevels.filter((c) => c?.displayName !== level?.displayName)
                                        );
                                      }
                                    }}
                                    checked={formik.values.courseLevels?.includes(level)}
                                  />
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      ))}
                  </TabPane>
                </TabContent>
              ))}

              {!isEmpty(formik.values.courseLevels) && (
                <div className="mt-4">
                  <Nav tabs>
                    {formik.values.courseLevels?.map((elem, index) => (
                      <NavItem key={`tab_${index}`}>
                        <NavLink
                          className={classNames({ active: coursesLevelsActiveTab === `${index}` })}
                          onClick={() => toggleCoursesLevelsTabs(`${index}`)}>
                          {elem?.displayName}
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>

                  {formik.values.courseLevels?.map((element, index) => (
                    <TabContent key={`tabContent_${index}`} activeTab={coursesLevelsActiveTab}>
                      <TabPane key={`tabPane_${index}`} tabId={`${index}`}>
                        {formik.values.courseLevels
                          ?.filter((data) => data?.displayName === element?.displayName)
                          ?.map((elem) => (
                            <div key={`tabContent_${elem.displayName}`} className="my-4">
                              <CardTitle className="mb-2">Course Degree - {elem?.displayName}</CardTitle>

                              <Row className="bg-light px-2 py-4 mx-0 rounded-2">
                                {elem?.course_degree?.map((sub_level, i) => {
                                  return (
                                    <Col key={`degree_${i}`} md={4}>
                                      <ToggleButton
                                        label={sub_level?.displayName}
                                        onChange={(e) => {
                                          const selectedCourseDegrees = formik.values.courseDegrees || [];

                                          if (e.target.checked) {
                                            if (!selectedCourseDegrees.includes(sub_level)) {
                                              formik.setFieldValue('courseDegrees', [
                                                ...selectedCourseDegrees,
                                                sub_level,
                                              ]);
                                            }
                                          } else {
                                            formik.setFieldValue(
                                              'courseDegrees',
                                              selectedCourseDegrees.filter(
                                                (c) => c?.displayName !== sub_level?.displayName
                                              )
                                            );
                                          }
                                        }}
                                        checked={formik.values.courseDegrees?.includes(sub_level)}
                                      />
                                    </Col>
                                  );
                                })}
                              </Row>
                            </div>
                          ))}
                      </TabPane>
                    </TabContent>
                  ))}
                </div>
              )}
            </CardBody>
          </Card>
        </>
      )}

      {!isEmpty(formik.values.courseTypes) && !isEmpty(coursesDegrees?.results) && (
        <div className="mt-4">
          <Card>
            <CardBody>
              <h5> {formik.values.courseLevels?.map((e) => `${e?.displayName}` + ', ')}</h5>
              <Nav className="mt-4" tabs>
                {formik?.values?.courseDegrees?.map((elem, index) => (
                  <NavItem key={`tab_${index}`}>
                    <NavLink
                      className={classNames({ active: coursesDegreesActiveTab === `${index}` })}
                      onClick={() => toggleCoursesDegreesTabs(`${index}`)}>
                      {elem?.displayName}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>

              {formik.values.courseDegrees?.map((e, index) => (
                <TabContent key={`tabContent_${index}`} activeTab={coursesDegreesActiveTab}>
                  <TabPane key={`tabPane_${index}`} tabId={`${index}`}>
                    <Row className="bg-light px-2 py-4 mx-0 rounded-2 mt-4">
                      {coursesDegrees?.results
                        ?.filter((elem) => elem?.degreeTypeId === e?.id)
                        ?.map((degree) => (
                          <Col key={`_col_${degree.displayName}_`} md={4}>
                            <ToggleButton
                              label={degree?.displayName}
                              onChange={(e) => {
                                const selectedcourseDegreesList = formik.values.courseDegreesList || [];

                                if (e.target.checked) {
                                  if (!selectedcourseDegreesList.includes(degree)) {
                                    formik.setFieldValue('courseDegreesList', [...selectedcourseDegreesList, degree]);
                                  }
                                } else {
                                  formik.setFieldValue(
                                    'courseDegreesList',
                                    selectedcourseDegreesList.filter((c) => c !== degree)
                                  );
                                }
                              }}
                              checked={formik.values.courseDegreesList?.includes(degree)}
                            />
                          </Col>
                        ))}
                    </Row>
                  </TabPane>
                </TabContent>
              ))}
            </CardBody>
          </Card>
        </div>
      )}

      {(user?.entityPermissions?.college.admin ||
        (collegeDetail.editable &&
          (collegeDetail?.createdBy?.id === user.id || collegeDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Details
          </Button>
          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.COLLEGE_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

HospitalAdministrationCourseType.propTypes = {};

export default HospitalAdministrationCourseType;
