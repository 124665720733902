import { createSelector } from 'reselect';

import { initialState } from './reducer';

export const SCHOOL_STORE_KEY = 'school';

const selectSchoolSubStore = (store) => store[SCHOOL_STORE_KEY] || initialState;

export const schoolListSelector = createSelector(selectSchoolSubStore, (subState) => subState.schools);

export const schoolsPaginationSelector = createSelector(selectSchoolSubStore, (subState) => subState.schoolsPagination);

export const schoolReviewsSelector = createSelector(selectSchoolSubStore, (subState) => subState.schoolReviews);

export const schoolReviewsPaginationSelector = createSelector(
  selectSchoolSubStore,
  (subState) => subState.schoolReviewsPagination
);

export const googleObjectListSelector = createSelector(selectSchoolSubStore, (subState) => subState.googleObjects);

export const googleObjectListPaginationSelector = createSelector(
  selectSchoolSubStore,
  (subState) => subState.googleObjectsPagination
);

export const schoolSearchListSelector = createSelector(selectSchoolSubStore, (subState) => subState.schoolSearchList);

export const googleObjectForApprovalSelector = createSelector(
  selectSchoolSubStore,
  (subState) => subState.googleObjectForApproval
);

export const admissionInquiriesSelector = createSelector(
  selectSchoolSubStore,
  (subState) => subState.admissionInquiries
);

export const admissionInquiriesPaginationSelector = createSelector(
  selectSchoolSubStore,
  (subState) => subState.admissionInquiriesPagination
);

export const countriesSelector = createSelector(selectSchoolSubStore, (subState) => subState.countries);

export const regionsSelector = createSelector(selectSchoolSubStore, (subState) => subState.regions);

export const citiesSelector = createSelector(selectSchoolSubStore, (subState) => subState.cities);

export const subAreasSelector = createSelector(selectSchoolSubStore, (subState) => subState.subAreas);

export const schoolDetailSelector = createSelector(selectSchoolSubStore, (subState) => subState.schoolDetail);

export const selectSchoolBoards = createSelector(selectSchoolSubStore, (schoolState) => schoolState.schoolBoards);

export const selectSchoolLevels = createSelector(selectSchoolSubStore, (schoolState) => schoolState.schoolLevels);

export const selectSchoolsMasterData = createSelector(
  selectSchoolSubStore,
  (schoolState) => schoolState.schoolsMasterData
);

export const selectSchoolsLevelMaster = createSelector(
  selectSchoolSubStore,
  (schoolState) => schoolState.schoolsLevelMaster
);

export const selectSchoolsInfraList = createSelector(
  selectSchoolSubStore,
  (schoolState) => schoolState.schoolsInfraList
);

export const selectSchoolsGroups = createSelector(selectSchoolSubStore, (schoolState) => schoolState.schoolsGroups);
