import { call, put, takeLatest } from 'redux-saga/effects';
import { api } from '../../utils/axios';
import config from '../../config/config';

import { getUserList, getUserSearchList } from './actions';
import { POST, PUT, GET } from '../../utils/httpMethods';
import { setGlobalError } from '../../common/globalComponents/actions';

export const GET_USERS_API_URL = `${config.apiEndpoint}/admin/users`;
export const GET_USERS_SEARCH_API_URL = `${config.apiEndpoint}/user/admin/search`;
export const GET_USER_PROFILE_API_URL = `${config.apiEndpoint}/user/admin/`;

const getUserListCall = (filters) =>
  api({
    method: 'POST',
    url: GET_USERS_SEARCH_API_URL,
    data: filters,
  });

function* getUserListRequest(action) {
  try {
    yield put(getUserList.request({ isLoading: true }));
    const response = yield call(getUserListCall, action.payload);
    if (response.success) {
      yield put(getUserList.success({ ...response.data }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getUserList.failure(error));
  } finally {
    yield put(getUserList.fulfill({ isLoading: false }));
  }
}

function* getUserSearchListRequest(action) {
  try {
    yield put(getUserSearchList.request());
    const response = yield call(getUserListCall, action.payload);
    if (response.success) {
      yield put(getUserSearchList.success({ ...response.data }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getUserSearchList.failure(error));
  } finally {
    yield put(getUserSearchList.fulfill());
  }
}

export default function* userSaga() {
  yield takeLatest(getUserList.TRIGGER, getUserListRequest);
  yield takeLatest(getUserSearchList.TRIGGER, getUserSearchListRequest);
}
