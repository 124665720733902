import { createSelector } from 'reselect';

import { initialState } from './reducer';

export const USER_STORE_KEY = 'user';

const selectForumSubStore = (store) => store[USER_STORE_KEY] || initialState;

const userListSelector = createSelector(selectForumSubStore, (subState) => subState.users);

const userSearchListSelector = createSelector(selectForumSubStore, (subState) => subState.userSearchList);

const userPaginationSelector = createSelector(selectForumSubStore, (subState) => subState.userPagination);

const userProfileSelector = createSelector(selectForumSubStore, (subState) => subState.userProfile);

export { userListSelector, userSearchListSelector, userPaginationSelector };
