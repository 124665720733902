import Loader from 'components/Loader';
import useRequest from 'hooks/useRequest';
import { isEmpty, map } from 'lodash';
import MiniCards from 'components/MiniCard/mini-card';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Container,
  Table,
  UncontrolledDropdown,
  Row,
  Col,
  CardTitle,
} from 'reactstrap';
import {
  BabyNameGenderValue,
  LANGUAGE_VALUES,
  GET_USER_ROLE,
  GET_USER_SOURCE,
  TOPIC_STATUS_STING,
} from 'utils/constants';
import { buildURL, printDateTime, printDateTimeTwoLines, titleCaseIfExists } from 'utils/helper';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { toast } from 'react-toastify';
import { SMKLinks } from 'utils/links';
import { Divider } from '@material-ui/core';

const TopicDetail = (props) => {
  const { id: topicId } = useParams();
  const [topicDetails, setTopicDetails] = useState();
  toast.configure();

  const [getTopicDetails, { loading: getTopicDetailsLoading }] = useRequest({
    url: `/forum/admin/topic/search`,
    method: 'POST',
    body: {
      id: topicId,
    },
    onSuccess: (data) => {
      setTopicDetails(data?.results[0]);
    },
    onError: (err) => {
      toast.error(err);
    },
  });

  const [markApproveAndUnpublishRequest, { loading: markApproveAndUnpublishLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      getTopicDetails();
      toast.success(`Success! Topic has been ${data?.status !== 0 ? 'UnPublished' : 'Approved'}.`);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      getTopicDetails();
      toast.success(`Success!  Topic has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  useEffect(() => {
    getTopicDetails();
  }, []);

  const topicStatusColors = {
    0: 'warning',
    1: 'success',
    2: 'danger',
  };
  const topicStatusIcons = {
    0: 'bx bxs-error',
    1: 'bx-check-shield',
    2: 'bxs-shield-x',
  };

  const miniCards = [
    {
      title: 'Topic ID',
      iconClass: 'bx-file',
      text: `${topicDetails?.id || '-'}`,
    },
    {
      title: 'Status',
      iconClass: `${topicStatusIcons[topicDetails?.status]}`,
      iconBgColor: `bg-${topicStatusColors[topicDetails?.status]}`,
      text: `${TOPIC_STATUS_STING[topicDetails?.status]}`,
      textColor: `text-${topicStatusColors[topicDetails?.status]}`,
    },
    {
      title: 'Deleted',
      iconClass: `${topicDetails?.deleted ? 'bxs-trash' : 'bxs-trash'}`,
      iconBgColor: `${topicDetails?.deleted ? 'bg-success' : 'bg-danger'}`,
      text: `${topicDetails?.deleted ? 'Yes' : 'No'}`,
      textColor: `${topicDetails?.deleted ? 'text-success' : 'text-danger'}`,
    },
  ];

  const handleApprove = (data) => {
    data?.status !== 0
      ? markApproveAndUnpublishRequest({
          url: `/forum/admin/topic/unpublish`,
          body: {
            id: data?.id,
          },
        })
      : markApproveAndUnpublishRequest({
          url: `/forum/admin/topic/approve`,
          body: {
            id: data?.id,
            status: 1,
            withReAssignment: true,
            comments: data?.comments,
          },
        });
  };

  const handleDelete = (data) => {
    deleteUnDeleteRequest({
      url: `/forum/admin/topic/delete`,
      body: {
        id: data?.id,
        deleted: !data?.deleted,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getTopicDetailsLoading || deleteUnDeleteLoading || markApproveAndUnpublishLoading} />
        <Container fluid>
          <Breadcrumbs title="Topic" breadcrumbItem="Topic detail" />

          {!isEmpty(topicDetails) && (
            <>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-end pt-3">
                    <h3 className="mb-0">
                      {` (${topicDetails?.id})  `} {topicDetails?.title}
                    </h3>
                    <UncontrolledDropdown style={{ zIndex: '9999' }}>
                      <DropdownToggle href="#" className="card-drop cursor-pointer" tag="i">
                        <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem onClick={() => handleApprove(topicDetails)}>
                          <i
                            className={`fas ${
                              topicDetails?.status !== 0 ? 'fa-trash-alt text-danger' : 'fa-power-off text-success'
                            }     m-1 pe-3`}
                          />
                          {topicDetails?.status !== 0 ? 'Unpublish' : 'Approve'}
                        </DropdownItem>

                        <DropdownItem onClick={() => handleDelete(topicDetails)}>
                          <i
                            className={`fas fa-trash-alt ${
                              topicDetails?.deleted ? 'text-success' : 'text-danger'
                            } m-1 pe-3`}
                          />
                          {topicDetails?.deleted ? 'Undelete' : 'Delete'}
                        </DropdownItem>
                        <Divider className="my-2" />

                        <Link
                          to={buildURL(SMKLinks.TOPIC_EDIT, {
                            id: topicDetails?.id,
                          })}>
                          <DropdownItem>
                            <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                            Edit
                          </DropdownItem>
                        </Link>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>

                  <CardTitle
                    className="mt-4 text-muted  font-weight-normal"
                    dangerouslySetInnerHTML={{
                      __html: topicDetails?.content,
                    }}
                  />
                </CardBody>
              </Card>
            </>
          )}

          <Row>
            <Col xl="4">
              <Card>
                <CardBody className="p-2">
                  <CardTitle className="mb-2 ms-2 ">Created By</CardTitle>
                  <div className="table-responsive" style={{ minHeight: 50 }}>
                    <Table className="table-nowrap mb-0">
                      {!isEmpty(topicDetails?.createdBy) && (
                        <tbody>
                          <tr>
                            <th>ID / Full Name</th>
                            <td>
                              ( {topicDetails?.createdBy?.id} ) {topicDetails?.createdBy?.displayName}
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card?.title}
                    text={card?.text}
                    textColor={card?.textColor}
                    iconClass={card?.iconClass}
                    iconBgColor={card?.iconBgColor}
                    key={`_card_${key}`}
                  />
                ))}
              </Row>
            </Col>
            {!isEmpty(topicDetails?.category) && (
              <Col xl="12">
                <Card>
                  <CardBody>
                    {!isEmpty(topicDetails?.category) ? (
                      <>
                        <CardTitle className="mb-4">Category </CardTitle>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive" style={{ minHeight: 100 }}>
                              <Table className=" table-nowrap table-centered table-borderless">
                                <thead>
                                  <tr className="bg-light">
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>slug</th>
                                    <th>Language</th>
                                    <th>Post Count</th>
                                    <th>Topic Count</th>
                                    <th>Follow Count</th>
                                    <th>View Count</th>
                                    <th>Frontend App Name</th>
                                    <th>Created Date</th>
                                    <th>Active</th>
                                    <th>Deleted</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{topicDetails?.category?.id}</td>
                                    <td>{topicDetails?.category?.name}</td>
                                    <td>{topicDetails?.category?.slug}</td>
                                    <td>{LANGUAGE_VALUES[topicDetails?.category?.language]}</td>
                                    <td>{topicDetails?.category?.postCount}</td>
                                    <td>{topicDetails?.category?.topicCount}</td>
                                    <td>{topicDetails?.category?.followCount}</td>
                                    <td>{topicDetails?.category?.viewCount}</td>
                                    <td>{topicDetails?.category?.frontend_app_name}</td>
                                    <td>{printDateTimeTwoLines(topicDetails?.category?.createdDate)}</td>
                                    <td>
                                      <Badge className={topicDetails?.category?.active ? 'bg-success' : 'bg-danger'}>
                                        {topicDetails?.category?.active ? 'Yes' : 'No'}
                                      </Badge>
                                    </td>
                                    <td>
                                      <Badge className={topicDetails?.category?.deleted ? 'bg-success' : 'bg-danger'}>
                                        {topicDetails?.category?.deleted ? 'Yes' : 'No'}
                                      </Badge>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <div style={{ minHeight: 230 }} className="d-flex justify-content-center align-items-center">
                        <div className="text-center">
                          <i className="bx bxs-error" style={{ fontSize: '70px', color: 'orange' }} />
                          <h5 className="mt-3">No Category Found!</h5>
                        </div>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            )}

            {!isEmpty(topicDetails?.subCategories) && (
              <Col sm="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Sub Categories </CardTitle>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive" style={{ minHeight: 100 }}>
                          <Table className=" table-nowrap table-centered table-borderless">
                            <thead>
                              <tr className="bg-light">
                                <th>ID</th>
                                <th>Name</th>
                                <th>slug</th>
                                <th>Language</th>
                                <th>Post Count</th>
                                <th>Topic Count</th>
                                <th>Follow Count</th>
                                <th>View Count</th>
                                <th>Frontend App Name</th>
                                <th>Created Date</th>
                                <th>Active</th>
                                <th>Deleted</th>
                              </tr>
                            </thead>
                            <tbody>
                              {topicDetails?.subCategories?.map((elem) => (
                                <tr>
                                  <td>{elem?.id}</td>
                                  <td>{elem?.name}</td>
                                  <td>{elem?.slug}</td>
                                  <td>{LANGUAGE_VALUES[elem?.language]}</td>
                                  <td>{elem?.postCount}</td>
                                  <td>{elem?.topicCount}</td>
                                  <td>{elem?.followCount}</td>
                                  <td>{elem?.viewCount}</td>
                                  <td>{elem?.frontend_app_name}</td>
                                  <td>{printDateTimeTwoLines(elem?.createdDate)}</td>
                                  <td>
                                    <Badge className={topicDetails?.category?.active ? 'bg-success' : 'bg-danger'}>
                                      {topicDetails?.category?.active ? 'Yes' : 'No'}
                                    </Badge>
                                  </td>
                                  <td>
                                    <Badge className={topicDetails?.category?.deleted ? 'bg-success' : 'bg-danger'}>
                                      {topicDetails?.category?.deleted ? 'Yes' : 'No'}
                                    </Badge>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}

            {!isEmpty(topicDetails?.tags) && (
              <Col sm="12">
                <Card>
                  <CardBody>
                    <CardTitle className="mb-4">Tags </CardTitle>
                    <Row>
                      <Col xl="12">
                        <div className="table-responsive" style={{ minHeight: 100 }}>
                          <Table className=" table-nowrap table-centered table-borderless">
                            <thead>
                              <tr className="bg-light">
                                <th>ID</th>
                                <th>Name</th>
                                <th>slug</th>
                                <th>Language</th>
                                <th>Active</th>
                                <th>Approved</th>
                                <th>Created Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {topicDetails?.tags?.map((elem, index) => (
                                <tr key={index}>
                                  <td>{elem?.id}</td>
                                  <td>{elem?.name}</td>
                                  <td>{elem?.slug}</td>
                                  <td>{LANGUAGE_VALUES[elem?.language]}</td>
                                  <td>
                                    <Badge className={elem?.active ? 'bg-success' : 'bg-danger'}>
                                      {elem?.active ? 'Yes' : 'No'}
                                    </Badge>
                                  </td>
                                  <td>
                                    <Badge className={elem?.approved ? 'bg-success' : 'bg-danger'}>
                                      {elem?.approved ? 'Yes' : 'No'}
                                    </Badge>
                                  </td>
                                  <td>{printDateTime(elem?.createdDate)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

TopicDetail.propTypes = {
  currentPost: PropTypes.object,
};

export default TopicDetail;
