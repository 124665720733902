import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import SeoMetaData from 'containers/blog/BlogList/components/SeoMetaData';
import { useFormik } from 'formik';
import { COMMUNITY_GRAPHQL_URL } from 'lib/apollo-constant';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { LANGUAGE_ARRAY_WITH_STRING, MODULE_TYPE } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { SMKLinks } from '../../../utils/links';
import GroupCategoriesSearch from '../components/GroupCategoriesSearch';
import { ADD_COMMUNITY_GROUP, UPDATE_COMMUNITY_GROUP } from '../graphql/community.mutation';
import { COMMUNITY_GROUPS_GET_BY_ID, COMMUNITY_GROUPS_SEARCH } from '../graphql/community.query';
import AttachmentImageUploader from 'components/AttachmentImageUploader';

const AddUpdateGroups = () => {
  const history = useHistory();
  const { id: categoryId } = useParams();

  toast.configure();

  const [groupList, setGroupList] = useState();
  const [groupDetails, setGroupDetails] = useState();

  const [groupListRequest, { loading: groupListLoading }] = useLazyQuery(COMMUNITY_GROUPS_SEARCH, {
    ...COMMUNITY_GRAPHQL_URL,
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_group_search)) {
        setGroupList(data?.admin_community_group_search?.results);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [getGroupDetailsRequest, { loading: getGroupDetailsLoading }] = useLazyQuery(COMMUNITY_GROUPS_GET_BY_ID, {
    ...COMMUNITY_GRAPHQL_URL,
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_group_getById)) {
        setGroupDetails(data?.admin_community_group_getById);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [addCategoryRequest, { loading: addCategoryLoading }] = useMutation(ADD_COMMUNITY_GROUP, {
    ...COMMUNITY_GRAPHQL_URL,
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_group_add)) {
        toast.success(`Success! Group ( ${data?.id} ) has been added.`);
        history.replace(SMKLinks.COMMUNITY_GROUPS_LIST);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [updateCategoryRequest, { loading: updateCategoryLoading }] = useMutation(UPDATE_COMMUNITY_GROUP, {
    ...COMMUNITY_GRAPHQL_URL,
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_group_update)) {
        toast.success(`Success! Group ( ${data?.id} ) has been updated.`);
        window.location.reload();
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const editInitialValues = () => {
    return {
      id: groupDetails?.id,
      active: groupDetails?.active,
      deleted: groupDetails?.deleted,
      isAnonymousPostAllowed: groupDetails?.isAnonymousPostAllowed,
      isAnonymousCommentAllowed: groupDetails?.isAnonymousCommentAllowed,

      name: groupDetails?.name,
      slug: groupDetails?.slug,
      description: groupDetails?.description,
      categories: groupDetails?.categories,

      image: groupDetails?.image || {},
      coverImage: groupDetails?.coverImage || {},
      language: LANGUAGE_ARRAY_WITH_STRING.find((t) => t.label.toLowerCase() === groupDetails?.language.toLowerCase())
        .value,
      // translations: !isEmpty(groupDetails?.translations) ? groupDetails?.translations[0] : {},
      seoTitle: groupDetails?.seo?.title || '',
      seoDescription: groupDetails?.seo?.description || '',
      seoKeywords: !isEmpty(groupDetails?.seo?.keywords)
        ? groupDetails?.seo?.keywords?.split(', ')?.map((e) => ({
            label: e,
            value: e,
          }))
        : '',
      ogImage: groupDetails?.seo?.ogImage || {},
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    isAnonymousPostAllowed: false,
    isAnonymousCommentAllowed: false,
    name: '',
    slug: '',
    description: '',
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
    categories: null,

    seoTitle: '',
    seoDescription: '',
    seoKeywords: '',
    ogImage: {},

    image: {},
    coverImage: {},
    // translations: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        isAnonymousPostAllowed: values?.isAnonymousPostAllowed,
        isAnonymousCommentAllowed: values?.isAnonymousCommentAllowed,
        name: values?.name,
        slug: values?.slug,
        description: values?.description,
        categories: !isEmpty(values?.categories) ? values?.categories?.map((e) => ({ id: e?.id })) : null,
        language: values?.language,
        // translations: !isEmpty(values?.translations) ? [values?.translations?.id] : [],
        seo: {
          title: !isEmpty(values?.seoTitle) ? values?.seoTitle : '',
          description: !isEmpty(values?.seoDescription) ? values?.seoDescription : '',
          keywords: !isEmpty(values?.seoKeywords) ? values?.seoKeywords?.map((e) => e?.value).join(', ') : '',
          ...(!isEmpty(values?.ogImage)
            ? {
                ogImageId: values?.ogImage?.id,
                ogImage: undefined,
              }
            : { ogImageId: null }),
        },
        ...(!isEmpty(values?.image)
          ? {
              imageId: values?.image?.id,
              image: undefined,
            }
          : { imageId: null, image: undefined }),
        ...(!isEmpty(values?.coverImage)
          ? {
              coverImageId: values?.coverImage?.id,
              coverImage: undefined,
            }
          : { coverImageId: null, coverImage: undefined }),

        seoTitle: undefined,
        seoDescription: undefined,
        seoKeywords: undefined,
        ogImage: undefined,
      };

      isEmpty(categoryId)
        ? addCategoryRequest({
            variables: {
              createDTO: {
                ...data,
              },
            },
          })
        : updateCategoryRequest({
            variables: {
              groupId: categoryId,
              createDTO: {
                ...data,
              },
            },
          });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(groupDetails)) {
      formik.setValues(editInitialValues());
    }
  }, [groupDetails]);

  useEffect(() => {
    if (!isEmpty(categoryId)) {
      getGroupDetailsRequest({
        variables: {
          groupId: categoryId,
        },
      });
    }
  }, [categoryId]);

  /* useEffect(() => {
    if (!isEmpty(formik.values.language)) {
      groupListRequest({
        variables: {
          searchDTO: {
            page: 1,
            size: 500,
            sortBy: 'id',
            sortOrder: 0,
            active: true,
            deleted: false,
          },
        },
      });
    }
  }, [formik.values.language]); */

  return (
    <div className="page-content">
      <Loader isActive={addCategoryLoading || getGroupDetailsLoading || updateCategoryLoading || groupListLoading} />
      <Container fluid>
        <Breadcrumbs
          title="Community Group"
          breadcrumbItem={`${isEmpty(categoryId) ? 'Add' : 'Edit'}  Community Group`}
        />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="name" className="form-label  ">
                          Name
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name.."
                          invalid={!!(formik.touched.name && formik.errors.name)}
                          {...formik.getFieldProps('name')}
                        />
                        <FormFeedback>{formik.errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12} className="mb-4">
                      <SlugInput
                        formik={formik}
                        ID={categoryId}
                        nameValue={formik.values.name}
                        formGroupClassName={'mt-0'}
                        showResetRegenerate={true}
                        currentSlug={groupDetails?.slug}
                      />
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="description" className="form-label  ">
                          Description
                        </Label>
                        <Input
                          id="description"
                          name="description"
                          style={{ height: '100px' }}
                          type="textarea"
                          className="form-control"
                          placeholder="Description.."
                          invalid={!!(formik.touched.description && formik.errors.description)}
                          {...formik.getFieldProps('description')}
                        />
                        <FormFeedback>{formik.errors.description}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <GroupCategoriesSearch formik={formik} />

              <SeoMetaData formik={formik} forType={MODULE_TYPE.FORUM} />
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <Button type="submit" color="primary" className="mb-3 w-100">
                    {categoryId ? 'Save' : 'Add'}
                  </Button>

                  <Button
                    className="w-100"
                    color="light"
                    onClick={() => {
                      history.replace(SMKLinks.COMMUNITY_GROUPS_LIST);
                    }}>
                    Cancel
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {['active', 'deleted', 'isAnonymousPostAllowed', 'isAnonymousCommentAllowed']?.map((elem, index) => (
                    <FormGroup className={`mb-3`} key={index}>
                      <Label className="mb-3">
                        {elem === 'isAnonymousPostAllowed'
                          ? 'isAnonymous Post Allowed'
                          : elem === 'isAnonymousCommentAllowed'
                          ? 'isAnonymous Comment Allowed'
                          : titleCaseIfExists(elem)}
                      </Label>
                      <div className="d-flex align-items-center">
                        {[true, false].map((option, i) => (
                          <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                            <input
                              type="radio"
                              id={`${elem}_${option}`}
                              name={elem}
                              className="form-check-input"
                              checked={formik.values[elem] === option}
                              onChange={() => formik.setFieldValue(`${elem}`, option)}
                            />
                            <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                  ))}
                </CardBody>
              </Card>

              <Card className="border">
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <CardTitle className="mb-3">Language </CardTitle>
                      {LANGUAGE_ARRAY_WITH_STRING?.map((option, i) => (
                        <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`language_${option?.value}`}
                            name="language"
                            className="form-check-input"
                            checked={formik.values.language === option?.value}
                            onChange={() => formik.setFieldValue('language', option?.value)}
                          />
                          <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                            {option?.label}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <AttachmentImageUploader
                forType={MODULE_TYPE.FORUM}
                formik={formik}
                fieldName={'image'}
                label={'Select Image'}
              />
              <AttachmentImageUploader
                forType={MODULE_TYPE.FORUM}
                formik={formik}
                fieldName={'coverImage'}
                label={'Select Cover Image'}
              />
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddUpdateGroups;
