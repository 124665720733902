import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
// Import Breadcrumb
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import moment from 'moment';
import ArticleTable from './ArticleTable';
import RecentArticleTable from './RecentArticleTable';
import ArticleMtdTable from './ArticleMtdTable';

const ArticleDashboard = (props) => {
  const [startDate, setStartDate] = useState(moment(new Date())?.subtract(1, 'month'));
  const [endDate, setEndDate] = useState(new Date());

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t('TPZ')} breadcrumbItem={props.t('Article Dashboard')} />

          <Row>
            <Col md={6}>
              <RecentArticleTable />
            </Col>
            <Col md={12}>
              <ArticleMtdTable />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <ArticleTable />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

ArticleDashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(ArticleDashboard);
