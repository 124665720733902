import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import TextEditor from 'components/TextEditor';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { API_BASE_URL, BABY_NAME_TYPE, LANGUAGE_ARRAY_WITH_STRING } from 'utils/constants';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { getTranslationLabel } from 'utils/helper';
import { PRINT_LANGUAGE } from '../../../utils/constants';
import PageDetailCard from 'components/PageDetailsCard';
import { useRouter } from 'hooks/useRouter';

const UpdateBabyNameMetaData = () => {
  const router = useRouter();
  const history = useHistory();
  const { id: babyNameMetaId } = useParams();

  const isTranslate =
    router?.asPath?.includes('/translate/meta-data') || router?.pathname?.includes('/translate/meta-data');

  toast.configure();
  const [babyNameDetails, setBabyNameDetails] = useState();
  const [babyNameList, setBabyNameList] = useState();
  const [enParentObj, setEnParentObj] = useState();
  const metDataTranslationParentObj = babyNameList?.filter((e) => e?.id === babyNameDetails?.enId);

  useEffect(() => {
    if (!isEmpty(metDataTranslationParentObj)) {
      setEnParentObj(metDataTranslationParentObj[0]);
    }
  }, [metDataTranslationParentObj]);

  const [getbabyNameMetaListRequest, { loading: getbabyNameMetaListLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      setBabyNameList(data?.results);
    },
  });

  const [addBabyNameRequest, { loading: addBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo`,
    method: 'POST',
    onSuccess: (data) => {
      if (data?.success === false || data?.error) {
        data?.error ? toast.error(data?.error) : toast.error(data?.message?.message);
      } else {
        const url = SMKLinks.BABY_NAME_META_DATA;
        history.replace(url);
        toast.success('Success! Baby Name meta data has been added.');
      }
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });
  const [getBabyNameRequest, { loading: getBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/${babyNameMetaId}`,
    method: 'GET',
    onSuccess: (data) => {
      setBabyNameDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      history.replace(SMKLinks.BABY_NAME_META_DATA);
    },
  });

  const [updateBabyNameRequest, { loading: updateBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/${babyNameMetaId}`,
    method: 'PUT',
    onSuccess: (data) => {
      if (data?.success === false || data?.error) {
        data?.error ? toast.error(data?.error) : toast.error(data?.message?.message);
      } else {
        const url = SMKLinks.BABY_NAME_META_DATA;
        history.replace(url);
        toast.success('Success! Baby Name meta data has been updated.');
      }
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => {
    const filteredTypeObj = BABY_NAME_TYPE?.find((e) => e?.value == babyNameDetails?.type);
    const filteredLanguageObj = isTranslate
      ? LANGUAGE_ARRAY_WITH_STRING?.find((e) => e?.value == 'hi')
      : LANGUAGE_ARRAY_WITH_STRING?.find((e) => e?.value == babyNameDetails?.language);

    return {
      active: babyNameDetails?.active,
      deleted: babyNameDetails?.deleted,
      popular: babyNameDetails?.popular,
      name: babyNameDetails?.name,
      slug: babyNameDetails?.slug,
      summary: !isEmpty(babyNameDetails?.summary) ? babyNameDetails?.summary : '',
      description: !isEmpty(babyNameDetails?.description) ? babyNameDetails?.description : '',
      content: !isEmpty(babyNameDetails?.content) ? babyNameDetails?.content : '',

      type: !isEmpty(filteredTypeObj) ? filteredTypeObj : null,
      language: !isEmpty(filteredLanguageObj) ? filteredLanguageObj : null,
      translations: !isEmpty(babyNameDetails?.translations) ? babyNameDetails?.translations[0] : {},
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    popular: false,
    name: '',
    slug: '',
    summary: '',
    description: '',
    content: '',
    type: '',
    language: LANGUAGE_ARRAY_WITH_STRING[0],
    translations: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(babyNameMetaId) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        popular: values?.popular,
        name: values?.name,
        slug: values?.slug,
        summary: values?.summary,
        description: values?.description,
        content: values?.content,
        type: values?.type?.value,
        language: values?.language?.value,
        translations: !isEmpty(values?.translations) ? [values?.translations?.id] : [],
      };
      if (isEmpty(babyNameMetaId)) {
        addBabyNameRequest({
          body: {
            ...data,
          },
        });
      } else if (isTranslate) {
        addBabyNameRequest({
          body: {
            ...data,
            translations: [Number(babyNameMetaId)],
          },
        });
      } else {
        updateBabyNameRequest({
          body: {
            ...data,
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Please provide babyname name')
        .min(2, 'Name should be at least 2 characters long')
        .max(128, 'Name should be at most 128 characters long'),
      type: Yup.object()
        .required('Please provide type')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(babyNameMetaId)) {
      getBabyNameRequest();
    }
  }, [babyNameMetaId]);

  useEffect(() => {
    getbabyNameMetaListRequest({
      body: {
        page: 1,
        size: 100000,
        sortBy: 'id',
        sortOrder: 0,
      },
    });
  }, []);

  return (
    <div className="page-content">
      <Loader
        isActive={addBabyNameLoading || getBabyNameLoading || updateBabyNameLoading || getbabyNameMetaListLoading}
      />
      <Container fluid>
        <Breadcrumbs
          title="Babynames"
          breadcrumbItem={`${isEmpty(babyNameMetaId) ? 'Add' : isTranslate ? 'Translate' : 'Edit'}  Babyname meta data`}
        />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="name" className="form-label  ">
                          Name
                        </Label>
                        <Input
                          id="name"
                          type="text"
                          className="form-control"
                          placeholder=" Name..."
                          label=" Name"
                          invalid={!!(formik.touched.name && formik.errors.name)}
                          {...formik.getFieldProps('name')}
                          error={!isEmpty(formik.errors.name)}
                        />
                        <FormFeedback>{formik.errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <SlugInput
                          formik={formik}
                          ID={babyNameMetaId}
                          nameValue={formik.values.name}
                          formGroupClassName={'mt-0'}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="type" className="form-label">
                          Type
                        </Label>
                        <Select
                          id="type"
                          options={BABY_NAME_TYPE}
                          value={formik.values.type}
                          onChange={(type) => formik.setFieldValue('type', type)}
                          isClearable
                        />
                        {formik.touched.type && <div className="invalid-feedback d-block">{formik.errors.type}</div>}
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="summary" className="form-label  ">
                          Summary
                        </Label>
                        <Input
                          id="summary"
                          name="summary"
                          style={{ height: '100px' }}
                          type="textarea"
                          className="form-control"
                          placeholder="Summary..."
                          invalid={!!(formik.touched.summary && formik.errors.summary)}
                          {...formik.getFieldProps('summary')}
                        />
                        <FormFeedback>{formik.errors.summary}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>

                  {/* {!isEmpty(enParentObj) && (
                    <div>
                      <span> ID : {enParentObj?.en?.id}</span>
                      <br />
                      <span> Name : {enParentObj?.en?.name}</span>
                      <br />
                      <span> Slug : {enParentObj?.en?.slug || '-'}</span>
                      <br />
                    </div>
                  )} */}
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="description" className="form-label  ">
                          Description
                        </Label>
                        <TextEditor
                          simple
                          height={150}
                          initialValue={formik.values?.description}
                          onChange={(val) => formik.setFieldValue('description', val)}
                        />

                        <FormFeedback>{formik.errors.description}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4 ">
                        <Label for="content" className="form-label  ">
                          Content
                        </Label>
                        <TextEditor
                          simple
                          height={150}
                          initialValue={formik.values?.content}
                          onChange={(val) => formik.setFieldValue('content', val)}
                        />

                        <FormFeedback>{formik.errors.content}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md={3}>
              <Card>
                <CardBody>
                  <div>
                    <Button type="submit" color="primary" className="w-100">
                      Save Name
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="w-100 mt-3"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.BABY_NAME_META_DATA);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </CardBody>
              </Card>

              <PageDetailCard data={babyNameDetails} />

              <Card>
                <CardBody>
                  <div className="border-bottom mb-3">
                    <CardTitle className="mb-3">Active </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`active_${i}`} className="form-check mb-2 d-inline-block me-4">
                        <input
                          type="radio"
                          id={`active_${option}`}
                          name="active"
                          className="form-check-input"
                          checked={formik.values.active === option}
                          onChange={() => formik.setFieldValue('active', option)}
                        />
                        <label className="form-check-label" htmlFor={`active_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="border-bottom mb-3">
                    <CardTitle className="mb-3">Delete </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`deleted_${i}`} className="form-check d-inline-block me-4">
                        <input
                          type="radio"
                          id={`deleted_${option}`}
                          name="deleted"
                          className="form-check-input"
                          checked={formik.values.deleted === option}
                          onChange={() => formik.setFieldValue('deleted', option)}
                        />
                        <label className="form-check-label" htmlFor={`deleted_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </div>

                  <CardTitle className="mb-3">Popular </CardTitle>
                  {[true, false].map((option, i) => (
                    <div key={`popular_${i}`} className="form-check d-inline-block me-4">
                      <input
                        type="radio"
                        id={`popular_${option}`}
                        name="popular"
                        className="form-check-input"
                        checked={formik.values.popular === option}
                        onChange={() => formik.setFieldValue('popular', option)}
                      />
                      <label className="form-check-label" htmlFor={`popular_${option}`}>
                        {option ? 'Yes' : 'No'}
                      </label>
                    </div>
                  ))}
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <FormGroup className="mb-4">
                    <Label for="language" className="form-label">
                      Language
                    </Label>
                    <Select
                      id="language"
                      isDisabled={isTranslate}
                      options={
                        isTranslate
                          ? LANGUAGE_ARRAY_WITH_STRING?.filter((e) => e?.value === 'hi')
                          : LANGUAGE_ARRAY_WITH_STRING
                      }
                      value={formik.values.language}
                      onChange={(language) => formik.setFieldValue('language', language)}
                    />
                    {formik.touched.language && (
                      <div className="invalid-feedback d-block">{formik.errors.language}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <CardTitle className="mb-2">Translation </CardTitle>
                    <SmkAsyncSelect
                      isDisabled={formik.values.language?.value === 'hi'}
                      acceptedKey={'name'}
                      acceptedValue={'id'}
                      placeholder="Type baby name.."
                      onChange={(res) => {
                        formik.setFieldValue('translations', res);
                      }}
                      fetchUrl={`${API_BASE_URL}/baby-name/admin/metaInfo/search`}
                      filters={{
                        autoComplete: true,
                        search: true,
                        active: true,
                        page: 1,
                        size: 500,
                        sortBy: 'id',
                        sortOrder: 0,
                        language: formik.values.language?.value === 'en' ? 'hi' : 'en',
                      }}
                      searchKeyName="name"
                      value={
                        !isEmpty(formik?.values?.translations)
                          ? {
                              label: getTranslationLabel(formik?.values?.translations),
                              value: formik?.values?.translations?.id,
                            }
                          : ''
                      }
                    />

                    {!isEmpty(formik?.values?.translations) && (
                      <div className="mt-3">
                        <div className="card bg-light">
                          <div className="card-body">
                            <a target="_blank" href={`/babynames/edit/meta-data/${formik?.values?.translations?.id}`}>
                              <p className="mb-1">
                                <span className="text-dark">Language:</span>{' '}
                                {PRINT_LANGUAGE[formik?.values?.translations?.language]}
                              </p>
                              <p className="mb-1">
                                <span className="text-dark">ID:</span> {formik?.values?.translations?.id}
                              </p>
                              <p className="mb-1">
                                <span className="text-dark">Title:</span>{' '}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: getTranslationLabel(formik?.values?.translations),
                                  }}
                                />
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    )}
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default UpdateBabyNameMetaData;
