import WorksheetList from './WorksheetsList';
import WorksheetListBasic from './WorksheetsList/worksheetBasic';
import CreateWorksheet from './CreateWorksheet';
import EditWorksheet from './EditWorksheet';
import WorksheetDetail from './WorksheetsDetail';
// import WorksheetEdit from './worksheets-edit';
import { USER_ROLE } from '../../utils/constants';
// import WorksheetCategories from './worksheets-categories';
// import WorksheetApprover from './worksheets-approver';
import WORKSHEETS_LINKS from './links';
import WorksheetsApprover from './WorksheetApprover';
import WorksheetCategories from './WorksheetCategories';
import SubjectTopic from './SubjectTopic';
import Subjects from './subjects';

export default [
  {
    path: WORKSHEETS_LINKS.WORKSHEET_LISTING,
    exact: true,
    component: WorksheetList,
  },
  {
    path: WORKSHEETS_LINKS.WORKSHEET_LISTING_BASIC,
    exact: true,
    component: WorksheetListBasic,
  },
  {
    path: WORKSHEETS_LINKS.WORKSHEET_SUBJECT_TOPIC,
    exact: true,
    component: SubjectTopic,
  },
  {
    path: WORKSHEETS_LINKS.WORKSHEET_SUBJECT,
    exact: true,
    component: Subjects,
  },
  {
    path: WORKSHEETS_LINKS.WORKSHEET_DETAIL,
    exact: true,
    component: WorksheetDetail,
  },
  {
    path: WORKSHEETS_LINKS.WORKSHEET_EDIT,
    exact: true,
    component: EditWorksheet,
  },
  {
    path: WORKSHEETS_LINKS.WORKSHEET_ADD,
    exact: true,
    component: CreateWorksheet,
  },
  {
    path: WORKSHEETS_LINKS.WORKSHEET_CATEGORIES,
    exact: true,
    component: WorksheetCategories,
  },
  {
    path: WORKSHEETS_LINKS.WORKSHEET_APPROVER,
    exact: true,
    component: WorksheetsApprover,
    role: [USER_ROLE.ADMIN],
  },
];
