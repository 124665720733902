import Loader from 'components/Loader';
import SitemapValidatorList from 'containers/others/SitemapValidatorList';
import useRequest from 'hooks/useRequest';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { titleCaseIfExists } from 'utils/helper';
import Select from 'react-select';

const SMKSitemap = () => {
  const [sitemapsList, setSitemapsList] = useState([]);
  const [showSitemapList, setShowSitemapList] = useState(false);
  const [isApproveSitemap, setIsApproveSitemap] = useState(false);
  const [showSitemapFilter, setShowSitemapFilter] = useState(null);

  toast.configure();

  const [getSitemapsListCall, { loading: getSitemapsListCallLoading }] = useRequest({
    url: '/admin-tools/sitemap/sitemap-url/schoolmykids/sitemaps',
    method: 'GET',
    onSuccess: (data) => {
      setSitemapsList(data);
    },
    onError: (err) => console.log(`Error occurred: ${err}`),
  });

  const [approveSitemapCall, { loading: approveSitemapCallLoading }] = useRequest({
    body: {},
    method: 'POST',
    onSuccess: () => {
      toast.success(`${titleCaseIfExists(activeTab)} Sitemap Approved Successfully`);
      setIsApproveSitemap(true);
    },
    onError: (err) => console.log(`Error occurred: ${err}`),
  });

  const [generateSitemapXMLCall, { loading: generateSitemapXMLCallLoading }] = useRequest({
    body: {},
    method: 'POST',
    onSuccess: () => {
      toast.success(`${titleCaseIfExists(activeTab)} Sitemap XML Generated Successfully`);
      setShowSitemapList(false);
    },
    onError: (err) => console.log(`Error occurred: ${err}`),
  });

  const [generateSitemapCall, { loading: generateSitemapCallLoading }] = useRequest({
    body: {},
    method: 'POST',
    onSuccess: () => {
      if (activeTab === 'forum' || activeTab === 'schoolmykids') {
        toast.success(`${titleCaseIfExists(activeTab)} Sitemap Generated Successfully`);
      } else {
        setShowSitemapList(true);
      }
    },
    onError: (err) => console.log(`Error occurred: ${err}`),
  });

  const tabsList = [
    { name: 'Calculator', slug: 'calculator' },
    { name: 'School', slug: 'school' },
    { name: 'Worksheet', slug: 'worksheet' },
    { name: 'Ptable', slug: 'ptable' },
    { name: 'Forum', slug: 'forum' },
    { name: 'Schoolmykids', slug: 'schoolmykids' },
  ];

  const [activeTab, setActiveTab] = useState(tabsList[0]?.slug);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setShowSitemapList(false);
      setIsApproveSitemap(false);
    }
  };

  const handleGenerateSitemap = () => {
    if (activeTab === 'forum' || activeTab === 'schoolmykids') {
      generateSitemapCall({
        url: `/admin-tools/sitemap/${activeTab}${activeTab !== 'schoolmykids' ? '/schoolmykids' : ''}`,
      });
    } else {
      generateSitemapCall({ url: `/admin-tools/sitemap/${activeTab}` });
    }
  };

  const handleApproveSitemap = () => {
    approveSitemapCall({
      url: `/admin-tools/sitemap/${activeTab}/approve`,
    });
  };
  const handleGenerateSitemapXML = () => {
    generateSitemapXMLCall({
      url: `/admin-tools/sitemap/${activeTab}/generate-xml`,
    });
  };

  useEffect(() => {
    getSitemapsListCall();
  }, []);

  const handleSitemapClick = (option) => {
    setShowSitemapList(true);
    setShowSitemapFilter({ sitemap: option?.value, ...option });
  };

  return (
    <div className="page-content" style={{ minHeight: '70vh' }}>
      <Loader
        isActive={
          generateSitemapCallLoading ||
          generateSitemapXMLCallLoading ||
          approveSitemapCallLoading ||
          getSitemapsListCallLoading
        }
      />
      <Container fluid>
        <Breadcrumbs title="System" breadcrumbItem="Generate Schoolmykids Sitemap" />
        <div>
          <Nav tabs>
            {tabsList.map((tab) => (
              <NavItem key={tab.slug}>
                <NavLink
                  className={`${activeTab === tab.slug ? 'active' : ''}`}
                  onClick={() => {
                    toggle(tab.slug);
                  }}>
                  <span style={activeTab === tab.slug ? { fontWeight: 600 } : {}}> {tab.name}</span>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {tabsList.map((tab) => (
              <TabPane tabId={tab.slug} key={tab.slug}>
                <div className="mt-4">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <h4 className="mb-0 me-4">{tab.name} Sitemap</h4>
                      <div style={{ width: 300 }}>
                        <Select
                          options={sitemapsList
                            ?.filter((elem) => elem?.sitemap?.split('/schoolmykids/')[1]?.startsWith(activeTab))
                            .map((elem, index) => ({
                              value: elem?.sitemap,
                              label: `${elem?.sitemap?.split('/schoolmykids/')[1]?.split('/')[1]}`,
                            }))}
                          value={showSitemapFilter}
                          onChange={(option) => {
                            handleSitemapClick(option);
                          }}
                          placeholder="Select a sitemap..."
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: 'white',
                            }),
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <div className="d-flex align-items-center justify-content-end mt-3">
                        {showSitemapList ? (
                          <>
                            {isApproveSitemap ? (
                              <Button color="success" onClick={handleGenerateSitemapXML}>
                                Generate XML
                              </Button>
                            ) : (
                              <Button color="warning" onClick={handleApproveSitemap}>
                                Approve Sitemap
                              </Button>
                            )}
                          </>
                        ) : (
                          <Button color="primary" onClick={handleGenerateSitemap}>
                            {tab.slug === 'schoolmykids' || tab.slug === 'forum' ? 'Generate' : 'Create'} Sitemap
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </TabPane>
            ))}
          </TabContent>
        </div>

        {showSitemapList && (
          <SitemapValidatorList
            showSitemapFilter={showSitemapFilter}
            showSitemapList={showSitemapList}
            source={'schoolmykids'}
            currentModule={activeTab}
          />
        )}
      </Container>
    </div>
  );
};

export default SMKSitemap;
