/*
 *
 * App actions
 *
 */

import { createRoutine } from 'redux-saga-routines';
import { SHOW_CIRCULAR_LOADER, SET_GLOBAL_ERROR, RESET_APP, USER_SEARCH_LIST } from './constants';

export const showCircularLoaderAction = createRoutine(SHOW_CIRCULAR_LOADER);
export const resetAppData = createRoutine(RESET_APP);
export const setGlobalError = createRoutine(SET_GLOBAL_ERROR);
export const getUserSearchList = createRoutine(USER_SEARCH_LIST);
