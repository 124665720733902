import { Divider } from '@material-ui/core';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Paginate from 'components/Paginate';
import TableError from 'components/TableError';
import { useFormik } from 'formik';
import { useRouter } from 'hooks/useRouter';
import { invert, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import {
  ACTIVE_AND_DELETED_ARRAY,
  LANGUAGE_ARRAY_WITH_STRING,
  LANGUAGE_VALUES_STRING,
  MODULE_TYPE,
  PRINT_LANGUAGE,
  PRINT_LANGUAGE_COLOR,
  SORT_VALUES,
} from 'utils/constants';
import { buildURL, checkIfValueExist, printDate, titleCaseIfExists } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../../../components/Loader';
import useRequest from '../../../../hooks/useRequest';

const TagsList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [tagsPagination, settagsPagination] = useState({});
  const [tagsList, settagsList] = useState();

  const [tagsListRequest, { loading: tagsListLoading }] = useRequest({
    method: 'POST',
    url: `/common/admin/tag/search`,
    onSuccess: (data) => {
      settagsList(data?.results);
      settagsPagination(data?.paginator);
    },
  });

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Tag has been ${data?.active ? 'active' : 'Inactive'}.`);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Tag has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const defaultPayload = {
    page: 1,
    size: 20,
    language: LANGUAGE_VALUES_STRING?.english,
    forType: {
      label: 'Forum',
      value: MODULE_TYPE.FORUM,
    },
    ...SORT_VALUES,
  };

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    tagsListRequest({
      body: {
        page: page,
        size: 50,
        language: defaultPayload?.language,
        forType: defaultPayload?.forType?.value,
        ...SORT_VALUES,
      },
    });
  };

  const applyFilters = (obj) => {
    setPageInUrl(1);

    tagsListRequest({
      body: {
        page: 1,
        size: 50,
        ...SORT_VALUES,
        active: checkIfValueExist(obj?.active?.value),
        deleted: checkIfValueExist(obj?.deleted?.value),
        forType: checkIfValueExist(obj?.forType?.value),
        language: obj?.language,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      active: { value: null, label: 'All' },
      deleted: { value: null, label: 'All' },
      language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
      forType: {
        label: 'Forum',
        value: MODULE_TYPE?.FORUM,
      },
    },
    onSubmit: (values) => {
      applyFilters(values);
    },
  });

  const clearFilters = () => {
    setPageInUrl(1);
    formik.resetForm();

    tagsListRequest({
      body: {
        ...defaultPayload,
        forType: defaultPayload?.forType?.value,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const handleActive = (data) => {
    markActiveInActiveRequest({
      url: `/common/admin/tag/${data?.id}`,
      body: {
        active: data?.active ? false : true,
      },
    });
  };
  const handleDelete = (data) => {
    deleteUnDeleteRequest({
      url: `/common/admin/tag/${data?.id}`,
      body: {
        deleted: data?.deleted ? false : true,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={tagsListLoading || markActiveInActiveLoading || deleteUnDeleteLoading} />
        <Container fluid>
          <Breadcrumbs title="Others" breadcrumbItem="Tags List" />
          <Card>
            <CardBody>
              <Form onSubmit={formik.handleSubmit}>
                <div className="mt-4">
                  <div className="row" style={{ gap: '20px' }}>
                    <div className="col-lg-2 mb-4">
                      <Label className="form-label">Active</Label>
                      <Select
                        options={ACTIVE_AND_DELETED_ARRAY}
                        value={formik?.values?.active}
                        onChange={(active) => formik.setFieldValue('active', active)}
                      />
                    </div>

                    <div className="col-lg-2 mb-4">
                      <Label className="form-label">Deleted</Label>
                      <Select
                        options={ACTIVE_AND_DELETED_ARRAY}
                        value={formik?.values?.deleted}
                        onChange={(deleted) => formik.setFieldValue('deleted', deleted)}
                      />
                    </div>
                    <div className="col-lg-2 mb-4">
                      <label className="form-label">Language</label>
                      <Select
                        options={LANGUAGE_ARRAY_WITH_STRING}
                        value={LANGUAGE_ARRAY_WITH_STRING?.find((t) => t.value == formik?.values?.language)}
                        onChange={(e) => formik.setFieldValue('language', e?.value)}
                      />
                    </div>
                    <div className="col-lg-2 mb-4">
                      <label className="form-label">For Type</label>
                      <Select
                        options={Object.keys(MODULE_TYPE)?.map((e) => ({
                          label: titleCaseIfExists(e),
                          value: MODULE_TYPE[e],
                        }))}
                        value={formik?.values?.forType}
                        onChange={(elem) => formik.setFieldValue('forType', elem)}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <button className="btn btn-primary me-4" type="submit">
                    Apply Filters
                  </button>
                  <a className="" type="reset" onClick={clearFilters}>
                    Clear
                  </a>
                </div>
              </Form>
            </CardBody>
          </Card>

          <Row className="mb-2  align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2">
                <div className="position-relative font-bold">{tagsPagination?.totalCount} Tags Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_TAGS}>
                  <i className="mdi mdi-plus me-1" />
                  Add Tag
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">ID</th>
                        <th scope="col">For Type</th>
                        <th scope="col">Name / Slug</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Language</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(tagsList) ? (
                        tagsList?.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>{titleCaseIfExists(invert(MODULE_TYPE)[data?.forType])}</td>
                            <td style={{ maxWidth: 500 }}>
                              <h5 className="text-truncate font-size-14">
                                <Link
                                  to={buildURL(SMKLinks.EDIT_TAGS, {
                                    id: data?.id,
                                  })}
                                  className="text-primary">
                                  {data?.name}
                                </Link>
                              </h5>
                              {data?.slug}
                            </td>
                            <td>{printDate(data?.createdDate)}</td>
                            <td>
                              <Badge className={PRINT_LANGUAGE_COLOR[data?.language]}>
                                {PRINT_LANGUAGE[data?.language]}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleActive(data)}>
                                    <i
                                      className={`fas  ${
                                        data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                      }   m-1 pe-3`}
                                    />
                                    {data?.active ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                  <DropdownItem onClick={() => handleDelete(data)}>
                                    <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                    {data?.deleted ? 'Undelete' : 'Delete'}
                                  </DropdownItem>
                                  <Divider className="my-2" />

                                  <Link
                                    to={buildURL(SMKLinks.EDIT_TAGS, {
                                      id: data?.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={tagsPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default TagsList;
