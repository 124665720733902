// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
// Import Date Picker
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { userSelector } from '../../../common/globalComponents/selectors';
import { useRouter } from '../../../hooks/useRouter';
import { getSchoolDetail, getSchoolsMasterData } from '../actions';
import { schoolDetailSelector } from '../selectors';
import AcademicInfo from './components/academicInfo';
import AdditionalInfo from './components/additionalInfo';
import AdmissionInfo from './components/admissionInfo';
import BasicInfo from './components/basicInfo';
import ContactDetails from './components/contactDetails';
import ExtraCurricularDetails from './components/extraCurricularDetails';
import FacilityInfraDetails from './components/facilityInfraDetails';
import FeeDetails from './components/feeDetails';
import SystemInfo from './components/systemInfo';
import SchoolSummaryBlock from './schoolSummaryBlock';
import SchoolDetailReviews from './components/reviews';
import SchoolImages from './components/schoolImages';

const stateSelector = createStructuredSelector({
  schoolDetail: schoolDetailSelector,
  user: userSelector,
});

const EditSchool = () => {
  const router = useRouter();

  const { schoolDetail, user } = useSelector(stateSelector);

  const [selectedTab, setSelectedTab] = useState(1);
  const { id: schoolId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSchoolDetail.trigger({ id: schoolId }));

    dispatch(getSchoolsMasterData.trigger());

    if (router?.query?.section) {
      switch (router.query.section) {
        case 'basic':
          setSelectedTab(1);
          break;

        case 'contact-details':
          setSelectedTab(2);
          break;

        case 'admission-info':
          setSelectedTab(3);
          break;

        case 'academic-info':
          setSelectedTab(5);
          break;

        case 'fees-details':
          setSelectedTab(4);
          break;

        case 'infra-details':
          setSelectedTab(6);
          break;

        case 'extra-curricular-details':
          setSelectedTab(7);
          break;

        case 'additional-info':
          setSelectedTab(8);
          break;

        case 'system-info':
          setSelectedTab(9);
          break;

        case 'reviews':
          setSelectedTab(10);
          break;

        case 'images':
          setSelectedTab(11);
          break;

        default:
          // basic
          setSelectedTab(1);
          break;
      }
    } else {
      // basic
      setSelectedTab(1);
    }
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Schools" breadcrumbItem="Edit School" />

          {!isEmpty(schoolDetail) && <SchoolSummaryBlock />}

          <Row>
            <Col lg={3}>
              <Card>
                <CardBody className="p-4">
                  <div>
                    <h5 className="text-muted">School Details</h5>

                    <ul className="list-unstyled font-weight-medium">
                      <li>
                        <Link
                          to={`/schools/edit/${schoolDetail?.id}/basic`}
                          replace
                          onClick={() => setSelectedTab(1)}
                          className={`${selectedTab === 1 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Basic Info
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/schools/edit/${schoolDetail?.id}/contact-details`}
                          replace
                          onClick={() => setSelectedTab(2)}
                          className={`${selectedTab === 2 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Contact Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/schools/edit/${schoolDetail?.id}/admission-info`}
                          replace
                          onClick={() => setSelectedTab(3)}
                          className={`${selectedTab === 3 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Admission Info
                        </Link>
                      </li>

                      <li>
                        <Link
                          to={`/schools/edit/${schoolDetail?.id}/academic-info`}
                          replace
                          onClick={() => setSelectedTab(5)}
                          className={`${selectedTab === 5 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Academic Info
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/schools/edit/${schoolDetail?.id}/fees-details`}
                          replace
                          onClick={() => setSelectedTab(4)}
                          className={`${selectedTab === 4 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Fees Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/schools/edit/${schoolDetail?.id}/infra-details`}
                          replace
                          onClick={() => setSelectedTab(6)}
                          className={`${selectedTab === 6 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Facility & Infra Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/schools/edit/${schoolDetail?.id}/extra-curricular-details`}
                          replace
                          onClick={() => setSelectedTab(7)}
                          className={`${selectedTab === 7 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Extra Curricular Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/schools/edit/${schoolDetail?.id}/additional-info`}
                          replace
                          onClick={() => setSelectedTab(8)}
                          className={`${selectedTab === 8 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Additional Info
                        </Link>
                      </li>
                      {user.entityPermissions?.school.publish && (
                        <li>
                          <Link
                            to={`/schools/edit/${schoolDetail?.id}/system-info`}
                            replace
                            onClick={() => setSelectedTab(9)}
                            className={`${selectedTab === 9 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                            <i className="mdi mdi-chevron-right me-1" /> System Info
                          </Link>
                        </li>
                      )}
                      <li>
                        <Link
                          to={`/schools/edit/${schoolDetail?.id}/reviews`}
                          replace
                          onClick={() => setSelectedTab(10)}
                          className={`${selectedTab === 10 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Reviews
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/schools/edit/${schoolDetail?.id}/images`}
                          replace
                          onClick={() => setSelectedTab(11)}
                          className={`${selectedTab === 11 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Images
                        </Link>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>Additional data goes here, like Google Object mapping, etc...</CardBody>
              </Card>
            </Col>
            {!isEmpty(schoolDetail) && (
              <Col lg={9}>
                {selectedTab === 1 && <BasicInfo />}
                {selectedTab === 2 && <ContactDetails />}
                {selectedTab === 3 && <AdmissionInfo />}
                {selectedTab === 4 && <FeeDetails />}
                {selectedTab === 5 && <AcademicInfo />}
                {selectedTab === 6 && <FacilityInfraDetails />}
                {selectedTab === 7 && <ExtraCurricularDetails />}
                {selectedTab === 8 && <AdditionalInfo />}
                {selectedTab === 9 && user.entityPermissions?.school.publish && <SystemInfo />}
                {selectedTab === 10 && <SchoolDetailReviews schoolId={schoolDetail?.id} />}
                {selectedTab === 11 && <SchoolImages schoolId={schoolDetail?.id} />}
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EditSchool;
