import React, { useEffect, Fragment, useState } from 'react';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, CardTitle, Input, FormFeedback } from 'reactstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
// actions
import { SMKLinks } from 'utils/links';
import { useHistory } from 'react-router-dom';
import { schoolClearCache } from 'containers/school/schoolDetailsHelper';
import { getSchoolBoards, updateSchoolFeeDetails } from '../../actions';
// selectors
import { selectSchoolsMasterData, selectSchoolBoards, schoolDetailSelector } from '../../selectors';
// constants
import { SeniorSecondaryStreams, SchoolMasterType } from '../../../../utils/constants';
// components
import TextEditor from '../../../../components/TextEditor';
import { htmlDecode } from '../../../../utils/helper';
import Loader from '../../../../components/Loader';
import useRequest from '../../../../hooks/useRequest';
import { userSelector } from '../../../../common/globalComponents/selectors';

const stateSelector = createStructuredSelector({
  schoolsMasterData: selectSchoolsMasterData,
  schoolBoards: selectSchoolBoards,
  schoolDetail: schoolDetailSelector,
  user: userSelector,
});

const FeeDetails = () => {
  const { user, schoolsMasterData, schoolBoards, schoolDetail } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const [feeInfo, setFeeInfo] = useState(schoolDetail?.admissionInfo?.feeInfo);

  const getInitialValues = () => {
    const { admissionInfo } = schoolDetail;
    const values = {};

    values.feeDetails = admissionInfo?.feeDetails || '';
    values.feeInfo = admissionInfo?.feeInfo || [];
    values.feeSheetUrl = admissionInfo?.feeSheetUrl || '';

    return values;
  };

  const [updateFeeDetailsRequest, { loading: updateFeeDetailsLoading }] = useRequest({
    url: '/school/admin/edit/updateFeeDetails',
    method: 'POST',
    onSuccess: (data) => {
      schoolClearCache({
        urlSlug: schoolDetail?.slug,
        schoolId: schoolDetail?.id,
      });

      window.location.reload();
    },
  });

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit(values) {
      // TODO: Get values from each attribute of 'values'

      // board: string;
      // level: string;
      // seniorStream: string;
      // boardingType: string;
      // studentType: string;
      // feeType: string;
      // fees: number;
      // feeFrequency: string;

      updateFeeDetailsRequest({
        data: {
          schoolId: schoolDetail.id,
          feeInfo: {
            ...values,
            feeSheetUrl: values?.feeSheetUrl,
            feeInfo: feeInfo
              .filter((fi) => fi.board || fi.level || fi.seniorStream)
              .map((fi) => ({
                board: fi.board?.name,
                level: fi.level?.value,
                seniorStream: fi.seniorStream?.value,
                boardingType: fi.boardingType?.name,
                studentType: fi.studentType?.value,
                feeType: fi.feeType?.value,
                fees: fi.fees,
                feeFrequency: fi.feeFrequency?.value,
              })),
          },
        },
      });
    },
    validationSchema: Yup.object().shape({
      feeDetails: Yup.string().nullable(),
      feeInfo: Yup.array(),
    }),
  });

  useEffect(() => {
    if (isEmpty(schoolBoards)) {
      dispatch(getSchoolBoards.trigger());
      return;
    }

    const res = [];
    schoolDetail.admissionInfo?.feeInfo?.forEach((fi) => {
      const board = schoolBoards.find((b) => b.name === fi.board) || {};
      const level = board?.subLevels.find((l) => l.name === fi.level) || {};
      res.push({
        board,
        level: { ...level, value: level.name, label: level.name },
        seniorStream: { value: fi.seniorStream, label: fi.seniorStream },
        boardingType: schoolsMasterData.find(
          (d) => d.type === SchoolMasterType.BOARDING_TYPE && d.name === fi.boardingType
        ),
        studentType: fi.studentTypee,
        feeType: fi.feeType,
        fees: fi.fees,
        feeFrequency: fi.feeFrequency,
      });
    });

    // prepopulate data else initialize with 1 empty row
    // if (isEmpty(res)) {
    //   res.push({
    //     board: '',
    //     level: '',
    //     seniorStream: '',
    //     boardingType: '',
    //     studentType: '',
    //     feeType: '',
    //     fees: '',
    //     feeFrequency: '',
    //   });
    // }
    // console.log({ array: res });

    setFeeInfo(res);
    // formik.setFieldValue('feeInfo', res);
  }, [schoolBoards]);

  const handleUpdateFeeInfo = (index, key, value) => {
    const array = feeInfo.slice();
    array[index] = { ...array[index], [key]: value };
    // console.log({ array, schoolBoards });
    setFeeInfo(array);
    // formik.setFieldValue('feeInfo', array);
  };

  const handleAddFeeInfo = () => {
    let array = feeInfo.slice();
    array = [
      ...array,
      {
        board: '',
        level: '',
        seniorStream: '',
        boardingType: '',
        studentType: '',
        feeType: '',
        fees: '',
        feeFrequency: '',
      },
    ];
    // console.log({ array });
    setFeeInfo(array);
    //  formik.setFieldValue('feeInfo', array);
  };

  const handleRemoveFeeInfo = (index) => {
    const array = feeInfo.slice();
    array.splice(index, 1);
    // console.log({ array });
    setFeeInfo(array);
    //  formik.setFieldValue('feeInfo', array);
  };

  const renderFeeInfo = () => {
    // board: string;
    // level: string;
    // seniorStream: string;
    // boardingType: string;
    // studentType: string;
    // feeType: string;
    // fees: number;
    // feeFrequency: string;

    return (
      <>
        <CardTitle className="mb-4">Fees Information</CardTitle>

        {feeInfo?.map((fee, i) => (
          <Fragment key={`person_${i}`}>
            <Row>
              <Col md={3}>
                <FormGroup className="mb-4">
                  <Label htmlFor="fee_board" className="form-label  ">
                    Board
                  </Label>
                  <Select
                    id="fee_board"
                    placeholder="Select Board..."
                    options={schoolBoards}
                    value={fee.board}
                    onChange={(val) => handleUpdateFeeInfo(i, 'board', val)}
                  />
                  {/* {!isEmpty(formik.errors.feeInfo) && formik.values.feeInfo[i]?.showBoardError && (
                  <div className="invalid-feedback d-block">{formik.errors.feeInfo[i]?.board}</div>
                )} */}
                </FormGroup>
              </Col>

              <Col md={3}>
                <FormGroup className="mb-4">
                  <Label htmlFor="fee_level" className="form-label  ">
                    Level
                  </Label>
                  <Select
                    id="fee_level"
                    placeholder="Select Level..."
                    // isDisabled={isEmpty(schoolBoards.find((b) => b.id === fee?.board?.id)?.subLevels)}
                    options={schoolDetail?.schoolLevels
                      ?.map((sl) => {
                        return sl?.classes?.map((l) => ({
                          value: l.name,
                          label: l.name,
                        }));
                      })
                      .flat()}
                    value={fee.level}
                    onChange={(val) => handleUpdateFeeInfo(i, 'level', val)}
                  />
                  {/* {!isEmpty(formik.errors.feeInfo) && formik.values.feeInfo[i]?.showLevelError && (
                  <div className="invalid-feedback d-block">{formik.errors.feeInfo[i]?.level}</div>
                )} */}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="mb-4">
                  <Label htmlFor="fee_stream" className="form-label  ">
                    Senior Stream
                  </Label>
                  <Select
                    id="fee_stream"
                    placeholder="Select Stream..."
                    options={SeniorSecondaryStreams.map((str) => ({
                      value: str.name,
                      label: str.name,
                    }))}
                    value={fee.seniorStream}
                    onChange={(val) => handleUpdateFeeInfo(i, 'seniorStream', val)}
                  />
                  {/* {!isEmpty(formik.errors.feeInfo) && formik.values.feeInfo[i]?.showSeniorStreamError && (
                  <div className="invalid-feedback d-block">{formik.errors.feeInfo[i]?.seniorStream}</div>
                )} */}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="mb-4">
                  <Label htmlFor="fee_boarding" className="form-label  ">
                    Boarding Type
                  </Label>
                  <Select
                    id="fee_boarding"
                    placeholder="Select Boarding..."
                    options={schoolsMasterData.filter((d) => d.type === SchoolMasterType.BOARDING_TYPE)}
                    value={fee.boardingType}
                    onChange={(val) => handleUpdateFeeInfo(i, 'boardingType', val)}
                  />
                  {/* {!isEmpty(formik.errors.feeInfo) && formik.values.feeInfo[i]?.showBoardingTypeError && (
                  <div className="invalid-feedback d-block">{formik.errors.feeInfo[i]?.boardingType}</div>
                )} */}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <FormGroup className="mb-4">
                  <Label htmlFor="fee_student_type" className="form-label  ">
                    Student Type
                  </Label>
                  <Select
                    id="fee_student_type"
                    placeholder="Select an option..."
                    options={[
                      { value: 'National', label: 'National' },
                      { value: 'International', label: 'International' },
                    ]}
                    value={fee.studentType}
                    onChange={(val) => handleUpdateFeeInfo(i, 'studentType', val)}
                  />
                  {/* {!isEmpty(formik.errors.feeInfo) && formik.values.feeInfo[i]?.showStudentTypeError && (
                  <div className="invalid-feedback d-block">{formik.errors.feeInfo[i]?.studentType}</div>
                )} */}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="mb-4">
                  <Label htmlFor="fee_type" className="form-label  ">
                    Fee Type
                  </Label>
                  <Select
                    id="fee_type"
                    placeholder="Select an option..."
                    options={[
                      { value: 'TutionFee', label: 'Tution fee' },
                      {
                        value: 'BooksFee',
                        label: 'Books, Stationary and Uniform',
                      },
                      { value: 'TransportFee', label: 'Transport Fee' },
                      {
                        value: 'InfrastructureFee',
                        label: 'Infrastructure Fee',
                      },
                      {
                        value: 'AdmissionFee',
                        label: 'Admission/Readmission Fee',
                      },
                      { value: 'LabFee', label: 'Lab Fee' },
                      { value: 'ActivityFee', label: 'Activity Fee' },
                    ]}
                    value={fee.feeType ? { value: fee.feeType, label: fee.feeType } : {}}
                    onChange={(val) => handleUpdateFeeInfo(i, 'feeType', val)}
                  />
                  {/* {!isEmpty(formik.errors.feeInfo) && formik.values.feeInfo[i]?.showFeeTypeError && (
                  <div className="invalid-feedback d-block">{formik.errors.feeInfo[i]?.feeType}</div>
                )} */}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="mb-4">
                  <Label htmlFor="fee_frequency" className="form-label  ">
                    Frequency
                  </Label>
                  <Select
                    id="fee_frequency"
                    placeholder="Select Frequency..."
                    options={[
                      { value: 'Monthly', label: 'Monthly' },
                      { value: 'Quarterly', label: 'Quarterly' },
                      { value: 'Bi-Yearly', label: 'Bi-Yearly' },
                      { value: 'Yearly', label: 'Yearly' },
                    ]}
                    value={fee.feeFrequency ? { value: fee.feeFrequency, label: fee.feeFrequency } : {}}
                    onChange={(val) => handleUpdateFeeInfo(i, 'feeFrequency', val)}
                  />
                  {/* {!isEmpty(formik.errors.feeInfo) && formik.values.feeInfo[i]?.showFeeFrequencyError && (
                  <div className="invalid-feedback d-block">{formik.errors.feeInfo[i]?.feeFrequency}</div>
                )} */}
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="mb-4">
                  <Label htmlFor="fee_amount" className="form-label  ">
                    Fee
                  </Label>

                  <Input
                    id="fee_amount"
                    type="number"
                    className="form-control"
                    rows="3"
                    placeholder="Amount..."
                    value={fee.fees}
                    onChange={(e) => handleUpdateFeeInfo(i, 'fees', e.target.value)}
                  />
                  {/* {!isEmpty(formik.errors.feeInfo) && formik.values.feeInfo[i]?.showFeesError && (
                  <div className="invalid-feedback d-block">{formik.errors.feeInfo[i]?.fees}</div>
                )} */}
                </FormGroup>
              </Col>
              <Col sm={12}>
                <FormGroup className="mb-4">
                  <Button color="danger" className="mt-2 w-100" onClick={() => handleRemoveFeeInfo(i)}>
                    <i className="bx bx-trash" /> Delete
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Fragment>
        ))}

        <Row>
          <Col>
            <Button className="w-100 mt-3" color="light" onClick={handleAddFeeInfo}>
              <i className="bx bx-plus me-2" />
              Add Information
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateFeeDetailsLoading} />

      <h5 className="mb-4">Fees Details</h5>

      <Card>
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="schooldesc" className="form-label ">
              Fees Details
            </Label>
            <TextEditor
              simple
              initialValue={htmlDecode(formik.values.feeDetails)}
              onChange={(val) => formik.setFieldValue('feeDetails', val)}
            />
            {formik.touched.feeDetails && <div className="invalid-feedback d-block">{formik.errors.feeDetails}</div>}
          </FormGroup>
          <Col md={12}>
            <FormGroup className="mb-4">
              <Label for="feeSheetUrl" className="form-label  ">
                Fee Details PDF URL
              </Label>
              <Input
                id="feeSheetUrl"
                type="text"
                className="form-control"
                rows="3"
                placeholder="URL..."
                invalid={!!(formik.touched.feeSheetUrl && formik.errors.feeSheetUrl)}
                {...formik.getFieldProps('feeSheetUrl')}
              />
              <FormFeedback>{formik.errors.feeSheetUrl}</FormFeedback>
            </FormGroup>
          </Col>
          {renderFeeInfo()}
        </CardBody>
      </Card>

      {(user?.entityPermissions?.school.admin ||
        (schoolDetail.editable &&
          (schoolDetail?.createdBy?.id === user.id || schoolDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Information
          </Button>
          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.SCHOOL_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

export default FeeDetails;
