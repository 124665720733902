import { PUBLIC_URL } from '../../utils/constants';

export default {
  ADD_TAGS: `${PUBLIC_URL}/tag/add`,
  EDIT_TAGS: `${PUBLIC_URL}/tag/edit/:id`,
  TAGS_LIST: `${PUBLIC_URL}/tags/list`,

  FEATURE_BANNER_LIST: `${PUBLIC_URL}/featureBanner/list`,
  ADD_FEATURE_BANNER: `${PUBLIC_URL}/featureBanner/add`,
  EDIT_FEATURE_BANNER: `${PUBLIC_URL}/featureBanner/edit/:id`,

  API_CACHE_LIST: `${PUBLIC_URL}/api-cache/list`,
  CDN_CACHE_LIST: `${PUBLIC_URL}/cdn-cache/list`,
  SEO_CHECKER: `${PUBLIC_URL}/others/seo-checker`,

  PAGEVIEW: `${PUBLIC_URL}/analytics/pageview`,
  WORKSHEET_DOWNLOAD: `${PUBLIC_URL}/analytics/worksheet-download`,
  BABY_TRACKING_ACTIVITY: `${PUBLIC_URL}/analytics/baby-tracking-activity`,

  HEARTBEATS: `${PUBLIC_URL}/heartbeats`,
  SEND_NOTIFICATINS: `${PUBLIC_URL}/send-notifications`,

  APP_BANNERS_LISTING: `${PUBLIC_URL}/app-banners/list`,
  APP_BANNERS_ADD: `${PUBLIC_URL}/app-banners/add`,
  APP_BANNERS_EDIT: `${PUBLIC_URL}/app-banners/edit/:id`,

  SUBSCRIPTION_PLANS: `${PUBLIC_URL}/subscription-plans`,
  ADD_SUBSCRIPTION_PLAN: `${PUBLIC_URL}/subscription-plan/add`,
  EDIT_SUBSCRIPTION_PLAN: `${PUBLIC_URL}/subscription-plan/edit/:id`,

  SUBSCRIPTION_OFFERS: `${PUBLIC_URL}/subscription-offers`,
  ADD_SUBSCRIPTION_OFFER: `${PUBLIC_URL}/subscription-offer/add`,
  EDIT_SUBSCRIPTION_OFFER: `${PUBLIC_URL}/subscription-offer/edit/:id`,

  // Media
  MEDIA_LISTING: `${PUBLIC_URL}/media/list`,
  ADD_MEDIA: `${PUBLIC_URL}/media/add`,

  // HR
  JOB_APPLICATIONS_LISTING: `${PUBLIC_URL}/job-applications/list`,
  JOB_APPLICATIONS_EDIT: `${PUBLIC_URL}/job-applications/edit/:id`,
};
