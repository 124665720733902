import { useMutation } from '@apollo/client';
import Loader from 'components/Loader';
import SmkAsyncSelectGraphql from 'components/SmkAsyncSelect/customSelectGraphql';
import TableError from 'components/TableError';
import { NUTRITIONS_GRAPHQL_URL } from 'lib/apollo-constant';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { FOOD_ITEMS_SEARCH } from '../graphql/food_item.query';
import {
  ADD_RECIPE_INGREDIENTS,
  DELETE_RECIPE_INGREDIENTS,
  UPDATE_RECIPE_INGREDIENTS,
} from '../graphql/recipe.mutation';
import SubstituteCard from './SubstituteCard';

const IngredientsCard = ({ formik, recipeId, allIngredients }) => {
  toast.configure();

  const defaultIngredient = {
    isOptional: false,
    group: '',
    quantity: '',
    quantityUnit: '',
    preparationState: '',
    condition: '',
    substitutes: [],
  };

  const [ingredient, setIngredient] = useState(defaultIngredient);

  const [showAddIngredient, setShowAddIngredient] = useState(false);
  const [selectedFoodItem, setSelectedFoodItem] = useState();
  const [editIngredientId, setEditIngredientId] = useState('');

  const [addRecipeIngredientRequest, { loading: addRecipeIngredientLoading }] = useMutation(ADD_RECIPE_INGREDIENTS, {
    ...NUTRITIONS_GRAPHQL_URL,
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_nutrition_recipe_ingredient_add)) {
        toast.success(`Success! Recipe Ingredient has been added.`);
        window.location.reload();
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [updateRecipeIngredientRequest, { loading: updateRecipeIngredientLoading }] = useMutation(
    UPDATE_RECIPE_INGREDIENTS,
    {
      ...NUTRITIONS_GRAPHQL_URL,
      onCompleted: (data) => {
        if (!isEmpty(data?.admin_nutrition_recipe_ingredient_update)) {
          toast.success(`Success! Recipe Ingredient has been updated.`);
          window.location.reload();
        }
      },
      onError: () => {
        toast.error('Something went wrong!');
      },
    }
  );

  const [deleteRecipeIngredientRequest, { loading: deleteRecipeIngredientLoading }] = useMutation(
    DELETE_RECIPE_INGREDIENTS,
    {
      ...NUTRITIONS_GRAPHQL_URL,
      onCompleted: (data) => {
        if (data?.admin_nutrition_recipe_ingredient_delete) {
          toast.success(`Success! Recipe Ingredient has been deleted.`);
          window.location.reload();
        }
      },
      onError: () => {
        toast.error('Something went wrong!');
      },
    }
  );

  // Add Ingredient in Listing
  const handleSubmit = () => {
    const payload = {
      recipeId,
      foodItemId: selectedFoodItem?.id,
      isOptional: ingredient?.isOptional,
      group: ingredient?.group,
      quantity: ingredient?.quantity,
      quantityUnit: ingredient?.quantityUnit,
      preparationState: ingredient?.preparationState,
      condition: ingredient?.condition,
      substitutes: ingredient?.substitutes.map((sub) => ({
        id: sub?.id,
        name: sub?.name,
      })),
    };

    if (editIngredientId) {
      updateRecipeIngredientRequest({
        variables: {
          recipeIngredientId: editIngredientId,
          recipeIngredient: payload,
        },
      });
    } else {
      addRecipeIngredientRequest({
        variables: {
          recipeIngredient: payload,
        },
      });
    }
  };

  // Delete Ingredient in Listing
  const handleDelete = (elem) => {
    deleteRecipeIngredientRequest({
      variables: {
        recipeIngredientId: elem?.id,
      },
    });
  };

  return (
    <div className="mb-5">
      <Loader isActive={addRecipeIngredientLoading || updateRecipeIngredientLoading || deleteRecipeIngredientLoading} />
      <Card>
        <CardBody>
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div className="text-start">
              <h5 className="mb-2">{allIngredients.length} ingredients</h5>
              <h6 className="mb-0">Ingredients must be for {formik.values.servesCount} servings</h6>
            </div>
            <Button
              color="success"
              onClick={() => {
                setShowAddIngredient(true);
              }}>
              Add New
            </Button>
          </div>

          <div className="table-responsive mt-3" style={{ minHeight: 300 }}>
            <Table className="project-list-table   table-centered">
              <thead>
                <tr className="table-warning">
                  <th>S.No.</th>
                  <th>Group</th>
                  <th>Ingredient</th>
                  <th>Quantity</th>
                  <th>Preparation State</th>
                  <th>Condition</th>
                  <th>Substitutes</th>
                  <th>isOptional</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!isEmpty(allIngredients) ? (
                  allIngredients?.map((elem, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{elem?.group}</td>
                        <td>{elem?.foodItem?.name}</td>
                        <td>
                          {elem?.quantity} {elem?.quantityUnit}
                        </td>
                        <td>{elem?.preparationState}</td>
                        <td>{elem?.condition}</td>
                        <td>
                          {elem?.substitutes?.map((e) => (
                            <badge className="badge bg-dark me-3 h6 p-1">{e?.name}</badge>
                          ))}
                        </td>
                        <td>
                          <Badge className={elem?.isOptional ? 'bg-success' : 'bg-danger'}>
                            {elem?.isOptional ? 'Yes' : 'No'}
                          </Badge>
                        </td>
                        <td>
                          <UncontrolledDropdown>
                            <DropdownToggle href="#" className="card-drop" tag="i">
                              <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem
                                onClick={() => {
                                  setShowAddIngredient(true);
                                  setSelectedFoodItem(elem?.foodItem);
                                  setEditIngredientId(elem?.id);
                                  setIngredient(elem);
                                }}>
                                <i className="fas fa-pencil-alt text-success m-1 pe-3" />
                                Edit
                              </DropdownItem>
                              <DropdownItem onClick={() => handleDelete(elem)}>
                                <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <TableError />
                )}
              </tbody>
            </Table>
          </div>

          {showAddIngredient && (
            <>
              <div className="bg-light p-4 rounded-2 mb-4">
                <div className="mb-3">
                  <CardTitle className="mb-2">Food Item</CardTitle>
                  <SmkAsyncSelectGraphql
                    isClearable={false}
                    acceptedKey="name"
                    acceptedValue="id"
                    placeholder="Type food item name..."
                    searchKeyName="name"
                    query={FOOD_ITEMS_SEARCH}
                    queryName="admin_nutrition_foodItem_search"
                    dtoName="searchDTO"
                    filters={{
                      autoComplete: true,
                      active: true,
                      page: 1,
                      size: 500,
                      sortBy: 'id',
                      sortOrder: 0,
                      language: formik.values.language,
                    }}
                    value={
                      !isEmpty(selectedFoodItem)
                        ? {
                            label: selectedFoodItem?.name,
                            value: selectedFoodItem?.slug,
                          }
                        : null
                    }
                    onChange={(data) => {
                      setSelectedFoodItem(data);
                    }}
                  />
                </div>
              </div>

              {!isEmpty(selectedFoodItem?.name) && (
                <Form className="mb-3 border p-4 rounded-2">
                  <Row>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="group">Group</Label>
                        <Input
                          type="text"
                          name="group"
                          placeholder="Enter group"
                          value={ingredient.group}
                          onChange={(e) =>
                            setIngredient({
                              ...ingredient,
                              group: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="quantity">Quantity</Label>
                        <Input
                          type="number"
                          name="quantity"
                          placeholder="Enter quantity"
                          value={ingredient.quantity}
                          onChange={(e) =>
                            setIngredient({
                              ...ingredient,
                              quantity: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="quantityUnit">Quantity Unit</Label>
                        <Input
                          type="text"
                          name="quantityUnit"
                          placeholder="Enter quantity unit"
                          value={ingredient.quantityUnit}
                          onChange={(e) =>
                            setIngredient({
                              ...ingredient,
                              quantityUnit: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="preparationState">Preparation State</Label>
                        <Input
                          type="text"
                          name="preparationState"
                          placeholder="Enter preparation state"
                          value={ingredient.preparationState}
                          onChange={(e) =>
                            setIngredient({
                              ...ingredient,
                              preparationState: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="condition">Condition</Label>
                        <Input
                          type="text"
                          name="condition"
                          placeholder="Enter condition"
                          value={ingredient.condition}
                          onChange={(e) =>
                            setIngredient({
                              ...ingredient,
                              condition: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup check className={`mb-4 `}>
                        <Label check>
                          <Input
                            type="checkbox"
                            name="isOptional"
                            checked={ingredient.isOptional}
                            onChange={(e) =>
                              setIngredient({
                                ...ingredient,
                                isOptional: e.target.checked,
                              })
                            }
                          />
                          Is Optional
                        </Label>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <div className="bg-light rounded-2 p-3">
                        <SubstituteCard
                          className={'bg-light p-0'}
                          onChange={(res) =>
                            setIngredient({
                              ...ingredient,
                              substitutes: res,
                            })
                          }
                          initialValues={ingredient.substitutes}
                          filters={{
                            language: formik.values.language,
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}

              <Button type="button" color="primary" className="mt-4" onClick={handleSubmit}>
                {editIngredientId ? 'Save' : 'Add'} Ingredient
              </Button>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default IngredientsCard;
