import { USER_ROLE } from '../../utils/constants';
import FORUM_LINKS from './links';
import PostsList from './Post/postsList';
import TopicsList from './Topic/topicsList';
import PostDetail from './Post/postDetail';
import TopicDetail from './Topic/topicDetail';
import CategoryList from './Category/categoryList';
import TopicEdit from './Topic/topicEdit';
import PostEdit from './Post/postEdit';
import UpdateCategory from './Category/updateCategory';

const FORUM_ROUTES = [
  //---------- Forum Topic  ------------
  {
    path: FORUM_LINKS.TOPIC_LIST,
    exact: true,
    component: TopicsList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: FORUM_LINKS.TOPIC_DETAIL,
    exact: true,
    component: TopicDetail,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: FORUM_LINKS.TOPIC_EDIT,
    exact: true,
    component: TopicEdit,
    role: [USER_ROLE.ADMIN],
  },

  //----------  Forum Post ------------
  {
    path: FORUM_LINKS.POST_LIST,
    exact: true,
    component: PostsList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: FORUM_LINKS.POST_DETAIL,
    exact: true,
    component: PostDetail,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: FORUM_LINKS.POST_EDIT,
    exact: true,
    component: PostEdit,
    role: [USER_ROLE.ADMIN],
  },

  //---------- Forum Category  ------------
  {
    path: FORUM_LINKS.CATEGORY_LIST,
    exact: true,
    component: CategoryList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: FORUM_LINKS.CATEGORY_ADD,
    exact: true,
    component: UpdateCategory,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: FORUM_LINKS.CATEGORY_EDIT,
    exact: true,
    component: UpdateCategory,
    role: [USER_ROLE.ADMIN],
  },
];

export { FORUM_ROUTES, FORUM_LINKS };
