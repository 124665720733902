import SmkAsyncSelectGraphql from 'components/SmkAsyncSelect/customSelectGraphql';
import { isEmpty } from 'lodash';
import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { htmlDecode } from 'utils/helper';
import { FOOD_ITEMS_SEARCH } from '../graphql/food_item.query';

const MultipleSearch = ({ formik, label }) => {
  const handleSelectChange = (res) => {
    const current_data = !isEmpty(formik?.values?.[label]) ? formik?.values?.[label] : [];

    const isAlreadySelected = current_data?.some((item) => item?.id === res?.id);

    if (!isAlreadySelected) {
      const updatedData = [
        ...current_data,
        {
          name: res?.name,
          id: res?.id,
        },
      ];
      formik.setFieldValue(label, updatedData);
    }
  };

  const handleRemoveItem = (id) => {
    const updated_data = formik.values?.[label]?.filter((item) => item?.id !== id);
    formik.setFieldValue(label, updated_data);
  };

  return (
    <Card>
      <CardBody>
        <div className="mb-3">
          <CardTitle className="mb-3">Substitutes</CardTitle>
          <SmkAsyncSelectGraphql
            acceptedKey={'name'}
            acceptedValue={'id'}
            placeholder="Type Food item name.."
            searchKeyName="name"
            query={FOOD_ITEMS_SEARCH}
            queryName={'admin_nutrition_foodItem_search'}
            dtoName={'searchDTO'}
            filters={{
              autoComplete: true,
              active: true,
              page: 1,
              size: 500,
              sortBy: 'id',
              sortOrder: 0,
              language: formik.values.language,
            }}
            onChange={handleSelectChange}
          />
        </div>

        {!isEmpty(formik?.values?.[label]) &&
          formik.values?.[label]?.map((item, index) => (
            <div
              key={item.id}
              className="bg-light rounded-2 p-3 d-flex justify-content-between align-items-center mt-3  ">
              <h6 className="mb-0">
                {index + 1}. {htmlDecode(item.name)} {!isEmpty(item.slug) ? `- ( ${htmlDecode(item.slug)} )` : ''}
              </h6>
              <button className="btn btn-md btn-danger" type="button" onClick={() => handleRemoveItem(item.id)}>
                Remove
              </button>
            </div>
          ))}
      </CardBody>
    </Card>
  );
};

export default MultipleSearch;
