import Loader from 'components/Loader';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { Card, CardBody, Col, Row } from 'reactstrap';
import useRequest from 'hooks/useRequest';
import { useRouter } from 'hooks/useRouter';

const BlogPreviousVersion = () => {
  const router = useRouter();
  const articleId = router?.query?.articleId;
  const versionId = router?.query?.versionId;

  const [prevBlogVesionDetails, setPrevBlogVesionDetails] = useState({});
  const [blogVesionDetails, setBlogVesionDetails] = useState({});

  const [getPrevBlogVesionDetails, { loading: getPrevBlogVesionDetailsLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setPrevBlogVesionDetails(data);
    },
  });

  const [getBlogVesionDetails, { loading: getBlogVesionDetailsLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setBlogVesionDetails(data);
    },
  });

  useEffect(() => {
    if (versionId > 1) {
      getBlogVesionDetails({
        url: `/article/admin/${articleId}/version-details/${+versionId - 1}`,
      });
    }
    getPrevBlogVesionDetails({
      url: `/article/admin/${articleId}/version-details/${versionId}`,
    });
  }, [versionId, articleId]);

  const renderWithHighlight = (prev, current) => {
    return (
      <pre>
        {Object.entries(current).map(([key, value]) => {
          const isChanged = prev[key] !== value;
          return (
            <div key={key}>
              <strong>{key}:</strong>{' '}
              <span style={{ color: isChanged ? 'red' : 'black' }}>{JSON.stringify(value)}</span>
            </div>
          );
        })}
      </pre>
    );
  };

  return (
    <div className="page-content add-blog-page">
      <Loader isActive={getBlogVesionDetailsLoading || getPrevBlogVesionDetailsLoading} />
      <div className="mx-3">
        <Breadcrumbs title={`Blog`} breadcrumbItem={`Comparing Blog Versions`} />
        <Row>
          <Col md={6}>
            <Card>
              <CardBody>
                <h5> Previous Version: {+versionId - 1}</h5>
                <pre>{JSON.stringify(prevBlogVesionDetails, null, 2)}</pre>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card>
              <CardBody>
                <h5>Current Version: {versionId}</h5>
                {renderWithHighlight(prevBlogVesionDetails, blogVesionDetails)}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BlogPreviousVersion;
