import Loader from 'components/Loader';
import useRequest from 'hooks/useRequest';
import { isEmpty, map } from 'lodash';
import MiniCards from 'components/MiniCard/mini-card';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Container,
  Table,
  UncontrolledDropdown,
  Row,
  Col,
  CardTitle,
} from 'reactstrap';
import {
  BabyNameGenderValue,
  LANGUAGE_ARRAY,
  LANGUAGE_VALUES,
  GET_USER_ROLE,
  GET_USER_SOURCE,
  POST_STATUS_STING,
} from 'utils/constants';
import { buildURL, printDateTime, printDateTimeTwoLines, titleCaseIfExists } from 'utils/helper';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { toast } from 'react-toastify';
import { SMKLinks } from 'utils/links';
import { Divider } from '@material-ui/core';

const PostDetail = () => {
  const { id: postId } = useParams();

  const [postDetails, setpostDetails] = useState();
  toast.configure();

  const [getpostDetails, { loading: getpostDetailsLoading }] = useRequest({
    url: `/forum/admin/post/search`,
    method: 'POST',
    body: {
      id: postId,
    },
    onSuccess: (data) => {
      setpostDetails(data?.results[0]);
    },
    onError: (err) => {
      toast.error(err);
    },
  });

  const [markApproveAndUnpublishRequest, { loading: markApproveAndUnpublishLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      getpostDetails();
      toast.success(`Success! Post has been ${data?.status !== 0 ? 'UnPublished' : 'Approved'}.`);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      getpostDetails();
      toast.success(`Success!  Post has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  useEffect(() => {
    getpostDetails();
  }, []);

  const postStatusColors = {
    0: 'primary',
    1: 'warning',
    2: 'success',
    3: 'danger',
  };
  const postStatusIcons = {
    0: 'bx-file',
    1: 'bx bxs-error',
    2: 'bx-check-shield',
    3: 'bxs-shield-x',
  };

  const miniCards = [
    {
      title: 'Post ID',
      iconClass: 'bx-file',
      text: `${postDetails?.id}` || '-',
    },
    {
      title: 'Status',
      iconClass: `${postStatusIcons[postDetails?.status]}`,
      iconBgColor: `bg-${postStatusColors[postDetails?.status]}`,
      text: `${POST_STATUS_STING[postDetails?.status]}`,
      textColor: `text-${postStatusColors[postDetails?.status]}`,
    },
    {
      title: 'Deleted',
      iconClass: `${postDetails?.deleted ? 'bxs-trash' : 'bxs-trash'}`,
      iconBgColor: `${postDetails?.deleted ? 'bg-success' : 'bg-danger'}`,
      text: `${postDetails?.deleted ? 'Yes' : 'No'}`,
      textColor: `${postDetails?.deleted ? 'text-success' : 'text-danger'}`,
    },
  ];

  const handleApprove = (data) => {
    data?.status !== 1
      ? markApproveAndUnpublishRequest({
          url: `/forum/admin/post/unpublish`,
          body: {
            id: data?.id,
          },
        })
      : markApproveAndUnpublishRequest({
          url: `/forum/admin/post/approve`,
          body: {
            id: data?.id,
            status: 2,
          },
        });
  };

  const handleDelete = (data) => {
    deleteUnDeleteRequest({
      url: `/forum/admin/post/delete`,
      body: {
        id: data?.id,
        deleted: !data?.deleted,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getpostDetailsLoading || markApproveAndUnpublishLoading || deleteUnDeleteLoading} />
        <Container fluid>
          <Breadcrumbs title="Post" breadcrumbItem="Post detail" />

          {!isEmpty(postDetails) && (
            <>
              <Card>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-end pt-3">
                    <h3 className="mb-0">
                      {` (${postDetails?.id})  `} {postDetails?.topic?.title}
                    </h3>
                    <UncontrolledDropdown style={{ zIndex: '9999' }}>
                      <DropdownToggle href="#" className="card-drop cursor-pointer" tag="i">
                        <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem onClick={() => handleApprove(postDetails)}>
                          <i
                            className={`fas ${
                              postDetails?.status !== 1 ? 'fa-trash-alt text-danger' : 'fa-power-off text-success'
                            }     m-1 pe-3`}
                          />
                          {postDetails?.status !== 1 ? 'Unpublish' : 'Approve'}
                        </DropdownItem>
                        <DropdownItem onClick={() => handleDelete(postDetails)}>
                          <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                          {postDetails?.deleted ? 'Undelete' : 'Delete'}
                        </DropdownItem>
                        <Divider className="my-2" />

                        <Link
                          to={buildURL(SMKLinks.POST_EDIT, {
                            id: postDetails?.id,
                          })}>
                          <DropdownItem>
                            <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                            Edit
                          </DropdownItem>
                        </Link>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>

                  <CardTitle
                    className="mt-4 text-muted  font-weight-normal"
                    dangerouslySetInnerHTML={{
                      __html: postDetails?.content,
                    }}
                  />
                </CardBody>
              </Card>
            </>
          )}

          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <CardTitle className="mb-2 ms-2 ">Created By</CardTitle>
                  <div className="table-responsive" style={{ minHeight: 50 }}>
                    <Table className="table-nowrap mb-0">
                      {!isEmpty(postDetails?.createdBy) && (
                        <tbody>
                          <tr>
                            <th>ID</th>
                            <td> {postDetails?.createdBy?.id} </td>
                          </tr>
                          <tr>
                            <th> Full Name</th>
                            <td>{postDetails?.createdBy?.displayName}</td>
                          </tr>
                          <tr>
                            <th> Email</th>
                            <td>{postDetails?.createdBy?.email}</td>
                          </tr>
                          <tr>
                            <th> Is App Installed</th>
                            <td>
                              <Badge className={postDetails?.createdBy?.isAppInstalled ? 'bg-success' : 'bg-danger'}>
                                {postDetails?.createdBy?.isAppInstalled ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card?.title}
                    text={card?.text}
                    textColor={card?.textColor}
                    iconClass={card?.iconClass}
                    iconBgColor={card?.iconBgColor}
                    key={`_card_${key}`}
                  />
                ))}

                <Col xl="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-2 ms-2 ">More Details</CardTitle>
                      <div className="table-responsive" style={{ minHeight: 50 }}>
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th>Language</th>
                              <td> {LANGUAGE_ARRAY[postDetails?.language]?.label} </td>
                            </tr>
                            <tr>
                              <th>Approved By Id</th>
                              <td> {postDetails?.approvedById} </td>
                            </tr>
                            <tr>
                              <th> Like Count</th>
                              <td>{postDetails?.likeCount}</td>
                            </tr>
                            <tr>
                              <th> Dislike Count</th>
                              <td>{postDetails?.dislikeCount}</td>
                            </tr>
                            <tr>
                              <th> View Count</th>
                              <td>{postDetails?.viewCount}</td>
                            </tr>

                            <tr>
                              <th> Is Featured</th>
                              <td>
                                <Badge className={postDetails?.isFeatured ? 'bg-success' : 'bg-danger'}>
                                  {postDetails?.isFeatured ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                            </tr>
                            <tr>
                              <th> Is Expert Answer</th>
                              <td>
                                <Badge className={postDetails?.isExpertAnswer ? 'bg-success' : 'bg-danger'}>
                                  {postDetails?.isExpertAnswer ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                            </tr>
                            <tr>
                              <th> Active</th>
                              <td>
                                <Badge className={postDetails?.active ? 'bg-success' : 'bg-danger'}>
                                  {postDetails?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                            </tr>

                            <tr>
                              <th> Comments</th>
                              <td style={{ whiteSpace: 'normal' }}>{postDetails?.comments}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>

            {/* <Col xl="12">
              <Card>
                <CardBody>
                  {
                    !isEmpty(postDetails?.topic?.category) ? (
                      <>
                        <CardTitle className="mb-4">Category  </CardTitle>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive" style={{ minHeight: 100 }}>

                              <Table className=" table-nowrap table-centered table-borderless">
                                <thead>
                                  <tr className='bg-light'>
                                    <th>ID</th>
                                    <th>Name</th>
                                    <th>slug</th>
                                    <th>Language</th>
                                    <th>Post <br />Count</th>
                                    <th>Topic <br />Count</th>
                                    <th>Follow <br />Count</th>
                                    <th>View <br />Count</th>
                                    <th>Frontend <br />App Name</th>


                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>{postDetails?.topic?.category?.id}</td>
                                    <td>{postDetails?.topic?.category?.name}</td>
                                    <td>{postDetails?.topic?.category?.slug}</td>
                                    <td>{LANGUAGE_VALUES[postDetails?.topic?.category?.language]}</td>
                                    <td>{postDetails?.topic?.category?.postCount}</td>
                                    <td>{postDetails?.topic?.category?.topicCount}</td>
                                    <td>{postDetails?.topic?.category?.followCount}</td>
                                    <td>{postDetails?.topic?.category?.viewCount}</td>
                                    <td>{postDetails?.topic?.category?.frontend_app_name}</td>
                                  </tr>

                                </tbody>
                              </Table>

                            </div>
                          </Col>
                        </Row>
                      </>
                    )
                      : (
                        <div style={{ minHeight: 230 }} className="d-flex justify-content-center align-items-center">
                          <div className='text-center'>
                            <i className='bx bxs-error' style={{ fontSize: '70px', color: 'orange' }} />
                            <h5 className='mt-3'>No Category Found!</h5>
                          </div>

                        </div>
                      )
                  }
                </CardBody>
              </Card>
            </Col>
            {
              !isEmpty(postDetails?.topic?.subCategories) && (
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <CardTitle className="mb-4">Sub Categories </CardTitle>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive" style={{ minHeight: 100 }}>
                            <Table className=" table-nowrap table-centered table-borderless">

                              <thead>
                                <tr className='bg-light'>
                                  <th>ID</th>
                                  <th>Name</th>
                                  <th>slug</th>
                                  <th>Language</th>
                                  <th>Post Count</th>
                                  <th>Topic Count</th>
                                  <th>Follow Count</th>
                                  <th>View Count</th>
                                  <th>Frontend App Name</th>
                                  <th>Created Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  postDetails?.topic?.subCategories?.map((elem, index) => (
                                    <tr key={index}>
                                      <td>{elem?.id}</td>
                                      <td>{elem?.name}</td>
                                      <td>{elem?.slug}</td>
                                      <td>{LANGUAGE_VALUES[elem?.language]}</td>
                                      <td>{elem?.postCount}</td>
                                      <td>{elem?.topicCount}</td>
                                      <td>{elem?.followCount}</td>
                                      <td>{elem?.viewCount}</td>
                                      <td>{elem?.frontend_app_name}</td>
                                      <td>{printDateTimeTwoLines(elem?.createdDate)}</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </Table>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )
            } */}
          </Row>
        </Container>
      </div>
    </>
  );
};

PostDetail.propTypes = {
  currentPost: PropTypes.object,
};

export default PostDetail;
