// Import Breadcrumb
import { Divider } from '@material-ui/core';
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { printDate } from '../../../utils/helper';

const GroupMasterList = (props) => {
  const [ptable, setPtable] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showGroupEdit, setShowGroupEdit] = useState(false);
  const [selectedGroupMaster, setSelectedGroupMaster] = useState({});

  const [sort, setSort] = useState({
    sortBy: 'atomic_number',
    sortOrder: 1,
  });

  const [getPtableGroupMasterListRequest, { loading: getPtableGroupMasterListLoading }] = useRequest({
    url: `/ptable/admin/getGroupMaster`,
    method: 'GET',
    onSuccess: (data) => {
      // console.log(data);
      setPtable(data?.sort((a, b) => a.new_iupac - b.new_iupac));
    },
  });

  const [putGroupMasterListRequest, { loading: putGroupMasterListLoading }] = useRequest({
    url: `/ptable/admin/updateGroupMaster/${selectedGroupMaster?.id}`,
    method: 'PUT',
    onSuccess: (data) => {
      setShowGroupEdit(false);
      // console.log(data);
      // const result = [...ptable.filter((it) => it.id === data.id), ...data];
      // setPtable(result);
      getPtableGroupMasterListRequest();
    },
  });

  const [toggleEnableGroupRequest, { loading: toggleEnableGroupLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      getPtableGroupMasterListRequest();
      // console.log(data);
      // setPtable([data?.group, ...ptable.filter((u) => u.id !== data?.group?.id)]);
    },
  });

  const [toggleDeletedGroupRequest, { loading: toggleDeletedGroupLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      getPtableGroupMasterListRequest();
      // console.log(data);
      // setPtable([data?.group, ...ptable.filter((u) => u.id !== data?.group?.id)]);
    },
  });

  useEffect(() => {
    getPtableGroupMasterListRequest();
  }, []);

  const toggleEnable = (data) => {
    toggleEnableGroupRequest({
      url: `/ptable/admin/updateGroupMaster/${data?.id}`,
      body: {
        id: data?.id,
        approved: !data?.approved,
      },
    });
  };
  const toggleDeleted = (data) => {
    toggleDeletedGroupRequest({
      url: `/ptable/admin/updateGroupMaster/${data?.id}`,
      body: {
        id: data?.id,
        deleted: !data?.deleted,
      },
    });
  };

  const handleEditGroupMaster = (e) => {
    e.preventDefault();
    const cas = e.target.cas.value;
    const groupName = e.target.groupName.value;
    const trivialName = e.target.trivialName.value;
    const newIupac = e.target.newIupac.value;
    const oldIupac = e.target.oldIupac.value;
    const notes = e.target.notes.value;
    // console.log(cas, groupName, trivialName);
    putGroupMasterListRequest({
      body: {
        cas,
        group_name: groupName,
        new_iupac: parseInt(newIupac, 10),
        trivial_name: trivialName,
        old_iupac: oldIupac,
        notes,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getPtableGroupMasterListLoading ||
            toggleDeletedGroupLoading ||
            toggleEnableGroupLoading ||
            putGroupMasterListLoading
          }
        />

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="PTable" breadcrumbItem="Group Masters List" />

          <Row className="mb-2  align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2">
                <div className="position-relative font-bold">{ptable?.length} Group Masters Found</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div>
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '100px' }}>
                          #Id
                        </th>
                        <th scope="col">CAS</th>
                        <th scope="col">Group Name</th>
                        <th scope="col">Trivial Name</th>
                        <th scope="col">New Iupac</th>
                        <th scope="col">Old Iupac</th>
                        {/* <th scope="col" style={{ width: '10%' }}>
                          Notes
                        </th> */}
                        <th scope="col">Version</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Updated Date</th>
                        <th scope="col">Approved</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(ptable) ? (
                        ptable?.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>{data?.cas}</td>
                            <td>{data?.group_name}</td>
                            <td>{data?.trivial_name}</td>
                            <td>{data?.new_iupac}</td>
                            <td>{data?.old_iupac}</td>
                            <td>{data?.version}</td>
                            <td>{printDate(data?.createdDate)}</td>
                            <td>{printDate(data?.updatedDate)}</td>
                            <td>
                              <Badge className={data?.approved ? 'bg-success' : 'bg-danger'}>
                                {data?.approved ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop" tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem
                                    onClick={() => {
                                      setShowGroupEdit(true);
                                      setSelectedGroupMaster(data);
                                    }}>
                                    <i className="fas fa-edit text-success me-2 fa-fw" />
                                    Edit GroupMaster
                                  </DropdownItem>
                                  <Divider className="my-2" />

                                  <DropdownItem onClick={() => toggleEnable(data)}>
                                    <i
                                      className={`fas 
                                    ${data?.approved ? 'fa-trash text-danger' : 'fa-power-off text-success'}
                                    me-2 fa-fw`}
                                    />
                                    {data?.approved ? 'Disable' : 'Enable'}
                                  </DropdownItem>

                                  <DropdownItem onClick={() => toggleDeleted(data)}>
                                    <i
                                      className={`fas fa-trash 
                                    ${data?.deleted ? 'text-success' : 'text-danger'}
                                    me-2 fa-fw`}
                                    />
                                    {data?.deleted ? 'Undeleted' : 'Deleted'}
                                  </DropdownItem>

                                  {/* <Divider className="my-2" /> */}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={showGroupEdit}
            role="dialog"
            autoFocus
            centered
            className="exampleModal"
            tabIndex="-1"
            toggle={() => {
              setShowGroupEdit(false);
              setSelectedGroupMaster(null);
            }}>
            <div className="modal-content">
              <ModalHeader
                className="modalHeader"
                toggle={() => {
                  setShowGroupEdit(false);
                  setSelectedGroupMaster(null);
                }}>
                Edit Group Master
              </ModalHeader>
              <Form onSubmit={(e) => handleEditGroupMaster(e)}>
                <ModalBody>
                  <FormGroup className="mb-4">
                    <Label htmlFor="cas" className="form-label  ">
                      CAS
                    </Label>
                    <Input
                      id="cas"
                      type="text"
                      className="form-control"
                      placeholder="CAS"
                      defaultValue={selectedGroupMaster?.cas}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label htmlFor="groupName" className="form-label  ">
                      Group Name
                    </Label>
                    <Input
                      id="groupName"
                      type="text"
                      className="form-control"
                      placeholder="Group Name"
                      defaultValue={selectedGroupMaster?.group_name}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label htmlFor="trivialName" className="form-label  ">
                      Trivial Name
                    </Label>
                    <Input
                      id="trivialName"
                      type="text"
                      className="form-control"
                      placeholder="Trivial Name"
                      defaultValue={selectedGroupMaster?.trivial_name}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label htmlFor="newIupac" className="form-label  ">
                      New Iupac
                    </Label>
                    <Input
                      id="newIupac"
                      type="text"
                      className="form-control"
                      placeholder="New Iupac"
                      defaultValue={selectedGroupMaster?.new_iupac}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label htmlFor="oldIupac" className="form-label  ">
                      Old Iupac
                    </Label>
                    <Input
                      id="oldIupac"
                      type="text"
                      className="form-control"
                      placeholder="Old Iupac"
                      defaultValue={selectedGroupMaster?.old_iupac}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label htmlFor="notes" className="form-label  ">
                      Notes
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="notes"
                      rows="3"
                      placeholder="Notes..."
                      defaultValue={selectedGroupMaster?.notes}
                    />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button type="submit" color="success">
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default GroupMasterList;
