import { all, fork } from 'redux-saga/effects';

// public
// import AccountSaga from "./auth/register/saga"
import GlobalSaga from '../common/globalComponents/saga';
import AuthSaga from '../containers/authentication/saga';
import SchoolsSaga from '../containers/school/saga';
import UserSaga from '../containers/user/saga';
import ContactInquirySaga from '../containers/inquiry/saga';
import WorksheetSaga from '../containers/worksheets/saga';
import ForumSaga from '../containers/forum/saga';
// import ForgetSaga from "./auth/forgetpwd/saga"
// import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from './layout/saga';
import ecommerceSaga from './e-commerce/saga';
import calendarSaga from './calendar/saga';
import chatSaga from './chat/saga';
import cryptoSaga from './crypto/saga';
import invoiceSaga from './invoices/saga';
import projectsSaga from './projects/saga';
import tasksSaga from './tasks/saga';
import contactsSaga from './contacts/saga';
import locationSaga from '../containers/location/saga';
import CollegesSaga from '../containers/college/saga';


export default function* rootSaga() {
  yield all([
    // public
    // AccountSaga(),
    fork(GlobalSaga),
    fork(AuthSaga),
    fork(SchoolsSaga),
    fork(UserSaga),
    fork(ContactInquirySaga),
    fork(WorksheetSaga),
    fork(ForumSaga),
    // ProfileSaga(),
    // ForgetSaga(),
    LayoutSaga(),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(locationSaga),
    fork(CollegesSaga),
  ]);
}
