import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';

import AuthService from '../../common/auth.service';
import { isLoadingSelector, isLoggedInSelector } from '../../common/globalComponents/selectors';
import Loader from '../../components/Loader';
import { getUser } from '../../containers/authentication/actions';
import { SMKLinks } from '../../utils/links';
import { useRouter } from 'hooks/useRouter';
import HrLayout from 'components/VerticalLayout/HrLayout';
import { useMsal } from '@azure/msal-react';
import { isEmpty } from 'lodash';
import { admins_list } from 'utils/constants';

const stateSelector = createStructuredSelector({
  isLoading: isLoadingSelector,
  isLoggedIn: isLoggedInSelector,
});

const Authmiddleware = (props) => {
  const { component: Component, layout: Layout, isAuthProtected, path, ...rest } = props;

  const router = useRouter();
  const dispatch = useDispatch();
  const history = useHistory();
  const { accounts } = useMsal();

  const { isLoading, isLoggedIn } = useSelector(stateSelector);

  const hrRoutes = ['/auth/hr', '/hr', '/job-applications/list', '/job-applications/edit/:id'];

  const isUserAuthenticated = (userAccount) => {
    let isAuth = false;
    const accessToken = localStorage.getItem('accessToken');
    if (!isEmpty(accessToken) && admins_list.includes(userAccount?.username)) {
      isAuth = true;
    }
    return isAuth;
  };

  useEffect(() => {
    if (!hrRoutes.includes(path)) {
      if (!isLoggedIn) {
        const auth = new AuthService();
        const currentAppSource = auth.getAppSourceCookie();
        const token = auth.getSessionCookie();
        if (router?.pathname !== '/branding' || router?.pathname !== '/auth/microsoft') {
          if (!token) {
            history.replace(SMKLinks.LOGIN_PAGE);
          } else {
            dispatch(getUser.trigger({ currentAppSource }));
          }
        }
      }
    } else {
      if (!isUserAuthenticated(accounts[0])) {
        history.replace('/auth/hr');
      }
    }
  }, [isLoggedIn]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return !isAuthProtected || (isLoggedIn && isAuthProtected) ? (
          <Layout>
            <Loader isActive={isLoading} />
            <Component {...props} />
          </Layout>
        ) : (
          <>
            <HrLayout>
              <Loader isActive={isLoading} />
              <Component {...props} />
            </HrLayout>
          </>
        );
      }}
    />
  );
};

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
};

export default Authmiddleware;
