import AuthService from 'common/auth.service';
import { userSelector } from 'common/globalComponents/selectors';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import PageDetailCard from 'components/PageDetailsCard';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import {
  APP_BANNER_EXAMPLE_PAYLOAD,
  APP_BANNER_METADATA_EXAMPLES,
  BannerScreenEnum,
  MODULE_TYPE,
  NotificationActionEnum,
  appBannerSections,
} from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';

const stateSelector = createStructuredSelector({
  user: userSelector,
});

const UpdateAppBanner = () => {
  const { user } = useSelector(stateSelector);

  const history = useHistory();
  const { id: appBannerID } = useParams();

  toast.configure();

  const auth = new AuthService();
  const source = auth.getAppSourceCookie();

  const actionTypes = Object.keys(NotificationActionEnum)?.map((name) => ({
    label: titleCaseIfExists(name),
    value: NotificationActionEnum[name],
  }));
  const bannerScreenList = Object.keys(BannerScreenEnum)?.map((name) => ({
    label: titleCaseIfExists(name),
    value: BannerScreenEnum[name],
  }));

  const [appBannerDetails, setAppBannerDetails] = useState();

  const [getAppBannerRequest, { loading: getAppBannerLoading }] = useRequest({
    url: `/common/appBanner/admin/${appBannerID}`,
    method: 'GET',
    onSuccess: (data) => {
      setAppBannerDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [addAppBannerRequest, { loading: addAppBannerLoading }] = useRequest({
    url: `/common/appBanner/admin`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.APP_BANNERS_LISTING;
      history.replace(url);
      toast.success('Success! App Banner has been added.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [updateAppBannerRequest, { loading: updateAppBannerLoading }] = useRequest({
    url: `/common/appBanner/admin/${appBannerID}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.APP_BANNERS_LISTING;
      history.replace(url);
      toast.success('Success! App Banner has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const isJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return typeof parseObj === 'object' || typeof parseObj === 'Array' ? true : false;
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const convertJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return parseObj;
    } catch (err) {
      toast.error(err);
    }
  };

  const editInitialValues = () => {
    return {
      active: appBannerDetails?.active,
      deleted: appBannerDetails?.deleted,
      showOnce: appBannerDetails?.showOnce,
      appSource: appBannerDetails?.appSource,
      placement: appBannerSections?.find((e) => e?.value === appBannerDetails?.placement),
      action: actionTypes?.find((e) => e?.value === appBannerDetails?.action),
      screen: bannerScreenList?.find((e) => e?.value === appBannerDetails?.screen),
      title: appBannerDetails?.title,
      order: appBannerDetails?.order,
      image: appBannerDetails?.image || {},
      expiryDate: !isEmpty(appBannerDetails?.expiryDate) ? new Date(appBannerDetails?.expiryDate) : '',
      payload: !isEmpty(appBannerDetails?.payload) ? JSON.stringify(appBannerDetails?.payload, null, 2) : '{}',
      metaData: !isEmpty(appBannerDetails?.metaData) ? JSON.stringify(appBannerDetails?.metaData, null, 2) : null,
    };
  };

  useEffect(() => {
    if (!isEmpty(appBannerDetails)) {
      const initialValues = editInitialValues();
      formik.setValues(initialValues);
    }
  }, [appBannerDetails]);

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    showOnce: false,
    appSource: source,
    title: '',
    order: 99,
    screen: '',
    placement: '',
    action: null,
    payload: '{}',
    expiryDate: '',
    image: '',
    metaData: '{}',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(appBannerID) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: async (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        showOnce: values?.showOnce,
        title: values?.title,
        order: values?.order,
        screen: values?.screen?.value,
        appSource: values?.appSource,
        placement: values?.placement?.value,
        action: values?.action?.value,
        expiryDate: values?.expiryDate,
        payload: !isEmpty(values?.payload) ? convertJSON(values?.payload) : {},
        metaData: !isEmpty(values?.metaData) ? convertJSON(values?.metaData) : {},
        ...(!isEmpty(values?.image)
          ? {
              imageId: values?.image?.id,
              image: undefined,
            }
          : { imageId: null }),
      };

      if (!isEmpty(values.payload) && !isJSON(values.payload)) {
        toast.error('Please enter valid  payload JSON...');
      } else if (!isEmpty(values.metaData) && !isJSON(values.metaData)) {
        toast.error('Please enter valid  metaData JSON...');
      } else {
        if (isEmpty(appBannerID)) {
          addAppBannerRequest({
            body: {
              ...data,
            },
          });
        } else {
          updateAppBannerRequest({
            body: {
              ...data,
            },
          });
        }
      }
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Please provide title'),
      expiryDate: Yup.string().required('Please provide expiry date'),
      screen: Yup.object()
        .required('Please provide screen')
        .nullable(true),
      placement: Yup.object()
        .required('Please provide placement')
        .nullable(true),
      action: Yup.object()
        .required('Please provide action name')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(appBannerID)) {
      getAppBannerRequest();
    }
  }, [appBannerID]);

  const handleDuplicate = () => {
    const data = {
      active: false,
      deleted: false,
      showOnce: formik?.values?.showOnce,
      title: formik?.values?.title + ` (Draft) `,
      order: formik?.values?.order,
      screen: formik?.values?.screen?.value,
      appSource: formik?.values?.appSource,
      placement: formik?.values?.placement?.value,
      action: formik?.values?.action?.value,
      expiryDate: formik?.values?.expiryDate,
      payload: !isEmpty(formik?.values?.payload) ? convertJSON(formik?.values?.payload) : {},
      metaData: !isEmpty(formik?.values?.metaData) ? convertJSON(formik?.values?.metaData) : {},
      imageId: null,
    };

    if (!isEmpty(formik?.values.payload) && !isJSON(formik?.values.payload)) {
      toast.error('Please enter valid  payload JSON...');
    } else if (!isEmpty(formik?.values.metaData) && !isJSON(formik?.values.metaData)) {
      toast.error('Please enter valid  metaData JSON...');
    } else {
      addAppBannerRequest({
        body: {
          ...data,
        },
      });
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={addAppBannerLoading || getAppBannerLoading || updateAppBannerLoading} />
        <Container fluid>
          <Breadcrumbs title="App Banner" breadcrumbItem={`${isEmpty(appBannerID) ? 'Add' : 'Edit'}  App Banner`} />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="title" className="form-label">
                            Title
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Enter title..."
                            invalid={!!(formik.touched.title && formik.errors.title)}
                            {...formik.getFieldProps('title')}
                          />
                          <FormFeedback className="d-block">{formik.errors.title}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="screen" className="form-label">
                            Order
                          </Label>
                          <Input
                            id="order"
                            name="order"
                            type="number"
                            className="form-control"
                            placeholder="Enter order..."
                            invalid={!!(formik.touched.order && formik.errors.order)}
                            {...formik.getFieldProps('order')}
                          />
                          <FormFeedback className="d-block">{formik.errors.order}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="expiryDate" htmlFor="expiryDate" className="form-label ">
                            Expiry Date
                          </Label>
                          <div className="position-relative">
                            <DatePicker
                              minDate={new Date()}
                              className="form-control"
                              selected={formik.values.expiryDate}
                              onChange={(date) => formik.setFieldValue('expiryDate', date)}
                              placeholderText="Select Date..."
                              dateFormat="dd-MM-yyyy"
                            />
                            <FormFeedback className="d-block">{formik.errors.expiryDate}</FormFeedback>
                          </div>
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="screen" className="form-label">
                            Screen
                          </Label>
                          <Select
                            options={bannerScreenList}
                            placeholder="Select screen..."
                            value={formik.values.screen}
                            onChange={(elem) => formik.setFieldValue('screen', elem)}
                          />
                          <FormFeedback className="d-block">{formik.errors.screen}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="placement" className="form-label  ">
                            Placement
                          </Label>
                          <Select
                            options={appBannerSections}
                            placeholder="Select placement..."
                            value={formik.values.placement}
                            onChange={(elem) => formik.setFieldValue('placement', elem)}
                          />
                          <FormFeedback className="d-block">{formik.errors.placement}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="action" className="form-label  ">
                            Action
                          </Label>
                          <Select
                            id="action"
                            options={actionTypes}
                            value={formik.values.action}
                            onChange={(elem) => {
                              formik.setFieldValue('action', elem);
                            }}
                          />
                          <FormFeedback className="d-block">{formik.errors.action}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="payload" className="form-label">
                            Payload
                          </Label>
                          <Input
                            id="payload"
                            name="payload"
                            type="textarea"
                            rows={8}
                            className="form-control"
                            placeholder="Enter JSON payload"
                            invalid={!!(formik.touched.payload && formik.errors.payload)}
                            {...formik.getFieldProps('payload')}
                          />
                          <FormFeedback className="d-block">{formik.errors.payload}</FormFeedback>
                        </FormGroup>
                        {!isEmpty(formik.values.action?.value) &&
                          !isEmpty(
                            APP_BANNER_EXAMPLE_PAYLOAD?.find((e) => e?.action === formik.values.action?.value)
                          ) && (
                            <div className="mt-3 bg-light p-4 rounded-2 mb-4">
                              <h5 className="mb-3">Paylod Examples :</h5>

                              {APP_BANNER_EXAMPLE_PAYLOAD?.filter(
                                (e) => e?.action === formik.values.action?.value
                              )?.map((elem) => (
                                <pre>{JSON.stringify(elem, null, 2)}</pre>
                              ))}
                            </div>
                          )}
                      </Col>
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="metaData" className="form-label">
                            Meta Data
                          </Label>
                          <Input
                            id="metaData"
                            name="metaData"
                            type="textarea"
                            rows={8}
                            className="form-control"
                            placeholder="Enter JSON meta data"
                            invalid={!!(formik.touched.metaData && formik.errors.metaData)}
                            {...formik.getFieldProps('metaData')}
                          />
                          <FormFeedback className="d-block">{formik.errors.metaData}</FormFeedback>
                        </FormGroup>
                        <div className="mt-3 bg-light p-4 rounded-2 mb-4">
                          <h5 className="mb-3">MetaData Examples :</h5>
                          <pre>{JSON.stringify(APP_BANNER_METADATA_EXAMPLES, null, 2)}</pre>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100 mb-3">
                      {appBannerID ? 'Save App Banner' : 'Add App Banner'}
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.APP_BANNERS_LISTING);
                      }}>
                      Cancel
                    </Button>

                    {appBannerID && user?.entityPermissions?.app_banner?.create && (
                      <Button className="w-100 mt-3" color="success" onClick={handleDuplicate}>
                        Duplicate
                      </Button>
                    )}
                  </CardBody>
                </Card>
                <PageDetailCard data={appBannerDetails} />

                <Card>
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <CardTitle className="mb-3">Active </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`active_${option}`}
                              name="active"
                              className="form-check-input"
                              checked={formik.values.active === option}
                              onChange={() => formik.setFieldValue('active', option)}
                            />
                            <label className="form-check-label" htmlFor={`active_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>
                      <Col md={12}>
                        <CardTitle className="mb-3">Delete </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`deleted_${option}`}
                              name="deleted"
                              className="form-check-input"
                              checked={formik.values.deleted === option}
                              onChange={() => formik.setFieldValue('deleted', option)}
                            />
                            <label className="form-check-label" htmlFor={`deleted_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>
                      <Col md={12}>
                        <CardTitle className="mb-3">Show Once </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`showOnce_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`showOnce_${option}`}
                              name="showOnce"
                              className="form-check-input"
                              checked={formik.values.showOnce === option}
                              onChange={() => formik.setFieldValue('showOnce', option)}
                            />
                            <label className="form-check-label" htmlFor={`showOnce_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <AssetImageUploader
                  forType={MODULE_TYPE.METADATA}
                  formik={formik}
                  fieldName={'image'}
                  label={'Select Image'}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateAppBanner;
