import { call, put, takeLatest } from 'redux-saga/effects';
import {
  getContactInquiryList,
  getContactInquirySearchList,
  contactInquiryActionTaken,
  contactInquiryDelete,
} from './actions';
import { api } from '../../utils/axios';
import config from '../../config/config';
import { setGlobalError } from '../../common/globalComponents/actions';

export const GET_CONTACT_INQUIRY_LIST_API_URL = `${config.apiEndpoint}/contact-inquiry/admin/search`;
export const CONTACT_INQUIRY_MARK_ACTION_TAKEN_API = `${config.apiEndpoint}/contact-inquiry/admin/markActionTaken/`;
export const CONTACT_INQUIRY_DELETE_API = `${config.apiEndpoint}/contact-inquiry/admin/delete/`;

const getContactInquiryListCall = (filters) =>
  api({
    method: 'POST',
    url: GET_CONTACT_INQUIRY_LIST_API_URL,
    data: filters,
  });

const contactInquiryActionTakenCall = (data) =>
  api({
    method: 'PUT',
    url: CONTACT_INQUIRY_MARK_ACTION_TAKEN_API + data.id,
  });
const contactInquiryDeleteCall = (data) =>
  api({
    method: 'PUT',
    url: CONTACT_INQUIRY_DELETE_API + data.id,
  });

function* getContactInquiryListRequest(action) {
  try {
    yield put(getContactInquiryList.request({ isLoading: true }));
    const response = yield call(getContactInquiryListCall, action.payload);
    if (response) {
      yield put(getContactInquiryList.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getContactInquiryList.failure(error));
  } finally {
    yield put(getContactInquiryList.fulfill({ isLoading: false }));
  }
}

function* getContactInquirySearchListRequest(action) {
  try {
    yield put(getContactInquirySearchList.request());
    const response = yield call(getContactInquiryListCall, action.payload);
    if (response) {
      yield put(getContactInquirySearchList.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getContactInquirySearchList.failure(error));
  } finally {
    yield put(getContactInquirySearchList.fulfill());
  }
}

function* contactInquiryActionTakenRequest(action) {
  try {
    yield put(contactInquiryActionTaken.request());

    const response = yield call(contactInquiryActionTakenCall, action.payload);

    if (response) {
      yield put(contactInquiryActionTaken.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(contactInquiryActionTaken.failure(error));
  } finally {
    yield put(contactInquiryActionTaken.fulfill());
  }
}

function* contactInquiryDeleteRequest(action) {
  try {
    yield put(contactInquiryDelete.request());

    const response = yield call(contactInquiryDeleteCall, action.payload);

    if (response) {
      yield put(contactInquiryDelete.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(contactInquiryDelete.failure(error));
  } finally {
    yield put(contactInquiryDelete.fulfill());
  }
}

export default function* contactInquirySaga() {
  yield takeLatest(getContactInquiryList.TRIGGER, getContactInquiryListRequest);
  yield takeLatest(getContactInquirySearchList.TRIGGER, getContactInquirySearchListRequest);

  yield takeLatest(contactInquiryActionTaken.TRIGGER, contactInquiryActionTakenRequest);
  yield takeLatest(contactInquiryDelete.TRIGGER, contactInquiryDeleteRequest);
}
