import ToggleButton from 'components/ToggleButton';
import { useFormik } from 'formik';
import { useRouter } from 'hooks/useRouter';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { SOURCE } from 'utils/constants';
import { handleSaveAsset, titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { SMKLinks } from '../../../utils/links';
import UploadMedia from './uploadMedia';
import AuthService from 'common/auth.service';

const AddMedia = () => {
  const history = useHistory();
  const router = useRouter();
  const auth = new AuthService();
  const isTpzRoute = auth.getAppSourceCookie() !== 'schoolmykids';

  const [uploadedImagePreview, setUploadedImagePreview] = useState();
  const [withPadding, setWithPadding] = useState(false);

  const forTypeEnumList = {
    ARTICLE: 3,
    METADATA: 26,
    ...(isTpzRoute
      ? {
          BABY_NAMES: 9,
          MEMORIES: 19,
        }
      : {
          SCHOOL: 7,
          COLLEGE: 25,
          WORKSHEET: 8,
        }),
  };

  const moduleForTypeList = Object.keys(forTypeEnumList)?.map((e) => ({
    label: titleCaseIfExists(e),
    value: forTypeEnumList[e],
  }));

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      isAsset: true,
      width: '',
      height: '',
      backgroundColor: '',
      minPadding: '',
      uploadedFile: '',
      forType: moduleForTypeList[0],
    },
    onSubmit: async (values) => {
      const imageFile = await handleSaveAsset(uploadedImagePreview, values?.forType?.value);
      if (isEmpty(imageFile?.message)) {
        const url = SMKLinks.MEDIA_LISTING + `?page=1&type=${values?.forType?.label}`;
        history.replace(url);
        toast.success('Success! Media has been added.');
      } else {
        toast.error('Error! Something went wrong');
      }
    },
    validationSchema: Yup.object().shape(
      !withPadding
        ? {
            uploadedFile: Yup.string().required('Please provide file'),
          }
        : {
            uploadedFile: Yup.string().required('Please provide file'),
            width: Yup.number()
              .min(1, 'Width must be at least 1')
              .required('Please provide width'),
            height: Yup.number()
              .min(1, 'Height must be at least 1')
              .required('Please provide height'),
            backgroundColor: Yup.string().required('Please provide background color'),
            minPadding: Yup.number()
              .min(1, 'Minimum padding must be at least 1')
              .required('Please provide minimum padding'),
          }
    ),
  });

  useEffect(() => {
    if (!isEmpty(router?.query?.type)) {
      const type = moduleForTypeList?.find((e) => e?.label?.toLowerCase() === router?.query?.type?.toLowerCase());
      formik.setFieldValue('forType', type);
    }
  }, [router?.query?.type]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Media" breadcrumbItem={`Add Media`} />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <h5 className="border-bottom pb-3 mb-4">Configurations</h5>

                    <Row>
                      <Col md={12}>
                        <div className="col-lg-3">
                          <ToggleButton
                            label={'WithPadding'}
                            onChange={() => setWithPadding(!withPadding)}
                            checked={withPadding}
                          />
                        </div>
                      </Col>

                      {withPadding && (
                        <>
                          <Col md={6}>
                            <FormGroup className="mb-4">
                              <Label for="width" className="form-label">
                                Width
                              </Label>
                              <Input
                                id="width"
                                type="number"
                                className="form-control"
                                placeholder="Width..."
                                invalid={!!(formik.touched.width && formik.errors.width)}
                                {...formik.getFieldProps('width')}
                                error={!isEmpty(formik.errors.width)}
                              />
                              <FormFeedback className="d-block">{formik.errors.width}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-4">
                              <Label for="height" className="form-label">
                                Height
                              </Label>
                              <Input
                                id="height"
                                type="number"
                                className="form-control"
                                placeholder="Height..."
                                invalid={!!(formik.touched.height && formik.errors.height)}
                                {...formik.getFieldProps('height')}
                                error={!isEmpty(formik.errors.height)}
                              />
                              <FormFeedback className="d-block">{formik.errors.height}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-4">
                              <Label for="backgroundColor" className="form-label">
                                Background Color
                              </Label>
                              <div className="py-3">
                                <Input
                                  // style={{ padding: 16 }}
                                  id="backgroundColor"
                                  type="color"
                                  className="form-control"
                                  placeholder="Background Color..."
                                  invalid={!!(formik.touched.backgroundColor && formik.errors.backgroundColor)}
                                  {...formik.getFieldProps('backgroundColor')}
                                  error={!isEmpty(formik.errors.backgroundColor)}
                                />
                              </div>

                              <FormFeedback className="d-block">{formik.errors.backgroundColor}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-4">
                              <Label for="minPadding" className="form-label">
                                Minimum Padding
                              </Label>
                              <Input
                                id="minPadding"
                                type="number"
                                className="form-control"
                                placeholder="Minimum Padding..."
                                invalid={!!(formik.touched.minPadding && formik.errors.minPadding)}
                                {...formik.getFieldProps('minPadding')}
                                error={!isEmpty(formik.errors.minPadding)}
                              />
                              <FormFeedback className="d-block">{formik.errors.minPadding}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <UploadMedia
                      formik={formik}
                      field={'uploadedFile'}
                      uploadedImagePreview={uploadedImagePreview}
                      setUploadedImagePreview={setUploadedImagePreview}
                      source={isTpzRoute ? SOURCE[1]?.value : SOURCE[0]?.value}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100 mb-3">
                      Add Media
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        const url = SMKLinks.MEDIA_LISTING + `?page=1&type=${formik.values.forType.label}`;
                        history.replace(url);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>

                <Card className="border">
                  <CardBody>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="order" className="form-label  ">
                          For Type
                        </Label>
                        <Select
                          options={moduleForTypeList}
                          placeholder="Select type..."
                          value={formik.values.forType}
                          onChange={(elem) => formik.setFieldValue('forType', elem)}
                        />
                        <FormFeedback className="d-block">{formik.errors.forType}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default AddMedia;
