import React, { useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import { SnackbarProvider } from 'notistack';
// import ErrorHandler from '../../components/ErrorHandler/ErrorHandler';
import { createStructuredSelector } from 'reselect';
import AuthService from '../auth.service';
import { api } from '../../utils/axios';
import { showCircularLoaderSelector, showLinearLoaderSelector } from './selectors';
// import { useInjectSaga } from '../../store/injectSaga';
// import saga from '../saga';
// import { GLOBAL_KEY as key } from '../../utils/reducerSagaKeys';
// import { API_BASE_URL } from '../../utils';
import config from '../../config/config';

const stateSelector = createStructuredSelector({
  showLinearLoader: showLinearLoaderSelector,
  showCircularLoader: showCircularLoaderSelector,
});

const GlobalComponents = () => {
  const { showLinearLoader, showCircularLoader } = useSelector(stateSelector);

  return (
    <>
      {showLinearLoader ? <LinearProgress color="secondary" /> : null}
      {showCircularLoader ? (
        <div style={{ position: 'relative' }}>
          <CircularProgress size={40} left="40%" top={10} status="loading" style={{ marginLeft: '50%' }} />
        </div>
      ) : null}
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
        {/* <ErrorHandler /> */}
      </SnackbarProvider>
    </>
  );
};

export default GlobalComponents;
