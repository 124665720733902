import React from 'react';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// config
import { toast } from 'react-toastify';
import config from '../../../config/config';
// selectors
import { collegeDetailSelector } from '../selectors';
// helpers
import { printDate, convertHtmlToText } from '../../../utils/helper';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { userSelector } from '../../../common/globalComponents/selectors';

const stateSelector = createStructuredSelector({
  collegeDetail: collegeDetailSelector,
  user: userSelector,
});

const CollegeSummaryBlock = () => {
  const { collegeDetail, user } = useSelector(stateSelector);
  toast.configure();

  const renderLocation = () => {
    const { address } = collegeDetail;
    if (!address) return '';
    let loc = [address.region?.displayName, address.city?.displayName, address.country?.displayName]
      .filter(Boolean)
      .join(', ');
    return loc;
  };

  const [publishcollegeRequest, { loading: publishCollegeLoading }] = useRequest({
    url: `/college/admin/togglePublish/${collegeDetail.id}`,
    method: 'POST',
    onSuccess: (data) => {
      window.location.reload();
      toast.success('Success! College has been Published');
    },
  });

  const [deletecollegeRequest, { loading: deleteCollegeLoading }] = useRequest({
    url: `/college/admin/toggleDelete/${collegeDetail.id}`,
    method: 'POST',
    onSuccess: (data) => {
      // collegeDetailClearCache();
      window.location = `/colleges/list`;
      toast.success('Success! College has been Deleted');
      window.location.reload();
    },
  });

  const [duplicatecollegeRequest, { loading: duplicateCollegeLoading }] = useRequest({
    url: `/college/admin/duplicateCollege/${collegeDetail.id}`,
    method: 'PUT',
    onSuccess: (data) => {
      // collegeDetailClearCache();
      window.location = `/colleges/list`;
      toast.success('Success! Duplicate college has been created ');
    },
  });

  const [toggleEditableRequest, { loading: toggleEditableLoading }] = useRequest({
    url: `/college/admin/toggleEditable/${collegeDetail.id}`,
    method: 'PUT',
    onSuccess: (data) => {
      // collegeDetailClearCache();
      window.location.reload();
      toast.success('Success! College Toggle has been Added ');
    },
  });

  return (
    !isEmpty(collegeDetail) && (
      <Card>
        <Loader
          isActive={publishCollegeLoading || deleteCollegeLoading || duplicateCollegeLoading || toggleEditableLoading}
        />

        <CardBody>
          <Row>
            <Col md={3}>
              <div className="text-muted mb-2">Name</div>
              <CardTitle>
                {collegeDetail?.logoUrl && (
                  <a target="_blank" href={collegeDetail?.logoUrl} rel="noreferrer">
                    <img src={collegeDetail?.logoUrl} height="50" className="d-block m-auto" />
                  </a>
                )}
                <div className={collegeDetail?.logoUrl ? 'mt-1' : ''}>
                  {convertHtmlToText(collegeDetail.displayName || collegeDetail.name)}
                </div>
              </CardTitle>
            </Col>
            <Col md={3}>
              <div className="text-muted mb-2">Location</div>
              <CardTitle>{renderLocation()}</CardTitle>
            </Col>

            <Col md={3}>
              <div className="text-muted mb-2">Verified</div>
              <CardTitle>
                {collegeDetail?.systemDetails?.verified ? (
                  <Badge className="bg-success my-1">Verified</Badge>
                ) : (
                  <Badge className="bg-danger my-1">Not Verified</Badge>
                )}
              </CardTitle>
            </Col>
            <Col md={3}>
              <div className="text-muted mb-2">Status</div>
              <CardTitle>
                {collegeDetail.active ? (
                  <Badge className="bg-success mx-1">Published</Badge>
                ) : (
                  <Badge className="bg-warning mx-1">Draft</Badge>
                )}

                {!(collegeDetail?.systemDetails?.verified || collegeDetail?.active) && (
                  <Badge className="bg-danger mx-1">Inactive</Badge>
                )}
                {collegeDetail?.deleted && <Badge className="bg-danger mx-1">Deleted</Badge>}
              </CardTitle>
            </Col>
          </Row>
          <Row className="my-3">
            <Col md={3}>
              <div className="text-muted mb-2">Created Date</div>
              <CardTitle>{printDate(collegeDetail.createdDate)}</CardTitle>
            </Col>
            <Col md={3}>
              <div className="text-muted mb-2">Updated Date</div>
              <CardTitle>{printDate(collegeDetail.updatedDate)}</CardTitle>
            </Col>
            <Col md={3}>
              <div className="text-muted mb-2">Created By</div>
              <CardTitle>
                {collegeDetail?.createdBy?.displayName}{' '}
                {collegeDetail?.createdBy?.email && <small>({collegeDetail?.createdBy?.email})</small>}
              </CardTitle>
            </Col>

            <Col md={3}>
              <div className="text-muted mb-2">Actions</div>
              <div>
                {user?.entityPermissions?.school?.publish && (
                  <Button
                    className={`me-3 px-4 ${collegeDetail?.active ? 'bg-primary' : 'bg-success'}`}
                    type="submit"
                    onClick={() => publishcollegeRequest()}>
                    {collegeDetail?.active ? 'Unpublish' : 'Publish'}
                  </Button>
                )}
                {/* {user?.entityPermissions?.college.delete && (
                  <Button
                   className={collegeDetail.deleted ? 'primary' : 'danger'}
                    type="submit"
                    className="me-2"
                    onClick={() => deletecollegeRequest()}>
                    {collegeDetail.deleted ? 'Undelete' : 'Delete'}
                  </Button>
                )} */}

                {user?.entityPermissions?.college?.admin && (
                  <UncontrolledButtonDropdown>
                    <DropdownToggle color="light" caret>
                      More <i className="fa fa-chevron-down ms-2" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* <DropdownItem header>Header</DropdownItem>
                    <DropdownItem disabled>Action</DropdownItem> */}
                      <DropdownItem className="my-2" onClick={() => deletecollegeRequest()}>
                        <i className="fas fa-trash pe-3 text-danger" />
                        <span className={collegeDetail.deleted ? 'text-primary' : 'text-danger'}>
                          {collegeDetail.deleted ? 'Undelete' : 'Delete'} college
                        </span>
                      </DropdownItem>
                      <DropdownItem className="my-2" onClick={() => duplicatecollegeRequest()}>
                        <i className="fas fa-file pe-3 text-dark" />
                        Duplicate college
                      </DropdownItem>
                      {/* <DropdownItem onClick={() => purgecollegeCacheRequest()}>Purge Cache</DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                )}
              </div>
            </Col>
          </Row>

          <Row className="my-3">
            <Col md={3}>
              <div className="text-muted mb-2">Average Rating</div>
              <CardTitle>{collegeDetail.averageRating}</CardTitle>
            </Col>
            <Col md={3}>
              <div className="text-muted mb-2">Total Reviews</div>
              <CardTitle>{collegeDetail.totalReviews}</CardTitle>
            </Col>

            {/* <Col md={2}>
              <div className="text-muted mb-2">Claimed</div>
              <CardTitle className={collegeDetail?.claimed ? 'text-success' : 'text-danger'}>
                {collegeDetail.claimed ? printDate(collegeDetail.claimedDate) : 'No'}
              </CardTitle>
            </Col> */}
          </Row>
          {!isEmpty(collegeDetail.slug) && (
            <Row>
              <Col md={12}>
                <div className="text-muted mb-2">Permalink</div>
                <div>
                  {/* <a href={`${config.frontendBaseUrl}/college/${collegeDetail.slug}`} rel="noreferrer" target="_blank">
                    <span className="text-normal text-primary">{`${config.frontendBaseUrl}/college/${collegeDetail.slug}`}</span>
                  </a> */}
                  <a href={`${config.frontendBaseUrl}/college/${collegeDetail.slug}`} rel="noreferrer" target="_blank">
                    <span className="text-normal text-primary">{`${config.frontendBaseUrl}/college/${collegeDetail.slug}`}</span>
                  </a>
                </div>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    )
  );
};

export default CollegeSummaryBlock;
