import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { isEmpty } from 'lodash';
import React from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { htmlDecode } from 'utils/helper';

const FaqAndPageSearch = ({ formik, label, type, apiURL, filters, heading }) => {
  const handleSelectChange = (res) => {
    const currentData = !isEmpty(formik?.values?.[label]) ? formik?.values?.[label] : [];

    const isAlreadySelected = currentData?.some((item) => item?.id === res?.id);

    if (!isAlreadySelected) {
      const updatedData = [
        ...currentData,
        {
          title: res?.title,
          id: res?.id,
        },
      ];
      formik.setFieldValue(label, updatedData);
    }
  };

  const handleRemoveItem = (id) => {
    const updatedData = formik.values?.[label]?.filter((item) => item?.id !== id);
    formik.setFieldValue(label, updatedData);
  };

  return (
    <Card>
      <CardBody>
        <div className="mb-3">
          <CardTitle className="mb-3">{heading}</CardTitle>
          <SmkAsyncSelect
            acceptedKey={'title'}
            acceptedValue={'id'}
            placeholder={`Type ${type} name..`}
            onChange={handleSelectChange}
            fetchUrl={apiURL}
            filters={{
              autoComplete: true,
              active: true,
              deleted: false,
              page: 1,
              size: 20,
              sortBy: 'id',
              sortOrder: 0,
              ...(filters ? { ...filters } : {}),
            }}
            searchKeyName="name"
          />
        </div>

        {!isEmpty(formik?.values?.[label]) && (
          <div className="mt-3">
            {formik.values?.[label]?.map((item, index) => (
              <div
                key={item.id}
                className="bg-light rounded-2 p-3 d-flex justify-content-between align-items-center mt-3  ">
                <h6 className="mb-0">
                  {index + 1}. {htmlDecode(item.id)} - {htmlDecode(item.title)}
                </h6>
                <button className="btn btn-md btn-danger" type="button" onClick={() => handleRemoveItem(item.id)}>
                  Remove
                </button>
              </div>
            ))}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default FaqAndPageSearch;
