import { collegeClearCache } from 'containers/college/collegeDetailsHelper';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Badge, Card, CardBody, Form, FormGroup, Input, Label, Table } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import * as Yup from 'yup';
import Loader from '../../../../components/Loader';
import useRequest from '../../../../hooks/useRequest';
import { collegeDetailSelector } from '../../selectors';
import {
  COLLEGE_FEE_TYPE,
  COLLEGE_SEAT_CATEGORY,
  COLLEGE_SEAT_TYPE,
  CURRENCY_TYPE,
  CURRENT_YEAR,
} from '../../../../utils/constants';

const stateSelector = createStructuredSelector({
  collegeDetail: collegeDetailSelector,
});

const FeesMatrix = () => {
  const { collegeDetail } = useSelector(stateSelector);
  const [academicYear, setAcademicYear] = useState({ value: CURRENT_YEAR, label: CURRENT_YEAR });
  const [offerredCourses, setOfferredCourses] = useState([]);

  const defaultValues = {
    seatType: 'all',
    seatCategory: '',
    totalFees: 0,
    feesType: 'total-fees',
    comments: '',
    currency: 'inr',
  };

  const [updateFeesMatrixRequest, { loading: updateFeesMatrixLoading }] = useRequest({
    url: '/college/admin/edit/updateFeesMatrix',
    method: 'POST',
    onSuccess: (data) => {
      collegeClearCache({
        urlSlug: collegeDetail?.slug,
        collegeId: collegeDetail?.id,
      });

      window.location.reload();
    },
  });

  useEffect(() => {
    if (!isEmpty(collegeDetail)) {
      const { coursesOffered } = collegeDetail;

      let offerredCourses = coursesOffered.filter((t) => t.academicYear === academicYear?.value);
      if (isEmpty(offerredCourses)) {
        offerredCourses = coursesOffered.map((t) => ({ ...t, feeInfo: [], academicYear: academicYear?.value }));
      }
      setOfferredCourses((prev) => offerredCourses);

      const feesMatrix = {};
      offerredCourses
        ?.filter((t) => t.academicYear === academicYear?.value)
        ?.forEach((course) => {
          if (course.feeInfo.length > 0) {
            feesMatrix[course.courseId] = { feeInfo: course?.feeInfo, feeDetails: course?.feeDetails };
          } else {
            feesMatrix[course.courseId] = {
              feeInfo: [defaultValues],
              feeDetails: '',
            };
          }
        });
      formik.setFieldValue('feesMatrix', feesMatrix);
    }
  }, [collegeDetail, academicYear]);

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      courseId: '',
      feesMatrix: {},
    },
    onSubmit(values) {
      if (values.feesMatrix.length < 1 || values.feesMatrix.length > 10) {
        toast.error('Number of feesMatrix must be between 1 and 10');
        return;
      }

      const data = {
        collegeId: collegeDetail?.id,
        courseId: values.courseId,
        academicYear: academicYear?.value,
        feeInfo: values.feesMatrix[values.courseId]?.feeInfo,
        feeDetails: values.feesMatrix[values.courseId]?.feeDetails,
      };
      updateFeesMatrixRequest({ data });
    },
    validationSchema: Yup.object().shape({
      // feesMatrix: Yup.array().of(
      //   Yup.object().shape({
      //     seatType: Yup.string().required('Type is required'),
      //     seatCategory: Yup.string().required('Category is required'),
      //     totalFees: Yup.number()
      //       .positive()
      //       .required('Fees is required'),
      //     currency: Yup.string().required('currency is required'),
      //   })
      // ),
    }),
  });

  const startYear = new Date().getFullYear() + 1;
  const years = Array.from({ length: 16 }, (_, index) => startYear - index);

  const options = years
    .sort((a, b) => a - b)
    ?.map((year) => ({
      value: year,
      label: year.toString(),
    }));

  const addRow = (id) => {
    event.preventDefault();
    if (!isEmpty(formik.values.feesMatrix[id]) && formik.values.feesMatrix[id].feeInfo.length < 10) {
      const updatedfeesMatrix = {
        ...formik.values.feesMatrix,
        [id]: {
          feeInfo: [...(formik.values.feesMatrix[id].feeInfo || []), defaultValues],
          feeDetails: formik.values.feesMatrix[id].feeDetails,
        },
      };
      formik.setFieldValue('feesMatrix', updatedfeesMatrix);
    } else {
      const updatedfeesMatrix = {
        [id]: {
          feeInfo: [...(formik.values.feesMatrix[id].feeInfo || []), defaultValues],
          feeDetails: '',
        },
      };
      formik.setFieldValue('feesMatrix', updatedfeesMatrix);
    }
  };

  const removeRow = (id, index) => {
    event.preventDefault();
    if (formik.values.feesMatrix[id]?.feeInfo?.length > 1) {
      const updatedfeesMatrix = {
        ...formik.values.feesMatrix,
        [id]: {
          feeInfo: formik.values.feesMatrix[id].feeInfo.filter((_, i) => i !== index),
          feeDetails: formik.values.feesMatrix[id].feeDetails,
        },
      };
      formik.setFieldValue('feesMatrix', updatedfeesMatrix);
    }
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateFeesMatrixLoading} />

      <h5 className="mb-4">Fees Matrix</h5>

      <Card>
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="collegedesc" className="form-label ">
              Fees Matrix
            </Label>
            <div className="mb-5">
              <Select
                placeholder="Please Select Year"
                options={options}
                value={academicYear}
                onChange={(year) => setAcademicYear(year)}
              />
            </div>
            {!isEmpty(academicYear) && (
              <div className="mt-4">
                {!isEmpty(offerredCourses) &&
                  offerredCourses
                    ?.filter((t) => t.academicYear === academicYear?.value)
                    ?.map((elem, index) => (
                      <div className="college-matrix p-4 pb-3 mt-3 mb-4" key={index}>
                        <h5 className="mb-0">
                          {index + 1}.) {elem?.course?.name} -{' '}
                          <span>
                            <Badge className="bg-secondary">{academicYear?.value}</Badge>
                          </span>
                        </h5>
                        {!isEmpty(academicYear) && (
                          <>
                            <div className="table-responsive mt-2">
                              <Table className="project-list-table table-centered ">
                                <thead>
                                  <tr className="table-warning">
                                    <th>Seat Type</th>
                                    <th>Seat Category</th>
                                    <th>Fees Type</th>
                                    <th>Comments</th>
                                    <th>Total Fees</th>
                                    <th>Currency</th>
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>
                                  {formik.values.feesMatrix[elem.courseId]?.feeInfo?.map((row, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Select
                                          placeholder="Seat Type"
                                          id="seatType"
                                          options={Object.entries(COLLEGE_SEAT_TYPE).map(([key, value]) => ({
                                            value,
                                            label: key,
                                          }))}
                                          value={Object.entries(COLLEGE_SEAT_TYPE)
                                            .filter(([key, value]) => value === row.seatType)
                                            .map(([key, value]) => ({
                                              value,
                                              label: key,
                                            }))}
                                          onChange={(type) =>
                                            formik.setFieldValue(
                                              `feesMatrix[${elem.courseId}][feeInfo][${index}].seatType`,
                                              type.value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Select
                                          placeholder="Seat Category"
                                          id="seatCategory"
                                          options={Object.entries(COLLEGE_SEAT_CATEGORY).map(([key, value]) => ({
                                            value,
                                            label: key,
                                          }))}
                                          value={Object.entries(COLLEGE_SEAT_CATEGORY)
                                            .filter(([key, value]) => value === row.seatCategory)
                                            .map(([key, value]) => ({
                                              value,
                                              label: key,
                                            }))}
                                          onChange={(category) =>
                                            formik.setFieldValue(
                                              `feesMatrix[${elem.courseId}][feeInfo][${index}][seatCategory]`,
                                              category.value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Select
                                          placeholder="Fee type"
                                          id="feesType"
                                          options={Object.entries(COLLEGE_FEE_TYPE).map(([key, value]) => ({
                                            value,
                                            label: key,
                                          }))}
                                          value={Object.entries(COLLEGE_FEE_TYPE)
                                            .filter(([key, value]) => value === row.feesType)
                                            .map(([key, value]) => ({
                                              value,
                                              label: key,
                                            }))}
                                          onChange={(option) =>
                                            formik.setFieldValue(
                                              `feesMatrix[${elem.courseId}][feeInfo][${index}][feesType]`,
                                              option.value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          type="text"
                                          value={row.comments}
                                          onChange={(e) =>
                                            formik.setFieldValue(
                                              `feesMatrix[${elem.courseId}][feeInfo][${index}][comments]`,
                                              e.target.value
                                            )
                                          }
                                          placeholder="Comments"
                                        />
                                      </td>
                                      <td>
                                        <Input
                                          type="number"
                                          value={row.totalFees}
                                          onChange={(e) =>
                                            formik.setFieldValue(
                                              `feesMatrix[${elem.courseId}][feeInfo][${index}][totalFees]`,
                                              parseInt(e.target.value)
                                            )
                                          }
                                          placeholder="Total Fees"
                                        />
                                      </td>
                                      <td>
                                        <Select
                                          placeholder="Currency"
                                          id="currency"
                                          options={Object.entries(CURRENCY_TYPE).map(([key, value]) => ({
                                            value,
                                            label: key,
                                          }))}
                                          value={Object.entries(CURRENCY_TYPE)
                                            .filter(([key, value]) => value === row.currency)
                                            .map(([key, value]) => ({
                                              value,
                                              label: key,
                                            }))}
                                          onChange={(option) =>
                                            formik.setFieldValue(
                                              `feesMatrix[${elem.courseId}][feeInfo][${index}][currency]`,
                                              option.value
                                            )
                                          }
                                        />
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-danger w-100"
                                          onClick={() => removeRow(elem?.courseId, index)}
                                          type="button">
                                          <i className="bx bx-trash" />{' '}
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                                  <tr>
                                    <td colSpan={12} className="p-0">
                                      <button
                                        type="button"
                                        className="btn btn-outline-dark w-100"
                                        onClick={() => {
                                          addRow(elem?.courseId);
                                        }}>
                                        + Add{' '}
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                            <div className="mt-3">
                              <Label className="mb-2"> Fee Details</Label>
                              <Input
                                type="textarea"
                                rows={3}
                                value={formik.values.feesMatrix[elem.courseId]?.feeDetails}
                                onChange={(e) =>
                                  formik.setFieldValue(`feesMatrix[${elem.courseId}][feeDetails]`, e.target.value)
                                }
                                placeholder="Fee Details"
                              />
                            </div>
                            <div className="text-end ">
                              <button
                                type="submit"
                                className="btn btn-primary mt-2 mb-4 px-5 "
                                onClick={() => {
                                  formik.setFieldValue('courseId', elem.courseId);
                                  formik.handleSubmit();
                                }}>
                                Submit
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
              </div>
            )}
            {offerredCourses && <div> Kindly add the courses in Academic Details to proceed</div>}
          </FormGroup>
        </CardBody>
      </Card>
    </Form>
  );
};

export default FeesMatrix;
