import React, { useEffect, useState } from 'react';
import { ACTIVE_AND_DELETED_ARRAY, BabyNameGenderValue, LANGUAGE_VALUES, SORT_VALUES } from '../../../utils/constants';
import { Link, useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import Select from 'react-select';

import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import Loader from '../../../components/Loader';
import { buildURL, checkIfValueExist, printDateTime } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import { Divider } from '@material-ui/core';
import { toast } from 'react-toastify';
import config from 'config/config';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';

const BabynamePronunciationList = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [babyNamesPronunciationList, setbabyNamesPronunciationList] = useState([]);
  const [babyNamesPronunciationPagination, setbabyNamesPronunciationPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getbabyNamesPronunciationRequest, { loading: getbabyNamesPronunciationLoading }] = useRequest({
    url: `/baby-name/admin/pronunciation/search`,
    method: 'POST',
    onSuccess: (data) => {
      setbabyNamesPronunciationList(data?.results);
      setbabyNamesPronunciationPagination(data?.paginator);
    },
  });

  const [updateBabyNameRequest, { loading: updateBabyNameLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success('Success! Baby Name Pronunciation has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getbabyNamesPronunciationRequest({
      body: {
        page,
        ...filters,
        ...SORT_VALUES,
        size: 50,
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getbabyNamesPronunciationRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        ...SORT_VALUES,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const handleActive = (data) => {
    updateBabyNameRequest({
      url: `/baby-name/admin/pronunciation/${data?.id}`,
      body: {
        active: data?.active ? false : true,
      },
    });
  };
  const handleDelete = (data) => {
    updateBabyNameRequest({
      url: `/baby-name/admin/pronunciation/${data?.id}`,
      body: {
        deleted: data?.deleted ? false : true,
      },
    });
  };

  const checkFloatValue = (num) => {
    return Number.isNaN(num) ? 0 : Number.isInteger(num) ? num : Math.ceil(num);
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getbabyNamesPronunciationLoading || updateBabyNameLoading} />
        <Container fluid>
          <Breadcrumbs title="Babynames" breadcrumbItem="Baby Names Pronunciation List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {babyNamesPronunciationPagination?.totalCount} Pronunciation Data Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_BABY_NAME_PRONUNCIATION}>
                  <i className="mdi mdi-plus me-1" />
                  Add Pronunciation
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless ">
                    <thead>
                      <tr align="center" className="table-warning">
                        <th scope="col">#ID</th>
                        <th scope="col">Baby Name</th>
                        <th scope="col">Gender</th>
                        <th scope="col">Language</th>
                        <th scope="col">Accuracy</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Recordings</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(babyNamesPronunciationList) ? (
                        babyNamesPronunciationList?.map((data, index) => {
                          const audioURL = config?.frontendBaseUrl + data?.recording?.original;
                          const accuracyCount = checkFloatValue(
                            (data?.accuracyYesCount /
                              (data?.accuracyYesCount + data?.accuracyNoCount + data?.accuracyNotSureCount)) *
                              100
                          );
                          return (
                            <tr key={index} align="center">
                              <td>{data?.id}</td>
                              <td>
                                <Link
                                  to={buildURL(SMKLinks.EDIT_BABY_NAME_PRONUNCIATION, {
                                    id: data?.id,
                                  })}>
                                  {data?.babyName?.name}
                                </Link>
                              </td>
                              <td>{BabyNameGenderValue[data?.babyName?.genderValue]}</td>
                              <td>{LANGUAGE_VALUES[data?.babyName?.language]}</td>
                              <td align="left">
                                {accuracyCount + '%'}
                                <div className="progress ">
                                  <div
                                    className="progress-bar bg-success "
                                    role="progressbar"
                                    style={{ width: `${accuracyCount}%` }}></div>
                                </div>
                              </td>
                              <td>{printDateTime(data?.createdDate)}</td>
                              <td>
                                <audio src={audioURL} type="audio/mp3" controls />
                              </td>

                              <td>
                                <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                  {data?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop " tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleActive(data)}>
                                      <i
                                        className={`fas  ${
                                          data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                        }   m-1 pe-3`}
                                      />
                                      {data?.active ? 'Disable' : 'Enable'}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleDelete(data)}>
                                      <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                      {data?.deleted ? 'Undelete' : 'Delete'}
                                    </DropdownItem>
                                    <Divider className="my-2" />

                                    <Link
                                      to={buildURL(SMKLinks.EDIT_BABY_NAME_PRONUNCIATION, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={babyNamesPronunciationPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default BabynamePronunciationList;
