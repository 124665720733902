export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const API_ERROR = 'LOGIN_API_ERROR';

export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';

// ==========SMK==============

export const SET_GLOBAL_ERROR = 'global/error';

export const DO_LOGIN = 'smkAdmin/login';
export const DO_LOGOUT = 'smkAdmin/DO_LOGOUT';
