import AuthService from 'common/auth.service';
import React from 'react';
import MediwatcherTaxonomyListing from './components/mediwatcherTaxonomy';
import SmkTaxonomyListing from './components/smkTaxonomyList';
import TpzTaxonomyListing from './components/tpzTaxonomyList';

const TaxonomyListing = () => {
  const auth = new AuthService();
  const source = auth.getAppSourceCookie();

  return source === 'schoolmykids' ? (
    <SmkTaxonomyListing />
  ) : source === 'theparentz' ? (
    <TpzTaxonomyListing />
  ) : source === 'mediwatcher' ? (
    <MediwatcherTaxonomyListing />
  ) : (
    ''
  );
};

export default TaxonomyListing;
