const WORKSHEET_SUBJECTS_TOPICS = 'SMK_ADMIN/WORKSHEET_SUBJECT_TOPICS';
const WORKSHEET_SUBJECTS = 'SMK_ADMIN/WORKSHEET_SUBJECT';
const WORKSHEET_CREATE = 'SMK_ADMIN/WORKSHEET_CREATE';
const WORKSHEET_LIST = 'SMK_ADMIN/WORKSHEET_LIST';
const WORKSHEET_SEARCH = 'SMK_ADMIN/WORKSHEET_SEARCH';
const WORKSHEET_DETAIL = 'SMK_ADMIN/WORKSHEET_DETAIL';
const WORKSHEET_PUBLISH = 'SMK_ADMIN/WORKSHEET_PUBLISH';
const WORKSHEET_REJECT = 'SMK_ADMIN/WORKSHEET_REJECT';
const WORKSHEET_DELETE = 'SMK_ADMIN/WORKSHEET_DELETE';
const WORKSHEET_CATEGORY_CREATE = 'SMK_ADMIN/WORKSHEET_CATEGORY_CREATE';
const WORKSHEET_DETAILS_CLEAR = 'SMK_ADMIN/WORKSHEET_DETAILS_CLEAR';

module.exports = {
  WORKSHEET_DETAIL,
  WORKSHEET_SUBJECTS_TOPICS,
  WORKSHEET_SUBJECTS,
  WORKSHEET_CREATE,
  WORKSHEET_LIST,
  WORKSHEET_SEARCH,
  WORKSHEET_PUBLISH,
  WORKSHEET_DELETE,
  WORKSHEET_REJECT,
  WORKSHEET_CATEGORY_CREATE,
  WORKSHEET_DETAILS_CLEAR,
};
