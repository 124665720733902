import { createSelector } from 'reselect';

import { initialState } from './reducer';

export const LOCATION_STORE_KEY = 'location';

const selectLocationsSubStore = (store) => store[LOCATION_STORE_KEY] || initialState;

export const locationsListSelector = createSelector(selectLocationsSubStore, (subState) => subState.locations);

export const locationsPaginationSelector = createSelector(
  selectLocationsSubStore,
  (subState) => subState.locationsPagination
);

export const countriesSelector = createSelector(selectLocationsSubStore, (subState) => subState.countries);

export const regionsSelector = createSelector(selectLocationsSubStore, (subState) => subState.regions);

export const districtsSelector = createSelector(selectLocationsSubStore, (subState) => subState.districts);

export const citiesSelector = createSelector(selectLocationsSubStore, (subState) => subState.cities);

export const subAreasSelector = createSelector(selectLocationsSubStore, (subState) => subState.subAreas);
