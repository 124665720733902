import { Divider } from '@material-ui/core';
import { userSelector } from 'common/globalComponents/selectors';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import confirm from 'reactstrap-confirm';
import { createStructuredSelector } from 'reselect';
import { ACTIVE_AND_DELETED_ARRAY, LANGUAGE_ARRAY_WITH_STRING, SORT_VALUES } from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { buildURL, checkIfValueExist, getTranslationLabel, urlSlugify } from '../../../utils/helper';
import { createTreeStructure, hasAdminPermission } from '../helper';

const stateSelector = createStructuredSelector({
  loggedInUser: userSelector,
});

const BlogCategoriesList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();
  const { loggedInUser } = useSelector(stateSelector);

  const isArticleAdmin = hasAdminPermission(loggedInUser, 'article');

  const [categoriesList, setCategoriesList] = useState([]);
  const [categoriesListPagination, setCategoriesListPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    name: '',
    language: LANGUAGE_ARRAY_WITH_STRING[0],
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [getCategoriesListRequest, { loading: getCategoriesListLoading }] = useRequest({
    url: `/article/admin/categories/search`,
    method: 'POST',
    onSuccess: (data) => {
      const categoryTree = createTreeStructure(data?.results, 'parentCategoryId');
      setCategoriesList(categoryTree);
      setCategoriesListPagination(data?.pagination);
    },
  });

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Blog Category has been ${data?.active ? 'active' : 'Inactive'}.`);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success!  Blog Category has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getCategoriesListRequest({
      body: {
        page,
        size: 200,
        ...filters,
        active: checkIfValueExist(filters?.active?.value),
        deleted: checkIfValueExist(filters?.deleted?.value),
        language: checkIfValueExist(filters?.language?.value),
        name: urlSlugify(filters?.name),
        ...SORT_VALUES,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters, name: '' });
    getCategoriesListRequest({
      body: {
        page: 1,
        size: 200,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters?.active?.value),
        deleted: checkIfValueExist(defaultFilters?.deleted?.value),
        language: checkIfValueExist(defaultFilters?.language?.value),
        name: '',
        ...SORT_VALUES,
      },
    });
  };

  const handleActive = (data) => {
    markActiveInActiveRequest({
      url: `/article/admin/categories/${data?.id}`,
      body: {
        active: !data?.active,
      },
    });
  };

  const handleDelete = async (data) => {
    const result = await confirm({
      title: `${data?.deleted ? 'Undelete' : 'Delete'} Blog`,
      message: `Are you sure, you want to ${data?.deleted ? 'Undelete' : 'Delete'} this Blog?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      if (data?.deleted) {
        deleteUnDeleteRequest({
          url: `/article/admin/categories/${data?.id}`,
          method: 'PUT',
          body: {
            ...data,
            deleted: false,
          },
        });
      } else {
        deleteUnDeleteRequest({
          url: `/article/admin/categories/${data?.id}`,
          method: 'DELETE',
        });
      }
    }
  };

  const renderCategoryRow = (category, level = 0) => {
    return (
      <React.Fragment key={category?.id}>
        <tr className={!category?.active ? 'bg-danger' : ''}>
          <td>
            {isArticleAdmin ? (
              <Link
                to={buildURL(SMKLinks.EDIT_BLOG_CATEGORY, {
                  id: category?.id,
                })}>
                {category?.name}
              </Link>
            ) : (
              category?.name
            )}
            <br />
            {category?.slug}
          </td>
          <td>{LANGUAGE_ARRAY_WITH_STRING?.find((e) => e?.value === category?.language)?.label}</td>
          <td>
            {isArticleAdmin ? (
              <>
                {!isEmpty(category.translations) && (
                  <Link
                    to={buildURL(SMKLinks.EDIT_BLOG_CATEGORY, {
                      id: category?.translations[0]?.id,
                    })}>
                    {getTranslationLabel(category?.translations[0])} <br />
                    {category?.translations[0]?.slug}
                  </Link>
                )}
              </>
            ) : (
              <>
                {getTranslationLabel(category?.translations[0])} <br />
                {category?.translations[0]?.slug}
              </>
            )}
          </td>
          <td>
            <Badge className={category?.active ? 'bg-success' : 'bg-danger'}>{category?.active ? 'Yes' : 'No'}</Badge>
          </td>
          <td>
            <Badge className={category?.deleted ? 'bg-success' : 'bg-danger'}>{category?.deleted ? 'Yes' : 'No'}</Badge>
          </td>
          <td>
            <a
              target="_blank"
              rel="noreferrer"
              href={`/blog/list?page=1&language=en&tab=published&category=${category?.slug}__${category?.id}`}>
              View in Blog List
            </a>
          </td>
          {isArticleAdmin && (
            <td>
              <UncontrolledDropdown>
                <DropdownToggle href="#" className="card-drop" tag="i">
                  <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem onClick={() => handleActive(category)}>
                    <i
                      className={`fas  ${
                        category?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                      }   m-1 pe-3`}
                    />
                    {category?.active ? 'Disable' : 'Enable'}
                  </DropdownItem>
                  <DropdownItem onClick={() => handleDelete(category)}>
                    <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                    {category?.deleted ? 'Undelete' : 'Delete'}
                  </DropdownItem>
                  <Divider className="my-2" />

                  <Link
                    to={buildURL(SMKLinks.EDIT_BLOG_CATEGORY, {
                      id: category?.id,
                    })}>
                    <DropdownItem>
                      <i className="fas fa-pencil-alt text-success m-1 pe-3" />
                      Edit
                    </DropdownItem>
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          )}
        </tr>

        {category?.subcategories && category?.subcategories.length > 0 && (
          <tr>
            <td
              colSpan="10"
              style={{
                background: 'transparent',
                borderLeft: '1px solid #121212',
                paddingLeft: `${level === 0 ? 40 : level * 40}px`,
              }}>
              <div className="table-responsive">
                <Table className="project-list-table mt-0 table-centered table-borderless">
                  <thead>
                    <tr className="table-warning">
                      <th scope="col">Name</th>
                      <th scope="col">Language</th>
                      <th scope="col">Translation</th>
                      <th scope="col">Active</th>
                      <th scope="col">Deleted</th>
                      <th scope="col"></th>
                      {isArticleAdmin && <th scope="col" />}
                    </tr>
                  </thead>
                  <tbody>{category?.subcategories.map((sub) => renderCategoryRow(sub, level + 1))}</tbody>
                </Table>
              </div>
            </td>
          </tr>
        )}
      </React.Fragment>
    );
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getCategoriesListLoading || markActiveInActiveLoading || deleteUnDeleteLoading} />

        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Blog Categories list" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY_WITH_STRING}
                      value={filters.language}
                      onChange={(value) => setFilters({ ...filters, language: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {categoriesListPagination?.totalCount} Blog Categories found
                </div>
              </div>
            </Col>

            {isArticleAdmin && (
              <Col sm="8">
                <div className="text-sm-end">
                  <Link
                    replace
                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                    to={SMKLinks.ADD_BLOG_CATEGORY}>
                    <i className="mdi mdi-plus me-1" />
                    Add Blog Category
                  </Link>
                </div>
              </Col>
            )}
          </Row>

          <Row>
            <Col lg="12">
              <div className="table-responsive" style={{ minHeight: 300 }}>
                <Table className="project-list-table   table-centered table-borderless">
                  <thead>
                    <tr className="table-warning">
                      <th scope="col">Name</th>
                      <th scope="col">Language</th>
                      <th scope="col">Translation</th>
                      <th scope="col">Active</th>
                      <th scope="col">Deleted</th>
                      <th scope="col"></th>
                      {isArticleAdmin && <th scope="col" />}
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(categoriesList) ? (
                      categoriesList?.map((category) => renderCategoryRow(category))
                    ) : (
                      <TableError />
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={categoriesListPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default BlogCategoriesList;
