import produce from 'immer';
import remove from 'lodash/remove';
import {
  getForumCategories,
  getForumPost,
  getForumPostDetail,
  getForumSubCategories,
  getForumTopic,
  getForumTopicDetail,
  getForumTopicSearch,
  setForumPostApprove,
  setForumPostDeleted,
  setForumPostUnPublish,
  setForumTopicApprove,
  setForumTopicDeleted,
  setForumTopicUnPublish,
} from './actions';

export const initialState = {
  topicList: [],
  topicListPagination: {},
  postList: [],
  postListPagination: {},
  topicSearchList: [],
  forumSubCategories: [],
  forumCategoriesList: [],
  forumCategoriesPagination: {},
  topicDetail: {},
  postDetail: {},
};

const forumReducer = produce((draft, action) => {
  switch (action.type) {
    case getForumTopic.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.topicList = results;
      draft.topicListPagination = pagination;
      break;
    }

    case getForumPost.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.postList = results;
      draft.postListPagination = pagination;
      break;
    }

    case getForumTopicSearch.SUCCESS:
      draft.topicSearchList = action.payload.results;
      break;

    case getForumTopicDetail.SUCCESS: {
      const { results } = action.payload;
      if (results) {
        draft.topicDetail = results[0];
      }
      break;
    }

    case getForumPostDetail.SUCCESS: {
      const { results } = action.payload;
      if (results) {
        draft.postDetail = results[0];
      }
      console.log(`in reducer ${draft}`);
      break;
    }
    case getForumCategories.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.forumCategoriesList = results;
      draft.forumCategoriesPagination = pagination;
      break;
    }

    case getForumSubCategories.SUCCESS:
      draft.forumSubCategories = action.payload.results.map((category) => ({
        ...category,
        label: category.name,
        value: category.slug,
      }));
      break;

    case setForumPostApprove.SUCCESS: {
      const { post, detailPage } = action.payload;
      if (!detailPage) {
        draft.postList = remove(draft.postList, (n) => n.id !== post.id);
      } else {
        draft.postDetail = post;
      }
      break;
    }

    case setForumPostDeleted.SUCCESS: {
      const { post, detailPage } = action.payload;
      if (!detailPage) {
        draft.postList = remove(draft.postList, (n) => n.id !== post.id);
      } else {
        draft.postDetail = post;
      }
      break;
    }

    case setForumPostUnPublish.SUCCESS: {
      const { post } = action.payload;
      draft.postDetail = post;
      break;
    }

    case setForumTopicUnPublish.SUCCESS: {
      const { topic } = action.payload;
      draft.topicDetail = topic;
      break;
    }

    case setForumTopicApprove.SUCCESS: {
      const { topic, detailPage } = action.payload;
      if (!detailPage) {
        draft.topicList = remove(draft.topicList, (n) => n.id !== topic.id);
      } else {
        draft.topicDetail = topic;
      }
      break;
    }

    case setForumTopicDeleted.SUCCESS: {
      const { topic, detailPage } = action.payload;
      if (!detailPage) {
        draft.topicList = remove(draft.topicList, (n) => n.id !== topic.id);
      } else {
        draft.topicDetail = topic;
      }
      break;
    }

    // case '@@router/LOCATION_CHANGE':
    //   return initialState;

    default:
      break;
  }
}, initialState);

export default forumReducer;
