import produce from 'immer';
import { getUserList, getUserProfile, getUserSearchList } from './actions';

export const initialState = {
  users: [],
  userSearchList: [],
  userProfile: {},
  userPagination: {},
};

const userReducer = produce((draft, action) => {
  switch (action.type) {
    case getUserList.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.users = results;
      draft.userPagination = pagination;
      break;
    }

    case getUserSearchList.SUCCESS: {
      const { results } = action.payload;
      draft.userSearchList = results;
      break;
    }

    case getUserProfile.SUCCESS: {
      const { results } = action.payload;
      draft.userProfile = results;
      break;
    }

    case '@@router/LOCATION_CHANGE':
      return initialState;

    default:
      break;
  }
}, initialState);

export default userReducer;
