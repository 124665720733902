import Loader from 'components/Loader';
import config from 'config/config';
import { getCountries } from 'containers/school/actions';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import { LANGUAGE_VALUES_STRING } from 'utils/constants';
import { PRINT_LANGUAGE } from '../../../utils/constants';

const UpdateBabynamePronunciation = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: babynameId } = useParams();

  toast.configure();
  const [babyNameDetails, setBabyNameDetails] = useState();
  const [originList, setOriginList] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const audioURL = config?.frontendBaseUrl + babyNameDetails?.recording?.original;

  const [addBabyNameRequest, { loading: addBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/pronunciation`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.BABY_NAME_PRONUNCIATION;
      history.replace(url);
      toast.success('Success! Baby Name Meaning origin has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });
  const [getBabyNameRequest, { loading: getBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/pronunciation/${babynameId}`,
    method: 'GET',
    onSuccess: (data) => {
      setBabyNameDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateBabyNameRequest, { loading: updateBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/pronunciation/${babynameId}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.BABY_NAME_PRONUNCIATION;
      history.replace(url);
      toast.success('Success! Baby Name Meaning origin has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [getbabyNameMetaRequest, { loading: getbabyNameMetaLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      setOriginList(
        data?.results
          ?.filter((t) => t.type === 4 && t.language === PRINT_LANGUAGE.english)
          .map((r) => ({ ...r, label: r.name, value: r.id }))
      );
    },
  });
  const [getCountriesData, { loading: getCountriesLoading }] = useRequest({
    url: `/place/allCountries`,
    method: 'GET',
    onSuccess: (data) => {
      setCountriesList(data);
    },
  });

  const editInitialValues = () => {
    const originObj = originList?.filter((e) => e?.id == babyNameDetails?.originId);
    const countryObj = countriesList?.filter((e) => e?.name == babyNameDetails?.location);
    const filteredOriginObj = originObj[0];
    const filteredCountryObj = countryObj[0];

    return {
      createdById: babyNameDetails?.createdById,
      babyNameId: babyNameDetails?.babyNameId,
      accuracyYesCount: babyNameDetails?.accuracyYesCount,
      accuracyNoCount: babyNameDetails?.accuracyNoCount,
      accuracyNotSureCount: babyNameDetails?.accuracyNotSureCount,
      active: babyNameDetails?.active,
      deleted: babyNameDetails?.deleted,
      origin: filteredOriginObj && {
        label: filteredOriginObj?.name,
        value: filteredOriginObj?.id,
      },
      country: filteredCountryObj && {
        label: filteredCountryObj?.name,
        value: filteredCountryObj?.id,
      },
    };
  };

  const getInitialValues = () => ({
    createdById: '',
    babyNameId: '',
    accuracyYesCount: '',
    accuracyNoCount: '',
    accuracyNotSureCount: '',
    active: true,
    deleted: false,
    origin: '',
    country: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(babynameId) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: (values) => {
      const data = {
        createdById: values.createdById,
        babyNameId: values.babyNameId,
        accuracyYesCount: values.accuracyYesCount,
        accuracyNoCount: values.accuracyNoCount,
        accuracyNotSureCount: values.accuracyNotSureCount,
        active: values.active,
        deleted: values.deleted,
        originId: values.origin?.value,
        location: values.country?.label,
      };
      isEmpty(babynameId)
        ? addBabyNameRequest({
            body: {
              ...data,
            },
          })
        : updateBabyNameRequest({
            body: {
              ...data,
            },
          });
    },
  });

  useEffect(() => {
    getCountriesData();
    dispatch(getCountries.trigger({ page: 1, size: 500 }));
    getbabyNameMetaRequest({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: -1,
        language: LANGUAGE_VALUES_STRING.english,
      },
    });
  }, []);

  useEffect(() => {
    if (!isEmpty(babynameId)) {
      getBabyNameRequest();
    }
  }, [babynameId]);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            addBabyNameLoading ||
            getbabyNameMetaLoading ||
            getCountriesLoading ||
            getBabyNameLoading ||
            updateBabyNameLoading
          }
        />
        <Container fluid>
          <Breadcrumbs
            title="Babynames"
            breadcrumbItem={`${isEmpty(babynameId) ? 'Add' : 'Edit'}  Babyname Pronunciation`}
          />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="babynameId" className="form-label  ">
                            Baby Name ID
                          </Label>
                          <Input
                            id="babynameId"
                            type="number"
                            className="form-control"
                            placeholder="Baby Name ID..."
                            invalid={!!(formik?.touched?.babyNameId && formik.errors.babyNameId)}
                            {...formik.getFieldProps('babyNameId')}
                            error={!isEmpty(formik.errors.babyNameId)}
                          />
                          <FormFeedback>{formik.errors.babyNameId}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="createdById" className="form-label  ">
                            Created By ID
                          </Label>
                          <Input
                            id="createdById"
                            type="number"
                            className="form-control"
                            placeholder="Created By ID..."
                            invalid={!!(formik.touched.createdById && formik.errors.createdById)}
                            {...formik.getFieldProps('createdById')}
                            error={!isEmpty(formik.errors.createdById)}
                          />
                          <FormFeedback>{formik.errors.createdById}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="baby_origin" className="form-label  ">
                            Origin
                          </Label>
                          <Select
                            id="baby_origin"
                            placeholder="Select..."
                            value={formik.values.origin}
                            onChange={(origin) => formik.setFieldValue('origin', origin)}
                            options={originList?.map((e) => ({
                              label: e?.name,
                              value: e?.id,
                            }))}
                            isClearable
                          />
                          {formik.touched.origin && (
                            <div className="invalid-feedback d-block">{formik.errors.origin}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="country" className="form-label  ">
                            Country
                          </Label>
                          <Select
                            id="country"
                            placeholder="Select..."
                            value={formik.values.country}
                            onChange={(country) => formik.setFieldValue('country', country)}
                            options={countriesList?.map((e) => ({
                              label: e?.name,
                              value: e?.id,
                            }))}
                            isClearable
                          />
                          {formik.touched.country && (
                            <div className="invalid-feedback d-block">{formik.errors.country}</div>
                          )}
                        </FormGroup>
                      </Col>
                      {babyNameDetails?.recording && (
                        <Col md={6} className="mt-3 ps-4">
                          <audio src={audioURL} type="audio/mp3" controls />
                        </Col>
                      )}

                      <h5 className="mt-3 border-bottom pb-4">Accuracy Count</h5>
                      <Row className="mt-4">
                        <Col md={4}>
                          <FormGroup className="mb-4">
                            <Label for="accuracyYesCount" className="form-label  ">
                              Yes Count
                            </Label>
                            <Input
                              id="accuracyYesCount"
                              type="number"
                              className="form-control"
                              placeholder=" Accuracy yes count..."
                              invalid={!!(formik.touched.accuracyYesCount && formik.errors.accuracyYesCount)}
                              {...formik.getFieldProps('accuracyYesCount')}
                              error={!isEmpty(formik.errors.accuracyYesCount)}
                            />
                            <FormFeedback>{formik.errors.accuracyYesCount}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup className="mb-4">
                            <Label for="accuracyNoCount" className="form-label  ">
                              No Count
                            </Label>
                            <Input
                              id="accuracyNoCount"
                              type="number"
                              className="form-control"
                              placeholder=" Accuracy no count..."
                              invalid={!!(formik.touched.accuracyNoCount && formik.errors.accuracyNoCount)}
                              {...formik.getFieldProps('accuracyNoCount')}
                              error={!isEmpty(formik.errors.accuracyNoCount)}
                            />
                            <FormFeedback>{formik.errors.accuracyNoCount}</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup className="mb-4">
                            <Label for="accuracyNotSureCount" className="form-label  ">
                              Not Sure Count
                            </Label>
                            <Input
                              id="accuracyNotSureCount"
                              type="number"
                              className="form-control"
                              placeholder=" Accuracy Not sure count..."
                              invalid={!!(formik.touched.accuracyNotSureCount && formik.errors.accuracyNotSureCount)}
                              {...formik.getFieldProps('accuracyNotSureCount')}
                              error={!isEmpty(formik.errors.accuracyNotSureCount)}
                            />
                            <FormFeedback>{formik.errors.accuracyNotSureCount}</FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100 mb-3">
                      Save Name
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.BABY_NAME_PRONUNCIATION);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <div>
                      <CardTitle className="mb-3">Active</CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateBabynamePronunciation;
