import React from 'react';
import PropTypes from 'prop-types';

const Loader = (props) => {
  return (
    props.isActive && (
      <div id="preloader" style={{ backgroundColor: 'rgba(0,0,0,0.2)' }}>
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>
    )
  );
};

Loader.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default Loader;
