import { useFormik } from 'formik';
// actions
import isEmpty from 'lodash/isEmpty';
import * as moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { SMKLinks } from 'utils/links';
import * as Yup from 'yup';
import { schoolClearCache } from 'containers/school/schoolDetailsHelper';
import { userSelector } from '../../../../common/globalComponents/selectors';
import Loader from '../../../../components/Loader';
// components
import TextEditor from '../../../../components/TextEditor';
import useRequest from '../../../../hooks/useRequest';
import { urlSlugify } from '../../../../utils/helper';
import { updateSchoolSystemInfo } from '../../actions';
// selectors
import { schoolDetailSelector } from '../../selectors';
import FetchReviews from './fetchReviews';

const stateSelector = createStructuredSelector({
  schoolDetail: schoolDetailSelector,
  user: userSelector,
});

const SystemInfo = () => {
  const { user, schoolDetail } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const getInitialFormValues = () => {
    const {
      displayName,
      searchName,
      slug,
      uuid,
      showDetailedView,
      closed,
      systemInfo,
      active,
      schoolIds,
      claimed,
      claimedDate,
    } = schoolDetail;

    const values = {};

    values.displayName = displayName || '';
    values.searchName = searchName || '';
    values.slug = slug || '';

    values.uuid = uuid || '';
    values.verified = systemInfo?.verified ? 'Yes' : 'No';
    values.showDetailedView = showDetailedView ? 'Yes' : 'No';
    values.schoolClosed = closed ? 'Yes' : 'No';
    values.published = active ? 'Yes' : 'No';
    values.notes = systemInfo?.notes || '';
    values.googleMapsUrl = systemInfo?.googleMapsUrl || '';

    values.schoolIds = !isEmpty(schoolIds) ? schoolIds : [{ name: '', id: '' }];

    values.claimed = claimed ? 'Yes' : 'No' || false;
    values.claimedDate = claimedDate ? moment(claimedDate).toDate() : '';

    return values;
  };

  const [updateSystemInfoRequest, { loading: updateSystemInfoLoading }] = useRequest({
    url: '/school/admin/edit/updateSystemInfo',
    method: 'POST',
    onSuccess: (data) => {
      schoolClearCache({
        urlSlug: schoolDetail?.slug,
        schoolId: schoolDetail?.id,
      });
      window.location.reload();
    },
  });

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialFormValues(),
    onSubmit(values) {
      updateSystemInfoRequest({
        data: {
          schoolId: schoolDetail.id,
          displayName: values.displayName,
          searchName: values.searchName,
          slug: values.slug,
          active: values.published === 'Yes',
          systemInfo: {
            verified: values.verified === 'Yes',
            notes: values.notes,
            googleMapsUrl: values.googleMapsUrl,
          },
          claimed: values.claimed === 'Yes',
          showDetailedView: values.showDetailedView === 'Yes',
          closed: values.schoolClosed === 'Yes',
          claimedDate: values.claimedDate,
          schoolIds: values.schoolIds,
        },
      });
    },
    validationSchema: Yup.object().shape({
      displayName: Yup.string().nullable(),
      searchName: Yup.string().nullable(),
      slug: Yup.string().nullable(),
      uuid: Yup.string().nullable(),
      verified: Yup.string().oneOf(['Yes', 'No']),
      published: Yup.string().oneOf(['Yes', 'No']),
      notes: Yup.string().nullable(),
      googleMapsUrl: Yup.string().nullable(),
      claimed: Yup.string().oneOf(['Yes', 'No']),
      claimedDate: Yup.string().nullable(),
    }),
  });

  const refreshSlug = (e) => {
    e.preventDefault();
    const fullName =
      schoolDetail.name +
      (schoolDetail.address && schoolDetail.address.subArea && schoolDetail.address.subArea.name
        ? `, ${schoolDetail.address.subArea.name}`
        : '') +
      (schoolDetail.address && schoolDetail.address.city && schoolDetail.address.city.name
        ? `, ${schoolDetail.address.city.name}`
        : '') +
      (schoolDetail.address && schoolDetail.address.region && schoolDetail.address.region.name
        ? `, ${schoolDetail.address.region.name}`
        : '') +
      (schoolDetail.address && schoolDetail.address.country && schoolDetail.address.country.name
        ? `, ${schoolDetail.address.country.name}`
        : '');
    formik.setFieldValue('slug', urlSlugify(fullName));
  };

  const resetSlug = (e) => {
    e.preventDefault();
    formik.setFieldValue('slug', schoolDetail.slug);
  };

  const handleAddSchoolId = () => {
    formik.setFieldValue('schoolIds', [
      ...formik.values.schoolIds,
      {
        name: '',
        id: '',
      },
    ]);
  };

  const handleRemoveSchoolId = (index) => {
    const array = formik.values.schoolIds.slice();
    array.splice(index, 1);
    formik.setFieldValue('schoolIds', array);
  };

  const handleUpdateSchoolId = (index, key, value) => {
    const array = formik.values.schoolIds.slice();
    array[index] = { ...array[index], [key]: value };
    formik.setFieldValue('schoolIds', array);
  };

  const [fetchReviewRequest, { loading: fetchReviewLoading }] = useRequest({
    url: `/school/admin/fetchReviews`,
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      window.location.reload();
    },
  });

  const fetchReviews = () => {
    fetchReviewRequest({
      url: `/school/admin/fetchReviews/${schoolDetail?.id}`,
    });
  };

  const renderSchoolIds = () => {
    return (
      <>
        <CardTitle>School IDs</CardTitle>

        {formik.values.schoolIds.map((schoolId, i) => (
          <Row key={`schoolIds_${i}`}>
            <Col md={4}>
              <FormGroup className="mb-4">
                <Label htmlFor="schoolId_name" className="form-label  ">
                  Name
                </Label>
                <Input
                  id="schoolId_name"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder="Name..."
                  value={schoolId.name}
                  onChange={(e) => handleUpdateSchoolId(i, 'name', e.target.value)}
                />
                {!isEmpty(formik.errors.contactPerson) && (
                  <div className="invalid-feedback d-block">{formik.errors.schoolIds[i]?.name}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup className="mb-4">
                <Label htmlFor="person_id" className="form-label  ">
                  Id
                </Label>
                <Input
                  id="person_id"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder="ID..."
                  value={schoolId.id}
                  onChange={(e) => handleUpdateSchoolId(i, 'id', e.target.value)}
                />
                {!isEmpty(formik.errors.schoolIds) && (
                  <div className="invalid-feedback d-block">{formik.errors.schoolIds[i]?.id}</div>
                )}
              </FormGroup>
            </Col>
            <Col sm={2}>
              <FormGroup className="mb-4">
                <Button color="danger" className="mt-4" onClick={() => handleRemoveSchoolId(i)}>
                  <i className="bx bx-trash" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        ))}

        <Row>
          <Col>
            <Button className="w-100 mt-3" color="light" onClick={handleAddSchoolId}>
              <i className="bx bx-plus me-2" />
              Add School ID
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateSystemInfoLoading || fetchReviewLoading} />

      <h5 className="mb-4">System Info</h5>
      <Card>
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="display_name" className="form-label  ">
              Display Name
            </Label>
            <Input
              id="display_name"
              type="text"
              className="form-control"
              placeholder="School Display Name..."
              invalid={!!(formik.touched.displayName && formik.errors.displayName)}
              {...formik.getFieldProps('displayName')}
            />
            <FormFeedback>{formik.errors.displayName}</FormFeedback>
          </FormGroup>

          <FormGroup className="mb-4">
            <Label htmlFor="searchName" className="form-label  ">
              Search Name
            </Label>
            <p className="border p-2">{formik.values?.searchName}</p>
            <FormFeedback>{formik.errors.searchName}</FormFeedback>
          </FormGroup>

          <FormGroup className="mb-4">
            <Label htmlFor="slug" className="form-label  ">
              Slug
            </Label>

            {schoolDetail?.active ? (
              <p className="border p-2">{formik.values?.slug}</p>
            ) : (
              <Input
                id="slug"
                type="text"
                className={`form-control ${
                  !formik.values?.slug?.includes(`-s100${schoolDetail.id}`) ? ' text-danger' : ''
                }`}
                placeholder="School Name..."
                // slug editable if school not published
                readOnly={schoolDetail.active}
                invalid={!!(formik.touched.slug && formik.errors.slug)}
                {...formik.getFieldProps('slug')}
              />
            )}
            <FormFeedback>{formik.errors.slug}</FormFeedback>
            {!schoolDetail.active && formik.values?.slug?.includes(`-s100${schoolDetail.id}`) && (
              <Button type="submit" color="danger" onClick={(e) => refreshSlug(e)} className="btn-sm mt-2">
                Refresh Slug
              </Button>
            )}

            {!formik.values?.slug?.includes(`-s100${schoolDetail.id}`) && (
              <Button type="submit" color="primary" onClick={(e) => resetSlug(e)} className="btn-sm mt-2">
                Reset Slug
              </Button>
            )}
          </FormGroup>
        </CardBody>
      </Card>
      <Card>
        <CardBody> {renderSchoolIds()} </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardTitle>UUID</CardTitle>
          <FormGroup className="mb-4">
            <Input
              type="text"
              className="form-control"
              placeholder="UUID..."
              readOnly
              {...formik.getFieldProps('uuid')}
            />
          </FormGroup>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <CardTitle>Verify School</CardTitle>
              <div>
                {['Yes', 'No'].map((option, i) => (
                  <div key={`verified_${i}`} className="form-check mb-3 d-inline-block me-4">
                    <input
                      type="radio"
                      id={`verified_${option}`}
                      name="school_verified"
                      className="form-check-input"
                      checked={formik.values.verified === option}
                      onChange={() => formik.setFieldValue('verified', option)}
                    />
                    <label className="form-check-label" htmlFor={`verified_${option}`}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </Col>

            <Col md={6} className="mt-3">
              <CardTitle>Show Detailed View</CardTitle>
              <div>
                {['Yes', 'No'].map((option, i) => (
                  <div key={`showDetailedView_${i}`} className="form-check mb-3 d-inline-block me-4">
                    <input
                      type="radio"
                      id={`showDetailedView_${option}`}
                      name="school_showDetailedView"
                      className="form-check-input"
                      checked={formik.values.showDetailedView === option}
                      onChange={() => formik.setFieldValue('showDetailedView', option)}
                    />
                    <label className="form-check-label" htmlFor={`showDetailedView_${option}`}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </Col>
            <Col md={6} className="mt-3">
              <CardTitle>School Closed</CardTitle>
              <div>
                {['Yes', 'No'].map((option, i) => (
                  <div key={`schoolClosed_${i}`} className="form-check mb-3 d-inline-block me-4">
                    <input
                      type="radio"
                      id={`schoolClosed_${option}`}
                      name="schoolClosed_"
                      className="form-check-input"
                      checked={formik.values.schoolClosed === option}
                      onChange={() => formik.setFieldValue('schoolClosed', option)}
                    />
                    <label className="form-check-label" htmlFor={`schoolClosed_${option}`}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <CardTitle>Claimed School</CardTitle>
              <div>
                {['Yes', 'No'].map((option, i) => (
                  <div key={`claimed_${i}`} className="form-check mb-3 d-inline-block me-4">
                    <input
                      type="radio"
                      id={`claimed_${option}`}
                      name="school_claimed"
                      className="form-check-input"
                      checked={formik.values.claimed === option}
                      onChange={() => formik.setFieldValue('claimed', option)}
                    />
                    <label className="form-check-label" htmlFor={`claimed_${option}`}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </Col>

            <Col md={6}>
              <CardTitle>Claimed Date</CardTitle>
              <InputGroup id="startDate">
                <DatePicker
                  selected={formik.values.claimedDate}
                  className="form-control"
                  showYearDropdown
                  placeholderText="Select date"
                  autoComplete="off"
                  minDate={moment('2020-01-01').toDate()}
                  maxDate={moment().toDate()}
                  onChange={(date) => formik.setFieldValue('claimedDate', date)}
                  invalid={!!(formik.touched.claimedDate && formik.errors.claimedDate)}
                />
              </InputGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="notes" className="form-label  ">
              Notes
            </Label>
            <TextEditor
              simple
              initialValue={formik.values.notes}
              onChange={(val) => formik.setFieldValue('notes', val)}
            />
          </FormGroup>
        </CardBody>
      </Card>

      <Card className="mb-4">
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="googleMapsUrl" className="form-label d-flex justify-content-between">
              Google Maps Url
              <span>
                Click here to get the url:
                <a
                  className="ms-2"
                  target="_blank"
                  href={`https://www.google.com/maps/search/${schoolDetail?.displayName} ${schoolDetail?.address?.city?.name} ${schoolDetail?.address?.region?.name} ${schoolDetail?.address?.country?.name}`}
                  rel="noreferrer">
                  Google Maps
                </a>
              </span>
            </Label>
          </FormGroup>
          <FetchReviews schoolDetail={schoolDetail} schoolFormik={formik} />
        </CardBody>
      </Card>

      {/* <div className="d-flex justify-content-between">
            <p>
              <b>Last Review Fetch:</b>{' '}
              {schoolDetail?.systemInfo?.lastReviewFetchedOn
                ? moment(schoolDetail?.systemInfo?.lastReviewFetchedOn).format('DD-MMM-YYYY @ hh:mm a')
                : ''}
            </p>
            {schoolDetail.systemInfo?.googleMapsUrl && (
              <a className="btn btn-light" onClick={() => fetchReviews()}>
                Fetch Reviews
              </a>
            )}
          </div> */}

      {(user?.entityPermissions?.school.admin ||
        (schoolDetail.editable &&
          (schoolDetail?.createdBy?.id === user.id || schoolDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Information
          </Button>

          {schoolDetail?.systemInfo?.verified && !schoolDetail?.active && (
            <Button
              className="mx-4"
              color="success"
              onClick={() => {
                formik.setFieldValue('published', 'Yes');
                formik.handleSubmit();
              }}>
              Publish School
            </Button>
          )}
          {schoolDetail?.active && (
            <Button
              className="mx-4"
              color="warning"
              onClick={() => {
                formik.setFieldValue('published', 'No');
                formik.handleSubmit();
              }}>
              Unpublish School
            </Button>
          )}

          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.SCHOOL_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

SystemInfo.propTypes = {};

export default SystemInfo;
