import { PUBLIC_URL } from '../../utils/constants';

export default {
  WORKSHEET_ADD: `${PUBLIC_URL}/worksheet/add`,
  WORKSHEET_CATEGORIES: `${PUBLIC_URL}/worksheet/categories`,
  WORKSHEET_LISTING_BASIC: `${PUBLIC_URL}/worksheet-basic`,

  WORKSHEET_LISTING: `${PUBLIC_URL}/worksheet`,
  WORKSHEET_DETAIL: `${PUBLIC_URL}/worksheet/detail/:id`,
  WORKSHEET_EDIT: `${PUBLIC_URL}/worksheet/edit/:id`,
  WORKSHEET_APPROVER: `${PUBLIC_URL}/worksheet/approver`,
  WORKSHEET_SUBJECT_TOPIC: `${PUBLIC_URL}/worksheet/subjecttopic`,
  WORKSHEET_SUBJECT: `${PUBLIC_URL}/worksheet/subjects`,
};
