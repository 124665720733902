import { useMsal } from '@azure/msal-react';
import { useRouter } from 'hooks/useRouter';
import React from 'react';

const MicrosoftLogin = ({ userType }) => {
  const { instance } = useMsal();
  const router = useRouter();

  const handleLogin = async () => {
    await instance
      .loginPopup({
        scopes: ['User.Read'],
      })
      .catch((error) => console.error(error));
    await router.replace(`/auth/microsoft?userType=${userType}`);
  };

  return (
    <div>
      <div className="mt-3">
        <button type="button" className="btn btn-light w-100" onClick={handleLogin}>
          Sign in with Microsoft 🚀
        </button>
      </div>
    </div>
  );
};

export default MicrosoftLogin;
