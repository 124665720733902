import { useFormik } from 'formik';
// actions
import isEmpty from 'lodash/isEmpty';
import * as moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { SMKLinks } from 'utils/links';
import * as Yup from 'yup';
import { userSelector } from '../../../../common/globalComponents/selectors';
import Loader from '../../../../components/Loader';
// components
import TextEditor from '../../../../components/TextEditor';
import useRequest from '../../../../hooks/useRequest';
import { urlSlugify } from '../../../../utils/helper';
import { updateCollegeSystemDetails } from '../../actions';
// selectors
import { collegeDetailSelector } from '../../selectors';
import FetchReviews from './fetchReviews';

const stateSelector = createStructuredSelector({
  collegeDetail: collegeDetailSelector,
  user: userSelector,
});

const SystemDetails = () => {
  const { user, collegeDetail } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const getInitialFormValues = () => {
    const {
      displayName,
      searchName,
      slug,
      id,
      showDetailedView,
      closed,
      systemDetails,
      active,
      externalIds,
      claimed,
      claimedDate,
    } = collegeDetail;

    const values = {};

    values.displayName = displayName || '';
    values.searchName = searchName || '';
    values.slug = slug || '';

    values.uuid = id || '';
    values.verified = systemDetails?.verified ? 'Yes' : 'No';
    values.showDetailedView = showDetailedView ? 'Yes' : 'No';
    values.collegeClosed = closed ? 'Yes' : 'No';
    values.published = active ? 'Yes' : 'No';
    values.notes = systemDetails?.notes || '';
    values.googleMapsUrl = systemDetails?.googleMapsUrl || '';

    values.collegeIds = !isEmpty(externalIds) ? externalIds : [{ name: '', id: '' }];

    values.claimed = claimed ? 'Yes' : 'No' || false;
    values.claimedDate = claimedDate !== null || claimedDate !== '' ? moment(claimedDate).toDate() : '';

    return values;
  };

  const [updateSystemDetailsRequest, { loading: updateSystemDetailsLoading }] = useRequest({
    url: '/college/admin/edit/updateSystemDetails',
    method: 'POST',
    onSuccess: (data) => {
      window.location.reload();
    },
  });

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialFormValues(),
    onSubmit(values) {
      updateSystemDetailsRequest({
        data: {
          collegeId: collegeDetail.id,
          displayName: values.displayName,
          searchName: values.searchName,
          slug: values.slug,
          active: values.published === 'Yes',
          systemDetails: {
            verified: values.verified === 'Yes',
            notes: values.notes,
            googleMapsUrl: values.googleMapsUrl,
          },
          claimed: values.claimed === 'Yes',
          showDetailedView: values.showDetailedView === 'Yes',
          closed: values.collegeClosed === 'Yes',
          claimedDate: values.claimedDate,
          collegeIds: values.collegeIds,
        },
      });
    },
    validationSchema: Yup.object().shape({
      displayName: Yup.string().nullable(),
      searchName: Yup.string().nullable(),
      slug: Yup.string().nullable(),
      uuid: Yup.string().nullable(),
      verified: Yup.string().oneOf(['Yes', 'No']),
      published: Yup.string().oneOf(['Yes', 'No']),
      notes: Yup.string().nullable(),
      googleMapsUrl: Yup.string().nullable(),
      claimed: Yup.string().oneOf(['Yes', 'No']),
      claimedDate: Yup.date().nullable(),
    }),
  });

  const refreshSlug = (e) => {
    e.preventDefault();
    const fullName =
      collegeDetail.name +
      (collegeDetail.address && collegeDetail.address.subArea && collegeDetail.address.subArea.name
        ? `, ${collegeDetail.address.subArea.name}`
        : '') +
      (collegeDetail.address && collegeDetail.address.city && collegeDetail.address.city.name
        ? `, ${collegeDetail.address.city.name}`
        : '') +
      (collegeDetail.address && collegeDetail.address.region && collegeDetail.address.region.name
        ? `, ${collegeDetail.address.region.name}`
        : '') +
      (collegeDetail.address && collegeDetail.address.country && collegeDetail.address.country.name
        ? `, ${collegeDetail.address.country.name}`
        : '');
    formik.setFieldValue('slug', urlSlugify(fullName));
  };

  const resetSlug = (e) => {
    e.preventDefault();
    formik.setFieldValue('slug', collegeDetail.slug);
  };

  const handleaddCollegeId = () => {
    formik.setFieldValue('collegeIds', [
      ...formik.values.collegeIds,
      {
        name: '',
        id: '',
      },
    ]);
  };

  const handleRemoveCollegeId = (index) => {
    const array = formik.values.collegeIds.slice();
    array.splice(index, 1);
    formik.setFieldValue('collegeIds', array);
  };

  const handleUpdateCollegeId = (index, key, value) => {
    const array = formik.values.collegeIds.slice();
    array[index] = { ...array[index], [key]: value };
    formik.setFieldValue('collegeIds', array);
  };

  const [fetchReviewRequest, { loading: fetchReviewLoading }] = useRequest({
    url: `/college/admin/fetchReviews`,
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      window.location.reload();
    },
  });

  const fetchReviews = () => {
    fetchReviewRequest({
      url: `/college/admin/fetchReviews/${collegeDetail?.id}`,
    });
  };

  const rendercollegeIds = () => {
    return (
      <>
        <CardTitle>College IDs</CardTitle>

        {formik.values.collegeIds.map((collegeId, i) => (
          <Row key={`collegeIds_${i}`}>
            <Col md={4}>
              <FormGroup className="mb-4">
                <Label htmlFor="collegeId_name" className="form-label  ">
                  Name
                </Label>
                <Input
                  id="collegeId_name"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder="Name..."
                  value={collegeId.name}
                  onChange={(e) => handleUpdateCollegeId(i, 'name', e.target.value)}
                />
                {!isEmpty(formik.errors.contactPerson) && (
                  <div className="invalid-feedback d-block">{formik.errors.collegeIds[i]?.name}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup className="mb-4">
                <Label htmlFor="person_id" className="form-label  ">
                  Id
                </Label>
                <Input
                  id="person_id"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder="ID..."
                  value={collegeId.id}
                  onChange={(e) => handleUpdateCollegeId(i, 'id', e.target.value)}
                />
                {!isEmpty(formik.errors.collegeIds) && (
                  <div className="invalid-feedback d-block">{formik.errors.collegeIds[i]?.id}</div>
                )}
              </FormGroup>
            </Col>
            <Col sm={2}>
              <FormGroup className="mb-4">
                <Button color="danger" className="mt-4" onClick={() => handleRemoveCollegeId(i)}>
                  <i className="bx bx-trash" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        ))}

        <Row>
          <Col>
            <Button className="w-100 mt-3" color="light" onClick={handleaddCollegeId}>
              <i className="bx bx-plus me-2" />
              Add College ID
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateSystemDetailsLoading || fetchReviewLoading} />

      <h5 className="mb-4">System Details</h5>
      <Card>
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="display_name" className="form-label  ">
              Display Name
            </Label>
            <Input
              id="display_name"
              type="text"
              className="form-control"
              placeholder="College Display Name..."
              invalid={!!(formik.touched.displayName && formik.errors.displayName)}
              {...formik.getFieldProps('displayName')}
            />
            <FormFeedback>{formik.errors.displayName}</FormFeedback>
          </FormGroup>

          <FormGroup className="mb-4">
            <Label htmlFor="searchName" className="form-label  ">
              Search Name
            </Label>
            <p className="border p-2">{formik.values?.searchName}</p>
            <FormFeedback>{formik.errors.searchName}</FormFeedback>
          </FormGroup>

          <FormGroup className="mb-4">
            <Label htmlFor="slug" className="form-label  ">
              Slug
            </Label>

            {collegeDetail?.active ? (
              <p className="border p-2">{formik.values?.slug}</p>
            ) : (
              <Input
                id="slug"
                type="text"
                className={`form-control ${
                  !formik.values?.slug?.includes(`-s100${collegeDetail.id}`) ? ' text-danger' : ''
                }`}
                placeholder="College Name..."
                // slug editable if college not published
                readOnly={collegeDetail.active}
                invalid={!!(formik.touched.slug && formik.errors.slug)}
                {...formik.getFieldProps('slug')}
              />
            )}
            <FormFeedback>{formik.errors.slug}</FormFeedback>
            {!collegeDetail.active && formik.values?.slug?.includes(`-s100${collegeDetail.id}`) && (
              <Button type="submit" color="danger" onClick={(e) => refreshSlug(e)} className="btn-sm mt-2">
                Refresh Slug
              </Button>
            )}

            {!formik.values?.slug?.includes(`-s100${collegeDetail.id}`) && (
              <Button type="submit" color="primary" onClick={(e) => resetSlug(e)} className="btn-sm mt-2">
                Reset Slug
              </Button>
            )}
          </FormGroup>
        </CardBody>
      </Card>
      {/* <Card>
        <CardBody> {rendercollegeIds()} </CardBody>
      </Card> */}
      <Card>
        <CardBody>
          <CardTitle>UUID</CardTitle>
          <FormGroup className="mb-4">
            <Input
              type="text"
              className="form-control"
              placeholder="UUID..."
              readOnly
              {...formik.getFieldProps('uuid')}
            />
          </FormGroup>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <CardTitle>Verify College</CardTitle>
              <div>
                {['Yes', 'No'].map((option, i) => (
                  <div key={`verified_${i}`} className="form-check mb-3 d-inline-block me-4">
                    <input
                      type="radio"
                      id={`verified_${option}`}
                      name="college_verified"
                      className="form-check-input"
                      checked={formik.values.verified === option}
                      onChange={() => formik.setFieldValue('verified', option)}
                    />
                    <label className="form-check-label" htmlFor={`verified_${option}`}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </Col>

            <Col md={6}>
              <CardTitle>Publish College</CardTitle>
              {['Yes', 'No'].map((option, i) => (
                <div key={`published_${i}`} className="form-check mb-3 d-inline-block me-4">
                  <input
                    type="radio"
                    id={`published_${option}`}
                    name="college_published"
                    className="form-check-input"
                    checked={formik.values.published === option}
                    onChange={() => formik.setFieldValue('published', option)}
                  />
                  <label className="form-check-label" htmlFor={`published_${option}`}>
                    {option}
                  </label>
                </div>
              ))}
            </Col>

            {/* <Col md={6} className="mt-3">
              <CardTitle>Show Detailed View</CardTitle>
              <div>
                {['Yes', 'No'].map((option, i) => (
                  <div key={`showDetailedView_${i}`} className="form-check mb-3 d-inline-block me-4">
                    <input
                      type="radio"
                      id={`showDetailedView_${option}`}
                      name="college_showDetailedView"
                      className="form-check-input"
                      checked={formik.values.showDetailedView === option}
                      onChange={() => formik.setFieldValue('showDetailedView', option)}
                    />
                    <label className="form-check-label" htmlFor={`showDetailedView_${option}`}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </Col>
            <Col md={6} className="mt-3">
              <CardTitle>College Closed</CardTitle>
              <div>
                {['Yes', 'No'].map((option, i) => (
                  <div key={`collegeClosed_${i}`} className="form-check mb-3 d-inline-block me-4">
                    <input
                      type="radio"
                      id={`collegeClosed_${option}`}
                      name="collegeClosed_"
                      className="form-check-input"
                      checked={formik.values.collegeClosed === option}
                      onChange={() => formik.setFieldValue('collegeClosed', option)}
                    />
                    <label className="form-check-label" htmlFor={`collegeClosed_${option}`}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </Col> */}
          </Row>
        </CardBody>
      </Card>
      {/* <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <CardTitle>Claimed College</CardTitle>
              <div>
                {['Yes', 'No'].map((option, i) => (
                  <div key={`claimed_${i}`} className="form-check mb-3 d-inline-block me-4">
                    <input
                      type="radio"
                      id={`claimed_${option}`}
                      name="college_claimed"
                      className="form-check-input"
                      checked={formik.values.claimed === option}
                      onChange={() => formik.setFieldValue('claimed', option)}
                    />
                    <label className="form-check-label" htmlFor={`claimed_${option}`}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </Col>

            <Col md={6}>
              <CardTitle>Claimed Date</CardTitle>
              <InputGroup id="startDate">
                <DatePicker
                  selected={formik.values.claimedDate}
                  className="form-control"
                  showYearDropdown
                  placeholderText="Select date"
                  autoComplete="off"
                  minDate={moment('2020-01-01').toDate()}
                  maxDate={moment().toDate()}
                  onChange={(date) => formik.setFieldValue('claimedDate', date)}
                  invalid={!!(formik.touched.claimedDate && formik.errors.claimedDate)}
                />
              </InputGroup>
            </Col>
          </Row>
        </CardBody>
      </Card> */}
      <Card>
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="notes" className="form-label  ">
              Notes
            </Label>
            <TextEditor
              simple
              initialValue={formik.values.notes}
              onChange={(val) => formik.setFieldValue('notes', val)}
            />
          </FormGroup>
        </CardBody>
      </Card>

      <Card className="mb-4">
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="googleMapsUrl" className="form-label d-flex justify-content-between">
              Google Maps Url
              <span>
                Click here to get the url:
                <a
                  className="ms-2"
                  target="_blank"
                  href={`https://www.google.com/maps/search/${collegeDetail?.displayName} ${collegeDetail?.address?.city?.name} ${collegeDetail?.address?.region?.name} ${collegeDetail?.address?.country?.name}`}
                  rel="noreferrer">
                  Google Maps
                </a>
              </span>
            </Label>
          </FormGroup>
          <FetchReviews collegeDetail={collegeDetail} collegeFormik={formik} />
        </CardBody>
      </Card>

      {/* <div className="d-flex justify-content-between">
            <p>
              <b>Last Review Fetch:</b>{' '}
              {collegeDetail?.systemDetails?.lastReviewFetchedOn
                ? moment(collegeDetail?.systemDetails?.lastReviewFetchedOn).format('DD-MMM-YYYY @ hh:mm a')
                : ''}
            </p>
            {collegeDetail.systemDetails?.googleMapsUrl && (
              <a className="btn btn-light" onClick={() => fetchReviews()}>
                Fetch Reviews
              </a>
            )}
          </div> */}

      {(user?.entityPermissions?.college.admin ||
        (collegeDetail.editable &&
          (collegeDetail?.createdBy?.id === user.id || collegeDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Details
          </Button>
          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.COLLEGE_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

SystemDetails.propTypes = {};

export default SystemDetails;
