import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import useRequest from '../../../hooks/useRequest';
import Loader from '../../../components/Loader';

const TopUsers = ({ startDate, endDate }) => {
  const size = 10;
  const [users, setUsers] = useState([]);
  const [getAllActivityTopUsersRequest, { loading: getAllActivityTopUsersLoading }] = useRequest({
    url: '/patient-tracking/admin-tools/app-top-users',
    method: 'POST',
    onSuccess: (data) => {
      setUsers(data.results);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getAllActivityTopUsersRequest({
      body: {
        startDate,
        endDate,
        size,
      },
    });
  }, [startDate, endDate]);

  return (
    <>
      <Loader isActive={getAllActivityTopUsersLoading} />
      <Card>
        <CardBody>
          <div className="d-flex justify-content-between">
            <CardTitle className="float-sm-left">Top Ten Users</CardTitle>
            <Link className="float-sm-right" to="/tpz/activity-history">
              Activity History
            </Link>
          </div>
          <div className="table-responsive" style={{ minHeight: 300, maxHeight: 300 }}>
            <table className="table table-striped">
              <thead className="thead-light">
                <tr className="table-dark">
                  <th>User Name</th>
                  <th>Activity Name</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {users.map((it, key) => (
                  <tr key={`_tr_${key}`}>
                    <td className="table-warning">
                      <Link
                        to={{ pathname: `/tpz/activity-history`, query: { name: it.user_name } }}
                        className="text-body font-weight-bold">
                        {it.user_name}
                      </Link>
                    </td>
                    <td className="table-secondary">
                      <Link
                        to={{ pathname: `/tpz/activity-history`, query: { activityType: it.activity_type } }}
                        className="text-body font-weight-bold">
                        {it.activity_type}
                      </Link>
                    </td>
                    <td className="table-info">{it.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default TopUsers;
