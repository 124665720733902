import { PUBLIC_URL } from 'utils/constants';

export default {
  SMK_TAXONOMY_LISTING: `${PUBLIC_URL}/smk/taxonomy/list`,
  ADD_SMK_TAXONOMY: `${PUBLIC_URL}/smk/taxonomy/add`,
  EDIT_SMK_TAXONOMY: `${PUBLIC_URL}/smk/taxonomy/edit/:id`,

  TPZ_TAXONOMY_LISTING: `${PUBLIC_URL}/tpz/taxonomy/list`,
  ADD_TPZ_TAXONOMY: `${PUBLIC_URL}/tpz/taxonomy/add`,
  EDIT_TPZ_TAXONOMY: `${PUBLIC_URL}/tpz/taxonomy/edit/:id`,

  MEDIWATCHER_TAXONOMY_LISTING: `${PUBLIC_URL}/mediwatcher/taxonomy/list`,
  ADD_MEDIWATCHER_TAXONOMY: `${PUBLIC_URL}/mediwatcher/taxonomy/add`,
  EDIT_MEDIWATCHER_TAXONOMY: `${PUBLIC_URL}/mediwatcher/taxonomy/edit/:id`,
};
