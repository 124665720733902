export const NUTRITIONS_GRAPHQL_URL = {
  context: {
    uri: process.env.REACT_APP_BACKEND_BASE_API_URL + '/nutrition/graphql',
  },
};
export const COMMUNITY_GRAPHQL_URL = {
  context: {
    uri: process.env.REACT_APP_BACKEND_BASE_API_URL + '/community/graphql',
  },
};
