import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { toast } from 'react-toastify';
import { Card, CardBody, CardTitle } from 'reactstrap';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { printCurrencyTooltip } from '../../../utils/helper';

const UsersByBuildVersion = ({ startDate, endDate, system }) => {
  const [usersByBuild, setUsersByBuild] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [donutChartSeries, setDonutChartSeries] = useState([]);

  const [getUsersPercentageByBuildVersionRequest, { loading: getUsersPercentageByBuildVersionLoading }] = useRequest({
    url: `/patient-tracking/admin-tools/app-${system ? system : 'android'}-version-percentage`,
    method: 'POST',
    onSuccess: (data) => {
      setUsersByBuild(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getUsersPercentageByBuildVersionRequest({
      body: {
        startDate,
        endDate,
      },
    });
  }, [startDate, endDate]);

  useEffect(() => {
    setChartLabels(usersByBuild.map((e) => e.build_number));
    setDonutChartSeries(usersByBuild.map((e) => parseFloat(e.percentage)));
  }, [usersByBuild]);

  const donutChartOptions = {
    chart: {
      type: 'pie',
    },
    legend: {
      position: 'bottom',
    },
    colors: ['#278EFD', '#A5D0FF', '#4673A4'],
    labels: chartLabels,
    dataLabels: {
      style: {
        fontSize: '15px',
      },
      dropShadow: {
        enabled: false,
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            value: {
              fontSize: '12px',
              fontWeight: 600,
              offsetY: 0,
            },
          },
        },
      },
    },
    tooltip: {
      y: printCurrencyTooltip(),
    },
    responsive: [
      {
        breakpoint: 1200,
        options: {
          legend: {
            position: 'bottom',
            fontSize: 14,
            horizontalAlign: 'center',
          },
          chart: {
            height: 280,
          },
        },
      },
      {
        breakpoint: 1400,
        options: {
          legend: {
            position: 'top',
            fontSize: 10,
            offsetX: -70,
          },
          chart: {
            height: 200,
          },
        },
      },
      {
        breakpoint: 1600,
        options: {
          legend: {
            position: 'bottom',
            fontSize: 11,
            horizontalAlign: 'center',
            offsetX: -30,
          },
          chart: {
            height: 240,
          },
        },
      },
    ],
  };

  return (
    <>
      <Loader isActive={getUsersPercentageByBuildVersionLoading} />
      <Card>
        <CardBody>
          <CardTitle className="mb-4">% {`${system ? system : 'android'}`} Users Build Vesion</CardTitle>
          <div className="d-flex flex-row align-items-center" style={{ maxwidth: '300' }}>
            <table className="table table-bordered table-striped" style={{ width: '200', height: '200' }}>
              <thead className="thead-light">
                <tr>
                  <th>Build</th>
                  <th>Count</th>
                  <th>% Users</th>
                </tr>
              </thead>
              <tbody>
              {usersByBuild
                ?.slice()
                ?.sort((a, b) => +a.order - +b.order)
                ?.map((e, i) => {
                  return (
                    <tr key={`_item_${e.build_number}`}>
                      <td>{e.build_number}</td>
                      <td>{e?.count}</td>
                      <td>{e?.percentage} %</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <ReactApexChart options={donutChartOptions} series={donutChartSeries} type="pie" width={400} height={400} />
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default UsersByBuildVersion;
