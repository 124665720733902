// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Badge, Col, Container, Row, Table } from 'reactstrap';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { printDate } from '../../../utils/helper';

const ElementsList = (props) => {
  const [ptable, setPtable] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showElementEdit, setShowElementEdit] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);

  const [getPtableElementsListRequest, { loading: getPtableElementsListLoading }] = useRequest({
    url: `/ptable/admin/getElements`,
    method: 'GET',
    onSuccess: (data) => {
      // console.log(data);
      setPtable(data?.sort((a, b) => a.atomic_number - b.atomic_number));
    },
  });

  useEffect(() => {
    getPtableElementsListRequest();
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getPtableElementsListLoading} />

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="PTable" breadcrumbItem="Elements List" />

          <Row className="mb-2  align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2">
                <div className="position-relative font-bold">{ptable?.length} Elements Found</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div>
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">Atomic Number</th>
                        <th scope="col">Name</th>
                        <th scope="col">Symbol</th>
                        <th scope="col">Atomic Mass</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Updated Date</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(ptable) ? (
                        ptable?.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.atomic_number}</td>
                            <td>
                              <Link to={`/ptable/element/${data.symbol}`}>{data?.name}</Link>
                            </td>
                            <td>{data?.symbol}</td>
                            <td>{data?.atomic_mass}</td>
                            <td>{printDate(data?.createdDate)}</td>
                            <td>{printDate(data?.updatedDate)}</td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ElementsList;
