import Loader from 'components/Loader';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { showImageError } from 'containers/blog/helper';
import { schoolDetailSelector } from 'containers/school/selectors';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import confirm from 'reactstrap-confirm';
import { createStructuredSelector } from 'reselect';
import { api } from 'utils/axios';
import { API_BASE_URL, MODULE_TYPE, schoolAndCollegeImagesTypes } from 'utils/constants';
import { getImageUrl, IMAGE_SIZES, showImage, titleCaseIfExists } from 'utils/helper';
import config from '../../../../config/config';

const stateSelector = createStructuredSelector({
  schoolDetail: schoolDetailSelector,
});

const SchoolImages = ({ schoolId }) => {
  const { schoolDetail } = useSelector(stateSelector);
  toast.configure();

  const [formData, setFormData] = useState({
    type: '',
    file: '',
  });

  const [modalOpen, setModalOpen] = useState(false);

  const isLogoExist = schoolDetail?.images?.filter((e) => e?.type === schoolAndCollegeImagesTypes.logo)?.length > 0;
  const isFeaturedExist =
    schoolDetail?.images?.filter((e) => e?.type === schoolAndCollegeImagesTypes.featured)?.length > 0;

  const toggleModal = () => setModalOpen(!modalOpen);

  const imagesTypes = Object.keys(schoolAndCollegeImagesTypes)
    .map((key) => ({
      label: titleCaseIfExists(key),
      value: schoolAndCollegeImagesTypes[key],
    }))
    ?.filter((e) => {
      if (isLogoExist && e?.value === schoolAndCollegeImagesTypes.logo) return false;
      if (isFeaturedExist && e?.value === schoolAndCollegeImagesTypes.featured) return false;
      return true;
    });

  const handleSubmitImages = async () => {
    if (isEmpty(formData?.type)) {
      toast.error('Please select an image type');
      return;
    }
    if (isEmpty(formData?.file)) {
      toast.error('Please select an image URL');
      return;
    }

    await api({
      method: 'POST',
      url: `${config.apiEndpoint}/school/admin/edit/${schoolId}/addImage`,
      data: {
        type: formData.type,
        imageId: formData?.file?.id,
      },
    })
      .then((res) => {
        toast.success('Images uploaded successfully');
        toggleModal();
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err?.message || 'Something went wrong');
      });
  };

  const handleRemoveImage = async (imageId) => {
    if (imageId === null) {
      toast.error('Something went wrong');
      return;
    }

    const result = await confirm({
      title: 'Delete Image',
      message: `Are you sure, you want to Delete this image?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      await api({
        method: 'POST',
        url: `${config.apiEndpoint}/school/admin/edit/${schoolId}/removeImage/${imageId}`,
      })
        .then((res) => {
          toast.success('Images removed successfully');
          toggleModal();
          window.location.reload();
        })
        .catch((err) => {
          toast.error(err?.message || 'Something went wrong');
        });
    }
  };

  return (
    <div>
      <Loader isActive={false} />
      <div className="mb-4 d-flex align-items-center justify-content-between">
        <h5 className="mb-0">Logo & Images</h5>
        <Button className="mt-4 mb-3" color="success" onClick={toggleModal}>
          <i className="bx bx-plus me-2" />
          Add Asset
        </Button>
      </div>

      <Row>
        {schoolDetail?.images
          ?.filter((e) => !isEmpty(e?.image))
          ?.map((e, index) => {
            return (
              <Col md={6} className="mb-4" key={index}>
                <Card className="h-100">
                  <CardBody>
                    <div className="mb-4 d-flex align-items-center justify-content-between">
                      <h5 className="mb-0">{titleCaseIfExists(e?.type)}</h5>
                      <Button outline color="danger" onClick={() => handleRemoveImage(e.imageId)}>
                        <i className="bx bx-trash-alt" /> Remove
                      </Button>
                    </div>

                    <div className="border p-2">
                      <img
                        className="rounded-2 w-auto"
                        style={{ maxHeight: 200 }}
                        src={getImageUrl(e.image, IMAGE_SIZES.MEDIUM)}
                        alt={e.type}
                        onError={showImageError}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
      </Row>

      <Modal centered isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Select and Upload Image</ModalHeader>
        <ModalBody>
          <div>
            <FormGroup className="mb-4">
              <Label className="form-label">
                Type
                <span className="text-danger">*</span>
              </Label>
              <Select
                styles={{
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                }}
                options={imagesTypes}
                value={imagesTypes?.find((t) => t?.value === formData?.type)}
                onChange={(t) =>
                  setFormData({
                    ...formData,
                    type: t?.value,
                  })
                }
              />
            </FormGroup>

            <FormGroup className="mb-3">
              <Label className="form-label">
                URL <span className="text-danger">*</span>
              </Label>
              <SmkAsyncSelect
                acceptedKey={'name'}
                acceptedValue={'name'}
                placeholder="Type media name.."
                onChange={(res) => {
                  setFormData({
                    ...formData,
                    file: res,
                  });
                }}
                fetchUrl={`${API_BASE_URL}/asset/admin/search`}
                filters={{
                  autoComplete: true,
                  page: 1,
                  size: 1000,
                  sortBy: 'id',
                  sortOrder: 0,
                  forType: MODULE_TYPE.SCHOOL,
                }}
                searchKeyName="name"
                value={
                  !isEmpty(formData?.file?.name)
                    ? {
                        label: formData?.file?.name,
                        value: formData?.file?.name,
                      }
                    : null
                }
              />
              {!isEmpty(formData?.file) && (
                <img
                  className="mt-4 w-auto"
                  style={{ maxHeight: 200 }}
                  src={getImageUrl(formData.file, IMAGE_SIZES.MEDIUM)}
                  onError={showImageError}
                />
              )}
            </FormGroup>

            <Button className="mt-4 mb-2 w-100" color="primary" onClick={handleSubmitImages}>
              Update
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SchoolImages;
