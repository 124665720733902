import produce from 'immer';
import {
  admissionInquiriesMarkActionTaken,
  admissionInquiriesMarkDeleted,
  getAdmissionInquiries,
  getCities,
  getCountries,
  getRegions,
  getSchoolBoards,
  getSchoolDetail,
  getSchoolGoogleObjectForApproval,
  getSchoolGoogleObjectForReviewsApproval,
  getSchoolGoogleObjects,
  getSchoolLevels,
  getSchoolReviews,
  getSchools,
  getSchoolsInfraList,
  getSchoolsLevelMaster,
  getSchoolsMasterData,
  getSchoolsGroups,
  getSubAreas,
  schoolReviewApprove,
  schoolReviewDelete,
  updateSchoolBasicInfo,
  updateSchoolContactDetails,
  updateSchoolAdmissionInfo,
  updateSchoolFeeDetails,
  updateSchoolAcademicDetails,
  updateSchoolFacilityDetails,
  updateSchoolExtraCurricularDetails,
  updateSchoolAdditionalInfo,
  updateSchoolSystemInfo,
} from './actions';

export const initialState = {
  countries: [],
  regions: [],
  cities: [],
  subAreas: [],

  admissionInquiries: [],
  admissionInquiriesPagination: {},

  schoolSearchList: [],

  schools: [],
  schoolsPagination: {},

  schoolReviews: [],
  schoolReviewsPagination: [],

  googleObjects: [],
  googleObjectsPagination: [],

  googleObjectForApproval: {},

  schoolDetail: {},

  schoolBoards: [],
  schoolLevels: [],
  schoolsMasterData: [],
  schoolsInfraList: [],
  schoolsLevelMaster: [],
  schoolsGroups: [],
};

const schoolReducer = produce((draft, action) => {
  switch (action.type) {
    case getSchools.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.schools = results;
      draft.schoolsPagination = pagination;
      break;
    }

    case getSchoolReviews.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.schoolReviews = results;
      draft.schoolReviewsPagination = pagination;
      break;
    }

    case getSchoolGoogleObjects.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.googleObjects = results;
      draft.googleObjectsPagination = pagination;
      break;
    }

    case getSchoolGoogleObjectForApproval.SUCCESS: {
      const { result } = action.payload;
      draft.googleObjectForApproval = result;
      break;
    }

    case getSchoolGoogleObjectForReviewsApproval.SUCCESS: {
      const { result } = action.payload;
      draft.googleObjectForApproval = result;
      break;
    }

    case getAdmissionInquiries.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.admissionInquiries = results;
      draft.admissionInquiriesPagination = pagination;
      break;
    }
    case admissionInquiriesMarkActionTaken.SUCCESS: {
      const { admissionInquiry } = action.payload;

      draft.admissionInquiries = Object.assign([], draft.admissionInquiries).map((item) => {
        return item.id !== admissionInquiry.id ? item : admissionInquiry;
      });

      break;
    }

    case admissionInquiriesMarkDeleted.SUCCESS: {
      const { admissionInquiry } = action.payload;

      draft.admissionInquiries = Object.assign([], draft.admissionInquiries).map((item) => {
        return item.id !== admissionInquiry.id ? item : admissionInquiry;
      });

      break;
    }

    case getCountries.SUCCESS: {
      const { results } = action.payload;
      draft.countries = results.map((r) => ({ ...r, label: r.name + ` (${r.id})`, value: r.id }));
      break;
    }
    case getRegions.SUCCESS: {
      const { results } = action.payload;
      draft.regions = results.map((r) => ({ ...r, label: r.name + ` (${r.id})`, value: r.id }));
      break;
    }
    case getCities.SUCCESS: {
      const { results } = action.payload;
      draft.cities = results.map((r) => ({ ...r, label: r.name + ` (${r.id})`, value: r.id }));
      break;
    }
    case getSubAreas.SUCCESS: {
      const { results } = action.payload;
      draft.subAreas = results.map((r) => ({ ...r, label: r.name + ` (${r.id})  `, value: r.id }));
      break;
    }

    case schoolReviewApprove.SUCCESS: {
      const { schoolReview } = action.payload;

      const list = Object.assign([], draft.schoolReviews);

      const filteredItems = list.map((item) => {
        return item.id !== schoolReview.id ? item : schoolReview;
      });

      draft.schoolReviews = filteredItems;

      break;
    }
    case schoolReviewDelete.SUCCESS: {
      const { schoolReview } = action.payload;

      const list = Object.assign([], draft.schoolReviews);

      const filteredItems = list.map((item) => {
        return item.id !== schoolReview.id ? item : schoolReview;
      });

      draft.schoolReviews = filteredItems;

      break;
    }
    case getSchoolDetail.TRIGGER: {
      draft.schoolDetail = {};
      break;
    }

    // load school
    case getSchoolDetail.SUCCESS:
    case updateSchoolBasicInfo.SUCCESS:
    case updateSchoolContactDetails.SUCCESS:
    case updateSchoolAdmissionInfo.SUCCESS:
    case updateSchoolFeeDetails.SUCCESS:
    case updateSchoolAcademicDetails.SUCCESS:
    case updateSchoolFacilityDetails.SUCCESS:
    case updateSchoolExtraCurricularDetails.SUCCESS:
    case updateSchoolSystemInfo.SUCCESS:
    case updateSchoolAdditionalInfo.SUCCESS: {
      draft.schoolDetail = action.payload;
      break;
    }

    case getSchoolsInfraList.SUCCESS: {
      draft.schoolsInfraList = action.payload.map((s) => ({ ...s, value: s.id, label: s.name }));
      break;
    }

    case getSchoolBoards.SUCCESS: {
      // console.log(action.payload);
      draft.schoolBoards = action.payload.map((s) => ({ ...s, value: s.id, label: s.name }));
      break;
    }
    case getSchoolLevels.SUCCESS: {
      draft.schoolLevels = action.payload.map((s) => ({ ...s, value: s.id, label: s.name }));
      break;
    }
    case getSchoolsMasterData.SUCCESS: {
      draft.schoolsMasterData = action.payload.map((s) => ({ ...s, value: s.id, label: s.name }));
      break;
    }
    case getSchoolsLevelMaster.SUCCESS: {
      draft.schoolsLevelMaster = action.payload;
      break;
    }
    case getSchoolsGroups.SUCCESS: {
      const { results, paginator } = action.payload;
      draft.schoolsGroups = results.map((s) => ({ ...s, value: s.id, label: s.name }));
      break;
    }

    // case updateSchoolFacilityDetails.SUCCESS: {
    //   const {
    //     school: { infra, facilitiesInfo },
    //   } = action.payload;

    //   draft.schoolDetail.infra = infra;
    //   draft.schoolDetail.facilitiesInfo = facilitiesInfo;
    //   break;
    // }

    // case '@@router/LOCATION_CHANGE':
    //   return initialState;

    default:
      break;
  }
}, initialState);

export default schoolReducer;
