import { isEmpty } from 'lodash';
import { api } from 'utils/axios';
import config from '../../config/config';
import { convertHtmlToText, htmlDecode } from '../../utils/helper';

export const compareSchoolClassLevel = (a, b) => {
  // a should come before b in the sorted order
  if (a.level < b.level) {
    return -1;
    // a should come after b in the sorted order
  }
  if (a.level > b.level) {
    return 1;
    // and and b are the same
  }
  return 0;
};

export const getSchoolClassesSorted = (currentSchool) => {
  const classes = [];
  if (currentSchool && currentSchool.schoolLevels) {
    currentSchool.schoolLevels.map((s) => classes.push(...s.classes));
    classes.sort(compareSchoolClassLevel);
  }

  return classes;
};

export const getSchoolLevelsSorted = (currentSchool) => {
  const levels = [];
  if (currentSchool && currentSchool.schoolLevels) {
    currentSchool.schoolLevels.map((s) => levels.push(s.schoolLevel));
    levels.sort(compareSchoolClassLevel);
  }

  return levels;
};

export const getSchoolLowestGrade = (currentSchool) => {
  const classes = getSchoolClassesSorted(currentSchool);
  return currentSchool && classes && classes.length > 0 && classes[0] && classes[0].name;
};

export const getSchoolHighestGrade = (currentSchool) => {
  const classes = getSchoolClassesSorted(currentSchool);
  return (
    currentSchool && classes && classes.length > 1 && classes[classes.length - 1] && classes[classes.length - 1].name
  );
};

export const getSchoolLowestLevel = (currentSchool) => {
  const schoolLevels = getSchoolLevelsSorted(currentSchool);
  return currentSchool && schoolLevels && schoolLevels.length > 0 && schoolLevels[0].displayName;
};

export const getSchoolHighestLevel = (currentSchool) => {
  const schoolLevels = getSchoolLevelsSorted(currentSchool);
  return currentSchool && schoolLevels && schoolLevels.length > 0 && schoolLevels[schoolLevels.length - 1].displayName;
};

export const getSchoolDisplayName = (currentSchool) => {
  return currentSchool && convertHtmlToText(currentSchool.displayName);
};

export const getSchoolName = (currentSchool) => {
  return currentSchool && convertHtmlToText(currentSchool.name);
};

export const getSchoolCountry = (currentSchool) => {
  return currentSchool.address && currentSchool.address.country && currentSchool.address.country.displayName;
};
export const getSchoolState = (currentSchool) => {
  return currentSchool.address && currentSchool.address.region && currentSchool.address.region.displayName
    ? currentSchool.address.region.displayName
    : '';
};
export const getSchoolDistrict = (currentSchool) => {
  return currentSchool.address && currentSchool.address.district && currentSchool.address.district.displayName
    ? currentSchool.address.district.displayName
    : '';
};
export const getSchoolCity = (currentSchool) => {
  return currentSchool.address && currentSchool.address.city && currentSchool.address.city.displayName;
};
export const getSchoolMetro = (currentSchool) => {
  return currentSchool.address && currentSchool.address.metro && currentSchool.address.metro.displayName
    ? currentSchool.address.metro.displayName
    : '';
};
export const getSchoolSubArea = (currentSchool) => {
  return currentSchool.address && currentSchool.address.subArea && currentSchool.address.subArea.displayName
    ? currentSchool.address.subArea.displayName
    : '';
};
export const getSchoolLocality = (currentSchool) => {
  return currentSchool.address && currentSchool.address.locality && currentSchool.address.locality.displayName
    ? currentSchool.address.locality.displayName
    : '';
};
export const getSchoolStudentGender = (currentSchool) => {
  let gender = 'Coed';

  if (currentSchool.studentGender !== 0) {
    gender = currentSchool.studentGender === 1 ? 'Girls' : 'Boys';
  }

  return gender;
};

export const getSchoolBoardDisplayNames = (currentSchool) => {
  return (
    currentSchool.boards &&
    currentSchool.boards
      .filter((b) => !b.preschool)
      .map((m) => `${m.displayName} (${m.name})`)
      .join(', ')
  );
};

export const getSchoolAllBoardDisplayNames = (currentSchool) => {
  return currentSchool.boards && currentSchool.boards.map((m) => `${m.displayName} (${m.name})`).join(', ');
};

export const getSchoolPreschoolBoardDisplayNames = (currentSchool) => {
  return (
    currentSchool.boards &&
    currentSchool.boards
      .filter((b) => b.preschool)
      .map((m) => `${m.displayName} (${m.name})`)
      .join(', ')
  );
};

export const getSchoolBoardNames = (currentSchool) => {
  return (
    currentSchool.boards &&
    currentSchool.boards
      .filter((b) => !b.preschool)
      .map((m) => m.name)
      .join(', ')
  );
};

export const getSchoolPreschoolBoardNames = (currentSchool) => {
  return (
    currentSchool.boards &&
    currentSchool.boards
      .filter((b) => b.preschool)
      .map((m) => m.name)
      .join(', ')
  );
};

export const getSchoolManagementName = (currentSchool) => {
  return currentSchool.basicInfo && currentSchool.basicInfo.managementName;
};

export const getSchoolMotto = (currentSchool) => {
  return currentSchool.basicInfo && htmlDecode(currentSchool.basicInfo.schoolMotto);
};

export const getSchoolTotalStudents = (currentSchool) => {
  return currentSchool.additionalInfo && currentSchool.additionalInfo.studentCount;
};
export const getSchoolAcademicYear = (currentSchool) => {
  return currentSchool.admissionInfo && currentSchool.admissionInfo.academicYear;
};

export const getSchoolParentGroupNames = (currentSchool) => {
  return (
    currentSchool &&
    currentSchool.schoolGroups &&
    currentSchool.schoolGroups
      .filter((s) => s.schoolGroup && !s.schoolGroup.isAssociation)
      .map((m) => m.schoolGroup.name.trim())
      .join('')
  );
};

export const getSchoolAssociationGroups = (currentSchool) => {
  return currentSchool.schoolGroups.filter((s) => s.schoolGroup && s.schoolGroup.isAssociation);
};

export const getSchoolManagementType = (currentSchool) => {
  return (
    currentSchool.masterData &&
    currentSchool.masterData
      .filter((m) => m.type === 2)
      .map((m) => m.name)
      .join('')
  );
};

export const getSchoolBoardingType = (currentSchool) => {
  return (
    currentSchool.masterData &&
    currentSchool.masterData
      .filter((m) => m.type === 0)
      .map((m) => m.name)
      .join(', ')
  );
};

export const getSchoolLanguages = (currentSchool) => {
  return (
    currentSchool &&
    currentSchool.academicInfo &&
    currentSchool.academicInfo.languages &&
    currentSchool.academicInfo.languages.map((m) => m.name).join(', ')
  );
};

export const getSchoolEstablishedYear = (currentSchool) => {
  return currentSchool.basicInfo.establishedIn ? new Date(currentSchool.basicInfo.establishedIn).getFullYear() : '';
};

export const getSchoolStudentTeacherRatio = (currentSchool) => {
  return currentSchool.additionalInfo.studentCount > 0 && currentSchool.additionalInfo.teacherCount > 0
    ? Math.floor(currentSchool.additionalInfo.studentCount / currentSchool.additionalInfo.teacherCount)
    : 0;
};

export const getSchoolBoardsMap = (currentSchool) => {
  const boardsMap = {};
  if (currentSchool.academicInfo && currentSchool.academicInfo.curriculumDetails) {
    currentSchool.academicInfo.curriculumDetails.forEach((c) => {
      const key = c.curriculum;
      key.replace(' ', '');
      boardsMap[key] = c;
    });
  }
  return boardsMap;
};

export const isSchoolKVSSoeDel = (currentSchool) => {
  return (
    currentSchool.schoolGroups &&
    currentSchool.schoolGroups.filter(
      (sg) => sg.schoolGroup.shortName.toLowerCase() === 'kvs' || sg.schoolGroup.shortName.toLowerCase() === 'soedel'
    ).length > 0
  );
};

export const isSchoolPrimarySchool = (currentSchool) => {
  return currentSchool.schoolLevels.find((sl) => sl.schoolLevel.name === 'Primary School (I-V)');
};

export const getSchoolSubBardingType = (currentSchool) => {
  return (
    currentSchool.masterData &&
    currentSchool.masterData
      .filter((m) => m.type === 1)
      .map((m) => m.name)
      .join(', ')
  );
};

export const getSchoolNationalInternational = (currentSchool) => {
  // const country = getSchoolCountry(currentSchool);

  const curriculumType = 0;

  // currentSchool.boards &&
  //   currentSchool.boards.map((b) => {
  //     // b.country
  //   });

  return curriculumType;
};

export const schoolClearCache = ({ key, urlSlug, schoolId, removeBodyParam, babyNameMeaningOriginCache, babyName }) => {
  // api end points
  const searchCacheApiUrl = `${config.apiEndpoint}/admin-tools/searchCache`;
  const clearCDNCacheApiUrl = `${config.apiEndpoint}/admin-tools/clearCDNCache`;
  const clearCacheApiUrl = `${config.apiEndpoint}/admin-tools/clearCache`;

  // details page
  const babyNameDetailPage = `${process.env.REACT_APP_THEPARENTZ_WEB_URL}/baby-names/${babyName}`;
  const schoolDetailPage = `${config.frontendBaseUrl}/school/${urlSlug}`;

  const urls = [babyNameMeaningOriginCache ? babyNameDetailPage : schoolDetailPage];

  // // search cache and clear
  // api({
  //   method: 'POST',
  //   url: searchCacheApiUrl,
  //   data: {
  //     key: key ? key : 'SCHOOLMYKIDS_',
  //     bodyParam: !removeBodyParam ? 'schoolId' : undefined,
  //     bodyParamValue: !removeBodyParam ? schoolId : undefined,
  //     url: babyNameMeaningOriginCache ? `/api/v1/baby-name/meaningOrigin/${babyName}` : `/api/v1/school/${schoolId}`,
  //     useOr: true,
  //     page: 1,
  //     size: 1000,
  //     sortBy: 'id',
  //     sortOrder: 0,
  //   },
  // })?.then((data) => {
  //   if (!isEmpty(data?.results)) {
  //     let keys = [];
  //     data?.results?.map((e) => keys?.push(e.key));
  //     api({
  //       method: 'POST',
  //       url: clearCacheApiUrl,
  //       data: {
  //         keys,
  //       },
  //     })?.then(() => {
  //       api({
  //         method: 'POST',
  //         url: clearCDNCacheApiUrl,
  //         data: {
  //           urls,
  //         },
  //       });
  //     });
  //   }
  // });
};
