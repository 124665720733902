import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
  return (
    <>
      <footer className="footer" style={{ position: 'absolute' }}>
        <Container fluid>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © SchoolMyKids. All rights reserved.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block" />
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
