import decode from 'jwt-decode';
import Cookies from 'js-cookie';
import {
  APP_SOURCE,
  COOKIE_OPTIONS,
  SMK_ANONYMOUS_COOKIE,
  SMK_REFRESH_SESSION_COOKIE,
  SMK_SESSION_COOKIE,
} from '../utils/constants';

export default class AuthService {
  constructor() {
    this.loggedIn = this.loggedIn.bind(this);
    this.isTokenExpired = this.isTokenExpired.bind(this);
    // session
    this.setSessionCookie = this.setSessionCookie.bind(this);
    this.unsetSessionCookie = this.unsetSessionCookie.bind(this);
    this.getSessionCookie = this.getSessionCookie.bind(this);

    // refresh-session
    this.setRefreshSessionCookie = this.setRefreshSessionCookie.bind(this);
    this.unsetRefreshSessionCookie = this.unsetRefreshSessionCookie.bind(this);
    this.getRefreshSessionCookie = this.getRefreshSessionCookie.bind(this);

    // app source
    this.setAppSourceCookie = this.setAppSourceCookie.bind(this);
    this.unsetAppSourceCookie = this.unsetAppSourceCookie.bind(this);
    this.getAppSourceCookie = this.getAppSourceCookie.bind(this);

    this.getUserId = this.getUserId.bind(this);
  }

  loggedIn() {
    const token = this.getSessionCookie();
    return !!token && !this.isTokenExpired(token);
  }

  isTokenExpired(token) {
    try {
      const decoded = decode(token);
      return decoded.exp < Date.now() / 1000;
    } catch (err) {
      return false;
    }
  }

  setSessionCookie(idToken) {
    Cookies.set(SMK_SESSION_COOKIE, idToken, { expires: 1, ...COOKIE_OPTIONS });
  }

  unsetSessionCookie() {
    Cookies.set(SMK_SESSION_COOKIE, null, { expires: 0, ...COOKIE_OPTIONS });
    Cookies.remove(SMK_SESSION_COOKIE);
  }

  setRefreshSessionCookie(idToken) {
    Cookies.set(SMK_REFRESH_SESSION_COOKIE, idToken, { expires: 1, ...COOKIE_OPTIONS });
  }

  unsetRefreshSessionCookie() {
    Cookies.set(SMK_REFRESH_SESSION_COOKIE, null, { expires: 0, ...COOKIE_OPTIONS });
    Cookies.remove(SMK_REFRESH_SESSION_COOKIE);
  }

  setAppSourceCookie(idToken) {
    Cookies.set(APP_SOURCE, idToken, { expires: 1, ...COOKIE_OPTIONS });
  }

  unsetAppSourceCookie() {
    Cookies.set(APP_SOURCE, null, { expires: 0, ...COOKIE_OPTIONS });
    Cookies.remove(APP_SOURCE);
  }

  getSessionCookie() {
    return Cookies.get(SMK_SESSION_COOKIE);
  }

  getRefreshSessionCookie() {
    return Cookies.get(SMK_REFRESH_SESSION_COOKIE);
  }

  getAnonymousCookie() {
    return Cookies.get(SMK_ANONYMOUS_COOKIE);
  }
  getAppSourceCookie() {
    return Cookies.get(APP_SOURCE);
  }

  setAnonymousCookie(idToken) {
    Cookies.set(SMK_ANONYMOUS_COOKIE, idToken, {
      expires: 1,
      ...COOKIE_OPTIONS,
    });
  }

  unsetAnonymousCookie() {
    Cookies.set(SMK_ANONYMOUS_COOKIE, null, { expires: 0, ...COOKIE_OPTIONS });
    Cookies.remove(SMK_ANONYMOUS_COOKIE);
  }

  getUserId() {
    const token = decode(this.getSessionCookie());
    return token.id;
  }
}
