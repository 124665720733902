import React from 'react';
import {
  Badge,
  Button,
  ButtonDropdown,
  Card,
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// config
import { toast } from 'react-toastify';
import config from '../../../config/config';
// selectors
import { schoolDetailSelector } from '../selectors';
// helpers
import { printDate, convertHtmlToText } from '../../../utils/helper';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { userSelector } from '../../../common/globalComponents/selectors';
import { schoolClearCache } from '../schoolDetailsHelper';

const stateSelector = createStructuredSelector({
  schoolDetail: schoolDetailSelector,
  user: userSelector,
});

const SchoolSummaryBlock = () => {
  const { schoolDetail, user } = useSelector(stateSelector);
  toast.configure();

  const renderLoaction = () => {
    const {
      address: { country, region, city, subArea },
    } = schoolDetail;
    let loc = '';
    if (!isEmpty(subArea)) {
      loc += `${subArea.displayName}, `;
    }
    if (!isEmpty(city)) {
      loc += `${city.displayName}, `;
    }
    if (!isEmpty(country)) {
      loc += `${country.displayName}`;
    }

    return loc;
  };

  const schoolDetailClearCache = () => {
    if (schoolDetail.active) {
      schoolClearCache({
        urlSlug: schoolDetail?.slug,
        schoolId: schoolDetail?.id,
      });
    }
  };

  const [publishSchoolRequest, { loading: publishSchoolLoading }] = useRequest({
    url: `/school/admin/togglePublish/${schoolDetail.id}`,
    method: 'POST',
    onSuccess: (data) => {
      schoolDetailClearCache();
      window.location.reload();
      toast.success('Success! School has been Published');
    },
  });

  const [deleteSchoolRequest, { loading: deleteSchoolLoading }] = useRequest({
    url: `/school/admin/toggleDelete/${schoolDetail.id}`,
    method: 'POST',
    onSuccess: (data) => {
      schoolDetailClearCache();
      window.location = `/schools/list`;
      toast.success('Success! School has been Deleted');
      // window.location.reload();
    },
  });

  const [duplicateSchoolRequest, { loading: duplicateSchoolLoading }] = useRequest({
    url: `/school/admin/duplicateSchool/${schoolDetail.id}`,
    method: 'PUT',
    onSuccess: (data) => {
      schoolDetailClearCache();
      window.location = `/schools/list`;
      toast.success('Success! Duplicate School has been created ');
    },
  });

  const [toggleEditableRequest, { loading: toggleEditableLoading }] = useRequest({
    url: `/school/admin/toggleEditable/${schoolDetail.id}`,
    method: 'PUT',
    onSuccess: (data) => {
      schoolDetailClearCache();
      window.location.reload();
      toast.success('Success! School Toggle has been Added ');
    },
  });

  return (
    !isEmpty(schoolDetail) && (
      <Card>
        <Loader
          isActive={publishSchoolLoading || deleteSchoolLoading || duplicateSchoolLoading || toggleEditableLoading}
        />

        <CardBody>
          <Row>
            <Col md={3}>
              <div className="text-muted mb-2">Name</div>
              <CardTitle>
                {schoolDetail?.logoUrl && (
                  <a target="_blank" href={schoolDetail?.logoUrl} rel="noreferrer">
                    <img src={schoolDetail?.logoUrl} height="50" />
                  </a>
                )}
                <div className={schoolDetail?.logoUrl ? 'mt-1' : ''}>
                  {convertHtmlToText(schoolDetail?.displayName || schoolDetail?.name)}
                </div>
              </CardTitle>
            </Col>
            <Col md={3}>
              <div className="text-muted mb-2">Location</div>
              <CardTitle>{renderLoaction()}</CardTitle>
            </Col>

            <Col md={2}>
              <div className="text-muted mb-2">Verified</div>
              <CardTitle>
                {schoolDetail?.systemInfo?.verified ? (
                  <Badge className="bg-success my-1">Verified</Badge>
                ) : (
                  <Badge className="bg-danger my-1">Not Verified</Badge>
                )}
              </CardTitle>
            </Col>
            <Col md={2}>
              <div className="text-muted mb-2">Status</div>
              <CardTitle>
                {schoolDetail.active ? (
                  <Badge className="bg-success mx-1">Published</Badge>
                ) : (
                  <Badge className="bg-warning mx-1">Draft</Badge>
                )}

                {!(schoolDetail?.systemInfo?.verified || schoolDetail?.active) && (
                  <Badge className="bg-danger mx-1">Inactive</Badge>
                )}
                {schoolDetail?.deleted && <Badge className="bg-danger mx-1">Deleted</Badge>}
              </CardTitle>
            </Col>

            <Col md={2}>
              <div className="text-muted mb-2">Editable</div>
              <CardTitle className={schoolDetail?.editable ? 'text-success' : 'text-danger'}>
                {schoolDetail?.editable ? 'Yes' : 'No'}
              </CardTitle>
            </Col>
          </Row>
          <Row className="my-3">
            <Col md={3}>
              <div className="text-muted mb-2">Created Date</div>
              <CardTitle>{printDate(schoolDetail?.createdDate)}</CardTitle>
            </Col>
            <Col md={3}>
              <div className="text-muted mb-2">Updated Date</div>
              <CardTitle>{printDate(schoolDetail?.updatedDate)}</CardTitle>
            </Col>
            <Col md={3}>
              <div className="text-muted mb-2">Created By</div>
              <CardTitle>
                {schoolDetail?.createdBy?.displayName}{' '}
                {schoolDetail?.createdBy?.email && <small>({schoolDetail?.createdBy?.email})</small>}
              </CardTitle>
            </Col>

            <Col md={3}>
              <div className="text-muted mb-2">Actions</div>
              <div>
                {user?.entityPermissions?.school.publish && schoolDetail?.systemInfo?.verified && (
                  <Button
                    className={`me-3 px-4 ${schoolDetail.active ? 'bg-warning' : 'bg-success'}`}
                    type="submit"
                    onClick={() => publishSchoolRequest()}>
                    {schoolDetail.active ? 'Unpublish' : 'Publish'}
                  </Button>
                )}
                {/* {user?.entityPermissions?.school.delete && (
                  <Button
                   className={schoolDetail.deleted ? 'primary' : 'danger'}
                    type="submit"
                    className="me-2"
                    onClick={() => deleteSchoolRequest()}>
                    {schoolDetail.deleted ? 'Undelete' : 'Delete'}
                  </Button>
                )} */}

                {user?.entityPermissions?.school.admin && (
                  <UncontrolledButtonDropdown>
                    <DropdownToggle color="light" caret>
                      More <i className="fa fa-chevron-down ms-2" />
                    </DropdownToggle>
                    <DropdownMenu>
                      {/* <DropdownItem header>Header</DropdownItem>
                    <DropdownItem disabled>Action</DropdownItem> */}
                      <DropdownItem className="my-2" onClick={() => deleteSchoolRequest()}>
                        <i className="fas fa-trash pe-3 text-danger" />
                        <span className={schoolDetail.deleted ? 'text-primary' : 'text-danger'}>
                          {schoolDetail.deleted ? 'Undelete' : 'Delete'} School
                        </span>
                      </DropdownItem>
                      <DropdownItem className="my-2" onClick={() => duplicateSchoolRequest()}>
                        <i className="fas fa-file pe-3 text-dark" />
                        Duplicate School
                      </DropdownItem>
                      <DropdownItem className="my-2" onClick={() => toggleEditableRequest()}>
                        <i className="fas fa-toggle-on pe-3 text-dark" />
                        Toggle Editable
                      </DropdownItem>
                      {/* <DropdownItem onClick={() => purgeSchoolCacheRequest()}>Purge Cache</DropdownItem> */}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                )}
              </div>
            </Col>
          </Row>

          <Row className="my-3">
            <Col md={3}>
              <div className="text-muted mb-2">Average Rating</div>
              <CardTitle>{schoolDetail.averageRating}</CardTitle>
            </Col>
            <Col md={3}>
              <div className="text-muted mb-2">Total Reviews</div>
              <CardTitle>{schoolDetail.totalReviews}</CardTitle>
            </Col>

            <Col md={2}>
              <div className="text-muted mb-2">Claimed</div>
              <CardTitle className={schoolDetail?.claimed ? 'text-success' : 'text-danger'}>
                {schoolDetail.claimed ? printDate(schoolDetail.claimedDate) : 'No'}
              </CardTitle>
            </Col>
          </Row>
          {!isEmpty(schoolDetail.slug) && (
            <Row>
              <Col md={12}>
                <div className="text-muted mb-2">Permalink</div>
                <div>
                  <a href={`${config.frontendBaseUrl}/school/${schoolDetail.slug}`} rel="noreferrer" target="_blank">
                    <span className="text-normal text-primary">{`${config.frontendBaseUrl}/school/${schoolDetail.slug}`}</span>
                  </a>
                </div>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
    )
  );
};

export default SchoolSummaryBlock;
