import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';

const BabyActivityCount = ({ startDate, endDate }) => {
  const [groupedData, setGroupedData] = useState([]);
  const [getActivitiesCountListRequest, { loading: getActivitiesCountListLoading }] = useRequest({
    url: `/patient-tracking/admin-tools/app-baby-activity-count`,
    method: 'POST',
    onSuccess: (data) => {
      const groupedData = data?.results.reduce((acc, current) => {
        if (!acc[current.agecategory]) {
          acc[current.agecategory] = {};
        }
        acc[current.agecategory] = {
          ...acc[current.agecategory],
          [current.activity]: current.count,
        };
        return acc;
      }, {});
      setGroupedData(groupedData);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getActivitiesCountListRequest({
      body: {
        startDate,
        endDate,
      },
    });
  }, [startDate, endDate]);
  return (
    <>
      <Loader isActive={getActivitiesCountListLoading} />
      <Row>
        {Object.keys(groupedData).map((category, index) => {
          const categoryActivities = Object.keys(groupedData[category]);

          return (
            <Col md={4} key={`_test_${index}`}>
              <Card>
                <CardBody>
                  <CardTitle>{category}</CardTitle>
                  <div className="table-responsive" style={{ minHeight: 300, maxHeight: 300 }}>
                    <table key={`_table_${index}`} className="table table-striped table-centered mb-0">
                      <thead className="thead-light">
                        <tr className="table-dark">
                          <th>Activity Name</th>
                          <th>Count</th>
                        </tr>
                      </thead>
                      <tbody>
                        {categoryActivities.map((activity, key) => (
                          <tr key={`_key_${key}`}>
                            <td className="table-warning">{activity}</td>
                            <td className="table-info">{groupedData[category][activity]}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default BabyActivityCount;
