import { Divider } from '@material-ui/core';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Paginate from 'components/Paginate';
import TableError from 'components/TableError';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { buildURL, checkIfValueExist, getTranslationLabel } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import {
  ACTIVE_AND_DELETED_ARRAY,
  BABY_NAME_META_DATA_TYPE,
  BABY_NAME_TYPE,
  LANGUAGE_ARRAY_WITH_STRING,
  LANGUAGE_VALUES_STRING,
  PRINT_LANGUAGE,
  PRINT_LANGUAGE_COLOR,
  SORT_VALUES,
} from '../../../utils/constants';

const BabynameMetaDataList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [babyNameMetaInfoList, setbabyNameMetaInfoList] = useState([]);
  const [babyNamesMetaInfoPagination, setbabyNamesMetaInfoPagination] = useState({});

  const defaultPayload = {
    page: 1,
    size: 20,
    sortBy: 'id',
    sortOrder: -1,
    language: LANGUAGE_VALUES_STRING.english,
  };

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    popular: { value: null, label: 'All' },
    language: null,
    type: null,
    translated: { value: null, label: 'All' },
    name: '',
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [getbabyNameMetaRequest, { loading: getbabyNameMetaLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      setbabyNameMetaInfoList(data?.results);
      setbabyNamesMetaInfoPagination(data?.paginator);
    },
  });

  const [updateBabyNamesMetaData, { loading: updateBabyNamesMetaDataLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      getbabyNameMetaRequest({
        body: { ...defaultPayload },
      });
      toast.success('Success! Baby Names Meta Data has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [deleteBabyNamesMetaData, { loading: deleteBabyNamesMetaDataLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      getbabyNameMetaRequest({
        body: { ...defaultPayload },
      });
      toast.success('Success! Baby Names Meta Data has been Deleted.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const handleActive = (data) => {
    data?.active
      ? updateBabyNamesMetaData({
          url: `/baby-name/admin/metaInfo/markInActive/${data?.id}`,
        })
      : updateBabyNamesMetaData({
          url: `/baby-name/admin/metaInfo/markActive/${data?.id}`,
        });
  };
  const handleDelete = (data) => {
    data?.deleted
      ? deleteBabyNamesMetaData({
          url: `/baby-name/admin/metaInfo/undelete/${data?.id}`,
        })
      : deleteBabyNamesMetaData({
          url: `/baby-name/admin/metaInfo/delete/${data?.id}`,
        });
  };

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getbabyNameMetaRequest({
      body: {
        page,
        ...filters,
        ...SORT_VALUES,
        size: 50,
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        popular: checkIfValueExist(filters.popular?.value),
        language: checkIfValueExist(filters.language?.value),
        type: checkIfValueExist(filters.type?.value),
        translated: checkIfValueExist(filters.translated?.value),
        name: checkIfValueExist(filters.name),
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);
    setFilters({ ...defaultFilters });

    getbabyNameMetaRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: defaultFilters.active?.value,
        deleted: defaultFilters.deleted?.value,
        popular: defaultFilters.popular?.value,
        language: defaultFilters.language?.value,
        type: defaultFilters.type?.value,
        translated: defaultFilters.translated?.value,
        name: defaultFilters.name,
        ...SORT_VALUES,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getbabyNameMetaLoading || updateBabyNamesMetaDataLoading || deleteBabyNamesMetaDataLoading} />
        <Container fluid>
          <Breadcrumbs title="Babynames" breadcrumbItem="Baby Names Meta Data List" />

          <Card>
            <CardBody>
              <div className="mb-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Popular</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.popular}
                      onChange={(value) => setFilters({ ...filters, popular: value })}
                    />
                  </div>

                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Type</label>
                    <Select
                      id="type"
                      options={BABY_NAME_TYPE}
                      value={filters.type}
                      onChange={(value) => setFilters({ ...filters, type: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Translated</label>
                    <Select
                      id="translated"
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.translated}
                      onChange={(value) => setFilters({ ...filters, translated: value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY_WITH_STRING}
                      value={filters.language}
                      onChange={(value) => setFilters({ ...filters, language: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Name</label>
                    <Input
                      type="text"
                      placeholder="Search by name"
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2">
                <div className="position-relative font-bold">
                  {babyNamesMetaInfoPagination?.totalCount} Baby Names Meta Data Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_BABY_NAME_META_DATA}>
                  <i className="mdi mdi-plus me-1" />
                  Add Meta Data
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless ">
                    <thead>
                      <tr align="center" className="table-warning">
                        <th scope="col">#ID</th>
                        <th scope="col">Name/Slug</th>
                        <th scope="col">Translated</th>
                        <th scope="col">Type</th>
                        <th scope="col">Language</th>
                        <th scope="col">Popular</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(babyNameMetaInfoList) ? (
                        babyNameMetaInfoList?.map((data, index) => (
                          <tr key={index} align="center">
                            <td>{data?.id}</td>
                            <td>
                              <div>
                                <Link
                                  to={buildURL(SMKLinks.EDIT_BABY_NAME_META_DATA, {
                                    id: data?.id,
                                  })}>
                                  {data.name}
                                </Link>
                                <br />
                                {data.slug}
                              </div>
                            </td>
                            <td>
                              <Link
                                to={buildURL(SMKLinks.EDIT_BABY_NAME_META_DATA, {
                                  id: data?.translations[0]?.id,
                                })}>
                                {getTranslationLabel(data?.translations[0])}
                              </Link>
                              <br />
                              {data?.translations[0]?.slug}

                              {/* {data?.language !== LANGUAGE_VALUES_STRING?.english && data?.enId ? (
                                <Link
                                  to={buildURL(SMKLinks.EDIT_BABY_NAME_META_DATA, {
                                    id: data?.enId,
                                  })}>
                                  {data?.enId}
                                </Link>
                              ) : (
                                <Badge className={data?.translated || data?.enId ? 'bg-success' : 'bg-danger'}>
                                  {data?.translated || data?.enId ? 'Yes' : 'No'}
                                </Badge>
                              )} */}
                            </td>

                            <td>{BABY_NAME_META_DATA_TYPE[data?.type]}</td>
                            <td>
                              <Badge className={PRINT_LANGUAGE_COLOR[data?.language]}>
                                {PRINT_LANGUAGE[data?.language]}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.popular ? 'bg-success' : 'bg-danger'}>
                                {data?.popular ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleActive(data)}>
                                    <i
                                      className={`fas  ${
                                        data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                      }   m-1 pe-3`}
                                    />
                                    {data?.active ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                  <DropdownItem onClick={() => handleDelete(data)}>
                                    <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                    {data?.deleted ? 'Undelete' : 'Delete'}
                                  </DropdownItem>
                                  <Divider className="my-2" />
                                  <Link to={buildURL(SMKLinks.EDIT_BABY_NAME_META_DATA, { id: data?.id })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>
                                  {data?.language !== LANGUAGE_VALUES_STRING.hindi && isEmpty(data?.translations) && (
                                    <Link to={buildURL(SMKLinks.TRANSLATE_BABY_NAME_META_DATA, { id: data?.id })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Translate
                                      </DropdownItem>
                                    </Link>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={babyNamesMetaInfoPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default BabynameMetaDataList;
