import { createSelector } from 'reselect';

import { initialState } from './reducer';

export const OTHER_STORE_KEY = 'others';

const selectOthersSubStore = (store) => store[OTHER_STORE_KEY] || initialState;

export const countriesSelector = createSelector(selectOthersSubStore, (subState) => subState.countries);

export const regionsSelector = createSelector(selectOthersSubStore, (subState) => subState.regions);

export const districtsSelector = createSelector(selectOthersSubStore, (subState) => subState.districts);

export const citiesSelector = createSelector(selectOthersSubStore, (subState) => subState.cities);

export const subAreasSelector = createSelector(selectOthersSubStore, (subState) => subState.subAreas);
