import { PUBLIC_URL } from 'utils/constants';

export default {
  RECIPE_LIST: `${PUBLIC_URL}/nutrition/recipe`,
  RECIPE_ADD: `${PUBLIC_URL}/nutrition/recipe/add`,
  RECIPE_EDIT: `${PUBLIC_URL}/nutrition/recipe/edit/:id`,

  FOOD_ITEMS_LIST: `${PUBLIC_URL}/nutrition/food-items`,
  FOOD_ITEMS_ADD: `${PUBLIC_URL}/nutrition/food-items/add`,
  FOOD_ITEMS_EDIT: `${PUBLIC_URL}/nutrition/food-items/edit/:id`,
};
