import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { api } from '../../utils/axios';
import config from '../../config/config';
import { SMKLinks } from '../../utils/links';
import {
  addSchool,
  admissionInquiriesMarkActionTaken,
  admissionInquiriesMarkDeleted,
  getAdmissionInquiries,
  getCities,
  getCountries,
  getRegions,
  getSchoolBoards,
  getSchoolDetail,
  getSchoolGoogleObjectForApproval,
  getSchoolGoogleObjectForReviewsApproval,
  getSchoolGoogleObjects,
  getSchoolLevels,
  getSchoolReviews,
  getSchools,
  getSchoolsInfraList,
  getSchoolsLevelMaster,
  getSchoolsMasterData,
  getSchoolsGroups,
  getSubAreas,
  schoolReviewApprove,
  schoolReviewDelete,
  // NOTE: Remove later
  // updateSchoolAcademicDetails,
  // updateSchoolFacilityDetails,
  updateSchoolBasicInfo,
  updateSchoolContactDetails,
  updateSchoolAdmissionInfo,
  updateSchoolFeeDetails,
  updateSchoolAcademicDetails,
  updateSchoolFacilityDetails,
  updateSchoolExtraCurricularDetails,
  updateSchoolAdditionalInfo,
  updateSchoolSystemInfo,
} from './actions';
import { POST, PUT, GET } from '../../utils/httpMethods';
import { setGlobalError } from '../../common/globalComponents/actions';

export const ADD_SCHOOL_API_URL = `${config.apiEndpoint}/school/admin`;
export const GET_SCHOOL_API_URL = `${config.apiEndpoint}/school/admin/`;

export const GET_SCHOOLS_API_URL = `${config.apiEndpoint}/school/admin/search`;
export const GET_SCHOOL_REVIEWS_API_URL = `${config.apiEndpoint}/school/admin/review/search`;
export const GET_SCHOOL_GOOGLE_OBJECTS_API_URL = `${config.apiEndpoint}/admin/googleSchoolCards/search`;
export const GET_SCHOOL_GOOGLE_OBJECT_FOR_APPROVAL_API_URL = `${config.apiEndpoint}/admin/googleSchoolCards/forApproval`;
export const GET_SCHOOL_GOOGLE_OBJECT_FOR_REVIEWS_APPROVAL_API_URL = `${config.apiEndpoint}/admin/googleSchoolCards/forReviewsApproval`;

export const GET_PLACES = `${config.apiEndpoint}/place/admin/search`;
export const GET_ADMISSION_INQUIRIES_API_URL = `${config.apiEndpoint}/school/admin/admissionInquiry/search`;
export const ADMISSION_INQUIRIES_MARK_ACTION_TAKEN_API_URL = `${config.apiEndpoint}/school/admin/admissionInquiry/markActionTaken/`;
export const ADMISSION_INQUIRIES_MARK_DELETED_API_URL = `${config.apiEndpoint}/school/admin/admissionInquiry/delete/`;

export const SCHOOL_REVIEW_APPROVE_API = `${config.apiEndpoint}/school/admin/review/approve/`;
export const SCHOOL_REVIEW_DELETE_API = `${config.apiEndpoint}/school/admin/review/delete/`;

export const GET_SCHOOL_BOARDS_API_URL = `${config.apiEndpoint}/school/admin/curriculum/search`;
export const GET_SCHOOL_LEVELS_API_URL = `${config.apiEndpoint}/school/schoolLevel/getMasterData`;
export const GET_SCHOOLS_MASTER_DATA_API_URL = `${config.apiEndpoint}/school/schoolMasterData/search`;
export const GET_SCHOOLS_INFRA_LIST_API_URL = `${config.apiEndpoint}/school/schoolMasterData/getAllInfra`;
export const GET_SCHOOLS_LEVEL_MASTER_DATA_API_URL = `${config.apiEndpoint}/school/schoolLevel/getMasterData`;
export const GET_SCHOOLS_GROUPS_URL = `${config.apiEndpoint}/school/admin/schoolGroups/search`;

// NOTE: Remove later
// export const UPDATE_SCHOOL_FACILITY_DETAILS_API_URL = `${config.apiEndpoint}/school/admin/updateFacilityDetails`;
// export const UPDATE_SCHOOL_ACADEMIC_DETAILS_API_URL = `${config.apiEndpoint}/school/admin/updateAcademicDetails`;

export const UPDATE_SCHOOL_BASIC_INFO_API_URL = `${config.apiEndpoint}/school/admin/edit/updateBasicInfo`;
export const UPDATE_SCHOOL_CONTACT_DETAILS_API_URL = `${config.apiEndpoint}/school/admin/edit/updateContactDetails`;
export const UPDATE_SCHOOL_ADMISSION_INFO_API_URL = `${config.apiEndpoint}/school/admin/edit/updateAdmissionInfo`;
export const UPDATE_SCHOOL_FEE_DETAILS_API_URL = `${config.apiEndpoint}/school/admin/edit/updateFeeDetails`;
export const UPDATE_SCHOOL_ACADEMIC_DETAILS_API_URL = `${config.apiEndpoint}/school/admin/edit/updateAcademicDetails`;
export const UPDATE_SCHOOL_FACILITY_DETAILS_API_URL = `${config.apiEndpoint}/school/admin/edit/updateFacilityDetails`;
export const UPDATE_SCHOOL_EXTRA_CURRICULAR_DETAILS_API_URL = `${config.apiEndpoint}/school/admin/edit/updateExtraCurricularDetails`;
export const UPDATE_SCHOOL_ADDITIONAL_INFO_API_URL = `${config.apiEndpoint}/school/admin/edit/updateAdditionalInfo`;
export const UPDATE_SCHOOL_SYSTEM_INFO_API_URL = `${config.apiEndpoint}/school/admin/edit/updateSystemInfo`;

const getSchoolsCall = (filters) =>
  api({
    method: POST,
    url: GET_SCHOOLS_API_URL,
    data: filters,
  });
const getSchoolsReviewsCall = (filters) =>
  api({
    method: POST,
    url: GET_SCHOOL_REVIEWS_API_URL,
    data: filters,
  });

const getSchoolGoogleObjectsCall = (filters) =>
  api({
    method: POST,
    url: GET_SCHOOL_GOOGLE_OBJECTS_API_URL,
    data: filters,
  });

const getSchoolGoogleObjectForApprovalCall = () =>
  api({
    method: POST,
    url: GET_SCHOOL_GOOGLE_OBJECT_FOR_APPROVAL_API_URL,
    data: {},
  });
const getSchoolGoogleObjectForReviewsApprovalCall = () =>
  api({
    method: POST,
    url: GET_SCHOOL_GOOGLE_OBJECT_FOR_REVIEWS_APPROVAL_API_URL,
    data: {},
  });

const getPlaceCall = (filter) =>
  api({
    method: POST,
    url: GET_PLACES,
    data: filter,
  });

const getAdmissionInquiriesCall = (filter) =>
  api({
    method: POST,
    url: GET_ADMISSION_INQUIRIES_API_URL,
    data: filter,
  });

const admissionInquiriesMarkActionTakenCall = (object) =>
  api({
    method: PUT,
    url: ADMISSION_INQUIRIES_MARK_ACTION_TAKEN_API_URL + object.id,
    data: {},
  });

const admissionInquiriesMarkDeletedCall = (object) =>
  api({
    method: PUT,
    url: ADMISSION_INQUIRIES_MARK_DELETED_API_URL + object.id,
    data: {},
  });

const schoolReviewApproveCall = (data) =>
  api({
    method: PUT,
    url: SCHOOL_REVIEW_APPROVE_API + data.id,
  });
const schoolReviewDeleteCall = (data) =>
  api({
    method: PUT,
    url: SCHOOL_REVIEW_DELETE_API + data.id,
  });

const addSchoolCall = (school) =>
  api({
    method: POST,
    url: ADD_SCHOOL_API_URL,
    data: school,
  });

const getSchoolDetailCall = (id) =>
  api({
    method: GET,
    url: GET_SCHOOL_API_URL + id,
  });

const getSchoolsInfraListCall = () =>
  api({
    method: GET,
    url: GET_SCHOOLS_INFRA_LIST_API_URL,
    data: {},
  });
const getSchoolsMasterDataCall = () =>
  api({
    method: GET,
    url: GET_SCHOOLS_MASTER_DATA_API_URL,
    data: {},
  });

const getSchoolsLevelMasterDataCall = () =>
  api({
    method: POST,
    url: GET_SCHOOLS_LEVEL_MASTER_DATA_API_URL,
    data: {},
  });

const getSchoolsGroupsCall = (data) =>
  api({
    method: POST,
    url: GET_SCHOOLS_GROUPS_URL,
    data: data,
  });

const getSchoolBoardsCall = () =>
  api({
    method: POST,
    url: GET_SCHOOL_BOARDS_API_URL,
    data: {},
  });

const getSchoolLevelsCall = (country) => api({ method: POST, url: `${GET_SCHOOL_LEVELS_API_URL}`, data: { country } });

// const getSchoolsMasterDataCall = (name, type) =>
//   api({
//     method: POST,
//     url: GET_SCHOOLS_MASTER_DATA_API_URL + '?name=' + name + '&type=' + type,
//     data: {},
//   });

// NOTE: Remove later
// const updateSchoolFacilityDetailsCall = (data) =>
//   api({
//     method: POST,
//     url: UPDATE_SCHOOL_FACILITY_DETAILS_API_URL,
//     data: { ...data },
//   });
// const updateSchoolAcademicDetailsCall = (data) =>
//   api({
//     method: POST,
//     url: UPDATE_SCHOOL_ACADEMIC_DETAILS_API_URL,
//     data,
//   });

const updateSchoolBasicInfoCall = (data) =>
  api({
    method: POST,
    url: UPDATE_SCHOOL_BASIC_INFO_API_URL,
    data,
  });

const updateSchoolContactDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_SCHOOL_CONTACT_DETAILS_API_URL,
    data,
  });
const updateSchoolAdmissionInfoCall = (data) =>
  api({
    method: POST,
    url: UPDATE_SCHOOL_ADMISSION_INFO_API_URL,
    data,
  });

const updateSchoolFeeDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_SCHOOL_FEE_DETAILS_API_URL,
    data,
  });

const updateSchoolAcademicDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_SCHOOL_ACADEMIC_DETAILS_API_URL,
    data,
  });

const updateSchoolFacilityDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_SCHOOL_FACILITY_DETAILS_API_URL,
    data,
  });
const updateSchoolExtraCurricularDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_SCHOOL_EXTRA_CURRICULAR_DETAILS_API_URL,
    data,
  });
const updateSchoolAdditionalInfoCall = (data) =>
  api({
    method: POST,
    url: UPDATE_SCHOOL_ADDITIONAL_INFO_API_URL,
    data,
  });
const updateSchoolSystemInfoCall = (data) =>
  api({
    method: POST,
    url: UPDATE_SCHOOL_SYSTEM_INFO_API_URL,
    data,
  });

function* getSchoolsRequest(action) {
  try {
    yield put(getSchools.request({ isLoading: true }));
    const response = yield call(getSchoolsCall, action.payload);
    if (response) {
      yield put(getSchools.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getSchools.failure(error));
  } finally {
    yield put(getSchools.fulfill({ isLoading: false }));
  }
}

function* getSchoolReviewsRequest(action) {
  try {
    yield put(getSchoolReviews.request({ isLoading: true }));
    const response = yield call(getSchoolsReviewsCall, action.payload);
    if (response) {
      yield put(getSchoolReviews.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getSchoolReviews.failure(error));
  } finally {
    yield put(getSchoolReviews.fulfill({ isLoading: false }));
  }
}

function* getSchoolGoogleObjectsRequest(action) {
  try {
    yield put(getSchoolGoogleObjects.request({ isLoading: true }));
    const response = yield call(getSchoolGoogleObjectsCall, action.payload);
    if (response) {
      yield put(getSchoolGoogleObjects.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getSchoolGoogleObjects.failure(error));
  } finally {
    yield put(getSchoolGoogleObjects.fulfill({ isLoading: false }));
  }
}

function* getSchoolGoogleObjectForApprovalRequest(action) {
  try {
    yield put(getSchoolGoogleObjectForApproval.request({ isLoading: true }));
    const response = yield call(getSchoolGoogleObjectForApprovalCall, action.payload);
    if (response) {
      yield put(getSchoolGoogleObjectForApproval.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getSchoolGoogleObjectForApproval.failure(error));
  } finally {
    yield put(getSchoolGoogleObjectForApproval.fulfill({ isLoading: false }));
  }
}

function* getSchoolGoogleObjectForReviewsApprovalRequest(action) {
  try {
    yield put(getSchoolGoogleObjectForReviewsApproval.request({ isLoading: true }));
    const response = yield call(getSchoolGoogleObjectForReviewsApprovalCall, action.payload);
    if (response) {
      yield put(getSchoolGoogleObjectForReviewsApproval.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getSchoolGoogleObjectForReviewsApproval.failure(error));
  } finally {
    yield put(getSchoolGoogleObjectForReviewsApproval.fulfill({ isLoading: false }));
  }
}

function* getCountriesRequest(action) {
  try {
    yield put(getCountries.request({ isLoading: true }));
    const response = yield call(getPlaceCall, action.payload);
    if (response) {
      yield put(getCountries.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getCountries.failure(error));
  } finally {
    yield put(getCountries.fulfill({ isLoading: false }));
  }
}

function* getRegionsRequest(action) {
  try {
    yield put(getRegions.request({ isLoading: true }));
    const response = yield call(getPlaceCall, action.payload);
    if (response) {
      yield put(getRegions.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getRegions.failure(error));
  } finally {
    yield put(getRegions.fulfill({ isLoading: false }));
  }
}

function* getCitiesRequest(action) {
  try {
    yield put(getCities.request({ isLoading: true }));
    const response = yield call(getPlaceCall, action.payload);
    if (response) {
      yield put(getCities.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getCities.failure(error));
  } finally {
    yield put(getCities.fulfill({ isLoading: false }));
  }
}

function* getSubAreasRequest(action) {
  try {
    yield put(getSubAreas.request({ isLoading: true }));
    const response = yield call(getPlaceCall, action.payload);
    if (response) {
      yield put(getSubAreas.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getSubAreas.failure(error));
  } finally {
    yield put(getSubAreas.fulfill({ isLoading: false }));
  }
}

function* getAdmissionInquiriesRequest(action) {
  try {
    yield put(getAdmissionInquiries.request({ isLoading: true }));
    const response = yield call(getAdmissionInquiriesCall, action.payload);
    if (response) {
      yield put(getAdmissionInquiries.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getAdmissionInquiries.failure(error));
  } finally {
    yield put(getAdmissionInquiries.fulfill({ isLoading: false }));
  }
}

function* admissionInquiriesMarkActionTakenRequest(action) {
  try {
    yield put(admissionInquiriesMarkActionTaken.request({ isLoading: true }));
    const response = yield call(admissionInquiriesMarkActionTakenCall, action.payload);

    if (response) {
      yield put(admissionInquiriesMarkActionTaken.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(admissionInquiriesMarkActionTaken.failure(error));
  } finally {
    yield put(admissionInquiriesMarkActionTaken.fulfill({ isLoading: false }));
  }
}

function* admissionInquiriesMarkDeletedRequest(action) {
  try {
    yield put(admissionInquiriesMarkDeleted.request({ isLoading: true }));
    const response = yield call(admissionInquiriesMarkActionTakenCall, action.payload);

    if (response) {
      yield put(admissionInquiriesMarkDeleted.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(admissionInquiriesMarkDeleted.failure(error));
  } finally {
    yield put(admissionInquiriesMarkDeleted.fulfill({ isLoading: false }));
  }
}

function* schoolReviewApproveRequest(action) {
  try {
    yield put(schoolReviewApprove.request());

    const response = yield call(schoolReviewApproveCall, action.payload);

    if (response) {
      yield put(schoolReviewApprove.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(schoolReviewApprove.failure(error));
  } finally {
    yield put(schoolReviewApprove.fulfill());
  }
}

function* schoolReviewDeleteRequest(action) {
  try {
    yield put(schoolReviewDelete.request());

    const response = yield call(schoolReviewDeleteCall, action.payload);

    if (response) {
      yield put(schoolReviewDelete.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(schoolReviewDelete.failure(error));
  } finally {
    yield put(schoolReviewDelete.fulfill());
  }
}

function* addSchoolRequest(action) {
  try {
    yield put(addSchool.request({ isLoading: true }));

    const { school } = action.payload;

    const response = yield call(addSchoolCall, school);

    if (response) {
      const { addedSchool } = response;
      yield put(addSchool.success({ school: addedSchool }));
      yield put(push(SMKLinks.SCHOOL_LISTING));
    }
  } catch (error) {
    yield put(addSchool.failure(error));
  } finally {
    yield put(addSchool.fulfill({ isLoading: false }));
  }
}

function* getSchoolDetailRequest(action) {
  try {
    yield put(getSchoolDetail.request({ isLoading: true }));

    const { id } = action.payload;

    const response = yield call(getSchoolDetailCall, id);

    if (response) {
      yield put(getSchoolDetail.success(response));
    }
  } catch (error) {
    yield put(getSchoolDetail.failure(error));
  } finally {
    yield put(getSchoolDetail.fulfill({ isLoading: false }));
  }
}

function* getSchoolsInfraListRequest(action) {
  try {
    yield put(getSchoolsInfraList.request({ isLoading: true }));

    const response = yield call(getSchoolsInfraListCall);

    if (response) {
      yield put(getSchoolsInfraList.success(response));
    }
  } catch (error) {
    yield put(getSchoolsInfraList.failure(error));
  } finally {
    yield put(getSchoolsInfraList.fulfill({ isLoading: false }));
  }
}
function* getSchoolsMasterDataRequest(action) {
  try {
    yield put(getSchoolsMasterData.request());

    const response = yield call(getSchoolsMasterDataCall);

    if (response) {
      yield put(getSchoolsMasterData.success(response));
    }
  } catch (error) {
    yield put(getSchoolsMasterData.failure(error));
  } finally {
    yield put(getSchoolsMasterData.fulfill());
  }
}

function* getSchoolsLevelMasterRequest(action) {
  try {
    yield put(getSchoolsLevelMaster.request({ isLoading: true }));

    // check if data already in state - skip if so
    const response = yield call(getSchoolsLevelMasterDataCall);

    if (response) {
      yield put(getSchoolsLevelMaster.success(response));
    }
  } catch (error) {
    yield put(getSchoolsLevelMaster.failure(error));
  } finally {
    yield put(getSchoolsLevelMaster.fulfill({ isLoading: false }));
  }
}

function* getSchoolsGroupsRequest(action) {
  try {
    yield put(getSchoolsGroups.request({ isLoading: true }));

    // check if data already in state - skip if so
    const response = yield call(getSchoolsGroupsCall, action?.payload);
    if (response) {
      yield put(getSchoolsGroups.success(response));
    }
  } catch (error) {
    yield put(getSchoolsGroups.failure(error));
  } finally {
    yield put(getSchoolsGroups.fulfill({ isLoading: false }));
  }
}

function* getSchoolBoardsRequest(action) {
  try {
    yield put(getSchoolBoards.request({ isLoading: true }));

    // const { country, preschool } = action.payload;

    const response = yield call(getSchoolBoardsCall);

    if (response) {
      yield put(getSchoolBoards.success(response));
    }
  } catch (error) {
    yield put(getSchoolBoards.failure(error));
  } finally {
    yield put(getSchoolBoards.fulfill({ isLoading: false }));
  }
}

function* getSchoolLevelsRequest(action) {
  try {
    yield put(getSchoolLevels.request());

    const { country } = action.payload;

    const response = yield call(getSchoolLevelsCall, country);

    if (response) {
      yield put(getSchoolLevels.success(response));
    }
  } catch (error) {
    yield put(getSchoolLevels.failure(error));
  } finally {
    yield put(getSchoolLevels.fulfill());
  }
}

// function* getSchoolsMasterDataRequest(action) {
//   try {
//     yield put(getSchoolsMasterData.request());
//
//     const { name, type } = action.payload;
//
//     const response = yield call(getSchoolsMasterDataCall, name, type);
//
//     if (response) {
//       yield put(getSchoolsMasterData.success(response));
//     }
//   } catch (error) {
//     yield put(getSchoolsMasterData.failure(error));
//   } finally {
//     yield put(getSchoolsMasterData.fulfill());
//   }
// }

// NOTE: Remove later
// function* updateSchoolFacilityDetailsRequest(action) {
//   try {
//     yield put(updateSchoolFacilityDetails.request({ isLoading: true }));

//     const { data } = action.payload;

//     const response = yield call(updateSchoolFacilityDetailsCall, data);

//     if (response) {
//       yield put(updateSchoolFacilityDetails.success(response));
//     }
//   } catch (error) {
//     yield put(updateSchoolFacilityDetails.failure(error));
//   } finally {
//     yield put(updateSchoolFacilityDetails.fulfill({ isLoading: false }));
//   }
// }

// function* updateSchoolAcademicDetailsRequest(action) {
//   try {
//     yield put(updateSchoolAcademicDetails.request({ isLoading: true }));

//     const { data } = action.payload;

//     const response = yield call(updateSchoolAcademicDetailsCall, data);

//     if (response) {
//       yield put(updateSchoolAcademicDetails.success(response));
//     }
//   } catch (error) {
//     yield put(updateSchoolAcademicDetails.failure(error));
//   } finally {
//     yield put(updateSchoolAcademicDetails.fulfill({ isLoading: false }));
//   }
// }

function* updateSchoolBasicInfoRequest(action) {
  try {
    yield put(updateSchoolBasicInfo.request({ isLoading: true }));

    const response = yield call(updateSchoolBasicInfoCall, action.payload);

    if (response) {
      yield put(updateSchoolBasicInfo.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateSchoolBasicInfo.failure(error));
  } finally {
    yield put(updateSchoolBasicInfo.fulfill({ isLoading: false }));
  }
}
function* updateSchoolContactDetailsRequest(action) {
  try {
    yield put(updateSchoolContactDetails.request({ isLoading: true }));

    const response = yield call(updateSchoolContactDetailsCall, action.payload);

    if (response) {
      yield put(updateSchoolContactDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateSchoolContactDetails.failure(error));
  } finally {
    yield put(updateSchoolContactDetails.fulfill({ isLoading: false }));
  }
}

function* updateSchoolAdmissionInfoRequest(action) {
  try {
    yield put(updateSchoolAdmissionInfo.request({ isLoading: true }));

    const response = yield call(updateSchoolAdmissionInfoCall, action.payload);

    if (response) {
      yield put(updateSchoolAdmissionInfo.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateSchoolAdmissionInfo.failure(error));
  } finally {
    yield put(updateSchoolAdmissionInfo.fulfill({ isLoading: false }));
  }
}

function* updateSchoolFeeDetailsRequest(action) {
  try {
    yield put(updateSchoolFeeDetails.request({ isLoading: true }));

    const response = yield call(updateSchoolFeeDetailsCall, action.payload);

    if (response) {
      yield put(updateSchoolFeeDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateSchoolFeeDetails.failure(error));
  } finally {
    yield put(updateSchoolFeeDetails.fulfill({ isLoading: false }));
  }
}

function* updateSchoolAcademicDetailsRequest(action) {
  try {
    yield put(updateSchoolAcademicDetails.request({ isLoading: true }));

    const response = yield call(updateSchoolAcademicDetailsCall, action.payload);

    if (response) {
      yield put(updateSchoolAcademicDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateSchoolAcademicDetails.failure(error));
  } finally {
    yield put(updateSchoolAcademicDetails.fulfill({ isLoading: false }));
  }
}

function* updateSchoolFacilityDetailsRequest(action) {
  try {
    yield put(updateSchoolFacilityDetails.request({ isLoading: true }));

    const response = yield call(updateSchoolFacilityDetailsCall, action.payload);

    if (response) {
      yield put(updateSchoolFacilityDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateSchoolFacilityDetails.failure(error));
  } finally {
    yield put(updateSchoolFacilityDetails.fulfill({ isLoading: false }));
  }
}

function* updateSchoolExtraCurricularDetailsRequest(action) {
  try {
    yield put(updateSchoolExtraCurricularDetails.request({ isLoading: true }));

    const response = yield call(updateSchoolExtraCurricularDetailsCall, action.payload);

    if (response) {
      yield put(updateSchoolExtraCurricularDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateSchoolExtraCurricularDetails.failure(error));
  } finally {
    yield put(updateSchoolExtraCurricularDetails.fulfill({ isLoading: false }));
  }
}
function* updateSchoolSystemInfoRequest(action) {
  try {
    yield put(updateSchoolSystemInfo.request({ isLoading: true }));

    const response = yield call(updateSchoolSystemInfoCall, action.payload);

    if (response) {
      yield put(updateSchoolSystemInfo.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateSchoolSystemInfo.failure(error));
  } finally {
    yield put(updateSchoolSystemInfo.fulfill({ isLoading: false }));
  }
}
function* updateSchoolAdditionalInfoRequest(action) {
  try {
    yield put(updateSchoolAdditionalInfo.request({ isLoading: true }));

    const response = yield call(updateSchoolAdditionalInfoCall, action.payload);

    if (response) {
      yield put(updateSchoolAdditionalInfo.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateSchoolAdditionalInfo.failure(error));
  } finally {
    yield put(updateSchoolAdditionalInfo.fulfill({ isLoading: false }));
  }
}

export default function* schoolSaga() {
  yield takeLatest(addSchool.TRIGGER, addSchoolRequest);
  yield takeLatest(getSchoolDetail.TRIGGER, getSchoolDetailRequest);

  yield takeLatest(getSchools.TRIGGER, getSchoolsRequest);
  yield takeLatest(getSchoolReviews.TRIGGER, getSchoolReviewsRequest);
  yield takeLatest(getSchoolGoogleObjects.TRIGGER, getSchoolGoogleObjectsRequest);
  yield takeLatest(getSchoolGoogleObjectForApproval.TRIGGER, getSchoolGoogleObjectForApprovalRequest);
  yield takeLatest(getSchoolGoogleObjectForReviewsApproval.TRIGGER, getSchoolGoogleObjectForReviewsApprovalRequest);

  yield takeLatest(getCountries.TRIGGER, getCountriesRequest);
  yield takeLatest(getRegions.TRIGGER, getRegionsRequest);
  yield takeLatest(getCities.TRIGGER, getCitiesRequest);
  yield takeLatest(getSubAreas.TRIGGER, getSubAreasRequest);

  yield takeLatest(getAdmissionInquiries.TRIGGER, getAdmissionInquiriesRequest);
  yield takeLatest(admissionInquiriesMarkActionTaken.TRIGGER, admissionInquiriesMarkActionTakenRequest);
  yield takeLatest(admissionInquiriesMarkDeleted.TRIGGER, admissionInquiriesMarkDeletedRequest);

  yield takeLatest(schoolReviewApprove.TRIGGER, schoolReviewApproveRequest);
  yield takeLatest(schoolReviewDelete.TRIGGER, schoolReviewDeleteRequest);

  yield takeLatest(getSchoolBoards.TRIGGER, getSchoolBoardsRequest);
  yield takeLatest(getSchoolLevels.TRIGGER, getSchoolLevelsRequest);
  yield takeLatest(getSchoolsLevelMaster.TRIGGER, getSchoolsLevelMasterRequest);
  yield takeLatest(getSchoolsMasterData.TRIGGER, getSchoolsMasterDataRequest);
  yield takeLatest(getSchoolsGroups.TRIGGER, getSchoolsGroupsRequest);

  yield takeLatest(getSchoolsInfraList.TRIGGER, getSchoolsInfraListRequest);

  // NOTE: Remove later
  // yield takeLatest(updateSchoolFacilityDetails.TRIGGER, updateSchoolFacilityDetailsRequest);
  // yield takeLatest(updateSchoolAcademicDetails.TRIGGER, updateSchoolAcademicDetailsRequest);

  yield takeLatest(updateSchoolBasicInfo.TRIGGER, updateSchoolBasicInfoRequest);
  yield takeLatest(updateSchoolContactDetails.TRIGGER, updateSchoolContactDetailsRequest);
  yield takeLatest(updateSchoolAdmissionInfo.TRIGGER, updateSchoolAdmissionInfoRequest);
  yield takeLatest(updateSchoolFeeDetails.TRIGGER, updateSchoolFeeDetailsRequest);
  yield takeLatest(updateSchoolAcademicDetails.TRIGGER, updateSchoolAcademicDetailsRequest);
  yield takeLatest(updateSchoolFacilityDetails.TRIGGER, updateSchoolFacilityDetailsRequest);
  yield takeLatest(updateSchoolExtraCurricularDetails.TRIGGER, updateSchoolExtraCurricularDetailsRequest);
  yield takeLatest(updateSchoolAdditionalInfo.TRIGGER, updateSchoolAdditionalInfoRequest);
  yield takeLatest(updateSchoolSystemInfo.TRIGGER, updateSchoolSystemInfoRequest);
}
