import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { createStructuredSelector } from 'reselect';

// i18n and selectors
import { isLoggedInSelector, userSelector } from 'common/globalComponents/selectors';
import { API_BASE_URL } from 'utils/constants';
import AuthService from 'common/auth.service';
import RHAMenuBar from './RHAMenuBar';
import { RenderLogo } from './Sidebar';

// Redux state
const stateSelector = createStructuredSelector({
  isLoggedIn: isLoggedInSelector,
  user: userSelector,
});

RenderLogo.propTypes = {
  leftMenu: PropTypes.bool.isRequired,
  appSource: PropTypes.string,
};

// Main Sidebar component
const HrSidebar = (props) => {
  const auth = new AuthService();
  const currentAppSource = auth.getAppSourceCookie();

  const { user, isLoggedIn } = useSelector(stateSelector);
  const appSource = user?.appSource || 'schoolmykids';

  return (
    <div className="vertical-menu">
      <div
        className={
          API_BASE_URL === 'https://www.schoolmykids.com/api/v2'
            ? 'bg-warning navbar-brand-box px-0'
            : 'navbar-brand-box px-0'
        }>
        <Link to="/hr" className="logo logo-dark">
          <span className="logo-sm">
            <RenderLogo leftMenu={true} appSource={appSource} currentAppSource={currentAppSource} />
          </span>
          <span className="logo-lg">
            <RenderLogo leftMenu={false} appSource={appSource} currentAppSource={currentAppSource} />
          </span>
        </Link>

        <Link to="/hr" className="logo logo-light">
          <span className="logo-sm">
            <RenderLogo leftMenu={true} appSource={appSource} currentAppSource={currentAppSource} />
          </span>
          <span className="logo-lg">
            <RenderLogo leftMenu={false} appSource={appSource} currentAppSource={currentAppSource} />
          </span>
        </Link>
      </div>
      <div data-simplebar className="h-100">
        <RHAMenuBar />
      </div>
    </div>
  );
};

HrSidebar.propTypes = {
  leftMenu: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  layout: state.Layout,
});

export default connect(mapStateToProps, {})(withRouter(withTranslation()(HrSidebar)));
