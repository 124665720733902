import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import { useFormik } from 'formik';
import { useRouter } from 'hooks/useRouter';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { LANGUAGE_ARRAY, taxonomyType } from 'utils/constants';
import * as Yup from 'yup';
import useRequest from '../../../../hooks/useRequest';
import { SMKLinks } from '../../../../utils/links';

const UpdateMilestone = () => {
  const history = useHistory();
  const { id: milestoneID } = useParams();
  const router = useRouter();

  const { query } = router;

  toast.configure();

  const [milestonDetails, setmilestonDetails] = useState();
  const [categoryList, setcategoryList] = useState([]);
  const [ageGroupList, setageGroupList] = useState([]);

  // Dropdown Api Call
  const [getCategoryListRequest, { loading: getCategoryListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,

    method: 'POST',
    onSuccess: (data) => {
      setcategoryList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [getAgegroupListRequest, { loading: getAgegroupListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setageGroupList(
        data?.results
          ?.sort((a, b) => a.metaData?.value - b.metaData?.value)
          ?.map((e) => ({
            label: e.name,
            value: e.id,
          }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getMilestoneRequest, { loading: getMilestoneLoading }] = useRequest({
    url: `/milestone/admin/${milestoneID}`,

    method: 'GET',
    onSuccess: (data) => {
      setmilestonDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [addMilestoneRequest, { loading: addMilestoneLoading }] = useRequest({
    url: `/milestone/admin`,

    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.MILESTONE_LISTING;
      history.replace(url);
      toast.success('Success! Milestone has been added.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [UpdateMilestoneRequest, { loading: UpdateMilestoneLoading }] = useRequest({
    url: `/milestone/admin/${milestoneID}`,
    method: 'PUT',

    onSuccess: (data) => {
      const url = SMKLinks.MILESTONE_LISTING;
      history.replace(url);
      toast.success('Success! Milestone has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const isEmptyValue = (val) => (val !== null || val !== undefined ? val : undefined);

  const editInitialValues = () => {
    return {
      active: milestonDetails?.active,
      deleted: milestonDetails?.deleted,
      hasArticles: milestonDetails?.hasArticles,
      title: milestonDetails?.title,
      slug: milestonDetails?.slug,
      categoryId: categoryList?.find((e) => e?.value === milestonDetails?.categoryId),
      ageGroupId: ageGroupList?.find((e) => e?.value === milestonDetails?.ageGroupId),
      language: LANGUAGE_ARRAY?.find((e) => e?.value === milestonDetails?.language),
      order: milestonDetails?.order,
      description: milestonDetails?.description,
      detailsDate: isEmptyValue(milestonDetails?.details?.date)
        ? moment(milestonDetails?.details?.date)?.toDate('dd-mm-yyyy')
        : '',
      // content: milestonDetails?.content,
      image: milestonDetails?.image,
    };
  };

  const getInitialValues = () => ({
    active: false,
    deleted: false,
    hasArticles: false,
    title: '',
    slug: '',
    categoryId: null,
    ageGroupId: null,
    language: { value: 0, label: 'English' },
    order: '',
    description: '',
    detailsDate: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(milestoneID) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: async (values) => {
      const data = {
        active: values?.active || false,
        deleted: values?.deleted || false,
        hasArticles: values?.hasArticles || false,
        title: isEmptyValue(values?.title),
        slug: values?.slug,
        categoryId: isEmptyValue(values?.categoryId?.value),
        ageGroupId: isEmptyValue(values?.ageGroupId?.value),
        language: values?.language?.value || 0,
        order: values?.order || 0,
        description: isEmptyValue(values?.description),
        details: isEmptyValue(values?.detailsDate) ? { date: moment(values?.detailsDate).toISOString() } : {},
      };

      if (isEmpty(milestoneID)) {
        addMilestoneRequest({
          body: {
            ...data,
          },
        });
      } else {
        UpdateMilestoneRequest({
          body: {
            ...data,
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Please provide title'),
      categoryId: Yup.object()
        .required('Please provide category')
        .nullable(true),
      // ageGroupId: Yup.object()
      //   .required('Please provide Age group')
      //   .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(milestoneID)) {
      getMilestoneRequest();
    }
  }, [milestoneID]);

  // Dropdowns data api call
  useEffect(() => {
    const searchPayload = {
      page: 1,
      size: 1000,
      sortBy: 'name',
      sortOrder: 0,
    };
    getCategoryListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.MILESTONE_CATEGORY,
      },
    });
    getAgegroupListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.AGE_GROUP,
      },
    });
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getCategoryListLoading ||
            getAgegroupListLoading ||
            addMilestoneLoading ||
            getMilestoneLoading ||
            UpdateMilestoneLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="Milestone" breadcrumbItem={`${isEmpty(milestoneID) ? 'Add' : 'Edit'}  Milestone`} />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="title" className="form-label  ">
                            Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Enter Title..."
                            invalid={!!(formik.touched.title && formik.errors.title)}
                            {...formik.getFieldProps('title')}
                          />
                          <FormFeedback className="d-block">{formik.errors.title}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12} className="mb-4">
                        <SlugInput
                          formik={formik}
                          ID={milestoneID}
                          nameValue={formik.values.title}
                          formGroupClassName="mb-0"
                        />
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="categoryId" className="form-label  ">
                            Category <span className="text-danger">*</span>
                          </Label>

                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 999 }),
                            }}
                            placeholder="Select category..."
                            options={categoryList}
                            value={formik.values.categoryId}
                            onChange={(val) => formik.setFieldValue('categoryId', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.categoryId}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="ageGroupId" className="form-label  ">
                            Age Group
                          </Label>

                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 999 }),
                            }}
                            placeholder="Select age group..."
                            options={ageGroupList}
                            value={formik.values.ageGroupId}
                            onChange={(val) => formik.setFieldValue('ageGroupId', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.ageGroupId}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label className="form-label">Details Date (Event Date)</Label>
                          <DatePicker
                            selected={formik.values.detailsDate}
                            className="form-control"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            placeholderText="Select date"
                            autoComplete="off"
                            dateFormat="dd-MM-yyyy"
                            minDate={moment('1200-01-01').toDate()}
                            onChange={(date) => formik.setFieldValue('detailsDate', date)}
                            invalid={!!(formik.touched.detailsDate && formik.errors.detailsDate)}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="description" className="form-label">
                            Description
                          </Label>
                          <Input
                            id="description"
                            name="description"
                            type="textarea"
                            rows={10}
                            className="form-control"
                            placeholder="Enter description..."
                            invalid={!!(formik.touched.description && formik.errors.description)}
                            {...formik.getFieldProps('description')}
                          />
                          <FormFeedback className="d-block">{formik.errors.description}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <div>
                      <Button type="submit" color="primary" className="mb-3 w-100">
                        {milestoneID ? 'Save Milestone' : 'Add Milestone'}
                      </Button>

                      <Button
                        className="w-100"
                        color="light"
                        onClick={() => {
                          history.replace(SMKLinks.MILESTONE_LISTING);
                        }}>
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <CardTitle className="mb-3">Active </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`active_${option}`}
                              name="active"
                              className="form-check-input"
                              checked={formik.values.active === option}
                              onChange={() => formik.setFieldValue('active', option)}
                            />
                            <label className="form-check-label" htmlFor={`active_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>

                      <Col md={12}>
                        <CardTitle className="mb-3">Delete </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`deleted_${option}`}
                              name="deleted"
                              className="form-check-input"
                              checked={formik.values.deleted === option}
                              onChange={() => formik.setFieldValue('deleted', option)}
                            />
                            <label className="form-check-label" htmlFor={`deleted_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>
                      <Col md={12}>
                        <CardTitle className="mb-3">hasArticles </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`hasArticles_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`hasArticles_${option}`}
                              name="hasArticles"
                              className="form-check-input"
                              checked={formik.values.hasArticles === option}
                              onChange={() => formik.setFieldValue('hasArticles', option)}
                            />
                            <label className="form-check-label" htmlFor={`hasArticles_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="language" className="form-label">
                            Language
                          </Label>
                          <Select
                            id="language"
                            options={LANGUAGE_ARRAY}
                            value={formik.values.language}
                            onChange={(language) => formik.setFieldValue('language', language)}
                          />
                          <FormFeedback className="d-block">{formik.errors.language}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="order" className="form-label  ">
                            Order
                          </Label>
                          <Input
                            id="order"
                            name="order"
                            type="number"
                            className="form-control"
                            placeholder="Enter order..."
                            invalid={!!(formik.touched.order && formik.errors.order)}
                            {...formik.getFieldProps('order')}
                          />
                          <FormFeedback className="d-block">{formik.errors.order}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateMilestone;
