import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';

// import Charts
import { withTranslation } from 'react-i18next';
import StackedColumnChart from './StackedColumnChart';

import modalimage1 from '../../assets/images/product/img-7.png';
import modalimage2 from '../../assets/images/product/img-4.png';

// Pages Components
import WelcomeComp from './WelcomeComp';
import MonthlyEarning from './MonthlyEarning';
import SocialSource from './SocialSource';
import ActivityComp from './ActivityComp';
import TopCities from './TopCities';
import LatestTranaction from './LatestTranaction';

// Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import HeartBeats from 'containers/others/Heartbeats';

// i18n

const Dashboard = (props) => {
  const [modal, setmodal] = useState(false);

  const reports = [
    { title: 'Orders', iconClass: 'bx-copy-alt', description: '1,235' },
    { title: 'Revenue', iconClass: 'bx-archive-in', description: '$35, 723' },
    {
      title: 'Average Price',
      iconClass: 'bx-purchase-tag-alt',
      description: '$16.2',
    },
  ];
  const email = [
    { title: 'Week', linkto: '#', isActive: false },
    { title: 'Month', linkto: '#', isActive: false },
    { title: 'Year', linkto: '#', isActive: true },
  ];

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t('Dashboards')} breadcrumbItem={props.t('Dashboard')} />

          <Row>
            <Col md={5}>
              <WelcomeComp />
            </Col>
          </Row>
          <Row className="mt-2">
            <HeartBeats />
          </Row>
        </Container>
      </div>
      {/* <Modal
        isOpen={modal}
        role="dialog"
        autoFocus
        centered
        className="exampleModal"
        tabindex="-1"
        toggle={() => {
          setmodal(!modal);
        }}>
        <div className="modal-content">
          <ModalHeader
            toggle={() => {
              setmodal(!modal);
            }}>
            Order Details
          </ModalHeader>
          <ModalBody>
            <p className="mb-2">
              Product id: <span className="text-primary">#SK2540</span>
            </p>
            <p className="mb-4">
              Billing Name: <span className="text-primary">Neal Matthews</span>
            </p>

            <div className="table-responsive" style={{ minHeight: 300 }}>
              <Table className="table table-centered table-nowrap">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Product Name</th>
                    <th scope="col">Price</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage1} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">Wireless Headphone (Black)</h5>
                        <p className="text-muted mb-0">$ 225 x 1</p>
                      </div>
                    </td>
                    <td>$ 255</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div>
                        <img src={modalimage2} alt="" className="avatar-sm" />
                      </div>
                    </th>
                    <td>
                      <div>
                        <h5 className="text-truncate font-size-14">Hoodie (Blue)</h5>
                        <p className="text-muted mb-0">$ 145 x 1</p>
                      </div>
                    </td>
                    <td>$ 145</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Sub Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Shipping:</h6>
                    </td>
                    <td>Free</td>
                  </tr>
                  <tr>
                    <td colSpan="2">
                      <h6 className="m-0 text-end">Total:</h6>
                    </td>
                    <td>$ 400</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                setmodal(!modal);
              }}>
              Close
            </Button>
          </ModalFooter>
        </div>
      </Modal> */}
    </>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(Dashboard);
