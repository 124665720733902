import PropTypes from 'prop-types';
import React from 'react';
import { Card, CardBody, Col, Media } from 'reactstrap';

const MiniCards = (props) => {
  const { title, text, iconClass, textColor } = props;
  return (
    <>
      <Col md="4">
        <Card className="mini-stats-wid">
          <CardBody>
            <Media className="d-flex justify-content-between align-items-center">
              <Media body>
                <p className="text-muted font-weight-medium mb-1">{title}</p>
                <h4 className={`mb-0 ${textColor}`}>{text}</h4>
              </Media>
              <div className="mini-stat-icon avatar-sm align-self-center rounded-circle bg-primary">
                <span className="avatar-title">
                  <i className={`bx ${iconClass} font-size-24`} />
                </span>
              </div>
            </Media>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

MiniCards.propTypes = {
  iconClass: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

export default MiniCards;
