import Login from './Login';
import MicrosoftLoginRedirect from './MicrosoftLoginRedirect';
import LOGIN_LINKS from './links';
import HrLogin from './HrLogin';

export default [
  {
    path: LOGIN_LINKS.LOGIN_PAGE,
    exact: true,
    component: Login,
  },
  {
    path: LOGIN_LINKS.HR_LOGIN_PAGE,
    exact: true,
    component: HrLogin,
  },
  {
    path: LOGIN_LINKS.MICROSOFT_LOGIN_PAGE,
    exact: true,
    component: MicrosoftLoginRedirect,
  },
];
