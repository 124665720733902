import React from 'react';
import { Container } from 'reactstrap';
// components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import SingleWorksheetForm from '../components/SingleWorksheetForm';

const CreateWorksheet = () => {
  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Worksheets" breadcrumbItem="Add Worksheet" />
          <SingleWorksheetForm />
        </Container>
      </div>
    </>
  );
};

export default CreateWorksheet;
