import { PUBLIC_URL } from '../../utils/constants';

export default {
  // Medicines
  MEDICINES_LISTING: `${PUBLIC_URL}/tpz/medicines/list`,
  ADD_MEDICINE: `${PUBLIC_URL}/tpz/medicine/add`,
  EDIT_MEDICINE: `${PUBLIC_URL}/tpz/medicine/edit/:id`,

  // Solid-food
  SOLID_FOOD_LISTING: `${PUBLIC_URL}/tpz/solid-food/list`,
  ADD_SOLID_FOOD: `${PUBLIC_URL}/tpz/solid-food/add`,
  EDIT_SOLID_FOOD: `${PUBLIC_URL}/tpz/solid-food/edit/:id`,

  // Vaccination
  VACCINATION_LISTING: `${PUBLIC_URL}/tpz/vaccination/list`,
  ADD_VACCINATION: `${PUBLIC_URL}/tpz/vaccination/add`,
  EDIT_VACCINATION: `${PUBLIC_URL}/tpz/vaccination/edit/:id`,

  // Milestone
  MILESTONE_LISTING: `${PUBLIC_URL}/tpz/milestone/list`,
  MEMORIES_MILESTONE_LISTING: `${PUBLIC_URL}/tpz/milestone/memories-list`,

  ADD_MILESTONE: `${PUBLIC_URL}/tpz/milestone/add`,
  EDIT_MILESTONE: `${PUBLIC_URL}/tpz/milestone/edit/:id`,

  TPZ_APP_DASHBOARD: `${PUBLIC_URL}/tpz/app-dashboard`,
  TPZ_ACTIVITY_LIST: `${PUBLIC_URL}/tpz/activity-history`,
};
