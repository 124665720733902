import { isEmpty } from 'lodash';
import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';

const ImageUploadPreview = (img, fieldValue, formik, setUploadImagePreview, col) => {
  return (
    <>
      {!isEmpty(img) && (
        <Row>
          <Col md={col ? col : 3} className={`${col ? 'p-0' : ''}`}>
            <Card className="mt-1 mb-0 shadow-none dz-processing dz-image-preview dz-success dz-complete border border-2">
              <CardBody>
                <div className="d-flex  flex-column">
                  <a target="_blank" rel="noreferrer" className="text-center w-100" href={img}>
                    <img src={img} style={{ width: 120 }} />
                  </a>

                  <button
                    type="button"
                    className="btn btn-soft-danger px-3 py-2 mt-3 btn-sm"
                    onClick={() => {
                      if (setUploadImagePreview) {
                        setUploadImagePreview('');
                      }
                      formik.setFieldValue(fieldValue, null);
                    }}>
                    <i className="fas fa-trash me-3" />
                    Delete
                  </button>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};
export default ImageUploadPreview;
