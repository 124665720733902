import { USER_ROLE } from 'utils/constants';
import TAXONOMY_LINKS from './links';
import UpdateTaxonomy from './updateTaxonomy';
import TaxonomyListing from './index';

const TAXONOMY_ROUTES = [
  // SMK
  {
    path: TAXONOMY_LINKS.SMK_TAXONOMY_LISTING,
    exact: true,
    component: TaxonomyListing,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TAXONOMY_LINKS.ADD_SMK_TAXONOMY,
    exact: true,
    component: UpdateTaxonomy,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TAXONOMY_LINKS.EDIT_SMK_TAXONOMY,
    exact: true,
    component: UpdateTaxonomy,
    role: [USER_ROLE.ADMIN],
  },

  // TPZ
  {
    path: TAXONOMY_LINKS.TPZ_TAXONOMY_LISTING,
    exact: true,
    component: TaxonomyListing,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TAXONOMY_LINKS.ADD_TPZ_TAXONOMY,
    exact: true,
    component: UpdateTaxonomy,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TAXONOMY_LINKS.EDIT_TPZ_TAXONOMY,
    exact: true,
    component: UpdateTaxonomy,
    role: [USER_ROLE.ADMIN],
  },
  // TPZ
  {
    path: TAXONOMY_LINKS.MEDIWATCHER_TAXONOMY_LISTING,
    exact: true,
    component: TaxonomyListing,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TAXONOMY_LINKS.ADD_MEDIWATCHER_TAXONOMY,
    exact: true,
    component: UpdateTaxonomy,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: TAXONOMY_LINKS.EDIT_MEDIWATCHER_TAXONOMY,
    exact: true,
    component: UpdateTaxonomy,
    role: [USER_ROLE.ADMIN],
  },
];

export { TAXONOMY_ROUTES, TAXONOMY_LINKS };
