import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { buildURL, checkIfValueExist, showImage } from '../../../utils/helper';
import { toast } from 'react-toastify';
import { SMKLinks } from 'utils/links';
import { Divider } from '@material-ui/core';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import { ACTIVE_AND_DELETED_ARRAY, SORT_VALUES } from 'utils/constants';
import classNames from 'classnames';

const SchoolGroupsList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [isNameSort, setIsNameSort] = useState(false);
  const [isSlugSort, setIsSlugSort] = useState(false);
  const [schoolGroups, setschoolGroups] = useState([]);
  const [schoolGroupsPagination, setschoolGroupsPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    name: '',
  };
  const [filters, setFilters] = useState(defaultFilters);
  const [schoolMetaContent, setSchoolMetaContent] = useState([]);

  const [sort, setSort] = useState({
    sortBy: 'id',
    sortOrder: -1,
  });

  const [getSchoolMetaContentRequest, { loading: getSchoolMetaContentLoading }] = useRequest({
    url: `/school/admin/schoolMetaDataContent/search`,
    method: 'POST',
    onSuccess: (data) => {
      setSchoolMetaContent(data?.results);
    },
  });

  const [getschoolGroupsRequest, { loading: getschoolGroupsLoading }] = useRequest({
    url: `/school/admin/schoolGroups/search`,
    method: 'POST',
    onSuccess: (data) => {
      setschoolGroupsPagination(data?.paginator);
      setschoolGroups(data?.results);
    },
  });

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! School Group has been ${data?.active ? 'active' : 'Inactive'}.`);
    },
  });

  const [deleteUnDeleteGroupsRequest, { loading: deleteUnDeleteGroupsLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! School Group has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getschoolGroupsRequest({
      body: {
        page,
        size: 1000,
        ...filters,
        active: checkIfValueExist(filters?.active?.value),
        deleted: checkIfValueExist(filters?.deleted?.value),
        name: checkIfValueExist(filters?.name.trim()),
        ...SORT_VALUES,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getschoolGroupsRequest({
      body: {
        page: 1,
        size: 1000,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        name: checkIfValueExist(defaultFilters?.name),
        ...SORT_VALUES,
      },
    });
  };

  const handleActive = (data) => {
    data?.active
      ? markActiveInActiveRequest({
          url: `/school/admin/schoolGroups/markInactive/${data?.id}`,
        })
      : markActiveInActiveRequest({
          url: `/school/admin/schoolGroups/markActive/${data?.id}`,
        });
  };
  const handleDelete = (data) => {
    data?.deleted
      ? deleteUnDeleteGroupsRequest({
          url: `/school/admin/schoolGroups/undelete/${data?.id}`,
        })
      : deleteUnDeleteGroupsRequest({
          url: `/school/admin/schoolGroups/delete/${data?.id}`,
        });
  };

  useEffect(() => {
    getSchoolMetaContentRequest({
      body: {
        page: 1,
        size: 5000,
        sortBy: 'id',
        sortOrder: 0,
      },
    });
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getschoolGroupsLoading ||
            markActiveInActiveLoading ||
            deleteUnDeleteGroupsLoading ||
            getSchoolMetaContentLoading
          }
        />

        <Container fluid>
          <Breadcrumbs title="Schools" breadcrumbItem="School Groups List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>

                  <div className="col-lg-3">
                    <label className="form-label">Name </label>
                    <Input
                      type="text"
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                      placeholder="Search by name"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {schoolGroupsPagination?.totalCount} School Groups Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_SCHOOL_GROUPS}>
                  <i className="mdi mdi-plus me-1" />
                  Add School Group
                </Link>
              </div>
            </Col>
          </Row>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classNames({
                  active: currentActiveTab === '1',
                })}
                onClick={() => {
                  toggle('1');
                }}>
                School Groups
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({
                  active: currentActiveTab === '2',
                })}
                onClick={() => {
                  toggle('2');
                }}>
                Associations
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive" style={{ minHeight: 300 }}>
                      <Table className="project-list-table   table-centered table-borderless">
                        <thead>
                          <tr className="table-warning">
                            <th scope="col">ID</th>
                            <th scope="col">Logo</th>
                            <th scope="col">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Name</div>
                                <button
                                  type="button"
                                  className="btn btn-white p-0 border-0"
                                  onClick={() => {
                                    setIsNameSort(!isNameSort);
                                  }}>
                                  <i className={`bx bx-sort-${isNameSort ? 'up' : 'down'} fs-5`} />
                                </button>
                              </div>
                            </th>
                            <th scope="col">Short name</th>
                            <th scope="col">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Slug</div>
                                <button
                                  type="button"
                                  className="btn btn-white p-0 border-0"
                                  onClick={() => {
                                    setIsSlugSort(!isSlugSort);
                                  }}>
                                  <i className={`bx bx-sort-${isSlugSort ? 'up' : 'down'} fs-5`} />
                                </button>
                              </div>
                            </th>
                            <th scope="col">Website</th>
                            <th scope="col">Add Content</th>
                            <th scope="col">Active</th>
                            <th scope="col">Deleted</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(schoolGroups) ? (
                            schoolGroups
                              ?.filter((u) => !u.isAssociation)
                              ?.sort((a, b) => {
                                const nameA = a[isSlugSort ? 'slug' : 'name'].toLowerCase();
                                const nameB = b[isSlugSort ? 'slug' : 'name'].toLowerCase();
                                return (isNameSort ? -1 : 1) * nameA.localeCompare(nameB);
                              })
                              ?.map((data, index) => {
                                const isMetaData = schoolMetaContent?.find(
                                  (e) => +e?.refId?.replace('group_', '') === data?.id
                                );
                                return (
                                  <tr key={index}>
                                    <td>{data?.id}</td>
                                    <td>
                                      {!isEmpty(data?.logo?.original) && (
                                        <img width={40} height={40} src={showImage(data?.logo?.original)} />
                                      )}
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-between">
                                        <Link
                                          to={buildURL(SMKLinks.EDIT_SCHOOL_GROUPS, {
                                            id: data?.id,
                                          })}>
                                          {data?.name}
                                        </Link>
                                      </div>
                                    </td>
                                    <td>{data?.shortName}</td>
                                    <td>{data?.slug}</td>
                                    <td>{data?.website}</td>
                                    <td align="center">
                                      {isMetaData ? (
                                        <Link
                                          to={buildURL(SMKLinks.EDIT_SCHOOL_META_CONTENT, {
                                            id: isMetaData?.id,
                                            refId: isMetaData?.refId,
                                          })}>
                                          <i className="bx bx-pencil fs-4 text-dark" />
                                        </Link>
                                      ) : (
                                        <Link
                                          to={buildURL(SMKLinks.ADD_SCHOOL_META_CONTENT, '', {
                                            id: data?.id,
                                            slug: data?.slug,
                                            type: 'group',
                                          })}>
                                          <i className="bx bx-plus fs-4 text-dark" />
                                        </Link>
                                      )}
                                    </td>
                                    <td>
                                      <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                        {data?.active ? 'Yes' : 'No'}
                                      </Badge>
                                    </td>
                                    <td>
                                      <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                        {data?.deleted ? 'Yes' : 'No'}
                                      </Badge>
                                    </td>
                                    <td>
                                      <UncontrolledDropdown>
                                        <DropdownToggle href="#" className="card-drop " tag="i">
                                          <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                          <DropdownItem onClick={() => handleActive(data)}>
                                            <i
                                              className={`fas  ${
                                                data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                              }   m-1 pe-3`}
                                            />
                                            {data?.active ? 'Disable' : 'Enable'}
                                          </DropdownItem>
                                          <DropdownItem onClick={() => handleDelete(data)}>
                                            <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                            {data?.deleted ? 'Undelete' : 'Delete'}
                                          </DropdownItem>

                                          <Divider className="my-2" />

                                          <Link
                                            to={buildURL(SMKLinks.EDIT_SCHOOL_GROUPS, {
                                              id: data?.id,
                                            })}>
                                            <DropdownItem>
                                              <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                              Edit
                                            </DropdownItem>
                                          </Link>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                );
                              })
                          ) : (
                            <TableError />
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
              <Paginate pageInfo={schoolGroupsPagination} onChangePage={handleChangePage} />
            </TabPane>

            <TabPane tabId="2">
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive" style={{ minHeight: 300 }}>
                      <Table className="project-list-table   table-centered table-borderless">
                        <thead>
                          <tr className="table-warning">
                            <th scope="col">ID</th>
                            <th scope="col">Logo</th>
                            <th scope="col">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Name</div>
                                <button
                                  type="button"
                                  className="btn btn-white p-0 border-0"
                                  onClick={() => {
                                    setIsNameSort(!isNameSort);
                                  }}>
                                  <i className={`bx bx-sort-${isNameSort ? 'up' : 'down'} fs-5`} />
                                </button>
                              </div>
                            </th>
                            <th scope="col">Short name</th>
                            <th scope="col">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Slug</div>
                                <button
                                  type="button"
                                  className="btn btn-white p-0 border-0"
                                  onClick={() => {
                                    setIsSlugSort(!isSlugSort);
                                  }}>
                                  <i className={`bx bx-sort-${isSlugSort ? 'up' : 'down'} fs-5`} />
                                </button>
                              </div>
                            </th>
                            <th scope="col">Website</th>
                            <th scope="col">Add Content</th>
                            <th scope="col">Active</th>
                            <th scope="col">Deleted</th>
                            <th scope="col" />
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(schoolGroups) ? (
                            schoolGroups
                              ?.filter((u) => u.isAssociation)
                              ?.sort((a, b) => {
                                const nameA = a[isSlugSort ? 'slug' : 'name'].toLowerCase();
                                const nameB = b[isSlugSort ? 'slug' : 'name'].toLowerCase();
                                return (isNameSort ? -1 : 1) * nameA.localeCompare(nameB);
                              })
                              ?.map((data, index) => {
                                const isMetaData = schoolMetaContent?.find(
                                  (e) => +e?.refId?.replace('group_', '') === data?.id
                                );

                                return (
                                  <tr key={index}>
                                    <td>{data?.id}</td>
                                    <td>
                                      {!isEmpty(data?.logo) && (
                                        <img width={40} height={40} src={showImage(data?.logo?.original)} />
                                      )}
                                    </td>
                                    <td>
                                      <div className="d-flex justify-content-between">
                                        <Link
                                          to={buildURL(SMKLinks.EDIT_SCHOOL_GROUPS, {
                                            id: data?.id,
                                          })}>
                                          {data?.name}
                                        </Link>
                                      </div>
                                    </td>
                                    <td>{data?.shortName}</td>
                                    <td>{data?.slug}</td>
                                    <td>{data?.website}</td>
                                    <td align="center">
                                      {isMetaData ? (
                                        <Link
                                          to={buildURL(SMKLinks.EDIT_SCHOOL_META_CONTENT, {
                                            id: isMetaData?.id,
                                            refId: isMetaData?.refId,
                                          })}>
                                          <i className="bx bx-pencil fs-4 text-dark" />
                                        </Link>
                                      ) : (
                                        <Link
                                          to={buildURL(SMKLinks.ADD_SCHOOL_META_CONTENT, '', {
                                            id: data?.id,
                                            slug: data?.slug,
                                            type: 'group',
                                          })}>
                                          <i className="bx bx-plus fs-4 text-dark" />
                                        </Link>
                                      )}
                                    </td>
                                    <td>
                                      <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                        {data?.active ? 'Yes' : 'No'}
                                      </Badge>
                                    </td>
                                    <td>
                                      <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                        {data?.deleted ? 'Yes' : 'No'}
                                      </Badge>
                                    </td>
                                    <td>
                                      <UncontrolledDropdown>
                                        <DropdownToggle href="#" className="card-drop " tag="i">
                                          <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                          <DropdownItem onClick={() => handleActive(data)}>
                                            <i
                                              className={`fas  ${
                                                data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                              }   m-1 pe-3`}
                                            />
                                            {data?.active ? 'Disable' : 'Enable'}
                                          </DropdownItem>
                                          <DropdownItem onClick={() => handleDelete(data)}>
                                            <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                            {data?.deleted ? 'Undelete' : 'Delete'}
                                          </DropdownItem>

                                          <Divider className="my-2" />

                                          <Link
                                            to={buildURL(SMKLinks.EDIT_SCHOOL_GROUPS, {
                                              id: data?.id,
                                            })}>
                                            <DropdownItem>
                                              <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                              Edit
                                            </DropdownItem>
                                          </Link>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                );
                              })
                          ) : (
                            <TableError />
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
              <Paginate pageInfo={schoolGroupsPagination} onChangePage={handleChangePage} />
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </>
  );
};

export default SchoolGroupsList;
