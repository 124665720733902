// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { userSelector } from '../../common/globalComponents/selectors';
import Loader from '../../components/Loader';
import Paginate from '../../components/Paginate';
import useRequest from '../../hooks/useRequest';
import { buildURL } from '../../utils/helper';
import { SMKLinks } from '../../utils/links';

const stateSelector = createStructuredSelector({
  user: userSelector,
});

const RolesList = (props) => {
  const { user } = useSelector(stateSelector);

  const [roles, setRoles] = useState([]);
  const [pagination, setPagination] = useState({});

  const pageOptions = {
    sizePerPage: 10,
    totalSize: 50, // replace later with size(users),
    custom: true,
  };

  const [filters, setFilters] = useState({
    actionTaken: -1,
  });
  const [sort, setSort] = useState({
    sortBy: 'id',
    sortOrder: -1,
  });

  const [getRolesRequest, { loading: getRolesLoading }] = useRequest({
    url: `/user/admin/roles/search`,
    method: 'POST',
    onSuccess: (data) => {
      console.log(data);
      setPagination(data?.pagination);
      setRoles(data?.results);
    },
  });

  useEffect(() => {
    getRolesRequest({ data: { page: 1, size: 20, ...filters, ...sort } });
  }, []);

  const handleChangePage = (page) => {
    getRolesRequest({ data: { page, size: 20, ...filters, ...sort } });
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Loader isActive={getRolesLoading} />

          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Roles" breadcrumbItem="Roles List" />

          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{pagination?.totalCount} Roles Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                {user.entityPermissions?.user?.admin && (
                  <Link
                    to="/roles/add"
                    replace
                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2">
                    <i className="mdi mdi-plus me-1" />
                    Add Role
                  </Link>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <Col lg="12">
                <div className="">
                  <div className="table-responsive" style={{ minHeight: 300 }}>
                    <Table className="project-list-table table-nowrap table-centered table-borderless">
                      <thead>
                        <tr className="table-warning">
                          <th scope="col" style={{ width: '100px' }}>
                            #Id
                          </th>
                          <th scope="col">Name / Email</th>
                          <th scope="col">Permissions</th>
                          <th scope="col" />
                        </tr>
                      </thead>
                      <tbody>
                        {!isEmpty(roles) ? (
                          roles?.map((data, index) => (
                            <tr key={index}>
                              <td>{data.id}</td>
                              <td>
                                <p className="mb-0 font-weight-medium">
                                  <Link to={`/roles/${data.id}`}>{data?.name}</Link>
                                </p>
                              </td>
                              <td>Permissions</td>

                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop" tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem
                                      href={buildURL(SMKLinks.USER_PROFILE, {
                                        id: data.id,
                                      })}>
                                      <i className="fas fa-eye me-3 " />
                                      View
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <TableError />
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>

          <Paginate pageInfo={pagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default RolesList;
