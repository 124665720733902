import { Divider } from '@material-ui/core';
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { buildURL, checkIfValueExist } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import {
  ACTIVE_AND_DELETED_ARRAY,
  LANGUAGE_VALUES,
  PRINT_LANGUAGE,
  PRINT_LANGUAGE_COLOR,
} from '../../../utils/constants';

const BabynameSeoMetaDataList = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [babyNameSeoMetaDataList, setbabyNameSeoMetaDataList] = useState([]);
  const [babyNameSeoMetaDataPagination, setbabyNameSeoMetaDataPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    name: '',
    url: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getbabyNameSeoMetaRequest, { loading: getbabyNameSeoMetaLoading }] = useRequest({
    url: `/baby-name/admin/seoMetaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      setbabyNameSeoMetaDataList(data?.results);
      setbabyNameSeoMetaDataPagination(data?.paginator);
    },
  });
  const [updateBabyNameRequest, { loading: updateBabyNameLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success('Success! Baby Name Pronunciation has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);
    const data = {
      page,
      size: 1000,
      ...filters,
      active: checkIfValueExist(filters.active?.value),
      deleted: checkIfValueExist(filters.deleted?.value),
      name: checkIfValueExist(filters.name),
      url: checkIfValueExist(filters.url),
      sortBy: 'name',
      sortOrder: 0,
    };

    getbabyNameSeoMetaRequest({
      body: {
        ...data,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getbabyNameSeoMetaRequest({
      body: {
        page: 1,
        size: 100,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        url: checkIfValueExist(defaultFilters.url),
        sortBy: 'name',
        sortOrder: 0,
      },
    });
  };

  const handleActive = (data) => {
    updateBabyNameRequest({
      url: `/baby-name/admin/seoMetaInfo/${data?.id}`,
      body: {
        active: data?.active ? false : true,
      },
    });
  };
  const handleDelete = (data) => {
    updateBabyNameRequest({
      url: `/baby-name/admin/seoMetaInfo/${data?.id}`,
      body: {
        deleted: data?.deleted ? false : true,
      },
    });
  };
  useEffect(() => {
    getbabyNameSeoMetaRequest({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'name',
        sortOrder: 0,
      },
    });
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <div className="page-content">
      <Loader isActive={getbabyNameSeoMetaLoading || updateBabyNameLoading} />
      <Container fluid>
        <Breadcrumbs title="Babynames" breadcrumbItem="Baby Names SEO Meta Data List" />
        <Card>
          <CardBody>
            <div className="mt-4">
              <div className="row">
                <div className="col-lg-2">
                  <label className="form-label">Active</label>
                  <Select
                    options={ACTIVE_AND_DELETED_ARRAY}
                    value={filters.active}
                    onChange={(value) => {
                      setFilters({ ...filters, active: value });
                    }}
                  />
                </div>
                <div className="col-lg-2">
                  <label className="form-label">Deleted</label>
                  <Select
                    options={ACTIVE_AND_DELETED_ARRAY}
                    value={filters.deleted}
                    onChange={(value) => setFilters({ ...filters, deleted: value })}
                  />
                </div>

                <div className="col-lg-3">
                  <label className="form-label">Name</label>
                  <Input
                    type="text"
                    name="name"
                    placeholder="name..."
                    value={filters.name}
                    onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                  />
                </div>
                <div className="col-lg-3">
                  <label className="form-label">URL</label>
                  <Input
                    type="text"
                    name="url"
                    placeholder="URL..."
                    value={filters.url}
                    onChange={(e) => setFilters({ ...filters, url: e.target.value })}
                  />
                </div>
              </div>
            </div>

            <div className="mt-4">
              <a className="btn btn-primary me-4" onClick={applyFilters}>
                Apply Filters
              </a>
              <a onClick={clearFilters}>Clear</a>
            </div>
          </CardBody>
        </Card>

        <Row className="mb-2 align-items-center">
          <Col sm="4">
            <div className="search-box me-2 mb-2">
              <div className="position-relative font-bold">
                {babyNameSeoMetaDataPagination?.totalCount} SEO Meta Data Found
              </div>
            </div>
          </Col>
          <Col sm="8">
            <div className="text-sm-end">
              <Link
                replace
                className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                to={SMKLinks.ADD_BABY_NAME_SEO_META_DATA}>
                <i className="mdi mdi-plus me-1" />
                Add SEO Meta Data
              </Link>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className="">
              <div className="table-responsive" style={{ minHeight: 300 }}>
                <Table className="project-list-table table-centered table-borderless ">
                  <thead>
                    <tr className="table-warning">
                      <th scope="col">#ID</th>
                      <th scope="col">Name</th>
                      <th scope="col">Priority</th> <th scope="col">URL</th>
                      {/* <th scope="col">Meta Title</th> */}
                      {/* <th scope="col">Meta Description</th> */}
                      <th scope="col">Language</th>
                      <th scope="col">Active</th>
                      <th scope="col">Deleted</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(babyNameSeoMetaDataList) ? (
                      babyNameSeoMetaDataList?.map((data, index) => (
                        <tr key={index}>
                          <td>{data?.id}</td>
                          <td>
                            <Link
                              to={buildURL(SMKLinks.EDIT_BABY_NAME_SEO_META_DATA, {
                                id: data?.id,
                              })}>
                              {data?.name}
                            </Link>
                          </td>
                          <td>{data?.priority}</td> <td>{data?.url}</td>
                          <td>
                            <Badge className={PRINT_LANGUAGE_COLOR[data?.language]}>
                              {PRINT_LANGUAGE[data?.language]}
                            </Badge>
                          </td>
                          <td>
                            <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                              {data?.active ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                          <td>
                            <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                              {data?.deleted ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                          <td>
                            <UncontrolledDropdown>
                              <DropdownToggle href="#" className="card-drop " tag="i">
                                <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                              </DropdownToggle>
                              <DropdownMenu className="dropdown-menu-end">
                                <DropdownItem onClick={() => handleActive(data)}>
                                  <i
                                    className={`fas  ${
                                      data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                    }   m-1 pe-3`}
                                  />
                                  {data?.active ? 'Disable' : 'Enable'}
                                </DropdownItem>
                                <DropdownItem onClick={() => handleDelete(data)}>
                                  <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                  {data?.deleted ? 'Undelete' : 'Delete'}
                                </DropdownItem>
                                <Divider className="my-2" />

                                <Link
                                  to={buildURL(SMKLinks.EDIT_BABY_NAME_SEO_META_DATA, {
                                    id: data?.id,
                                  })}>
                                  <DropdownItem>
                                    <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                    Edit
                                  </DropdownItem>
                                </Link>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <TableError />
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
        <Paginate pageInfo={babyNameSeoMetaDataPagination} onChangePage={handleChangePage} />
      </Container>
    </div>
  );
};

export default BabynameSeoMetaDataList;
