import React from 'react';
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Row, FormFeedback } from 'reactstrap';
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// actions
import { useHistory } from 'react-router-dom';
import { SMKLinks } from 'utils/links';
import { schoolClearCache } from 'containers/school/schoolDetailsHelper';
import { updateSchoolAdmissionInfo } from '../../actions';
// selectors
import { schoolDetailSelector } from '../../selectors';
// constants
import { AdmissionContactPersonType, EMAIL_REGEX, PHONE_REGEX } from '../../../../utils/constants';
// helpers
import { titleCaseIfExists } from '../../../../utils/helper';
// components
import TextEditor from '../../../../components/TextEditor';
import Loader from '../../../../components/Loader';
import useRequest from '../../../../hooks/useRequest';
import { userSelector } from '../../../../common/globalComponents/selectors';

const stateSelector = createStructuredSelector({
  schoolDetail: schoolDetailSelector,
  user: userSelector,
});

const AdmissionInfo = () => {
  const { user, schoolDetail } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const getInitialValues = () => {
    const { admissionInfo } = schoolDetail;

    const values = {};

    values.academicYear = admissionInfo?.academicYear || '';
    values.admissionPeriod = admissionInfo?.admissionPeriod[0]?.period || '';
    values.contactPerson = !isEmpty(admissionInfo?.contactPerson)
      ? admissionInfo?.contactPerson
      : [
          {
            name: '',
            type: 'Contact Person',
            email: '',
            phone: '',
          },
        ];
    values.admissionDetails = admissionInfo?.admissionDetails || '';
    values.schoolBrochureUrl = admissionInfo?.schoolBrochureUrl || '';
    values.admissionBrochureUrl = admissionInfo?.admissionBrochureUrl || '';
    return values;
  };

  const [updateAdmissionInfoRequest, { loading: updateAdmissionInfoLoading }] = useRequest({
    url: '/school/admin/edit/updateAdmissionInfo',
    method: 'POST',
    onSuccess: (data) => {
      schoolClearCache({
        urlSlug: schoolDetail?.slug,
        schoolId: schoolDetail?.id,
      });

      window.location.reload();
    },
  });

  const formik = useFormik({
    initialValues: getInitialValues(),
    onSubmit(values) {
      updateAdmissionInfoRequest({
        data: {
          schoolId: schoolDetail?.id,
          admissionInfo: {
            ...values,
            // filter out empty rows
            contactPerson: values.contactPerson.filter((cp) => cp.type && (cp.name || cp.email || cp.phone)),
            admissionPeriod: [{ board: 'All', level: 'All', period: values.admissionPeriod }],
          },
        },
      });
    },
    validationSchema: Yup.object().shape({
      academicYear: Yup.string().nullable(),
      admissionPeriod: Yup.string().nullable(),
      contactPerson: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().nullable(),
          type: Yup.string().test('type test', 'Please select type', function(val) {
            // NOTE: type required only if any of the below provided
            // eslint-disable-next-line react/no-this-in-sfc
            if (!(this.name || this.email || this.phone)) {
              return true;
            }
            return !!val;
          }),
          email: Yup.string()
            .nullable()
            .matches(EMAIL_REGEX, 'Please provide valid email'),
          phone: Yup.string()
            .nullable()
            .matches(PHONE_REGEX, 'Please provide valid number'),
        })
      ),
      admissionDetails: Yup.string().nullable(),
    }),
  });

  const handleAddPerson = () => {
    formik.setFieldValue('contactPerson', [
      ...formik.values.contactPerson,
      {
        name: '',
        type: '',
        email: '',
        phone: '',
      },
    ]);
  };

  const handleRemovePerson = (index) => {
    const array = formik.values.contactPerson.slice();
    array.splice(index, 1);
    formik.setFieldValue('contactPerson', array);
  };

  const handleUpdatePerson = (index, key, value) => {
    const array = formik.values.contactPerson.slice();
    array[index] = { ...array[index], [key]: value };
    formik.setFieldValue('contactPerson', array);
  };

  const renderContactPersons = () => {
    const types = Object.entries(AdmissionContactPersonType).map(([k, v]) => ({
      value: v,
      label: titleCaseIfExists(k),
    }));

    return (
      <>
        <CardTitle>Admission Contact Person</CardTitle>

        {formik.values.contactPerson.map((person, i) => (
          <Row key={`person_${i}`}>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="person_type" className="form-label  ">
                  Type
                </Label>
                <Select
                  id="person_type"
                  options={types}
                  value={types.find((t) => t.value === person.type)}
                  onChange={({ value }) => handleUpdatePerson(i, 'type', value)}
                />
                {!isEmpty(formik.errors.contactPerson) && (
                  <div className="invalid-feedback d-block">{formik.errors.contactPerson[i]?.type}</div>
                )}
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="person_name" className="form-label  ">
                  Name
                </Label>
                <Input
                  id="person_name"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder="Name..."
                  value={person.name}
                  onChange={(e) => handleUpdatePerson(i, 'name', e.target.value)}
                />
                {!isEmpty(formik.errors.contactPerson) && (
                  <div className="invalid-feedback d-block">{formik.errors.contactPerson[i]?.name}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup className="mb-4">
                <Label htmlFor="person_phone" className="form-label  ">
                  Phone
                </Label>

                <PhoneInput
                  country="in"
                  isValid={isEmpty(formik.errors.contactPerson) || isEmpty(formik.errors.contactPerson[i]?.phone)}
                  enableSearch
                  preferredCountries={['in', 'us', 'sg', 'uk']}
                  value={person.phone}
                  onChange={(value) => handleUpdatePerson(i, 'phone', value)}
                  countryCodeEditable
                />
                {!isEmpty(formik.errors.contactPerson) && (
                  <div className="invalid-feedback d-block">{formik.errors.contactPerson[i]?.phone}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="person_email" className="form-label  ">
                  Email
                </Label>
                <Input
                  id="person_email"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder="Email..."
                  value={person.email}
                  onChange={(e) => handleUpdatePerson(i, 'email', e.target.value)}
                />
                {!isEmpty(formik.errors.contactPerson) && (
                  <div className="invalid-feedback d-block">{formik.errors.contactPerson[i]?.email}</div>
                )}
              </FormGroup>
            </Col>
            <Col sm={1}>
              <FormGroup className="mb-4">
                <Button color="danger" className="mt-4" onClick={() => handleRemovePerson(i)}>
                  <i className="bx bx-trash" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        ))}

        <Row>
          <Col>
            <Button className="mt-3 w-100" color="light" onClick={handleAddPerson}>
              <i className="bx bx-plus me-2" />
              Add Person
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateAdmissionInfoLoading} />
      <h5 className="mb-4">Admission Info</h5>
      <Card>
        <CardBody>
          <CardTitle>Academic Year</CardTitle>

          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="school_acad_year" className="form-label  ">
                  Academic Year
                </Label>
                <Input
                  id="school_acad_year"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder=""
                  invalid={!!(formik.touched.academicYear && formik.errors.academicYear)}
                  {...formik.getFieldProps('academicYear')}
                />
                <FormFeedback>{formik.errors.academicYear}</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="school_acad_period" className="form-label  ">
                  Admission Period
                </Label>
                <Input
                  id="school_acad_period"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder=""
                  invalid={!!(formik.touched.admissionPeriod && formik.errors.admissionPeriod)}
                  {...formik.getFieldProps('admissionPeriod')}
                />
                <FormFeedback>{formik.errors.admissionPeriod}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <hr className="mb-4" />

          {renderContactPersons()}
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Col md={12}>
            <FormGroup className="mb-4">
              <Label for="schoolBrochureUrl" className="form-label  ">
                School Brochure URL
              </Label>
              <Input
                id="schoolBrochureUrl"
                type="text"
                className="form-control"
                rows="3"
                placeholder="URL..."
                invalid={!!(formik.touched.schoolBrochureUrl && formik.errors.schoolBrochureUrl)}
                {...formik.getFieldProps('schoolBrochureUrl')}
              />
              <FormFeedback>{formik.errors.schoolBrochureUrl}</FormFeedback>
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup className="mb-4">
              <Label for="admissionBrochureUrl" className="form-label  ">
                Admission Brochure URL
              </Label>
              <Input
                id="admissionBrochureUrl"
                type="text"
                className="form-control"
                rows="3"
                placeholder="URL..."
                invalid={!!(formik.touched.admissionBrochureUrl && formik.errors.admissionBrochureUrl)}
                {...formik.getFieldProps('admissionBrochureUrl')}
              />
              <FormFeedback>{formik.errors.admissionBrochureUrl}</FormFeedback>
            </FormGroup>
          </Col>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="schooldesc" className="form-label ">
              School Admission Details
            </Label>
            <TextEditor
              simple
              initialValue={formik.values.admissionDetails}
              onChange={(val) => formik.setFieldValue('admissionDetails', val)}
            />
          </FormGroup>
        </CardBody>
      </Card>
      {(user?.entityPermissions?.school.admin ||
        (schoolDetail.editable &&
          (schoolDetail?.createdBy?.id === user.id || schoolDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Information
          </Button>
          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.SCHOOL_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

AdmissionInfo.propTypes = {};

export default AdmissionInfo;
