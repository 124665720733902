import { Breadcrumbs } from '@material-ui/core';
import AuthService from 'common/auth.service';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import Loader from '../../components/Loader';
import ToggleButton from '../../components/ToggleButton';
import useRequest from '../../hooks/useRequest';
import { useRouter } from '../../hooks/useRouter';
import {
  PermissionEntityEnum_MEDIWATCHER,
  PermissionEntityEnum_SMK,
  PermissionEntityEnum_TPZ,
} from '../../utils/constants';
import { titleCaseIfExists } from '../../utils/helper';

// actions

const AddRole = () => {
  const router = useRouter();
  toast.configure();

  const auth = new AuthService();
  const source = auth.getAppSourceCookie();
  const permission_enum =
    source === 'schoolmykids'
      ? PermissionEntityEnum_SMK
      : source === 'theparentz'
      ? PermissionEntityEnum_TPZ
      : PermissionEntityEnum_MEDIWATCHER;

  const entityTypes = Object.entries(permission_enum).map(([k, v]) => ({
    value: v,
    label: titleCaseIfExists(k)?.replace('_', ' '),
  }));

  const [role, setRole] = useState(null);

  const [addRoleRequest, { loading: addRoleLoading }] = useRequest({
    url: '/user/admin/roles',
    method: 'POST',
    onSuccess: (data) => {
      window.location = '/roles/list';
      toast.success('Success! Role has been Added.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [updateRoleRequest, { loading: updateRoleLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      window.location = '/roles/list';
      toast.success('Success! Role has been Updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: role || {
      name: '',
      permissions: entityTypes?.map((e) => {
        return {
          entity: e?.value,
          admin: false,
          import: false,
          export: false,
          reporting: false,
          publish: false,
          create: false,
          read: false,
          update: false,
          delete: false,
        };
      }),
    },
    onSubmit(values) {
      let newValues = { ...values };
      if (role) {
        updateRoleRequest({ url: `/user/admin/roles/${role?.id}`, data: newValues });
      } else {
        addRoleRequest({ data: newValues });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is requried'),
      permissions: Yup.array().of(
        Yup.object().shape({
          entity: Yup.string().required('Entity type is required'),
        })
      ),
    }),
  });

  const [getRoleRequest, { loading: getRoleLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setRole(data);
    },
  });

  useEffect(() => {
    if (router?.query?.id) {
      getRoleRequest({ url: `/user/admin/roles/${router?.query?.id}` });
    }
  }, []);

  const handleUpdatePermission = (entity, key, value) => {
    const array = formik.values.permissions.slice();

    let permission = array.find((p) => p.entity === entity.value);
    permission = { ...permission, [key]: value };

    formik.setFieldValue('permissions', [...array.filter((e) => e.entity !== entity.value), permission]);
  };

  const renderPermissions = () => {
    return (
      <>
        <CardTitle>Permissions</CardTitle>

        <table className="table table-responsive table-hover table-white">
          <tbody>
            <tr>
              <th>Entity</th>
              <th />
              <th>Create</th>
              <th>Read</th>
              <th>Update</th>
              <th>Delete</th>
              <th>Admin</th>
              <th>Import</th>
              <th>Export</th>
              <th>Reporting</th>
              <th>Publish</th>
            </tr>
            {entityTypes.map((entity, i) => {
              const permission = formik.values.permissions?.find((p) => p.entity === entity.value);

              return (
                <tr key={`permission_${i}`}>
                  <td>{entity.label}</td>
                  <td>
                    <ToggleButton
                      onChange={(e) => {
                        const array = formik.values.permissions.slice();

                        const permission = {
                          entity: entity.value,

                          admin: e.target.checked,
                          import: e.target.checked,
                          export: e.target.checked,
                          reporting: e.target.checked,
                          publish: e.target.checked,

                          create: e.target.checked,
                          read: e.target.checked,
                          update: e.target.checked,
                          delete: e.target.checked,
                        };

                        formik.setFieldValue('permissions', [
                          ...array.filter((e) => e.entity !== entity.value),
                          permission,
                        ]);
                      }}
                    />
                  </td>

                  <td>
                    <ToggleButton
                      onChange={(e) => handleUpdatePermission(entity, 'create', e.target.checked)}
                      checked={permission?.create}
                    />
                  </td>
                  <td>
                    <ToggleButton
                      onChange={(e) => handleUpdatePermission(entity, 'read', e.target.checked)}
                      checked={permission?.read}
                    />
                  </td>
                  <td>
                    <ToggleButton
                      onChange={(e) => handleUpdatePermission(entity, 'update', e.target.checked)}
                      checked={permission?.update}
                    />
                  </td>
                  <td>
                    <ToggleButton
                      onChange={(e) => handleUpdatePermission(entity, 'delete', e.target.checked)}
                      checked={permission?.delete}
                    />
                  </td>

                  <td>
                    <ToggleButton
                      onChange={(e) => handleUpdatePermission(entity, 'admin', e.target.checked)}
                      checked={permission?.admin}
                    />
                  </td>
                  <td>
                    <ToggleButton
                      onChange={(e) => handleUpdatePermission(entity, 'import', e.target.checked)}
                      checked={permission?.import}
                    />
                  </td>
                  <td>
                    <ToggleButton
                      onChange={(e) => handleUpdatePermission(entity, 'export', e.target.checked)}
                      checked={permission?.export}
                    />
                  </td>
                  <td>
                    <ToggleButton
                      onChange={(e) => handleUpdatePermission(entity, 'reporting', e.target.checked)}
                      checked={permission?.reporting}
                    />
                  </td>
                  <td>
                    <ToggleButton
                      onChange={(e) => handleUpdatePermission(entity, 'publish', e.target.checked)}
                      checked={permission?.publish}
                    />
                  </td>
                  {/* <td>
                  <FormGroup className="mb-0 mt-2">
                    <Button color="danger" className="btn-sm" onClick={() => handleRemovePermission(i)}>
                      <i className="bx bx-trash" />
                    </Button>
                  </FormGroup>
                </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>

        {/* <Row className="m-2">
          <Button  className="bg-light" onClick={handleAddPermission}>
            Add Role
          </Button>
        </Row> */}
      </>
    );
  };

  return (
    <div className="page-content">
      <Loader isActive={getRoleLoading || addRoleLoading || updateRoleLoading} />
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Roles" breadcrumbItem="Add Role" />

        <Form onSubmit={formik.handleSubmit}>
          <h5 className="mb-4">{role ? 'Update' : 'New'} Role</h5>

          <Card>
            <CardBody>
              <Row>
                <Col md={12}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="name" className="form-label  ">
                      Role Name
                    </Label>
                    <Input
                      id="name"
                      type="text"
                      className="form-control"
                      rows="3"
                      placeholder=""
                      invalid={!!(formik.touched.name && formik.errors.name)}
                      {...formik.getFieldProps('name')}
                    />
                    <FormFeedback>{formik.errors.name}</FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>{renderPermissions()}</CardBody>
          </Card>

          <Row>
            <Col lg={12} className="mt-2 mb-5">
              <Button type="submit" color="primary">
                Save Role
              </Button>
              <Button type="reset" color="light" className="ms-3" onClick={formik.resetForm}>
                Clear
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

AddRole.propTypes = {};

export default AddRole;
