import edjsHTML from 'editorjs-html';

const getAlignmentClass = (alignment) => {
  switch (alignment) {
    case 'center':
      return 'text-center';
    case 'left':
      return 'text-start';
    case 'right':
      return 'text-end';
    default:
      return '';
  }
};

const handleColumns = (block, edjsParser) => {
  const colCount = block.data.cols.length;
  let colClass = 'col';

  switch (colCount) {
    case 2: {
      colClass = 'col-md-6';
      break;
    }
    case 3: {
      colClass = 'col-md-4';
      break;
    }
    case 4: {
      colClass = 'col-md-3';
      break;
    }
    default:
      colClass = 'col-md-12';
  }

  const alignmentClass = getAlignmentClass(block?.tunes?.anyTuneName?.alignment);

  const columnHtml = block.data.cols.map((col) => {
    const colBlocksHtml = col.blocks.map((colBlock) => handleBlock(colBlock, edjsParser));
    return `<div class="${colClass} ${alignmentClass}">${colBlocksHtml.join('')}</div>`;
  });

  return `<div class="row columns">${columnHtml.join('')}</div>`;
};

const handleTable = (block) => {
  const alignmentClass = getAlignmentClass(block?.tunes?.anyTuneName?.alignment);

  const rowsHtml = block.data.content
    .map((row) => {
      const cellsHtml = row.map((cell) => `<td>${cell}</td>`).join('');
      return `<tr>${cellsHtml}</tr>`;
    })
    .join('');

  return (
    '<div class="table-responsive"><table class="table table-bordered ' +
    alignmentClass +
    '">' +
    rowsHtml +
    '</table></div>'
  );
};

const handleCustomBlock = (block) => {
  if (block?.type === 'customBlock' && block?.data?.url) {
    const alignmentClass = getAlignmentClass(block?.tunes?.anyTuneName?.alignment);

    return (
      '<div class="custom-image-block mb-4 ' +
      alignmentClass +
      '">' +
      '<img src="' +
      block.data.url +
      '" alt="' +
      block.data.name +
      '" />' +
      '</div>'
    );
  }
  return '';
};
const handleRawHtml = (block) => {
  if (block?.type === 'raw' && block?.data?.html) {
    return `<div class="custom-raw-html">${block?.data?.html}</div>`;
  }
  return '';
};

const handleList = (block) => {
  const isOrdered = block?.data?.style === 'ordered';
  const listTag = isOrdered ? 'ol' : 'ul';

  return `<${listTag}>${block?.data?.items
    ?.map((item) => {
      // Check if the item has nested items
      const nestedList =
        item?.items?.length > 0 ? handleList({ data: { items: item.items, style: block.data.style } }) : '';
      return `<li>${item?.content}${nestedList}</li>`;
    })
    .join('')}</${listTag}>`;
};

const handleBlock = (block, edjsParser) => {
  switch (block.type) {
    case 'columns':
      return handleColumns(block, edjsParser);
    case 'table':
      return handleTable(block);
    case 'customBlock':
      return handleCustomBlock(block);
    case 'header':
      return handleHeader(block);
    case 'raw':
      return handleRawHtml(block);
    case 'list':
      return handleList(block);
    default:
      // eslint-disable-next-line no-case-declarations
      const alignmentClass = getAlignmentClass(block?.tunes?.anyTuneName?.alignment);
      return `<div class="${alignmentClass}">${edjsParser.parse({ blocks: [block] }).join('')}</div>`;
  }
};

const handleHeader = (block) => {
  const level = block.data?.level;
  const alignmentClass = getAlignmentClass(block?.tunes?.anyTuneName?.alignment);
  if (level === 2 || level === 3) {
    const text = block.data.text;
    const id = text?.replace(/\W/g, '');

    return `<h${level} id="${id}" class="${alignmentClass}">${text}</h${level}>`;
  }
  return '<h' + level + ' class="' + alignmentClass + '">' + block.data.text + '</h' + level + '>';
};

const processBlocksRecursively = (blocks, edjsParser) => {
  return blocks.map((block) => handleBlock(block, edjsParser)).join('');
};

export const convertEditorBlocksToHTML = (data) => {
  const edjsParser = edjsHTML();

  const combinedHtml = processBlocksRecursively(data?.blocks || [], edjsParser);

  return `<div class="content-wrapper">${combinedHtml}</div>`;
};
