import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  getForumPost,
  getForumTopic,
  setForumPostApprove,
  setForumTopicApprove,
  getForumTopicSearch,
  getForumSubCategories,
  getForumCategories,
  setForumTopicDeleted,
  setForumPostDeleted,
  getForumTopicDetail,
  setForumPostUnPublish,
  setForumTopicUnPublish,
  getForumPostDetail,
  getForumTopicUpdate,
  getForumPostUpdate,
} from './actions';
import { api } from '../../utils/axios';
import config from '../../config/config';
import { setGlobalError } from '../../common/globalComponents/actions';
import { SMKLinks } from '../../utils/links';
import { buildURL } from '../../utils/helper';

export const GET_FORUM_TOPIC_API_URL = `${config.apiEndpoint}/forum/admin/topic/search`;

export const GET_FORUM_TOPIC_POST_API_URL = `${config.apiEndpoint}/forum/admin/post/search`;

// export const GET_FORUM_TOPIC_SUBCATEGORIES_API_URL = `${config.apiEndpoint}/forum-category/search`;
export const GET_FORUM_TOPIC_CATEGORIES_API_URL = `${config.apiEndpoint}/forum/admin/forum-category/search`;
export const GET_FORUM_TOPIC_SUBCATEGORIES_API_URL = `${config.apiEndpoint}/forum/admin/forum-category/search`;

export const SET_FORUM_TOPIC_POST_APPROVE_API_URL = `${config.apiEndpoint}/forum/admin/post/approve`;

export const SET_FORUM_TOPIC_POST_DELETE_API_URL = `${config.apiEndpoint}/forum/admin/post/delete`;

export const SET_FORUM_TOPIC_POST_UNPUBLISH_API_URL = `${config.apiEndpoint}/forum/admin/post/unpublish`;

export const SET_FORUM_TOPIC_UNPUBLISH_API_URL = `${config.apiEndpoint}/forum/admin/topic/unpublish`;

export const SET_FORUM_TOPIC_DELETE_API_URL = `${config.apiEndpoint}/forum/admin/topic/delete`;

export const SET_FORUM_TOPIC_APPROVE_API_URL = `${config.apiEndpoint}/forum/admin/topic/approve`;

export const GET_FORUM_TOPIC_UPDATE_API_URL = `${config.apiEndpoint}/forum/admin/topic/update`;

export const GET_FORUM_POST_UPDATE_API_URL = `${config.apiEndpoint}/forum/admin/post/update`;

const getForumTopicListingCall = (filters) =>
  api({
    method: 'POST',
    url: GET_FORUM_TOPIC_API_URL,
    data: filters,
  });

const getForumTopicUpdateCall = (data) =>
  api({
    method: 'POST',
    url: GET_FORUM_TOPIC_UPDATE_API_URL,
    data,
  });

const getForumPostUpdateCall = (data) =>
  api({
    method: 'POST',
    url: GET_FORUM_POST_UPDATE_API_URL,
    data,
  });

const getForumCategoriesListingCall = (filters) =>
  api({
    method: 'POST',
    url: GET_FORUM_TOPIC_CATEGORIES_API_URL,
    data: filters,
  });
const getForumSubCategoriesListingCall = (filters) =>
  api({
    method: 'POST',
    url: GET_FORUM_TOPIC_SUBCATEGORIES_API_URL,
    data: filters,
  });

const getForumTopicPostListingCall = (filters) =>
  api({
    method: 'POST',
    url: GET_FORUM_TOPIC_POST_API_URL,
    data: filters,
  });

const setForumTopicApproveCall = (data) =>
  api({
    method: 'PUT',
    url: SET_FORUM_TOPIC_APPROVE_API_URL,
    data,
  });

const setForumTopicDeleteCall = (id, deleted, comments) =>
  api({
    method: 'PUT',
    url: SET_FORUM_TOPIC_DELETE_API_URL,
    data: { id, deleted, comments },
  });

const setForumTopicUnpublishCall = (id, comments) =>
  api({
    method: 'PUT',
    url: SET_FORUM_TOPIC_UNPUBLISH_API_URL,
    data: { id, comments },
  });

const setForumTopicPostUnpublishCall = (id, comments) =>
  api({
    method: 'PUT',
    url: SET_FORUM_TOPIC_POST_UNPUBLISH_API_URL,
    data: { id, comments },
  });

const setForumTopicPostApproveCall = (data) =>
  api({
    method: 'PUT',
    url: SET_FORUM_TOPIC_POST_APPROVE_API_URL,
    data,
  });

const setForumPostDeleteCall = (id, deleted, comments) =>
  api({
    method: 'PUT',
    url: SET_FORUM_TOPIC_POST_DELETE_API_URL,
    data: { id, deleted, comments },
  });

function* getForumTopicRequest(action) {
  try {
    yield put(getForumTopic.request({ isLoading: true }));
    const response = yield call(getForumTopicListingCall, action.payload);
    if (response) {
      yield put(getForumTopic.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getForumTopic.failure(error));
  } finally {
    yield put(getForumTopic.fulfill({ isLoading: false }));
  }
}

function* getForumTopicSearchRequest(action) {
  try {
    yield put(getForumTopicSearch.request({ isLoading: true }));
    const response = yield call(getForumTopicListingCall, action.payload);
    if (response) {
      yield put(getForumTopicSearch.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getForumTopicSearch.failure(error));
  } finally {
    yield put(getForumTopicSearch.fulfill({ isLoading: false }));
  }
}

function* getForumTopicDetailsRequest(action) {
  try {
    yield put(getForumTopicDetail.request({ isLoading: true }));
    const response = yield call(getForumTopicListingCall, action.payload);
    if (response) {
      yield put(getForumTopicDetail.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getForumTopicDetail.failure(error));
  } finally {
    yield put(getForumTopicDetail.fulfill({ isLoading: false }));
  }
}

function* getForumTopicUpdateRequest(action) {
  try {
    yield put(getForumTopicUpdate.request({ isLoading: true }));
    const { topicObj, queryParams } = action.payload;
    const response = yield call(getForumTopicUpdateCall, { ...topicObj });
    if (response) {
      yield put(getForumTopicUpdate.success({ ...response }));
      if (queryParams && queryParams.reurl === 'approval') {
        yield put(push(buildURL(SMKLinks.TOPIC_APPROVE)));
      } else {
        yield put(push(buildURL(SMKLinks.TOPIC_DETAIL, { id: response.data.topic.id })));
      }
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getForumTopicUpdate.failure(error));
  } finally {
    yield put(getForumTopicUpdate.fulfill({ isLoading: false }));
  }
}

function* getForumPostUpdateRequest(action) {
  try {
    yield put(getForumPostUpdate.request({ isLoading: true }));
    const { postObj, queryParams } = action.payload;
    const response = yield call(getForumPostUpdateCall, { ...postObj });
    if (response) {
      yield put(getForumPostUpdate.success({ ...response }));
      if (queryParams && queryParams.reurl === 'approval') {
        yield put(push(buildURL(SMKLinks.POST_APPROVE)));
      } else {
        yield put(push(buildURL(SMKLinks.POST_DETAIL, { id: response.data.post.id })));
      }
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getForumPostUpdate.failure(error));
  } finally {
    yield put(getForumPostUpdate.fulfill({ isLoading: false }));
  }
}

function* getForumPostRequest(action) {
  try {
    yield put(getForumPost.request({ isLoading: true }));
    const response = yield call(getForumTopicPostListingCall, action.payload);
    if (response) {
      yield put(getForumPost.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getForumPost.failure(error));
  } finally {
    yield put(getForumPost.fulfill({ isLoading: false }));
  }
}

function* getForumPostDetailRequest(action) {
  try {
    yield put(getForumPostDetail.request({ isLoading: true }));
    const response = yield call(getForumTopicPostListingCall, action.payload);
    yield put(getForumPostDetail.success({ ...response }));
  } catch (error) {
    yield put(getForumPostDetail.failure(error));
  } finally {
    yield put(getForumPostDetail.fulfill({ isLoading: false }));
  }
}

function* getForumCategoriesRequest(action) {
  try {
    yield put(getForumCategories.request({ isLoading: true }));
    const response = yield call(getForumCategoriesListingCall, action.payload);
    yield put(getForumCategories.success({ ...response }));
  } catch (error) {
    yield put(getForumCategories.failure(error));
  } finally {
    yield put(getForumCategories.fulfill({ isLoading: false }));
  }
}
function* getForumSubCategoriesRequest(action) {
  try {
    yield put(getForumSubCategories.request({ isLoading: true }));
    const response = yield call(getForumSubCategoriesListingCall, action.payload);
    yield put(getForumSubCategories.success({ ...response }));
  } catch (error) {
    yield put(getForumSubCategories.failure(error));
  } finally {
    yield put(getForumSubCategories.fulfill({ isLoading: false }));
  }
}

function* setForumPostApproveRequest(action) {
  try {
    yield put(setForumPostApprove.request({ isLoading: true }));
    const {
      id,
      status,
      withReAssignment,
      removeExternalLinks,
      reAssignmentGender,
      detailPage,
      comments,
    } = action.payload;
    const response = yield call(setForumTopicPostApproveCall, {
      id,
      status,
      withReAssignment,
      removeExternalLinks,
      reAssignmentGender,
      comments,
    });
    yield put(setForumPostApprove.success({ ...response, detailPage }));
  } catch (error) {
    yield put(setForumPostApprove.failure(error));
  } finally {
    yield put(setForumPostApprove.fulfill({ isLoading: false }));
  }
}

function* setForumTopicUnPublishedRequest(action) {
  try {
    yield put(setForumTopicUnPublish.request({ isLoading: true }));
    const { id, comments } = action.payload;
    const response = yield call(setForumTopicUnpublishCall, id, comments);
    yield put(setForumTopicUnPublish.success({ ...response }));
  } catch (error) {
    yield put(setForumTopicUnPublish.failure(error));
  } finally {
    yield put(setForumTopicUnPublish.fulfill({ isLoading: false }));
  }
}

function* setForumPostUnPublishedRequest(action) {
  try {
    yield put(setForumPostUnPublish.request({ isLoading: true }));
    const { id, comments } = action.payload;
    const response = yield call(setForumTopicPostUnpublishCall, id, comments);
    yield put(setForumPostUnPublish.success({ ...response }));
  } catch (error) {
    yield put(setForumPostUnPublish.failure(error));
  } finally {
    yield put(setForumPostUnPublish.fulfill({ isLoading: false }));
  }
}

function* setForumPostDeleteRequest(action) {
  try {
    yield put(setForumPostDeleted.request({ isLoading: true }));
    const { id, deleted, comments, detailPage } = action.payload;
    const response = yield call(setForumPostDeleteCall, id, deleted, comments);
    yield put(setForumPostDeleted.success({ ...response, detailPage }));
  } catch (error) {
    yield put(setForumPostDeleted.failure(error));
  } finally {
    yield put(setForumPostDeleted.fulfill({ isLoading: false }));
  }
}

function* setForumTopicApproveRequest(action) {
  try {
    yield put(setForumTopicApprove.request({ isLoading: true }));
    const {
      id,
      status,
      withReAssignment,
      removeExternalLinks,
      reAssignmentGender,
      detailPage,
      comments,
    } = action.payload;
    const response = yield call(setForumTopicApproveCall, {
      id,
      status,
      withReAssignment,
      removeExternalLinks,
      reAssignmentGender,
      comments,
    });
    yield put(setForumTopicApprove.success({ ...response, detailPage }));
  } catch (error) {
    yield put(setForumTopicApprove.failure(error));
  } finally {
    yield put(setForumTopicApprove.fulfill({ isLoading: false }));
  }
}

function* setForumTopicDeleteRequest(action) {
  try {
    yield put(setForumTopicDeleted.request({ isLoading: true }));
    const { id, deleted, comments, detailPage } = action.payload;
    const response = yield call(setForumTopicDeleteCall, id, deleted, comments);
    yield put(setForumTopicDeleted.success({ ...response, detailPage }));
  } catch (error) {
    yield put(setForumTopicDeleted.failure(error));
  } finally {
    yield put(setForumTopicDeleted.fulfill({ isLoading: false }));
  }
}

export default function* adminSaga() {
  yield takeLatest(getForumCategories.TRIGGER, getForumCategoriesRequest);
  yield takeLatest(getForumSubCategories.TRIGGER, getForumSubCategoriesRequest);

  yield takeLatest(getForumTopic.TRIGGER, getForumTopicRequest);
  yield takeLatest(getForumTopicSearch.TRIGGER, getForumTopicSearchRequest);
  yield takeLatest(getForumTopicDetail.TRIGGER, getForumTopicDetailsRequest);
  yield takeLatest(getForumTopicUpdate.TRIGGER, getForumTopicUpdateRequest);
  yield takeLatest(setForumTopicApprove.TRIGGER, setForumTopicApproveRequest);
  yield takeLatest(setForumTopicDeleted.TRIGGER, setForumTopicDeleteRequest);
  yield takeLatest(setForumTopicUnPublish.TRIGGER, setForumTopicUnPublishedRequest);

  yield takeLatest(getForumPost.TRIGGER, getForumPostRequest);
  yield takeLatest(getForumPostDetail.TRIGGER, getForumPostDetailRequest);
  yield takeLatest(getForumPostUpdate.TRIGGER, getForumPostUpdateRequest);
  yield takeLatest(setForumPostApprove.TRIGGER, setForumPostApproveRequest);
  yield takeLatest(setForumPostDeleted.TRIGGER, setForumPostDeleteRequest);
  yield takeLatest(setForumPostUnPublish.TRIGGER, setForumPostUnPublishedRequest);
}
