import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import Select from 'react-select';

import { Divider } from '@material-ui/core';
import TableError from 'components/TableError';
import { schoolClearCache } from 'containers/school/schoolDetailsHelper';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { buildURL, checkIfValueExist, printDate } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import {
  ACTIVE_AND_DELETED_ARRAY,
  BabyNameGenderValue,
  SORT_VALUES,
  createdByTypeSelect,
} from '../../../utils/constants';

const BabynameMeaningOriginList = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [babyNamesMeaningOriginList, setbabyNamesMeaningOriginList] = useState([]);
  const [babyNamesMeaningOriginPagination, setbabyNamesMeaningOriginPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    createdByType: { value: undefined, label: 'All' },
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getbabyNamesMeaningOriginRequest, { loading: getbabyNamesMeaningOriginLoading }] = useRequest({
    url: `/baby-name/admin/meaningOrigin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setbabyNamesMeaningOriginList(data?.results);
      setbabyNamesMeaningOriginPagination(data?.paginator);
    },
  });

  const [updateBabyNameRequest, { loading: updateBabyNameLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success('Success! Baby Name Meaning origin has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getbabyNamesMeaningOriginRequest({
      body: {
        page,
        ...SORT_VALUES,
        size: 50,
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        createdBy:
          filters.createdByType?.value !== undefined
            ? {
                type: filters.createdByType?.value,
              }
            : undefined,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getbabyNamesMeaningOriginRequest({
      body: {
        page: 1,
        size: 50,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        createdByType: checkIfValueExist(defaultFilters.createdByType?.value),
        ...SORT_VALUES,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const handleActive = (data) => {
    updateBabyNameRequest({
      url: `/baby-name/admin/meaningOrigin/${data?.id}`,
      body: {
        active: !data?.active,
      },
    });
    schoolClearCache({
      key: 'THEPARENTZ_',
      babyName: data?.babyName?.slug || data?.slug,
      babyNameMeaningOriginCache: true,
      removeBodyParam: true,
    });
  };
  const handleDelete = (data) => {
    updateBabyNameRequest({
      url: `/baby-name/admin/meaningOrigin/${data?.id}`,
      body: {
        deleted: !data?.deleted,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getbabyNamesMeaningOriginLoading || updateBabyNameLoading} />
        <Container fluid>
          <Breadcrumbs title="Babynames" breadcrumbItem="Baby Names Meaning Origin List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label"> Created By Type</label>
                    <Select
                      options={createdByTypeSelect}
                      value={filters.createdByType}
                      onChange={(value) => setFilters({ ...filters, createdByType: value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {babyNamesMeaningOriginPagination?.totalCount} Meaning Origin Data Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_BABY_NAME_MEANING_ORIGIN}>
                  <i className="mdi mdi-plus me-1" />
                  Add Meaning Origin
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless ">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">#ID</th>
                        <th scope="col" style={{ width: '150px' }}>
                          Baby Name{' '}
                        </th>
                        <th scope="col">Gender</th>
                        <th scope="col">Origin</th>
                        <th scope="col">Pronounced</th>
                        {/* <th scope="col">Script</th> */}
                        <th scope="col">Source</th>
                        <th scope="col">Country</th>
                        <th scope="col">CreatedById</th>
                        <th scope="col">Created date</th>
                        <th scope="col">Anonymous</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(babyNamesMeaningOriginList) ? (
                        babyNamesMeaningOriginList?.map((data, index) => (
                          <>
                            <tr key={index}>
                              <td>
                                <Link
                                  to={buildURL(SMKLinks.EDIT_BABY_NAME_MEANING_ORIGIN, {
                                    id: data?.id,
                                  })}>
                                  {data?.id}
                                </Link>
                              </td>
                              <td>
                                {data?.babyName?.name || data?.name}
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`https://www.theparentz.com/baby-names/${data?.babyName?.slug}`}>
                                  <i role="button" className="mdi mdi-open-in-new font-size-18 ms-2" />
                                </a>
                              </td>
                              <td>{BabyNameGenderValue[data?.gender]}</td>
                              <td>{data?.origin?.name}</td>
                              <td>{data?.pronounced}</td>
                              {/* <td>{data?.script}</td> */}
                              <td>{data?.source}</td>
                              <td>{data?.country?.displayName}</td>
                              <td>
                                <a href={`/users/profile/${data?.createdById}`}>{data?.createdById}</a>
                              </td>
                              <td>{printDate(data?.createdDate)}</td>
                              <td>
                                <Badge className={data?.anonymous ? 'bg-success' : 'bg-danger'}>
                                  {data?.anonymous ? 'Yes' : 'No'}
                                </Badge>
                              </td>

                              <td>
                                <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                  {data?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop " tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleActive(data)}>
                                      <i
                                        className={`fas  ${
                                          data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                        }   m-1 pe-3`}
                                      />
                                      {data?.active ? 'Disable' : 'Enable'}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleDelete(data)}>
                                      <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                      {data?.deleted ? 'Undelete' : 'Delete'}
                                    </DropdownItem>
                                    <Divider className="my-2" />

                                    <Link
                                      to={buildURL(SMKLinks.EDIT_BABY_NAME_MEANING_ORIGIN, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                            {!isEmpty(data?.meaning) && (
                              <tr>
                                <td />
                                <td colSpan={10}>
                                  <h6>Meaning :- </h6>
                                  {data?.meaning}
                                </td>
                                <td colSpan={5} />
                              </tr>
                            )}
                          </>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={babyNamesMeaningOriginPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default BabynameMeaningOriginList;
