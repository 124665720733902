import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import config from '../../config/config';
import { api } from 'utils/axios';
import { POST } from 'utils/httpMethods';

function UserAutoComplete({ selectedUser, onSelectUser, isNotClearable }) {
  const [inputValue, setInputValue] = useState('');

  const [filters, setFilters] = useState({
    allowBoth: true,
    autoComplete: true,
    page: 1,
    size: 5,
    sortBy: 'createdDate',
    sortOrder: 'desc',
  });

  // handle input change event
  const handleInputChange = (value) => {
    setInputValue(value);
  };

  // handle selection
  const handleChange = (value) => {
    onSelectUser(value);
  };

  // load options using API call
  const loadOptions = (inputValue) => {
    return api({
      method: POST,
      url: `${config.apiEndpoint}/user/admin/search?t=${new Date().getTime()}`,
      data: {
        ...filters,
        email: inputValue,
      },
    })?.then((data) => {
      return data?.results;
    });
  };

  return (
    <AsyncSelect
      // cacheOptions
      // defaultOptions
      placeholder="Type email..."
      value={selectedUser}
      getOptionLabel={(e) => e.email}
      getOptionValue={(e) => e.id}
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isClearable={isNotClearable ? false : true}
    />
  );
}

UserAutoComplete.propTypes = {};

export default UserAutoComplete;
