import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardTitle } from 'reactstrap';
import useRequest from '../../../hooks/useRequest';
import { isEmpty } from 'lodash';
import { bloggerEmailsList } from '../../../utils/constants';
import { BLOG_STATUS_ENUM } from 'containers/blog/helper';
import { titleCaseIfExists } from 'utils/helper';

function ArticleMtdTable() {
  const [data, setData] = useState([]);
  const [months, setMonths] = useState([]);

  const [getArticleMtdRequest, { loading: getArticleMtdLoading }] = useRequest({
    url: `/article/admin/articles-status-counts`,
    method: 'POST',
    onSuccess: (data) => {
      setData(data.filter((e) => bloggerEmailsList.includes(e.email)));
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getArticleMtdRequest();
  }, []);

  const processAndFormatData = () => {
    // Get months starting from the current month going backwards
    const currentMonth = new Date();
    setMonths(moment(currentMonth).toDate());
  };

  useEffect(() => {
    processAndFormatData();
  }, []);

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Article MTD - {moment(data && data[0]?.monthlyData).format('MMM yyyy')}</CardTitle>
        <div className="table-responsive">
          <table className="table table-striped table-hover" id="articles-table">
            <thead className="">
              <tr>
                <th>CreatedBy</th>
                <th>Total</th>

                {Object.keys(BLOG_STATUS_ENUM)?.map((e, index) => (
                  <th key={index}>{titleCaseIfExists(e)}</th>
                  //
                ))}
              </tr>
            </thead>
            {console.log(data)}
            <tbody>
              {data?.map((item) => (
                <tr key={item?.user_id}>
                  <td>{`${item?.first_name} ${item?.last_name}`}</td>
                  <td>{item?.monthlyData[moment(new Date()).format('MMM yyyy')]?.total || 0}</td>

                  {Object.keys(BLOG_STATUS_ENUM)?.map((e, index) => (
                    <td key={index}>
                      {item?.monthlyData[moment(new Date()).format('MMM yyyy')]?.[BLOG_STATUS_ENUM[e]] || 0}
                    </td>
                    // BLOG_STATUS_ENUM[e]
                  ))}
                </tr>
              ))}
              {isEmpty(data) && (
                <>
                  <tr>
                    <td colSpan={9} style={{ textAlign: 'center' }}>
                      No Data
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}

export default ArticleMtdTable;
