import Loader from 'components/Loader';
import useRequest from 'hooks/useRequest';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { getImageUrl, IMAGE_SIZES, printDateTime, showImage } from 'utils/helper';
import { userSelector } from '../../common/globalComponents/selectors';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import UserAutoComplete from '../../components/UserAutoComplete';
import ImageUpload from './ImageUpload';

const stateSelector = createStructuredSelector({
  user: userSelector,
});
const HelpCenter = () => {
  const { user: adminUser } = useSelector(stateSelector);
  const router = useRouter();

  toast.configure();

  const defaultFilters = {
    isClosed: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    startDate: moment().subtract(1, 'year'),
    endDate: moment(),
  };

  const [filters, setFilters] = useState(defaultFilters);
  const [inquiryDetails, setInquiryDetails] = useState([]);
  const [inquiryList, setInquiryList] = useState([]);
  const [inquiryIdReplyList, setInquiryIdReplyList] = useState([]);
  const [inquiryPagination, setInquiryPagination] = useState({});
  const [filteredInquiryList, setFilteredInquiryList] = useState({});

  const [selectedUser, setSelectedUser] = useState();

  const [activeInquiry, setActiveInquiry] = useState();
  const [activeTabID, setActiveTabID] = useState(router?.query?.id);
  const [page, setPage] = useState(1);
  const [currentMessage, setCurrentMessage] = useState('');
  const [selectedImage, setSelectedImage] = useState({});
  const size = 20;
  const endRef = useRef(null);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();

  const [imageUploadedModal, setImageUploadedModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const IMAGE_TYPE = 'thumbnailMedium';
  const [imageURL, setImageURL] = useState(null);

  const toggleImageUploadedModal = () => {
    setModalOpen(false);
    setImageUploadedModal(!imageUploadedModal);
  };

  useEffect(() => {
    endRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [inquiryIdReplyList]);

  const [getHelpCenterList, { loading: getHelpCenterListLoading }] = useRequest({
    method: 'POST',
    url: '/common/app-inquiry/admin/search',
    onSuccess: (data) => {
      if (page === 1) {
        setInquiryList(data.results);
      } else {
        setInquiryList((prev) => [...prev, ...data.results]);
      }
      setInquiryPagination(data?.pagination);
      const totalPages = Math.ceil(data?.pagination?.totalCount / data?.pagination?.size);
      setHasMore(page < totalPages);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getHelpCenterInquiryReply, { loading: getHelpCenterInquiryReplyLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setInquiryIdReplyList(data.sort((a, b) => moment(a.createdDate).diff(b.createdDate)));
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [deleteHelpCenterInquiryReply, { loading: deleteHelpCenterInquiryReplyLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: (data) => {
      console.log(data);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [saveHelpCenterInquiryReply, { loading: saveHelpCenterInquiryReplyLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      setImageURL(null);
      setSelectedImage(null);
      setCurrentMessage('');
      setInquiryIdReplyList([...inquiryIdReplyList, data]);
      window.location.reload();
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getHelpCenterDetails, { loading: getHelpCenterDetailsLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setInquiryDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const addReply = () => {
    saveHelpCenterInquiryReply({
      url: `/common/app-inquiry/admin/${activeTabID}/reply`,
      body: {
        content: currentMessage,
        imageId: selectedImage?.id,
      },
    });
  };

  useEffect(() => {
    if (activeTabID) {
      const queryParams = new URLSearchParams();
      queryParams.set('id', activeTabID);
      window.history.replaceState(null, '', `${router.pathname}?${queryParams.toString()}`);

      Promise.all([
        getHelpCenterInquiryReply({ url: `/common/app-inquiry/${activeTabID}/replies` }),
        getHelpCenterDetails({ url: `/common/app-inquiry/admin/${activeTabID}` }),
      ]);
    }
  }, [activeTabID]);

  useEffect(() => {
    if (!isEmpty(inquiryList)) {
      const filtered = selectedUser
        ? inquiryList.filter((inquiry) => inquiry?.createdById === selectedUser?.id)
        : inquiryList;

      if (!isEmpty(filtered) && !router?.query?.id) {
        setActiveTabID(filtered[0]?.id);
        setActiveInquiry(filtered[0]);
      }
      setFilteredInquiryList(filtered);
    }
  }, [selectedUser, inquiryList]);

  const callListUpdate = useCallback(() => {
    if (!hasMore || getHelpCenterListLoading) return; // Don't fetch if no more data or already loading

    getHelpCenterList({
      body: {
        page,
        size,
        sortBy: 'updatedDate',
        sortOrder: 1,
      },
    });
  }, [page, hasMore]);

  useEffect(() => {
    callListUpdate();
  }, [page]);

  useEffect(() => {
    setInquiryList([]);
    setPage(1);
  }, []);

  const lastPostElementRef = useCallback(
    (node) => {
      if (!hasMore || getHelpCenterListLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [hasMore, getHelpCenterListLoading]
  );

  // const handleDeleteMessage = (id) => {
  //   deleteHelpCenterInquiryReply({ url: `/common/app-inquiry/${id}/reply` });
  // };

  const handleCopyMessage = (message) => {
    setCurrentMessage(message);
  };

  const groupInquiriesByDate = (inquiries) => {
    const groups = {};

    inquiries.forEach((inquiry) => {
      const inquiryDate = moment(inquiry.createdDate);
      let label;

      if (inquiryDate.isSame(moment(), 'day')) {
        label = 'Today';
      } else if (inquiryDate.isSame(moment().subtract(1, 'day'), 'day')) {
        label = 'Yesterday';
      } else {
        label = inquiryDate.format('MMMM DD, YYYY');
      }

      if (!groups[label]) {
        groups[label] = [];
      }
      groups[label].push(inquiry);
    });

    return groups;
  };

  const groupedInquiries = groupInquiriesByDate(inquiryIdReplyList);

  const printDuration = (val) => {
    const now = moment(new Date());
    const end = moment(val);
    var duration = now.diff(end, 'minutes');
    var numdays = Math.floor(duration / 1440);
    var numhours = Math.floor((duration % 1440) / 60);
    var numminutes = Math.floor((duration % 1440) % 60);

    if (numdays > 0) {
      return numdays + ' day';
    } else if (numhours > 0) {
      return numhours + ' hr';
    } else {
      return numminutes + ' min';
    }
  };

  useEffect(() => {
    if (selectedImage) {
      const resURL = selectedImage?.[IMAGE_TYPE] ? selectedImage?.[IMAGE_TYPE] : selectedImage['original'];
      const url = resURL ? showImage(resURL) : '';
      setImageURL(url);
    }
  }, [selectedImage]);

  return (
    <div className="page-content">
      <Loader isActive={getHelpCenterListLoading || getHelpCenterDetailsLoading} />
      <Container fluid>
        <Breadcrumbs title="Users" breadcrumbItem="Help center" />

        <Row>
          <Col lg="12">
            <div className="d-lg-flex">
              <div className="chat-leftsidebar me-lg-4">
                <div className="">
                  <div className="search-box chat-search-box py-4">
                    <div className="position-relative">
                      <UserAutoComplete
                        selectedUser={selectedUser}
                        onSelectUser={setSelectedUser}
                        isNotClearable={false}
                      />
                    </div>
                  </div>

                  <div className="chat-leftsidebar-nav">
                    <div>
                      <div className="d-flex align-items-center justify-content-between  mb-3">
                        <h5 className="font-size-14 mb-0">Recent ---</h5>
                        <p className="mb-0">{inquiryPagination?.totalCount} results found</p>
                      </div>
                      <ul className="list-unstyled chat-list" id="recent-list">
                        <div style={{ height: '450px', overflowY: 'auto' }}>
                          {!isEmpty(filteredInquiryList) &&
                            filteredInquiryList?.map((elem, index) => {
                              return (
                                <li
                                  key={`${elem.id}_${elem.title}`}
                                  className={elem?.id === activeTabID ? 'active' : ''}
                                  ref={filteredInquiryList.length === index + 1 ? lastPostElementRef : null}>
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      setActiveTabID(elem?.id);
                                    }}>
                                    <div className="d-flex">
                                      <div className="align-self-center me-3">
                                        {/* {isEmpty(elem?.image) ? (
                                          <div className="border border-dark rounded-circle avatar-xs d-flex align-items-center justify-content-center">
                                            <i className="bx bxs-user"></i>
                                          </div>
                                        ) : (
                                          <img
                                            src={getImageUrl(elem?.image, 'original')}
                                            className="rounded-circle avatar-xs"
                                          />
                                        )} */}
                                      </div>

                                      <div className="flex-grow-1 overflow-hidden">
                                        <h5 className="text-truncate font-size-14 mb-1">{elem?.title}</h5>
                                        <p className="text-truncate mb-0">{elem?.email}</p>
                                      </div>
                                      <div className="font-size-11">{printDuration(elem?.updatedDate)}</div>
                                    </div>
                                  </Link>
                                </li>
                              );
                            })}
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-100 user-chat">
                <Card>
                  <div className="p-4 border-bottom ">
                    <Row>
                      <Col md="4" xs="9">
                        <h5 className="font-size-15 mb-1">{inquiryDetails?.createdBy?.displayName}</h5>

                        <p className="text-muted mb-0">
                          <i
                            className={
                              filters?.Chat_Box_User_Status === 'online'
                                ? 'mdi mdi-circle text-success align-middle me-1'
                                : filters?.Chat_Box_User_Status === 'intermediate'
                                ? 'mdi mdi-circle text-warning align-middle me-1'
                                : 'mdi mdi-circle align-middle me-1'
                            }
                          />
                          {filters?.Chat_Box_User_Status}
                        </p>
                      </Col>
                      <Col md="8" xs="3">
                        <ul className="list-inline user-chat-nav text-end mb-0">
                          <li className="list-inline-item">
                            <UncontrolledDropdown direction="left">
                              <DropdownToggle className="btn nav-btn">
                                <i className="bx bx-dots-horizontal-rounded"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem href="#">Close</DropdownItem>
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <div className="chat-conversation p-3">
                      <ul className="list-unstyled mb-0">
                        <div style={{ maxHeight: '486px', overflowY: 'auto' }}>
                          <li className=" ">
                            <div className="conversation-list">
                              <div className="ctext-wrap">
                                <div className="conversation-name ">{inquiryDetails?.createdBy?.displayName}</div>
                                <p>{inquiryList?.find((e) => e?.id === activeTabID)?.title}</p>
                                <p>{inquiryList?.find((e) => e?.id === activeTabID)?.content}</p>
                                {!isEmpty(inquiryList?.find((e) => e?.id === activeTabID)?.image?.thumbnailSmall) && (
                                  <a
                                    href={getImageUrl(
                                      inquiryList?.find((e) => e?.id === activeTabID)?.image,
                                      IMAGE_SIZES.ORIGINAL
                                    )}
                                    target="_blank"
                                    rel="noreferrer">
                                    <img
                                      src={getImageUrl(
                                        inquiryList?.find((e) => e?.id === activeTabID)?.image,
                                        IMAGE_SIZES.MEDIUM
                                      )}
                                      width={40}
                                      height={40}
                                    />
                                  </a>
                                )}
                                <p className="chat-time mb-0">
                                  <i className="bx bx-time-five align-middle me-1" />
                                  {printDateTime(inquiryList?.find((e) => e?.id === activeTabID)?.createdDate)}
                                </p>
                              </div>
                            </div>
                          </li>
                          {Object.entries(groupedInquiries).map(([dateLabel, inquiries], index) => (
                            <React.Fragment key={dateLabel}>
                              <li key={`date-label-${index}`}>
                                <div className="chat-day-title">
                                  <span className="title">{dateLabel}</span>
                                </div>
                              </li>
                              {inquiries.map((inquiry, idx) => (
                                <li
                                  key={`inquiry-${inquiry.id}-${idx}`}
                                  className={inquiry?.createdById !== activeInquiry?.createdById ? 'right' : ''}>
                                  <div className="conversation-list">
                                    {inquiry?.createdById === adminUser.id && (
                                      <UncontrolledDropdown>
                                        <DropdownToggle href="#" className="dropdown-toggle" tag="a">
                                          <i className="bx bx-dots-vertical-rounded" />
                                        </DropdownToggle>
                                        <DropdownMenu className="dropdown-menu-end">
                                          <DropdownItem href="#" onClick={() => handleCopyMessage(inquiry?.content)}>
                                            Copy
                                          </DropdownItem>
                                          {/* <DropdownItem href="#" onClick={() => handleDeleteMessage(inquiry?.id)}>
                                            Delete
                                          </DropdownItem> */}
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    )}
                                    <div className="ctext-wrap">
                                      <div className="conversation-name">{inquiry?.createdBy?.displayName}</div>
                                      <p>{inquiry?.content}</p>
                                      {!isEmpty(inquiry?.image?.thumbnailSmall) && (
                                        <a
                                          href={getImageUrl(inquiry?.image, IMAGE_SIZES.ORIGINAL)}
                                          target="_blank"
                                          rel="noreferrer">
                                          <img
                                            src={getImageUrl(inquiry?.image, IMAGE_SIZES.MEDIUM)}
                                            width={300}
                                            height={300}
                                          />
                                        </a>
                                      )}
                                      <p className="chat-time mb-0">
                                        <i className="bx bx-time-five align-middle me-1" />
                                        {moment(inquiry?.createdDate).format('hh:mm')}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </React.Fragment>
                          ))}
                          <div ref={endRef} />
                        </div>
                      </ul>
                    </div>
                    <div className="input-wrapper" style={{ display: 'flex', alignItems: 'center' }}>
                      {imageURL && (
                        <div className="image-preview position-relative me-2">
                          <img
                            src={imageURL}
                            alt="Selected"
                            className="img-fluid rounded"
                            style={{ maxWidth: '300px', maxHeight: '300px' }}
                          />
                          {/* <button
                            type="button"
                            className="btn btn-danger btn-sm position-absolute top-0 start-100 translate-middle"
                            onClick={() => setSelectedImage(null)}
                            style={{ borderRadius: '50%', padding: '2px', fontSize: '10px' }}>
                            <i className="mdi mdi-close"></i>
                          </button> */}
                        </div>
                      )}
                    </div>
                    <div className="p-3 chat-input-section">
                      <Row>
                        <Col>
                          <div className="position-relative">
                            <input
                              type="text"
                              value={currentMessage}
                              onChange={(e) => setCurrentMessage(e.target.value)}
                              className="form-control chat-input"
                              placeholder="Enter message..."
                            />
                          </div>

                          <div
                            className="chat-input-links"
                            style={{
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                            }}>
                            <ul className="list-inline mb-0">
                              {/* <li className="list-inline-item">
                                <Link to="#">
                                  <i className="mdi mdi-emoticon-happy-outline" id="Emojitooltip"></i>
                                  <UncontrolledTooltip placement="top" target="Emojitooltip">
                                    Emojis
                                  </UncontrolledTooltip>
                                </Link>
                              </li> */}
                              <li className="list-inline-item">
                                <Link to="#" onClick={toggleImageUploadedModal}>
                                  <i className="mdi mdi-file-image-outline" id="Imagetooltip"></i>
                                  <UncontrolledTooltip placement="top" target="Imagetooltip">
                                    Images
                                  </UncontrolledTooltip>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Col>
                        <Col className="col-auto">
                          <Button
                            type="button"
                            color="primary"
                            aria-label="Send message"
                            onClick={() => addReply()}
                            className="btn-rounded chat-send w-md">
                            <span className="d-none d-sm-inline-block me-2">Send</span> <i className="mdi mdi-send"></i>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
                <ImageUpload
                  isOpen={imageUploadedModal}
                  toggle={toggleImageUploadedModal}
                  onUpload={(file) => {
                    toggleImageUploadedModal();
                    setSelectedImage(file);
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HelpCenter;
