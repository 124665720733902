import { PUBLIC_URL } from 'utils/constants';

export default {
  // FAQ
  FAQ_METADATA_LIST: `${PUBLIC_URL}/faqs/metadata`,
  FAQ_LIST: `${PUBLIC_URL}/faqs`,
  FAQ_ADD: `${PUBLIC_URL}/faq/add`,
  FAQ_EDIT: `${PUBLIC_URL}/faq/edit/:id`,

  // Page
  STATIC_METADATA_PAGES_LIST: `${PUBLIC_URL}/pages/metadata`,
  STATIC_PAGES_LIST: `${PUBLIC_URL}/pages`,
  STATIC_PAGES_ADD: `${PUBLIC_URL}/pages/add`,
  STATIC_PAGES_EDIT: `${PUBLIC_URL}/pages/edit/:id`,
};
