import React, { useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

import 'react-datepicker/dist/react-datepicker.css';

import { convertToRaw } from 'draft-js';
import { useFormik } from 'formik';
import { invert, isEmpty, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { createStructuredSelector } from 'reselect';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { AGE_GROUP, DIFFICULTY_LEVEL, GRADES, WORKSHEET_STATUS } from '../../../utils/constants';
import { createWorksheet } from '../actions';
import { createCategoryDoneSelector, workSheetsSubjectsSelector, workSheetsTopicsSelector } from '../selectors';

const stateSelector = createStructuredSelector({
  category: createCategoryDoneSelector,
  subjectList: workSheetsSubjectsSelector,
  topicList: workSheetsTopicsSelector,
});

const WorksheetsApprover = (props) => {
  const { category, subjectList, topicList } = useSelector(stateSelector);
  const { worksheet, isDup } = props;
  const isEdit = !isEmpty(worksheet);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [tags, setTags] = useState([]);
  const [topics, setTopics] = useState({});
  const [subjects, setSubjects] = useState([]);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  function handleAcceptedFiles(files) {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setselectedFiles(files);
  }

  const dispatch = useDispatch();

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      title: isEdit ? worksheet.title : '',
      ageGroup: isEdit ? worksheet.ageGroup : '',
      level: isEdit ? worksheet.level : '',
      jsonContent: isEdit ? worksheet.jsonContent : '',
      worksheetAttachment: isEdit && !isDup ? worksheet.worksheetAttachment : null,
      worksheetAttachments: [],
      answerAttachment: isEdit && !isDup ? worksheet.answerAttachment : null,
      subjects: [],
      topics: [],
      sequence: isEdit ? worksheet.sequence : '',
      active: isEdit && !isDup ? worksheet.active : false,
      isAnswerVisible: isEdit && !isDup ? worksheet.isAnswerVisible : false,
      id: isEdit && !isDup ? worksheet.id : null,
      grades: isEdit ? worksheet.grades : [],
      status: isEdit && !isDup ? worksheet.status : WORKSHEET_STATUS.PENDING_FOR_APPROVAL,
      deleted: isEdit && !isDup ? worksheet.deleted : false,
    },
    onSubmit: (values) => {
      dispatch(
        createWorksheet.trigger({
          school: {
            name: values.name,
            shortName: values.showText,
            active: false,
            address: {
              country: values.country,
              region: values.region,
              city: values.city,
              subArea: values.subArea,
            },
            systemInfo: {
              notes: values.notes,
            },
          },
        })
      );
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required('Please Provide a title')
        .min(10, 'Title should be at least 10 characters long')
        .max(150, 'Title should be at most 100 characters long'),
      jsonContent: Yup.string(),
      ageGroup: Yup.number().required('Please Select a Age Group'),
      level: Yup.number().required('Please Select a Difficulty Level'),
      worksheetAttachment: isEdit && Yup.object(),
      worksheetAttachments: !isEdit && Yup.array().min(1, 'Please select atleast one worksheet'),
      subjects: Yup.array().min(1, 'Please select atleast one subject'),
      sequence: Yup.number(),
      grades: Yup.array().min(1, 'Please select atleast one grade'),
    }),
  });

  useEffect(() => {
    if (isEdit) {
      const TopicsObj = {};
      const subjectObj = worksheet.subjects.map((subject) => {
        TopicsObj[subject.slug] = [];
        return {
          ...subject,
          label: subject.name,
          value: subject.slug,
        };
      });
      setSubjects(subjectObj);
      formik.values.subjects = subjectObj;

      for (const topic of worksheet.topics) {
        TopicsObj[topic.subject.slug].push({
          ...topic,
          label: topic.name,
          value: topic.slug,
        });
      }
      setTopics(TopicsObj);
      setTags(
        worksheet.tags.map((tag) => ({
          label: tag.name,
          value: tag.name,
        }))
      );
      const gradeObj = [];
      for (const key of worksheet.grades) {
        gradeObj.push({ label: invert(GRADES)[key], value: key });
      }
      formik.values.grades = gradeObj;
    }
  }, [isEdit]);

  const handleSubCheckBox = (subject) => {
    if (!isEmpty(subjects.filter((sub) => sub.id === subject.id))) {
      setSubjects(subjects.filter((sub) => sub.id !== subject.id));
      setTopics({ ...topics, [subject.slug]: [] });
    } else {
      setSubjects([...subjects, subject]);
      formik.values.subjects = [...subjects, subject];
    }
  };

  // const handleOnChangeTopic = (values, subject) => {
  //   const newValues = [...values];
  //   values.map((value) => (
  //     value.parentTopics &&
  //       value.parentTopics.map((parentTopic) => (
  //         (newValues.filter((val) => val.id === parentTopic.id).length === 0) && newValues.push(parentTopic)
  //     ));
  // ));
  //   setTopics({ ...topics, [subject]: newValues });
  // };

  const handleWorkSheetImage = (fileObj) => {
    console.log(fileObj);
    if (fileObj) {
      formik.setFieldValue('worksheetAttachments', fileObj.data.files);
    }
  };
  const handleWorkSheetAnswerImage = (fileObj) => {
    if (fileObj) {
      formik.setFieldValue('answerAttachment', fileObj.data.files[0]);
    }
  };
  const checkForSubjectTopics = () => {
    for (const sub of subjects) {
      // if (isEmpty(topics[sub.slug])) {
      //   formik.errors.topics = 'Select Topics for the selected subject';
      //   break;
      // } else {
      delete formik.errors.topics;
      // }
    }
  };
  const handleOnSubmit = (isDuplicate) => {
    checkForSubjectTopics();
    formik.validateForm().then((res) => {
      if (isEmpty(res)) {
        let topicList = [];
        Object.keys(topics).map((key) => (topicList = [...topicList, ...topics[key]]));
        formik.values.topics = topicList;
        formik.values.subjects = subjects;
        formik.values.grades = formik.values.grades.map((grade) => grade.value);
        const worksheetObj = {
          worksheet: { ...worksheet, ...formik.values },
          // tags: getTags(),
          worksheetAttachments: formik.values.worksheetAttachments,
        };
        dispatch(createWorksheet.trigger({ worksheetObj, isDuplicate }));
      }
    });
  };

  const onEditorChange = (data) => {
    formik.values.jsonContent = convertToRaw(data);
  };

  const onTagChange = (data) => {
    setTags(data);
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Worksheets" breadcrumbItem="Add Worksheets" />

          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">Create New Worksheet</CardTitle>
                  <Form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="title" className="form-label  ">
                            Title
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            placeholder="Enter Title..."
                            fullWidth
                            id="title"
                            label="Title"
                            variant="outlined"
                            invalid={!!(formik.touched.title && formik.errors.title)}
                            {...formik.getFieldProps('title')}
                            error={!isEmpty(formik.errors.title)}
                            helperText={!isEmpty(formik.errors.title) && formik.errors.title}
                          />
                          <FormFeedback>{formik.errors.title}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={8}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="jsonContent" className="form-label  ">
                            Description
                          </Label>
                          <Input
                            type="textarea"
                            className="form-control"
                            id="jsonContent"
                            rows="3"
                            placeholder="jsonContent..."
                            invalid={!!(formik.touched.jsonContent && formik.errors.jsonContent)}
                            {...formik.getFieldProps('jsonContent')}
                          />
                          <FormFeedback>{formik.errors.jsonContent}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="3" sm="6">
                        <FormGroup>
                          <Label htmlFor="ageGroup">Select Age group</Label>
                          <Select
                            className="select2-dropdown"
                            value={AGE_GROUP[formik.values.ageGroup]}
                            onChange={(v) => {
                              formik.setFieldValue('ageGroup', v);
                            }}
                            options={Object.keys(AGE_GROUP).map((e) => {
                              return { value: AGE_GROUP[e], label: e };
                            })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3" sm="6">
                        <FormGroup>
                          <Label htmlFor="level">Select Difficulty Level</Label>
                          <Select
                            className="select2-dropdown"
                            value={DIFFICULTY_LEVEL[formik.values.level]}
                            onChange={(v) => {
                              formik.setFieldValue('level', v);
                            }}
                            options={Object.keys(DIFFICULTY_LEVEL).map((e) => {
                              return { value: DIFFICULTY_LEVEL[e], label: e };
                            })}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3" sm="6">
                        <FormGroup>
                          <Label htmlFor="level">Select Class Levels</Label>
                          <Select
                            className="select2-dropdown"
                            value={GRADES[formik.values.grades]}
                            onChange={(v) => {
                              formik.setFieldValue('grades', v);
                            }}
                            options={Object.keys(GRADES).map((e) => {
                              return { value: GRADES[e], label: e };
                            })}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {subjectList &&
                        subjectList.length > 0 &&
                        map(subjectList, (subject, key) => (
                          <div className="bg-soft-info custom-control custom-checkbox mt-1 mb-2 ms-1 me-1">
                            <input
                              type="checkbox"
                              className="form-check-input input-mini"
                              id={`${key}_first`}
                              name={`${key}_first`}
                              checked={!isEmpty(subjects.filter((sub) => sub.id === subject.id))}
                              onChange={() => handleSubCheckBox(subject)}
                            />
                            <Label className="form-check-label" for={`${key}_first`}>
                              {subject.name}
                            </Label>
                          </div>
                        ))}
                    </Row>
                  </Form>
                  <FormGroup>
                    <Label className="col-form-label col-lg-2">Upload Worksheet</Label>
                    <Col lg="10">
                      <Form>
                        <Dropzone
                          onDrop={(acceptedFiles) => {
                            handleAcceptedFiles(acceptedFiles);
                          }}>
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div className="dz-message needsclick" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                          {selectedFiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={`${i}-file`}>
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link to="#" className="text-muted font-weight-bold">
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Form>
                    </Col>
                  </FormGroup>
                  <Row className="justify-content-end">
                    <Col lg="10">
                      <Button type="submit" color="primary">
                        Create Worksheet
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WorksheetsApprover;
