import React, { useEffect, useState } from 'react';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

// Import Image
import { Divider } from '@material-ui/core';
import Loader from 'components/Loader';
import Paginate from 'components/Paginate';
import TableError from 'components/TableError';
import useRequest from 'hooks/useRequest';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import { createStructuredSelector } from 'reselect';
import {
  ACTIVE_AND_DELETED_ARRAY,
  LANGUAGE_ARRAY_WITH_STRING,
  LANGUAGE_VALUES,
  PRINT_LANGUAGE,
  PRINT_LANGUAGE_COLOR,
  SORT_VALUES,
} from 'utils/constants';
import { buildURL, checkIfValueExist, printDate, titleCaseIfExists } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import { getForumCategories } from '../actions';
import { categoryListPaginationSelector, categoryListSelector } from '../selectors';
import { toast } from 'react-toastify';
import Select from 'react-select';

const stateSelector = createStructuredSelector({
  categories: categoryListSelector,
  categoriesPagination: categoryListPaginationSelector,
});

const CategoryList = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const history = useHistory();

  const { categories, categoriesPagination } = useSelector(stateSelector);

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
    id: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);
    dispatch(
      getForumCategories.trigger({
        page,
        size: 20,
        ...filters,
        ...SORT_VALUES,
        active: checkIfValueExist(filters?.active?.value),
        deleted: checkIfValueExist(filters?.deleted?.value),
      })
    );
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const applyFilters = () => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  };

  const clearFilters = () => {
    setFilters({
      ...defaultFilters,
    });
    dispatch(
      getForumCategories.trigger({
        page: 1,
        size: 20,
        ...defaultFilters,
        ...SORT_VALUES,
        active: checkIfValueExist(defaultFilters?.active?.value),
        deleted: checkIfValueExist(defaultFilters?.deleted?.value),
      })
    );
  };

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Category has been ${data?.active ? 'active' : 'Inactive'}.`);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Category has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const handleActive = (data) => {
    markActiveInActiveRequest({
      url: `/forum/admin/forum-category/${data?.id}`,
      body: {
        active: data?.active ? false : true,
      },
    });
  };
  const handleDelete = (data) => {
    deleteUnDeleteRequest({
      url: `/forum/admin/forum-category/${data?.id}`,
      body: {
        deleted: data?.deleted ? false : true,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={markActiveInActiveLoading || deleteUnDeleteLoading} />

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Categories" breadcrumbItem="Categories List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY_WITH_STRING}
                      value={LANGUAGE_ARRAY_WITH_STRING?.find((t) => t.value == filters?.language)}
                      onChange={(item) =>
                        setFilters({
                          ...filters,
                          language: item.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Category ID</label>
                    <Input
                      type="number"
                      placeholder="Category ID..."
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: +e?.target?.value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2  align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2">
                <div className="position-relative font-bold">{categoriesPagination?.totalCount} Categories Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.CATEGORY_ADD}>
                  <i className="mdi mdi-plus me-1" />
                  Add Category
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">#Id</th>
                        <th scope="col">
                          Name <br /> Slug
                        </th>
                        <th scope="col">Post Count</th>
                        <th scope="col">Topic Count</th>
                        <th scope="col">Follow Count</th>
                        <th scope="col">View Count</th>
                        <th scope="col">Created On</th>
                        <th scope="col">Language</th>
                        <th scope="col">Approved</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(categories) ? (
                        map(categories, (category, index) => (
                          <tr key={index}>
                            <td>{category?.id} </td>
                            <td>
                              <Link
                                to={buildURL(SMKLinks.CATEGORY_EDIT, {
                                  id: category?.id,
                                })}
                                className="text-primary">
                                {category?.name} <br />
                              </Link>
                              {category?.slug}
                            </td>
                            <td>{category?.postCount} </td>
                            <td>{category?.topicCount} </td>
                            <td>{category?.followCount} </td>
                            <td>{category?.viewCount} </td>
                            <td>{printDate(category?.createdDate)} </td>
                            <td align="center">
                              <Badge className={PRINT_LANGUAGE_COLOR[category?.language]}>
                                {PRINT_LANGUAGE[category?.language]}
                              </Badge>
                            </td>
                            <td align="center">
                              <Badge className={category?.approvedById !== null ? 'bg-success' : 'bg-danger'}>
                                {category?.approvedById !== null ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={category?.active ? 'bg-success' : 'bg-danger'}>
                                {category?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={category?.deleted ? 'bg-success' : 'bg-danger'}>
                                {category?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleActive(category)}>
                                    <i
                                      className={`fas  ${
                                        category?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                      }   m-1 pe-3`}
                                    />
                                    {category?.active ? 'Disable' : 'Enable'}
                                  </DropdownItem>

                                  {!category?.deleted && (
                                    <DropdownItem onClick={() => handleDelete(category)}>
                                      <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                      {category?.deleted ? 'Undelete' : 'Delete'}
                                    </DropdownItem>
                                  )}

                                  <Divider className="my-2" />

                                  <Link
                                    to={buildURL(SMKLinks.CATEGORY_EDIT, {
                                      id: category?.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={categoriesPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default CategoryList;
