import { USER_ROLE } from 'utils/constants';
import ARTICLE_LINKS from './links';
import ArticleDashboard from './dashboard';

const ARTICLE_ROUTES = [
  {
    path: ARTICLE_LINKS.ARTICLE_DASHBOARD,
    exact: true,
    component: ArticleDashboard,
    role: [USER_ROLE.ADMIN],
  },
];

export { ARTICLE_ROUTES, ARTICLE_LINKS };
