import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Alert, Button, Card, CardBody, Col, Container, Form, Row } from 'reactstrap';
import * as Yup from 'yup';
import profileImg from '../../assets/images/profile-img.png';
import MicrosoftLogin from './microsoftLogin';

const HrLogin = (props) => {
  const [userType, setUserType] = useState('hr');

  const loginErrorMsg = '';

  const formik = useFormik({
    initialValues: {
      remember: false,
    },
    onSubmit(values) {},
    validationSchema: Yup.object().shape({}),
  });

  return (
    <div className="account-pages d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="overflow-hidden">
              <div className="bg-soft-primary">
                <Row>
                  <Col className="col-7">
                    <div className="text-primary p-4">
                      <h5 className="text-primary">HR Admin</h5>
                      <p>Sign in to continue</p>
                    </div>
                  </Col>
                  <Col className="col-5 align-self-end">
                    <img src={profileImg} className="img-fluid" />
                  </Col>
                </Row>
              </div>
              <CardBody className="pt-0">
                <div className="p-2">
                  <Form className="form-horizontal" onSubmit={formik.handleSubmit}>
                    {loginErrorMsg && <Alert color="danger">{loginErrorMsg}</Alert>}

                    <MicrosoftLogin userType={userType} />
                  </Form>
                </div>
              </CardBody>
            </Card>

            <div className="mt-5 text-center">
              <a target="_blank" rel="noreferrer" href={'/branding'} className="text-primary text-center">
                View Branding Page
              </a>
              <p className="mt-2">© {new Date().getFullYear()} ParentsNKids.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

HrLogin.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};

export default HrLogin;
