import Breadcrumbs from 'components/Common/Breadcrumb';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { buildURL, checkIfValueExist, printSerialNumbers } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../components/Loader';
import Paginate from '../../components/Paginate';
import useRequest from '../../hooks/useRequest';
import { useRouter } from '../../hooks/useRouter';
import {
  ACTIVE_AND_DELETED_ARRAY,
  LANGUAGE_ARRAY_WITH_STRING,
  LANGUAGE_VALUES,
  PRINT_LANGUAGE,
  PRINT_LANGUAGE_COLOR,
  sortBySearchData,
} from '../../utils/constants';

const CalculatorsList = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [calculatorCategories, setCalculatorCategories] = useState([]);
  const [calculatorsList, setCalculatorsList] = useState([]);
  const [calculatorsPagination, setCalculatorsPagination] = useState({});

  // filters states

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    sortBy: { value: 'createdDate', label: 'Created Date' },
    language: LANGUAGE_ARRAY_WITH_STRING[0],
    categoryId: null,
    name: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getCalculatorTaxonomyList, { loading: getCalculatorTaxonomyListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setCalculatorCategories(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getCalculatorsListRequest, { loading: getCalculatorsListLoading }] = useRequest({
    url: `/calculator/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setCalculatorsList(data?.results);
      setCalculatorsPagination(data?.paginator);
    },
  });

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Calculator Banner has been ${data?.active ? 'active' : 'Inactive'}.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page })}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);
    getCalculatorsListRequest({
      body: {
        page,
        size: 25,
        sortOrder: 1,
        language: filters?.language?.value,
        sortBy: checkIfValueExist(filters.sortBy?.value),
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        isPopular: checkIfValueExist(filters.isPopular?.value),
        isLive: checkIfValueExist(filters.isLive?.value),
        categoryId: checkIfValueExist(filters.categoryId?.value),
        name: checkIfValueExist(filters.name),
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);
    setFilters({ ...defaultFilters, categoryId: null });
    getCalculatorsListRequest({
      body: {
        page: 1,
        size: 25,
        sortOrder: 1,
        language: defaultFilters?.language?.value,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        isPopular: checkIfValueExist(defaultFilters.isPopular?.value),
        isLive: checkIfValueExist(defaultFilters.isLive?.value),
        sortBy: checkIfValueExist(defaultFilters.sortBy?.value),
        categoryId: checkIfValueExist(defaultFilters.categoryId?.value),
        name: checkIfValueExist(defaultFilters.name),
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  useEffect(() => {
    getCalculatorTaxonomyList({
      body: {
        page: 1,
        size: 500,
        sortBy: 'id',
        sortOrder: 0,
        type: 'calculator_category',
        language: filters.language?.value,
      },
    });
  }, [filters.language?.value]);

  const handleActive = (data) => {
    markActiveInActiveRequest({
      url: `/calculator/admin/${data?.id}`,
      body: {
        ...data,
        active: !data?.active,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getCalculatorsListLoading || getCalculatorTaxonomyListLoading || markActiveInActiveLoading} />
        <Container fluid>
          <Breadcrumbs title="Calculators" breadcrumbItem="Calculators List" />

          <Card>
            <CardBody>
              <div className="mt-3">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Sort By</label>
                    <Select
                      options={sortBySearchData?.filter((e) => e?.value !== 'ageGroup')}
                      placeholder="Select sortBy..."
                      value={filters.sortBy}
                      onChange={(value) => setFilters({ ...filters, sortBy: value })}
                    />
                  </div>

                  <div className="col-lg-3  mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY_WITH_STRING}
                      value={filters.language}
                      onChange={(value) => setFilters({ ...filters, language: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">isPopular</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.isPopular}
                      onChange={(value) => setFilters({ ...filters, isPopular: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Category</label>
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 999 }),
                      }}
                      placeholder="Select category..."
                      options={calculatorCategories
                        ?.filter((d) => d.level === 0)
                        ?.map((e) => ({
                          label: e?.name,
                          value: e?.id,
                        }))}
                      value={filters?.categoryId}
                      onChange={(val) => setFilters({ ...filters, categoryId: val })}
                    />
                  </div>

                  <div className="col-lg-3 ">
                    <label className="form-label">Name</label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="Name"
                      value={filters?.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{calculatorsPagination?.totalCount} Calculators Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_CALCULATOR}>
                  <i className="mdi mdi-plus me-1" />
                  Add Calculator
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table  table-borderless ">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col"></th>
                        <th scope="col">Title/slug</th>
                        <th scope="col">Primary Category</th>
                        <th scope="col">Categories</th>
                        <th scope="col">Language</th>
                        <th scope="col">isPopular</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(calculatorsList) ? (
                        calculatorsList?.map((data, index) => {
                          return (
                            <tr key={index} className={data?.deleted || !data?.active ? 'bg-danger' : ''}>
                              <td>{printSerialNumbers(calculatorsPagination)[index]}</td>
                              <td>
                                <Link
                                  to={buildURL(SMKLinks.EDIT_CALCULATOR, {
                                    id: data?.id,
                                  })}>
                                  {data?.title}
                                </Link>
                                <br />
                                Slug: {data?.slug} <br />
                                Name: {data?.name}
                              </td>
                              <td>
                                <p className="me-2 mb-2">{data?.primaryCategory?.name}</p>
                              </td>
                              <td>
                                {data?.categories?.map((e) => (
                                  <p className="me-2 mb-2">{e?.name}</p>
                                ))}
                              </td>
                              <td>
                                <Badge className={PRINT_LANGUAGE_COLOR[data?.language]}>
                                  {PRINT_LANGUAGE[data?.language]}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.isPopular ? 'bg-success' : 'bg-danger'}>
                                  {data?.isPopular ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                  {data?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop " tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <Link
                                      to={buildURL(SMKLinks.EDIT_CALCULATOR, {
                                        id: data?.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>

                                    <DropdownItem onClick={() => handleActive(data)}>
                                      <i
                                        className={`fas  ${
                                          data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                        }   m-1 pe-3`}
                                      />
                                      {data?.active ? 'Disable' : 'Enable'}
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={calculatorsPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default CalculatorsList;
