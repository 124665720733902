import { call, put, takeLatest } from 'redux-saga/effects';
import { getUserSearchList } from './actions';
import { api } from '../../utils/axios';
import { API_BASE_URL } from '../../utils/constants';

const GET_USER_SEARCH_API_URL = `${API_BASE_URL}/user/search`;

const getUserSearchCall = (filters) =>
  api({
    method: 'POST',
    url: GET_USER_SEARCH_API_URL,
    data: filters,
  });

function* getUserSearchRequest(actions) {
  try {
    yield put(getUserSearchList.request());

    const response = yield call(getUserSearchCall, actions.payload);

    if (response.success) {
      yield put(getUserSearchList.success({ ...response.data }));
    }
  } catch (error) {
    yield put(getUserSearchList.failure(error));
  } finally {
    yield put(getUserSearchList.fulfill());
  }
}

export default function* globalSaga() {
  yield takeLatest(getUserSearchList.TRIGGER, getUserSearchRequest);
}
