import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { buildURL, checkIfValueExist, showImage } from '../../../utils/helper';
import { toast } from 'react-toastify';
import { SMKLinks } from 'utils/links';
import { Divider } from '@material-ui/core';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import { ACTIVE_AND_DELETED_ARRAY, SORT_VALUES } from 'utils/constants';

const CollegeMetaContentList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [collegeMetaContent, setCollegeMetaContent] = useState([]);
  const [collegeMetaContentPagination, setCollegeMetaContentPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    refId: '',
    slug: '',
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [getCollegeMetaContentRequest, { loading: getCollegeMetaContentLoading }] = useRequest({
    url: `/college/admin/collegeMetaDataContent/search`,
    method: 'POST',
    onSuccess: (data) => {
      setCollegeMetaContentPagination(data?.paginator);

      const filteredData = data?.results?.reduce((acc, obj) => {
        const { id, refId, slug, active, deleted, attribute } = obj;
        const existingItem = acc.find((item) => item.refId === refId && item.slug === slug);
        if (existingItem) {
          existingItem.attirbuteNames.push({ attribute });
        } else {
          acc.push({ id, refId, slug, active, deleted, attirbuteNames: [{ attribute }] });
        }
        return acc;
      }, []);
      setCollegeMetaContent(filteredData);
    },
  });

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! College Group Content has been ${data?.active ? 'active' : 'Inactive'}.`);
    },
  });

  const [deleteUnDeleteGroupsContentRequest, { loading: deleteUnDeleteGroupsContentLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! College Group Content has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getCollegeMetaContentRequest({
      body: {
        page,
        size: 500,
        ...filters,
        active: checkIfValueExist(filters?.active?.value),
        deleted: checkIfValueExist(filters?.deleted?.value),
        refId: checkIfValueExist(filters?.refId.trim()),
        slug: checkIfValueExist(filters?.slug.trim()),
        ...SORT_VALUES,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getCollegeMetaContentRequest({
      body: {
        page: 1,
        size: 500,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        refId: checkIfValueExist(defaultFilters?.refId),
        slug: checkIfValueExist(defaultFilters?.slug),
        ...SORT_VALUES,
      },
    });
  };

  const handleActive = (data) => {
    data?.active
      ? markActiveInActiveRequest({
          url: `/college/admin/collegeMetaDataContent/markInactive/${data?.id}`,
        })
      : markActiveInActiveRequest({
          url: `/college/admin/collegeMetaDataContent/markActive/${data?.id}`,
        });
  };
  const handleDelete = (data) => {
    data?.deleted
      ? deleteUnDeleteGroupsContentRequest({
          url: `/college/admin/collegeMetaDataContent/undelete/${data?.id}`,
        })
      : deleteUnDeleteGroupsContentRequest({
          url: `/college/admin/collegeMetaDataContent/delete/${data?.id}`,
        });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={getCollegeMetaContentLoading || markActiveInActiveLoading || deleteUnDeleteGroupsContentLoading}
        />

        <Container fluid>
          <Breadcrumbs title="Colleges" breadcrumbItem="College Meta Data List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>

                  <div className="col-lg-3">
                    <label className="form-label">Ref ID </label>
                    <Input
                      type="text"
                      value={filters.refId}
                      onChange={(e) => setFilters({ ...filters, refId: e.target.value })}
                      placeholder="Search by ref Id"
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label">Slug </label>
                    <Input
                      type="text"
                      value={filters.slug}
                      onChange={(e) => setFilters({ ...filters, slug: e.target.value })}
                      placeholder="Search by slug"
                    />
                  </div>
                </div>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{collegeMetaContent?.length} College Meta Data Found</div>
              </div>
            </Col>
            {/* <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_COLLEGE_META_CONTENT}>
                  <i className="mdi mdi-plus me-1" />
                  Add College Group Content
                </Link>
              </div>
            </Col> */}
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">Ref ID</th>
                        <th scope="col">Slug</th>
                        <th scope="col">Attributes</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(collegeMetaContent) ? (
                        collegeMetaContent
                          ?.sort((a, b) => {
                            const nameA = a?.slug?.toLowerCase();
                            const nameB = b?.slug?.toLowerCase();
                            return nameA.localeCompare(nameB);
                          })
                          ?.map((data, index) => (
                            <tr key={index}>
                              <td>
                                <Link
                                  to={buildURL(SMKLinks.EDIT_COLLEGE_META_CONTENT, {
                                    id: data?.id,
                                    refId: data?.refId,
                                  })}>
                                  {data?.refId}
                                </Link>
                              </td>
                              <td>{data?.slug}</td>
                              <td>
                                <ul>
                                  {data?.attirbuteNames?.map((elem) => (
                                    <li>{elem?.attribute}</li>
                                  ))}
                                </ul>
                              </td>
                              <td>
                                <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                  {data?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop " tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleActive(data)}>
                                      <i
                                        className={`fas  ${
                                          data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                        }   m-1 pe-3`}
                                      />
                                      {data?.active ? 'Disable' : 'Enable'}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleDelete(data)}>
                                      <i className={`fas fa-trash-alt text-danger m-1 pe-3`} />
                                      {data?.deleted ? 'Undelete' : 'Delete'}
                                    </DropdownItem>
                                    <Divider className="my-2" />

                                    <Link
                                      to={buildURL(SMKLinks.EDIT_COLLEGE_META_CONTENT, {
                                        id: data?.id,
                                        refId: data?.refId,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={collegeMetaContentPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default CollegeMetaContentList;
