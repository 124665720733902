import { collegeClearCache } from 'containers/college/collegeDetailsHelper';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import * as Yup from 'yup';
import Loader from '../../../../components/Loader';
import useRequest from '../../../../hooks/useRequest';
import {
  collegeDetailSelector,
  selectCollegesCoursesMasterData,
  selectCollegesCoursesSearchData,
  selectCollegesGroups,
} from '../../selectors';
import { CURRENT_YEAR } from '../../../../utils/constants';
import {
  getCollegeCoursesSearchData,
  getCollegesCoursesMasterData,
  getCollegesGroups,
  getCollegesMasterData,
} from '../../actions';
import ToggleButton from 'components/ToggleButton';
import classNames from 'classnames';

const stateSelector = createStructuredSelector({
  collegeDetail: collegeDetailSelector,
  collegesGroups: selectCollegesGroups,
  collegesCoursesMasterData: selectCollegesCoursesMasterData,
  collegesCoursesSearchData: selectCollegesCoursesSearchData,
});

const CoursesDetails = () => {
  const { collegeDetail, collegesCoursesMasterData, collegesCoursesSearchData, collegesGroups } = useSelector(
    stateSelector
  );
  const [academicYear, setAcademicYear] = useState({ value: CURRENT_YEAR, label: CURRENT_YEAR });
  const dispatch = useDispatch();

  const [coursesDegreesActiveTab, setCoursesDegreesActiveTab] = useState('0');

  const toggleCoursesDegreesTabs = (tab) => {
    if (coursesDegreesActiveTab !== tab) {
      setCoursesDegreesActiveTab(tab);
    }
  };

  const collegeGroupsPayload = {
    page: 1,
    size: 500,
    sortBy: 'id',
    sortOrder: 0,
  };

  useEffect(() => {
    dispatch(getCollegesGroups.trigger(collegeGroupsPayload));
  }, []);

  const [collegeAcademicYearOptions, setCollegeAcademicYearOptions] = useState([]);
  const [updateCourseMatrixRequest, { loading: updateCourseMatrixLoading }] = useRequest({
    url: '/college/admin/edit/updateCourseDetails',
    method: 'POST',
    onSuccess: (data) => {
      collegeClearCache({
        urlSlug: collegeDetail?.slug,
        collegeId: collegeDetail?.id,
      });
      window.location.reload();
    },
  });

  useEffect(() => {
    if (!isEmpty(collegeDetail)) {
      const { coursesOffered } = collegeDetail;
      setCollegeAcademicYearOptions([...new Set(coursesOffered?.map((e) => e?.academicYear))]);
    }
  }, [collegeDetail, academicYear]);

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      courseDegrees: [],
      courseDegreesList: [],
    },
    onSubmit(values) {
      const data = {
        collegeId: collegeDetail?.id,
        courses: values.courseDegreesList.map((e) => ({
          ...e,
          academicYear: academicYear.value,
        })),
      };

      updateCourseMatrixRequest({ data });
    },
    validationSchema: Yup.object().shape({}),
  });

  const startYear = new Date().getFullYear() + 1;
  const years = Array.from({ length: 3 }, (_, index) => startYear - index);

  const options = years
    .sort((a, b) => a - b)
    ?.map((year) => ({
      value: year,
      label: year.toString(),
      isYearEdited: collegeAcademicYearOptions?.includes(year),
    }));

  useEffect(() => {
    if (!isEmpty(collegesCoursesMasterData)) {
      const { coursesOffered } = collegeDetail;
      const uniqueList = (type) => [...new Set(coursesOffered?.map((elem) => elem?.course[type]))];
      const filterList = (data1, data2) => data1?.filter((elem) => data2?.some((e) => e === elem?.id));
      // Course Type
      const courseTypesList = uniqueList('typeId');
      const courseTypeData = filterList(collegesCoursesMasterData, courseTypesList);
      // Course Level and Degrees
      const courseLevels = courseTypeData.flatMap((element) => element.course_level);
      const courseDegrees = courseLevels.flatMap((level) => level.course_degree);
      // Course Degrees
      const courseDegreesIdList = uniqueList('degreeTypeId');
      formik.setFieldValue('courseDegrees', filterList(courseDegrees, courseDegreesIdList));
    }
  }, [collegesCoursesMasterData, collegeDetail]);

  useEffect(() => {
    if (!isEmpty(collegesCoursesSearchData?.results)) {
      let courseDegreesList = collegesCoursesSearchData?.results?.filter((elem) =>
        collegeDetail?.coursesOffered?.some((e) => e?.course?.id === elem?.id && e?.academicYear === academicYear.value)
      );

      if (!isEmpty(collegeDetail?.coursesOffered)) {
        courseDegreesList = courseDegreesList?.map((e) => {
          const course = collegeDetail?.coursesOffered?.find((elem) => elem?.courseId === e?.id);
          return {
            ...e,
            academicYear: academicYear.value,
            firstOfferedYear: course?.firstOfferedYear,
            recognitionStatus: course?.recognitionStatus,
            recognitionDescription: course?.recognitionDescription,
            totalSeats: course?.totalSeats,
            affiliatedTo: course?.affiliatedTo,
            dateLOP: course?.dateLOP,
          };
        });
      }
      formik.setFieldValue('courseDegreesList', courseDegreesList);
    }
  }, [collegeDetail, collegesCoursesSearchData, academicYear]);

  useEffect(() => {
    dispatch(getCollegesCoursesMasterData.trigger({}));
    dispatch(
      getCollegesMasterData.trigger({
        page: 1,
        size: 500,
        sortBy: 'id',
        sortOrder: 0,
      })
    );
    dispatch(
      getCollegeCoursesSearchData.trigger({
        page: 1,
        size: 500,
        sortBy: 'id',
        sortOrder: 0,
        typeIds: formik.values.courseTypes?.map((e) => e?.id),
        levelIds: formik.values.courseLevels?.map((e) => e?.id),
        degreeTypeIds: formik.values.courseDegrees?.map((e) => e?.id),
      })
    );
  }, []);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateCourseMatrixLoading} />

      <h5 className="mb-4">Course Matrix</h5>

      <Card>
        <CardBody>
          <h6 className="mb-4 text-end">Available Years: {collegeAcademicYearOptions?.join(',')}</h6>

          <FormGroup className=" mt-2 mb-4">
            <Label htmlFor="year" className="form-label ">
              Course Matrix
            </Label>
            <div className="mb-5">
              <Select
                placeholder="Please Select Year"
                id="year"
                options={options}
                value={academicYear}
                onChange={(year) => setAcademicYear(year)}
              />
            </div>
          </FormGroup>

          <div className="mb-4">
            <Nav className="mt-4" tabs>
              {formik?.values?.courseDegrees?.map((elem, index) => (
                <NavItem key={`tab_${index}`}>
                  <NavLink
                    className={classNames({ active: coursesDegreesActiveTab === `${index}` })}
                    onClick={() => toggleCoursesDegreesTabs(`${index}`)}>
                    {elem?.displayName}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            {formik.values.courseDegrees?.map((e, index) => (
              <TabContent key={`tabContent_${index}`} activeTab={coursesDegreesActiveTab}>
                <TabPane key={`tabPane_${index}`} tabId={`${index}`}>
                  <Row className="bg-light px-2 py-4 mx-0 rounded-2 mt-4">
                    {collegesCoursesSearchData?.results
                      ?.filter((elem) => elem?.degreeTypeId === e?.id)
                      ?.map((degree) => (
                        <Col key={`_col_${degree.displayName}_`} md={4}>
                          <ToggleButton
                            label={degree?.displayName}
                            disabled
                            onChange={(e) => {
                              const selectedcourseDegreesList = formik.values.courseDegreesList || [];

                              if (e.target.checked) {
                                if (!selectedcourseDegreesList.includes(degree)) {
                                  formik.setFieldValue('courseDegreesList', [...selectedcourseDegreesList, degree]);
                                }
                              } else {
                                formik.setFieldValue(
                                  'courseDegreesList',
                                  selectedcourseDegreesList.filter((c) => c?.id !== degree?.id)
                                );
                              }
                            }}
                            checked={!isEmpty(formik.values.courseDegreesList?.find((e) => e?.id === degree?.id))}
                          />
                        </Col>
                      ))}
                  </Row>
                </TabPane>
              </TabContent>
            ))}
          </div>
        </CardBody>
      </Card>

      {!isEmpty(academicYear) &&
        !isEmpty(formik.values.courseDegreesList) &&
        formik.values.courseDegreesList
          //?.filter((t) => formik?.values?.courseDegrees[coursesDegreesActiveTab].id === t?.degreeTypeId)
          // ?.filter((t) => t.academicYear === academicYear?.value)
          ?.map(
            (elem, index) =>
              formik?.values?.courseDegrees[coursesDegreesActiveTab]?.id === elem?.degreeTypeId && (
                <Card className="mt-4" key={`courseDegreesList[${index}].name`}>
                  <CardBody>
                    <CardTitle className="h5 mb-3">
                      {index + 1}.) {elem?.name} -{' '}
                      <span>
                        <Badge className="bg-secondary">{academicYear?.value}</Badge>
                      </span>
                    </CardTitle>

                    <h5 className="mb-0"></h5>
                    <div className="table-responsive mt-2">
                      <Row className="project-list-table table-centered ">
                        <Col sm={2}>
                          <Label className="form-label  ">First Offered Year</Label>

                          <Input
                            type="text"
                            value={elem.firstOfferedYear}
                            onChange={(e) =>
                              formik.setFieldValue(`courseDegreesList[${index}].firstOfferedYear`, e.target.value)
                            }
                            placeholder="Offered Year"
                          />
                        </Col>
                        <Col sm={2}>
                          <Label className="form-label  ">Total Seats</Label>

                          <Input
                            type="text"
                            value={elem.totalSeats}
                            onChange={(e) =>
                              formik.setFieldValue(`courseDegreesList[${index}].totalSeats`, e.target.value)
                            }
                            placeholder="Total Seats"
                          />
                        </Col>

                        <Col sm={3}>
                          <Label className="form-label  ">Affiliated To</Label>

                          <Select
                            id={`affiliatedTo_${index}`}
                            options={collegesGroups.filter((gr) => !gr.isAssociation)}
                            value={collegesGroups
                              .filter((gr) => !gr.isAssociation)
                              .find((t) => t.name === elem.affiliatedTo)}
                            onChange={(e) => formik.setFieldValue(`courseDegreesList[${index}].affiliatedTo`, e.name)}
                          />
                        </Col>
                        <Col sm={2}>
                          <Label className="form-label  ">Date LOP</Label>

                          <Input
                            type="number"
                            value={elem.dateLOP}
                            onChange={(e) =>
                              formik.setFieldValue(`courseDegreesList[${index}].dateLOP`, e.target.value)
                            }
                            placeholder="Date LOP"
                          />
                        </Col>
                        <Col sm={3}>
                          <Label className="form-label  ">Recognition Status</Label>

                          <Input
                            type="text"
                            value={elem.recognitionStatus}
                            onChange={(e) =>
                              formik.setFieldValue(`courseDegreesList[${index}].recognitionStatus`, e.target.value)
                            }
                            placeholder="Recognition Status"
                          />
                        </Col>
                        <Col sm={12} className="mt-3">
                          <Label className="form-label  ">Recognition Description</Label>

                          <Input
                            type="text"
                            value={elem.recognitionDescription}
                            onChange={(e) =>
                              formik.setFieldValue(`courseDegreesList[${index}].recognitionDescription`, e.target.value)
                            }
                            placeholder="Recognition Description"
                          />
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              )
          )}

      <div className="mt-4 mb-2">
        <button
          type="submit"
          className="btn btn-primary px-4"
          onClick={() => {
            formik.handleSubmit();
          }}>
          Submit
        </button>
      </div>
    </Form>
  );
};

export default CoursesDetails;
