// ACTION TYPES
export const COLLEGE_LIST = 'smk/college/COLLEGE_LIST';

export const ADD_COLLEGE = 'smk/college/ADD_COLLEGE';
export const GET_COLLEGE_DETAIL = 'smk/college/GET_COLLEGE_DETAIL';

export const GET_COLLEGE_MASTER_DATA = 'smk/college/GET_COLLEGE_MASTER_DATA';
export const GET_COLLEGE_COURSES = 'smk/college/GET_COLLEGE_COURSES';
export const GET_COLLEGES_INFRA_LIST = 'smk/college/GET_COLLEGES_INFRA_LIST';
export const GET_COLLEGES_GROUPS = 'smk/college/GET_COLLEGES_GROUPS';
export const GET_COLLEGE_COURSES_MASTER_DATA = 'smk/college/GET_COLLEGE_COURSES_MASTER_DATA';
export const GET_COLLEGE_COURSES_SEARCH_DATA = 'smk/college/GET_COLLEGE_COURSES_SEARCH_DATA';

export const UPDATE_COLLEGE_BASIC_DETAILS = 'smk/college/UPDATE_COLLEGE_BASIC_DETAILS';
export const UPDATE_COLLEGE_CONTACT_DETAILS = 'smk/college/UPDATE_COLLEGE_CONTACT_DETAILS';
export const UPDATE_COLLEGE_ADMISSION_DETAILS = 'smk/college/UPDATE_COLLEGE_ADMISSION_DETAILS';
export const UPDATE_COLLEGE_FEE_DETAILS = 'smk/college/UPDATE_COLLEGE_FEE_DETAILS';
export const UPDATE_COLLEGE_ACADEMIC_DETAILS = 'smk/college/UPDATE_COLLEGE_ACADEMIC_DETAILS';
export const UPDATE_COLLEGE_FACILITY_DETAILS = 'smk/college/UPDATE_COLLEGE_FACILITY_DETAILS';
export const UPDATE_COLLEGE_EXTRA_CURRICULAR_DETAILS = 'smk/college/UPDATE_COLLEGE_EXTRA_CURRICULAR_DETAILS';
export const UPDATE_COLLEGE_ADDITIONAL_DETAILS = 'smk/college/UPDATE_COLLEGE_ADDITIONAL_DETAILS';
export const UPDATE_COLLEGE_SYSTEM_DETAILS = 'smk/college/UPDATE_COLLEGE_SYSTEM_DETAILS';

export const GET_COLLEGE_REVIEWS = 'SMK_ADMIN/GET_COLLEGE_REVIEWS';

export const GET_COUNTRIES = 'SMK_ADMIN/GET_COUNTRIES';
export const GET_REGIONS = 'SMK_ADMIN/GET_REGIONS';
export const GET_CITIES = 'SMK_ADMIN/GET_CITIES';
export const GET_SUB_AREAS = 'SMK_ADMIN/GET_SUB_AREAS';

export const GET_COLLEGE_ADMISSION_INQUIRIES = 'SMK_ADMIN/GET_COLLEGE_ADMISSION_INQUIRIES';
export const COLLEGE_ADMISSION_INQUIRIES_MARK_ACTION_TAKEN = 'SMK_ADMIN/COLLEGE_ADMISSION_INQUIRIES_MARK_ACTION_TAKEN';
export const COLLEGE_ADMISSION_INQUIRIES_MARK_DELETED = 'SMK_ADMIN/COLLEGE_ADMISSION_INQUIRIES_MARK_DELETED';

export const COLLEGE_REVIEW_APPROVE = 'SMK_ADMIN/COLLEGE_REVIEW_APPROVE';
export const COLLEGE_REVIEW_DELETE = 'SMK_ADMIN/COLLEGE_REVIEW_DELETE';
