import TableError from 'components/TableError';
import { invert, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
} from 'reactstrap';
import confirm from 'reactstrap-confirm';
import { SUBSCRIPTION_MODULE_TYPE, SUBSCRIPTION_STATUS_TYPES } from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import {
  buildURL,
  checkIfValueExist,
  printDateTimeTwoLines,
  printSerialNumbers,
  titleCaseIfExists,
} from '../../../utils/helper';

const UserSubscriptionsList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [subscriptionsList, setSubscriptionsList] = useState([]);

  const [userSubscriptionList, setUserSubscriptionList] = useState([]);
  const [userSubscriptionPagination, setUserSubscriptionPagination] = useState({});

  const subscriptionStatusTypes = Object.keys(SUBSCRIPTION_STATUS_TYPES)?.map((elem) => ({
    label: titleCaseIfExists(elem),
    value: SUBSCRIPTION_STATUS_TYPES[elem],
  }));

  const primaryModuleTypes = Object.keys(SUBSCRIPTION_MODULE_TYPE)?.map((elem) => ({
    label: titleCaseIfExists(elem),
    value: SUBSCRIPTION_MODULE_TYPE[elem],
  }));

  const defaultFilters = {
    status: 'all',
    primaryModule: '',
    name: '',
  };

  const [activeTab, setActiveTab] = useState('all');
  const [openRow, setOpenRow] = useState(null);

  const toggleRow = (index) => {
    setOpenRow(openRow === index ? null : index);
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [updateUserSubscriptionRequest, { loading: updateUserSubscriptionLoading }] = useRequest({
    method: 'PUT',
    onSuccess: () => {
      toast.success('User subscription expired');
      window.location.reload();
    },
    onError: () => {
      toast.error('Something went wrong');
    },
  });

  const [getSubscriptionListRequest, { loading: getSubscriptionListLoading }] = useRequest({
    url: '/subscription/admin/subscription-plan/search',
    method: 'POST',
    onSuccess: (data) => {
      const subscription_list = data?.results
        ?.map((e) => ({
          id: e?.id,
          label: `${e?.name} - ${e?.currency}`,
          value: e?.slug,
        }))
        ?.filter((item, index, self) => index === self.findIndex((t) => t.value === item.value));

      setSubscriptionsList(subscription_list);
    },
  });

  const [getuserSubscriptionListRequest, { loading: getuserSubscriptionListLoading }] = useRequest({
    url: '/subscription/admin/user-subscription/search',
    method: 'POST',
    onSuccess: (data) => {
      setUserSubscriptionList(data?.results);
      setUserSubscriptionPagination(data?.paginator);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page, inputFilters) => {
    setPageInUrl(page);
    const data = inputFilters || filters;

    const payload = {
      page,
      size: 2000,
      sortBy: 'startDate',
      sortOrder: -1,
      ...data,
      status: data?.status === 'all' ? null : checkIfValueExist(data?.status),
      primaryModule: checkIfValueExist(data?.primaryModule),
    };
    getuserSubscriptionListRequest({
      body: {
        ...payload,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1, filters);
  };

  const clearFilters = () => {
    setPageInUrl(1);
    setActiveTab('all');

    setFilters({ ...defaultFilters });
    const defaultPayload = {
      page: 1,
      size: 2000,
      sortBy: 'startDate',
      sortOrder: -1,
      ...defaultFilters,
      status: defaultFilters?.status === 'all' ? null : checkIfValueExist(defaultFilters?.status),
      primaryModule: checkIfValueExist(defaultFilters?.primaryModule),
    };
    getuserSubscriptionListRequest({
      body: {
        ...defaultPayload,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1, filters);
    getSubscriptionListRequest({
      body: {
        page: 1,
        size: 5000,
        sortBy: 'id',
        sortOrder: -1,
        active: true,
      },
    });
  }, []);

  const handleExpiredPlan = async (data) => {
    const result = await confirm({
      title: `Expire subscription`,
      message: `Are you sure, you want to expire this user subscription?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      updateUserSubscriptionRequest({
        url: `/subscription/admin/user-subscription/${data?.id}/cancel`,
        body: {
          ...data,
          endDate: null,
          status: 'expired',
        },
      });
    }
  };

  return (
    <div className="page-content">
      <Loader
        isActive={getuserSubscriptionListLoading || updateUserSubscriptionLoading || getSubscriptionListLoading}
      />

      <Container fluid>
        <Breadcrumbs title="User Subscriptions" breadcrumbItem="User Subscriptions List" />

        <div>
          <Nav tabs>
            {subscriptionStatusTypes?.map((elem, index) => {
              return (
                <NavItem key={index}>
                  <NavLink
                    className={activeTab === elem?.value?.toLowerCase() ? 'active' : ''}
                    onClick={() => {
                      setActiveTab(elem?.value?.toLowerCase());
                      const updatedFilters = { ...filters, status: elem?.value };
                      setFilters(updatedFilters);
                      handleChangePage(1, updatedFilters);
                    }}>
                    {elem?.label}
                  </NavLink>
                </NavItem>
              );
            })}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              <Card>
                <CardBody>
                  <div className="mt-4">
                    <Row>
                      <div className="col-lg-3 mb-4">
                        <label className="form-label">Primary module</label>
                        <Select
                          options={primaryModuleTypes}
                          value={primaryModuleTypes?.find((e) => e?.value === filters?.primaryModule) || null}
                          placeholder="Select Primary Module..."
                          onChange={(e) => setFilters({ ...filters, primaryModule: e?.value })}
                        />
                      </div>
                      <div className="col-lg-3 mb-4">
                        <label className="form-label">Subscription name</label>
                        <Select
                          options={subscriptionsList}
                          value={subscriptionsList?.find((e) => e?.value === filters?.name) || null}
                          placeholder="Select Subscription name..."
                          onChange={(e) => setFilters({ ...filters, name: e?.value })}
                        />
                      </div>
                    </Row>
                  </div>
                  <div className="mt-4 pt-2">
                    <a className="btn btn-primary me-4" onClick={applyFilters}>
                      Apply Filters
                    </a>
                    <a className="" onClick={clearFilters}>
                      Clear
                    </a>
                  </div>
                </CardBody>
              </Card>
            </TabPane>
          </TabContent>
        </div>

        <Row className="mb-2 align-items-center">
          <Col sm="4">
            <div className="search-box me-2 mb-2 d-inline-block">
              <div className="position-relative font-bold">
                {userSubscriptionPagination?.totalCount} User Subscriptions Found
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col lg="12">
            <div className="">
              <div className="table-responsive" style={{ minHeight: 300 }}>
                <Table className="project-list-table   table-centered table-borderless">
                  <thead>
                    <tr className="table-warning">
                      <th scope="col" style={{ width: '60px' }}>
                        S.No.
                      </th>{' '}
                      <th scope="col">Subscription ID</th>
                      <th scope="col">Plan name</th>
                      <th scope="col">Payment Done By </th>
                      <th scope="col">Payment Date</th>
                      <th scope="col">Payment Amount</th>
                      <th scope="col">Payment Details</th>
                      <th scope="col">Status</th>
                      <th scope="col">Active</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(userSubscriptionList) ? (
                      userSubscriptionList
                        ?.filter((t) => !t.subscriptionPlan?.slug.includes('free', 'free-test'))
                        ?.map((elem, index) => (
                          <React.Fragment key={index}>
                            <tr>
                              <td>{printSerialNumbers(userSubscriptionPagination)[index]}</td>
                              <td>
                                <Link
                                  to={buildURL(SMKLinks.USER_SUBSCRIPTION_DETAILS, {
                                    id: elem?.id,
                                  })}>
                                  {elem?.id}
                                </Link>
                              </td>

                              <td>
                                <div className="align-items-center">
                                  {titleCaseIfExists(
                                    invert(SUBSCRIPTION_MODULE_TYPE)[elem?.subscriptionPlan?.primaryModule]
                                  )}
                                  <br />
                                  <a
                                    target="_blank"
                                    href={
                                      buildURL(SMKLinks.EDIT_SUBSCRIPTION_PLAN, {
                                        id: elem?.subscriptionPlan?.id,
                                      }) + '?view=true'
                                    }>
                                    {elem?.subscriptionPlan?.name}-{elem?.subscriptionPlan?.currency} ({' '}
                                    {elem?.subscriptionPlan?.price})
                                  </a>
                                </div>
                              </td>
                              <td>
                                {elem?.payment?.paymentDetails?.payment?.email} <br />
                                {elem?.payment?.paymentDetails?.payment?.contact}
                                <br />
                                <button className="btn btn-link" type="button" onClick={() => toggleRow(index)}>
                                  More Details
                                </button>
                              </td>
                              <td>{printDateTimeTwoLines(elem?.startDate)}</td>

                              <td>
                                Order- {elem?.payment?.paymentDetails?.order?.status}:
                                {elem?.payment?.paymentDetails?.order?.currency}{' '}
                                {elem?.payment?.paymentDetails?.order?.amount_paid / 100}
                              </td>
                              <td>
                                {elem?.payment?.paymentDetails?.payment?.currency} {elem?.payment?.amount} <br />{' '}
                                {elem?.payment?.paymentDetails?.payment?.status}-
                                {elem?.payment?.paymentDetails?.payment?.method}-
                                {elem?.payment?.paymentDetails?.payment?.wallet}
                              </td>
                              <td>
                                <Badge
                                  className={
                                    elem?.status === 'active'
                                      ? 'bg-success'
                                      : elem?.status === 'payment_pending'
                                      ? 'bg-warning'
                                      : 'bg-danger'
                                  }>
                                  {titleCaseIfExists(elem?.status)}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={elem?.active ? 'bg-success' : 'bg-danger'}>
                                  {elem?.active ? 'Active' : 'Disabled'}
                                </Badge>
                              </td>
                              <td className="text-center">
                                {elem?.status !== 'expired' && (
                                  <UncontrolledDropdown>
                                    <DropdownToggle className="card-drop" tag="a">
                                      <i className="mdi mdi-dots-vertical font-size-18" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                      <DropdownItem onClick={() => handleExpiredPlan(elem)}>
                                        <div>
                                          <i className="mdi mdi-trash-can-outline font-size-16 text-danger me-1" />{' '}
                                          <span style={{ color: '#212529' }}>Plan Expired</span>
                                        </div>
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                )}
                              </td>
                            </tr>

                            {/* collapsed row  */}
                            <tr>
                              <td colSpan="10" style={{ padding: 0, border: 'none' }}>
                                <Collapse isOpen={openRow === index}>
                                  <div className="p-3" style={{ backgroundColor: '#f8f9fa' }}>
                                    <strong>Payment Detail:</strong>
                                    <ul>
                                      <li>id: {elem?.payment?.id}</li>
                                      <li>Payment Reference: {elem?.payment?.reference}</li>
                                    </ul>
                                  </div>
                                </Collapse>
                              </td>
                            </tr>
                          </React.Fragment>
                        ))
                    ) : (
                      <TableError />
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>

        {!isEmpty(userSubscriptionList) && (
          <Paginate pageInfo={userSubscriptionPagination} onChangePage={handleChangePage} />
        )}
      </Container>
    </div>
  );
};

export default UserSubscriptionsList;
