import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import GlobalComponents from './common/globalComponents';
import './i18n';
import * as serviceWorker from './serviceWorker';
import store, { history } from './store';
import { ApolloProvider } from '@apollo/client';
import { createApolloServer } from 'lib/apollo-server';
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from 'containers/authentication/msalConfig';

const client = createApolloServer();
const msalInstance = new PublicClientApplication(msalConfig);

const app = (
  <ApolloProvider client={client}>
    <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <GlobalComponents />
          <App />
        </ConnectedRouter>
      </Provider>
    </MsalProvider>
  </ApolloProvider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
