import { createSelector } from 'reselect';

import { initialState } from './reducer';

export const COLLEGE_STORE_KEY = 'college';

const selectCollegeSubStore = (store) => store[COLLEGE_STORE_KEY] || initialState;

export const collegeListSelector = createSelector(selectCollegeSubStore, (subState) => subState.colleges);

export const collegesPaginationSelector = createSelector(
  selectCollegeSubStore,
  (subState) => subState.collegesPagination
);

export const collegeDetailSelector = createSelector(selectCollegeSubStore, (subState) => subState.collegeDetail);

export const selectCollegesMasterData = createSelector(
  selectCollegeSubStore,
  (subState) => subState.collegesMasterData
);

export const selectCollegesInfraList = createSelector(selectCollegeSubStore, (subState) => subState.collegesInfraList);

export const selectCollegesGroups = createSelector(selectCollegeSubStore, (subState) => subState.collegesGroups);

export const selectCollegesCoursesMasterData = createSelector(
  selectCollegeSubStore,
  (subState) => subState.collegesCoursesMasterData
);
export const selectCollegesCoursesSearchData = createSelector(
  selectCollegeSubStore,
  (subState) => subState.collegesCoursesSearchData
);

export const countriesSelector = createSelector(selectCollegeSubStore, (subState) => subState.countries);

export const regionsSelector = createSelector(selectCollegeSubStore, (subState) => subState.regions);

export const citiesSelector = createSelector(selectCollegeSubStore, (subState) => subState.cities);

export const subAreasSelector = createSelector(selectCollegeSubStore, (subState) => subState.subAreas);

export const selectCollegeCourses = createSelector(selectCollegeSubStore, (subState) => subState.courses);

