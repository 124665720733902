import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import globalReducer from '../common/globalComponents/reducer';
import schoolReducer from '../containers/school/reducer';
import userReducer from '../containers/user/reducer';
import contactInquiryReducer from '../containers/inquiry/reducer';
import worksheetReducer from '../containers/worksheets/reducer';
import forumReducer from '../containers/forum/reducer';
import collegeReducer from '../containers/college/reducer';

// Front
import Layout from './layout/reducer';

// Authentication
// import Login from "./auth/login/reducer"
// import Account from "./auth/register/reducer"
// import ForgetPassword from "./auth/forgetpwd/reducer"
// import Profile from "./auth/profile/reducer"
// E-commerce
import ecommerce from './e-commerce/reducer';

// Calendar
import calendar from './calendar/reducer';

// chat
import chat from './chat/reducer';

// crypto
import crypto from './crypto/reducer';

// invoices
import invoices from './invoices/reducer';

// projects
import projects from './projects/reducer';

// tasks
import tasks from './tasks/reducer';

// contacts
import contacts from './contacts/reducer';
import locationReducer from '../containers/location/reducer';
import othersReducer from '../containers/others/reducer';

import { SCHOOL_STORE_KEY } from '../containers/school/selectors';
import { USER_STORE_KEY } from '../containers/user/selectors';
import { CONTACT_INQUIRY_KEY } from '../containers/inquiry/selectors';
import { WORKSHEET_KEY } from '../containers/worksheets/selectors';
import { FORUM_KEY } from '../containers/forum/selectors';
import { GLOBAL_STORE_KEY } from '../common/globalComponents/selectors';
import { LOCATION_STORE_KEY } from '../containers/location/selectors';
import { OTHER_STORE_KEY } from '../containers/others/selectors';
import { COLLEGE_STORE_KEY } from '../containers/college/selectors';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    // public
    Layout,
    [GLOBAL_STORE_KEY]: globalReducer,
    [SCHOOL_STORE_KEY]: schoolReducer,
    [USER_STORE_KEY]: userReducer,
    [CONTACT_INQUIRY_KEY]: contactInquiryReducer,
    [WORKSHEET_KEY]: worksheetReducer,
    [FORUM_KEY]: forumReducer,
    [LOCATION_STORE_KEY]: locationReducer,
    [OTHER_STORE_KEY]: othersReducer,
    [COLLEGE_STORE_KEY]: collegeReducer,

    // Login,
    // Account,
    // ForgetPassword,
    // Profile,
    ecommerce,
    calendar,
    chat,
    crypto,
    invoices,
    projects,
    tasks,
    contacts,
  });

export default rootReducer;
