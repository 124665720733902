import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, Badge, Input, Container, Row, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import { toast } from 'react-toastify';
import { printDateTime } from '../../../utils/helper';
import moment from 'moment';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRouter } from '../../../hooks/useRouter';
import Loader from '../../../components/Loader';
import DateRangeSelector from '../../../components/dateRangeSelector';

const ActivityHistory = (props) => {
  const router = useRouter();
  const [dateRange, setDateRange] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useEffect(() => {
    if (dateRange) {
      setStartDate(dateRange.dateRange.startDate);
      setEndDate(dateRange.dateRange.endDate);
    }
  }, [dateRange]);

  const size = 20;
  const [activities, setActivities] = useState([]);
  const [activityType, setActivityType] = useState(router?.location?.query?.activityType || '');
  const [name, setName] = useState(router?.location?.query?.name || '');
  const [getLatestUsersActivityHistoryRequest, { loading: getLatestUsersActivityHistoryLoading }] = useRequest({
    url: '/patient-tracking/admin-tools/app-latest-activity-history',
    method: 'POST',
    onSuccess: (data) => {
      setActivities(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getLatestUsersActivityHistoryRequest({
      body: {
        startDate,
        endDate,
        size,
        name,
        activityType,
      },
    });
  }, []);

  const onSearch = () => {
    getLatestUsersActivityHistoryRequest({
      body: {
        startDate,
        endDate,
        size,
        name,
        activityType,
      },
    });
  };

  // const onClear = () => {
  //   getLatestUsersActivityHistoryRequest({
  //     body: {
  //       startDate: null,
  //       endDate: null,
  //       size,
  //       name: '',
  //     },
  //   });
  // };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getLatestUsersActivityHistoryLoading} />
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title={props.t('TPZ')} breadcrumbItem={props.t('ActivityHistory')} />
          {/* React Calendar  */}
          <div className="row">
            <div className="col-md-4">
              <DateRangeSelector dateRange={dateRange} setDateRange={setDateRange} />
            </div>
            <div className="col-md-3">
              <label className="form-label">Search Activity</label>
              <Input
                type="text"
                name="activity"
                placeholder="type to search"
                value={activityType}
                onChange={(e) => {
                  setActivityType(e.target.value);
                }}
              />
            </div>
            <div className="col-md-3">
              <label className="form-label">Search Name</label>
              <Input
                type="text"
                name="search"
                placeholder="type to search"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="col-md-2">
              <Button className="pull-right mt-4" onClick={onSearch}>
                Search
              </Button>
            </div>
          </div>
          <Row>
            <Col md={12}>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th>Id</th>
                      <th>Baby Name</th>
                      <th>Baby Age</th>
                      <th>Parent Name</th>
                      <th>Activity Name</th>
                      <th>Created Date</th>
                      <th>Start Date</th>
                      <th>Timezone</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activities.map((item, key) => (
                      <tr key={`_tr_${key}`}>
                        <td>
                          <Link to="#" className="text-body font-weight-bold">
                            {item.id}
                          </Link>
                        </td>
                        <td>{`${item.first_name} ${item.last_name}`}</td>
                        <td>
                          {moment().diff(moment(item.dob), 'years') > 0
                            ? `${moment().diff(moment(item.dob), 'years')} years`
                            : `${moment().diff(moment(item.dob), 'months')} months`}
                        </td>
                        <td>{item.display_name}</td>
                        <td>{item.activity_type}</td>
                        <td>{printDateTime(item.created_date)}</td>
                        <td>{printDateTime(item.start_date)}</td>
                        <td>{item.timezone}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

ActivityHistory.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(ActivityHistory);
