import { useFormik } from 'formik';
// actions
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, InputGroup, Label, Row } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { SMKLinks } from 'utils/links';
import * as Yup from 'yup';
import { schoolClearCache } from 'containers/school/schoolDetailsHelper';
import Select from 'react-select';
import { userSelector } from '../../../../common/globalComponents/selectors';
import Loader from '../../../../components/Loader';
// components
import TextEditor from '../../../../components/TextEditor';
import ToggleButton from '../../../../components/ToggleButton';
import useRequest from '../../../../hooks/useRequest';
// constants
import { BoardsType, SchoolMasterType, SchoolShifts, SeniorSecondaryStreams } from '../../../../utils/constants';
// helpers
import { htmlDecode, titleCaseIfExists } from '../../../../utils/helper';
import { getSchoolBoards, getSchoolLevels, getSchoolsGroups } from '../../actions';
// selectors
import {
  schoolDetailSelector,
  selectSchoolBoards,
  selectSchoolLevels,
  selectSchoolsGroups,
  selectSchoolsMasterData,
} from '../../selectors';
import { isArray } from 'lodash';

const stateSelector = createStructuredSelector({
  schoolsMasterData: selectSchoolsMasterData,
  schoolBoards: selectSchoolBoards,
  schoolLevels: selectSchoolLevels,
  schoolsGroups: selectSchoolsGroups,
  schoolDetail: schoolDetailSelector,
  user: userSelector,
});

const AcademicInfo = () => {
  const { user, schoolsMasterData, schoolBoards, schoolLevels, schoolsGroups, schoolDetail } = useSelector(
    stateSelector
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const [gradeError, setGradeError] = useState('');

  const gradeSelectFunc = (data) => {
    const classesArr = [];
    data
      .slice()
      .sort((a, b) => a.level - b.level)
      ?.map((e) => e?.classes?.map((c) => classesArr?.push(c)));

    const unique2 = classesArr.filter((obj, index) => {
      return index === classesArr.findIndex((o) => obj.level === o.level && obj.name === o.name);
    });

    return unique2?.sort((a, b) => a?.level - b?.level);
  };

  const gradeSelectArray = gradeSelectFunc(schoolLevels);

  const getInitialValues = () => {
    const { academicInfo, schoolLevels, international, boards, schoolGroups } = schoolDetail;

    const values = {};
    // Shifts
    values.schoolShift = academicInfo?.shift || [];
    // School Hours
    values.schoolHours = htmlDecode(academicInfo?.schoolHours[0]?.hours) || '';
    // Language Instruction
    values.languages = academicInfo?.languages || [];
    // School Levels
    values.schoolLevels = schoolLevels || [];
    // School Senior Secondary Streams
    values.seniorSecondaryStreams = academicInfo?.seniorSecondaryStreams || [];
    // Board Type
    values.boardType = international || 0;
    // Boards
    values.boards = boards || [];
    // Board Cirriculum Details
    values.curriculumDetails = academicInfo?.curriculumDetails || [];
    values.lowestGrade =
      gradeSelectArray
        ?.filter((e) => e?.name == schoolDetail?.lowestGrade)
        ?.map((e) => ({
          ...e,
          label: e?.name,
          value: e?.name,
        })) || [];
    values.highestGrade =
      gradeSelectArray
        ?.filter((e) => e?.name == schoolDetail?.highestGrade)
        ?.map((e) => ({
          ...e,
          label: e?.name,
          value: e?.name,
        })) || [];
    // School Asso.
    // filter out parent
    values.schoolGroups =
      schoolGroups?.map((gr) => ({
        ...gr.schoolGroup,
        value: gr?.schoolGroup?.id,
        label: gr?.schoolGroup?.name,
      })) || [];
    // Syllabus Details
    values.syllabusDetails = academicInfo?.syllabusDetails;
    return values;
  };

  const [updateSchoolAcademicDetailsRequest, { loading: updateSchoolAcademicDetailsLoading }] = useRequest({
    url: '/school/admin/edit/updateAcademicDetails',
    method: 'POST',
    onSuccess: (data) => {
      schoolClearCache({
        urlSlug: schoolDetail?.slug,
        schoolId: schoolDetail?.id,
      });
      window.location.reload();
    },
  });

  const isEmptyValue = (val) => {
    return val || undefined;
  };

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: getInitialValues(),
    onSubmit(values) {
      updateSchoolAcademicDetailsRequest({
        data: {
          schoolId: isEmptyValue(schoolDetail?.id),
          academicInfo: {
            shift: isEmptyValue(values.schoolShift),
            schoolHours: [
              {
                board: 'All',
                hours: isEmptyValue(values.schoolHours),
                level: 'All',
              },
            ],
            languages: isEmptyValue(values.languages),
            seniorSecondaryStreams: isEmptyValue(values.seniorSecondaryStreams),
            curriculumDetails: isEmptyValue(values.curriculumDetails),
            syllabusDetails: isEmptyValue(values.syllabusDetails),
          },
          schoolLevels: isEmptyValue(values.schoolLevels),
          international: isEmptyValue(values.boardType),
          boards: isEmptyValue(values.boards),
          schoolGroups: values.schoolGroups ? values.schoolGroups : [],
          lowestGrade: isEmptyValue(values?.lowestGrade?.value),
          highestGrade: isEmptyValue(values?.highestGrade?.value),
        },
      });
    },
    validationSchema: Yup.object().shape({
      // name: Yup.string().required('Please provide school name'),
    }),
  });

  const schoolGroupsPayload = {
    page: 1,
    size: 500,
    sortBy: 'id',
    sortOrder: 0,
  };
  useEffect(() => {
    dispatch(getSchoolBoards.trigger());
    dispatch(getSchoolsGroups.trigger(schoolGroupsPayload));
    dispatch(
      getSchoolLevels.trigger({
        country: schoolDetail?.address?.country?.name,
      })
    );
  }, []);

  // console.log(formik.values);

  const handleToggleShifts = (checked, shift) => {
    let array = formik.values.schoolShift.slice();
    if (checked) {
      array = [...array, shift];
    } else {
      array = array.filter((s) => s.name.toLowerCase() !== shift.name.toLowerCase());
    }
    formik.setFieldValue('schoolShift', array);
  };

  const handleToggleLanguage = (checked, language) => {
    let array = formik.values.languages.slice();
    if (checked) {
      array = [...array, language];
    } else {
      array = array.filter((l) => l.name.toLowerCase() !== language.name.toLowerCase());
    }
    formik.setFieldValue('languages', array);
  };

  const handleToggleLevel = (checked, level) => {
    let array = formik.values.schoolLevels.slice();
    if (checked) {
      // array = [...array, { schoolLevel: level, classes: [] }];
      // select all classes of the level on level selected
      array = [...array, { schoolLevel: level, classes: level.level === 2 ? [] : level.classes }];
    } else {
      array = array.filter((l) => l.schoolLevel.id !== level.id);
    }
    formik.setFieldValue('schoolLevels', array);
  };

  const handleToggleClass = (checked, level, levelClass) => {
    //  all selected levels
    const lvls = formik.values.schoolLevels.slice();
    // level of current class
    const index = lvls.findIndex((l) => l.schoolLevel.id === level.id);

    // check if level not selected / no class selected
    let cl = index === -1 || isEmpty(lvls[index].classes) ? [] : lvls[index].classes;

    if (checked) {
      cl = [...cl, levelClass];
    } else {
      cl = cl.filter((c) => c.name.toLowerCase() !== levelClass.name.toLowerCase());
    }

    if (index === -1) {
      lvls.push({ schoolLevel: level, classes: cl });
    } else {
      lvls[index] = { ...lvls[index], classes: cl };
    }
    formik.setFieldValue('schoolLevels', lvls);
  };

  const handleToggleStream = (checked, stream) => {
    let array = formik.values.seniorSecondaryStreams;
    if (checked) {
      array = [...array, stream];
    } else {
      array = array.filter((s) => s.name.toLowerCase() !== stream.name.toLowerCase());
    }
    formik.setFieldValue('seniorSecondaryStreams', array);
  };

  const handleToggleCurriculumBoard = (checked, board) => {
    let boardsArray = formik.values.boards;
    let curriculumArray = formik.values.curriculumDetails;
    if (checked) {
      // find board in academicInfo?.curriculumDetails
      const cc = schoolDetail?.academicInfo?.curriculumDetails?.find((c) => c.curriculum === board.name);

      // add board
      boardsArray = [...boardsArray, board];
      // initialize curriculum fields for board
      curriculumArray = [
        ...curriculumArray,
        {
          curriculum: board.name,
          levels: [],
          affiliationNumber: '',
          affiliationStartDate: '',
          affiliationType: '',
          classes: [],
          ...(cc || {}),
        },
      ];
    } else {
      // remove board
      boardsArray = boardsArray.filter((b) => b.id !== board.id);
      // remove curriculum fields for board
      curriculumArray = curriculumArray.filter((c) => c.curriculum !== board.name);
    }
    formik.setFieldValue('boards', boardsArray);
    formik.setFieldValue('curriculumDetails', curriculumArray);
  };

  const handleToggleSubLevel = (checked, curriculumDetail, subLevel) => {
    const array = formik.values.curriculumDetails.slice();
    const index = array.findIndex((crr) => crr.curriculum === curriculumDetail?.curriculum);
    if (checked) {
      array[index] = {
        ...array[index],
        levels: [...array[index].levels, subLevel],
      };
    } else {
      array[index] = {
        ...array[index],
        levels: array[index].levels.filter((l) => l.name !== subLevel.name),
      };
    }
    formik.setFieldValue('curriculumDetails', array);
  };

  const handleCurriculumUpdate = (key, board, value) => {
    const array = formik.values.curriculumDetails.slice();
    const index = array.findIndex((c) => c.curriculum === board.name);
    array[index] = { ...array[index], [key]: value };
    formik.setFieldValue('curriculumDetails', array);
  };

  const handleToggleGroup = (checked, group) => {
    let array = formik.values.schoolGroups.slice();

    // console.log(formik.values.schoolGroups);

    if (checked) {
      array = [...array, group];
    } else {
      array = array.filter((gr) => gr.id !== group.id);
    }

    // console.log(group);

    formik.setFieldValue('schoolGroups', array);
  };

  const renderLanguages = () => {
    const langs = schoolsMasterData.filter((data) => data.type === SchoolMasterType.LANGUAGE_OF_INSTRUCTION);

    return (
      <Card>
        <CardBody>
          <CardTitle className="mb-4">Language of Instruction</CardTitle>

          <Row>
            {langs.map((lang, i) => {
              return (
                <Col key={`lang_${i}`} md={2}>
                  <ToggleButton
                    label={lang.name}
                    onChange={(e) => handleToggleLanguage(e.target.checked, lang)}
                    checked={formik.values.languages.some((l) => l.name.toLowerCase() === lang.name.toLowerCase())}
                  />
                </Col>
              );
            })}
          </Row>
        </CardBody>
      </Card>
    );
  };

  useEffect(() => {
    if (!isEmpty(formik.values.highestGrade)) {
      formik?.values?.lowestGrade?.level > formik?.values?.highestGrade?.level
        ? setGradeError('Please select a grade higher than the lowest')
        : setGradeError('');
    }
  }, [formik.values.highestGrade]);

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateSchoolAcademicDetailsLoading} />

      <h5 className="mb-4">Academic Info</h5>

      <Card>
        <CardBody>
          <FormGroup className="mb-4">
            <CardTitle htmlFor="schooldesc" className="form-label mb-2">
              School Shift
            </CardTitle>
            <Row>
              {SchoolShifts.map((shift, i) => (
                <Col key={`shift_${i}`} md={3}>
                  <ToggleButton
                    label={shift.name}
                    onChange={(e) => handleToggleShifts(e.target.checked, shift)}
                    checked={formik.values.schoolShift.some((s) => s.name.toLowerCase() === shift.name.toLowerCase())}
                  />
                </Col>
              ))}
            </Row>
          </FormGroup>

          <FormGroup className="mb-4">
            <Label htmlFor="schooldesc" className="form-label ">
              School Hours
            </Label>
            <TextEditor
              simple
              initialValue={formik.values.schoolHours}
              onChange={(val) => formik.setFieldValue('schoolHours', val)}
            />
          </FormGroup>
        </CardBody>
      </Card>

      {renderLanguages()}

      <Card>
        <CardBody>
          <CardTitle>School Levels</CardTitle>

          <Row className="mt-5">
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="lowestGrade" className="form-label  ">
                  Lowest grade
                </Label>

                <Select
                  id="lowestGrade"
                  placeholder="Select an option"
                  options={gradeSelectArray?.map((e) => ({
                    ...e,
                    label: e?.name,
                    value: e?.name,
                  }))}
                  value={formik.values.lowestGrade}
                  onChange={(val) => formik.setFieldValue('lowestGrade', val)}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="highestGrade" className="form-label  ">
                  Highest grade
                </Label>

                <Select
                  id="highestGrade"
                  placeholder="Select an option"
                  options={gradeSelectArray?.map((e) => ({
                    ...e,
                    label: e?.name,
                    value: e?.name,
                  }))}
                  value={formik.values.highestGrade}
                  onChange={(val) => {
                    formik.setFieldValue('highestGrade', val);
                    setGradeError('');
                  }}
                />
                {!isEmpty(gradeError) && <p className="text-start text-danger mb-0 mt-2">{gradeError}</p>}
              </FormGroup>
            </Col>
          </Row>

          <div className="table-responsive mt-4">
            <table className="table table-responsive table-hover">
              <thead>
                <tr>
                  <th width="25%">Levels</th>
                  <th width="75%">Classes</th>
                </tr>
              </thead>

              <tbody>
                {!isEmpty(schoolLevels) &&
                  schoolLevels
                    .slice()
                    .sort((a, b) => a.level - b.level)
                    .map((level, i) => (
                      <tr key={`level_${i}`}>
                        <td>
                          <ToggleButton
                            label={level.name}
                            onChange={(e) => handleToggleLevel(e.target.checked, level)}
                            checked={formik.values.schoolLevels.some((l) => l.schoolLevel.id === level.id)}
                          />
                        </td>
                        <td>
                          <Row>
                            {!isEmpty(level.classes) &&
                              level.classes.map((c, i) => (
                                <Col key={`level_class_${i}`} md={4}>
                                  <ToggleButton
                                    label={c.name}
                                    onChange={(e) => handleToggleClass(e.target.checked, level, c)}
                                    checked={formik.values.schoolLevels.some(
                                      (l) =>
                                        l.schoolLevel.id === level.id &&
                                        !isEmpty(l.classes) &&
                                        l.classes.some((cl) => cl.name.toLowerCase() === c.name.toLowerCase())
                                    )}
                                  />
                                </Col>
                              ))}
                          </Row>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>

      {/* Show this block only if Pre School Level is selected */}
      {formik.values.schoolLevels?.some((l) => l.schoolLevel.level === 2) && (
        <Card>
          <CardBody>
            <CardTitle className="mb-4">Preschool Curriculum</CardTitle>

            <Row>
              {!isEmpty(schoolBoards) &&
                schoolBoards
                  .filter((b) => b.preschool)
                  .map((board, i) => {
                    return (
                      <Col key={`board_pre_${i}`} md={4}>
                        <ToggleButton
                          label={board.name}
                          onChange={(e) => handleToggleCurriculumBoard(e.target.checked, board)}
                          checked={formik.values.boards.some((b) => b.name === board.name)}
                        />
                      </Col>
                    );
                  })}
            </Row>
          </CardBody>
        </Card>
      )}

      {/* Show this block only if Senior High School Level is sleected */}
      {formik.values.schoolLevels.some((l) => l.schoolLevel.name?.includes('Senior Secondary School (XI-XII)')) && (
        <Card>
          <CardBody>
            <CardTitle className="mb-4">School Senior Secondary Streams</CardTitle>

            <Row>
              {SeniorSecondaryStreams.map((stream, i) => {
                return (
                  <Col key={`stream_${i}`} md={4}>
                    <ToggleButton
                      label={stream.name}
                      onChange={(e) => handleToggleStream(e.target.checked, stream)}
                      checked={formik.values.seniorSecondaryStreams.some(
                        (s) => s.name.toLowerCase() === stream.name.toLowerCase()
                      )}
                    />
                  </Col>
                );
              })}
            </Row>
          </CardBody>
        </Card>
      )}

      <Card>
        <CardBody>
          <CardTitle className="mb-4">Board Type</CardTitle>

          <div>
            {Object.keys(BoardsType).map((key, i) => (
              <div key={`type_${i}`} className="form-check mb-3 d-inline-block me-4">
                <input
                  type="radio"
                  id={key}
                  name="school_board_type"
                  className="form-check-input"
                  checked={formik.values.boardType === BoardsType[key]}
                  onChange={() => formik.setFieldValue('boardType', BoardsType[key])}
                />
                <label className="form-check-label" htmlFor={key}>
                  {titleCaseIfExists(key)}
                </label>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle className="mb-4">Curriculum Boards</CardTitle>

          <Row>
            {!isEmpty(schoolBoards) &&
              schoolBoards
                .filter((b) => !b.preschool)
                .map((board, i) => {
                  return (
                    <Col key={`board_${i}`} md={4}>
                      <ToggleButton
                        label={board.name}
                        onChange={(e) => handleToggleCurriculumBoard(e.target.checked, board)}
                        checked={formik.values.boards.some((b) => b.id === board.id)}
                      />
                    </Col>
                  );
                })}
          </Row>
        </CardBody>
      </Card>

      {formik.values.boards.map((board, i) => {
        const details = formik.values.curriculumDetails.find((cd) => cd.curriculum === board.name);

        return (
          <Card key={`board_curr_${i}`}>
            <CardBody>
              <CardTitle className="mb-4">{board.name}</CardTitle>

              {!isEmpty(schoolBoards) && !isEmpty(schoolBoards.find((b) => b.id === board.id)?.subLevels) && (
                <Row className="mb-4">
                  <Col sm={12}>
                    <h6 className="pb-2">Sub Levels</h6>
                  </Col>
                  {!isEmpty(schoolBoards) &&
                    schoolBoards
                      .find((b) => b.id === board.id)
                      .subLevels.map((lvl, i) => (
                        <Col key={`sublvl_${i}`} md={6}>
                          <ToggleButton
                            label={lvl.name}
                            onChange={(e) => handleToggleSubLevel(e.target.checked, details, lvl)}
                            checked={details?.levels.some((l) => l.name === lvl.name)}
                          />
                        </Col>
                      ))}
                </Row>
              )}

              <Row>
                <Col md={4}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="school_aff_num" className="form-label  ">
                      Affiliation Number
                    </Label>
                    <Input
                      id="school_aff_num"
                      type="text"
                      className="form-control"
                      placeholder="Affiliation Number..."
                      label="Affiliation Number"
                      value={details?.affiliationNumber}
                      onChange={(e) => handleCurriculumUpdate('affiliationNumber', board, e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-4">
                    <Label for="startDate">Affiliation Start Date</Label>
                    <InputGroup id="startDate">
                      <DatePicker
                        selected={
                          details?.affiliationStartDate
                            ? moment(
                                isArray(details?.affiliationStartDate)
                                  ? details?.affiliationStartDate[0]
                                  : details?.affiliationStartDate
                              ).toDate()
                            : ''
                        }
                        className="form-control"
                        showYearDropdown
                        placeholderText="Select date"
                        autoComplete="off"
                        minDate={moment()
                          .subtract(100, 'years')
                          .toDate()}
                        maxDate={moment().toDate()}
                        onChange={(date) => handleCurriculumUpdate('affiliationStartDate', board, date)}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup className="mb-4">
                    <Label htmlFor="school_aff_type" className="form-label  ">
                      Affiliation Type
                    </Label>
                    <Input
                      id="school_aff_type"
                      type="text"
                      className="form-control"
                      placeholder="Affiliation Type"
                      label="Affiliation Type"
                      value={details?.affiliationType}
                      onChange={(e) => handleCurriculumUpdate('affiliationType', board, e.target.value)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        );
      })}

      <Card>
        <CardBody>
          <CardTitle className="mb-4">School Associations</CardTitle>

          <Row>
            {schoolsGroups
              .filter((gr) => gr.isAssociation)
              .map((gr, i) => (
                <Col key={`gr_${i}`} md={6}>
                  <ToggleButton
                    label={`${gr.name} (${gr.shortName ? gr.shortName : ''})`}
                    onChange={(e) => handleToggleGroup(e.target.checked, gr)}
                    checked={formik.values.schoolGroups.some((g) => g.id === gr.id)}
                  />
                </Col>
              ))}
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Syllabus Details</CardTitle>

          <FormGroup className="mb-4">
            <TextEditor
              simple
              initialValue={formik.values.syllabusDetails}
              onChange={(val) => formik.setFieldValue('syllabusDetails', val)}
            />
          </FormGroup>
        </CardBody>
      </Card>

      {(user?.entityPermissions?.school.admin ||
        (schoolDetail.editable &&
          (schoolDetail?.createdBy?.id === user.id || schoolDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Information
          </Button>
          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.SCHOOL_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

AcademicInfo.propTypes = {};

export default AcademicInfo;
