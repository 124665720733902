import React from 'react';
import { Table } from 'reactstrap';

const Branding = () => {
  return (
    <div>
      <div className="container">
        <div className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
          <div className="table-responsive" style={{ minHeight: 300 }}>
            <h2 className="text-center">Our Social media pages</h2>
            <Table className="project-list-table   table-centered table-borderless">
              <thead>
                <tr className="table-warning">
                  <th>Type</th>
                  <th>TPZ</th>
                  <th>SMK</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Facebook Page</td>
                  <td>
                    <a href="https://www.facebook.com/TheParentZOfficial/">
                      https://www.facebook.com/TheParentZOfficial/
                    </a>
                  </td>
                  <td>
                    <a href="https://www.facebook.com/schoolmykids/">https://www.facebook.com/schoolmykids/</a>
                  </td>
                </tr>
                <tr>
                  <td>Facebook Group</td>
                  <td>
                    <a href="https://www.facebook.com/groups/409023036225231/">Parenting Tips and Advice</a>
                  </td>
                  <td>
                    <a href="https://www.facebook.com/groups/1386468741398641/">
                      Schools - Find Top Schools in India and Asia
                    </a>
                  </td>
                </tr>
                <tr>
                  <td> </td>
                  <td> </td>
                  <td>
                    <a href="https://www.facebook.com/groups/310816119349596/">
                      Interactive Periodic Table of Elements @schoolmykids.com
                    </a>
                  </td>
                </tr>
                <tr>
                  <td> </td>
                  <td> </td>
                  <td>
                    <a href="https://www.facebook.com/groups/118182522145774/">
                      Medical and Dental Colleges In India - NEET/AIIMS/JIPMER
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>pinterest</td>
                  <td>
                    <a href="https://in.pinterest.com/TheParentZ/">https://in.pinterest.com/TheParentZ/</a>
                  </td>
                  <td>
                    <a href="https://in.pinterest.com/schoolmykids/">https://in.pinterest.com/schoolmykids/</a>
                  </td>
                </tr>
                <tr>
                  <td>Instagram</td>
                  <td>
                    <a href="https://www.instagram.com/TheParentZOfficial/">
                      https://www.instagram.com/TheParentZOfficial/
                    </a>
                  </td>
                  <td>
                    <a href="https://www.instagram.com/schoolmykids/">https://www.instagram.com/schoolmykids/</a>
                  </td>
                </tr>
                <tr>
                  <td>Youtube</td>
                  <td>
                    <a href="https://www.youtube.com/@TheParentZOfficial">
                      https://www.youtube.com/@TheParentZOfficial
                    </a>
                  </td>
                  <td>
                    <a href="https://www.youtube.com/@SchoolMyKidsOfficial">
                      https://www.youtube.com/@SchoolMyKidsOfficial
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>twitter</td>
                  <td>
                    <a href="https://twitter.com/TheParentZ">https://twitter.com/TheParentZ</a>
                  </td>
                  <td>
                    <a href="https://www.twitter.com/schoolmykids">https://www.twitter.com/schoolmykids</a>
                  </td>
                </tr>
                <tr>
                  <td>Linkedin</td>
                  <td>
                    <a href="https://www.linkedin.com/company/the-parentz">
                      https://www.linkedin.com/company/the-parentz
                    </a>
                  </td>
                  <td>
                    <a href="https://www.linkedin.com/company/schoolmykids/">
                      https://www.linkedin.com/company/schoolmykids/
                    </a>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Branding;
