const CONTACT_INQUIRY_LIST = 'SMK_ADMIN/CONTACT_INQUIRY_LIST';
const CONTACT_INQUIRY_SEARCH = 'SMK_ADMIN/CONTACT_INQUIRY_SEARCH';

const CONTACT_INQUIRY_ACTION_TAKEN = 'SMK_ADMIN/CONTACT_INQUIRY_ACTION_TAKEN';
const CONTACT_INQUIRY_DELETE = 'SMK_ADMIN/CONTACT_INQUIRY_DELETE';

module.exports = {
  CONTACT_INQUIRY_LIST,
  CONTACT_INQUIRY_SEARCH,
  CONTACT_INQUIRY_ACTION_TAKEN,
  CONTACT_INQUIRY_DELETE,
};
