import produce from 'immer';
import { getCities, getCountries, getRegions, getDistricts, getSubAreas } from './actions';

export const initialState = {
  countries: [],
  regions: [],
  districts: [],
  cities: [],
  subAreas: [],
};

const othersReducer = produce((draft, action) => {
  switch (action.type) {
    case getCountries.SUCCESS: {
      const { results } = action.payload;
      draft.countries = results.map((r) => ({ ...r, label: r.name, value: r.id }));
      break;
    }
    case getRegions.SUCCESS: {
      const { results } = action.payload;
      draft.regions = results.map((r) => ({ ...r, label: r.name, value: r.id }));
      break;
    }
    case getDistricts.SUCCESS: {
      const { results } = action.payload;
      draft.districts = results.map((r) => ({ ...r, label: r.name, value: r.id }));
      break;
    }
    case getCities.SUCCESS: {
      const { results } = action.payload;
      draft.cities = results.map((r) => ({ ...r, label: r.name, value: r.id }));
      break;
    }
    case getSubAreas.SUCCESS: {
      const { results } = action.payload;
      draft.subAreas = results.map((r) => ({ ...r, label: r.name, value: r.id }));
      break;
    }

    default:
      break;
  }
}, initialState);

export default othersReducer;
