import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import { useFormik } from 'formik';
import { useRouter } from 'hooks/useRouter';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { HINDI_BABY_NAMES_GENDER_ARRAY, LANGUAGE_ARRAY_WITH_STRING, LANGUAGE_VALUES_STRING } from 'utils/constants';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { buildURL, titleCaseIfExists } from '../../../utils/helper';
import { SMKLinks } from '../../../utils/links';
import PageDetailCard from 'components/PageDetailsCard';

const TranslateBabyName = () => {
  const router = useRouter();
  const history = useHistory();

  toast.configure();

  const { id: babyNameId } = useParams();

  const defaultFilters = {
    ...(router?.query?.page ? { page: router?.query?.page } : {}),
    ...(router?.query?.origin ? { origin: router?.query?.origin } : {}),
    ...(router?.query?.religion ? { religion: router?.query?.religion } : {}),
  };

  const languageValue = LANGUAGE_VALUES_STRING?.hindi;
  const [babynamesList, setBabynamesList] = useState([]);

  const [babyDetails, setBabyDetails] = useState({});
  const [nakshatraList, setNakshatraList] = useState([]);
  const [rashiList, setRashiList] = useState([]);
  const [religionList, setReligionList] = useState([]);
  const [originList, setOriginList] = useState([]);
  const [meaningWordsList, setMeaningWordsList] = useState([]);

  const nextBabyName = () => {
    const indexofCurrentBabyName = babynamesList?.findIndex((e) => Number(e?.id) === Number(babyNameId));

    if (indexofCurrentBabyName < babynamesList?.length - 1) {
      return babynamesList[indexofCurrentBabyName + 1]?.id;
    }
    toast.error('Baby names list has been ended.');
    return babynamesList[0]?.id;
  };

  const [translateBabyNameRequest, { loading: translateBabyNameLoading }] = useRequest({
    url: `/baby-name/admin`,
    method: 'POST',
    onSuccess: (data) => {
      if (data?.message?.message) {
        toast.error(data?.message?.message);
      } else {
        toast.success('Sucess! Baby name has translated');

        const nextBabyNameID = nextBabyName();
        let url;
        if (nextBabyNameID) {
          url = buildURL(SMKLinks.TRANSLATE_BABY_NAME, { id: nextBabyNameID }, defaultFilters);
        } else {
          url = buildURL(
            SMKLinks.BABY_NAME_LISTING,
            {},
            { ...defaultFilters, ...(defaultFilters?.page ? { page: +defaultFilters?.page + 1 } : {}) }
          );
        }
        history.replace(url);
      }
    },
    onError: (err) => {
      toast.error(err?.message?.message);
      console.log(err);
    },
  });

  const [editBabyNameRequest, { loading: editBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/${babyNameId}`,
    method: 'GET',
    onSuccess: (data) => {
      setBabyDetails(data);
    },
    onError: (err) => {
      console.log(`Error occurred: ${err}`);
    },
  });

  const [getBabyNamesRequest, { loading: getBabyNamesLoading }] = useRequest({
    url: `/baby-name/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      const results = data?.results?.filter((e) => isEmpty(e?.translations));
      const idIndex = results?.findIndex((e) => +e?.id === +babyNameId);
      const dataList = results?.slice(idIndex + 1, 1000);
      setBabynamesList(dataList);
    },
  });

  const [getbabyNameMetaRequest, { loading: getbabyNameMetaLoading }] = useRequest({
    url: `/baby-name/admin/metaInfo/search`,
    method: 'POST',
    onSuccess: (data) => {
      const reply = data?.results;
      setNakshatraList(
        reply
          .filter((t) => t.type === 1 && t.language === languageValue)
          .map((r) => ({ ...r, label: r.name, value: r.id }))
      );
      setRashiList(
        reply
          .filter((t) => t.type === 2 && t.language === languageValue)
          .map((r) => ({ ...r, label: r.name, value: r.id }))
      );
      setReligionList(
        reply
          .filter((t) => t.type === 3 && t.language === languageValue)
          .map((r) => ({ ...r, label: r.name, value: r.id }))
      );
      setOriginList(
        reply
          .filter((t) => t.type === 4 && t.language === languageValue)
          .map((r) => ({ ...r, label: r.name, value: r.id }))
      );
      setMeaningWordsList(
        reply
          .filter((t) => t.type === 5 && t.language === languageValue)
          .map((r) => ({ ...r, label: r.name, value: r.id }))
      );
    },
  });

  const getInitialFormValues = () => {
    const hindiNakshatraValues = () => {
      const hindiNakshatra = nakshatraList?.find(
        (e) =>
          e?.slug === babyDetails?.nakshatra?.slug &&
          e?.language === languageValue &&
          e?.type === babyDetails?.nakshatra?.type
      );
      return {
        label: titleCaseIfExists(hindiNakshatra?.name),
        value: hindiNakshatra?.id,
        ...hindiNakshatra,
      };
    };
    const hindiRashiValues = () => {
      const hindiRashi = rashiList?.find(
        (e) =>
          e?.slug === babyDetails?.rashi?.slug && e?.language === languageValue && e?.type === babyDetails?.rashi?.type
      );
      return {
        label: titleCaseIfExists(hindiRashi?.name),
        value: hindiRashi?.id,
        ...hindiRashi,
      };
    };

    const values = { ...babyDetails };
    values.name = babyDetails?.name;
    values.slug = babyDetails?.slug;
    values.meaning = babyDetails?.meaning;
    values.description = babyDetails?.description;
    values.approved = babyDetails?.approved;

    values.language = {
      label: 'Hindi',
      value: 'hi',
      ...babyDetails?.language,
    };
    values.gender = HINDI_BABY_NAMES_GENDER_ARRAY?.find((e) => e?.value === babyDetails?.genderValue);
    values.rashi = hindiRashiValues();
    values.nakshatra = hindiNakshatraValues();

    values.origins = babyDetails?.origins?.map((origin) => {
      const hindiOrigin = originList?.find(
        (e) => e?.slug === origin?.slug && e?.language === languageValue && e?.type === origin?.type
      );
      return (
        !isEmpty(hindiOrigin) && {
          label: titleCaseIfExists(hindiOrigin?.name),
          value: hindiOrigin?.id,
          ...hindiOrigin,
        }
      );
    });
    values.religions = babyDetails?.religions?.map((religion) => {
      const hindiReligion = religionList?.find(
        (e) => e?.slug === religion?.slug && e?.language === languageValue && e?.type === religion?.type
      );
      return (
        !isEmpty(hindiReligion) && {
          label: titleCaseIfExists(hindiReligion?.name),
          value: hindiReligion?.id,
          ...hindiReligion,
        }
      );
    });
    values.meanings = babyDetails?.meanings?.map((meaning) => {
      const hindiMeaning = meaningWordsList?.find(
        (e) => e?.slug === meaning?.slug && e?.language === languageValue && e?.type === meaning?.type
      );
      return (
        !isEmpty(hindiMeaning) && {
          label: titleCaseIfExists(hindiMeaning?.name),
          value: hindiMeaning?.id,
          ...hindiMeaning,
        }
      );
    });

    if (values.nakshatra.value === undefined) {
      values.nakshatra = [];
    }
    if (values.rashi.value === undefined) {
      values.rashi = [];
    }
    // if (values?.gender?.value === undefined) {
    //     values?.gender = []
    // }

    return values;
  };

  const isEmptyValue = (val) => (!isEmpty(val) ? val : undefined);

  const checkValidValue = (val) => val?.filter((e) => e !== false);

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialFormValues(),
    enableReinitialize: true,
    onSubmit: (values) => {
      const babynameUpdatePayload = {
        language: values?.language?.value,
        name: isEmptyValue(values?.name),
        slug: isEmptyValue(values?.slug),
        meaning: isEmptyValue(values?.meaning),
        spell: isEmptyValue(values?.spell),
        syllable: isEmptyValue(values?.syllable),
        phonetic: isEmptyValue(values?.phonetic),
        pronunciation: isEmptyValue(values?.pronunciation),

        genderValue: values?.gender?.value || 0,

        rashi: isEmptyValue(values.rashi),
        nakshatra: isEmptyValue(values.nakshatra),
        religions: checkValidValue(values.religions),
        meanings: checkValidValue(values.meanings),
        origins: checkValidValue(values.origins),

        approved: values.approved,
        active: values.active,
      };
      translateBabyNameRequest({
        body: {
          ...babynameUpdatePayload,
          translations: [+babyNameId],
        },
      });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Please provide babyname name')
        .min(2, 'Name should be at least 2 characters long')
        .max(128, 'Name should be at most 128 characters long')
        .nullable(true),
      description: Yup.string()
        // .required('Please provide baby name description')
        .min(2, 'Description should be at least 2 characters long')
        .max(1024, 'Description should be at most 1024 characters long')
        .nullable(true),
      gender: Yup.object()
        .required('Please provide gender')
        .nullable(true),
      // meanings: Yup.array()
      // .required()
      // .min(1, 'Please provide meanings'),
      origins: Yup.array()
        .required()
        .min(1, 'Please provide origin'),
      // nakshatra: Yup.object()
      //   .required('Please provide nakshatra')
      //   .nullable(true),
      religions: Yup.array()
        .required()
        .min(1, 'Please provide religion'),
      meaning: Yup.string()
        .required()
        .min(2, 'Meaning should be at least 2 characters long')
        .max(512, 'Meaning should be at most 512 characters long')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (babyNameId) {
      editBabyNameRequest();
      getBabyNamesRequest({
        body: {
          page: 1,
          size: 20,
          sortBy: 'id',
          sortOrder: -1,
          language: LANGUAGE_VALUES_STRING.english,
          ...defaultFilters,
        },
      });
    }
  }, [babyNameId]);

  useEffect(() => {
    getbabyNameMetaRequest({
      body: {
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: -1,
        language: LANGUAGE_VALUES_STRING.hindi,
      },
    });
  }, []);

  const skipBabyName = () => {
    const nextBabyNameID = nextBabyName();
    let url;
    if (nextBabyNameID) {
      url = buildURL(SMKLinks.TRANSLATE_BABY_NAME, { id: nextBabyNameID }, defaultFilters);
    } else {
      url = buildURL(
        SMKLinks.BABY_NAME_LISTING,
        {},
        { ...defaultFilters, ...(defaultFilters?.page ? { page: +defaultFilters?.page + 1 } : {}) }
      );
    }
    history.replace(url);
  };

  return (
    <>
      {babyDetails && (
        <div className="page-content">
          <Loader
            isActive={getbabyNameMetaLoading || getBabyNamesLoading || editBabyNameLoading || translateBabyNameLoading}
          />

          <Container fluid>
            <Breadcrumbs title="Babynames" breadcrumbItem="Translate Babyname" />
            <Form onSubmit={formik.handleSubmit}>
              <div>
                <Row>
                  <Col md={9}>
                    <Card>
                      <CardBody>
                        <Row className="align-items-center">
                          <Col md={12}>
                            <FormGroup className="mb-4">
                              <Label for="babyname" className="form-label  ">
                                Name
                              </Label>
                              <Input
                                id="babyname"
                                type="text"
                                className="form-control"
                                placeholder="Baby Name..."
                                label="Baby Name"
                                invalid={!!(formik.touched.name && formik.errors.name)}
                                {...formik.getFieldProps('name')}
                                error={!isEmpty(formik.errors.name)}
                              />
                              <FormFeedback>{formik.errors.name}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={12} className="mb-4">
                            <SlugInput
                              formik={formik}
                              ID={babyNameId}
                              nameValue={formik.values.name}
                              formGroupClassName="mb-0"
                            />
                          </Col>

                          <Col md={12}>
                            <FormGroup className="mb-4">
                              <Label for="babyname_meaning" className="form-label  ">
                                Meaning
                              </Label>
                              <Input
                                id="babyname_meaning"
                                name="babyname_meaning"
                                type="text"
                                className="form-control"
                                placeholder="Baby name meaning..."
                                invalid={!!(formik.touched.meaning && formik.errors.meaning)}
                                {...formik.getFieldProps('meaning')}
                              />
                              <FormFeedback>{formik.errors.meaning}</FormFeedback>
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup className="mb-4">
                              <Label for="baby_meanings" className="form-label  ">
                                Meanings
                              </Label>
                              <Select
                                id="baby_meanings"
                                placeholder="Select..."
                                value={formik.values.meanings}
                                onChange={(meaning) => formik.setFieldValue('meanings', meaning)}
                                options={meaningWordsList}
                                isMulti
                              />
                              {formik.touched.meanings && (
                                <div className="invalid-feedback d-block">{formik.errors.meanings}</div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-4">
                              <Label for="baby_origin" className="form-label  ">
                                Origins
                              </Label>
                              <Select
                                id="baby_origin"
                                placeholder="Select..."
                                value={formik.values.origins}
                                onChange={(origin) => formik.setFieldValue('origins', origin)}
                                options={originList}
                                isMulti
                                isClearable
                              />
                              {formik.touched.origins && (
                                <div className="invalid-feedback d-block">{formik.errors.origins}</div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-4">
                              <Label for="baby_religion" className="form-label  ">
                                Religions
                              </Label>
                              <Select
                                id="baby_religion"
                                placeholder="Select..."
                                value={formik.values.religions}
                                onChange={(religion) => formik.setFieldValue('religions', religion)}
                                options={religionList}
                                isMulti
                                isClearable
                              />
                              {formik.touched.religions && (
                                <div className="invalid-feedback d-block">{formik.errors.religions}</div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md={6}>
                            <FormGroup className="mb-4">
                              <Label for="baby_rashi" className="form-label  ">
                                Rashi
                              </Label>
                              <Select
                                id="baby_rashi"
                                placeholder="Select..."
                                value={formik.values.rashi}
                                onChange={(rashi) => formik.setFieldValue('rashi', rashi)}
                                options={rashiList}
                                isClearable
                              />
                              {formik.touched.rashi && (
                                <div className="invalid-feedback d-block">{formik.errors.rashi}</div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-4">
                              <Label for="baby_nakshatra" className="form-label  ">
                                Nakshatra
                              </Label>
                              <Select
                                id="baby_nakshatra"
                                placeholder="Select..."
                                value={formik.values.nakshatra}
                                onChange={(nakshatra) => formik.setFieldValue('nakshatra', nakshatra)}
                                options={nakshatraList}
                                isClearable
                              />
                              {formik.touched.nakshatra && (
                                <div className="invalid-feedback d-block">{formik.errors.nakshatra}</div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md={12}>
                            <FormGroup className="mb-4">
                              <Label for="similarNames" className="form-label  ">
                                Similar Names
                              </Label>
                              <Select
                                id="similarNames"
                                placeholder="Select..."
                                value={formik.values.similarNames}
                                onChange={(similarNames) => formik.setFieldValue('similarNames', similarNames)}
                                options={[]}
                              />
                              {formik.touched.similarNames && (
                                <div className="invalid-feedback d-block">{formik.errors.similarNames}</div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col md={12}>
                            <FormGroup className="mb-3">
                              <Label for="description" className="form-label  ">
                                Description
                              </Label>
                              <Input
                                rows={4}
                                id="description"
                                type="textarea"
                                className="form-control"
                                placeholder="Baby name description..."
                                label="description"
                                invalid={!!(formik.touched.description && formik.errors.description)}
                                {...formik.getFieldProps('description')}
                                error={!isEmpty(formik.errors.description)}
                              />
                              <FormFeedback>{formik.errors.description}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col md={3}>
                    <Card>
                      <CardBody>
                        <Button type="submit" color="primary" className="mb-3 w-100">
                          Save & Next
                        </Button>

                        <Button
                          className="w-100"
                          color="light"
                          onClick={() => {
                            history.replace(SMKLinks.BABY_NAME_LISTING);
                          }}>
                          Cancel
                        </Button>

                        <Button outline color="dark" className="mt-3 w-100" onClick={skipBabyName}>
                          Skip
                        </Button>
                      </CardBody>
                    </Card>
                    <PageDetailCard data={babyDetails} />

                    <Card>
                      <CardBody>
                        <FormGroup className="mb-4">
                          <Label for="language" className="form-label">
                            Language
                          </Label>
                          <Select
                            isDisabled={true}
                            id="language"
                            options={LANGUAGE_ARRAY_WITH_STRING?.filter((e) => e?.value !== 'en')}
                            value={formik.values.language}
                            onChange={(language) => formik.setFieldValue('language', language)}
                          />
                          {formik.touched.language && (
                            <div className="invalid-feedback d-block">{formik.errors.language}</div>
                          )}
                        </FormGroup>

                        <FormGroup>
                          <Label for="baby_gender" className="form-label">
                            Gender
                          </Label>
                          <Select
                            id="baby_gender"
                            options={HINDI_BABY_NAMES_GENDER_ARRAY}
                            value={formik.values.gender}
                            onChange={(gender) => formik.setFieldValue('gender', gender)}
                          />
                          {formik.touched.gender && (
                            <div className="invalid-feedback d-block">{formik.errors.gender}</div>
                          )}
                        </FormGroup>
                      </CardBody>
                    </Card>

                    <Card>
                      <CardBody>
                        <div>
                          <CardTitle className="mb-3">Active</CardTitle>
                          {[true, false].map((option, i) => (
                            <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                              <input
                                type="radio"
                                id={`active_${option}`}
                                name="babyname_active"
                                className="form-check-input"
                                checked={formik.values.active === option}
                                onChange={() => formik.setFieldValue('active', option)}
                              />
                              <label className="form-check-label" htmlFor={`active_${option}`}>
                                {option ? 'Yes' : 'No'}
                              </label>
                            </div>
                          ))}
                        </div>

                        <div>
                          <CardTitle className="mb-3">Publish</CardTitle>
                          {[true, false].map((option, i) => (
                            <div key={`approved_${i}`} className="form-check  d-inline-block me-4">
                              <input
                                type="radio"
                                id={`approved_${option}`}
                                name="babyname_approved"
                                className="form-check-input"
                                checked={formik.values.approved === option}
                                onChange={() => formik.setFieldValue('approved', option)}
                              />
                              <label className="form-check-label" htmlFor={`approved_${option}`}>
                                {option ? 'Yes' : 'No'}
                              </label>
                            </div>
                          ))}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>

              <Card>
                <CardBody>
                  <div className="px-3">
                    <Row>
                      <Col>
                        <FormGroup className="mb-3">
                          <Label for="syllableCount" className="form-label  ">
                            Syllable Count
                          </Label>
                          <h5>{babyDetails?.syllableCount}</h5>
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup className="mb-3">
                          <Label for="wordCount" className="form-label  ">
                            Word Count
                          </Label>
                          <h5>{babyDetails?.wordCount}</h5>
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup className="mb-3">
                          <Label for="characterCount" className="form-label  ">
                            Character Count
                          </Label>
                          <h5>{babyDetails?.characterCount}</h5>
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup className="mb-3">
                          <Label for="pythagoreanNumerology" className="form-label  ">
                            Pythagorean Numerology
                          </Label>
                          <h5>{babyDetails?.pythagoreanNumerology}</h5>
                        </FormGroup>
                      </Col>

                      <Col>
                        <FormGroup className="mb-3">
                          <Label for="indianNumerology" className="form-label  ">
                            Indian Numerology
                          </Label>
                          <h5>{babyDetails?.indianNumerology}</h5>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Form>
          </Container>
        </div>
      )}
    </>
  );
};

export default TranslateBabyName;
