import AuthService from 'common/auth.service';
import { userSelector } from 'common/globalComponents/selectors';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import PageDetailCard from 'components/PageDetailsCard';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import {
  APP_BANNER_EXAMPLE_PAYLOAD,
  APP_BANNER_METADATA_EXAMPLES,
  BannerScreenEnum,
  FIREBASE_JOB_APPLICATION_URL,
  MODULE_TYPE,
  NotificationActionEnum,
  appBannerSections,
  applpicationStatus,
} from 'utils/constants';
import { fetchJobApplications, titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import { useRouter } from 'hooks/useRouter';

const stateSelector = createStructuredSelector({
  user: userSelector,
});

const UpdateJobApplication = () => {
  const { user } = useSelector(stateSelector);
  const router = useRouter();
  const { query, locale } = router;

  const history = useHistory();
  const { id: appBannerID } = useParams();

  toast.configure();

  const auth = new AuthService();
  const source = auth.getAppSourceCookie();

  const actionTypes = Object.keys(NotificationActionEnum)?.map((name) => ({
    label: titleCaseIfExists(name),
    value: NotificationActionEnum[name],
  }));
  const bannerScreenList = Object.keys(BannerScreenEnum)?.map((name) => ({
    label: titleCaseIfExists(name),
    value: BannerScreenEnum[name],
  }));

  const [appBannerDetails, setAppBannerDetails] = useState();
  const [jobApplications, setJobApplications] = useState([]);
  const [currentApplication, setCurrentApplication] = useState({});

  const [getAppBannerRequest, { loading: getAppBannerLoading }] = useRequest({
    url: `/common/appBanner/admin/${appBannerID}`,
    method: 'GET',
    onSuccess: (data) => {
      setAppBannerDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [addAppBannerRequest, { loading: addAppBannerLoading }] = useRequest({
    url: `/common/appBanner/admin`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.APP_BANNERS_LISTING;
      history.replace(url);
      toast.success('Success! App Banner has been added.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [updateAppBannerRequest, { loading: updateAppBannerLoading }] = useRequest({
    url: `/common/appBanner/admin/${appBannerID}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.APP_BANNERS_LISTING;
      history.replace(url);
      toast.success('Success! App Banner has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const positionsOfJobs = ['React Native', 'ReactJS', 'Content Writer', 'Digital Marketing', 'Data Entry', 'Other'];

  const updateJobApplication = async (applicationId, updatedData) => {
    try {
      const response = await fetch(`${FIREBASE_JOB_APPLICATION_URL}/jobApplicationsData/${applicationId}.json`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...updatedData,
          updatedDate: new Date().toISOString(),
        }),
      });

      if (!response.ok) {
        toast.error('Failed to update job application');
        throw new Error('Failed to update job application');
      }

      const responseData = await response.json();
      //   getJobApplications();
      toast.success('Success! Application has been updated.');
      return responseData;
    } catch (error) {
      toast.error('Failed to update job application');
      throw new Error('Failed to update job application');
    }
  };

  const getInitialValues = () => ({
    tellUsAboutYourself: '',
    name: '',
    email: '',
    phoneNumber: '',
    position: '',
    currentCTC: '',
    noticePeriodInDays: '',
    cvURL: null,
    yearsOfExperience: '',
    city: '',
    submittedDate: new Date().toISOString(),
    updatedDate: '',
    applicationStatus: 'applied',
    comments: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: isEmpty(currentApplication) ? { ...getInitialValues() } : { ...currentApplication },
    onSubmit: async (values) => {
      updateJobApplication(query?.id, values);
    },
    validationSchema: Yup.object().shape({}),
  });

  const formatString = (input) => {
    if (!input) return '';
    return input
      .split('-')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getJobApplications = async () => {
    try {
      const data = await fetchJobApplications();
      setJobApplications(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getJobApplications();
  }, []);

  useEffect(() => {
    const jobData = jobApplications?.find((ele) => ele?.id === query?.id);
    setCurrentApplication(jobData);
  }, [jobApplications]);

  return (
    <>
      <div className="page-content">
        <Loader isActive={addAppBannerLoading || getAppBannerLoading || updateAppBannerLoading} />
        <Container fluid>
          <Breadcrumbs
            title="Job Application"
            breadcrumbItem={`${isEmpty(appBannerID) ? 'Add' : 'Edit'}  Job Application`}
          />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={8}>
                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="title" className="form-label">
                              Name
                            </Label>
                            <Input
                              id="title"
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Enter title..."
                              invalid={!!(formik.touched.name && formik.errors.name)}
                              disabled={true}
                              {...formik.getFieldProps('name')}
                            />
                            <FormFeedback className="d-block">{formik.errors.name}</FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="applicationStatus" className="form-label  ">
                              Update Status
                            </Label>
                            <Select
                              options={applpicationStatus}
                              placeholder="Update application status..."
                              value={applpicationStatus?.find((ele) => ele?.value === formik.values.applicationStatus)}
                              onChange={(field, value) => {
                                formik.setFieldValue('applicationStatus', field?.value);
                              }}
                            />
                            <FormFeedback className="d-block">{formik.errors.applicationStatus}</FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="comments" className="form-label">
                              Comment
                            </Label>
                            <Input
                              id="comments"
                              name="comments"
                              type="textarea"
                              rows={5}
                              className="form-control"
                              placeholder="Add a comment"
                              invalid={!!(formik.touched.comments && formik.errors.comments)}
                              {...formik.getFieldProps('comments')}
                            />
                            <FormFeedback className="d-block">{formik.errors.comments}</FormFeedback>
                          </FormGroup>
                        </Col>
                      </Col>
                      {!isEmpty(currentApplication?.cvURL) && (
                        <Col md={4}>
                          <iframe
                            src={`${process.env.REACT_APP_SMK_AWS_S3_CLOUDFRONT_URL}/${currentApplication?.cvURL}`}
                            style={{ height: '300px' }}
                            title="PDF Viewer"></iframe>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100 mb-3">
                      {appBannerID ? 'Save Job Application' : 'Add Job Application'}
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.JOB_APPLICATIONS_LISTING);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>
                <PageDetailCard data={appBannerDetails} />
              </Col>
            </Row>
            <p className="fw-bold fs-5 mb-4">Applicant Details</p>
            {!isEmpty(currentApplication) && (
              <Row>
                <Col md={9}>
                  <Card>
                    <CardBody>
                      <Row>
                        <Col md={3}>
                          <p>
                            <span className="fw-bold">Name : </span>
                            {currentApplication?.name}
                          </p>
                        </Col>
                        <Col md={3}>
                          <span className="fw-bold">Email : </span>
                          {currentApplication?.email}
                        </Col>
                        <Col md={3}>
                          <span className="fw-bold">Phone Number : </span>
                          {currentApplication?.phoneNumber}
                        </Col>
                        <Col md={3}>
                          <span className="fw-bold">City : </span>
                          {currentApplication?.city}
                        </Col>
                        <Col md={3}>
                          <span className="fw-bold">YOE : </span>
                          {currentApplication?.yearsOfExperience} Years
                        </Col>
                        <Col md={3}>
                          <span className="fw-bold">Status : </span>
                          {formatString(currentApplication?.applicationStatus)}
                        </Col>
                        <Col md={3}>
                          <span className="fw-bold">Current CTC : </span>
                          {currentApplication?.currentCTC}
                        </Col>
                        <Col md={3}>
                          <span className="fw-bold">Notice Period : </span>
                          {currentApplication?.noticePeriodInDays} Days
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateJobApplication;
