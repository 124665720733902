import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import config from '../../config/config';
import { api } from 'utils/axios';
import { POST } from 'utils/httpMethods';

function SchoolAutoComplete({ selectedSchool, onSelectSchool, isNotClearable }) {
  const [inputValue, setInputValue] = useState('');

  const [filters, setFilters] = useState({
    allowBoth: true,
    autoComplete: true,
    page: 1,
    size: 5,
    sortBy: 'name',
    sortOrder: 'desc',
  });

  // handle input change event
  const handleInputChange = (value) => {
    setInputValue(value);
  };

  // handle selection
  const handleChange = (value) => {
    onSelectSchool(value);
  };

  // load options using API call
  const loadOptions = (inputValue) => {
    return api({
      method: POST,
      url: `${config.apiEndpoint}/school/admin/search?t=${new Date().getTime()}`,
      data: {
        ...filters,
        name: inputValue,
      },
    })?.then((data) => {
      return data?.results;
    });
  };

  return (
    <AsyncSelect
      // cacheOptions
      // defaultOptions
      placeholder="Please type school name..."
      value={selectedSchool}
      getOptionLabel={(e) => `${e.displayName} (${e.id})`}
      getOptionValue={(e) => e.id}
      loadOptions={loadOptions}
      onInputChange={handleInputChange}
      onChange={handleChange}
      isClearable={isNotClearable ? false : true}
    />
  );
}

SchoolAutoComplete.propTypes = {};

export default SchoolAutoComplete;
