import { PUBLIC_URL } from '../../utils/constants';

export default {
  ADD_SCHOOL: `${PUBLIC_URL}/schools/add`,
  EDIT_SCHOOL: `${PUBLIC_URL}/schools/edit/:id`,
  EDIT_SCHOOL_SECTION: `${PUBLIC_URL}/schools/edit/:id/:section`,

  SCHOOL_LISTING: `${PUBLIC_URL}/schools/list`,
  SCHOOL_REVIEWS: `${PUBLIC_URL}/schools/reviews`,
  ADMISSION_INQUIRY: `${PUBLIC_URL}/schools/admission-inquiry`,
  SCHOOL_BOARDS: `${PUBLIC_URL}/schools/boards`,
  SCHOOL_GROUPS: `${PUBLIC_URL}/schools/groups`,
  EDIT_SCHOOL_GROUPS: `${PUBLIC_URL}/schools/groups/edit/:id`,
  ADD_SCHOOL_GROUPS: `${PUBLIC_URL}/schools/groups/add`,

  SCHOOL_META_CONTENT: `${PUBLIC_URL}/schools/meta-content`,
  EDIT_SCHOOL_META_CONTENT: `${PUBLIC_URL}/schools/meta-content/edit/:id/:refId`,
  ADD_SCHOOL_META_CONTENT: `${PUBLIC_URL}/schools/meta-content/add`,

  // SCHOOL_GOOGLE_OBJECTS: PUBLIC_URL + '/school/googleObjects',
  // SCHOOL_GOOGLE_OBJECT_APPROVAL: PUBLIC_URL + '/school/googleObjects/approval',
  SCHOOL_FEATURED: `${PUBLIC_URL}/schools/featured`,
  ADD_SCHOOL_FEATURED: `${PUBLIC_URL}/schools/featured/add`,
  EDIT_SCHOOL_FEATURED: `${PUBLIC_URL}/schools/featured/edit/:id`,
};
