import { USER_ROLE } from '../../utils/constants';
import FoodItemsList from './foodItems';
import AddUpdateFoodItems from './foodItems/addUpdateFoodItems';
import RECIPE_LINKS from './links';
import RecipeList from './recipe';
import AddUpdateRecipe from './recipe/addUpdateRecipe';

const RECIPE_ROUTES = [
  {
    path: RECIPE_LINKS.RECIPE_LIST,
    exact: true,
    component: RecipeList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: RECIPE_LINKS.RECIPE_ADD,
    exact: true,
    component: AddUpdateRecipe,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: RECIPE_LINKS.RECIPE_EDIT,
    exact: true,
    component: AddUpdateRecipe,
    role: [USER_ROLE.ADMIN],
  },

  // Food Items
  {
    path: RECIPE_LINKS.FOOD_ITEMS_LIST,
    exact: true,
    component: FoodItemsList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: RECIPE_LINKS.FOOD_ITEMS_ADD,
    exact: true,
    component: AddUpdateFoodItems,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: RECIPE_LINKS.FOOD_ITEMS_EDIT,
    exact: true,
    component: AddUpdateFoodItems,
    role: [USER_ROLE.ADMIN],
  },
];

export { RECIPE_LINKS, RECIPE_ROUTES };
