import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardTitle, Col, Input, InputGroup, InputGroupText, Label, Row } from 'reactstrap';
import { NUTRITIONS_LIST } from '../constants';
import MultipleNutritions from './MultipleNutritions';
import { isEmpty } from 'lodash';

const NutritionCard = ({ formik, formikLabel, values }) => {
  toast.configure();

  const defaultUnits = {
    unit: '',
    dv: undefined,
    value: undefined,
  };

  const defaultValues = NUTRITIONS_LIST.reduce((acc, nutrient) => {
    acc[nutrient.value] = nutrient?.inputType === 'DTO' ? defaultUnits : '';
    return acc;
  }, {});

  const [localValues, setLocalValues] = useState(defaultValues);

  // set formik values
  useEffect(() => {
    if (!isEmpty(values)) {
      setLocalValues(values);
    }
  }, [values]);

  useEffect(() => {
    formik.setFieldValue(formikLabel, localValues);
  }, [localValues]);

  // Handler to update the local state when input changes
  const handleLocalChange = (key, value) => {
    setLocalValues((prev) => ({ ...prev, [key]: value }));
  };

  // Function to render string input
  const renderStringInput = (nutrition, index) => (
    <Row key={nutrition.value} className="align-items-center mb-3">
      <Col md={4}>
        <Label>
          {index}. {nutrition.label}
        </Label>
      </Col>
      <Col md={8} className="d-flex justify-content-end">
        <Input
          placeholder={`Enter ${nutrition?.label}`}
          style={{ width: 200 }}
          type="text"
          value={localValues[nutrition.value] || ''}
          onChange={(e) => handleLocalChange(nutrition.value, e.target.value)}
        />
      </Col>
    </Row>
  );

  // Function to render float input
  const renderFloatInput = (nutrition, index) => (
    <Row key={nutrition.value} className="align-items-center mb-3">
      <Col md={4}>
        <Label>
          {index}. {nutrition.label}
        </Label>
      </Col>
      <Col md={8} className="d-flex justify-content-end">
        <Input
          placeholder={`Enter ${nutrition?.label}`}
          style={{ width: 200 }}
          type="number"
          value={localValues[nutrition.value]}
          onChange={(e) => handleLocalChange(nutrition.value, parseFloat(e.target.value))}
        />
      </Col>
    </Row>
  );

  // Function to render custom inputs (value, unit, dv)
  const renderCustomInputs = (nutrition, index) => {
    return (
      <Row key={nutrition.value} className="align-items-center mb-3">
        <Col md={4}>
          <Label>
            {index}. {nutrition.label}
          </Label>
        </Col>
        <Col md={8} className="d-flex justify-content-end">
          <div className="d-flex align-items-center" style={{ gap: 20 }}>
            <Input
              type="number"
              step="0.01"
              placeholder="Value"
              value={localValues[nutrition.value]?.value}
              onChange={(e) =>
                handleLocalChange(nutrition.value, {
                  ...localValues[nutrition.value],
                  value: parseFloat(e.target.value),
                })
              }
            />
            <select
              className="form-select"
              value={localValues[nutrition.value]?.unit}
              onChange={(e) =>
                handleLocalChange(nutrition.value, {
                  ...localValues[nutrition.value],
                  unit: e.target.value,
                })
              }>
              <option value={''}>Select Unit</option>
              <option value={'g'}>Gram (g)</option>
              <option value={'mg'}>Miligram (mg)</option>
              <option value={'mcg'}>Microgram (mcg)</option>
            </select>
            <InputGroup>
              <Input
                type="number"
                placeholder="DV"
                value={localValues[nutrition.value]?.dv}
                onChange={(e) =>
                  handleLocalChange(nutrition.value, {
                    ...localValues[nutrition.value],
                    dv: parseFloat(e.target.value),
                  })
                }
              />
              <InputGroupText>%</InputGroupText>
            </InputGroup>
          </div>
        </Col>
      </Row>
    );
  };

  // Function to render JSON input
  const renderJSONObjectInput = (nutrition, index) => (
    <Row key={nutrition.value} className="mb-3">
      <Col md={12}>
        <Label>
          {index}. {nutrition.label}
        </Label>
        <Input
          rows={5}
          placeholder={`Enter ${nutrition?.label}`}
          type="textarea"
          value={localValues[nutrition.value] || ''}
          onChange={(e) => handleLocalChange(nutrition.value, e.target.value)}
        />
      </Col>
    </Row>
  );

  const renderCustomInput = (nutrition, index) => {
    switch (nutrition.inputType) {
      case 'String':
        return renderStringInput(nutrition, index + 1);
      case 'Float':
        return renderFloatInput(nutrition, index + 1);
      case 'JSONObject':
        return renderJSONObjectInput(nutrition, index + 1);
      case 'DTO':
        if (nutrition?.isMultiple) {
          return (
            <MultipleNutritions
              index={index + 1}
              formikKey={nutrition}
              values={localValues[nutrition?.value]}
              setLocalValues={setLocalValues}
            />
          );
        }
        return renderCustomInputs(nutrition, index + 1);
      default:
        return null;
    }
  };

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">
          <h4 className="border-bottom pb-3">Nutrition</h4>
        </CardTitle>

        {NUTRITIONS_LIST?.map((nutrition, index) => renderCustomInput(nutrition, index))}
      </CardBody>
    </Card>
  );
};

export default NutritionCard;
