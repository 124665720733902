const language_worksheets = [
  {
    subject: 'english',
    topic: 'word-puzzles',
    generators: [
      {
        slug: 'word-search',
      },
      {
        slug: 'word-scramble',
      },
      {
        slug: 'spelling-test',
      },
      {
        slug: 'crossword-puzzle',
      },
    ],
  },
];

const puzzle_worksheets = [
  {
    subject: 'games-and-puzzles',
    topic: 'puzzles',
    generators: [
      {
        slug: 'word-scramble',
      },
    ],
  },
];

export const math_worksheets = [
  {
    subject: 'math',
    topic: 'addition',
    generators: [
      {
        slug: 'addition',
      },
      {
        slug: 'addition-multiple-addends',
      },
      {
        slug: 'dot-figure-addition',
      },
      {
        slug: 'addition-missing-numbers',
      },
      {
        slug: 'time-addition',
      },
      {
        slug: 'measurement-addition',
      },
      {
        slug: 'decimals-addition',
      },
      {
        slug: 'decimal-addition-multiple-addends',
      },
      {
        slug: 'negative-numbers-addition',
      },
    ],
  },
  {
    subject: 'math',
    topic: 'subtraction',
    generators: [
      {
        slug: 'subtraction',
      },
      {
        slug: 'dot-figure-subtraction',
      },
      {
        slug: 'subtraction-missing-numbers',
      },
      {
        slug: 'time-subtraction',
      },
      {
        slug: 'measurement-subtraction',
      },
      {
        slug: 'decimals-subtraction',
      },
      {
        slug: 'negative-numbers-subtraction',
      },
    ],
  },

  {
    subject: 'math',
    topic: 'multiplication',

    generators: [
      {
        slug: 'multiplication',
      },
      {
        slug: 'multiplication-missing-numbers',
      },
      {
        slug: 'base-numbers-multiplication',
      },
      {
        slug: 'negative-numbers-multiplication',
      },
      {
        slug: 'decimal-multiplication',
      },
      {
        slug: 'base-numbers-decimal-multiplication',
      },
    ],
  },
  {
    subject: 'math',
    topic: 'division',
    generators: [
      {
        slug: 'division',
      },
      {
        slug: 'division-missing-numbers',
      },
      {
        slug: 'negative-numbers-division',
      },
      {
        slug: 'decimal-division',
      },
    ],
  },
  {
    subject: 'math',
    topic: 'exponents',
    generators: [],
  },
  {
    subject: 'math',
    topic: 'factors',
    generators: [
      {
        slug: 'factors',
      },
      // {
      //   slug: 'greatest-common-factor',
      // },
      // {
      //   slug: 'lowest-common-multiple',
      // },
    ],
  },

  {
    subject: 'math',
    topic: 'fractions',

    generators: [
      {
        slug: 'fractions-addition',
      },
      {
        slug: 'fractions-subtraction',
      },
      {
        slug: 'fractions-multiplication',
      },
      {
        slug: 'fractions-division',
      },
      {
        slug: 'greatest-common-factor',
      },
      {
        slug: 'least-common-multiple',
      },
      {
        slug: 'equivalent-fraction-problems',
      },
      {
        slug: 'equivalent-fractions',
      },
      {
        slug: 'reducing-fractions',
      },
      {
        slug: 'fractions-conversion',
      },
      {
        slug: 'fractions-comparison',
      },
    ],
  },
  {
    subject: 'math',
    topic: 'mean-mode-median-range',

    generators: [
      {
        slug: 'mean-mode-median-range',
      },
    ],
  },

  {
    subject: 'math',
    topic: 'money',

    generators: [
      {
        slug: 'money-counting',
      },
    ],
  },

  {
    subject: 'math',
    topic: 'percent',

    generators: [
      {
        slug: 'percent-conversion',
      },
      {
        slug: 'percentage-calculation',
      },
    ],
  },

  {
    subject: 'math',
    topic: 'measurement',

    generators: [
      {
        slug: 'measurement-addition',
      },
      {
        slug: 'measurement-subtraction',
      },
      {
        slug: 'irregular-measurement',
      },
    ],
  },
  {
    subject: 'math',
    topic: 'number-lines',

    generators: [],
  },

  {
    subject: 'math',
    topic: 'number-concept',

    generators: [
      {
        slug: 'counting',
      },
      {
        slug: 'number-comparison',
      },
      {
        slug: 'even-and-odd-number',
      },
      {
        slug: 'rounding-numbers',
      },
      {
        slug: 'rounding-decimal-numbers',
      },
      {
        slug: 'rounding-hundreds-chart',
      },

      {
        slug: 'number-in-words-numerical',
      },
    ],
  },
  {
    subject: 'math',
    topic: 'number-counting',

    generators: [
      {
        slug: 'numerical-series',
      },

      {
        slug: 'shapes-series',
      },
      {
        slug: 'counting-series',
      },
      {
        slug: 'skip-counting',
      },
    ],
  },
  {
    subject: 'math',
    topic: 'percent',

    generators: [],
  },
  {
    subject: 'math',
    topic: 'place-value',

    generators: [
      {
        slug: 'place-value',
      },

      {
        slug: 'numbers-in-normal-and-expanded-form',
      },
    ],
  },
  {
    subject: 'math',
    topic: 'time',

    generators: [
      {
        slug: 'time-addition',
      },
      {
        slug: 'time-subtraction',
      },
      {
        slug: 'tell-time',
      },
      {
        slug: 'draw-clock-hands',
      },
      {
        slug: 'tell-time-elapsed',
      },
      {
        slug: 'time-calculate',
      },
      {
        slug: 'time-conversion',
      },
      {
        slug: 'make-a-calendar',
      },
      {
        slug: 'analog-and-digital-clock',
      },
    ],
  },
  {
    subject: 'math',
    topic: 'algebra',
    generators: [],
  },
  {
    subject: 'math',
    topic: 'word-problems',
    generators: [],
  },
];

export const WorksheetGeneratorsConstant = [...language_worksheets, ...puzzle_worksheets, ...math_worksheets];
