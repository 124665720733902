import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardTitle } from 'reactstrap';
import useRequest from '../../../hooks/useRequest';
import { isEmpty } from 'lodash';
import { bloggerEmailsList } from '../../../utils/constants';

function RecentArticleTable() {
  const [data, setData] = useState([]);

  const [getPublishedCountForRecentDaysRequest, { loading: getPublishedCountForRecentDaysLoading }] = useRequest({
    url: `/article/admin/recent-published-articles-count`,
    method: 'POST',
    onSuccess: (data) => {
      setData(data.filter((e) => bloggerEmailsList.includes(e.email)));
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getPublishedCountForRecentDaysRequest();
  }, []);

  return (
    <Card>
      <CardBody>
        <CardTitle className="mb-4">Recent Articles</CardTitle>
        <div className="table-responsive">
          <table className="table table-striped table-hover" id="articles-table">
            <thead className="">
              <tr>
                <th>CreatedBy</th>
                <th>Today</th>
                <th>Yesterday</th>
                <th>Day Before Yesterday</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item) => (
                <tr key={item?.user_id}>
                  <td>{`${item?.first_name} ${item?.last_name}`}</td>
                  <td>{item?.today}</td>
                  <td>{item?.yesterday}</td>
                  <td>{item?.day_before_yesterday}</td>
                </tr>
              ))}
              {isEmpty(data) && (
                <>
                  <tr>
                    <td colSpan={4} style={{ textAlign: 'center' }}>
                      No Data
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}

export default RecentArticleTable;
