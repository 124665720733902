import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import Loader from 'components/Loader';
import { toast } from 'react-toastify';
import { SMKLinks } from 'utils/links';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getCities,
  getCountries,
  getRegions,
  getSchoolBoards,
  getSchoolLevels,
  getSchoolsGroups,
  getSchoolsMasterData,
  getSubAreas,
} from '../actions';
import useRequest from '../../../hooks/useRequest';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import {
  citiesSelector,
  countriesSelector,
  regionsSelector,
  selectSchoolBoards,
  selectSchoolLevels,
  selectSchoolsMasterData,
  subAreasSelector,
} from '../selectors';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import {
  EMAIL_REGEX,
  GENDER,
  LATITUDE_REGEX,
  LONGITUDE_REGEX,
  PHONE_REGEX,
  SCHOOL_GENDER_VALUES,
  SchoolMasterType,
  SchoolShifts,
  WEBSITE_REGEX,
} from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import 'react-phone-input-2/lib/style.css';
import ToggleButton from 'components/ToggleButton';
import PhoneInput from 'react-phone-input-2';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
  schoolsMasterData: selectSchoolsMasterData,
  schoolLevelsList: selectSchoolLevels,
  schoolBoards: selectSchoolBoards,
});

const SchoolAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { countries, regions, cities, subAreas, schoolsMasterData, schoolLevelsList, schoolBoards } = useSelector(
    stateSelector
  );
  toast.configure();

  const [gradeError, setGradeError] = useState();

  const [addSchoolRequest, { loading: addSchoolLoading }] = useRequest({
    url: `/school/admin`,
    method: 'POST',
    onSuccess: (data) => {
      history.replace('/schools/list');
      toast.success('Success! School has been Added');
    },
    onError: (err) => {
      toast.error(err?.message || err);
    },
  });

  const isEmptyValue = (val) => {
    return val || undefined;
  };

  const getInitialValues = () => ({
    name: '',
    shortName: '',

    country: {},
    region: {},
    district: {},
    city: {},
    subArea: {},

    postalCode: '',
    fullAddress: '',
    latitude: '',
    longitude: '',

    establishedIn: '',
    studentGender: {},

    managementType: {},
    managementName: '',

    boardingType: {},
    boardingSubTypes: [],
    boardingGenderType: {},

    curriculumBoards: [],

    lowestgrade: {},
    highestgrade: {},

    schoolShift: [],

    phoneMobile: '',
    phoneLandline: '',
    emailInfo: '',
    emailAdmission: '',
  });

  const addObjectsInArray = ({ field1, value1, field2, value2, type }) => {
    const finalArr = [];
    if (isEmpty(value1) && isEmpty(value2)) {
      return undefined;
    }
    if (!isEmpty(value1)) {
      if (type === 'numbers') {
        finalArr?.push({
          type: field1,
          number: value1,
        });
      } else {
        finalArr?.push({
          type: field1,
          email: value1,
        });
      }
    }
    if (!isEmpty(value2)) {
      if (type === 'numbers') {
        finalArr?.push({
          type: field2,
          number: value2,
        });
      } else {
        finalArr?.push({
          type: field2,
          email: value2,
        });
      }
    }

    return finalArr;
  };

  const checkArrUndefined = (arr) => {
    return arr.every((element) => element === undefined);
  };

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit: (values) => {
      if (isEmpty(gradeError)) {
        let masterData = [values.managementType, values.boardingType];
        if (values.boardingType.name.includes('Boarding')) {
          masterData = [...masterData, ...values.boardingSubTypes, values.boardingGenderType];
        }

        const academicInfoData = {
          shift: isEmpty(values?.schoolShift) ? undefined : values.schoolShift,
        };

        const admissionInfoData = {
          academicYear: isEmptyValue(values.academicYear),
          admissionPeriod: !isEmpty(values.admissionPeriod)
            ? [
                {
                  board: 'All',
                  level: 'All',
                  period: isEmptyValue(values.admissionPeriod),
                },
              ]
            : undefined,
        };

        const basicInfoData = {
          establishedIn: isEmptyValue(values.establishedIn),
          managementName: isEmptyValue(values.managementName),

          phoneNumbers: addObjectsInArray({
            field1: 'mobile',
            value1: values?.phoneMobile,
            field2: 'landline',
            value2: values?.phoneLandline,
            type: 'numbers',
          }),
          emails: addObjectsInArray({
            field1: 'info',
            value1: values?.emailInfo,
            field2: 'admissions',
            value2: values?.emailAdmission,
            type: 'email',
          }),
          website: values.website,
        };

        const academicInfoDataValues = Object.values(academicInfoData);
        const admissionInfoDataValues = Object.values(admissionInfoData);
        const basicInfoDataValues = Object.values(basicInfoData);

        addSchoolRequest({
          body: {
            active: false,
            name: isEmptyValue(values?.name),
            shortName: isEmptyValue(values?.shortName),
            studentGender: values.studentGender.value,
            boards: isEmptyValue(values.curriculumBoards),
            lowestGrade: isEmptyValue(values.lowestgrade?.label),
            highestGrade: isEmptyValue(values.highestgrade?.label),
            address: {
              country: {
                id: isEmptyValue(values.country.id),
                name: isEmptyValue(values.country.name),
              },
              region: {
                id: isEmptyValue(values.region.id),
                name: isEmptyValue(values.region.name),
              },
              city: {
                id: isEmptyValue(values.city.id),
                name: isEmptyValue(values.city.name),
              },
              district: isEmpty(values.district)
                ? undefined
                : {
                    id: isEmptyValue(values.district.id),
                    name: isEmptyValue(values.district.name),
                  },
              subArea: isEmpty(values.subArea)
                ? undefined
                : {
                    id: isEmptyValue(values.subArea.id),
                    name: isEmptyValue(values.subArea.name),
                  },
              postalCode: isEmptyValue(values.postalCode),
              fullAddress: isEmptyValue(values.fullAddress),
              location: {
                latitude: Number(String(values?.latitude).slice(0, 11)) || 0,
                longitude: Number(String(values?.longitude).slice(0, 11)) || 0,
              },
            },
            academicInfo: checkArrUndefined(academicInfoDataValues) ? undefined : academicInfoData,
            admissionInfo: checkArrUndefined(admissionInfoDataValues) ? undefined : admissionInfoData,
            basicInfo: checkArrUndefined(basicInfoDataValues) ? undefined : basicInfoData,
            masterData,
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('School Name is required'),
      country: Yup.object().test('countryTest', 'Please select a country', (val) => !isEmpty(val)),
      region: Yup.object().test('regionTest', 'Please select a region', (val) => !isEmpty(val)),
      city: Yup.object().test('cityTest', 'Please select a city', (val) => !isEmpty(val)),
      // establishedIn: Yup.date('Established year is required'),
      studentGender: Yup.object().test(
        'studentGenderTest',
        'Please select student gender type',
        (val) => !isEmpty(val)
      ),
      managementType: Yup.object().test('managTest', 'Please select management type', (val) => !isEmpty(val)),
      // managementName: Yup.string().required('Mangement name is required'),
      boardingType: Yup.object().test('boardingTest', 'Please select boarding type', (val) => !isEmpty(val)),
      boardingSubTypes: Yup.array().when('boardingType', {
        is: (val) => !isEmpty(val) && [val.name].includes('Boarding'),
        then: Yup.array().required('boardingSubTypeTest', 'Please select an boarding subtype ', (val) => !isEmpty(val)),
      }),
      boardingGenderType: Yup.object().when('boardingType', {
        is: (val) => !isEmpty(val) && [val.name].includes('Boarding'),
        then: Yup.object().test('boardingGenderTest', 'Please select an boarding gender ', (val) => !isEmpty(val)),
      }),
      curriculumBoards: Yup.array().test(
        'curriculumBoardsTest',
        'Please select curriculum boards',
        (val) => !isEmpty(val)
      ),
      lowestgrade: Yup.object().test('lowestgradeTest', 'Please select lowest grade', (val) => !isEmpty(val)),
      highestgrade: Yup.object().test('highestgradeTest', 'Please select highest grade', (val) => !isEmpty(val)),
      emailInfo: Yup.string().matches(EMAIL_REGEX, 'Please provide valid email'),
      emailAdmission: Yup.string().matches(EMAIL_REGEX, 'Please provide valid email'),
      phoneMobile: Yup.string().matches(PHONE_REGEX, 'Please provide valid number'),
      phoneLandline: Yup.string().matches(PHONE_REGEX, 'Please provide valid number'),
      website: Yup.string().matches(
        WEBSITE_REGEX,
        'Please enter a valid URL with a valid domain (e.g. .com, .org, .edu)'
      ),
      latitude: Yup.string().matches(LATITUDE_REGEX, 'Please enter correct format'),
      longitude: Yup.string().matches(LONGITUDE_REGEX, 'Please enter correct format'),
    }),
  });

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));
    dispatch(getSchoolsMasterData.trigger());
    dispatch(getSchoolBoards.trigger());
    dispatch(
      getSchoolsGroups.trigger({
        page: 1,
        size: 500,
        sortBy: 'id',
        sortOrder: 0,
      })
    );
  }, []);

  const handleCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      dispatch(
        getSchoolLevels.trigger({
          country: country?.name || '',
        })
      );

      formik.setFieldValue('country', country);
    } else {
      formik.setFieldValue('country', {});
    }

    formik.setFieldValue('region', {});
    formik.setFieldValue('city', {});
    formik.setFieldValue('subArea', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: false,
          region: region.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );

      formik.setFieldValue('region', region);
    } else {
      formik.setFieldValue('region', {});
    }

    formik.setFieldValue('district', {});
    formik.setFieldValue('city', {});
    formik.setFieldValue('subArea', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleDistrictSelect = (district) => {
    if (!isEmpty(district)) {
      dispatch(
        getSubAreas.trigger({
          level: 4,
          city: district.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );

      formik.setFieldValue('district', district);
    } else {
      formik.setFieldValue('district', {});
    }

    formik.setFieldValue('city', {});
    formik.setFieldValue('subArea', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleCitySelect = (city) => {
    if (!isEmpty(city)) {
      if (!city.id && !city.name) {
        city.name = city.label;
      }
      dispatch(
        getSubAreas.trigger({
          level: 4,
          city: city.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      formik.setFieldValue('city', city);
    } else {
      formik.setFieldValue('city', {});
    }

    formik.setFieldValue('subArea', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      if (!subArea.id && !subArea.name) {
        subArea.name = subArea.label;
      }
      formik.setFieldValue('subArea', subArea);
    } else {
      formik.setFieldValue('subArea', {});
    }

    formik.setFieldValue('postalCode', '');
  };

  const handleToggleShifts = (checked, shift) => {
    let array = formik.values.schoolShift?.slice();
    if (checked) {
      array = [...array, shift];
    } else {
      array = array.filter((s) => s.name.toLowerCase() !== shift.name.toLowerCase());
    }
    formik.setFieldValue('schoolShift', array);
  };

  const gradeSelectFunc = (data) => {
    const classesArr = [];
    data
      .slice()
      .sort((a, b) => a.level - b.level)
      ?.map((e) => e?.classes?.map((c) => classesArr?.push(c)));

    const unique2 = classesArr.filter((obj, index) => {
      return index === classesArr.findIndex((o) => obj.level === o.level && obj.name === o.name);
    });

    return unique2?.sort((a, b) => a?.level - b?.level);
  };

  const gradeSelectArray = gradeSelectFunc(schoolLevelsList);

  const schoolBoardsSelect = schoolBoards
    ?.filter((d) => !d.preschool)
    ?.sort((a, b) => {
      if (a.slug < b.slug) {
        return -1;
      }
      if (a.slug > b.slug) {
        return 1;
      }
      return 0;
    });

  useEffect(() => {
    if (!isEmpty(formik.values.highestgrade)) {
      formik?.values?.lowestgrade?.level > formik?.values?.highestgrade?.level
        ? setGradeError('Please select a grade higher than the lowest')
        : setGradeError('');
    }
  }, [formik.values.highestgrade]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Loader isActive={addSchoolLoading} />
          <Breadcrumbs title="Schools" breadcrumbItem="Add School" />
          <Form onSubmit={formik.handleSubmit}>
            <h3 className="mb-4">Basic Info</h3>
            <Card className="mb-5">
              <CardBody>
                <Row>
                  <Col md={7}>
                    <FormGroup className="mb-4">
                      <Label for="schoolname" className="form-label  ">
                        School Name
                      </Label>
                      <Input
                        id="schoolname"
                        type="text"
                        className="form-control"
                        placeholder="School Name..."
                        label="School Name"
                        invalid={!!(formik.touched.name && formik.errors.name)}
                        {...formik.getFieldProps('name')}
                        error={!isEmpty(formik.errors.name)}
                      />
                      <FormFeedback className="d-block">{formik.errors.name}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={5}>
                    <FormGroup className="mb-4">
                      <Label for="school_shortname" className="form-label  ">
                        School Short Name
                      </Label>
                      <Input
                        id="school_shortname"
                        name="school_shortname"
                        type="text"
                        className="form-control"
                        placeholder="School Short Name..."
                        invalid={!!(formik.touched.shortName && formik.errors.shortName)}
                        {...formik.getFieldProps('shortName')}
                      />
                      <FormFeedback className="d-block">{formik.errors.shortName}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label for="establishedIn">Established In</Label>
                      <InputGroup id="establishedIn">
                        <DatePicker
                          selected={formik.values.establishedIn}
                          className="form-control"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholder="Established in..."
                          autoComplete="off"
                          minDate={moment('1200-01-01').toDate()}
                          maxDate={moment().toDate()}
                          onChange={(date) => {
                            formik.setFieldValue('establishedIn', date);
                          }}
                          invalid={!!(formik.touched.establishedIn && formik.errors.establishedIn)}
                        />
                      </InputGroup>
                      <FormFeedback className="d-block">{formik.errors.establishedIn}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label for="school_gender" className="form-label  ">
                        Student Gender Type
                      </Label>
                      <Select
                        id="school_gender"
                        placeholder="Please select an option"
                        options={Object.entries(SCHOOL_GENDER_VALUES).map(([key, value]) => ({
                          value,
                          label: titleCaseIfExists(key),
                        }))}
                        value={formik.values.studentGender}
                        onChange={(val) => formik.setFieldValue('studentGender', val)}
                      />
                      <FormFeedback className="d-block">{formik.errors.studentGender}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormGroup className="mb-4">
                      <Label for="school_boarding" className="form-label">
                        Boarding Type
                      </Label>
                      <Select
                        id="school_boarding"
                        placeholder="Select an option"
                        options={schoolsMasterData.filter((d) => d.type === SchoolMasterType.BOARDING_TYPE)}
                        value={formik.values.boardingType}
                        onChange={(val) => formik.setFieldValue('boardingType', val)}
                      />
                      <FormFeedback className="d-block">{formik.errors.boardingType}</FormFeedback>
                    </FormGroup>
                  </Col>

                  {formik.values?.boardingType?.name?.includes('Boarding') && (
                    <>
                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="school_sub_boarding" className="form-label  ">
                            Sub Boarding Type
                          </Label>
                          <Select
                            isMulti
                            id="school_sub_boarding"
                            placeholder="Select an option"
                            options={schoolsMasterData.filter((d) => d.type === SchoolMasterType.BOARDING_SUB_TYPE)}
                            value={formik.values.boardingSubTypes}
                            onChange={(val) => formik.setFieldValue('boardingSubTypes', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.boardingSubTypes}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="school_boarding_gender_type" className="form-label  ">
                            Boarding Gender Type
                          </Label>
                          <Select
                            id="school_boarding_gender_type"
                            placeholder="Select an option"
                            options={schoolsMasterData.filter((d) => d.type === SchoolMasterType.BOARDING_GENDER_TYPE)}
                            value={formik.values.boardingGenderType}
                            onChange={(val) => formik.setFieldValue('boardingGenderType', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.boardingGenderType}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Row>

                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label for="school_manag_type" className="form-label  ">
                        Management Type
                      </Label>
                      <Select
                        id="school_manag_type"
                        placeholder="Select an option"
                        options={schoolsMasterData.filter((d) => d.type === SchoolMasterType.MANAGEMENT_TYPE)}
                        value={formik.values.managementType}
                        onChange={(val) => formik.setFieldValue('managementType', val)}
                      />
                      {formik.touched.managementType && (
                        <div className="invalid-feedback d-block">{formik.errors.managementType}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label for="school_manag_name" className="form-label  ">
                        Management Name/Trust/Society Name
                      </Label>
                      <Input
                        id="school_manag_name"
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        label="Established In"
                        invalid={!!(formik.touched.managementName && formik.errors.managementName)}
                        {...formik.getFieldProps('managementName')}
                      />
                      <FormFeedback className="d-block">{formik.errors.managementName}</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <FormGroup className="mb-4">
                      <CardTitle htmlFor="schooldesc" className="form-label mb-4">
                        School Shift
                      </CardTitle>
                      <Row>
                        {SchoolShifts.map((shift, i) => (
                          <Col key={`shift_${i}`} md={3}>
                            <ToggleButton
                              label={shift.name}
                              onChange={(e) => handleToggleShifts(e.target.checked, shift)}
                              checked={formik.values.schoolShift.some(
                                (s) => s.name.toLowerCase() === shift.name.toLowerCase()
                              )}
                            />
                          </Col>
                        ))}
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <h3 className="mb-4">Address</h3>
            <Card className="mb-5">
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_country" className="form-label  ">
                        Country <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="school_country"
                        value={formik.values.country}
                        options={countries}
                        onChange={handleCountrySelect}
                      />
                      {formik.touched.country && (
                        <div className="invalid-feedback d-block">{formik.errors.country}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_region" className="form-label  ">
                        Region/State <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="school_region"
                        value={formik.values.region}
                        options={regions}
                        onChange={handleRegionSelect}
                      />
                      {formik.touched.region && <div className="invalid-feedback d-block">{formik.errors.region}</div>}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_district" className="form-label  ">
                        District
                      </Label>
                      <Select
                        isClearable
                        id="school_district"
                        value={formik.values.district}
                        // NOTE: cities and districts too
                        options={cities?.filter((c) => c.level === 2)}
                        onChange={handleDistrictSelect}
                      />
                      {formik.touched.district && (
                        <div className="invalid-feedback d-block">{formik.errors.district}</div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_city" className="form-label  ">
                        City <span className="text-danger">*</span>
                      </Label>
                      <Creatable
                        isClearable
                        id="school_city"
                        value={formik.values.city}
                        options={cities?.filter((c) => c.level === 3)}
                        onChange={handleCitySelect}
                      />
                      {formik.touched.city && <div className="invalid-feedback d-block">{formik.errors.city}</div>}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_subarea" className="form-label  ">
                        SubArea
                      </Label>
                      <Creatable
                        isClearable
                        id="school_subarea"
                        value={formik.values.subArea}
                        options={subAreas}
                        onChange={handleSubAreaSelect}
                      />
                      {formik.touched.subArea && (
                        <div className="invalid-feedback d-block">{formik.errors.subArea}</div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_pincode" className="form-label  ">
                        Pincode
                      </Label>
                      <Input
                        id="school_pincode"
                        type="text"
                        className="form-control"
                        placeholder="Pincode..."
                        invalid={!!(formik.touched.postalCode && formik.errors.postalCode)}
                        {...formik.getFieldProps('postalCode')}
                      />
                      <FormFeedback className="d-block">{formik.errors.postalCode}</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_address" className="form-label  ">
                        Address
                      </Label>
                      <Input
                        id="school_address"
                        type="textarea"
                        className="form-control"
                        rows="3"
                        placeholder="Address..."
                        invalid={!!(formik.touched.fullAddress && formik.errors.fullAddress)}
                        {...formik.getFieldProps('fullAddress')}
                      />
                      <FormFeedback className="d-block">{formik.errors.fullAddress}</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="latitude" className="form-label  ">
                        Latitude
                      </Label>
                      <Input
                        id="latitude"
                        type="text"
                        className="form-control mb-1"
                        placeholder="Latitude..."
                        invalid={!!(formik.touched.latitude && formik.errors.latitude)}
                        {...formik.getFieldProps('latitude')}
                      />
                      Ref: 20.12345678
                      <FormFeedback className="d-block">{formik.errors.latitude}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="longitude" className="form-label  ">
                        Longitude
                      </Label>
                      <Input
                        id="longitude"
                        type="text"
                        className="form-control mb-1"
                        placeholder="Longitude..."
                        invalid={!!(formik.touched.longitude && formik.errors.longitude)}
                        {...formik.getFieldProps('longitude')}
                      />
                      Ref: 20.12345678
                      <FormFeedback className="d-block">{formik.errors.longitude}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <h3 className="mb-4">Contact Details</h3>
            <Card className="mb-5">
              <CardBody>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="phoneLandline" className="form-label  ">
                        Phone (Landline)
                      </Label>
                      <PhoneInput
                        disableSearchIcon
                        specialLabel=""
                        country="in"
                        inputClass="phoneInput"
                        buttonClass="phoneButton"
                        enableSearch
                        placeholder="Phone (Landline)..."
                        onChange={(phone) => formik.setFieldValue('phoneLandline', phone)}
                      />
                      <FormFeedback className="d-block">{formik.errors.phoneLandline}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="phoneMobile" className="form-label  ">
                        Phone (Mobile)
                      </Label>
                      <PhoneInput
                        disableSearchIcon
                        specialLabel=""
                        country="in"
                        inputClass="phoneInput"
                        buttonClass="phoneButton"
                        enableSearch
                        placeholder="Phone (Mobile)..."
                        onChange={(phone) => formik.setFieldValue('phoneMobile', phone)}
                      />
                      <FormFeedback className="d-block">{formik.errors.phoneMobile}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="emailInfo" className="form-label  ">
                        Email (Info)
                      </Label>
                      <Input
                        id="emailInfo"
                        type="text"
                        className="form-control"
                        placeholder="Email (Info)..."
                        invalid={!!(formik.touched.emailInfo && formik.errors.emailInfo)}
                        {...formik.getFieldProps('emailInfo')}
                      />
                      <FormFeedback className="d-block">{formik.errors.emailInfo}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="emailAdmission" className="form-label  ">
                        Email (Admission)
                      </Label>
                      <Input
                        id="emailAdmission"
                        type="text"
                        className="form-control"
                        placeholder="Email (Admission)..."
                        invalid={!!(formik.touched.emailAdmission && formik.errors.emailAdmission)}
                        {...formik.getFieldProps('emailAdmission')}
                      />
                      <FormFeedback className="d-block">{formik.errors.emailAdmission}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="website" className="form-label  ">
                        Website
                      </Label>
                      <Input
                        id="website"
                        type="text"
                        className="form-control"
                        placeholder="Website..."
                        invalid={!!(formik.touched.website && formik.errors.website)}
                        {...formik.getFieldProps('website')}
                      />
                      <FormFeedback className="d-block">{formik.errors.website}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <h3 className="mb-4">Curriculum</h3>
            <Card className="mb-5">
              <CardBody className="my-3">
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_acad_year" className="form-label">
                        Academic Year
                      </Label>
                      <Input
                        id="school_acad_year"
                        type="text"
                        className="form-control"
                        rows="3"
                        placeholder=""
                        invalid={!!(formik.touched.academicYear && formik.errors.academicYear)}
                        {...formik.getFieldProps('academicYear')}
                      />
                      <FormFeedback className="d-block">{formik.errors.academicYear}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="school_acad_period" className="form-label  ">
                        Admission Period
                      </Label>
                      <Input
                        id="school_acad_period"
                        type="text"
                        className="form-control"
                        rows="3"
                        placeholder=""
                        invalid={!!(formik.touched.admissionPeriod && formik.errors.admissionPeriod)}
                        {...formik.getFieldProps('admissionPeriod')}
                      />
                      <FormFeedback className="d-block">{formik.errors.admissionPeriod}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col md={4}>
                    <FormGroup className="mb-4">
                      <Label for="school_manag_type" className="form-label  ">
                        Curriculum Boards
                      </Label>

                      <Select
                        isMulti
                        id="school_manag_type"
                        placeholder="Select an option"
                        options={schoolBoardsSelect}
                        value={formik.values.curriculumBoards}
                        onChange={(val) => formik.setFieldValue('curriculumBoards', val)}
                      />
                      <FormFeedback className="d-block">{formik.errors.curriculumBoards}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="mb-4">
                      <Label for="lowestgrade" className="form-label  ">
                        Lowest grade
                      </Label>

                      <Select
                        id="lowestgrade"
                        placeholder="Select an option"
                        options={gradeSelectArray?.map((e) => ({
                          ...e,
                          label: e?.name,
                          value: e?.name,
                        }))}
                        value={formik.values.lowestgrade}
                        onChange={(val) => formik.setFieldValue('lowestgrade', val)}
                      />
                      <FormFeedback className="d-block">{formik.errors.lowestgrade}</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup className="mb-4">
                      <Label for="highestgrade" className="form-label  ">
                        Highest grade
                      </Label>

                      <Select
                        id="highestgrade"
                        placeholder="Select an option"
                        options={gradeSelectArray?.map((e) => ({
                          ...e,
                          label: e?.name,
                          value: e?.name,
                        }))}
                        value={formik.values.highestgrade}
                        onChange={(val) => {
                          formik.setFieldValue('highestgrade', val);
                          setGradeError('');
                        }}
                      />

                      {!isEmpty(gradeError) && <p className="text-start text-danger mt-2">{gradeError}</p>}
                      <FormFeedback className="d-block">{formik.errors.highestgrade}</FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            <div className="pb-4 pt-3">
              <Button type="submit" color="primary">
                Submit School
              </Button>
              <Button
                color="light"
                className="ms-4 px-3"
                onClick={() => {
                  history.replace(SMKLinks.SCHOOL_LISTING);
                }}>
                Cancel
              </Button>
            </div>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default SchoolAdd;
