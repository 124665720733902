import { Breadcrumbs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { Button, Card, CardBody, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';

const EditProperty = () => {
  const router = useRouter();
  toast.configure();

  const [propertyMaster, setPropertyMaster] = useState(null);
  const [propertiesObj, setPropertiesObj] = useState({});
  const [selectedProperty, setSelectedProperty] = useState();

  const [updateElementPropertyRequest, { loading: updateElementPropertyLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      // console.log(data);
      toast.success('Success! Element has been Updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const [getPropertyRequest, { loading: getPropertyLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      const properties = {};

      // eslint-disable-next-line no-restricted-syntax
      for (const p of data) {
        const pm = propertyMaster?.find((p1) => p1.property_key === p.property_key);
        properties[p.atomic_number] = { ...pm, ...p };
      }

      setPropertiesObj(properties);
    },
  });

  const [getPropertyMasterRequest, { loading: getPropertyMasterLoading }] = useRequest({
    method: 'GET',
    url: `/ptable/admin/getPropertyMaster`,
    onSuccess: (data) => {
      // console.log(data?.results);
      setPropertyMaster(data);
      const selectedRecord = data?.filter((t) => t.property_key === router?.query?.id)[0];
      setSelectedProperty(selectedRecord);
    },
  });

  useEffect(() => {
    if (router?.query?.id) {
      getPropertyMasterRequest();
    }
  }, []);
  useEffect(() => {
    if (propertyMaster) {
      getPropertyRequest({
        url: `/ptable/admin/getPropertyDetails/${router?.query?.id}`,
      });
    }
  }, [propertyMaster]);

  const handleUpdateProperty = (t) => {
    updateElementPropertyRequest({
      url: `/ptable/admin/updateElementProperty/${t.id}`,
      data: { ...t },
    });
  };

  return (
    <div className="page-content">
      <Loader isActive={getPropertyLoading || updateElementPropertyLoading || getPropertyMasterLoading} />
      <Container fluid>
        {/* Render Breadcrumbs */}
        <Breadcrumbs title="Users" breadcrumbItem="Add User" />

        <h5 className="mb-4">Update Property</h5>

        <Card>
          <CardBody>
            <Row>
              <Col md={2}>
                <FormGroup className="mb-4">
                  <Label htmlFor="category" className="form-label  ">
                    Category
                  </Label>
                  <Input
                    id="category"
                    type="text"
                    className="form-control"
                    placeholder="Category"
                    value={selectedProperty?.category}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup className="mb-4 ms-1">
                  <Label htmlFor="subCategory" className="form-label  ">
                    Sub Category
                  </Label>
                  <Input
                    id="subCategory"
                    type="text"
                    className="form-control"
                    placeholder="Sub Category"
                    value={selectedProperty?.sub_category}
                  />
                </FormGroup>
              </Col>
              <Col md={2}>
                <FormGroup className="mb-4 ms-1">
                  <Label htmlFor="propertyUnit" className="form-label  ">
                    Property Unit
                  </Label>
                  <Input
                    id="propertyUnit"
                    type="text"
                    className="form-control"
                    placeholder="Property Unit"
                    value={selectedProperty?.property_unit}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="mb-4 ms-1">
                  <Label htmlFor="property" className="form-label  ">
                    Property
                  </Label>
                  <Input
                    id="property"
                    type="text"
                    className="form-control"
                    placeholder="Property"
                    value={selectedProperty?.property}
                  />
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup className="mb-4 ms-1">
                  <Label htmlFor="propertyKey" className="form-label  ">
                    Property Key
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="propertyKey"
                    placeholder="Property Key..."
                    value={selectedProperty?.property_key}
                  />
                </FormGroup>
              </Col>

              {/* <FormGroup className="mb-4">
                <Label htmlFor="numeric" className="form-label  ">
                  Numeric
                </Label>
                <Input
                  id="numeric"
                  type="checkbox"
                  className="form-control ms-0 ps-0"
                  placeholder="Numeric"
                  defaultChecked={selectedProperty?.numeric}
                />
              </FormGroup> */}
            </Row>
          </CardBody>
        </Card>
        <h4>Properties</h4>
        <Card>
          <CardBody>
            <table className="table table-hover">
              {Object.values(propertiesObj)
                .sort((a, b) => a.id > b.id)
                .map((t, i) => {
                  // const pm = propertyMaster?.find((p) => p.property_key === t.property_key);
                  return (
                    <tr key={`_key_${i}`}>
                      <td>{i + 1}</td>
                      <td>{t?.main_category}</td>
                      <td>{t?.property_key}</td>
                      <td>{t?.property_unit}</td>
                      <td>
                        {t.property_key}
                        <Input
                          id={t.property_key}
                          type="text"
                          className="form-control"
                          value={typeof t.value === 'object' ? JSON.stringify(t.value) : t.value}
                          // onChange={(e) => handleEditProperty(t, e.target.value)}
                        />
                      </td>
                      <td className="">
                        <div className="mt-3">
                          <a className="btn btn-success" onClick={() => handleUpdateProperty(t)}>
                            Save
                          </a>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </table>
          </CardBody>
        </Card>
        <Row>
          <Col lg={12} className="mt-2 mb-5">
            <Button type="submit" color="primary">
              Save
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

EditProperty.propTypes = {};

export default EditProperty;
