import Cookies from 'js-cookie';
import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { COOKIE_OPTIONS, SMK_ACCESS_KEY, WEBSITE_ACCESS_TOKEN } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import profileImg from '../../assets/images/profile-img.png';
import avatar2 from '../../assets/images/users/avatar-2.jpg';
import { isLoggedInSelector, userSelector } from '../../common/globalComponents/selectors';

const stateSelector = createStructuredSelector({
  isLoggedIn: isLoggedInSelector,
  user: userSelector,
});

const WelcomeComp = () => {
  const { user, isLoggedIn } = useSelector(stateSelector);

  const handleOpenStaging = (type) => {
    const domainName =
      type === 'smk'
        ? process.env.REACT_APP_SCHOOLMYKIDS_WEB_URL
        : type === 'tpz'
        ? process.env.REACT_APP_THEPARENTZ_WEB_URL
        : process.env.REACT_APP_MEDIWATCHER_WEB_URL;

    const timestamp = String(new Date().getTime()).slice(0, 4) + '00000';
    const dynamicAccessKey = `_smk_${btoa(timestamp)}`;
    Cookies.set(SMK_ACCESS_KEY, dynamicAccessKey, { expires: 1, domain: '.rhatech.in', ...COOKIE_OPTIONS });
    Cookies.set(dynamicAccessKey, WEBSITE_ACCESS_TOKEN, { expires: 1, domain: '.rhatech.in', ...COOKIE_OPTIONS });
    window.open(domainName, '_blank');
  };

  return (
    <>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>{titleCaseIfExists(user?.appSource)} Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="Profile Image" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="12">
              <div className="avatar-md profile-user-wid mb-3">
                <img src={user?.socialImageUrl || avatar2} alt="" className="img-thumbnail rounded-circle" />
              </div>
              <h5 className="font-size-15 text-truncate">{isLoggedIn ? `${user?.firstName} ${user?.lastName}` : ''}</h5>
              <p className="text-muted mb-0 text-truncate">{user?.email}</p>
              <p className="text-muted mb-0 text-truncate">
                {user?.roles?.map((e, i) => (i === 0 ? e?.name : e?.name + ', '))}
              </p>
              <p className="text-muted mb-0 mt-4 text-truncate">
                <b>App source :</b> {user?.appSource}
              </p>

              {process.env.REACT_APP_NODE_ENV === 'staging' && (
                <div className="d-flex flex-wrap aligm-items-center mt-4" style={{ gap: '20px' }}>
                  <button
                    className="btn btn-sm btn-outline-dark"
                    onClick={() => {
                      handleOpenStaging('smk');
                    }}>
                    Go to Schoolmykids staging
                  </button>
                  <button
                    className="btn btn-sm btn-outline-dark"
                    onClick={() => {
                      handleOpenStaging('tpz');
                    }}>
                    Go to The Parentz staging
                  </button>
                  <button
                    className="btn btn-sm btn-outline-dark"
                    onClick={() => {
                      handleOpenStaging('mediwatcher');
                    }}>
                    Go to Mediwatcher staging
                  </button>
                </div>
              )}
            </Col>

            {/* <Col sm="8">
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">125</h5>
                    <p className="text-muted mb-0">Projects</p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">$1245</h5>
                    <p className="text-muted mb-0">Revenue</p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link to="" className="btn btn-primary waves-effect waves-light btn-sm">
                    View Profile <i className="mdi mdi-arrow-right ms-1" />
                  </Link>
                </div>
              </div>
            </Col> */}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
export default WelcomeComp;
