import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane, Table } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { getSchoolBoards } from '../actions';
import { selectSchoolBoards } from '../selectors';
import { SMKLinks } from 'utils/links';
import { Link, useHistory } from 'react-router-dom';
import { buildURL } from 'utils/helper';
import classNames from 'classnames';
import Loader from 'components/Loader';
import useRequest from 'hooks/useRequest';

const stateSelector = createStructuredSelector({
  schoolBoards: selectSchoolBoards,
});

const SchoolBoardsList = () => {
  const dispatch = useDispatch();
  const [isNameSort, setIsNameSort] = useState(false);
  const [isDisplayNameSort, setDisplayNameSort] = useState(false);

  const { schoolBoards } = useSelector(stateSelector);

  const [schoolMetaContent, setSchoolMetaContent] = useState([]);

  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  const [getSchoolMetaContentRequest, { loading: getSchoolMetaContentLoading }] = useRequest({
    url: `/school/admin/schoolMetaDataContent/search`,
    method: 'POST',
    onSuccess: (data) => {
      setSchoolMetaContent(data?.results);
    },
  });

  useEffect(() => {
    getSchoolMetaContentRequest({
      body: {
        page: 1,
        size: 5000,
        sortBy: 'id',
        sortOrder: 0,
      },
    });
    dispatch(getSchoolBoards.trigger());
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getSchoolMetaContentLoading} />

        <Container fluid>
          <Breadcrumbs title="Schools" breadcrumbItem="School Boards List" />

          <Row className="mb-2 align-items-center mb-4">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{schoolBoards?.length} School Boards Found</div>
              </div>
            </Col>
          </Row>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classNames({
                  active: currentActiveTab === '1',
                })}
                onClick={() => {
                  toggle('1');
                }}>
                Boards
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({
                  active: currentActiveTab === '2',
                })}
                onClick={() => {
                  toggle('2');
                }}>
                Boards - Preschool
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={currentActiveTab}>
            <TabPane tabId="1">
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive" style={{ minHeight: 300 }}>
                      <Table className="project-list-table   table-centered table-borderless">
                        <thead>
                          <tr className="table-warning">
                            <th scope="col">ID</th>
                            <th scope="col">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Name</div>
                                <button
                                  type="button"
                                  className="btn btn-white p-0 border-0"
                                  onClick={() => {
                                    setIsNameSort(!isNameSort);
                                  }}>
                                  <i className={`bx bx-sort-${isNameSort ? 'up' : 'down'} fs-5`} />
                                </button>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Display Name</div>
                                <button
                                  type="button"
                                  className="btn btn-white p-0 border-0"
                                  onClick={() => {
                                    setDisplayNameSort(!isDisplayNameSort);
                                  }}>
                                  <i className={`bx bx-sort-${isDisplayNameSort ? 'up' : 'down'} fs-5`} />
                                </button>
                              </div>
                            </th>
                            <th scope="col">Sub Levels</th>
                            <th scope="col">Add Content</th>
                            <th scope="col">Active</th>
                            <th scope="col">Deleted</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(schoolBoards) ? (
                            schoolBoards
                              ?.filter((u) => !u.preschool)
                              ?.sort((a, b) => a.id - b.id)
                              ?.map((data, index) => {
                                const isMetaData = schoolMetaContent?.find(
                                  (e) => +e?.refId?.replace('board_', '') === data?.id
                                );

                                return (
                                  <tr key={index}>
                                    <td>{data?.id}</td>
                                    <td>
                                      <div className="d-flex justify-content-between">
                                        <div>
                                          <strong>{data?.displayName}</strong>
                                          <br />
                                          {data?.name}
                                        </div>
                                      </div>
                                    </td>
                                    <td>{data?.slug}</td>

                                    <td width={100}>
                                      {data?.subLevels?.map((sublevel, index) => (
                                        <Badge className="bg-light me-2 mb-2 p-2" key={index}>
                                          {sublevel?.name}
                                        </Badge>
                                      ))}
                                    </td>
                                    <td>
                                      {isMetaData ? (
                                        <Link
                                          to={buildURL(SMKLinks.EDIT_SCHOOL_META_CONTENT, {
                                            id: isMetaData?.id,
                                            refId: isMetaData?.refId,
                                          })}>
                                          <i className="bx bx-pencil fs-4 text-dark" />
                                        </Link>
                                      ) : (
                                        <Link
                                          to={buildURL(SMKLinks.ADD_SCHOOL_META_CONTENT, '', {
                                            id: data?.id,
                                            slug: data?.slug,
                                            type: 'board',
                                          })}>
                                          <i className="bx bx-plus fs-4 text-dark" />
                                        </Link>
                                      )}
                                    </td>
                                    <td>
                                      <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                        {data?.active ? 'Yes' : 'No'}
                                      </Badge>
                                    </td>
                                    <td>
                                      <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                        {data?.deleted ? 'Yes' : 'No'}
                                      </Badge>
                                    </td>
                                  </tr>
                                );
                              })
                          ) : (
                            <TableError />
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>{' '}
            </TabPane>

            <TabPane tabId="2">
              <Row>
                <Col lg="12">
                  <div className="">
                    <div className="table-responsive" style={{ minHeight: 300 }}>
                      <Table className="project-list-table   table-centered table-borderless">
                        <thead>
                          <tr className="table-warning">
                            <th scope="col">ID</th>
                            <th scope="col">
                              <div className="d-flex align-items-center  ">
                                <div>Name</div>
                                <button
                                  type="button"
                                  className="btn btn-white p-0 border-0"
                                  onClick={() => {
                                    setIsNameSort(!isNameSort);
                                  }}>
                                  <i className={`bx bx-sort-${isNameSort ? 'up' : 'down'}   fs-5`} />
                                </button>
                              </div>
                            </th>
                            <th scope="col">
                              <div className="d-flex align-items-center justify-content-between">
                                <div>Slug</div>
                                <button
                                  type="button"
                                  className="btn btn-white p-0 border-0"
                                  onClick={() => {
                                    setDisplayNameSort(!isDisplayNameSort);
                                  }}>
                                  <i className={`bx bx-sort-${isDisplayNameSort ? 'up' : 'down'} fs-5`} />
                                </button>
                              </div>
                            </th>
                            <th scope="col">Sub Levels</th>
                            <th scope="col">Add Content</th>
                            <th scope="col">Active</th>
                            <th scope="col">Deleted</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmpty(schoolBoards) ? (
                            schoolBoards
                              ?.filter((u) => u.preschool)
                              ?.sort((a, b) => a.id - b.id)
                              ?.map((data, index) => {
                                const isMetaData = schoolMetaContent?.find(
                                  (e) => +e?.refId?.replace('board_', '') === data?.id
                                );
                                return (
                                  <tr key={index}>
                                    <td>{data?.id}</td>
                                    <td>
                                      <div className="d-flex justify-content-between">
                                        <div>
                                          <strong>{data?.displayName}</strong>
                                          <br />
                                          {data?.name}
                                        </div>
                                      </div>
                                    </td>
                                    <td>{data?.slug}</td>
                                    <td width={100}>
                                      {data?.subLevels?.map((sublevel, index) => (
                                        <Badge className="bg-light me-2 mb-2 p-2">{sublevel?.name}</Badge>
                                      ))}
                                    </td>
                                    <td>
                                      {isMetaData ? (
                                        <Link
                                          to={buildURL(SMKLinks.EDIT_SCHOOL_META_CONTENT, {
                                            id: isMetaData?.id,
                                            refId: isMetaData?.refId,
                                          })}>
                                          <i className="bx bx-pencil fs-4 text-dark" />
                                        </Link>
                                      ) : (
                                        <Link
                                          to={buildURL(SMKLinks.ADD_SCHOOL_META_CONTENT, '', {
                                            id: data?.id,
                                            slug: data?.slug,
                                            type: 'board',
                                          })}>
                                          <i className="bx bx-plus fs-4 text-dark" />
                                        </Link>
                                      )}
                                    </td>
                                    <td>
                                      <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                        {data?.active ? 'Yes' : 'No'}
                                      </Badge>
                                    </td>
                                    <td>
                                      <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                        {data?.deleted ? 'Yes' : 'No'}
                                      </Badge>
                                    </td>
                                  </tr>
                                );
                              })
                          ) : (
                            <TableError />
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>{' '}
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </>
  );
};

export default SchoolBoardsList;
