import TextEditor from 'components/TextEditor';
import React from 'react';
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';

const BlogCareerGuidance = ({ careerGuidance, setCareerGuidance }) => {
  const renderInput = (key, label, isNumber = false) => {
    return (
      <FormGroup className="mb-4">
        <Label for={key} className="form-label">
          {label}
        </Label>
        <Input
          type={isNumber ? 'number' : 'text'}
          id={key}
          name={key}
          className="form-control"
          placeholder={`Enter ${key?.replaceAll('_', ' ')}...`}
          value={careerGuidance?.[key]}
          onChange={(e) => {
            const value = e.target.value;
            setCareerGuidance((prev) => ({
              ...prev,
              [key]: value,
            }));
          }}
        />
      </FormGroup>
    );
  };
  const renderTextEditor = (key, label) => {
    return (
      <div className="my-4">
        <Label className="mb-2 form-label">{label}</Label>
        <TextEditor
          height="100"
          simple
          initialValue={careerGuidance?.[key]}
          onChange={(value) => {
            setCareerGuidance((prev) => ({
              ...prev,
              [key]: value,
            }));
          }}
        />
      </div>
    );
  };
  return (
    <Card className="mt-5">
      <CardBody>
        <h5 className="border-bottom pb-3 d-flex align-items-center justify-content-between mb-4">Career Guidance</h5>

        <Row>
          <Col md={12}>{renderInput('course_name', 'Course name')}</Col>
          <Col md={12}> {renderTextEditor('introduction', 'Introduction')}</Col>
          <Col md={12}> {renderTextEditor('eligibility', 'Eligibility')}</Col>
          <Col md={12}> {renderTextEditor('courses', 'Courses')}</Col>
          <Col md={12}> {renderTextEditor('institutes_universities', 'Institutes Universities')}</Col>
          <Col md={6}>{renderInput('salary', 'Salary', true)}</Col>
          <Col md={6}>{renderInput('stream', 'Stream')}</Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default BlogCareerGuidance;
