import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import map from 'lodash/map';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';

// Import Image
import { createStructuredSelector } from 'reselect';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import TableError from 'components/TableError';
import Loader from 'components/Loader';
import { Divider } from '@material-ui/core';
import useRequest from 'hooks/useRequest';
import { toast } from 'react-toastify';
import {
  ACTIVE_AND_DELETED_ARRAY,
  LANGUAGE_ARRAY_WITH_STRING,
  LANGUAGE_VALUES,
  POST_STATUS_STING,
  PRINT_LANGUAGE,
  PRINT_LANGUAGE_COLOR,
  SORT_VALUES,
} from '../../../utils/constants';
import { SMKLinks } from '../../../utils/links';
import { buildURL, checkIfValueExist, printDateTimeTwoLines } from '../../../utils/helper';
import { getForumPost } from '../actions';
import Paginate from '../../../components/Paginate';
import { postListPaginationSelector, postListSelector } from '../selectors';
import Select from 'react-select';

const stateSelector = createStructuredSelector({
  posts: postListSelector,
  postsPagination: postListPaginationSelector,
});

const PostsList = (props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const history = useHistory();

  const { posts, postsPagination } = useSelector(stateSelector);
  toast.configure();

  const [markApproveAndUnpublishRequest, { loading: markApproveAndUnpublishLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      toast.success(`Success! Post has been ${data?.status !== 1 ? 'UnPublished' : 'Approved'}.`);
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
    },
    onError: (err) => {
      toast.error(err);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      toast.success(`Success!  Post has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
    },
    onError: (err) => {
      toast.error(err);
    },
  });

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
    id: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);
    dispatch(
      getForumPost.trigger({
        page,
        size: 20,
        ...filters,
        ...SORT_VALUES,
        active: checkIfValueExist(filters?.active?.value),
        deleted: checkIfValueExist(filters?.deleted?.value),
      })
    );
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const applyFilters = () => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  };

  const clearFilters = () => {
    setFilters({
      ...defaultFilters,
    });
    dispatch(
      getForumPost.trigger({
        page: 1,
        size: 20,
        ...defaultFilters,
        ...SORT_VALUES,
        active: checkIfValueExist(defaultFilters?.active?.value),
        deleted: checkIfValueExist(defaultFilters?.deleted?.value),
      })
    );
  };

  const postStatusColors = {
    0: 'bg-primary',
    1: 'bg-warning',
    2: 'bg-success',
    3: 'bg-danger',
  };

  const handleApprove = (data) => {
    data?.status !== 1
      ? markApproveAndUnpublishRequest({
          url: `/forum/admin/post/unpublish`,
          body: {
            id: data?.id,
          },
        })
      : markApproveAndUnpublishRequest({
          url: `/forum/admin/post/approve`,
          body: {
            id: data?.id,
            status: 2,
          },
        });
  };

  const handleDelete = (data) => {
    deleteUnDeleteRequest({
      url: `/forum/admin/post/delete`,
      body: {
        id: data?.id,
        deleted: !data?.deleted,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={deleteUnDeleteLoading || markApproveAndUnpublishLoading} />
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Posts" breadcrumbItem="Posts List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY_WITH_STRING}
                      value={LANGUAGE_ARRAY_WITH_STRING?.find((t) => t.value == filters?.language)}
                      onChange={(item) =>
                        setFilters({
                          ...filters,
                          language: item.value,
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Post ID</label>
                    <Input
                      type="number"
                      placeholder="Post ID..."
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: e?.target?.value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{postsPagination?.totalCount} Posts Found</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">Post Id</th>
                        <th scope="col">
                          Topic Title <br /> Topic Slug{' '}
                        </th>
                        <th scope="col">Created By</th>
                        <th scope="col">Created On</th>

                        {/* <th scope="col">Like <br />Count</th>
                        <th scope="col">Dislike <br />Count</th> */}
                        <th scope="col">Language</th>
                        <th scope="col">Status</th>
                        <th scope="col">
                          Is Expert <br />
                          Answer
                        </th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(posts) ? (
                        map(posts, (post, key) => (
                          <React.Fragment key={key}>
                            <tr>
                              <td>
                                <Link
                                  to={buildURL(SMKLinks.POST_DETAIL, {
                                    id: post.id,
                                  })}>
                                  {post.id}
                                </Link>
                              </td>

                              <td style={{ maxWidth: 800, whiteSpace: 'pre-wrap' }}>
                                <Link
                                  to={buildURL(SMKLinks.POST_EDIT, {
                                    id: post.id,
                                  })}>
                                  {post?.topic && `( ${post?.topic?.id} )`} {post.topic && post.topic.title}
                                </Link>
                                {/* <br /> */}
                                {/* {post.topic && post.topic.slug} */}
                              </td>
                              <td>
                                <h5 className="text-truncate font-size-14">
                                  {post.createdBy
                                    ? !isEmpty(post.createdBy.firstName)
                                      ? post.createdBy.firstName
                                      : post.createdBy.displayName
                                    : ''}
                                </h5>
                              </td>
                              <td>{printDateTimeTwoLines(post.createdDate)}</td>
                              {/* <td>{post?.likeCount}</td>
                              <td>{post?.dislikeCount}</td> */}
                              <td>
                                <Badge className={PRINT_LANGUAGE_COLOR[post?.language]}>
                                  {PRINT_LANGUAGE[post?.language]}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={postStatusColors[post?.status]}>
                                  {POST_STATUS_STING[post.status]}
                                </Badge>
                              </td>

                              <td align="center">
                                <Badge className={post?.isExpertAnswer ? 'bg-success' : 'bg-danger'}>
                                  {post?.isExpertAnswer ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td align="center">
                                <Badge className={post?.active ? 'bg-success' : 'bg-danger'}>
                                  {post?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={post?.deleted ? 'bg-success' : 'bg-danger'}>
                                  {post?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>

                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop" tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem onClick={() => handleApprove(post)}>
                                      <i
                                        className={`fas ${
                                          post?.status !== 1 ? 'fa-trash-alt text-danger' : 'fa-power-off text-success'
                                        }     m-1 pe-3`}
                                      />
                                      {post?.status !== 1 ? 'Unpublish' : 'Approve'}
                                    </DropdownItem>

                                    {!post?.deleted && (
                                      <DropdownItem onClick={() => handleDelete(post)}>
                                        <i
                                          className={`fas fa-trash-alt ${
                                            post?.deleted ? 'text-success' : 'text-danger'
                                          } m-1 pe-3`}
                                        />
                                        {post?.deleted ? 'Undelete' : 'Delete'}
                                      </DropdownItem>
                                    )}

                                    <Divider className="my-2" />

                                    <DropdownItem
                                      href={buildURL(SMKLinks.POST_DETAIL, {
                                        id: post.id,
                                      })}>
                                      <i className="fas fa-eye text-primary m-1 pe-3 " />
                                      Details
                                    </DropdownItem>
                                    <DropdownItem
                                      href={buildURL(SMKLinks.POST_EDIT, {
                                        id: post.id,
                                      })}>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                            {!isEmpty(JSON.parse(post?.jsonContent)) && (
                              <tr>
                                <td></td>
                                <td colSpan={2} style={{ maxWidth: 800, whiteSpace: 'pre-wrap' }}>
                                  <Label className="form-label border-bottom fw-bold">Message</Label>
                                  <h6 className="mb-0 mt-1" style={{ lineHeight: '20px' }}>
                                    {JSON.parse(post?.jsonContent)?.blocks[0]?.text}
                                  </h6>
                                </td>
                                <td colSpan={20}></td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={postsPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default PostsList;
