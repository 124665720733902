import CustomAttachmentImageUploader from 'components/AttachmentImageUploader/CustomAttachementImageUploader';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { MODULE_TYPE } from 'utils/constants';

const InstructionsCard = ({ formik, formikLabel, values, allIngredients }) => {
  toast.configure();

  const defaultDirection = {
    step: undefined,
    title: '',
    description: '',
    image: null,
    ingredientIds: [],
    utensils: [],
  };

  const defaultInstructions = {
    directions: [defaultDirection],
    equipmentsNeeded: '',
    variationsAndSubstitutes: '',
    servingSuggestions: '',
    storageTips: '',
    visualPresentation: '',
  };

  const [instructions, setInstructions] = useState(defaultInstructions);

  // Set Formik values when component mounts or when values change
  useEffect(() => {
    if (!isEmpty(values)) {
      setInstructions({
        ...values,
        directions: !isEmpty(values.directions)
          ? values.directions.map((dir) => ({
              ...dir,
              ingredientIds: !isEmpty(dir?.ingredientIds)
                ? dir.ingredientIds?.map((dir) => ({
                    label: allIngredients?.find((e) => e?.id === dir)?.foodItem?.name,
                    value: allIngredients?.find((e) => e?.id === dir)?.id,
                  }))
                : [],
              utensils: !isEmpty(dir?.utensils)
                ? dir?.utensils?.map((u) => ({
                    label: u,
                    value: u,
                  }))
                : [],
            }))
          : [],
      });
    }
  }, [values, allIngredients]);

  useEffect(() => {
    formik.setFieldValue(formikLabel, instructions);
  }, [instructions]);

  const handleInputChange = (e, key) => {
    const { value } = e.target;

    // Update the singular fields in instructions
    setInstructions((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleDirectionInputChange = (e, key, index) => {
    let updatedvalue;
    if (key === 'image' || key === 'utensils' || key === 'ingredientIds') {
      updatedvalue = e;
    } else {
      updatedvalue = e.target.value;
    }

    // Update directions state
    const updatedDirections = instructions.directions.map((direction, i) =>
      i === index ? { ...direction, [key]: updatedvalue, step: i + 1 } : { ...direction, step: i + 1 }
    );

    setInstructions((prev) => ({
      ...prev,
      directions: updatedDirections,
    }));
  };

  const handleAddDirection = () => {
    const added_direction = [...instructions.directions, defaultDirection];
    setInstructions((prev) => ({
      ...prev,
      directions: added_direction,
    }));
  };

  const handleDeleteDirection = (index) => {
    const deleted_direction = instructions.directions.filter((_, i) => i !== index);
    setInstructions((prev) => ({
      ...prev,
      directions: deleted_direction,
    }));
    toast.success(`Step ${index + 1} deleted successfully.`);
  };

  return (
    <Card>
      <CardBody>
        <h4 className="mb-3">Instructions</h4>
        <div>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="equipmentsNeeded">Equipments Needed</Label>
                <Input
                  type="textarea"
                  rows={3}
                  id="equipmentsNeeded"
                  placeholder="Equipments Needed"
                  value={instructions.equipmentsNeeded}
                  onChange={(e) => handleInputChange(e, 'equipmentsNeeded')}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="variationsAndSubstitutes">Variations and Substitutes</Label>
                <Input
                  type="textarea"
                  rows={3}
                  id="variationsAndSubstitutes"
                  placeholder="Variations and Substitutes"
                  value={instructions.variationsAndSubstitutes}
                  onChange={(e) => handleInputChange(e, 'variationsAndSubstitutes')}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="servingSuggestions">Serving Suggestions</Label>
                <Input
                  type="textarea"
                  rows={3}
                  id="servingSuggestions"
                  placeholder="Serving Suggestions"
                  value={instructions.servingSuggestions}
                  onChange={(e) => handleInputChange(e, 'servingSuggestions')}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="storageTips">Storage Tips</Label>
                <Input
                  type="textarea"
                  rows={3}
                  id="storageTips"
                  placeholder="Storage Tips"
                  value={instructions.storageTips}
                  onChange={(e) => handleInputChange(e, 'storageTips')}
                />
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup className="mb-4">
                <Label for="visualPresentation">Visual Presentation</Label>
                <Input
                  type="textarea"
                  rows={3}
                  id="visualPresentation"
                  placeholder="Visual Presentation"
                  value={instructions.visualPresentation}
                  onChange={(e) => handleInputChange(e, 'visualPresentation')}
                />
              </FormGroup>
            </Col>

            <FormGroup className="mb-4">
              <div className="bg-light p-3 w-100 text-center  mb-3 border rounded-2 d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Steps: {instructions.directions.length}</h4>
                <Button color="success" onClick={handleAddDirection}>
                  Add Step
                </Button>
              </div>

              {instructions.directions.map((direction, index) => (
                <div key={index} className="p-4 border rounded-2 mb-4 shadow">
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <h4 className="mb-0">Step {index + 1}</h4>
                    <Button outline color="danger" onClick={() => handleDeleteDirection(index)}>
                      Delete
                    </Button>
                  </div>

                  <Row>
                    <Col md={8}>
                      <Row>
                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for={`title-${index}`}>Title</Label>
                            <Input
                              type="text"
                              id={`title-${index}`}
                              placeholder="Title"
                              value={direction.title}
                              onChange={(e) => handleDirectionInputChange(e, 'title', index)}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for={`description-${index}`}>Description</Label>
                            <Input
                              type="textarea"
                              rows={3}
                              id={`description-${index}`}
                              placeholder="Description"
                              value={direction.description}
                              onChange={(e) => handleDirectionInputChange(e, 'description', index)}
                            />
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="ingredients" className="form-label">
                              Ingredients
                            </Label>
                            <Select
                              isMulti
                              placeholder="Type Ingredient Name.."
                              value={direction.ingredientIds}
                              onChange={(res) => handleDirectionInputChange(res, 'ingredientIds', index)}
                              options={
                                !isEmpty(allIngredients)
                                  ? allIngredients?.map((e) => ({
                                      label: e?.foodItem?.name,
                                      value: e?.id,
                                    }))
                                  : []
                              }
                            />
                            {!isEmpty(formik?.values?.ingredientIds) && (
                              <div className="mt-3">
                                Ingredients:{' '}
                                <span>
                                  <b>{formik?.values?.ingredientIds?.map((e) => e?.value)?.join(', ')}</b>
                                </span>
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for={`utensils-${index}`}>Utensils</Label>
                            <CreatableSelect
                              isMulti
                              placeholder="Type utensils name.."
                              value={direction.utensils}
                              onChange={(res) => handleDirectionInputChange(res, 'utensils', index)}
                              options={[]}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}>
                      <CustomAttachmentImageUploader
                        label={'Select Image'}
                        forType={MODULE_TYPE.RECIPE}
                        value={direction.image}
                        onChange={(e) => handleDirectionInputChange(e, 'image', index)}
                        onReset={() => handleDirectionInputChange(null, 'image', index)}
                      />
                    </Col>
                  </Row>
                </div>
              ))}
            </FormGroup>
          </Row>
        </div>
      </CardBody>
    </Card>
  );
};

export default InstructionsCard;
