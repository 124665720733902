import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import * as Yup from 'yup';

// actions
import { schoolClearCache } from 'containers/school/schoolDetailsHelper';
import { useHistory } from 'react-router-dom';
import { SMKLinks } from 'utils/links';
import { getSchoolsGroups } from '../../actions';
// selectors
import { schoolDetailSelector, selectSchoolsGroups, selectSchoolsMasterData } from '../../selectors';
// constants
import {
  EMAIL_REGEX,
  GENDER,
  NAME_REGEX,
  PHONE_REGEX,
  SCHOOL_GENDER_VALUES,
  SchoolMasterType,
} from '../../../../utils/constants';
// helpers
import { convertHtmlToText, htmlDecode, titleCaseIfExists } from '../../../../utils/helper';
// components
import { userSelector } from '../../../../common/globalComponents/selectors';
import Loader from '../../../../components/Loader';
import TextEditor from '../../../../components/TextEditor';
import useRequest from '../../../../hooks/useRequest';

const stateSelector = createStructuredSelector({
  schoolsMasterData: selectSchoolsMasterData,
  schoolsGroups: selectSchoolsGroups,
  schoolDetail: schoolDetailSelector,
  user: userSelector,
});

const BasicInfo = () => {
  const { user, schoolsMasterData, schoolsGroups, schoolDetail } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const schoolGroupsPayload = {
    page: 1,
    size: 500,
    sortBy: 'id',
    sortOrder: 0,
  };

  useEffect(() => {
    dispatch(getSchoolsGroups.trigger(schoolGroupsPayload));
  }, []);

  const getInitialFormValues = () => {
    const {
      name,
      shortName,
      basicInfo,
      masterData,
      images,
      admissionInfo,
      schoolGroups,
      studentGender,
      preschool,
    } = schoolDetail;

    const values = {};

    // Basic Info
    values.name = convertHtmlToText(name) || '';
    values.shortName = convertHtmlToText(shortName) || '';
    values.preschool = preschool ? 'Yes' : 'No';

    values.establishedIn = basicInfo?.establishedIn ? moment(basicInfo.establishedIn).toDate() : '';

    const parent = schoolGroups?.find((sc) => !sc.schoolGroup?.isAssociation);

    values.parentSchool = !isEmpty(parent)
      ? {
          ...parent.schoolGroup,
          value: parent.schoolGroup?.id,
          label: parent.schoolGroup?.name,
        }
      : null;

    values.motto = htmlDecode(basicInfo?.schoolMotto) || '';

    const gender = Object.entries(GENDER).find(([k, v]) => v === studentGender);
    values.studentGender = {
      label: titleCaseIfExists(gender[0]),
      value: gender[1],
    };

    // prepopulate boarding type
    const boarding = masterData?.find((b) => b.type === SchoolMasterType.BOARDING_TYPE);
    values.boardingType =
      (boarding && schoolsMasterData?.find((d) => d.type === SchoolMasterType.BOARDING_TYPE && d.id === boarding.id)) ||
      {};

    // prepopulate boarding gender
    const boardingGender = masterData?.find((b) => b.type === SchoolMasterType.BOARDING_GENDER_TYPE);
    values.boardingGenderType =
      (boardingGender &&
        schoolsMasterData.find(
          (d) => d.type === SchoolMasterType.BOARDING_GENDER_TYPE && boardingGender.id === d.id
        )) ||
      {};

    // prepopulate boarding sub type
    // const boardingSubType = masterData?.find((b) => b.type === SchoolMasterType.BOARDING_SUB_TYPE);
    values.boardingSubTypes =
      schoolsMasterData?.filter((d) =>
        masterData?.find((b) => b.id === d.id && d.type === b.type && b.type === SchoolMasterType.BOARDING_SUB_TYPE)
      ) || {};

    values.twitter = basicInfo?.socialProfiles?.find((sp) => sp.type === 'twitter')?.handle || '';
    values.facebook = basicInfo?.socialProfiles?.find((sp) => sp.type === 'facebook')?.handle || '';

    // Management Details
    const management = masterData?.find((m) => m.type === SchoolMasterType.MANAGEMENT_TYPE);
    values.managementType =
      (management &&
        schoolsMasterData?.find((d) => d.type === SchoolMasterType.MANAGEMENT_TYPE && d.id === management.id)) ||
      {};
    values.managementName = basicInfo?.managementName || '';
    const principal = basicInfo?.management.find((m) => m.type === 'Principal');
    values.principalName = principal?.name || '';
    values.principalEmail = principal?.email || '';
    values.principalPhone = principal?.phone || '';

    const chairman = basicInfo?.management.find((m) => m.type === 'Chairman');
    values.chairmanName = chairman?.name || '';
    values.chairmanEmail = chairman?.email || '';
    values.chairmanPhone = chairman?.phone || '';
    values.officeHours = basicInfo?.officeHours || '';
    values.committee = basicInfo?.managementCommittee || '';
    values.about = basicInfo?.about || '';

    return values;
  };

  const [updateBasicInfoRequest, { loading: updateBasicInfoLoading }] = useRequest({
    url: '/school/admin/edit/updateBasicInfo',
    method: 'POST',
    onSuccess: (data) => {
      schoolClearCache({
        urlSlug: schoolDetail?.slug,
        schoolId: schoolDetail?.id,
      });
      window.location.reload();
    },
  });

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialFormValues(),
    onSubmit(values) {
      let masterData = [values.managementType, values.boardingType];
      if (values.boardingType.name.includes('Boarding')) {
        masterData = [...masterData, ...values.boardingSubTypes, values.boardingGenderType];
      }

      const restSchoolGroups = schoolDetail?.schoolGroups?.filter((elem) => elem?.schoolGroup?.isAssociation);
      updateBasicInfoRequest({
        data: {
          schoolId: schoolDetail.id,
          name: values.name,
          shortName: values.shortName,
          preschool: values.preschool === 'Yes',
          schoolGroups: values.parentSchool ? [...restSchoolGroups, values.parentSchool] : restSchoolGroups,
          images: !isEmpty(values?.featuredImages)
            ? values?.featuredImages
                ?.filter((e) => !isEmpty(e?.type) && !isEmpty(e?.file))
                ?.map((elem) => {
                  return {
                    type: elem?.type,
                    imageId: elem?.file?.id,
                  };
                })
            : [],
          studentGender: values.studentGender.value,
          basicInfo: {
            establishedIn: values.establishedIn,
            schoolMotto: values.motto,
            managementName: values.managementName,
            management: [
              {
                email: values.principalEmail,
                name: values.principalName,
                phone: values.principalPhone,
                type: 'Principal',
              },
              {
                email: values.chairmanEmail,
                name: values.chairmanName,
                phone: values.chairmanPhone,
                type: 'Chairman',
              },
            ],
            officeHours: values.officeHours,
            managementCommittee: values.committee,
            about: values.about,
            socialProfiles: [
              { type: 'twitter', handle: values.twitter },
              { type: 'facebook', handle: values.facebook },
            ],
          },
          masterData,
          admissionInfo: {
            schoolBrochureUrl: values.brochure,
          },
        },
      });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Please provide school name')
        .typeError('Please provide school name')
        .min(8, 'Title should be at least 8 characters long')
        .max(150, 'Title should be at most 100 characters long'),
      shortName: Yup.string()
        .nullable()
        // eslint-disable-next-line func-names
        .test('shortNameTest', 'Short name should be smaller than name', function(value) {
          // eslint-disable-next-line react/no-this-in-sfc
          return !value || (value?.length > 0 && this.parent.name?.length > value?.length);
        }),
      // Basic Info
      establishedIn: Yup.date().nullable(),
      parentSchool: Yup.object().nullable(),
      // .test('groupTest', 'Please select an option', (val) => !isEmpty(val)),
      motto: Yup.string().nullable(),
      boardingType: Yup.object().test('boardingTest', 'Please select boarding', (val) => !isEmpty(val)),
      boardingSubTypes: Yup.array().when('boardingType', {
        is: (val) => !isEmpty(val) && val.name.includes('Boarding'),
        then: Yup.array().test('boardingSubTypeTest', 'Please select an option', (val) => !isEmpty(val)),
      }),
      boardingGenderType: Yup.object().when('boardingType', {
        is: (val) => !isEmpty(val) && val.name.includes('Boarding'),
        then: Yup.object().test('boardingGenderTest', 'Please select an option', (val) => !isEmpty(val)),
      }),
      twitter: Yup.string().nullable(),
      facebook: Yup.string().nullable(),
      // Logo & Images
      // featureImageUrls: Yup.string().nullable(),
      brochure: Yup.string().nullable(),
      // Management Details
      managementType: Yup.object().test('managTest', 'Please select management type', (val) => !isEmpty(val)),
      managementName: Yup.string().nullable(),
      chairmanName: Yup.string()
        .nullable()
        // .required('Please provide chairman name')
        .matches(NAME_REGEX, 'Please provide valid chairman name'),
      chairmanEmail: Yup.string()
        .matches(EMAIL_REGEX, 'Please provide valid email')
        .nullable(),
      chairmanPhone: Yup.string()
        .matches(PHONE_REGEX, 'Please provide valid number')
        .nullable(),
      principalName: Yup.string()
        .nullable()
        // .required('Please provide principal name')
        .matches(NAME_REGEX, 'Please provide valid principal name'),
      principalEmail: Yup.string()
        .matches(EMAIL_REGEX, 'Please provide valid email')
        .nullable(),
      principalPhone: Yup.string()
        .matches(PHONE_REGEX, 'Please provide valid number')
        .nullable(),
      officeHours: Yup.string().nullable(),
      // .required('Please provide office hours'),
      committee: Yup.string().nullable(),
      about: Yup.string().nullable(),
      // .required('Please provide few words about school'),
    }),
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateBasicInfoLoading} />

      <h5 className="mb-4">Basic Info</h5>

      <Card>
        <CardBody>
          <Row>
            <Col md={8}>
              <FormGroup className="mb-4">
                <Label for="schoolname" className="form-label  ">
                  School Name
                </Label>
                <Input
                  id="schoolname"
                  type="text"
                  className="form-control"
                  placeholder="School Name..."
                  label="School Name"
                  variant="outlined"
                  invalid={!!(formik.touched.name && formik.errors.name)}
                  {...formik.getFieldProps('name')}
                />
                <FormFeedback>{formik.errors.name}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="mb-4">
                <Label for="school_shortname" className="form-label  ">
                  School Short Name
                </Label>
                <Input
                  id="school_shortname"
                  name="school_shortname"
                  type="text"
                  className="form-control"
                  placeholder="School Short Name..."
                  invalid={!!(formik.touched.shortName && formik.errors.shortName)}
                  {...formik.getFieldProps('shortName')}
                />
                <FormFeedback>{formik.errors.shortName}</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={6} className="mt-3">
              <CardTitle>Preschool</CardTitle>
              <div>
                {['Yes', 'No'].map((option, i) => (
                  <div key={`preschool_${i}`} className="form-check mb-3 d-inline-block me-4">
                    <input
                      type="radio"
                      id={`preschool_${option}`}
                      name="preschool"
                      className="form-check-input"
                      checked={formik.values.preschool === option}
                      onChange={() => formik.setFieldValue('preschool', option)}
                    />
                    <label className="form-check-label" htmlFor={`preschool_${option}`}>
                      {option}
                    </label>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="establishedIn">Established In</Label>
                <InputGroup id="establishedIn">
                  <DatePicker
                    selected={formik.values.establishedIn}
                    className="form-control"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select date"
                    autoComplete="off"
                    minDate={moment('1200-01-01').toDate()}
                    maxDate={moment().toDate()}
                    onChange={(date) => formik.setFieldValue('establishedIn', date)}
                    invalid={!!(formik.touched.establishedIn && formik.errors.establishedIn)}
                  />
                </InputGroup>
                <FormFeedback className="d-block">{formik.errors.establishedIn}</FormFeedback>
              </FormGroup>

              <FormGroup className="mb-4">
                <Label for="school_parent" className="form-label">
                  Parent School
                </Label>
                <Select
                  isClearable
                  id="school_parent"
                  placeholder="Please select an option"
                  options={schoolsGroups.filter((gr) => !gr.isAssociation)}
                  value={formik.values.parentSchool}
                  onChange={(val) => formik.setFieldValue('parentSchool', val)}
                />
                {formik.touched.parentSchool && (
                  <div className="invalid-feedback d-block">{formik.errors.parentSchool}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="school_motto" className="form-label  ">
                  School Motto
                </Label>
                <Input
                  id="school_motto"
                  type="textarea"
                  className="form-control"
                  placeholder="School Motto..."
                  invalid={!!(formik.touched.motto && formik.errors.motto)}
                  {...formik.getFieldProps('motto')}
                />
                <FormFeedback>{formik.errors.motto}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>

          <hr className="mb-4" />

          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="school_gender" className="form-label  ">
                  Student Gender Type
                </Label>
                <Select
                  id="school_gender"
                  placeholder="Please select an option"
                  options={Object.entries(SCHOOL_GENDER_VALUES).map(([key, value]) => ({
                    value,
                    label: titleCaseIfExists(key),
                  }))}
                  value={formik.values.studentGender}
                  onChange={(val) => formik.setFieldValue('studentGender', val)}
                />
                {formik.touched.studentGender && (
                  <div className="invalid-feedback d-block">{formik.errors.studentGender}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="school_boarding" className="form-label  ">
                  Boarding Type
                </Label>
                <Select
                  id="school_boarding"
                  placeholder="Please select an option"
                  options={schoolsMasterData.filter((d) => d.type === SchoolMasterType.BOARDING_TYPE)}
                  value={formik.values.boardingType}
                  onChange={(val) => formik.setFieldValue('boardingType', val)}
                />
                {formik.touched.boardingType && (
                  <div className="invalid-feedback d-block">{formik.errors.boardingType}</div>
                )}
              </FormGroup>
            </Col>
            {formik.values?.boardingType?.name?.includes('Boarding') && (
              <>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label for="school_sub_boarding" className="form-label  ">
                      Sub Boarding Type
                    </Label>
                    <Select
                      isMulti
                      id="school_sub_boarding"
                      placeholder="Please select an option"
                      options={schoolsMasterData.filter((d) => d.type === SchoolMasterType.BOARDING_SUB_TYPE)}
                      value={formik.values.boardingSubTypes}
                      onChange={(val) => {
                        console.log(val);
                        formik.setFieldValue('boardingSubTypes', val);
                      }}
                    />
                    {formik.touched.boardingSubTypes && (
                      <div className="invalid-feedback d-block">{formik.errors.boardingSubTypes}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label for="school_boarding_gender_type" className="form-label  ">
                      Boarding Gender Type
                    </Label>
                    <Select
                      id="school_boarding_gender_type"
                      placeholder="Please select an option"
                      options={schoolsMasterData.filter((d) => d.type === SchoolMasterType.BOARDING_GENDER_TYPE)}
                      value={formik.values.boardingGenderType}
                      onChange={(val) => formik.setFieldValue('boardingGenderType', val)}
                    />
                    {formik.touched.boardingGenderType && (
                      <div className="invalid-feedback d-block">{formik.errors.boardingGenderType}</div>
                    )}
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>

          <hr className="mb-4" />

          <CardTitle>Social Profile</CardTitle>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="school_twitter" className="form-label  ">
                  Twitter Handle
                </Label>
                <Input
                  id="school_twitter"
                  type="text"
                  className="form-control"
                  placeholder=""
                  label="Twitter Handle"
                  invalid={!!(formik.touched.twitter && formik.errors.twitter)}
                  {...formik.getFieldProps('twitter')}
                />
                <FormFeedback>{formik.errors.twitter}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="school_facebook" className="form-label  ">
                  Facebook Handle
                </Label>
                <Input
                  id="school_facebook"
                  type="text"
                  className="form-control"
                  placeholder=""
                  label="Facebook Handle"
                  invalid={!!(formik.touched.facebook && formik.errors.facebook)}
                  {...formik.getFieldProps('facebook')}
                />
                <FormFeedback>{formik.errors.facebook}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Management Details</CardTitle>

          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="school_manag_type" className="form-label  ">
                  Management Type
                </Label>
                <Select
                  id="school_manag_type"
                  placeholder="Select an option"
                  options={schoolsMasterData.filter((d) => d.type === SchoolMasterType.MANAGEMENT_TYPE)}
                  value={formik.values.managementType}
                  onChange={(val) => formik.setFieldValue('managementType', val)}
                />
                {formik.touched.managementType && (
                  <div className="invalid-feedback d-block">{formik.errors.managementType}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="school_manag_name" className="form-label  ">
                  Management Name/Trust/Society Name
                </Label>
                <Input
                  id="school_manag_name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  label="Management Name"
                  invalid={!!(formik.touched.managementName && formik.errors.managementName)}
                  {...formik.getFieldProps('managementName')}
                />
                <FormFeedback>{formik.errors.managementName}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <CardTitle>Chairman Details</CardTitle>
          <Row>
            <Col>
              <FormGroup className="mb-4">
                <Label for="school_chairname_name" className="form-label  ">
                  Name
                </Label>
                <Input
                  id="school_chairname_name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  invalid={!!(formik.touched.chairmanName && formik.errors.chairmanName)}
                  {...formik.getFieldProps('chairmanName')}
                />
                <FormFeedback>{formik.errors.chairmanName}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-4">
                <Label for="school_chairname_phone" className="form-label  ">
                  Phone Number
                </Label>
                <PhoneInput
                  country="in"
                  isValid={!(formik.touched.chairmanPhone && formik.errors.chairmanPhone)}
                  enableSearch
                  countryCodeEditable={false}
                  preferredCountries={['in', 'us', 'sg', 'uk']}
                  value={formik.values.chairmanPhone}
                  onChange={(value) => formik.setFieldValue('chairmanPhone', value)}
                />
                {formik.touched.chairmanPhone && (
                  <div className="invalid-feedback d-block">{formik.errors.chairmanPhone}</div>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-4">
                <Label for="school_chairname_email" className="form-label  ">
                  Email
                </Label>
                <Input
                  id="school_chairname_email"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  invalid={!!(formik.touched.chairmanEmail && formik.errors.chairmanEmail)}
                  {...formik.getFieldProps('chairmanEmail')}
                />
                <FormFeedback>{formik.errors.chairmanEmail}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <CardTitle>Principal / Head Details</CardTitle>
          <Row>
            <Col>
              <FormGroup className="mb-4">
                <Label for="school_principal_name" className="form-label  ">
                  Name
                </Label>
                <Input
                  id="school_principal_name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  invalid={!!(formik.touched.principalName && formik.errors.principalName)}
                  {...formik.getFieldProps('principalName')}
                />
                <FormFeedback>{formik.errors.principalName}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-4">
                <Label for="school_principal_phone" className="form-label  ">
                  Phone Number
                </Label>
                <PhoneInput
                  country="in"
                  isValid={!(formik.touched.principalPhone && formik.errors.principalPhone)}
                  enableSearch
                  countryCodeEditable={false}
                  preferredCountries={['in', 'us', 'sg', 'uk']}
                  value={formik.values.principalPhone}
                  onChange={(value) => formik.setFieldValue('principalPhone', value)}
                />
                {formik.touched.principalPhone && (
                  <div className="invalid-feedback d-block">{formik.errors.principalPhone}</div>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-4">
                <Label for="school_principal_email" className="form-label  ">
                  Email
                </Label>
                <Input
                  id="school_principal_email"
                  type="text"
                  className="form-control"
                  placeholder="Email..."
                  invalid={!!(formik.touched.principalEmail && formik.errors.principalEmail)}
                  {...formik.getFieldProps('principalEmail')}
                />
                <FormFeedback>{formik.errors.principalEmail}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="mb-4">
                <Label for="school_hours" className="form-label  ">
                  Office Hours
                </Label>
                <Input
                  id="school_hours"
                  type="textarea"
                  className="form-control"
                  placeholder="9:00 AM to 5:00 PM"
                  invalid={!!(formik.touched.officeHours && formik.errors.officeHours)}
                  {...formik.getFieldProps('officeHours')}
                />
                <FormFeedback>{formik.errors.officeHours}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col md={12}>
              <FormGroup className="mb-4">
                <Label for="school_committee" className="form-label  ">
                  Management Committee
                </Label>
                <TextEditor
                  simple
                  initialValue={formik.values.committee}
                  onChange={(val) => formik.setFieldValue('committee', val)}
                />
                {formik.touched.committee && <div className="invalid-feedback d-block">{formik.errors.committee}</div>}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col md={12}>
              <FormGroup className="mb-4">
                <Label for="school_about" className="form-label  ">
                  About School
                </Label>
                <TextEditor
                  simple
                  initialValue={formik.values.about}
                  onChange={(val) => formik.setFieldValue('about', val)}
                />
                {formik.touched.about && <div className="invalid-feedback d-block">{formik.errors.about}</div>}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {(user?.entityPermissions?.school.admin ||
        (schoolDetail.editable &&
          (schoolDetail?.createdBy?.id === user.id || schoolDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Information
          </Button>
          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.SCHOOL_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

BasicInfo.propTypes = {};

export default BasicInfo;
