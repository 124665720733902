import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { titleCaseIfExists, urlSlugify } from 'utils/helper';
import { api } from '../../utils/axios';
import { GET_USER_ROLE } from 'utils/constants';
import { useRouter } from 'hooks/useRouter';

const BlogSmkAsyncSelect = (props) => {
  const { isClearable = true, isValueSlugify = false, apiMethod = 'POST' } = props;
  const indicatorSeparatorStyle = {
    alignSelf: 'stretch',
    backgroundColor: 'transparent',
    marginBottom: 8,
    marginTop: 8,
    width: 1,
  };
  const [inputValue, setInputValue] = useState('');

  const promiseOptions = (inputValue) => {
    if (inputValue.length > 2) {
      return new Promise((resolve) => {
        api({
          method: apiMethod,
          url: props.fetchUrl + (props?.isQueryParam ? `?${props.searchKeyName}=${inputValue}` : ''),
          data: {
            [props.searchKeyName]: isValueSlugify ? urlSlugify(inputValue) : inputValue,
            ...props.filters,
          },
        }).then((response) => {
          const { data } = response;
          let results = response?.results;
          let formatData;

          if (isEmpty(data)) {
            formatData = results?.map((instance) => {
              const combine_label = !isEmpty(props?.combineLabelKey)
                ? props?.combineLabelKey === 'role'
                  ? `${instance[props?.acceptedKey]} - ${titleCaseIfExists(
                      GET_USER_ROLE[instance[props?.combineLabelKey]]
                    )}`
                  : instance[props?.combineLabelKey] + ' - ' + instance[props?.acceptedKey]
                : '';

              return {
                ...instance,
                label: !isEmpty(combine_label)
                  ? combine_label
                  : !isEmpty(props?.acceptedKey)
                  ? instance[props?.acceptedKey]
                  : instance?.name,
                value: !isEmpty(props?.acceptedValue) ? instance[props?.acceptedValue] : instance?.name,
              };
            });
          } else {
            formatData = data?.results?.map((instance) => ({
              ...instance,
              label: instance.name,
              value: instance.name,
            }));
          }

          setTimeout(() => {
            resolve(formatData);
          }, 1000);
        });
      });
    } else {
      return Promise.resolve([]);
    }
  };

  const handleChange = (newValue) => {
    if (props.onChange) {
      props.onChange(newValue);
    }
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    return newValue;
  };

  const IndicatorSeparator = ({ innerProps }) => {
    return <span style={indicatorSeparatorStyle} {...innerProps} />;
  };

  const handleBlur = () => {
    if (props.onBlur) {
      props.onBlur(props.id, true);
    }
  };

  return (
    <>
      {props.creatable && (
        <AsyncCreatableSelect
          components={{ DropdownIndicator: null, IndicatorSeparator }}
          inputValue={inputValue}
          isClearable={isClearable}
          isMulti={props.multi}
          onChange={handleChange}
          onBlur={handleBlur}
          onInputChange={handleInputChange}
          loadOptions={promiseOptions}
          placeholder={props.placeholder}
          value={props.value}
          noOptionsMessage={() => null}
          menuPlacement="top"
        />
      )}
      {!props.creatable && (
        <AsyncSelect
          id={props.id}
          isMulti={props.multi}
          components={{ DropdownIndicator: null, IndicatorSeparator }}
          isClearable={isClearable}
          onChange={handleChange}
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: '1px solid #ced4da',
            }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onBlur={handleBlur}
          loadOptions={promiseOptions}
          placeholder={props.placeholder}
          isDisabled={props.isDisabled}
          noOptionsMessage={() => null}
          value={props.value || null}
        />
      )}
    </>
  );
};

BlogSmkAsyncSelect.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.array,
  // options: PropTypes.object, // not being used
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  small: PropTypes.bool,
  bordered: PropTypes.bool,
  multi: PropTypes.bool,
  isDisabled: PropTypes.func,
  creatable: PropTypes.bool,
  fetchUrl: PropTypes.string,
  filters: PropTypes.object,
  searchKeyName: PropTypes.string,
};

export default BlogSmkAsyncSelect;
