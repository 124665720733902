import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import { useFormik } from 'formik';
import { useRouter } from 'hooks/useRouter';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { LANGUAGE_ARRAY, medicineStrengthUnits, medicineTypes, taxonomyType } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import useRequest from '../../../../hooks/useRequest';
import { SMKLinks } from '../../../../utils/links';

const UpdateMedicine = () => {
  const history = useHistory();
  const { id: medicineID } = useParams();
  const router = useRouter();

  const { query } = router;

  toast.configure();

  const [medicineDetails, setmedicineDetails] = useState();
  const [medicineTypeList, setmedicineTypeList] = useState([]);
  const [medicineSystemList, setmedicineSystemList] = useState([]);
  const [medicineClassificationList, setmedicineClassificationList] = useState([]);

  // Dropdown Api Call
  const [getmedicineTypeListRequest, { loading: getmedicineTypeListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setmedicineTypeList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [getmedicineSystemListRequest, { loading: getmedicineSystemListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setmedicineSystemList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [getmedicineClassificationListRequest, { loading: getmedicineClassificationListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setmedicineClassificationList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getMedicineRequest, { loading: getMedicineLoading }] = useRequest({
    url: `/medicine/admin/${medicineID}`,

    method: 'GET',
    onSuccess: (data) => {
      setmedicineDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [addMedicineRequest, { loading: addMedicineLoading }] = useRequest({
    url: `/medicine/admin`,

    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.MEDICINES_LISTING;
      history.replace(url);
      toast.success('Success! Medicine has been added.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [UpdateMedicineRequest, { loading: UpdateMedicineLoading }] = useRequest({
    url: `/medicine/admin/${medicineID}`,
    method: 'PUT',

    onSuccess: (data) => {
      const url = SMKLinks.MEDICINES_LISTING;
      history.replace(url);
      toast.success('Success! Medicine has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const editInitialValues = () => {
    const typeValue = Object.keys(medicineTypes)
      ?.map((e) => ({
        label: titleCaseIfExists(e),
        value: medicineTypes[e],
      }))
      ?.find((elem) => elem?.value === medicineDetails?.type);

    const strengthUnitValue = Object.keys(medicineStrengthUnits)
      ?.map((e) => ({
        label: titleCaseIfExists(e),
        value: medicineStrengthUnits[e],
      }))
      ?.find((elem) => elem?.value === medicineDetails?.strengthUnit);

    const medicineTypeIdValue = medicineTypeList?.find((e) => e?.value === medicineDetails?.medicineType?.id);

    const medicineSystemValue = !isEmpty(medicineDetails?.medicineSystems)
      ? medicineDetails?.medicineSystems?.map((e) => ({
          label: e?.name,
          value: e?.id,
        }))
      : [];

    const classificationsValue = !isEmpty(medicineDetails?.classifications)
      ? medicineDetails?.classifications?.map((e) => ({
          label: e?.name,
          value: e?.id,
        }))
      : [];

    return {
      id: medicineDetails?.id,
      active: medicineDetails?.active,
      deleted: medicineDetails?.deleted,
      rxRequried: medicineDetails?.rxRequried,
      name: medicineDetails?.name,
      slug: medicineDetails?.slug,
      description: medicineDetails?.description,
      manufacturerName: medicineDetails?.manufacturerName,
      packSize: medicineDetails?.packSize,
      composition: medicineDetails?.composition,
      strength: medicineDetails?.strength,
      language: LANGUAGE_ARRAY?.find((e) => e?.value === medicineDetails?.language),
      type: typeValue,
      medicineTypeId: medicineTypeIdValue,
      medicineSystems: medicineSystemValue,
      classifications: classificationsValue,
      strengthUnit: strengthUnitValue,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    language: { value: 0, label: 'English' },
    rxRequried: '',
    name: '',
    slug: '',
    type: null,
    medicineTypeId: '',
    medicineSystems: [],
    classifications: [],
    description: '',
    manufacturerName: '',
    packSize: '',
    composition: '',
    strength: '',
    strengthUnit: null,
  });

  const isEmptyValue = (val) => (val !== null || val !== undefined ? val : undefined);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(medicineID) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: async (values) => {
      const data = {
        active: values?.active || false,
        deleted: values?.deleted || false,
        language: values?.language?.value || 0,
        name: isEmptyValue(values?.name),
        slug: isEmptyValue(values?.slug),
        type: isEmptyValue(values?.type?.value),
        medicineType: !isEmpty(values?.medicineTypeId) ? { id: values?.medicineTypeId?.value } : null,
        medicineSystems: !isEmpty(values?.medicineSystems)
          ? [...values?.medicineSystems?.map((e) => ({ id: e?.value }))]
          : [],

        classifications: !isEmpty(values?.classifications)
          ? [...values?.classifications?.map((e) => ({ id: e?.value }))]
          : [],
        manufacturerName: isEmptyValue(values?.manufacturerName),
        packSize: isEmptyValue(values?.packSize),
        composition: isEmptyValue(values?.composition),
        description: isEmptyValue(values?.description),
        rxRequried: isEmptyValue(values?.rxRequried),
        strength: values?.strength == '' ? null : values?.strength,
        strengthUnit: values?.strengthUnit == null ? {} : values?.strengthUnit?.value,
      };

      if (isEmpty(medicineID)) {
        addMedicineRequest({
          body: {
            ...data,
          },
        });
      } else {
        UpdateMedicineRequest({
          body: {
            ...data,
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
      type: Yup.object()
        .required('Please provide type')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(medicineID)) {
      getMedicineRequest();
    }
  }, [medicineID]);

  // Dropdowns data api call
  useEffect(() => {
    const searchPayload = {
      page: 1,
      size: 1000,
      sortBy: 'name',
      sortOrder: 0,
    };
    getmedicineTypeListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.MEDICINE_TYPE,
      },
    });
    getmedicineSystemListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.MEDICINE_SYSTEM,
      },
    });
    getmedicineClassificationListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.MEDICINE_CLASSFICATION,
      },
    });
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getmedicineTypeListLoading ||
            getmedicineSystemListLoading ||
            getmedicineClassificationListLoading ||
            addMedicineLoading ||
            getMedicineLoading ||
            UpdateMedicineLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="Medicine" breadcrumbItem={`${isEmpty(medicineID) ? 'Add' : 'Edit'}  Medicine`} />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="name" className="form-label  ">
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="name..."
                            invalid={!!(formik.touched.name && formik.errors.name)}
                            {...formik.getFieldProps('name')}
                          />
                          <FormFeedback className="d-block">{formik.errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12} className="mb-4">
                        <SlugInput
                          formik={formik}
                          ID={medicineID}
                          nameValue={formik.values.name}
                          formGroupClassName="mb-0"
                        />
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="type" className="form-label  ">
                            Type <span className="text-danger">*</span>
                          </Label>
                          <Select
                            options={Object.keys(medicineTypes)?.map((e) => ({
                              label: titleCaseIfExists(e),
                              value: medicineTypes[e],
                            }))}
                            placeholder="Select type..."
                            value={formik.values.type}
                            onChange={(val) => formik.setFieldValue('type', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.type}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="medicineTypeId" className="form-label  ">
                            Medicine type
                          </Label>

                          <Select
                            isClearable
                            placeholder="Select medicine type..."
                            options={medicineTypeList}
                            value={formik.values.medicineTypeId}
                            onChange={(val) => formik.setFieldValue('medicineTypeId', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.medicineTypeId}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="medicineSystems" className="form-label  ">
                            Medicine system
                          </Label>
                          <Select
                            isMulti
                            placeholder="Select medicine system..."
                            options={medicineSystemList}
                            value={formik.values.medicineSystems}
                            onChange={(val) => formik.setFieldValue('medicineSystems', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.medicineSystems}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="classifications" className="form-label  ">
                            Classifications
                          </Label>
                          <Select
                            isMulti
                            placeholder="Select classifications..."
                            options={medicineClassificationList}
                            value={formik.values.classifications}
                            onChange={(val) => formik.setFieldValue('classifications', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.classifications}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="manufacturerName" className="form-label  ">
                            Manufacturer name
                          </Label>
                          <Input
                            id="manufacturerName"
                            name="manufacturerName"
                            type="text"
                            className="form-control"
                            placeholder="Enter manufacturer name..."
                            invalid={!!(formik.touched.manufacturerName && formik.errors.manufacturerName)}
                            {...formik.getFieldProps('manufacturerName')}
                          />
                          <FormFeedback className="d-block">{formik.errors.manufacturerName}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="packSize" className="form-label  ">
                            Pack size
                          </Label>
                          <Input
                            id="packSize"
                            name="packSize"
                            type="text"
                            className="form-control"
                            placeholder="Enter pack size..."
                            invalid={!!(formik.touched.packSize && formik.errors.packSize)}
                            {...formik.getFieldProps('packSize')}
                          />
                          <FormFeedback className="d-block">{formik.errors.packSize}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="rxRequried" className="form-label  ">
                            Rx requried
                          </Label>
                          <Input
                            id="rxRequried"
                            name="rxRequried"
                            type="text"
                            className="form-control"
                            placeholder="Enter Rx requried..."
                            invalid={!!(formik.touched.rxRequried && formik.errors.rxRequried)}
                            {...formik.getFieldProps('rxRequried')}
                          />
                          <FormFeedback className="d-block">{formik.errors.rxRequried}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="strength" className="form-label  ">
                            Strength
                          </Label>
                          <Input
                            id="strength"
                            name="strength"
                            type="number"
                            className="form-control"
                            placeholder="Enter strength..."
                            invalid={!!(formik.touched.strength && formik.errors.strength)}
                            {...formik.getFieldProps('strength')}
                          />
                          <FormFeedback className="d-block">{formik.errors.strength}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="strengthUnit" className="form-label  ">
                            Strength unit
                          </Label>
                          <Select
                            isClearable
                            options={Object.keys(medicineStrengthUnits)?.map((e) => ({
                              label: titleCaseIfExists(e),
                              value: medicineStrengthUnits[e],
                            }))}
                            placeholder="Select Strength unit..."
                            value={formik.values.strengthUnit}
                            onChange={(val) => formik.setFieldValue('strengthUnit', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.strengthUnit}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="composition" className="form-label  ">
                            Composition
                          </Label>
                          <Input
                            id="composition"
                            name="composition"
                            type="textarea"
                            rows={5}
                            className="form-control"
                            placeholder="Enter composition..."
                            invalid={!!(formik.touched.composition && formik.errors.composition)}
                            {...formik.getFieldProps('composition')}
                          />
                          <FormFeedback className="d-block">{formik.errors.composition}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="description" className="form-label">
                            Description
                          </Label>
                          <Input
                            id="description"
                            name="description"
                            type="textarea"
                            rows={5}
                            className="form-control"
                            placeholder="Enter description..."
                            invalid={!!(formik.touched.description && formik.errors.description)}
                            {...formik.getFieldProps('description')}
                          />
                          <FormFeedback className="d-block">{formik.errors.description}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <div>
                      <Button type="submit" color="primary" className="w-100 mb-3">
                        {medicineID ? 'Save Medicine' : 'Add Medicine'}
                      </Button>

                      <Button
                        className="w-100"
                        color="light"
                        onClick={() => {
                          history.replace(SMKLinks.MEDICINES_LISTING);
                        }}>
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Col md={12}>
                      <CardTitle className="mb-3">Active </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>

                    <Col md={12}>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="language" className="form-label">
                          Language
                        </Label>
                        <Select
                          id="language"
                          options={LANGUAGE_ARRAY}
                          value={formik.values.language}
                          onChange={(language) => formik.setFieldValue('language', language)}
                        />
                        <FormFeedback className="d-block">{formik.errors.language}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateMedicine;
