import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect, useSelector } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

// users
import { createStructuredSelector } from 'reselect';
import avatar2 from '../../../assets/images/users/avatar-2.jpg';
import { isLoggedInSelector, userSelector } from '../../../common/globalComponents/selectors';

const stateSelector = createStructuredSelector({
  isLoggedIn: isLoggedInSelector,
  user: userSelector,
});

const ProfileMenu = (props) => {
  const { user, isLoggedIn } = useSelector(stateSelector);
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState('');

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.displayName);
      } else if (process.env.REACT_APP_DEFAULTAUTH === 'fake' || process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
        const obj = JSON.parse(localStorage.getItem('authUser'));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  return (
    <>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle
          className="btn header-item waves-effect d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button">
          <img className="rounded-circle header-profile-user" src={user?.socialImageUrl || avatar2} />
          <span className="d-none d-xl-inline-block ms-2 me-1 text-start">
            {isLoggedIn ? `${user?.firstName} ${user?.lastName}` : ''}
            <br />
            {user?.roles[0]?.name}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            <i className="bx bx-user font-size-16 align-middle me-2" />
            {props.t('Profile')}{' '}
          </DropdownItem> */}

          {/* <div className="dropdown-divider" /> */}
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-2 text-danger" />
            <span>{props.t('Logout')}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  // const { error, success } = state.Profile
  const { error, success } = {};
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)));
