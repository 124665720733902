import Loader from 'components/Loader';
import useRequest from 'hooks/useRequest';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Badge, Button, Col, Container, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';
import { printDate, titleCaseIfExists } from 'utils/helper';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useRouter } from 'hooks/useRouter';
import TableError from 'components/TableError';

const MismatchSitemap = () => {
  toast.configure();
  const router = useRouter();

  const [sitemapsList, setSitemapsList] = useState([]);

  const isSMK = router?.pathname?.startsWith('/smk');
  const isTPZ = router?.pathname?.startsWith('/tpz');

  const tpz_tabsList = [
    { name: 'Baby Names', slug: 'babyNames' },
    { name: 'Forum', slug: 'forum' },
    { name: 'The Parentz', slug: 'theparentz' },
  ];

  const smk_tabsList = [
    { name: 'Calculator', slug: 'calculator' },
    { name: 'School', slug: 'school' },
    { name: 'Worksheet', slug: 'worksheet' },
    { name: 'Ptable', slug: 'ptable' },
    { name: 'Forum', slug: 'forum' },
    { name: 'Schoolmykids', slug: 'schoolmykids' },
  ];

  const tabsList = isSMK ? smk_tabsList : isTPZ ? tpz_tabsList : [];

  const [activeTab, setActiveTab] = useState(tabsList[0]?.slug);

  const [getMistmatchURL, { loading: getMistmatchURLLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      if (data?.success !== false) {
        setSitemapsList(data);
      }
    },
    onError: (err) => console.log(`Error occurred: ${err}`),
  });

  const [approveSitemapCall, { loading: approveSitemapCallLoading }] = useRequest({
    body: {},
    method: 'POST',
    onSuccess: () => {
      toast.success(`${titleCaseIfExists(activeTab)} Sitemap Approved Successfully`);
      window.location.reload();
    },
    onError: (err) => console.log(`Error occurred: ${err}`),
  });

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleApproveSitemap = () => {
    approveSitemapCall({
      url: `/admin-tools/sitemap/${activeTab}/approve`,
    });
  };

  useEffect(() => {
    if (activeTab) {
      getMistmatchURL({
        url: `/admin-tools/sitemap/sitemap-url/${activeTab}/mismatch`,
      });
    }
  }, [activeTab]);

  const renderTable = (dataList) => {
    return (
      <div className="table-responsive" style={{ minHeight: 300 }}>
        <Table className="project-list-table   table-centered table-borderless">
          <thead>
            <tr className="table-warning">
              <th scope="col">S.No.</th>
              <th scope="col">Id</th>
              <th scope="col">UID</th>
              <th scope="col">URL</th>
              <th scope="col">Version</th>
              <th scope="col">Module</th>
              <th scope="col">Sitemap</th>
              <th scope="col">Language</th>
              <th scope="col">Created date</th>
              <th scope="col">Updated date</th>
              <th scope="col">Active </th>
              <th scope="col">Deleted </th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(dataList) ? (
              dataList?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{data?.id}</td>
                    <td>{data?.uid}</td>
                    <td>{data?.url}</td>
                    <td>{data?.version}</td>
                    <td>{data?.module}</td>
                    <td>{data?.sitemap}</td>
                    <td>{data?.language}</td>
                    <td>{printDate(data?.created_date)}</td>
                    <td>{printDate(data?.updated_date)}</td>
                    <td>
                      <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>{data?.active ? 'Yes' : 'No'}</Badge>
                    </td>
                    <td>
                      <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                        {data?.deleted ? 'Yes' : 'No'}
                      </Badge>
                    </td>
                  </tr>
                );
              })
            ) : (
              <TableError />
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <div className="page-content" style={{ minHeight: '70vh' }}>
      <Loader isActive={getMistmatchURLLoading || approveSitemapCallLoading} />
      <Container fluid>
        <Breadcrumbs title="System" breadcrumbItem="Generate Schoolmykids Sitemap" />
        <div className="mt-4">
          <Nav tabs>
            {tabsList.map((tab) => (
              <NavItem key={tab.slug}>
                <NavLink
                  className={`${activeTab === tab.slug ? 'active' : ''}`}
                  onClick={() => {
                    toggle(tab.slug);
                  }}>
                  <span style={activeTab === tab.slug ? { fontWeight: 600 } : {}}> {tab.name}</span>
                </NavLink>
              </NavItem>
            ))}
          </Nav>
          <TabContent activeTab={activeTab}>
            {tabsList.map((tab) => (
              <TabPane tabId={tab.slug} key={tab.slug}>
                <div className="d-flex align-items-center justify-content-end mt-3">
                  <Button color="success" onClick={handleApproveSitemap}>
                    Approve Sitemap
                  </Button>
                </div>
              </TabPane>
            ))}
          </TabContent>
        </div>

        {!isEmpty(sitemapsList) && (
          <Row>
            <Col md={6}>
              <h5>{sitemapsList?.missingInOld?.length} data found</h5>
              {renderTable(sitemapsList?.missingInOld)}
            </Col>
            <Col md={6}>
              <h5>{sitemapsList?.missingInNew?.length} data found</h5>
              {renderTable(sitemapsList?.missingInNew)}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default MismatchSitemap;
