import { PUBLIC_URL } from '../../utils/constants';

export default {
  TOPIC_LIST: `${PUBLIC_URL}/forum/topic`,
  TOPIC_DETAIL: `${PUBLIC_URL}/forum/topic/detail/:id`,
  TOPIC_EDIT: `${PUBLIC_URL}/forum/topic/edit/:id`,
  TOPIC_APPROVE: `${PUBLIC_URL}/forum/topic/approve`,
  POST_LIST: `${PUBLIC_URL}/forum/post`,
  POST_DETAIL: `${PUBLIC_URL}/forum/post/detail/:id`,
  POST_EDIT: `${PUBLIC_URL}/forum/post/edit/:id`,
  POST_APPROVE: `${PUBLIC_URL}/forum/post/approve`,
  CATEGORY_ADD: `${PUBLIC_URL}/forum/category/add`,
  CATEGORY_EDIT: `${PUBLIC_URL}/forum/category/edit/:id`,
  CATEGORY_LIST: `${PUBLIC_URL}/forum/category`,
};
