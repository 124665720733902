import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Container } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
// actions
import { getWorksheetDetail } from '../actions';
// selectors
import { worksheetDetailSelector } from '../selectors';
// components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import SingleWorksheetForm from '../components/SingleWorksheetForm';

const stateSelector = createStructuredSelector({
  worksheetDetail: worksheetDetailSelector,
});

const EditWorksheet = () => {
  const { worksheetDetail } = useSelector(stateSelector);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWorksheetDetail.trigger({ id }));
  }, [id]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Worksheets" breadcrumbItem="Edit Worksheet" />

          {!isEmpty(worksheetDetail) && worksheetDetail?.id == id && (
            <SingleWorksheetForm worksheet={worksheetDetail} />
          )}
        </Container>
      </div>
    </>
  );
};

export default EditWorksheet;
