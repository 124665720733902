import Breadcrumbs from 'components/Common/Breadcrumb';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Paginate from 'components/Paginate';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ACTIVE_AND_DELETED_ARRAY, LANGUAGE_VALUES, MODULE_TYPE, SORT_VALUES } from 'utils/constants';
import Loader from '../../../../components/Loader';
import useRequest from '../../../../hooks/useRequest';
import TableError from 'components/TableError';
import { invert, isEmpty } from 'lodash';
import { Divider } from '@material-ui/core';
import { buildURL, checkIfValueExist, printDate, printDateTime, printDateTimeTwoLines } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import { useRouter } from 'hooks/useRouter';

const BabyTrackingActivityList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [pageviewPagination, setpageviewPagination] = useState({});
  const [pageviewList, setpageviewList] = useState();

  const [pageviewListRequest, { loading: pageviewListLoading }] = useRequest({
    method: 'POST',
    url: `/common/pageView/admin/search`,
    onSuccess: (data) => {
      setpageviewList(data?.results);
      setpageviewPagination(data?.pagination);
    },
  });

  const defaultPayload = {
    page: 1,
    size: 20,
    ...SORT_VALUES,
  };

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    pageviewListRequest({
      body: {
        page: page,
        size: 50,
        ...SORT_VALUES,
      },
    });
  };

  const applyFilters = (obj) => {
    setPageInUrl(1);

    pageviewListRequest({
      body: {
        page: 1,
        size: 50,
        ...SORT_VALUES,
        active: checkIfValueExist(obj?.active?.value),
        deleted: checkIfValueExist(obj?.deleted?.value),
        source: checkIfValueExist(obj?.source?.value),
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      active: { value: null, label: 'All' },
      deleted: { value: null, label: 'All' },
      source: { value: null, label: 'All' },
    },
    onSubmit: (values) => {
      applyFilters(values);
    },
  });

  const clearFilters = () => {
    setPageInUrl(1);
    formik.resetForm();

    pageviewListRequest({
      body: {
        ...defaultPayload,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={pageviewListLoading} />
        <Container fluid>
          <Breadcrumbs title="Pageview" breadcrumbItem="Baby-Tracking Activity List" />
          <Card>
            <CardBody>
              <Form onSubmit={formik.handleSubmit}>
                <div className="mt-4">
                  <div className="row" style={{ gap: '20px' }}>
                    <div className="col-lg-2">
                      <Label className="form-label">Active</Label>
                      <Select
                        options={ACTIVE_AND_DELETED_ARRAY}
                        value={formik?.values?.active}
                        onChange={(active) => formik.setFieldValue('active', active)}
                      />
                    </div>

                    <div className="col-lg-2">
                      <Label className="form-label">Deleted</Label>
                      <Select
                        options={ACTIVE_AND_DELETED_ARRAY}
                        value={formik?.values?.deleted}
                        onChange={(deleted) => formik.setFieldValue('deleted', deleted)}
                      />
                    </div>
                    <div className="col-lg-2">
                      <Label className="form-label">Source</Label>
                      <Select
                        options={[
                          { value: null, label: 'All' },
                          { value: 'schoolmykids', label: 'Schoolmykids' },
                          { value: 'theparentz', label: 'Theparentz' },
                        ]}
                        value={formik?.values?.source}
                        onChange={(source) => formik.setFieldValue('source', source)}
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-4">
                  <button className="btn btn-primary me-4" type="submit">
                    Apply Filters
                  </button>
                  <a className="" type="reset" onClick={clearFilters}>
                    Clear
                  </a>
                </div>
              </Form>
            </CardBody>
          </Card>

          <Row className="mb-2  align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2">
                <div className="position-relative font-bold">
                  {pageviewPagination?.totalCount} Baby Tracking Activities Found
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">ID</th>
                        <th scope="col">Source</th>
                        <th scope="col">URL</th>
                        <th scope="col">Country name</th>
                        <th scope="col">State</th>
                        <th scope="col">City</th>
                        <th scope="col">Postal</th>
                        <th scope="col">Ipv4</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(pageviewList) ? (
                        pageviewList?.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>{data?.source}</td>
                            <td>{data?.url}</td>
                            <td>{data?.location?.countryName + `(${data?.location?.countryCode})`}</td>
                            <td>{data?.location?.state}</td>
                            <td>{data?.location?.city}</td>
                            <td>{data?.location?.postal}</td>
                            <td>{data?.location?.ipv4}</td>
                            <td>{printDateTimeTwoLines(data?.createdDate)}</td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={pageviewPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default BabyTrackingActivityList;
