import Loader from 'components/Loader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import SlugInput from 'components/SlugInput';
import Select from 'react-select';
import { SMKLinks } from 'utils/links';
import { getCollegesCoursesMasterData } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCollegesCoursesMasterData } from '../selectors';
import { createStructuredSelector } from 'reselect';

const stateSelector = createStructuredSelector({
  collegeCoursesMasterData: selectCollegesCoursesMasterData,
});

const UpdateCollegeCourses = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: courseId } = useParams();

  toast.configure();

  const { collegeCoursesMasterData } = useSelector(stateSelector);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [courseLevelsList, setcourseLevelsList] = useState([]);
  const [courseDegreesList, setCourseDegreesList] = useState([]);

  const [collegeCourseDetails, setcollegeCourseDetails] = useState();

  const [addCourseRequest, { loading: addCourseLoading }] = useRequest({
    url: `/college/admin/course`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.COLLEGE_COURSES;
      history.replace(url);
      toast.success('Success! College Courses has been added.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [updateCourseRequest, { loading: updateCourseLoading }] = useRequest({
    url: `/college/admin/course/${courseId}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.COLLEGE_COURSES;
      history.replace(url);
      toast.success('Success! College Courses has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getCollegeCourseRequest, { loading: getCollegeCourseLoading }] = useRequest({
    url: `/college/admin/course/${courseId}`,
    method: 'GET',
    onSuccess: (data) => {
      setcollegeCourseDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const getInitialValues = {
    active: true,
    deleted: false,
    id: '',
    name: '',
    slug: '',
    shortName: '',
    displayName: '',
    department: '',
    duration: '',
    prerequisite: '',
    courseType: '',
    courseLevel: '',
    courseDegree: '',
    trainingDuration: '',
    durationNotes: '',
    description: '',
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues,
    onSubmit: async (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        name: values?.name,
        slug: values?.slug,
        shortName: values?.shortName,
        displayName: values?.displayName,
        department: values?.department,
        duration: values?.duration,
        prerequisite: values?.prerequisite,
        typeId: values?.courseType?.value,
        levelId: values?.courseLevel?.value,
        degreeTypeId: values?.courseDegree?.value,
        trainingDuration: values?.trainingDuration,
        durationNotes: values?.durationNotes,
        description: values?.description,
      };

      if (isEmpty(courseId)) {
        addCourseRequest({
          body: {
            ...data,
          },
        });
      } else {
        updateCourseRequest({
          body: {
            ...data,
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide Name'),
      slug: Yup.string().required('Please provide Slug'),
      courseType: Yup.object().required('Please provide Course Type'),
      courseLevel: Yup.object().required('Please provide Course Level'),
      courseDegree: Yup.object().required('Please provide Course Degree'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(courseId)) {
      getCollegeCourseRequest();
    }
  }, [courseId]);

  useEffect(() => {
    dispatch(getCollegesCoursesMasterData.trigger({}));
  }, []);

  useEffect(() => {
    if (!isEmpty(collegeCoursesMasterData)) {
      setCourseTypeList(
        collegeCoursesMasterData?.map((e) => ({
          label: e.displayName,
          value: e?.id,
        }))
      );
    }
  }, [collegeCoursesMasterData]);

  useEffect(() => {
    if (!isEmpty(formik.values.courseType)) {
      setcourseLevelsList(
        collegeCoursesMasterData
          ?.find((e) => e?.id === formik?.values?.courseType?.value)
          ?.course_level?.map((e) => ({
            label: e.displayName,
            value: e?.id,
          }))
      );
    }
  }, [formik.values.courseType]);

  useEffect(() => {
    if (!isEmpty(formik.values.courseType) && !isEmpty(formik.values.courseLevel)) {
      // formik.setValues({ ...formik?.values, courseDegree: '' });
      setCourseDegreesList(
        collegeCoursesMasterData
          ?.find((e) => e?.id === formik?.values?.courseType?.value)
          ?.course_level?.find((e) => e?.id === formik?.values?.courseLevel?.value)
          ?.course_degree?.map((e) => ({
            label: e.displayName,
            value: e?.id,
          }))
      );
    }
  }, [formik.values.courseLevel]);

  useEffect(() => {
    if (!isEmpty(collegeCourseDetails)) {
      const editInitialValues = {
        id: collegeCourseDetails?.id,
        active: collegeCourseDetails?.active,
        deleted: collegeCourseDetails?.deleted,
        name: collegeCourseDetails?.name,
        slug: collegeCourseDetails?.slug,
        shortName: collegeCourseDetails?.shortName,
        displayName: collegeCourseDetails?.displayName,
        department: collegeCourseDetails?.department,
        duration: collegeCourseDetails?.duration,
        prerequisite: collegeCourseDetails?.prerequisite,
        courseType: !isEmpty(collegeCourseDetails?.type)
          ? {
              label: collegeCourseDetails?.type?.displayName,
              value: collegeCourseDetails?.type?.id,
            }
          : {},
        courseLevel: !isEmpty(collegeCourseDetails?.level)
          ? {
              label: collegeCourseDetails?.level?.displayName,
              value: collegeCourseDetails?.level?.id,
            }
          : {},
        courseDegree: !isEmpty(collegeCourseDetails?.degreeType)
          ? {
              label: collegeCourseDetails?.degreeType?.displayName,
              value: collegeCourseDetails?.degreeType?.id,
            }
          : {},
        trainingDuration: collegeCourseDetails?.trainingDuration,
        durationNotes: collegeCourseDetails?.durationNotes,
        description: collegeCourseDetails?.description,
      };
      formik.setValues(editInitialValues);
    }
  }, [collegeCourseDetails]);

  return (
    <div className="page-content">
      <Loader isActive={getCollegeCourseLoading || addCourseLoading || updateCourseLoading} />
      <Container fluid>
        <Breadcrumbs title="College Course" breadcrumbItem={`${isEmpty(courseId) ? 'Add' : 'Edit'}  College Course`} />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="name" className="form-label  ">
                          Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Enter Name..."
                          invalid={!!(formik.touched.name && formik.errors.name)}
                          {...formik.getFieldProps('name')}
                        />
                        <FormFeedback className="d-block">{formik.errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6} className="mb-4">
                      <SlugInput
                        required={true}
                        formik={formik}
                        nameValue={formik.values.name}
                        formGroupClassName="mb-0"
                      />
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="displayName" className="form-label  ">
                          Display name
                        </Label>
                        <Input
                          id="displayName"
                          name="displayName"
                          type="text"
                          className="form-control"
                          placeholder="Enter Display name..."
                          invalid={!!(formik.touched.displayName && formik.errors.displayName)}
                          {...formik.getFieldProps('displayName')}
                        />
                        <FormFeedback className="d-block">{formik.errors.displayName}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="shortName" className="form-label  ">
                          Short name
                        </Label>
                        <Input
                          id="shortName"
                          name="shortName"
                          type="text"
                          className="form-control"
                          placeholder="Enter Short name..."
                          invalid={!!(formik.touched.shortName && formik.errors.shortName)}
                          {...formik.getFieldProps('shortName')}
                        />
                        <FormFeedback className="d-block">{formik.errors.shortName}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="duration" className="form-label">
                          Duration
                        </Label>
                        <Input
                          id="duration"
                          name="duration"
                          type="text"
                          className="form-control"
                          placeholder="Enter Duration..."
                          invalid={!!(formik.touched.duration && formik.errors.duration)}
                          {...formik.getFieldProps('duration')}
                        />
                        <FormFeedback className="d-block">{formik.errors.duration}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="trainingDuration" className="form-label">
                          Training Duration
                        </Label>
                        <Input
                          id="trainingDuration"
                          name="trainingDuration"
                          type="text"
                          className="form-control"
                          placeholder="Enter trainingDuration..."
                          invalid={!!(formik.touched.trainingDuration && formik.errors.trainingDuration)}
                          {...formik.getFieldProps('trainingDuration')}
                        />
                        <FormFeedback className="d-block">{formik.errors.trainingDuration}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="durationNotes" className="form-label  ">
                          Duration Notes
                        </Label>
                        <Input
                          id="durationNotes"
                          name="durationNotes"
                          type="text"
                          className="form-control"
                          placeholder="Enter Short name..."
                          invalid={!!(formik.touched.durationNotes && formik.errors.durationNotes)}
                          {...formik.getFieldProps('durationNotes')}
                        />
                        <FormFeedback className="d-block">{formik.errors.durationNotes}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="department" className="form-label  ">
                          Department
                        </Label>
                        <Input
                          id="department"
                          name="department"
                          type="text"
                          className="form-control"
                          placeholder="Enter Department..."
                          invalid={!!(formik.touched.department && formik.errors.department)}
                          {...formik.getFieldProps('department')}
                        />
                        <FormFeedback className="d-block">{formik.errors.department}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={4}>
                      <FormGroup className="mb-4">
                        <label className="form-label">
                          Course Type <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={courseTypeList}
                          value={formik.values.courseType}
                          onChange={(value) => {
                            formik.setValues({ ...formik?.values, courseLevel: '', courseDegree: '' });
                            formik.setFieldValue('courseType', value);
                          }}
                        />
                        <FormFeedback className="d-block">{formik.errors.courseType}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="mb-4">
                        <label className="form-label">
                          Course Level <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={courseLevelsList}
                          value={formik.values.courseLevel}
                          onChange={(value) => {
                            formik.setValues({ ...formik?.values, courseDegree: '' });
                            formik.setFieldValue('courseLevel', value);
                          }}
                        />
                        <FormFeedback className="d-block">{formik.errors.courseLevel}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="mb-4">
                        <label className="form-label">
                          Course Degree <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={courseDegreesList}
                          value={formik.values.courseDegree}
                          onChange={(value) => formik.setFieldValue('courseDegree', value)}
                        />
                        <FormFeedback className="d-block">{formik.errors.courseDegree}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="prerequisite" className="form-label">
                          Prerequisite
                        </Label>
                        <Input
                          id="prerequisite"
                          name="prerequisite"
                          type="textarea"
                          className="form-control"
                          placeholder="Enter Prerequisite..."
                          invalid={!!(formik.touched.prerequisite && formik.errors.prerequisite)}
                          {...formik.getFieldProps('prerequisite')}
                        />
                        <FormFeedback className="d-block">{formik.errors.prerequisite}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="description" className="form-label">
                          Description
                        </Label>
                        <Input
                          id="description"
                          name="description"
                          type="textarea"
                          rows={5}
                          className="form-control"
                          placeholder="Enter Description..."
                          invalid={!!(formik.touched.description && formik.errors.description)}
                          {...formik.getFieldProps('description')}
                        />
                        <FormFeedback className="d-block">{formik.errors.description}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div>
                    <Button type="submit" color="primary" className="mb-3 w-100">
                      {courseId ? 'Save Course' : 'Add Course'}
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.COLLEGE_COURSES);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={12} className="mb-3">
                      <CardTitle>Active </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>

                    <Col md={12} className="mb-3">
                      <CardTitle>Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default UpdateCollegeCourses;
