import produce from 'immer';
import { doLogin, getUser, doLogout } from '../../containers/authentication/actions';
import { getPermissions } from '../../utils/helper';
import { showCircularLoaderAction, setGlobalError, resetAppData, getUserSearchList } from './actions';

export const initialState = {
  isLoading: false,
  user: {},
  isLoggedIn: false,
  showLinearLoader: false,
  showCircularLoader: false,
  errorObj: {},
  searchUserList: [],
  userRole: '',
};

const globalReducer = produce((draft, action) => {
  if (action.type.includes('/REQUEST')) {
    if (action.payload && action.payload.isLoading) {
      draft.showLinearLoader = true;
      draft.isLoading = true;
    }
  } else if (action.type.includes('/FULFILL')) {
    if (action.payload && !action.payload.isLoading) {
      draft.showLinearLoader = false;
      draft.isLoading = false;
    }
  } else if (action.type.includes('/FAILURE')) {
    draft.isLoading = false;
  }
  switch (action.type) {
    case getUser.SUCCESS: {
      const { user } = action.payload;
      draft.user = { ...user, entityPermissions: getPermissions(user.roles) };
      draft.userRole = user.role;
      // break;
    }

    case doLogin.SUCCESS: {
      const { token } = action.payload;
      draft.token = token;
      draft.isLoggedIn = true;
      break;
    }

    case doLogout.SUCCESS:
      draft.user = {};
      draft.token = '';
      draft.isLoggedIn = false;
      break;

    case getUserSearchList.SUCCESS: {
      const { results } = action.payload;
      draft.searchUserList = results;
      break;
    }

    case showCircularLoaderAction.TRIGGER: {
      draft.showCircularLoader = true;
      break;
    }
    case showCircularLoaderAction.FULFILL: {
      draft.showCircularLoader = false;
      break;
    }

    case setGlobalError.TRIGGER: {
      const errorObj = action.payload;
      errorObj.key = errorObj.key || Date.now;
      draft.errorObj = errorObj;
      break;
    }

    case resetAppData.TRIGGER: {
      return initialState;
    }
    default: {
      return draft;
    }
  }
}, initialState);

export default globalReducer;
