import { USER_ROLE } from '../../utils/constants';
import SCHOOL_LINKS from '../college/links';
import AddCollege from './AddCollege';
import CollegeAdmissionInquiryList from './AdmissionInquiry';
import CollegeCoursesList from './CollegeCourses';
import UpdateCollegeCourses from './CollegeCourses/UpdateCollegeCourses';
import CollegesFeatured from './CollegeFeatured';
import UpdateCollegeFeatured from './CollegeFeatured/UpdateCollegeFeatured';
import CollegeGroupsList from './CollegeGroups';
import UpdateCollegeGroups from './CollegeGroups/UpdateCollegeGroups';
import CollegesList from './CollegeList';
import CollegeReviewsList from './CollegeReviews';
import CollegeMetaContentList from './CollegesMetaData';
import UpdateCollegeMetaData from './CollegesMetaData/UpdateCollegeMetaData';
import EditCollege from './EditCollege';
import COLLEGE_LINKS from './links';

export default [
  {
    path: COLLEGE_LINKS.ADD_COLLEGE,
    exact: true,
    component: AddCollege,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COLLEGE_LINKS.EDIT_COLLEGE_SECTION,
    exact: true,
    component: EditCollege,
    role: [USER_ROLE.ADMIN],
    hideLeftSide: true,
  },
  {
    path: COLLEGE_LINKS.EDIT_COLLEGE,
    exact: true,
    component: EditCollege,
    role: [USER_ROLE.ADMIN],
    hideLeftSide: true,
  },
  {
    path: COLLEGE_LINKS.COLLEGE_LISTING,
    exact: true,
    component: CollegesList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COLLEGE_LINKS.COLLEGE_META_CONTENT,
    exact: true,
    component: CollegeMetaContentList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COLLEGE_LINKS.ADD_COLLEGE_META_CONTENT,
    exact: true,
    component: UpdateCollegeMetaData,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COLLEGE_LINKS.EDIT_COLLEGE_META_CONTENT,
    exact: true,
    component: UpdateCollegeMetaData,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.COLLEGE_GROUPS,
    exact: true,
    component: CollegeGroupsList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.ADD_COLLEGE_GROUP,
    exact: true,
    component: UpdateCollegeGroups,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.EDIT_COLLEGE_GROUP,
    exact: true,
    component: UpdateCollegeGroups,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.COLLEGE_FEATURED,
    exact: true,
    component: CollegesFeatured,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.ADD_COLLEGE_FEATURED,
    exact: true,
    component: UpdateCollegeFeatured,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.EDIT_COLLEGE_FEATURED,
    exact: true,
    component: UpdateCollegeFeatured,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.COLLEGE_REVIEWS,
    exact: true,
    component: CollegeReviewsList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COLLEGE_LINKS.COLLEGE_ADMISSION_INQUIRY,
    exact: true,
    component: CollegeAdmissionInquiryList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COLLEGE_LINKS.COLLEGE_COURSES,
    exact: true,
    component: CollegeCoursesList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COLLEGE_LINKS.ADD_COLLEGE_COURSE,
    exact: true,
    component: UpdateCollegeCourses,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: COLLEGE_LINKS.EDIT_COLLEGE_COURSE,
    exact: true,
    component: UpdateCollegeCourses,
    role: [USER_ROLE.ADMIN],
    hideLeftSide: true,
  },
];
