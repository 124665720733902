import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import MiniCards from 'components/MiniCard/mini-card';
import 'flatpickr/dist/themes/material_blue.css';
import useRequest from 'hooks/useRequest';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import map from 'lodash/map';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { CURRENCY_ARRAY, GET_GENDER, WEEK_DAYS } from 'utils/constants';
import { buildURL, printDateTime, showImage, titleCaseIfExists } from 'utils/helper';
import dummyUserIcon from 'assets/images/dummy-user.svg';
import { SMKLinks } from '../../../utils/links';

const DoctorDetail = () => {
  const { id: doctorId } = useParams();
  const [doctorDetails, setDoctorDetails] = useState([]);
  const router = useRouter();

  const [doctorLocationDetails, setdoctorLocationDetails] = useState();
  const [locationDetails, setLocationDetails] = useState();
  const [healthCarefacilityList, sethealthCarefacilityList] = useState();

  const [getDoctorLocationDetails, { loading: getDoctorLocationLoading }] = useRequest({
    url: `/doctor/admin/${doctorId}/locations`,
    method: 'GET',
    onSuccess: (data) => {
      setdoctorLocationDetails(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });
  const [getDoctorDetails, { loading: getDoctorLoading }] = useRequest({
    url: `/doctor/admin/${doctorId}`,
    method: 'GET',
    onSuccess: (data) => {
      setDoctorDetails(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  useEffect(() => {
    getDoctorDetails();
  }, []);

  const miniCards = [
    {
      title: 'Doctor name',
      iconClass: 'bxs-user-circle',
      text: `${`${doctorDetails?.firstName} ${doctorDetails?.lastName}` || '-'} `,
    },
    {
      title: 'Active',
      iconClass: 'bx-power-off',
      text: `${doctorDetails?.active ? 'Yes' : 'No'}`,
      textColor: `${doctorDetails?.active ? 'text-success' : 'text-danger'}`,
    },
    {
      title: 'Deleted',
      iconClass: 'bx-trash',
      text: `${doctorDetails?.deleted ? 'Yes' : 'No'}`,
      textColor: `${doctorDetails?.deleted ? 'text-success' : 'text-danger'}`,
    },
  ];

  // location

  const [getHealthcareFacilityRequest, { loading: getHealthcareFacilityLoading }] = useRequest({
    url: `/doctor/admin/healthcareFacility/search`,
    method: 'POST',
    onSuccess: (data) => {
      sethealthCarefacilityList(data?.results);
    },
  });

  useEffect(() => {
    getDoctorLocationDetails();
    getHealthcareFacilityRequest({
      body: {
        page: 1,
        size: 5000,
        sortBy: 'id',
        sortOrder: 0,
      },
    });
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getDoctorLoading || getHealthcareFacilityLoading} />
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem="Doctor Detail" />
          <Row>
            <Col xl={4}>
              <div className="d-flex align-items-center justify-content-start">
                <div className="me-2">
                  <img
                    src={
                      !isEmpty(doctorDetails?.profileImage?.filename)
                        ? showImage(doctorDetails.profileImage?.filename)
                        : dummyUserIcon
                    }
                    className="avatar-md rounded-circle img-thumbnail"
                  />
                </div>
                <div className="align-self-center">
                  <div className="text-muted">
                    <h5 className="mb-1"> {`${doctorDetails?.firstName} ${doctorDetails?.lastName}`}</h5>
                    <p className="mb-0">{doctorDetails?.email}</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={8} className="d-flex align-item-center justify-content-evenly">
              {map(miniCards, (card, key) => (
                <MiniCards
                  mdCol={3}
                  title={card?.title}
                  text={card?.text}
                  textColor={card?.textColor}
                  iconClass={card?.iconClass}
                  key={`_card_${key}`}
                />
              ))}
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-0">Basic Information</CardTitle>

                  <div className="table-responsive" style={{ minHeight: 200 }}>
                    <Table className="table-nowrap mb-0 table-striped table-white">
                      <tbody>
                        <tr>
                          <th>First name :</th>
                          <td>{doctorDetails?.firstName}</td>
                        </tr>
                        <tr>
                          <th>Last name :</th>
                          <td>{doctorDetails?.lastName}</td>
                        </tr>
                        <tr>
                          <th>Gender :</th>
                          <td>{GET_GENDER[doctorDetails?.gender]}</td>
                        </tr>
                        <tr>
                          <th>Email :</th>
                          <td>{doctorDetails?.email}</td>
                        </tr>
                        <tr>
                          <th>Phone number :</th>
                          <td>{doctorDetails?.phoneNumber}</td>
                        </tr>
                        <tr>
                          <th>Created Date :</th>
                          <td>{printDateTime(doctorDetails?.createdDate)}</td>
                        </tr>
                        <tr>
                          <th>Active :</th>
                          <td>
                            <Badge className={doctorDetails?.active ? 'bg-success' : 'bg-danger'}>
                              {doctorDetails?.active ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th>Deleted :</th>
                          <td>
                            <Badge className={doctorDetails?.deleted ? 'bg-success' : 'bg-danger'}>
                              {doctorDetails?.deleted ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl="8">
              <Card>
                <CardBody>
                  <div className="py-3 mb-0 d-flex justify-content-between">
                    <CardTitle className="card-title text-center ">Clinic List</CardTitle>
                    <Link
                      to={buildURL(SMKLinks.ADD_CLINIC, {
                        doctorId: doctorId,
                      })}
                      className="btn btn-primary float-right">
                      Create New
                    </Link>
                  </div>
                  <div className="table-responsive" style={{ minHeight: 200 }}>
                    <Table className="table-nowrap mb-0 table-striped table-white">
                      <thead>
                        <tr>
                          <th>Healthcare facility name</th>
                          <th>Default charges</th>
                          <th>Timings</th>
                          <th>Active</th>
                          <th>Deleted</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {doctorLocationDetails?.map((location, index) => (
                          <tr key={`_t${location?.healthcareFacility?.name}_${index}_`}>
                            <td>
                              <div
                                className="text-primary"
                                style={{ cursor: 'pointer' }}
                                onClick={() => setLocationDetails(location)}>
                                {location?.healthcareFacility?.name}
                              </div>
                            </td>
                            <td>{location?.defaultCharges}</td>
                            <td>{location?.timing}</td>

                            <td>
                              <Badge className={location?.active ? 'bg-success' : 'bg-danger'}>
                                {location?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={location?.deleted ? 'bg-success' : 'bg-danger'}>
                                {location?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <Link
                                    to={buildURL(SMKLinks.EDIT_CLINIC, {
                                      doctorId: doctorId,
                                      id: location?.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

DoctorDetail.propTypes = {
  currentPost: PropTypes.object,
};

export default DoctorDetail;
