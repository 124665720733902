import AuthService from 'common/auth.service';
import { userSelector } from 'common/globalComponents/selectors';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { ACTIVE_AND_DELETED_ARRAY, API_BASE_URL, LANGUAGE_ARRAY_WITH_STRING } from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import {
  buildURL,
  checkIfValueExist,
  getCurrentUserData,
  htmlDecode,
  printDate,
  printSerialNumbers,
  titleCaseIfExists,
  urlSlugify,
} from '../../../utils/helper';
import { BLOG_STATUS_ENUM, checkUserPermission, hasPublishPermission } from '../helper';

const stateSelector = createStructuredSelector({
  loggedInUser: userSelector,
});

const BlogList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();
  const { loggedInUser } = useSelector(stateSelector);

  const auth = new AuthService();
  const source = auth.getAppSourceCookie();
  const [articleUser, setArticleUser] = useState({});

  useEffect(() => {
    if (!isEmpty(loggedInUser)) {
      setArticleUser(checkUserPermission(loggedInUser, 'article'));
    } else {
      setArticleUser({});
    }
  }, [loggedInUser]);

  const [activeTab, setActiveTab] = useState('published');
  const [blogList, setblogList] = useState([]);
  const [blogListPagination, setblogListPagination] = useState({});

  const BLOG_FILTERS = [
    {
      name: 'Published',
      filters: {
        active: true,
        deleted: false,
      },
    },
    {
      name: 'Draft',
      filters: {
        active: false,
        deleted: false,
      },
    },
    {
      name: 'Ready for review',
      filters: {
        active: false,
        deleted: false,
      },
    },
    {
      name: 'On Hold',
      filters: {
        active: false,
        deleted: false,
      },
    },
    {
      name: 'Ready for publish',
      filters: {
        active: false,
        deleted: false,
      },
    },
    {
      name: 'unPublished',
      filters: {
        active: false,
        deleted: false,
      },
    },
    {
      name: 'Trash',
      filters: {
        active: false,
        deleted: true,
      },
    },
    {
      name: 'All',
    },
  ];

  const { stgURL } = getCurrentUserData();

  const defaultFilters = {
    active: true,
    deleted: false,
    startDate: moment().subtract(10, 'years'),
    endDate: moment(),
    name: '',
    category: '',
    author: '',
    createdBy: '',
    language: LANGUAGE_ARRAY_WITH_STRING[0],
    appSource: source,
    dateType: 'createdDate',
    status: BLOG_STATUS_ENUM.PUBLISHED,
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [getblogListRequest, { loading: getblogListLoading }] = useRequest({
    url: `/article/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setblogList(data?.results);
      setblogListPagination(data?.pagination);
    },
  });

  const setPageInUrl = (page, language) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}${
        language ? `&language=${language}` : filters?.language?.value ? `&language=${filters?.language?.value}` : ``
      }`,
    });
  };

  const handleGetBlogList = (page, filters) => {
    getblogListRequest({
      body: {
        page,
        size: 20,
        sortBy: 'updatedDate',
        sortOrder: 1,
        ...filters,
        active: filters?.active,
        deleted: filters?.deleted,
        name: urlSlugify(filters?.name),
        language: checkIfValueExist(filters?.language?.value),
        ...(filters?.category?.id
          ? {
              primaryCategoryId: filters?.category?.id,
            }
          : {}),
        ...(filters?.author?.id
          ? {
              authorId: filters?.author?.id,
            }
          : {}),
        ...(filters?.createdBy?.id
          ? {
              createdById: filters?.createdBy?.id,
            }
          : {}),
        author: undefined,
        createdBy: undefined,
        category: undefined,
        appSource: undefined,
        ...(!loggedInUser?.entityPermissions?.article?.publish
          ? {
              createdById: loggedInUser?.id,
            }
          : {}),
        status: filters?.status,
      },
    });
  };
  const handleChangePage = (page) => {
    setPageInUrl(page);
    const activeTabFilters = BLOG_FILTERS?.find((e, i) => activeTab === String(i + 1));
    handleGetBlogList(page, { ...filters, ...activeTabFilters?.filters });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1, defaultFilters?.language?.value);
    setFilters({ ...defaultFilters, id: '' });

    const activeTabFilters = BLOG_FILTERS?.find((e, i) => activeTab === String(i + 1));
    handleGetBlogList(1, { ...defaultFilters, ...activeTabFilters?.filters });
  };

  useEffect(() => {
    if (!router?.query?.language && !router?.query?.tab) {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
    }
  }, []);

  useEffect(() => {
    const { language, tab, page, category } = router.query;

    const category_slug = category?.split('__')[0];
    const category_id = category?.split('__')[1];

    if (language || tab || category) {
      let updatedFilters = { ...filters };

      // Handle language filter
      if (language) {
        const lang = LANGUAGE_ARRAY_WITH_STRING.find((lang) => lang?.value === language);
        if (lang) {
          updatedFilters = { ...updatedFilters, language: lang };
        }
      }

      // Handle tab filter
      if (tab) {
        const tabFilters = BLOG_FILTERS?.find((e) => urlSlugify(e?.name) === tab);
        if (tabFilters) {
          setActiveTab(tab); // Set activeTab state
          updatedFilters = {
            ...updatedFilters,
            ...tabFilters.filters,
            status: tabFilters?.name === 'All' ? null : tabFilters?.name?.toLowerCase()?.replace(' ', '_'),
          };
        }
      }
      if (category) {
        updatedFilters = {
          ...updatedFilters,
          category: { id: category_id, label: category_slug, value: category_id },
        };
      }

      // Update filters and trigger blog list fetch
      setFilters(updatedFilters);
      handleGetBlogList(1, updatedFilters);

      // Update URL to include page and language
      const queryParams = new URLSearchParams();
      if (page) queryParams.set('page', page);
      if (language) queryParams.set('language', language);
      if (tab) queryParams.set('tab', tab);
      if (category) queryParams.set('category', category_slug);

      const newUrl = `${router.pathname}?${queryParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
    }
  }, [router.query.language, router.query.tab, router.query.category]);

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    if (activeTab && currentSearchParams.get('tab') !== activeTab) {
      currentSearchParams.set('tab', activeTab);
      const newUrl = `${window.location.pathname}?${currentSearchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
    }
  }, [activeTab]);

  const handleSetCurrentTab = (data) => {
    const current_tab = data?.name?.toLowerCase()?.replaceAll(' ', '_');
    setActiveTab(current_tab);

    const currentFilters = { ...filters, ...data?.filters, status: current_tab === 'all' ? null : current_tab };
    setFilters(currentFilters);
    handleGetBlogList(1, currentFilters);
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getblogListLoading} />

        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Blog list" />

          <div>
            <Nav tabs>
              {BLOG_FILTERS?.map((elem, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      className={activeTab === elem?.name?.toLowerCase()?.replaceAll(' ', '_') ? 'active' : ''}
                      onClick={() => {
                        handleSetCurrentTab(elem);
                      }}>
                      {elem?.name}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <TabContent activeTab={activeTab}>
              {
                <TabPane tabId={activeTab}>
                  <Card>
                    <CardBody>
                      <div className="mt-4">
                        <div className="row">
                          <div className="col-lg-2 mb-4">
                            <label className="form-label">Active</label>
                            <Select
                              isDisabled={activeTab !== 'all'}
                              options={ACTIVE_AND_DELETED_ARRAY}
                              value={ACTIVE_AND_DELETED_ARRAY?.find((e) => String(e?.value) === String(filters.active))}
                              onChange={(e) => setFilters({ ...filters, active: e?.value })}
                            />
                          </div>
                          <div className="col-lg-2 mb-4">
                            <label className="form-label">Deleted</label>
                            <Select
                              isDisabled={activeTab !== 'all'}
                              options={ACTIVE_AND_DELETED_ARRAY}
                              value={ACTIVE_AND_DELETED_ARRAY?.find(
                                (e) => String(e?.value) === String(filters.deleted)
                              )}
                              onChange={(e) => setFilters({ ...filters, deleted: e?.value })}
                            />
                          </div>
                          <div className="col-lg-2 mb-4">
                            <label className="form-label">Status</label>
                            <Select
                              isDisabled={activeTab !== 'all'}
                              options={Object.keys(BLOG_STATUS_ENUM)?.map((e) => ({
                                label: titleCaseIfExists(e),
                                value: BLOG_STATUS_ENUM[e],
                              }))}
                              value={Object.keys(BLOG_STATUS_ENUM)
                                ?.filter((elem) => urlSlugify(BLOG_STATUS_ENUM[elem]) === urlSlugify(filters.status))
                                ?.map((e) => ({
                                  label: titleCaseIfExists(e),
                                  value: BLOG_STATUS_ENUM[e],
                                }))}
                              onChange={(e) => setFilters({ ...filters, status: e?.value })}
                            />
                          </div>
                          <div className="col-lg-2 mb-4">
                            <label className="form-label">Language</label>
                            <Select
                              options={LANGUAGE_ARRAY_WITH_STRING}
                              value={filters.language}
                              onChange={(value) => setFilters({ ...filters, language: value })}
                            />
                          </div>

                          {/* <div className="col-lg-3 mb-4">
                            <label htmlFor="startDate" className="form-label">
                              Created Date - From
                            </label>
                            <Input
                              type="date"
                              id="startDate"
                              placeholder="From Date"
                              value={filters.startDate.format('YYYY-MM-DD')}
                              max={moment().format('YYYY-MM-DD')}
                              onChange={(e) => setFilters({ ...filters, startDate: moment(e.target.value) })}
                            />
                          </div>
                          <div className="col-lg-3 mb-4">
                            <label htmlFor="endDate" className="form-label">
                              Created Date - To
                            </label>
                            <Input
                              type="date"
                              id="endDate"
                              placeholder="To Date"
                              value={filters.endDate.format('YYYY-MM-DD')}
                              max={moment().format('YYYY-MM-DD')}
                              onChange={(e) => setFilters({ ...filters, endDate: moment(e.target.value) })}
                            />
                          </div> */}

                          <div className="col-lg-4 mb-4">
                            <label htmlFor="name" className="form-label">
                              Title
                            </label>
                            <Input
                              type="text"
                              name="name"
                              placeholder="Title..."
                              value={filters.name}
                              onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                            />
                          </div>

                          <div className="col-lg-3 mb-4">
                            <label htmlFor="category" className="form-label">
                              Category
                            </label>
                            <SmkAsyncSelect
                              creatable={false}
                              acceptedKey={'name'}
                              acceptedValue={'id'}
                              placeholder="Type category name.."
                              onChange={(res) => {
                                setFilters({ ...filters, category: res });
                              }}
                              fetchUrl={`${API_BASE_URL}/article/admin/categories/search`}
                              filters={{
                                autoComplete: true,
                                search: true,
                                page: 1,
                                size: 100,
                                sortBy: 'id',
                                sortOrder: 0,
                                language: filters.language.value,
                                appSource: filters.appSource,
                              }}
                              searchKeyName="name"
                              value={!isEmpty(filters?.category) ? filters?.category : ''}
                            />
                          </div>
                          <div className="col-lg-3 mb-4">
                            <label htmlFor="author" className="form-label">
                              Author
                            </label>
                            <SmkAsyncSelect
                              isClearable={false}
                              combineLabelKey={'role'}
                              acceptedKey={'displayName'}
                              acceptedValue={'id'}
                              placeholder="Type author name.."
                              onChange={(res) => {
                                setFilters({ ...filters, author: res });
                              }}
                              fetchUrl={`${API_BASE_URL}/user/admin/search-article-authors`}
                              apiMethod={'GET'}
                              searchKeyName="name"
                              isQueryParam={true}
                              value={!isEmpty(filters?.author) ? filters?.author : ''}
                            />
                          </div>
                          {hasPublishPermission(loggedInUser, 'article') && (
                            <div className="col-lg-3 mb-4">
                              <label htmlFor="CreatedBy" className="form-label">
                                CreatedBy
                              </label>
                              <SmkAsyncSelect
                                isClearable={false}
                                combineLabelKey={'role'}
                                acceptedKey={'displayName'}
                                acceptedValue={'id'}
                                placeholder="Type author name.."
                                onChange={(res) => {
                                  setFilters({ ...filters, createdBy: res });
                                }}
                                fetchUrl={`${API_BASE_URL}/user/admin/search-article-authors`}
                                apiMethod={'GET'}
                                searchKeyName="name"
                                isQueryParam={true}
                                value={!isEmpty(filters?.createdBy) ? filters?.createdBy : ''}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-4 pt-2">
                        <a className="btn btn-primary me-4" onClick={applyFilters}>
                          Apply Filters
                        </a>
                        <a className="" onClick={clearFilters}>
                          Clear
                        </a>
                      </div>
                    </CardBody>
                  </Card>
                </TabPane>
              }
            </TabContent>
          </div>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{blogListPagination?.totalCount || 0} Blogs found</div>
              </div>
            </Col>
            <Col sm="8">
              {loggedInUser?.entityPermissions?.article?.create && (
                <div className="text-sm-end">
                  <Link
                    replace
                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                    to={SMKLinks.ADD_BLOG}>
                    <i className="mdi mdi-plus me-1" />
                    Add Blog
                  </Link>
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">Id</th>
                        <th scope="col">Title</th>
                        <th scope="col">Primary Category</th>
                        <th scope="col">Author</th>
                        <th scope="col">Created Date</th>

                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(blogList) ? (
                        blogList?.map((data, index) => (
                          <tr key={index} className={data?.deleted ? 'bg-danger' : ''}>
                            <td>{printSerialNumbers(blogListPagination)[index]}</td>
                            <td style={{ width: 600 }}>
                              <Link
                                className="h5 text-primary"
                                to={buildURL(SMKLinks.EDIT_BLOG, {
                                  id: data?.id,
                                })}>
                                <span dangerouslySetInnerHTML={{ __html: data?.title }} />
                              </Link>
                              {!data?.active && (
                                <a
                                  className="ms-3"
                                  target="_blank"
                                  rel="noreferrer"
                                  href={`${stgURL}/${source === 'theparentz' ? 'parenting' : 'blog'}/preview/${
                                    data?.id
                                  }?token=${auth.getSessionCookie()}`}>
                                  <i className="bx bx-link-external fs-4" />
                                </a>
                              )}

                              <br />
                              {data?.active && (
                                <div className="mt-2">
                                  <a
                                    className="text-info"
                                    target="_blank"
                                    rel="noreferrer"
                                    href={`${stgURL}${data?.language == 'hi' ? '/hi' : ''}/${
                                      data?.primaryCategory?.slug
                                    }/${data?.slug}`}>
                                    {`${data?.language == 'hi' ? '/hi' : ''}/${data?.primaryCategory?.slug}/${
                                      data?.slug
                                    }`}

                                    {/* <i className='bx' */}
                                  </a>
                                </div>
                              )}
                              {!isEmpty(data.translations) && (
                                <>
                                  <br />
                                  <h6 className="mt-2">Translation</h6>
                                  {
                                    LANGUAGE_ARRAY_WITH_STRING?.find(
                                      (e) => e?.value === data?.translations[0]?.language
                                    )?.label
                                  }{' '}
                                  -
                                  <Link
                                    to={buildURL(SMKLinks.EDIT_BLOG, {
                                      id: data?.translations[0]?.id,
                                    })}>
                                    {htmlDecode(data?.translations[0]?.title)}
                                  </Link>
                                </>
                              )}
                            </td>
                            <td>
                              {titleCaseIfExists(data?.primaryCategory?.name)} <br />
                              {data?.primaryCategory?.slug}
                            </td>
                            <td>
                              {data?.author?.displayName} <br />{' '}
                              <span className="mt-2">CreatedBy: {data?.createdBy?.displayName}</span>
                            </td>
                            <td>
                              Created
                              <br /> {printDate(data?.createdDate)}
                            </td>
                            <td>
                              {data?.active ? 'Published' : 'Modified'}:
                              <br /> {printDate(data?.updatedDate)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={blogListPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default BlogList;
