import { useFormik } from 'formik';
import invert from 'lodash/invert';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// components
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from 'reactstrap';
import * as Yup from 'yup';
import Loader from '../../../../components/Loader';
import config from '../../../../config/config';
import useRequest from '../../../../hooks/useRequest';
import { useRouter } from '../../../../hooks/useRouter';
import { SCHOOL_REVIEWER_TYPE, SORT_VALUES, SchoolReviewRejectReason } from '../../../../utils/constants';
import { convertHtmlToText, printDateTimeTwoLines, titleCaseIfExists } from '../../../../utils/helper';
import { schoolClearCache } from '../../schoolDetailsHelper';

const SchoolDetailReviews = ({ schoolId }) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [schoolReviews, setSchoolReviews] = useState([]);
  const [schoolReviewsPagination, setSchoolReviewsPagination] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(null);
  const [isToggle, setIsToggle] = useState(false);
  const [school, setSchool] = useState(false);

  const [getSchoolReviewsRequest, { loading: getSchoolReviewsLoading }] = useRequest({
    url: `/school/admin/review/search`,
    method: 'POST',
    onSuccess: (data) => {
      setSchoolReviewsPagination(data?.pagination);
      setSchoolReviews(data?.results);
    },
  });

  const handleChangePage = () => {
    getSchoolReviewsRequest({
      body: {
        page: 1,
        size: 500,
        startDate: moment('1950-01-01'),
        endDate: moment(),
        schoolId: schoolId,
        ...SORT_VALUES,
      },
    });
  };

  const [markApprovedRequest, { loading: markApprovedLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success('Success! School Review has been approved.');
    },
  });

  const [markUnapprovedRequest, { loading: markUnapprovedLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success('Success! School Review has been UnApproved.');
    },
  });

  const [deleteReviewRequest, { loading: deleteReviewLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      setShowDeleteModal(null);
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success('Success! School Review has been deleted.');
    },
  });

  const markApproved = (review) => {
    markApprovedRequest({ url: `/school/admin/review/approve/${review.id}` });
    schoolClearCache({
      removeBodyParam: true,
      urlSlug: review?.school?.slug,
      schoolId: review?.school?.id,
    });
  };

  const markUnapproved = (review) => {
    markUnapprovedRequest({
      url: `/school/admin/review/unapprove/${review.id}`,
    });
  };

  const markDeleted = (review, payload) => {
    deleteReviewRequest({
      url: `/school/admin/review/delete/${review.id}`,
      body: payload,
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const reviewRejectFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      rejectReason: '',
      rejectComments: '',
    },
    onSubmit(values) {
      markDeleted(showDeleteModal, values);
      showDeleteModal(null);
    },
    validationSchema: Yup.object().shape({
      rejectReason: Yup.string().required('Reject reason is requried'),
    }),
  });

  const shortString = (data, str, n) => {
    return str.length > n ? (
      <>
        {str.slice(0, n - 1)}
        <a
          onClick={() => {
            setIsToggle(true);
            setSchool(data);
          }}
          className="cursor-pointer  text-info">
          ...more
        </a>
      </>
    ) : (
      str
    );
  };

  return (
    <>
      <Loader
        isActive={getSchoolReviewsLoading || markApprovedLoading || markUnapprovedLoading || deleteReviewLoading}
      />

      <Row className="mb-2">
        <Col sm="4">
          <div className="search-box me-2 mb-2 d-inline-block">
            <div className="position-relative font-bold">
              {schoolReviewsPagination?.totalCount} School Reviews Found
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <div className="">
            <div className="table-responsive" style={{ minHeight: 300 }}>
              <Table className="project-list-table   table-centered table-borderless">
                <thead>
                  <tr className="table-warning">
                    <th scope="col">Review Id</th>
                    <th scope="col">Name / Email / Phone</th>
                    <th scope="col">Status</th>
                    <th scope="col">Reviewer Type</th>
                    <th scope="col">Review Source</th>
                    <th scope="col">Rating</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(schoolReviews) ? (
                    schoolReviews
                      ?.sort((a, b) => {
                        if (a?.createdBy?.email < b?.createdBy?.email) return -1;
                        if (a?.createdBy?.email > b?.createdBy?.email) return 1;
                        if (a?.updatedDate < b?.updatedDate) return 1;
                        if (a?.updatedDate > b?.updatedDate) return -1;
                        return 0;
                      })
                      ?.map((data, index) => (
                        <>
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>
                              <strong>
                                {data?.createdBy?.firstName} {data?.createdBy?.lastName}{' '}
                                {data?.keepAnonymous ? ` - Anonymous` : ''}
                              </strong>
                              <div className="d-flex mt-2">
                                {data?.createdBy?.phoneNumber && (
                                  <>
                                    ({data?.createdBy?.phoneNumber}) <br />
                                  </>
                                )}
                                {data?.createdBy?.email}
                              </div>
                            </td>
                            <td className="text-nowrap">
                              {/* eslint-disable-next-line no-nested-ternary */}
                              {data?.active ? (
                                <Badge className="bg-success m-2">Approved</Badge>
                              ) : data?.deleted ? (
                                <>
                                  <Badge className="bg-danger m-2">Rejected</Badge>
                                  <Badge className="bg-light m-2">
                                    {titleCaseIfExists(invert(SchoolReviewRejectReason)[data?.rejectReason])}
                                  </Badge>
                                </>
                              ) : (
                                <Badge className="bg-warning m-2">Pending</Badge>
                              )}
                            </td>
                            <td className="text-nowrap">{invert(SCHOOL_REVIEWER_TYPE)[data.reviewerType]}</td>
                            <td className="text-nowrap">{data.reviewSource === 1 ? 'SMK' : 'Google'}</td>
                            <td className="text-nowrap">{data.rating}</td>
                            <td className="text-nowrap">{printDateTimeTwoLines(data.createdDate)}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td colSpan={5}>
                              <h5 className="text-info mb-3 border-bottom pb-2">Subject/Message</h5>
                              <Badge className={`${data?.school?.active ? 'bg-success' : 'bg-danger'} mb-2`}>
                                {data?.school?.active ? 'Active' : ' Inactive'}
                              </Badge>
                              <span className="ms-2">#{data?.school?.id}</span> -
                              <a
                                target="_blank"
                                href={`${config.production_url}/school/${data?.school?.slug}`}
                                rel="noreferrer">
                                {convertHtmlToText(data.school?.name)}
                              </a>
                              <br />
                              <strong>{data.title}</strong>
                              <br />
                              {shortString(data, data?.text, 120)}
                            </td>

                            <td>
                              <div className="row-actions">
                                <a className="btn btn-sm btn-success" onClick={() => markApproved(data)}>
                                  <i className="fa fa-fw fa-check" />
                                </a>

                                <a className="btn btn-sm btn-danger ms-2" onClick={() => setShowDeleteModal(data)}>
                                  <i className="fa fa-fw fa-times" />
                                </a>
                              </div>
                              {/* <UncontrolledDropdown direction="left">
                                <DropdownToggle tag="a" caret className="bg-transparent borderless text-dark">
                                  <i className="mdi mdi-dots-horizontal font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu className='dropdown-menu-end'>
                                  <DropdownItem tag="a" onClick={() => markApproved(data)}>
                                    Approve Review
                                  </DropdownItem>
                                  <DropdownItem tag="a" onClick={() => markDeleted(data)}>
                                    Reject Review
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown> */}
                            </td>
                          </tr>
                        </>
                      ))
                  ) : (
                    <TableError />
                  )}
                </tbody>
              </Table>
            </div>
          </div>
        </Col>
      </Row>

      <Modal
        isOpen={showDeleteModal}
        role="dialog"
        autoFocus
        centered
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setShowDeleteModal(null);
          reviewRejectFormik.resetForm();
        }}>
        <div className="modal-content">
          <ModalHeader
            className="modalHeader"
            toggle={() => {
              setShowDeleteModal(null);
              reviewRejectFormik.resetForm();
            }}>
            Reason for review rejection
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={reviewRejectFormik.handleSubmit}>
              <FormGroup className="mb-4">
                <p>
                  <b>Review Id: {showDeleteModal?.id}</b>
                  <br />
                  School: #{showDeleteModal?.school?.id} - {convertHtmlToText(showDeleteModal?.school?.name)}
                </p>
              </FormGroup>

              <FormGroup className="mb-4">
                <Label htmlFor="name" className="form-label  ">
                  Reject Reason
                </Label>

                <Select
                  styles={{
                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  }}
                  id="source"
                  placeholder="Please select an option"
                  options={Object.entries(SchoolReviewRejectReason).map(([key, value]) => ({
                    value,
                    label: titleCaseIfExists(key),
                  }))}
                  value={{
                    label: titleCaseIfExists(invert(SchoolReviewRejectReason)[reviewRejectFormik.values.rejectReason]),
                    value: reviewRejectFormik.values.rejectReason,
                  }}
                  onChange={(val) => reviewRejectFormik.setFieldValue('rejectReason', val?.value)}
                />
                {reviewRejectFormik.errors.rejectReason && (
                  <div className="text-danger">{reviewRejectFormik.errors.rejectReason}</div>
                )}
              </FormGroup>

              <FormGroup className="mb-4">
                <Label htmlFor="name" className="form-label  ">
                  Reject Comments
                </Label>

                <Input
                  id="rejectComments"
                  type="textarea"
                  rows={3}
                  className="form-control"
                  placeholder="rejectComments"
                  invalid={!!(reviewRejectFormik.touched.rejectComments && reviewRejectFormik.errors.rejectComments)}
                  {...reviewRejectFormik.getFieldProps('rejectComments')}
                />
                <FormFeedback>{reviewRejectFormik.errors.rejectComments}</FormFeedback>
              </FormGroup>

              <div className="mt-4">
                <Button type="submit" color="success">
                  Submit
                </Button>
                <Button
                  color="light"
                  type="button"
                  className="ms-2"
                  onClick={() => {
                    setShowDeleteModal(null);
                    reviewRejectFormik.resetForm();
                  }}>
                  Cancel
                </Button>
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>

      <Modal
        isOpen={isToggle}
        role="dialog"
        autoFocus
        centered
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setIsToggle(null);
        }}>
        <div className="modal-content">
          <ModalHeader
            className="modalHeader bg-light"
            toggle={() => {
              setIsToggle(null);
            }}>
            Reason for rejection
          </ModalHeader>
          <ModalBody>
            <div className="p-1">
              <h5>
                #{school?.school?.id} -
                <a target="_blank" href={`${config.production_url}/school/${school?.school?.slug}`} rel="noreferrer">
                  {convertHtmlToText(school.school?.name)}
                </a>
              </h5>
              <strong>{school?.title}</strong>
              <hr />
              {school?.text}
            </div>

            <div className="mt-4 mb-2">
              <a
                className="btn btn-success me-4"
                onClick={() => {
                  markApproved(school);
                  setIsToggle(null);
                }}>
                Approve
              </a>

              <a
                className="btn btn-danger"
                onClick={() => {
                  setShowDeleteModal(school);
                  setIsToggle(null);
                }}>
                Reject
              </a>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default SchoolDetailReviews;
