import React from 'react';
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Label, Row, FormFeedback } from 'reactstrap';
import Select from 'react-select';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// actions
import isEmpty from 'lodash/isEmpty';
import { useHistory } from 'react-router-dom';
import { SMKLinks } from 'utils/links';
import { updateSchoolAdditionalInfo } from '../../actions';
// selectors
import { schoolDetailSelector } from '../../selectors';
// constants
import { RADIX, SchoolHolidaysType, NUMBER_REGEX } from '../../../../utils/constants';
// helpers
import { titleCaseIfExists } from '../../../../utils/helper';
// components
import TextEditor from '../../../../components/TextEditor';

// styles
import '../style.scss';
import Loader from '../../../../components/Loader';
import useRequest from '../../../../hooks/useRequest';
import { userSelector } from '../../../../common/globalComponents/selectors';
import { schoolClearCache } from 'containers/school/schoolDetailsHelper';

const stateSelector = createStructuredSelector({
  schoolDetail: schoolDetailSelector,
  user: userSelector,
});

const AdditionalInfo = () => {
  const { user, schoolDetail } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const getInitialFormValues = () => {
    const { additionalInfo, academicInfo } = schoolDetail;
    const values = {};

    values.ranking = additionalInfo?.ranking || [];
    // !isEmpty(additionalInfo?.ranking)
    //   ? additionalInfo?.ranking
    //   : [{ rankingBody: '', rank: '', year: '', type: '', internal: false }];
    values.campusSize = additionalInfo?.campusSize || '';
    values.studentCount = additionalInfo?.studentCount || 0;
    values.teacherCount = additionalInfo?.teacherCount || 0;
    values.studentTeacherRatio = additionalInfo?.studentTeacherRatio || '';
    values.relatedSchools = additionalInfo?.relatedSchools || [];

    values.schoolHolidays = !isEmpty(additionalInfo?.schoolHolidays)
      ? additionalInfo?.schoolHolidays
      : [{ name: '', details: '' }];
    values.awards = additionalInfo?.awards || '';
    values.resultDetails = academicInfo?.resultDetails || '';
    values.alumni = additionalInfo?.alumni || '';
    values.staffDetails = additionalInfo?.staffDetails || '';
    return values;
  };

  const [updateAdditionalInfoRequest, { loading: updateAdditionalInfoLoading }] = useRequest({
    url: '/school/admin/edit/updateAdditionalInfo',
    method: 'POST',
    onSuccess: (data) => {
      schoolClearCache({
        urlSlug: schoolDetail?.slug,
        schoolId: schoolDetail?.id,
      });

      window.location.reload();
    },
  });

  const isEmptyValue = (val) => {
    return val || undefined;
  };

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialFormValues(),
    onSubmit(values) {
      // console.log(values);
      updateAdditionalInfoRequest({
        data: {
          schoolId: isEmptyValue(schoolDetail.id),
          additionalInfo: {
            ranking: isEmptyValue(values.ranking),
            campusSize: values.campusSize,
            studentCount: isEmptyValue(values.studentCount),
            teacherCount: isEmptyValue(values.teacherCount),
            studentTeacherRatio: isEmptyValue(values.studentTeacherRatio),
            relatedSchools: [],
            // filter out empty rows
            schoolHolidays: values.schoolHolidays.filter((h) => h.name && h.details),
            awards: isEmptyValue(values.awards),
            resultDetails: isEmptyValue(values.resultDetails),
            alumni: isEmptyValue(values.alumni),
            staffDetails: isEmptyValue(values.staffDetails),
          },
          academicInfo: {
            resultDetails: isEmptyValue(values.resultDetails),
          },
        },
      });
    },
    validationSchema: Yup.object().shape({
      ranking: Yup.array()
        .of(
          Yup.object().shape({
            type: Yup.string().required('Please select type'),
            year: Yup.string().when('type', {
              is: (val) => !isEmpty(val), // val -> type
              then: Yup.string().test('yearTest', 'Please provide year', (val) => !isEmpty(val)),
            }),
            rankingBody: Yup.string().when('year', {
              is: (val) => !isEmpty(val), // val -> year
              then: Yup.string().test('bodyTest', 'Please provide ranking body', (val) => !isEmpty(val)),
            }),
            rank: Yup.string().when('rankingBody', {
              is: (val) => !isEmpty(val), // val -> rankingBody
              then: Yup.string().test(
                'rankTest',
                'Please provide valid rank',
                (val) => !isEmpty(val) && NUMBER_REGEX.test(val)
              ),
            }),
          })
        )
        .test('rankingTest', 'Please remove duplicate entries', (val) => {
          const map = {};
          val.forEach((entry) => {
            const { type, year, rankingBody } = entry;
            const key = type + year + rankingBody;
            map[key] = map[key] + 1 || 1;
          });
          return Object.values(map).every((val) => val === 1);
        }),
      campusSize: Yup.string().nullable(),
      studentCount: Yup.number().nullable(),
      teacherCount: Yup.number().nullable(),
      studentTeacherRatio: Yup.string().nullable(),
      relatedSchools: Yup.array(),

      schoolHolidays: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().when('details', {
            is: (val) => !isEmpty(val), // val -> details
            then: Yup.string().required('Please select type'),
          }),
          details: Yup.string().nullable(),
        })
      ),
      awards: Yup.string().nullable(),
      resultDetails: Yup.string().nullable(),
      alumni: Yup.string().nullable(),
      staffDetails: Yup.string().nullable(),
    }),
  });

  const getStudentTeacherRatio = () => {
    const { studentCount, teacherCount } = formik.values;

    if (studentCount > 0 && teacherCount > 0) {
      return `${Math.floor(studentCount / teacherCount)}:1`;
    }
    return '';
  };

  const handleUpdateHoliday = (index, key, value) => {
    const array = formik.values.schoolHolidays.slice();
    array[index] = { ...array[index], [key]: value };
    formik.setFieldValue('schoolHolidays', array);
  };

  const handleAddHoliday = () => {
    const array = formik.values.schoolHolidays;
    const last = array[array.length - 1];
    // prevent adding empty rows
    if (array.length > 0 && (!isEmpty(formik.errors.schoolHolidays) || !last.name || !last.details)) {
      return;
    }
    formik.setFieldValue('schoolHolidays', [...array, { name: '', details: '' }]);
  };

  const handleRemoveHoliday = (index) => {
    const array = formik.values.schoolHolidays.slice();
    array.splice(index, 1);
    formik.setFieldValue('schoolHolidays', array);
  };

  const handleAddRank = () => {
    const array = formik.values.ranking;
    const last = array[array.length - 1];

    if (
      (array.length > 0 && !isEmpty(formik.errors.ranking)) ||
      (last && (!last.type || !last.year || !last.rankingBody || !last.rank))
    ) {
      return;
    }

    formik.setFieldValue('ranking', [...array, { rankingBody: '', rank: '', year: '', type: '', internal: false }]);
  };

  const handleUpdateRank = (index, key, value) => {
    const array = formik.values.ranking.slice();
    array[index] = { ...array[index], [key]: value };
    formik.setFieldValue('ranking', array);
  };

  const handleRemoveRank = (index) => {
    const array = formik.values.ranking.slice();
    array.splice(index, 1);
    formik.setFieldValue('ranking', array);
  };

  const handleToddleInternal = (index, checked) => {
    const array = formik.values.ranking.slice();
    array[index] = {
      ...array[index],
      internal: checked,
      rankingBody: checked ? 'School My Kids' : '',
    };
    formik.setFieldValue('ranking', array);
  };

  const renderRanking = () => {
    const types = [
      { label: 'Country', value: 'Country' },
      { label: 'State', value: 'State' },
      { label: 'City', value: 'City' },
      { label: 'Overall', value: 'Overall' },
    ];

    const years = new Array(5)
      .fill(new Date().getFullYear() - 5)
      .map((y, i) => ({ value: y + i + 1, label: y + i + 1 }));

    return (
      <>
        {formik.values.ranking.map((rank, i) => (
          <Row key={`rank_${i}`}>
            <Col md={2}>
              <FormGroup>
                <Label htmlFor="ranktype" className="form-label  ">
                  Type
                </Label>

                <Select
                  id="ranktype"
                  options={types}
                  value={types.find((t) => t.value === rank.type)}
                  onChange={({ value }) => handleUpdateRank(i, 'type', value)}
                  className="zindex1"
                />
                {!isEmpty(formik.errors.ranking) && (
                  <div className="invalid-feedback d-block">{formik.errors.ranking[i]?.type}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup className="mb-4">
                <Label htmlFor="rankyear" className="form-label  ">
                  Year
                </Label>
                <Select
                  id="rankyear"
                  options={years}
                  value={years.find((y) => y.value === rank.year)}
                  onChange={({ value }) => handleUpdateRank(i, 'year', value)}
                />
                {!isEmpty(formik.errors.ranking) && (
                  <div className="invalid-feedback d-block">{formik.errors.ranking[i]?.year}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="mb-4">
                <Label htmlFor="rankbody" className="form-label  ">
                  Ranking Body
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="rankbody"
                  placeholder=""
                  readOnly={rank.internal}
                  value={rank.rankingBody}
                  onChange={(e) => handleUpdateRank(i, 'rankingBody', e.target.value)}
                />
                {!isEmpty(formik.errors.ranking) && (
                  <div className="invalid-feedback d-block">{formik.errors.ranking[i]?.rankingBody}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup className="mb-4">
                <Label htmlFor="rank" className="form-label  ">
                  Rank
                </Label>
                <Input
                  type="number"
                  className="form-control"
                  id="rank"
                  placeholder=""
                  value={rank.rank}
                  onChange={(e) => handleUpdateRank(i, 'rank', parseInt(e.target.value, RADIX) || '')}
                />
                {!isEmpty(formik.errors.ranking) && (
                  <div className="invalid-feedback d-block">{formik.errors.ranking[i]?.rank}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup check>
                <Label htmlFor="rank" className="form-label  ">
                  Internal
                </Label>
                <div className="custom-control custom-checkbox mb-3">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="CustomCheck1"
                    checked={rank.internal}
                    onClick={() => handleToddleInternal(i, !rank.internal)}
                  />
                  <label className="form-check-label" />
                </div>
              </FormGroup>
            </Col>
            <Col md={1}>
              <FormGroup className="mb-4">
                <Button color="danger" className="mt-4" onClick={() => handleRemoveRank(i)}>
                  <i className="bx bx-trash" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        ))}
        <Row className="justify-content-center">
          <div className="text-center">
            {/* Show ranking error if its string */}
            {!Array.isArray(formik.errors.ranking) && (
              <div className="invalid-feedback d-block">{formik.errors.ranking}</div>
            )}

            <Col>
              <Button className="w-100 mt-3" color="light" onClick={handleAddRank}>
                <i className="bx bx-plus me-2" />
                Add Rank
              </Button>
            </Col>
          </div>
        </Row>
      </>
    );
  };

  const renderHolidays = () => {
    const types = Object.entries(SchoolHolidaysType).map(([key, value]) => ({
      value,
      label: titleCaseIfExists(key),
      // disable options for which data already entered
      isDisabled: formik.values.schoolHolidays.some((h) => h.name === value),
    }));

    return (
      <>
        {formik.values.schoolHolidays.map((holiday, i) => (
          <Row key={`hol_${i}`}>
            <Col md={3}>
              <FormGroup className="mb-4">
                <Label htmlFor={`holiday_type_${i}`} className="form-label  ">
                  Holiday Type
                </Label>
                <Select
                  id={`holiday_type_${i}`}
                  placeholder="Please select type"
                  options={types}
                  value={types.find((t) => t.value === holiday.name)}
                  onChange={({ value }) => handleUpdateHoliday(i, 'name', value)}
                />
                {!isEmpty(formik.errors.schoolHolidays) && (
                  <div className="invalid-feedback d-block">{formik.errors.schoolHolidays[i]?.name}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={8}>
              <FormGroup className="mb-4">
                <Label htmlFor={`holiday_details_${i}`} className="form-label  ">
                  Holiday Details
                </Label>
                <Input
                  id={`holiday_details_${i}`}
                  type="text"
                  className="form-control"
                  placeholder="Holiday Details..."
                  value={holiday.details}
                  onChange={(e) => handleUpdateHoliday(i, 'details', e.target.value)}
                  // invalid={!!(formik.touched.motto && formik.errors.motto)}
                  // {...formik.getFieldProps('motto')}
                />
                {/* <FormFeedback>{formik.errors.motto}</FormFeedback> */}
              </FormGroup>
            </Col>
            <Col sm={1}>
              <FormGroup className="mb-4">
                <Button color="danger" className="mt-4" onClick={() => handleRemoveHoliday(i)}>
                  <i className="bx bx-trash" />
                </Button>
              </FormGroup>
            </Col>
          </Row>
        ))}
        <Row>
          <Col>
            <Button className="mt-3 w-100" color="light" disabled={isEmpty(types)} onClick={handleAddHoliday}>
              <i className="bx bx-plus me-2" />
              Add Holiday
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateAdditionalInfoLoading} />

      <h5 className="mb-4">Additional Info</h5>

      <Card>
        <CardBody>
          <CardTitle>Awards & Recognition Details</CardTitle>

          <CardTitle>Ranking</CardTitle>

          {renderRanking()}
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="campus_size" className="form-label  ">
              Campus Size
            </Label>
            <Input
              id="campus_size"
              type="text"
              className="form-control"
              rows="3"
              placeholder=""
              invalid={!!(formik.touched.campusSize && formik.errors.campusSize)}
              {...formik.getFieldProps('campusSize')}
            />
            <FormFeedback>{formik.errors.campusSize}</FormFeedback>
          </FormGroup>

          <CardTitle>Student/Teacher Ratio</CardTitle>

          <Row className="mt-4">
            <Col md={4}>
              <FormGroup className="mb-4">
                <Label htmlFor="student_count" className="form-label  ">
                  Number of Student
                </Label>
                <Input
                  id="student_count"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder=""
                  invalid={!!(formik.touched.studentCount && formik.errors.studentCount)}
                  {...formik.getFieldProps('studentCount')}
                />
                <FormFeedback>{formik.errors.studentCount}</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup className="mb-4">
                <Label htmlFor="teacher_count" className="form-label  ">
                  Number of Teachers
                </Label>
                <Input
                  id="teacher_count"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder=""
                  invalid={!!(formik.touched.teacherCount && formik.errors.teacherCount)}
                  {...formik.getFieldProps('teacherCount')}
                />
                <FormFeedback>{formik.errors.teacherCount}</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={4}>
              <FormGroup className="mb-4">
                <Label htmlFor="st_ratio" className="form-label  ">
                  Students to Teachers Ratio
                </Label>
                <Input
                  readOnly
                  id="st_ratio"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder=""
                  value={getStudentTeacherRatio()}
                />
                <FormFeedback>{formik.errors.studentTeacherRatio}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Related Schools</CardTitle>

          <FormGroup className="mb-4">
            <Label htmlFor="schooldesc" className="form-label  ">
              Related School
            </Label>
            <Input type="text" className="form-control" id="schooldesc" rows="3" placeholder="Notes..." />
          </FormGroup>

          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="schooldesc" className="form-label  ">
                  Junior Wing
                </Label>
                <Input type="text" className="form-control" id="schooldesc" rows="3" placeholder="Notes..." />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label htmlFor="schooldesc" className="form-label  ">
                  Senior Wing
                </Label>
                <Input type="text" className="form-control" id="schooldesc" rows="3" placeholder="Notes..." />
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>School Holidays</CardTitle>
          {renderHolidays()}
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>School Awards and Recognitions</CardTitle>

          <FormGroup className="mb-4">
            <TextEditor
              simple
              initialValue={formik.values.awards}
              onChange={(val) => formik.setFieldValue('awards', val)}
            />
          </FormGroup>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>School Results</CardTitle>

          <FormGroup className="mb-4">
            <TextEditor
              simple
              initialValue={formik.values.resultDetails}
              onChange={(val) => formik.setFieldValue('resultDetails', val)}
            />
          </FormGroup>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>School Alumni</CardTitle>

          <FormGroup className="mb-4">
            <TextEditor
              simple
              initialValue={formik.values.alumni}
              onChange={(val) => formik.setFieldValue('alumni', val)}
            />
          </FormGroup>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>School Staff Details</CardTitle>

          <FormGroup className="mb-4">
            <TextEditor
              simple
              initialValue={formik.values.staffDetails}
              onChange={(val) => formik.setFieldValue('staffDetails', val)}
            />
          </FormGroup>
        </CardBody>
      </Card>

      {(user?.entityPermissions?.school.admin ||
        (schoolDetail.editable &&
          (schoolDetail?.createdBy?.id === user.id || schoolDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Information
          </Button>
          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.SCHOOL_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

AdditionalInfo.propTypes = {};

export default AdditionalInfo;
