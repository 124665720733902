import Loader from 'components/Loader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { COLLEGE_GENDER_VALUES, CollegeMasterType } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { getCities, getCountries, getRegions, getSubAreas, getCollegesMasterData } from '../actions';
import {
  citiesSelector,
  countriesSelector,
  regionsSelector,
  subAreasSelector,
  selectCollegesMasterData,
} from '../selectors';
import Creatable from 'react-select/creatable';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
  collegesMasterData: selectCollegesMasterData,
});

const AddCollege = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { countries, regions, cities, subAreas, collegesMasterData } = useSelector(stateSelector);
  toast.configure();

  const [addCollegeRequest, { loading: addCollegeLoading }] = useRequest({
    url: `/college/admin`,
    method: 'POST',
    onSuccess: () => {
      history.replace('/colleges/list');
      toast.success('Success! College has been Added');
    },
    onError: (err) => {
      toast.error(err?.message || err);
    },
  });

  const isEmptyValue = (val) => {
    return val || undefined;
  };

  const getInitialValues = () => ({
    name: '',
    shortName: '',
    slug: '',

    establishedIn: '',
    studentGender: {},

    managementType: {},
    managementName: '',

    boardingType: {},
    // boardingSubTypes: [],
    // boardingGenderType: {},

    country: {},
    region: {},
    district: {},
    city: {},
    subArea: {},

    postalCode: '',
    fullAddress: '',
    latitude: '',
    longitude: '',
  });

  const checkArrUndefined = (arr) => {
    return arr.every((element) => element === undefined);
  };

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit: (values) => {
      let masterData = [values.managementType, values.boardingType];
      // if (values.boardingType.name.includes('Boarding')) {
      //   masterData = [...masterData, ...values.boardingSubTypes, values.boardingGenderType];
      // }

      const basicDetailsData = {
        establishedIn: isEmptyValue(values.establishedIn),
        managementName: isEmptyValue(values.managementName),
      };

      const basicDetailsDataValues = Object.values(basicDetailsData);

      addCollegeRequest({
        body: {
          active: false,
          name: isEmptyValue(values?.name),
          shortName: isEmptyValue(values?.shortName),
          slug: '',
          studentGender: values.studentGender.value,
          basicDetails: checkArrUndefined(basicDetailsDataValues) ? undefined : basicDetailsData,
          masterData,
        },
      });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('College Name is required'),
      studentGender: Yup.object().test(
        'studentGenderTest',
        'Please select student gender type',
        (val) => !isEmpty(val)
      ),
      managementType: Yup.object().test('managTest', 'Please select management type', (val) => !isEmpty(val)),
      // managementName: Yup.string().required('Mangement name is required'),
      boardingType: Yup.object().test('boardingTest', 'Please select boarding type', (val) => !isEmpty(val)),
    }),
  });

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));
    dispatch(getCollegesMasterData.trigger());
  }, []);

  const handleCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      formik.setFieldValue('country', country);
    } else {
      formik.setFieldValue('country', {});
    }

    formik.setFieldValue('region', {});
    formik.setFieldValue('city', {});
    formik.setFieldValue('subArea', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: false,
          region: region.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );

      formik.setFieldValue('region', region);
    } else {
      formik.setFieldValue('region', {});
    }

    formik.setFieldValue('district', {});
    formik.setFieldValue('city', {});
    formik.setFieldValue('subArea', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleDistrictSelect = (district) => {
    if (!isEmpty(district)) {
      dispatch(
        getSubAreas.trigger({
          level: 4,
          city: district.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );

      formik.setFieldValue('district', district);
    } else {
      formik.setFieldValue('district', {});
    }

    formik.setFieldValue('city', {});
    formik.setFieldValue('subArea', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleCitySelect = (city) => {
    if (!isEmpty(city)) {
      if (!city.id && !city.name) {
        city.name = city.label;
      }
      dispatch(
        getSubAreas.trigger({
          level: 4,
          city: city.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      formik.setFieldValue('city', city);
    } else {
      formik.setFieldValue('city', {});
    }

    formik.setFieldValue('subArea', {});
    formik.setFieldValue('postalCode', '');
  };

  const handleSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      if (!subArea.id && !subArea.name) {
        subArea.name = subArea.label;
      }
      formik.setFieldValue('subArea', subArea);
    } else {
      formik.setFieldValue('subArea', {});
    }

    formik.setFieldValue('postalCode', '');
  };

  useEffect(() => {
    dispatch(getCollegesMasterData.trigger());
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Loader isActive={addCollegeLoading} />
          <Breadcrumbs title="Colleges" breadcrumbItem="Add College" />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card className="mb-5">
                  <CardBody>
                    <Row>
                      <Col md={7}>
                        <FormGroup className="mb-4">
                          <Label for="collegeName" className="form-label  ">
                            College Name<span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="collegeName"
                            type="text"
                            className="form-control"
                            placeholder="College Name..."
                            label="College Name"
                            invalid={!!(formik.touched.name && formik.errors.name)}
                            {...formik.getFieldProps('name')}
                            error={!isEmpty(formik.errors.name)}
                          />
                          <FormFeedback className="d-block">{formik.errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={5}>
                        <FormGroup className="mb-4">
                          <Label for="college__shortname" className="form-label  ">
                            College Short Name
                          </Label>
                          <Input
                            id="college_shortname"
                            name="college__shortname"
                            type="text"
                            className="form-control"
                            placeholder="College Short Name..."
                            invalid={!!(formik.touched.shortName && formik.errors.shortName)}
                            {...formik.getFieldProps('shortName')}
                          />
                          <FormFeedback className="d-block">{formik.errors.shortName}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="establishedIn">Established In</Label>
                          <InputGroup id="establishedIn">
                            <DatePicker
                              selected={formik.values.establishedIn}
                              className="form-control"
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              placeholder="Established in..."
                              autoComplete="off"
                              minDate={moment('1200-01-01').toDate()}
                              maxDate={moment().toDate()}
                              onChange={(date) => {
                                formik.setFieldValue('establishedIn', date);
                              }}
                              invalid={!!(formik.touched.establishedIn && formik.errors.establishedIn)}
                            />
                          </InputGroup>
                          <FormFeedback className="d-block">{formik.errors.establishedIn}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="gender" className="form-label  ">
                            Student Gender Type<span className="text-danger">*</span>
                          </Label>
                          <Select
                            id="gender"
                            placeholder="Please select an option"
                            options={Object.entries(COLLEGE_GENDER_VALUES).map(([key, value]) => ({
                              value,
                              label: titleCaseIfExists(key),
                            }))}
                            value={formik.values.studentGender}
                            onChange={(val) => formik.setFieldValue('studentGender', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.studentGender}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={4}>
                        <FormGroup className="mb-4">
                          <Label for="boarding" className="form-label">
                            Boarding Type<span className="text-danger">*</span>
                          </Label>
                          <Select
                            id="boarding"
                            placeholder="Select an option"
                            options={collegesMasterData.filter((d) => d.type === CollegeMasterType.BOARDING_TYPE)}
                            value={formik.values.boardingType}
                            onChange={(val) => formik.setFieldValue('boardingType', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.boardingType}</FormFeedback>
                        </FormGroup>
                      </Col>

                      {formik.values?.boardingType?.name?.includes('Boarding') && (
                        <>
                          <Col md={4}>
                            <FormGroup className="mb-4">
                              <Label for="sub_boarding" className="form-label  ">
                                Sub Boarding Type
                              </Label>
                              <Select
                                isMulti
                                id="sub_boarding"
                                placeholder="Select an option"
                                options={collegesMasterData.filter(
                                  (d) => d.type === CollegeMasterType.BOARDING_SUB_TYPE
                                )}
                                value={formik.values.boardingSubTypes}
                                onChange={(val) => formik.setFieldValue('boardingSubTypes', val)}
                              />
                              <FormFeedback className="d-block">{formik.errors.boardingSubTypes}</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md={4}>
                            <FormGroup className="mb-4">
                              <Label for="boarding_gender_type" className="form-label  ">
                                Boarding Gender Type
                              </Label>
                              <Select
                                id="boarding_gender_type"
                                placeholder="Select an option"
                                options={collegesMasterData.filter(
                                  (d) => d.type === CollegeMasterType.BOARDING_GENDER_TYPE
                                )}
                                value={formik.values.boardingGenderType}
                                onChange={(val) => formik.setFieldValue('boardingGenderType', val)}
                              />
                              <FormFeedback className="d-block">{formik.errors.boardingGenderType}</FormFeedback>
                            </FormGroup>
                          </Col>
                        </>
                      )}
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="manag_type" className="form-label  ">
                            Management Type<span className="text-danger">*</span>
                          </Label>
                          <Select
                            id="manag_type"
                            placeholder="Select an option"
                            options={collegesMasterData.filter((d) => d.type === CollegeMasterType.MANAGEMENT_TYPE)}
                            value={formik.values.managementType}
                            onChange={(val) => formik.setFieldValue('managementType', val)}
                          />
                          {formik.touched.managementType && (
                            <div className="invalid-feedback d-block">{formik.errors.managementType}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="manag_name" className="form-label  ">
                            Management Name/Trust/Society Name
                          </Label>
                          <Input
                            id="manag_name"
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            label="Established In"
                            invalid={!!(formik.touched.managementName && formik.errors.managementName)}
                            {...formik.getFieldProps('managementName')}
                          />
                          <FormFeedback className="d-block">{formik.errors.managementName}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <h3 className="mb-4">Address</h3>
                <Card className="mb-5">
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="school_country" className="form-label  ">
                            Country <span className="text-danger">*</span>
                          </Label>
                          <Select
                            id="school_country"
                            value={formik.values.country}
                            options={countries}
                            onChange={handleCountrySelect}
                          />
                          {formik.touched.country && (
                            <div className="invalid-feedback d-block">{formik.errors.country}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="school_region" className="form-label  ">
                            Region/State <span className="text-danger">*</span>
                          </Label>
                          <Select
                            id="school_region"
                            value={formik.values.region}
                            options={regions}
                            onChange={handleRegionSelect}
                          />
                          {formik.touched.region && (
                            <div className="invalid-feedback d-block">{formik.errors.region}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="school_district" className="form-label  ">
                            District
                          </Label>
                          <Creatable
                            isClearable
                            id="school_district"
                            value={formik.values.district}
                            // NOTE: cities and districts too
                            options={cities?.filter((c) => c.level === 2)}
                            onChange={handleDistrictSelect}
                          />
                          {formik.touched.district && (
                            <div className="invalid-feedback d-block">{formik.errors.district}</div>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="school_city" className="form-label  ">
                            City <span className="text-danger">*</span>
                          </Label>
                          <Creatable
                            isClearable
                            id="school_city"
                            value={formik.values.city}
                            options={cities?.filter((c) => c.level === 3)}
                            onChange={handleCitySelect}
                          />
                          {formik.touched.city && <div className="invalid-feedback d-block">{formik.errors.city}</div>}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="school_subarea" className="form-label  ">
                            SubArea
                          </Label>
                          <Creatable
                            isClearable
                            id="school_subarea"
                            value={formik.values.subArea}
                            options={subAreas}
                            onChange={handleSubAreaSelect}
                          />
                          {formik.touched.subArea && (
                            <div className="invalid-feedback d-block">{formik.errors.subArea}</div>
                          )}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="school_pincode" className="form-label  ">
                            Pincode
                          </Label>
                          <Input
                            id="school_pincode"
                            type="text"
                            className="form-control"
                            placeholder="Pincode..."
                            invalid={!!(formik.touched.postalCode && formik.errors.postalCode)}
                            {...formik.getFieldProps('postalCode')}
                          />
                          <FormFeedback className="d-block">{formik.errors.postalCode}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="school_address" className="form-label  ">
                            Address
                          </Label>
                          <Input
                            id="school_address"
                            type="textarea"
                            className="form-control"
                            rows="3"
                            placeholder="Address..."
                            invalid={!!(formik.touched.fullAddress && formik.errors.fullAddress)}
                            {...formik.getFieldProps('fullAddress')}
                          />
                          <FormFeedback className="d-block">{formik.errors.fullAddress}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="latitude" className="form-label  ">
                            Latitude
                          </Label>
                          <Input
                            id="latitude"
                            type="text"
                            className="form-control mb-1"
                            placeholder="Latitude..."
                            invalid={!!(formik.touched.latitude && formik.errors.latitude)}
                            {...formik.getFieldProps('latitude')}
                          />
                          Ref: 20.12345678
                          <FormFeedback className="d-block">{formik.errors.latitude}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label htmlFor="longitude" className="form-label  ">
                            Longitude
                          </Label>
                          <Input
                            id="longitude"
                            type="text"
                            className="form-control mb-1"
                            placeholder="Longitude..."
                            invalid={!!(formik.touched.longitude && formik.errors.longitude)}
                            {...formik.getFieldProps('longitude')}
                          />
                          Ref: 20.12345678
                          <FormFeedback className="d-block">{formik.errors.longitude}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <div>
                      <Button type="submit" color="primary" className="mb-3 w-100">
                        Add College
                      </Button>

                      <Button
                        className="w-100"
                        color="light"
                        onClick={() => {
                          history.replace(SMKLinks.COLLEGE_LISTING);
                        }}>
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default AddCollege;
