import TextEditor from 'components/TextEditor';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, FormGroup, Input, Label } from 'reactstrap';

const FaqCard = ({ formik, apiDetails }) => {
  const [faqSections, setFaqSections] = useState([]);

  const handleUpdateValue = (index, type, value) => {
    const updatedFaqSections = [...faqSections];
    updatedFaqSections[index][type] = value;
    setFaqSections(updatedFaqSections);
  };

  const addFaqSection = () => {
    setFaqSections([...faqSections, { title: '', description: '' }]);
  };

  const removeFaqSection = (index) => {
    const updatedFaqSections = faqSections.filter((_, i) => i !== index);
    setFaqSections(updatedFaqSections);
  };

  useEffect(() => {
    formik.setFieldValue('faqs', !isEmpty(faqSections) && !isEmpty(faqSections[0]?.title) ? faqSections : []);
  }, [faqSections]);

  useEffect(() => {
    if (!isEmpty(apiDetails) && !isEmpty(apiDetails?.faqs)) {
      setFaqSections(apiDetails?.faqs);
    }
  }, [apiDetails]);

  return (
    <div className="mb-5">
      <h5 className="border-bottom pb-3">FAQ Items</h5>
      {!isEmpty(faqSections) &&
        faqSections?.map((section, index) => (
          <Card className="border mb-4" key={index}>
            <CardBody>
              <div>
                <div className="mb-3 bg-light p-3 rounded-2">
                  <h4 className="text-center mb-0"> FAQ Item -{index + 1}</h4>
                </div>

                <div className="row mb-3 d-flex justify-content-end">
                  <div className="col-md-3 text-end">
                    <Button outline color="danger" onClick={() => removeFaqSection(index)}>
                      Remove
                    </Button>
                  </div>
                </div>

                <FormGroup className="mb-4">
                  <Label for={`faqTitle-${index}`}>Title</Label>
                  <Input
                    placeholder="Enter title"
                    type="text"
                    id={`faqTitle-${index}`}
                    value={section.title}
                    onChange={(e) => handleUpdateValue(index, 'title', e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="mb-4">
                  <Label for={`faqDescription-${index}`}>Description</Label>
                  <TextEditor
                    height="100"
                    simple
                    initialValue={section.description}
                    onChange={(val) => handleUpdateValue(index, 'description', val)}
                  />
                </FormGroup>
              </div>
            </CardBody>
          </Card>
        ))}
      <Button className="mt-3" color="info" onClick={addFaqSection}>
        <i className="bx bx-plus me-3" />
        Add FAQ
      </Button>
    </div>
  );
};

export default React.memo(FaqCard);
