import produce from 'immer';
import {
  getCities,
  getCollegeCourses,
  getCollegeCoursesSearchData,
  getCollegeDetail,
  getColleges,
  getCollegesCoursesMasterData,
  getCollegesGroups,
  getCollegesInfraList,
  getCollegesMasterData,
  getCountries,
  getRegions,
  getSubAreas,
  updateCollegeAcademicDetails,
  updateCollegeAdditionalDetails,
  updateCollegeAdmissionDetails,
  updateCollegeBasicDetails,
  updateCollegeContactDetails,
  updateCollegeExtraCurricularDetails,
  updateCollegeFacilityDetails,
  updateCollegeFeeDetails,
  updateCollegeSystemDetails,
  getCollegeAdmissionInquiries,
  collegeAdmissionInquiriesMarkActionTaken,
  collegeAdmissionInquiriesMarkDeleted,
  getCollegeReviews,
} from './actions';

export const initialState = {
  countries: [],
  regions: [],
  cities: [],
  subAreas: [],

  collegeAdmissionInquiries: [],
  collegeAdmissionInquiriesPagination: {},

  collegeSearchList: [],

  colleges: [],
  collegesPagination: {},

  collegeReviews: [],
  collegeReviewsPagination: [],

  collegeDetail: {},

  collegeCourses: [],
  collegesMasterData: [],
  collegesInfraList: [],
  collegesGroups: [],

  collegesCoursesMasterData: [],
  collegesCoursesSearchData: [],

  courses: [],
};

const collegeReducer = produce((draft, action) => {
  switch (action.type) {
    case getColleges.SUCCESS: {
      const { results, paginator } = action.payload;
      draft.colleges = results;
      draft.collegesPagination = paginator;
      break;
    }

    case getCollegeReviews.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.collegeReviews = results;
      draft.collegeReviewsPagination = pagination;
      break;
    }

    case getCollegeDetail.TRIGGER: {
      draft.collegeDetail = {};
      break;
    }
    case getCollegesGroups.SUCCESS: {
      const { results, paginator } = action.payload;
      draft.collegesGroups = results?.map((s) => ({ ...s, value: s.id, label: s.name }));
      break;
    }

    case getCollegeAdmissionInquiries.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.collegeAdmissionInquiries = results;
      draft.collegeAdmissionInquiriesPagination = pagination;
      break;
    }
    case collegeAdmissionInquiriesMarkActionTaken.SUCCESS: {
      const { admissionInquiry } = action.payload;

      draft.collegeAdmissionInquiries = Object.assign([], draft.collegeAdmissionInquiries).map((item) => {
        return item.id !== admissionInquiry.id ? item : admissionInquiry;
      });

      break;
    }

    case collegeAdmissionInquiriesMarkDeleted.SUCCESS: {
      const { admissionInquiry } = action.payload;

      draft.collegeAdmissionInquiries = Object.assign([], draft.collegeAdmissionInquiries).map((item) => {
        return item.id !== admissionInquiry.id ? item : admissionInquiry;
      });

      break;
    }

    case getCountries.SUCCESS: {
      const { results } = action.payload;
      draft.countries = results.map((r) => ({ ...r, label: r.name + ` (${r.id})`, value: r.id }));
      break;
    }
    case getRegions.SUCCESS: {
      const { results } = action.payload;
      draft.regions = results.map((r) => ({ ...r, label: r.name + ` (${r.id})`, value: r.id }));
      break;
    }
    case getCities.SUCCESS: {
      const { results } = action.payload;
      draft.cities = results.map((r) => ({ ...r, label: r.name + ` (${r.id})`, value: r.id }));
      break;
    }
    case getSubAreas.SUCCESS: {
      const { results } = action.payload;
      draft.subAreas = results.map((r) => ({ ...r, label: r.name + ` (${r.id})  `, value: r.id }));
      break;
    }

    // load college
    case getCollegeDetail.SUCCESS:
    case updateCollegeBasicDetails.SUCCESS:
    case updateCollegeContactDetails.SUCCESS:
    case updateCollegeAdmissionDetails.SUCCESS:
    case updateCollegeFeeDetails.SUCCESS:
    case updateCollegeAcademicDetails.SUCCESS:
    case updateCollegeFacilityDetails.SUCCESS:
    case updateCollegeExtraCurricularDetails.SUCCESS:
    case updateCollegeSystemDetails.SUCCESS:
    case updateCollegeAdditionalDetails.SUCCESS: {
      draft.collegeDetail = action.payload;
      break;
    }

    case getCollegesInfraList.SUCCESS: {
      draft.collegesInfraList = action.payload.map((s) => ({ ...s, value: s.id, label: s.name }));
      break;
    }

    // case getCollegeCourses.SUCCESS: {
    //   // console.log(action.payload);
    //   draft.collegeCourses = action.payload.map((s) => ({ ...s, value: s.id, label: s.name }));
    //   break;
    // }
    case getCollegesMasterData.SUCCESS: {
      draft.collegesMasterData = action.payload.map((s) => ({ ...s, value: s.id, label: s.name }));
      break;
    }
    case getCollegesCoursesMasterData.SUCCESS: {
      draft.collegesCoursesMasterData = action.payload;
      break;
    }
    case getCollegeCoursesSearchData.SUCCESS: {
      draft.collegesCoursesSearchData = action.payload;
      break;
    }
    case getCollegeCourses.SUCCESS: {
      draft.collegesCoursesSearchData = action.payload;
      break;
    }
    default:
      break;
  }
}, initialState);

export default collegeReducer;
