const FORUM_TOPIC = 'SMK_ADMIN/FORUM_TOPIC';
const FORUM_TOPIC_SEARCH = 'SMK_ADMIN/FORUM_TOPIC_SEARCH';
const FORUM_TOPIC_DETAIL = 'SMK_ADMIN/FORUM_TOPIC_DETAIL';
const FORUM_TOPIC_UPDATE = 'SMK_ADMIN/FORUM_TOPIC_UPDATE';
const SET_FORUM_TOPIC_ACTIVE = 'SMK_ADMIN/SET_FORUM_TOPIC_APPROVE';
const SET_FORUM_TOPIC_UNPUBLISH = 'SMK_ADMIN/SET_FORUM_TOPIC_UNPUBLISH';
const SET_FORUM_TOPIC_DELETED = 'SMK_ADMIN/SET_FORUM_TOPIC_DELETED';
const SET_FORUM_POST_DELETED = 'SMK_ADMIN/SET_FORUM_POST_DELETED';
const SET_FORUM_POST_ACTIVE = 'SMK_ADMIN/SET_FORUM_POST_APPROVE';
const SET_FORUM_POST_UNPUBLISH = 'SMK_ADMIN/SET_FORUM_POST_UNPUBLISH';

const FORUM_POST = 'SMK_ADMIN/FORUM_POST';
const FORUM_POST_DETAIL = 'SMK_ADMIN/FORUM_POST_DETAIL';
const FORUM_POST_UPDATE = 'SMK_ADMIN/FORUM_POST_UPDATE';

const FORUM_SUB_CATEGORIES = 'SMK_ADMIN/FORUM_SUB_CATEGORIES';
const FORUM_CATEGORIES = 'SMK_ADMIN/FORUM_CATEGORIES';

module.exports = {
  FORUM_TOPIC,
  FORUM_POST,
  FORUM_TOPIC_SEARCH,
  SET_FORUM_POST_ACTIVE,
  SET_FORUM_TOPIC_ACTIVE,
  FORUM_CATEGORIES,
  FORUM_SUB_CATEGORIES,
  SET_FORUM_TOPIC_DELETED,
  SET_FORUM_POST_DELETED,
  FORUM_TOPIC_DETAIL,
  SET_FORUM_TOPIC_UNPUBLISH,
  FORUM_POST_DETAIL,
  SET_FORUM_POST_UNPUBLISH,
  FORUM_TOPIC_UPDATE,
  FORUM_POST_UPDATE,
};
