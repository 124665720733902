import HelpCenterList from 'containers/helpCenter/HelpCenterList';
import { USER_ROLE } from '../../utils/constants';
import ContactInquiryList from './InquiryList';
import CONTACT_INQUIRY_LINKS from './links';
import HelpCenter from 'containers/helpCenter/HelpCenter';

export default [
  {
    path: CONTACT_INQUIRY_LINKS.CONTACT_INQUIRY_LISTING,
    exact: true,
    component: ContactInquiryList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: CONTACT_INQUIRY_LINKS.HELP_CENTER,
    exact: true,
    component: HelpCenterList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: CONTACT_INQUIRY_LINKS.HELP_CENTER_CONVERSATIONS,
    exact: true,
    component: HelpCenter,
    role: [USER_ROLE.ADMIN],
  },
];
