export const ALLERGENS_LIST = [
  'Milk',
  'Eggs',
  'Peanuts',
  'Tree Nuts',
  'Fish',
  'Shellfish',
  'Soy',
  'Wheat',
  'Sesame',
  'Mustard',
  'Celery',
  'Lupin',
  'Mollusks',
];

export const NUTRITIONS_LIST = [
  {
    label: 'Serving Weight',
    value: 'servingWeight',
    inputType: 'String',
  },
  {
    label: 'Serving Amount',
    value: 'servingAmount',
    inputType: 'String',
  },
  {
    label: 'Calories',
    value: 'calories',
    inputType: 'Float',
  },
  {
    label: 'Total Fats',
    value: 'fats_total',
    inputType: 'DTO',
  },
  {
    label: 'Saturated Fats',
    value: 'fats_saturated',
    inputType: 'DTO',
  },
  {
    label: 'Trans Fats',
    value: 'fats_trans',
    inputType: 'DTO',
  },
  {
    label: 'Polyunsaturated Fats',
    value: 'fats_polyunsaturated',
    inputType: 'DTO',
  },
  {
    label: 'Monounsaturated Fats',
    value: 'fats_monounsaturated',
    inputType: 'DTO',
  },
  {
    label: 'Cholesterol',
    value: 'cholesterol',
    inputType: 'DTO',
  },
  {
    label: 'Sodium',
    value: 'sodium',
    inputType: 'DTO',
  },
  {
    label: 'Total Carbohydrates',
    value: 'carbohydrates_total',
    inputType: 'DTO',
  },
  {
    label: 'Carbohydrates Fiber',
    value: 'carbohydrates_fiber',
    inputType: 'DTO',
  },
  {
    label: 'Total Carbohydrates Sugar',
    value: 'carbohydrates_sugars_total',
    inputType: 'DTO',
  },
  {
    label: 'Carbohydrates Sugar Added',
    value: 'carbohydrates_sugars_added',
    inputType: 'DTO',
  },
  {
    label: 'Protein',
    value: 'protein',
    inputType: 'DTO',
  },
  {
    label: 'Vitamins',
    value: 'vitamins',
    inputType: 'DTO',
    isMultiple: true,
  },
  {
    label: 'Minerals',
    value: 'minerals',
    inputType: 'DTO',
    isMultiple: true,
  },
  {
    label: 'Glycemic Index',
    value: 'glycemicIndex',
    inputType: 'Float',
  },
];
