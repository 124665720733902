const SHOW_CIRCULAR_LOADER = 'global/showCircularLoader';
const SET_GLOBAL_ERROR = 'global/error';
const GET_USER = 'global/getUser';
const USER_SEARCH_LIST = 'global/getUserSearch';
const RESET_APP = 'global/resetApp';

module.exports = {
  SHOW_CIRCULAR_LOADER,
  SET_GLOBAL_ERROR,
  GET_USER,
  RESET_APP,
  USER_SEARCH_LIST,
};
