import { createRoutine } from 'redux-saga-routines';
import {
  GET_CITIES,
  GET_COUNTRIES,
  GET_REGIONS,
  GET_DISTRICTS,
  GET_SUB_AREAS,
  LOCATION_LIST,
  ADD_LOCATION,
  EDIT_LOCATION,
  GET_LOCATION_DETAIL,
} from './actionTypes';

// ACTIONS
export const getLocations = createRoutine(LOCATION_LIST);
export const addLocation = createRoutine(ADD_LOCATION);
export const updateLocation = createRoutine(EDIT_LOCATION);
export const getLocationDetail = createRoutine(GET_LOCATION_DETAIL);

export const getCountries = createRoutine(GET_COUNTRIES);
export const getRegions = createRoutine(GET_REGIONS);
export const getDistricts = createRoutine(GET_DISTRICTS);
export const getCities = createRoutine(GET_CITIES);
export const getSubAreas = createRoutine(GET_SUB_AREAS);
