import { USER_ROLE } from '../../utils/constants';
import GENERATE_SITEMAP_LINKS from './links';
import MismatchSitemap from './Sitemap/mistmatchSitemap';
import SMKSitemap from './Sitemap/smkSitemap';
import TPZSitemap from './Sitemap/theParentzSitemap';

export default [
  {
    path: GENERATE_SITEMAP_LINKS.GENERATE_SMK_SITEMAP,
    exact: true,
    component: SMKSitemap,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: GENERATE_SITEMAP_LINKS.GENERATE_TPZ_SITEMAP,
    exact: true,
    component: TPZSitemap,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: GENERATE_SITEMAP_LINKS.MISMATCH_SMK_SITEMAP,
    exact: true,
    component: MismatchSitemap,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: GENERATE_SITEMAP_LINKS.MISMATCH_TPZ_SITEMAP,
    exact: true,
    component: MismatchSitemap,
    role: [USER_ROLE.ADMIN],
  },
];
