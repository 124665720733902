import { createSelector } from 'reselect';

import { initialState } from './reducer';

export const WORKSHEET_KEY = 'worksheet';

const selectForumSubStore = (store) => store[WORKSHEET_KEY] || initialState;

const workSheetsSubjectsSelector = createSelector(selectForumSubStore, (subState) => subState.subjects);

const workSheetsTopicsSelector = createSelector(selectForumSubStore, (subState) => subState.topics);

const workSheetListSelector = createSelector(selectForumSubStore, (subState) => subState.worksheets);

const workSheetSearchListSelector = createSelector(selectForumSubStore, (subState) => subState.worksheetSearchList);

const workSheetPaginationSelector = createSelector(selectForumSubStore, (subState) => subState.worksheetPagination);

const worksheetDetailSelector = createSelector(selectForumSubStore, (subState) => subState.worksheetDetail);

const createCategoryDoneSelector = createSelector(selectForumSubStore, (subState) => subState.createCategoryDone);

export {
  workSheetsTopicsSelector,
  workSheetsSubjectsSelector,
  workSheetListSelector,
  workSheetSearchListSelector,
  workSheetPaginationSelector,
  worksheetDetailSelector,
  createCategoryDoneSelector,
};
