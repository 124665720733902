import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { toast } from 'react-toastify';
import confirm from 'reactstrap-confirm';

import { isEmpty } from 'lodash';
import TableError from 'components/TableError';
import useRequest from '../../../hooks/useRequest';
import Loader from '../../../components/Loader';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Paginate from 'components/Paginate';
import { useHistory } from 'react-router-dom';
import { useRouter } from 'hooks/useRouter';
import { SORT_VALUES } from 'utils/constants';

const ApiCacheList = () => {
  toast.configure();

  const [apiCacheList, setApiCacheList] = useState([]);
  const [apiCacheListPagination, setApiCacheListPagination] = useState([]);
  const [paramsValue, setParamsValue] = useState('');
  const [showFullParamsModal, setShowFullParamsModal] = useState(false);
  const [applyFiltersShow, setApplyFiltersShow] = useState(false);
  const router = useRouter();
  const history = useHistory();

  const prefixInputValues = [
    'BANNERS_KEY',
    'BABYNAME_SEARCH_KEY',
    'BABYNAME_POPULAR_KEY',
    'BABYNAME_DETAILS_KEY',
    'BABYNAME_SIMILAR_KEY',
    'BABYNAME_MEANING_ORIGIN_KEY',
    'BABYNAME_METAINFO_TYPE_KEY',
    'BABYNAME_METAINFO_ALL_KEY',
    'BABYNAME_SEO_METAINFO_ALL_KEY',
    'BABYNAME_SEO_METAINFO_SEARCH_KEY',
    'BT_CMS_ACTIVITY_TYPE_KEY',
    'BT_CMS_SYMPTOMS_KEY',
    'BT_CMS_SOLID_FOOD_KEY',
    'BT_CMS_MEDICINE_KEY',
    'BT_CMS_MILESTONE_KEY',
    'BT_CMS_VACCINATION_KEY',
    'BT_CMS_SEARCH_KEY',
    'BLOG_CHECK_CATEGORY_POST_KEY',
    'BLOG_SEARCH_KEY',
    'BLOG_CATEGORY_KEY',
    'BLOG_CATEGORIES_KEY',
    'BLOG_DETAILS_KEY',
    'CALC_CATEGORIES_KEY',
    'CALC_POPULAR_KEY',
    'CALC_SEARCH_KEY',
    'CALC_CATEGORY_KEY',
    'SCHOOL_CURRICULUM_KEY',
    'SCHOOL_SEARCH_KEY',
    'SCHOOL_FEATURED_KEY',
    'SCHOOL_PLACE_KEY',
    'SCHOOL_SIMILAR_KEY',
    'SCHOOL_FEEDTOPIC_KEY',
    'SCHOOL_FEEDTOPIC_POSTS_KEY',
    'SCHOOL_COUNTRY_BOARDS_KEY',
    'SCHOOL_APPLICABLE_BOARDS_KEY',
    'SCHOOL_ADMISSION_CRITERIA_POINTS_KEY',
    'SCHOOL_DETAILS_KEY',
    'SCHOOL_LEVEL_KEY',
    'SCHOOL_MASTERDATA_KEY',
    'STATIC_URLS_KEY',
    'WG_CATEGORIES_KEY',
    'WG_POPULAR_KEY',
    'WG_SEARCH_KEY',
  ];
  const prefixInputSelectValues = prefixInputValues?.map((elem) => {
    return {
      label: elem,
      value: elem,
    };
  });

  const defaultFilters = {
    prefixSelect: { value: 'SCHOOLMYKIDS_', label: 'SchoolMyKids' },
    prefixInput: { value: '', label: 'All' },
    url: '',
    queryParam: '',
    queryParamValue: '',
    bodyParam: '',
    bodyParamValue: '',
    useOr: true,
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [getApiCacheList, { loading: getApiCacheListLoading }] = useRequest({
    url: `/admin-tools/searchCache`,
    method: 'POST',
    onSuccess: (data) => {
      setApiCacheList(data?.results);
      setApiCacheListPagination(data?.pagination);
    },
  });
  const [deleteAPICache, { loading: deleteAPICacheLoading }] = useRequest({
    url: '/admin-tools/clearCache',
    method: 'POST',
    onSuccess: (data) => {
      clearFilters(true);
      toast.success('Success! API Cache has been Deleted');
    },
    onError: (err) => {
      toast.error(err?.message);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const defaultPayload = {
    page: 1,
    size: 20,
    sortBy: 'id',
    sortOrder: 0,
    useOr: true,
  };

  const applyFilters = () => {
    setPageInUrl(1);
    setApplyFiltersShow(true);
    getApiCacheList({
      body: {
        key: filters?.prefixSelect?.value + filters?.prefixInput?.value,
        url: filters?.url,
        queryParam: filters?.queryParam,
        queryParamValue: filters?.queryParamValue,
        bodyParam: filters?.bodyParam,
        bodyParamValue: filters?.bodyParamValue,
        ...defaultPayload,
      },
    });
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });

    getApiCacheList({
      body: {
        ...defaultPayload,
        key: defaultFilters?.prefixSelect?.value + defaultFilters?.prefixInput?.value,
      },
    });
  };

  useEffect(() => {
    clearFilters();
  }, []);

  const handleDeleteAPICache = async (data, deleteAll, clearAllCache) => {
    const result = await confirm({
      title: `Delete ${deleteAll ? 'All' : ''} API Cache`,
      message: `Are you sure, you want to Delete  ${deleteAll ? 'All' : 'this'} API Cache?`,
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });

    if (result) {
      if (!isEmpty(clearAllCache)) {
        deleteAPICache({
          body: {
            keys: [],
            keyPrefix: `${filters?.prefixSelect?.value}`,
          },
        });
      } else {
        deleteAPICache(
          deleteAll
            ? {
                body: {
                  keys: [],
                  keyPrefix: `${data?.prefix}`,
                },
              }
            : {
                body: {
                  keys: [data?.key],
                },
              }
        );
      }
    }
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);
    // setFilters({ ...defaultFilters });

    getApiCacheList({
      body: {
        key: filters?.prefixSelect?.value + filters?.prefixInput?.value,
        page: page,
        size: 50,
        ...SORT_VALUES,
      },
    });
  };

  const clearCacheWithFilters = () => {
    let keys = [];
    apiCacheList?.map((e) => keys?.push(e.key));
    deleteAPICache({
      body: {
        keys,
        keyPrefix: '',
        urls: '',
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getApiCacheListLoading || deleteAPICacheLoading} />

        <Container fluid>
          <Breadcrumbs title="Others" breadcrumbItem="API Cache list" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <Row className="mt-4">
                  <Col md={3}>
                    <Label htmlFor="prefix" className="form-label">
                      Prefix
                    </Label>
                    <FormGroup className="mb-4">
                      <Select
                        id="prefix"
                        options={[
                          {
                            label: 'SchoolMyKids',
                            value: 'SCHOOLMYKIDS_',
                          },
                          {
                            label: 'TheParentz',
                            value: 'THEPARENTZ_',
                          },
                        ]}
                        value={filters.prefixSelect}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            prefixSelect: e,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label className="form-label">&nbsp;</Label>
                      <Select
                        id="prefix"
                        options={prefixInputSelectValues}
                        value={filters.prefixInput}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            prefixInput: e,
                          })
                        }
                      />

                      {/* 
                      <Input
                        type="text"
                        value={filters?.prefixInput}
                        onChange={(e) =>
                          setFilters({
                            ...filters,
                            prefixInput: e.target.value,
                          })
                        }
                      /> */}
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup className="mb-4 d-flex flex-column">
                      <Label className="form-label">&nbsp;</Label>
                      <Button
                        onClick={() => {
                          handleDeleteAPICache('', true, 'clearAllCache');
                        }}
                        color="light"
                        className="ms-4">
                        Clear All cache
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <div className="col-lg-12 mb-4">
                    <label className="form-label">URL</label>
                    <Input
                      type="text"
                      name="url"
                      placeholder="URL..."
                      value={filters?.url}
                      onChange={(e) => setFilters({ ...filters, url: e.target.value })}
                    />
                  </div>

                  <div className="col-lg-3">
                    <label className="form-label">Query param</label>
                    <Input
                      type="text"
                      name="queryParam"
                      placeholder="Query param..."
                      value={filters?.queryParam}
                      onChange={(e) => setFilters({ ...filters, queryParam: e.target.value })}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label">Query param value</label>
                    <Input
                      type="text"
                      name="queryParamValue"
                      placeholder="QueryParamValue..."
                      value={filters?.queryParamValue}
                      onChange={(e) => setFilters({ ...filters, queryParamValue: e.target.value })}
                    />
                  </div>

                  <div className="col-lg-3 ">
                    <label className="form-label">Body param</label>
                    <Input
                      type="text"
                      name="bodyParam"
                      placeholder="Body param..."
                      value={filters?.bodyParam}
                      onChange={(e) => setFilters({ ...filters, bodyParam: e.target.value })}
                    />
                  </div>
                  <div className="col-lg-3 ">
                    <label className="form-label">Body param value</label>
                    <Input
                      type="text"
                      name="bodyParamValue"
                      placeholder="Body param value..."
                      value={filters?.bodyParamValue}
                      onChange={(e) => setFilters({ ...filters, bodyParamValue: e.target.value })}
                    />
                  </div>
                </Row>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <div className="mb-2 d-flex align-items-center justify-content-between">
            <div className="search-box me-2 mb-2 d-inline-block">
              <div className="position-relative font-bold">
                Total {apiCacheListPagination?.totalCount} API Cache Found, Showing {apiCacheList?.length}
              </div>
            </div>

            {applyFiltersShow && (
              <a className="btn btn-primary " onClick={clearCacheWithFilters}>
                Clear Cache with Filters
              </a>
            )}
          </div>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">S.No. </th>
                        <th scope="col">Keys </th>
                        {/* <th scope="col">Params</th> */}
                        <th scope="col">URL </th>
                        <th scope="col">Query </th>
                        <th scope="col">Body </th>
                        <th scope="col">Action </th>
                        {/* <th scope="col" /> */}
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(apiCacheList) ? (
                        apiCacheList?.map((data, index) => {
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                {data?.prefix}
                                {`${data?.key?.slice(0, 10)}...`}
                              </td>
                              {/* <td>
                                <span
                                  style={{
                                    cursor: 'pointer',
                                    color: '#3452e1',
                                  }}
                                  onClick={() => {
                                    setShowFullParamsModal(
                                      !showFullParamsModal,
                                    );
                                    setParamsValue(data?.key);
                                    // navigator.clipboard.writeText(data?.key);
                                    // toast.success('Key copied to clipboard!');
                                  }}>
                                  {data?.key?.slice(0, 50) + '...'}
                                </span>
                              </td> */}
                              <td>{data?.url}</td>
                              <td>{!isEmpty(data?.query) && JSON.stringify(data?.query)}</td>
                              <td> {!isEmpty(data?.body) && JSON.stringify(data?.body)}</td>
                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop " tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem
                                      onClick={() => {
                                        handleDeleteAPICache(data, false);
                                      }}>
                                      <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                      Delete
                                    </DropdownItem>
                                    {/* <DropdownItem
                                      onClick={() => {
                                        handleDeleteAPICache(data, true);
                                      }}>
                                      <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                      Delete All
                                    </DropdownItem> */}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={apiCacheListPagination} onChangePage={handleChangePage} />
        </Container>

        <Modal
          isOpen={showFullParamsModal}
          role="dialog"
          autoFocus
          centered
          className="exampleModal"
          tabIndex="-1"
          toggle={() => {
            setShowFullParamsModal(!showFullParamsModal);
          }}>
          <div className="modal-content">
            <ModalHeader
              className="modalHeader"
              toggle={() => {
                setShowFullParamsModal(!showFullParamsModal);
              }}>
              See Full Params
            </ModalHeader>
            <ModalBody>
              <h5
                style={{
                  wordWrap: 'break-word',
                }}>
                {paramsValue}
              </h5>
            </ModalBody>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ApiCacheList;
