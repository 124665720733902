import React from 'react';

const TableError = () => {
  return (
    <tr>
      <td colSpan={'100%'}>
        <div style={{ minHeight: 170 }} className="d-flex justify-content-center align-items-center">
          <div className="text-center">
            <i className="bx bxs-error" style={{ fontSize: '50px', color: '#ffbc42' }} />
            <h5 className="mt-3 text-secondary">No Record Found!</h5>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default TableError;
