import AuthService from 'common/auth.service';
import { userSelector } from 'common/globalComponents/selectors';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { API_BASE_URL } from 'utils/constants';
import { changeSidebarType, showRightSidebarAction, toggleLeftmenu } from '../../store/actions';
import { RenderLogo } from './Sidebar';
import HRProfileMenu from 'components/CommonForBoth/TopbarDropdown/HRProfileMenu';

const stateSelector = createStructuredSelector({
  user: userSelector,
});
const HrHeader = (props) => {
  const [search, setsearch] = useState(false);
  const { t } = props;

  const { user } = useSelector(stateSelector);
  const appSource = user?.appSource || 'schoolmykids';

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === 'default') {
      props.changeSidebarType('condensed', isMobile);
    } else if (props.leftSideBarType === 'condensed') {
      props.changeSidebarType('default', isMobile);
    }
  }

  const auth = new AuthService();
  const source = auth.getAppSourceCookie();
  const isSMK = source === 'schoolmykids';
  const isTPZ = source === 'theparentz';

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsVisible(currentScrollTop < lastScrollTop);

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  useEffect(() => {
    const headerElement = document.getElementById('page-topbar');
    if (headerElement) {
      if (isVisible) {
        headerElement.classList.remove('hidden-header');
        headerElement.classList.add('visible-header');
      } else {
        headerElement.classList.remove('visible-header');
        headerElement.classList.add('hidden-header');
      }
    }
  }, [isVisible]);

  return (
    <header id="page-topbar">
      <div
        className={
          API_BASE_URL === 'https://www.schoolmykids.com/api/v2' ? 'bg-warning navbar-header' : 'navbar-header'
        }>
        <div className="d-flex">
          <div className="navbar-brand-box d-lg-none bg-white">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <RenderLogo leftMenu={true} appSource={appSource} />
              </span>
              <span className="logo-lg">
                <RenderLogo leftMenu={false} appSource={appSource} />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <RenderLogo leftMenu={true} appSource={appSource} />
              </span>
              <span className="logo-lg">
                <RenderLogo leftMenu={false} appSource={appSource} />
              </span>
            </Link>
          </div>
        </div>
        <div className="d-flex">
          <HRProfileMenu />
        </div>
      </div>
    </header>
  );
};

HrHeader.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(HrHeader));
