import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import PropTypes from 'prop-types';

const Paginate = (props) => {
  const { pageInfo, onChangePage } = props;
  const [pageNumbers, setPageNumbers] = useState([]);

  const currentPage = Number(pageInfo?.currentPage);
  const totalCount = Number(pageInfo?.totalCount);
  const size = Number(pageInfo?.size);

  useEffect(() => {
    const totalPages = Math.ceil(totalCount / size);
    const p = [];
    for (let i = currentPage - 5; i <= currentPage + 5; i += 1) {
      if (i > 0 && i <= totalPages) {
        p.push(i);
      }
    }
    totalCount === 0 ? setPageNumbers([]) : setPageNumbers(p);
  }, [currentPage, totalCount]);

  const nextPage = () => {
    const totalPages = Math.ceil(totalCount / size);
    const p = [];
    for (let i = currentPage - 2; i <= currentPage + 8; i += 1) {
      if (i > 0 && i <= totalPages) {
        p.push(i);
      }
    }
    onChangePage(currentPage + 1);
    setPageNumbers(p);
  };

  const previousPage = () => {
    const totalPages = Math.ceil(totalCount / size);
    const p = [];
    for (let i = currentPage - 8; i <= currentPage + 2; i += 1) {
      if (i > 0 && i <= totalPages) {
        p.push(i);
      }
    }
    onChangePage(currentPage - 1);
    setPageNumbers(p);
  };

  return (
    <>
      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink previous onClick={previousPage} />
        </PaginationItem>
        {pageNumbers?.map((number) => (
          <PaginationItem key={number}>
            <PaginationLink
              onClick={() => onChangePage(number)}
              className={number === currentPage ? 'rounded-circle bg-soft-primary' : ''}>
              {number}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationLink
            next
            disabled={pageNumbers[pageNumbers.length - 1] === Math.ceil(totalCount / size)}
            onClick={nextPage}
          />
        </PaginationItem>
      </Pagination>
    </>
  );
};

Paginate.propTypes = {
  pageInfo: PropTypes.shape({
    currentPage: PropTypes.number,
    totalCount: PropTypes.number,
    size: PropTypes.number,
  }).isRequired,
  onChangePage: PropTypes.func.isRequired,
};

export default Paginate;
