import NestedImageUploader from 'components/NestedImageUploader';
import TextEditor from 'components/TextEditor';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import { MODULE_TYPE, STATIC_PAGE_SECTION_TYPES } from 'utils/constants';
import CustomArticleSearch from '../components/CustomArticleSearch';

const StaticPageSections = ({ sections, setSections }) => {
  const defaultPayload = {
    order: '',
    title: '',
    slug: '',
    subTitle: '',
    articleIds: null,
    content: '',
    image: {},
    listItems: [],
  };

  const handleAddSection = () => {
    if (sections?.length === 0) {
      setSections([defaultPayload]);
      return;
    }

    const lastSection = sections[sections?.length - 1];

    if (!lastSection?.order || !lastSection?.title || !lastSection?.slug) {
      toast.error('Please fill required fields.');
      return;
    }

    setSections([...sections, defaultPayload]);
  };

  const handleRemoveSection = (index) => {
    setSections(sections?.filter((_, i) => i !== index));
  };

  const handleUpdateSection = (index, field, value) => {
    setSections((prev) => prev?.map((section, i) => (i === index ? { ...section, [field]: value } : section)));
  };

  const handleAddItem = (sectionIndex) => {
    const currentSec = sections[sectionIndex];
    const lastItem = currentSec?.listItems[currentSec?.listItems?.length - 1];

    if (lastItem && (!lastItem?.title || !lastItem?.order)) {
      toast.error('Please fill required fields.');
      return;
    }

    const newItem = {
      order: '',
      title: '',
      description: '',
      image: {},
    };
    const updatedSections = sections?.map((section, i) =>
      i === sectionIndex ? { ...section, listItems: [...section.listItems, newItem] } : section
    );
    setSections(updatedSections);
  };

  const handleRemoveItem = (sectionIndex, itemIndex) => {
    setSections((prevSections) =>
      prevSections.map((section, i) =>
        i === sectionIndex
          ? {
              ...section,
              listItems: (section.listItems || []).filter((_, j) => j !== itemIndex),
            }
          : section
      )
    );
  };

  const handleUpdateItems = (sectionIndex, itemIndex, field, val) => {
    setSections((prevSections) => {
      return prevSections.map((section, i) => {
        if (i === sectionIndex) {
          return {
            ...section,
            listItems: (section.listItems || []).map((item, j) => (j === itemIndex ? { ...item, [field]: val } : item)),
          };
        }
        return section;
      });
    });
  };

  return (
    <div className="mb-5">
      <h5 className="border-bottom pb-3">Sections</h5>
      {!isEmpty(sections) &&
        sections?.map((section, sectionIndex) => (
          <Card className="mb-4" key={sectionIndex}>
            <CardBody>
              <div className="bg-info text-white p-3 text-center rounded-2 mb-4">
                <h4 className="mb-0">Section - {sectionIndex + 1}</h4>
              </div>
              <div className="row mb-4 d-flex justify-content-end">
                <div className="col-md-3 text-end">
                  <Button outline color="danger" onClick={() => handleRemoveSection(sectionIndex)}>
                    Remove Section
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="row">
                    <div className=" col-md-3">
                      <Label for={`order-${sectionIndex}`} className="form-label mb-0">
                        Order <span className="text-danger">*</span>
                      </Label>
                    </div>
                    <div className="col-md-9">
                      <Select
                        placeholder="Select order"
                        id="order"
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 99999 }),
                        }}
                        options={Array.from({ length: 20 }, (_, index) => ({
                          value: index + 1,
                          label: (index + 1).toString(),
                        }))}
                        value={
                          section?.order
                            ? {
                                label: section.order.toString(),
                                value: section.order,
                              }
                            : null
                        }
                        onChange={(e) => handleUpdateSection(sectionIndex, 'order', Number(e.value))}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="mb-4 row">
                    <div className=" col-md-2 text-end">
                      <Label for={`slug-${sectionIndex}`} className="form-label mb-0 ">
                        Key <span className="text-danger">*</span>
                      </Label>
                    </div>
                    <div className="col-md-10">
                      <Select
                        placeholder="Select key"
                        id="key"
                        styles={{
                          menu: (provided) => ({ ...provided, zIndex: 99999 }),
                        }}
                        options={STATIC_PAGE_SECTION_TYPES}
                        value={STATIC_PAGE_SECTION_TYPES?.find((e) => e?.value === section.slug)}
                        onChange={(e) => handleUpdateSection(sectionIndex, 'slug', e.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 d-flex align-items-start row">
                <div className=" col-md-1">
                  <Label for={`title-${sectionIndex}`} className="form-label mb-0">
                    Title <span className="text-danger">*</span>
                  </Label>
                </div>
                <div className="col-md-11">
                  <Input
                    id={`title-${sectionIndex}`}
                    name={`title-${sectionIndex}`}
                    type="text"
                    className="form-control"
                    placeholder="title..."
                    value={section.title}
                    onChange={(e) => handleUpdateSection(sectionIndex, 'title', e.target.value)}
                  />
                </div>
              </div>

              <div className="mb-4 d-flex align-items-start row">
                <div className=" col-md-1">
                  <Label for={`subTitle-${sectionIndex}`} className="form-label mb-0">
                    SubTitle
                  </Label>
                </div>
                <div className="col-md-11">
                  <Input
                    id={`subTitle-${sectionIndex}`}
                    name={`subTitle-${sectionIndex}`}
                    type="text"
                    className="form-control"
                    placeholder="subTitle..."
                    value={section.subTitle}
                    onChange={(e) => handleUpdateSection(sectionIndex, 'subTitle', e.target.value)}
                  />
                </div>
              </div>
              {/* <div className="mb-4 d-flex align-items-start row">
                <div className=" col-md-1">
                  <Label for={`link-${sectionIndex}`} className="form-label mb-0">
                    Link
                  </Label>
                </div>
                <div className="col-md-11">
                  <Input
                    id={`link-${sectionIndex}`}
                    name={`link-${sectionIndex}`}
                    type="text"
                    className="form-control"
                    placeholder="link..."
                    value={section.link}
                    onChange={(e) => handleUpdateSection(sectionIndex, 'link', e.target.value)}
                  />
                </div>
              </div> */}
              <div className="mb-4 d-flex align-items-start row">
                <div className=" col-md-1">
                  <Label for={`content-${sectionIndex}`} className="form-label mb-0">
                    Content
                  </Label>
                </div>
                <div className="col-md-11">
                  <TextEditor
                    simple
                    height={200}
                    initialValue={section.content}
                    onChange={(val) => handleUpdateSection(sectionIndex, 'content', val)}
                  />
                </div>
              </div>
              <div className="mb-4 d-flex align-items-start row">
                <div className=" col-md-1"></div>
                <div className="col-md-11">
                  <div className="row">
                    <div className="col-md-6">
                      <Label for={`metadata-${sectionIndex}`} className="form-label mb-3">
                        Metadata
                      </Label>
                      <Input
                        rows={10}
                        id={`metadata-${sectionIndex}`}
                        name={`metadata-${sectionIndex}`}
                        type="textarea"
                        className="form-control"
                        placeholder="Metadata..."
                        value={section.metadata}
                        onChange={(e) => handleUpdateSection(sectionIndex, 'metadata', e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <Label for={`image-${sectionIndex}`} className="form-label mb-3">
                        Image
                      </Label>
                      <NestedImageUploader
                        forType={MODULE_TYPE.METADATA}
                        label={'Select Image'}
                        value={section.image}
                        onChange={(val) => handleUpdateSection(sectionIndex, 'image', val)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-4 pt-4 d-flex align-items-start row">
                <div className="col-md-1">
                  <Label for={`articles-${sectionIndex}`} className="form-label mb-3">
                    Articles
                  </Label>
                </div>
                <div className="col-md-11">
                  <CustomArticleSearch
                    value={section.articleIds}
                    onChange={(data) => handleUpdateSection(sectionIndex, 'articleIds', data)}
                  />
                </div>
              </div>

              <div className="mb-4 pt-4 d-flex align-items-start row">
                <div className="col-md-1">
                  <Label for={`items-${sectionIndex}`} className="form-label mb-3">
                    Items
                  </Label>
                </div>
                <div className="col-md-11">
                  {!isEmpty(section?.listItems) &&
                    section?.listItems?.map((item, itemIndex) => {
                      return (
                        <div key={itemIndex} className="mb-3 bg-light p-4 rounded-3 mb-3">
                          <div className="p-3 bg-white shadow-md border border-info text-info text-center rounded-2 mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                              <h4 className="mb-0">
                                Section - {sectionIndex + 1} , <span className="ms-3">Item - {itemIndex + 1}</span>
                              </h4>

                              <Button outline color="danger" onClick={() => handleRemoveItem(sectionIndex, itemIndex)}>
                                <i class="bx bx-trash me-1" /> Delete
                              </Button>
                            </div>
                          </div>
                          <div className="mb-3 form-control mb-0 rounded-2 p-4 py-4">
                            <Row>
                              <Col md={3} className="mb-3">
                                <Label for={`item_order_${sectionIndex}`} className="form-label">
                                  Order <span className="text-danger">*</span>
                                </Label>
                                <Select
                                  placeholder="Select order"
                                  id="order"
                                  styles={{
                                    menu: (provided) => ({ ...provided, zIndex: 99999 }),
                                  }}
                                  options={Array.from({ length: 20 }, (_, index) => ({
                                    value: index + 1,
                                    label: (index + 1).toString(),
                                  }))}
                                  value={
                                    item.order
                                      ? {
                                          label: item.order.toString(),
                                          value: item.order,
                                        }
                                      : null
                                  }
                                  onChange={(e) => handleUpdateItems(sectionIndex, itemIndex, 'order', Number(e.value))}
                                />
                              </Col>
                              <Col md={9} className="mb-3">
                                <Label for={`item_title_${sectionIndex}`} className="form-label">
                                  Title <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  id={`item_title_${itemIndex}`}
                                  name={`item_title_${itemIndex}`}
                                  type="text"
                                  className="form-control"
                                  placeholder="Title..."
                                  value={item.title}
                                  onChange={(e) => handleUpdateItems(sectionIndex, itemIndex, 'title', e.target.value)}
                                />
                              </Col>
                              <Col md={9} className="mb-3">
                                <Label for={`item_subTitle_${sectionIndex}`} className="form-label">
                                  SubTitle
                                </Label>
                                <Input
                                  id={`item_subTitle_${itemIndex}`}
                                  name={`item_subTitle_${itemIndex}`}
                                  type="textarea"
                                  rows={3}
                                  className="form-control"
                                  placeholder="summary..."
                                  value={item.subTitle}
                                  onChange={(e) =>
                                    handleUpdateItems(sectionIndex, itemIndex, 'subTitle', e.target.value)
                                  }
                                />
                              </Col>

                              <Col md={12} className="mb-3">
                                <Label for={`item_description_${sectionIndex}`} className="form-label">
                                  Content
                                </Label>

                                <TextEditor
                                  simple
                                  height={200}
                                  initialValue={item.description}
                                  onChange={(val) => {
                                    handleUpdateItems(sectionIndex, itemIndex, 'description', val);
                                  }}
                                />
                              </Col>
                              <Col md={6}>
                                <Label for={`metadata_${sectionIndex}`} className="form-label">
                                  Metadata
                                </Label>
                                <Input
                                  rows={10}
                                  id={`item_metadata_${itemIndex}`}
                                  name={`item_metadata_${itemIndex}`}
                                  type="textarea"
                                  className="form-control"
                                  placeholder="Metadata..."
                                  value={item.metadata}
                                  onChange={(e) =>
                                    handleUpdateItems(sectionIndex, itemIndex, 'metadata', e.target.value)
                                  }
                                />
                              </Col>
                              <Col md={6}>
                                <NestedImageUploader
                                  forType={MODULE_TYPE.METADATA}
                                  label={'Select Image'}
                                  value={item.image}
                                  onChange={(val) => handleUpdateItems(sectionIndex, itemIndex, 'image', val)}
                                />
                              </Col>
                            </Row>
                          </div>
                        </div>
                      );
                    })}

                  <Button
                    className={!isEmpty(section?.listItems) ? 'mt-3 px-4' : 'px-4'}
                    color="success"
                    onClick={() => handleAddItem(sectionIndex)}>
                    Add Item
                  </Button>
                </div>
              </div>
            </CardBody>
          </Card>
        ))}

      <Button className="mt-3" color="success" onClick={handleAddSection}>
        <i className="bx bx-plus me-2" />
        Add Section
      </Button>
      <Card className="mt-4">
        <CardBody>
          <h6>Example of Meta Data (enter in curly bracket)</h6>
          <pre>
            {JSON.stringify(
              {
                route: 'parenting-tips',
                isBlogCategoryRoute: 'parenting-tips',
                route: 'ADVERTISE_WITH_US',
                url:
                  'https://www.hindustantimes.com/education/virtual-after-school-activities-to-keep-kids-engaged-during-covid-19/story-W32bVEg0I29mzX2ucDZ4AN.html',
              },
              null,
              2
            )}
          </pre>
        </CardBody>
      </Card>
    </div>
  );
};

export default React.memo(StaticPageSections);
