import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import { useFormik } from 'formik';
import useRequest from 'hooks/useRequest';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Link, useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { DISCOUNT_TYPES, MODULE_TYPE } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import * as Yup from 'yup';

const AddUpdateSubscriptionOffer = () => {
  const { id: subscriptionOfferID } = useParams();
  const history = useHistory();
  toast.configure();

  const [subscriptionOfferDetails, setSubscriptionOfferDetails] = useState({});

  const discountTypes = Object.keys(DISCOUNT_TYPES)?.map((elem) => ({
    label: titleCaseIfExists(elem),
    value: DISCOUNT_TYPES[elem],
  }));

  const [getSubscriptionOfferRequest, { loading: getSubscriptionOfferLoading }] = useRequest({
    url: `/subscription/offers/admin/subscription-offer/${subscriptionOfferID}`,
    method: 'GET',
    onSuccess: (data) => {
      setSubscriptionOfferDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addSubscriptionOfferRequest, { loading: addSubscriptionOfferLoading }] = useRequest({
    url: `/subscription/offers/admin/subscription-offer`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.SUBSCRIPTION_OFFERS;
      history.replace(url);
      toast.success('Success! Subscription Offer has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateSubscriptionOfferRequest, { loading: updateSubscriptionOfferLoading }] = useRequest({
    url: `/subscription/offers/admin/subscription-offer/${subscriptionOfferID}`,
    method: 'PUT',
    onSuccess: (data) => {
      toast.success('Success! Subscription Offer has been updated.');
      window.location.reload();
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => ({
    ...subscriptionOfferDetails,
    startDate: !isEmpty(subscriptionOfferDetails?.startDate) ? new Date(subscriptionOfferDetails?.startDate) : null,
    endDate: !isEmpty(subscriptionOfferDetails?.endDate) ? new Date(subscriptionOfferDetails?.endDate) : null,
  });

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    title: '',
    discountType: '',
    discount: '',
    startDate: '',
    endDate: '',
    image: '',
    code: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isEmpty(subscriptionOfferID) ? { ...getInitialValues() } : { ...editInitialValues() },

    onSubmit: async (values) => {
      if (values?.discountType === DISCOUNT_TYPES.PERCENTAGE && values?.discount > 100) {
        toast.error('Discount cannot be greater than 100%');
        return '';
      }

      const payload = {
        body: {
          active: values?.active,
          deleted: values?.deleted,
          imageId: !isEmpty(values?.image) ? values?.image?.id : null,
          title: values?.title,
          discount: values?.discount,
          code: values?.code,
          discountType: values?.discountType,
          startDate: moment(values?.startDate),
          endDate: moment(values?.endDate),
        },
      };

      if (isEmpty(subscriptionOfferID)) {
        addSubscriptionOfferRequest(payload);
      } else {
        updateSubscriptionOfferRequest(payload);
      }
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Please Enter title'),
      code: Yup.string().required('Please Enter code'),
      discountType: Yup.string().required('Please Enter Discount Type'),
      discount: Yup.number()
        .min(0)
        .required('Please Enter Discount'),
      startDate: Yup.string().required('Please Enter Start Date'),
      endDate: Yup.string().required('Please Enter End Date'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(subscriptionOfferID)) {
      getSubscriptionOfferRequest();
    }
  }, [subscriptionOfferID]);

  return (
    <div className="page-content">
      <Loader isActive={addSubscriptionOfferLoading || getSubscriptionOfferLoading || updateSubscriptionOfferLoading} />
      <Container fluid>
        <Breadcrumbs
          title="Subscription Offer"
          breadcrumbItem={`${isEmpty(subscriptionOfferID) ? 'Add' : 'Edit'}  Subscription Offer`}
        />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="mx-0">
                    <CustomInput name="title" type="text" placeholder="Title" col={12} formik={formik} />
                    <CustomInput name="code" type="text" placeholder="Code" col={12} formik={formik} />

                    <FormGroup className="mb-4 col-md-6">
                      <Label for="startDate" htmlFor="startDate" className="form-label ">
                        Start Date
                      </Label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={new Date()}
                          className="form-control"
                          selected={formik.values.startDate}
                          onChange={(date) => formik.setFieldValue('startDate', date)}
                          placeholderText="Select Start Date..."
                          dateFormat="dd-MM-yyyy"
                        />
                        {formik.touched.startDate && formik.errors.startDate ? (
                          <FormFeedback className="d-block">{formik.errors.startDate}</FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>
                    <FormGroup className="mb-4 col-md-6">
                      <Label for="endDate" htmlFor="endDate" className="form-label ">
                        End Date
                      </Label>
                      <div className="position-relative">
                        <DatePicker
                          minDate={formik.values.startDate || new Date()}
                          className="form-control"
                          selected={formik.values.endDate}
                          onChange={(date) => formik.setFieldValue('endDate', date)}
                          placeholderText="Select End Date..."
                          dateFormat="dd-MM-yyyy"
                        />
                        {formik.touched.endDate && formik.errors.endDate ? (
                          <FormFeedback className="d-block">{formik.errors.endDate}</FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>

                    <FormGroup className="mb-4 col-md-6">
                      <Label for="discountType" htmlFor="discountType" className="form-label">
                        Discount Type
                      </Label>
                      <div className="position-relative">
                        <Select
                          options={discountTypes}
                          onChange={(e) => formik.setFieldValue('discountType', e?.value)}
                          value={discountTypes?.find((e) => e?.value === formik?.values?.discountType)}
                          onBlur={formik.handleBlur}
                          placeholder="Select Discount Type..."
                        />
                        {formik.touched.discountType && formik.errors.discountType ? (
                          <FormFeedback className="d-block">{formik.errors.discountType}</FormFeedback>
                        ) : null}
                      </div>
                    </FormGroup>

                    <CustomInput name="discount" type="number" placeholder="Discount" col={6} formik={formik} />
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card className="mb-4">
                <CardBody>
                  <Button color={'primary'} className="w-100" size="md" type="submit">
                    {!isEmpty(subscriptionOfferID) ? 'Update' : 'Add Subscription offer'}
                  </Button>
                  <Link to={'/subscription-offers'} className="btn btn-light w-100 mt-3">
                    Cancel
                  </Link>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {['active', 'deleted']?.map((elem, index) => (
                    <FormGroup className={`${'mb-3'}`} key={index}>
                      <Label className="mb-3">{titleCaseIfExists(elem)} </Label>
                      <div className="d-flex align-items-center">
                        {[true, false].map((option, i) => (
                          <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                            <input
                              type="radio"
                              id={`${elem}_${option}`}
                              name={elem}
                              className="form-check-input"
                              checked={formik.values[elem] === option}
                              onChange={() => formik.setFieldValue(`${elem}`, option)}
                            />
                            <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                  ))}
                </CardBody>
              </Card>

              <AssetImageUploader
                forType={MODULE_TYPE.METADATA}
                formik={formik}
                fieldName={'image'}
                label={'Select Image'}
              />
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

const CustomInput = ({ name, type, placeholder, col, className, rows, formik }) => {
  return (
    <FormGroup className={`mb-4 col-md-${col} ${className ? className : ''}`}>
      <Label htmlFor={name} className="form-label ">
        {placeholder}
      </Label>
      <Input
        name={name}
        type={type}
        className="form-control"
        placeholder={`Enter ${placeholder}`}
        onChange={formik?.handleChange}
        value={formik?.values[name]}
        rows={rows}
        invalid={formik?.touched[name] && formik?.errors[name] ? true : false}
      />
      {formik?.touched[name] && formik?.errors[name] && (
        <FormFeedback className="d-block">{formik.errors[name]}</FormFeedback>
      )}
    </FormGroup>
  );
};

export default AddUpdateSubscriptionOffer;
