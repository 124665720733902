import React, { useState } from 'react';
import Select from 'react-select';
import { WEEK_DAYS } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';

const TimingsTableRows = ({ rows, tableRowRemove, formik, addRowTable, weekDay }) => {
  return rows?.map((rowsData, index) => {
    const { day, startTime, endTime } = rowsData;
    return (
      <tr key={index}>
        <td style={{ width: '200px' }}>
          <Select
            id="day"
            options={Object.keys(WEEK_DAYS)?.map((name) => ({
              label: titleCaseIfExists(name),
              value: WEEK_DAYS[name],
            }))}
            value={formik.values.day}
            onChange={(value) => formik.setFieldValue('day', value)}
          />
        </td>
        <td style={{ width: '100px' }}></td>

        <td style={{ verticalAlign: 'middle' }}>Morning</td>
        <td>
          <Flatpickr
            className="form-control d-block"
            placeholder="Select time"
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: 'H:i',
              time_24hr: false,
            }}
            onChange={(e) => formik.setFieldValue('startTime', e)}
          />
        </td>
        <td style={{ verticalAlign: 'middle' }}>To</td>

        <td>
          <Flatpickr
            className="form-control d-block"
            placeholder="Select time"
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: 'H:i',
              time_24hr: false,
            }}
            onChange={(e) => formik.setFieldValue('endTime', e)}
          />
        </td>

        <td style={{ verticalAlign: 'middle' }}>
          <button onClick={() => tableRowRemove(index, weekDay)} className="btn btn-light rounded-circle">
            <i className="bx bx-minus" />
          </button>
        </td>
      </tr>
    );
  });
};

export default TimingsTableRows;
