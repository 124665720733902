import AuthService from 'common/auth.service';
import { userSelector } from 'common/globalComponents/selectors';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { API_BASE_URL } from 'utils/constants';
import { SMKLinks } from 'utils/links';
import { changeSidebarType, showRightSidebarAction, toggleLeftmenu } from '../../store/actions';
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import { RenderLogo } from './Sidebar';

const stateSelector = createStructuredSelector({
  user: userSelector,
});
const Header = (props) => {
  const [search, setsearch] = useState(false);
  const { t } = props;

  const { user } = useSelector(stateSelector);
  const appSource = user?.appSource || 'schoolmykids';

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === 'default') {
      props.changeSidebarType('condensed', isMobile);
    } else if (props.leftSideBarType === 'condensed') {
      props.changeSidebarType('default', isMobile);
    }
  }

  const auth = new AuthService();
  const source = auth.getAppSourceCookie();
  const isSMK = source === 'schoolmykids';
  const isTPZ = source === 'theparentz';

  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsVisible(currentScrollTop < lastScrollTop);

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);

  useEffect(() => {
    const headerElement = document.getElementById('page-topbar');
    if (headerElement) {
      if (isVisible) {
        headerElement.classList.remove('hidden-header');
        headerElement.classList.add('visible-header');
      } else {
        headerElement.classList.remove('visible-header');
        headerElement.classList.add('hidden-header');
      }
    }
  }, [isVisible]);

  return (
    <header id="page-topbar">
      <div
        className={
          API_BASE_URL === 'https://www.schoolmykids.com/api/v2' ? 'bg-warning navbar-header' : 'navbar-header'
        }>
        <div className="d-flex">
          <div className="navbar-brand-box d-lg-none bg-white">
            <Link to="/" className="logo logo-dark">
              <span className="logo-sm">
                <RenderLogo leftMenu={true} appSource={appSource} />
              </span>
              <span className="logo-lg">
                <RenderLogo leftMenu={false} appSource={appSource} />
              </span>
            </Link>

            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <RenderLogo leftMenu={true} appSource={appSource} />
              </span>
              <span className="logo-lg">
                <RenderLogo leftMenu={false} appSource={appSource} />
              </span>
            </Link>
          </div>

          <div className="d-flex align-items-center">
            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars" />
            </button>

            <UncontrolledDropdown className="dropdown-mega d-none d-lg-block ms-2">
              <DropdownToggle className="btn header-item" caret tag="button">
                Analytics
                <i className="mdi mdi-chevron-down ms-1" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-mega-menu-lg">
                <Row>
                  <Col md={12}>
                    <h5 className="font-size-14 mt-0">
                      <span>{t('Analytics')}</span>
                    </h5>
                    <ul className="list-unstyled megamenu-list mb-0">
                      {user.entityPermissions?.user?.reporting && (
                        <li>
                          <Link replace to={SMKLinks?.PAGEVIEW} className="waves-effect">
                            <i className="bx bx-calendar me-2" />
                            <span>{t('Page views')}</span>
                          </Link>
                        </li>
                      )}
                      {isSMK && user.entityPermissions?.worksheet?.reporting && (
                        <li>
                          <Link replace to={SMKLinks?.WORKSHEET_DOWNLOAD} className="waves-effect">
                            <i className="bx bx-calendar me-2" />
                            <span>{t('Worksheet download')}</span>
                          </Link>
                        </li>
                      )}
                      {isTPZ && user.entityPermissions?.tpz_app?.reporting && (
                        <>
                          <li>
                            <Link replace to={SMKLinks?.BABY_TRACKING_ACTIVITY} className="waves-effect">
                              <i className="bx bx-calendar me-2" />
                              <span>{t('Baby-tracking activity')}</span>
                            </Link>
                          </li>
                          <li>
                            <Link replace to={SMKLinks.TPZ_APP_DASHBOARD}>
                              <i className="bx bxs-dashboard me-2" />
                              <span>{t('App Dashboard')}</span>
                            </Link>
                          </li>
                        </>
                      )}

                      {user.entityPermissions?.article?.reporting && (
                        <li>
                          <Link replace to={SMKLinks.ARTICLE_DASHBOARD}>
                            <i className="bx bxs-dashboard me-2" />
                            <span>{t('Article Dashboard')}</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </Col>
                </Row>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown className="dropdown-mega d-none d-lg-block ms-2">
              <DropdownToggle className="btn header-item" caret tag="button">
                Tools
                <i className="mdi mdi-chevron-down ms-1" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-megamenu">
                <Row>
                  {user?.entityPermissions?.user?.admin && (
                    <>
                      <Col md={3}>
                        <h5 className="font-size-14 mt-0">
                          <span>{t('Cache')}</span>
                        </h5>
                        <ul className="list-unstyled megamenu-list mb-0">
                          <li>
                            <Link replace to={SMKLinks?.API_CACHE_LIST}>
                              {t('API Cache')}
                            </Link>
                          </li>
                          <li>
                            <Link replace to={SMKLinks?.CDN_CACHE_LIST}>
                              {t('CDN Cache')}
                            </Link>
                          </li>
                          <li>
                            <Link replace to={SMKLinks?.SEO_CHECKER}>
                              {t('SEO Checker')}
                            </Link>
                          </li>
                        </ul>
                      </Col>
                      <Col md={3}>
                        <h5 className="font-size-14 mt-0">
                          <span>{t('Others')}</span>
                        </h5>
                        <ul className="list-unstyled megamenu-list mb-0">
                          <li>
                            <Link replace to={SMKLinks?.TAGS_LIST}>
                              {t('Tags List')}
                            </Link>
                          </li>
                          <li>
                            <Link replace to={SMKLinks?.FEATURE_BANNER_LIST}>
                              {t('Feature Banner List')}
                            </Link>
                          </li>
                          {isSMK && user?.entityPermissions?.masterdata?.admin && (
                            <li>
                              <Link replace to={SMKLinks?.LOCATION_LISTING}>
                                {t('Locations List')}
                              </Link>
                            </li>
                          )}
                        </ul>
                      </Col>
                      <Col md={3}>
                        <h5 className="font-size-14 mt-0">
                          <span>{t('Sitemaps')}</span>
                        </h5>
                        <ul className="list-unstyled megamenu-list mb-0">
                          {user?.entityPermissions?.masterdata?.admin && (
                            <>
                              <li>
                                <Link
                                  replace
                                  to={
                                    isSMK ? SMKLinks?.GENERATE_SMK_SITEMAP : isTPZ ? SMKLinks?.GENERATE_TPZ_SITEMAP : ''
                                  }>
                                  {t('Sitemap List')}
                                </Link>
                              </li>
                              <li>
                                <Link
                                  replace
                                  to={
                                    isSMK ? SMKLinks?.MISMATCH_SMK_SITEMAP : isTPZ ? SMKLinks?.MISMATCH_TPZ_SITEMAP : ''
                                  }>
                                  {t('Mismatch Sitemap List')}
                                </Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </Col>
                    </>
                  )}
                </Row>
              </DropdownMenu>
            </UncontrolledDropdown>
            {user?.entityPermissions?.masterdata?.read && (
              <UncontrolledDropdown className="dropdown-mega d-none d-lg-block ms-2">
                <DropdownToggle className="btn header-item" caret tag="button">
                  {t('Media')}
                  <i className="mdi mdi-chevron-down ms-1" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-mega-menu-lg">
                  <Row>
                    <Col md={12}>
                      <ul className="list-unstyled megamenu-list mb-0">
                        <li>
                          <Link replace to={SMKLinks.MEDIA_LISTING}>
                            {t('Media List')}
                          </Link>
                        </li>
                        <li>
                          <Link replace to={SMKLinks.ADD_MEDIA}>
                            {t('Add Media')}
                          </Link>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
            {user?.entityPermissions?.send_notification?.read && (
              <UncontrolledDropdown className="dropdown-mega d-none d-lg-block ms-2">
                <DropdownToggle className="btn header-item" caret tag="button">
                  <Link replace to={SMKLinks?.SEND_NOTIFICATINS} className="text-black">
                    <i className="bx bx-bell me-2" />
                    <span className="">{t('Send Notifications')}</span>
                  </Link>
                </DropdownToggle>
              </UncontrolledDropdown>
            )}
          </div>
        </div>
        <div className="d-flex">
          <ProfileMenu />
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
