import React from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import PropTypes from 'prop-types';

const TextEditor = (props) => {
  const { simple, initialValue, onChange, width, height, placeholder } = props;

  const basicFeatures = [
    // ['fontSize'],
    // ['formatBlock'],
    ['bold', 'underline', 'italic', 'strike'],
    ['formatBlock'],
    // ['paragraphStyle', 'blockquote'],
    // ['fontColor', 'hiliteColor'],
    // '/', // Line break
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list'],
    ['table', 'link', 'image'],
    ['fullScreen', 'preview', 'codeView'],
  ];

  const allFeatures = [
    ['font', 'fontSize', 'formatBlock'],
    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
    ['undo', 'redo'],
    ['paragraphStyle', 'blockquote'],
    ['fontColor', 'hiliteColor', 'textStyle'],
    ['removeFormat'],
    '/', // Line break
    ['outdent', 'indent'],
    ['align', 'horizontalRule', 'list', 'lineHeight'],
    [
      'table',
      'link',
      'image',
      'video',
      'audio',
      // 'math', // You must add the 'katex' library at options to use the 'math' plugin.
    ],
    // ['imageGallery'], // You must add the "imageGalleryUrl".
    ['fullScreen', 'showBlocks', 'codeView'],
    [
      'preview',
      //  'print' // doesn't seem to work
    ],
    [
      // 'save', // doesn't seem to work
      'template',
    ],
  ];

  const handleChange = (content) => {
    if (onChange) {
      onChange(content);
    }
  };

  return (
    <SunEditor
      width={width}
      height={height}
      placeholder={placeholder}
      // =====Boolean====
      // autoFocus
      // enable
      // disable
      // hide
      // show
      // showToolbar
      // enableToolbar
      // ================
      setContents={initialValue}
      defaultValue={initialValue}
      onChange={handleChange}
      // onScroll={console.log}
      // onClick={handleClick}
      // onMouseDown={handleMouseDown}
      setOptions={{
        // plugins,
        buttonList: simple ? basicFeatures : allFeatures,
        showPathLabel: false,
      }}
    />
  );
};

TextEditor.propTypes = {
  simple: PropTypes.bool,
  initialValue: PropTypes.string,
  onChange: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  placeholder: PropTypes.string,
};

TextEditor.defaultProps = {
  simple: true,
  width: '100%',
  height: '100%',
  placeholder: 'Please type here...',
};

export default TextEditor;
