import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import Select from 'react-select';

// Import Image
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import * as moment from 'moment';
import { ACTIVE_AND_DELETED_ARRAY, SORT_VALUES } from 'utils/constants';
import { checkIfValueExist } from 'utils/helper';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';

const SuggestedBabynamesList = (props) => {
  const router = useRouter();
  const history = useHistory();

  const [babyNames, setBabyNames] = useState([]);
  const [babyNamesPagination, setBabyNamesPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    startDate: moment().subtract(5, 'years'),
    endDate: moment(),
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getSuggestedBabyNameRequest, { loading: getSuggestedBabyNameLoading }] = useRequest({
    url: `/baby-name/admin/suggestion`,
    method: 'POST',
    onSuccess: (data) => {
      // console.log(data);
      setBabyNamesPagination(data?.pagination);
      setBabyNames(data?.results);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getSuggestedBabyNameRequest({
      body: {
        page,
        ...filters,
        ...SORT_VALUES,
        size: 50,
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getSuggestedBabyNameRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        ...SORT_VALUES,
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        {getSuggestedBabyNameLoading && <Loader isActive />}
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="BabyNames" breadcrumbItem="Suggested Names List" />
          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Published</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {babyNamesPagination?.totalCount} Suggested Baby Names Found
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '100px' }}>
                          #ID
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Meaning</th>
                        <th scope="col">Gender</th>
                        {/* <th scope="col">Added Date</th>
                        <th scope="col">Processed</th> */}
                        {/* <th scope="col">Active</th> */}
                        <th scope="col">View Count</th>
                        <th scope="col">Favourite Count</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(babyNames) ? (
                        map(babyNames, (data, index) => (
                          <tr key={index}>
                            <td>{data.id}</td>
                            <td>{data?.name}</td>
                            <td>{data?.meaning}</td>
                            <td>{data?.gender?.name}</td>
                            <td>{data?.viewCount}</td>
                            <td>{data?.favouriteCount}</td>

                            {/* <td>{printDateTime(data.createdDate)}</td>
                            <td>
                              <Badge className={data.isProcessed ? 'bg-success' : 'bg-danger'}>
                                {data.isProcessed ? 'Yes' : 'No'}
                              </Badge>
                            </td> */}
                            {/* <td>
                              <Badge className={data.active ? 'bg-success' : 'bg-danger'}>{data.active ? 'Yes' : 'No'}</Badge>
                            </td> */}
                            <td>
                              <Link
                                to={{
                                  pathname: `/babynames/add`,
                                  state: {
                                    data,
                                  },
                                }}>
                                Add to Baby List
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={babyNamesPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default SuggestedBabynamesList;
