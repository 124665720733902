import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import 'react-diff-view/style/index.css';

const cheerio = require('cheerio');
const jsonFormat = require('json-format');
import pretty from 'pretty';

import { Diff, Hunk, parseDiff } from 'react-diff-view';
import { diffLines, formatLines } from '../../../utils/unidiff/unidiff';

import useRequest from '../../../hooks/useRequest';
import Loader from '../../../components/Loader';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useRouter } from 'hooks/useRouter';
import isEmpty from 'lodash/isEmpty';

const SEOChecker = () => {
  toast.configure();

  const router = useRouter();

  const [diff, setDiff] = useState();

  const isJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return typeof parseObj === 'object' || typeof parseObj === 'Array' ? true : false;
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const EMPTY_HUNKS = [];

  let meta = 'meta,link';
  let removeTags = ['script', 'ins', 'head', 'nav', 'footer', 'aside', 'svg'];

  const config1_value = JSON.stringify({
    meta,
    removeTags,
    replace: [
      { old: 'https://www.schoolmykids.com', new: 'localhost' },
      { old: 'www.schoolmykids.com', new: 'localhost' },
      { old: 'SchoolMyKids', new: 'The ParentZ' },
    ],
  });

  const config2_value = JSON.stringify({
    meta,
    removeTags,
    replace: [
      { old: 'https://www.theparentz.com', new: 'localhost' },
      { old: 'www.theparentz.com', new: 'localhost' },
      { old: 'http://localhost:8082', new: 'localhost' },
      { old: 'TheParentZ', new: 'The ParentZ' },
    ],
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: {
      url1: !isEmpty(router?.query?.source)
        ? router?.query?.source
        : 'https://www.schoolmykids.com/parenting/baby-names/darsh',
      url2: !isEmpty(router?.query?.target) ? router?.query?.target : 'http://localhost:8082/baby-names/darsh',
      config1: config1_value,
      config2: config2_value,
    },
    onSubmit: (values) => {
      if (isJSON(values.config1) && isJSON(values.config2)) {
        getHTMLOfURL({
          body: {
            url1: values?.url1,
            url2: values?.url2,
          },
        });
      } else {
        toast.error('Please enter valid JSON...');
      }
    },
    validationSchema: Yup.object().shape({
      url1: Yup.string().required('Please provide Source URL'),
      url2: Yup.string().required('Please provide Target URL'),
      config1: Yup.string().required('Please enter config JSON'),
      config2: Yup.string().required('Please provide config JSON'),
    }),
  });

  const [getHTMLOfURL, { loading: getHTMLOfURLLoading }] = useRequest({
    url: `/admin-tools/seoChecker`,
    method: 'POST',
    onSuccess: (data) => {
      const propsData = showHTMLResponse({
        url1: data[0],
        url2: data[1],
      });
      const { diff } = propsData;
      setDiff(diff);
    },
  });

  const showHTMLResponse = ({ url1, url2 }) => {
    let editedDom1 = '';
    let editedDom2 = '';
    let meta1 = [];
    let meta2 = [];

    let configParams1 = {};
    let configParams2 = {};

    if (url1 && url2) {
      configParams1 = isJSON(formik?.values?.config1) && JSON.parse(formik?.values?.config1);
      configParams2 = isJSON(formik?.values?.config2) && JSON.parse(formik?.values?.config2);

      let html1 = url1?.response?.content;
      let html2 = url2?.response?.content;

      const dom1 = cheerio.load(html1);
      const dom2 = cheerio.load(html2);

      dom1(configParams1.meta).map((i, d) => {
        meta1.push({
          name: dom1(d).attr('name') || '',
          property: dom1(d).attr('property') || '',
          content: dom1(d).attr('content') || '',
          rel: dom2(d).attr('rel') || '',
          href: dom2(d).attr('href') || '',
        });
      });

      dom2(configParams2.meta).map((i, d) => {
        meta2.push({
          name: dom2(d).attr('name') || '',
          property: dom2(d).attr('property') || '',
          content: dom2(d).attr('content') || '',
          rel: dom2(d).attr('rel') || '',
          href: dom2(d).attr('href') || '',
        });
      });
      meta1 = meta1.sort((a, b) => (a.rel || a.property || a.name) - (b.rel || b.property || b.name));
      meta2 = meta2.sort((a, b) => (a.rel || a.property || a.name) - (b.rel || b.property || b.name));

      // remove tags
      for (const tag of configParams1.removeTags) {
        dom1(tag).map((index, item) => {
          dom1(item).remove();
        });
      }
      for (const tag of configParams2.removeTags) {
        dom2(tag).map((index, item) => {
          dom2(item).remove();
        });
      }

      editedDom1 = jsonFormat(meta1) + pretty(dom1.html()?.replace(/</g, '\r\n<'), { ocd: true });
      editedDom2 = jsonFormat(meta2) + pretty(dom2.html()?.replace(/</g, '\r\n<'), { ocd: true });

      for (const replace of configParams1.replace) {
        editedDom1 = editedDom1?.replace(new RegExp(replace.old, 'g'), replace.new);
      }

      for (const replace of configParams2.replace) {
        editedDom2 = editedDom2?.replace(new RegExp(replace.old, 'g'), replace.new);
      }
    }

    const diffText = formatLines(diffLines(editedDom1, editedDom2), {
      ignoreWhitespace: true,
      newlineIsToken: true,
      context: 3,
    });
    const [diff] = parseDiff(diffText, { nearbySequences: 'zip' });

    return {
      diff,
    };
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getHTMLOfURLLoading} />

        <Container fluid>
          <Breadcrumbs title="Others" breadcrumbItem="SEO Checker" />
          <Card>
            <CardBody>
              <Form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="url1" className="form-label  ">
                        Source Url
                      </Label>
                      <Input
                        id="url1"
                        name="url1"
                        type="text"
                        className="form-control"
                        placeholder="Enter URL..."
                        invalid={!!(formik.touched.url1 && formik.errors.url1)}
                        {...formik.getFieldProps('url1')}
                      />
                      <FormFeedback>{formik.errors.url1}</FormFeedback>
                    </FormGroup>

                    <FormGroup className="mb-4">
                      <Label htmlFor="config1" className="form-label  ">
                        Config
                      </Label>
                      <Input
                        id="config1"
                        name="config1"
                        type="textarea"
                        rows={7}
                        className="form-control"
                        placeholder="Enter JSON..."
                        invalid={!!(formik.touched.config1 && formik.errors.config1)}
                        {...formik.getFieldProps('config1')}
                      />
                      <FormFeedback>{formik.errors.config1} </FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label htmlFor="url2" className="form-label  ">
                        Target Url
                      </Label>
                      <Input
                        id="url2"
                        name="url2"
                        type="text"
                        className="form-control"
                        placeholder="Enter URL..."
                        invalid={!!(formik.touched.url2 && formik.errors.url2)}
                        {...formik.getFieldProps('url2')}
                      />
                      <FormFeedback>{formik.errors.url2}</FormFeedback>
                    </FormGroup>

                    <FormGroup className="mb-4">
                      <Label htmlFor="config2" className="form-label  ">
                        Config
                      </Label>
                      <Input
                        id="config2"
                        name="config2"
                        type="textarea"
                        rows={7}
                        className="form-control"
                        placeholder="Enter JSON..."
                        invalid={!!(formik.touched.config2 && formik.errors.config2)}
                        {...formik.getFieldProps('config2')}
                      />
                      <FormFeedback>{formik.errors.config2} </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button type="submit" color="primary" className="mt-5 btn-lg py-2" style={{ width: 200 }}>
                    Compare
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>

        <Card className="mt-5">
          <h4 className="mx-4 mt-4">Results:</h4>
          <CardBody>
            <Diff viewType="split" diffType="" hunks={diff?.hunks || EMPTY_HUNKS}>
              {(hunks) => hunks.map((hunk) => <Hunk key={hunk?.content} hunk={hunk} />)}
            </Diff>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default SEOChecker;
