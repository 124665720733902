import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Label,
  Input,
  Container,
  Form,
  FormFeedback,
  CardTitle,
} from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import isEmpty from 'lodash/isEmpty';
import Select from 'react-select';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import { countriesSelector, regionsSelector, citiesSelector, subAreasSelector } from '../selectors';
import { getCities, getCountries, getRegions, getSubAreas } from '../actions';
import { BANNER_TYPE, FEATURED_TYPE } from 'utils/constants';
import moment from 'moment';
import CollegeAutoComplete from 'components/CollegeAutoComplete';
import { titleCaseIfExists } from 'utils/helper';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
});

const UpdateCollegeFeatured = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id: collegeFeaturedID } = useParams();

  toast.configure();

  const [collegeFeaturedDetails, setcollegeFeaturedDetails] = useState();
  const { countries, regions, cities, subAreas } = useSelector(stateSelector);

  const selectTypeOptions = [];

  for (const [key, value] of Object.entries(FEATURED_TYPE)) {
    selectTypeOptions?.push({
      label: titleCaseIfExists(key),
      value: value,
    });
  }

  const [getCollegeFeaturedRequest, { loading: getCollegeFeaturedLoading }] = useRequest({
    url: `/college/admin/featured/${collegeFeaturedID}`,
    method: 'GET',
    onSuccess: (data) => {
      setcollegeFeaturedDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addCollegeFeaturedRequest, { loading: addCollegeFeaturedLoading }] = useRequest({
    url: `/college/admin/featured`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.COLLEGE_FEATURED;
      history.replace(url);
      toast.success('Success! College Featured has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateCollegeFeaturedRequest, { loading: updateCollegeFeaturedLoading }] = useRequest({
    url: `/college/admin/featured/${collegeFeaturedID}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.COLLEGE_FEATURED;
      history.replace(url);
      toast.success('Success!College Featured has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const isJSON = (val) => {
    try {
      return typeof val === 'string' && JSON.parse(val);
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const convertJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return parseObj;
    } catch (err) {
      toast.error(err);
    }
  };

  const editInitialValues = () => {
    const featuredtypeObj = selectTypeOptions?.filter((e) => e?.value === collegeFeaturedDetails?.featuredType);
    const countryObj = countries?.filter((e) => e?.value === collegeFeaturedDetails?.country?.id);
    const regionObj = regions?.filter((e) => e?.value === collegeFeaturedDetails?.region?.id);
    const cityObj = cities?.filter((e) => e?.value === collegeFeaturedDetails?.city?.id);

    const metaDataJSON = !isEmpty(collegeFeaturedDetails?.metaData)
      ? JSON.stringify(collegeFeaturedDetails?.metaData, null, 2)
      : '';

    return {
      id: collegeFeaturedDetails?.id,
      active: collegeFeaturedDetails?.active,
      deleted: collegeFeaturedDetails?.deleted,
      position: collegeFeaturedDetails?.position,
      expiryDate: moment(collegeFeaturedDetails?.expiryDate),
      college: collegeFeaturedDetails?.college,

      metaData: metaDataJSON,

      featuredType: !isEmpty(featuredtypeObj)
        ? {
            label: featuredtypeObj[0]?.label,
            value: featuredtypeObj[0]?.value,
          }
        : undefined,
      country: !isEmpty(countryObj)
        ? {
            label: countryObj[0]?.label,
            value: countryObj[0]?.value,
          }
        : undefined,
      region: !isEmpty(regionObj)
        ? {
            label: regionObj[0]?.label,
            value: regionObj[0]?.value,
          }
        : undefined,
      city: !isEmpty(cityObj)
        ? {
            label: cityObj[0]?.label,
            value: cityObj[0]?.value,
          }
        : undefined,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    position: '',
    featuredType: '',
    college: '',
    expiryDate: null,

    country: '',
    region: '',
    city: '',
    metaData: '',
    // subArea: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(collegeFeaturedID) ? getInitialValues() : editInitialValues(),
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        type: values?.type?.value,
        expiryDate: moment(values?.expiryDate).toISOString(),
        featuredType: values?.featuredType?.value,

        metaData: convertJSON(values?.metaData),

        position: values?.position,
        college: !isEmpty(values?.college)
          ? {
              id: values?.college?.id,
            }
          : undefined,

        country: values?.country?.id
          ? {
              id: values?.country?.id,
            }
          : undefined,
        region: values?.region?.id
          ? {
              id: values?.region?.id,
            }
          : undefined,
        city: values?.city?.id
          ? {
              id: values?.city?.id,
            }
          : undefined,
        subArea: values?.subArea?.id
          ? {
              id: values?.subArea?.id,
            }
          : undefined,
      };

      isEmpty(collegeFeaturedID)
        ? addCollegeFeaturedRequest({
            body: {
              ...data,
            },
          })
        : updateCollegeFeaturedRequest({
            body: {
              ...data,
            },
          });
    },
    validationSchema: Yup.object().shape({
      position: Yup.string().required('Please provide position'),
      featuredType: Yup.object()
        .required('Please provide Featured type')
        .nullable(true),
      metaData: Yup.string().test(
        'metaDataTest',
        'Please enter valid JSON in meta data',
        (val) => isEmpty(val) || isJSON(val)
      ),
      expiryDate: Yup.string()
        .test('expiryDateTest', 'Please provide a expiry date', (val) => !isEmpty(val))
        .nullable(true),
      college: Yup.object()
        .test('collegeTest', 'Please select a college', (val) => !isEmpty(val))
        .nullable(true),
    }),
  });

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));
  }, []);

  useEffect(() => {
    if (!isEmpty(collegeFeaturedID)) {
      getCollegeFeaturedRequest();
      dispatch(
        getCities.trigger({
          level: 3,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      dispatch(
        getRegions.trigger({
          level: 1,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
    }
  }, [collegeFeaturedID]);

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      formik.setFieldValue('country', country);
      formik.setFieldValue('region', {});
      formik.setFieldValue('city', {});
      // formik.setFieldValue('subArea', {});
    } else {
      formik.setFieldValue('country', '');
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );

      formik.setFieldValue('region', region);
      formik.setFieldValue('city', {});
      // formik.setFieldValue('subArea', {});
    } else {
      formik.setFieldValue('region', '');
    }
  };
  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      formik.setFieldValue('city', city);
    } else {
      formik.setFieldValue('city', '');
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={addCollegeFeaturedLoading || getCollegeFeaturedLoading || updateCollegeFeaturedLoading} />
        <Container fluid>
          <Breadcrumbs
            title="Location"
            breadcrumbItem={`${isEmpty(collegeFeaturedID) ? 'Add' : 'Edit'} College Featured`}
          />

          <Card>
            <CardBody>
              <Form onSubmit={formik.handleSubmit}>
                <Row className="align-items-center">
                  {collegeFeaturedID && (
                    <Col md={collegeFeaturedID ? 3 : 6}>
                      <FormGroup className="mb-4">
                        <Label className="form-label  ">College Featured ID</Label>
                        <Input
                          id="id"
                          style={{ background: 'whitesmoke' }}
                          disabled
                          className="form-control"
                          {...formik.getFieldProps('id')}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  <Col md={collegeFeaturedID ? 3 : 6}>
                    <FormGroup className="mb-4">
                      <Label for="position" className="form-label  ">
                        Position
                      </Label>
                      <Input
                        id="position"
                        name="position"
                        type="number"
                        className="form-control"
                        placeholder="Position..."
                        invalid={!!(formik.touched.position && formik.errors.position)}
                        {...formik.getFieldProps('position')}
                      />
                      <FormFeedback>{formik.errors.position}</FormFeedback>
                    </FormGroup>
                  </Col>

                  <Col md={2}>
                    <CardTitle className="mb-3">Active </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                        <input
                          type="radio"
                          id={`active_${option}`}
                          name="active"
                          className="form-check-input"
                          checked={formik.values.active === option}
                          onChange={() => formik.setFieldValue('active', option)}
                        />
                        <label className="form-check-label" htmlFor={`active_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </Col>

                  <Col md={2}>
                    <CardTitle className="mb-3">Delete </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                        <input
                          type="radio"
                          id={`deleted_${option}`}
                          name="deleted"
                          className="form-check-input"
                          checked={formik.values.deleted === option}
                          onChange={() => formik.setFieldValue('deleted', option)}
                        />
                        <label className="form-check-label" htmlFor={`deleted_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </Col>

                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label for="featuredType" className="form-label  ">
                        Featured Type
                      </Label>
                      <Select
                        id="featuredType"
                        isClearable
                        options={selectTypeOptions}
                        value={formik.values.featuredType}
                        onChange={(featuredType) => formik.setFieldValue('featuredType', featuredType)}
                      />
                      {formik.touched.featuredType && (
                        <div className="invalid-feedback d-block">{formik.errors.featuredType}</div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md={6}>
                    <FormGroup className="mb-4">
                      <Label for="expiryDate" className="form-label  ">
                        Expiry Date
                      </Label>
                      <Input
                        type="date"
                        id="expiryDate"
                        placeholder="Expiry Date"
                        value={formik?.values?.expiryDate?.format('YYYY-MM-DD')}
                        onChange={(e) => formik.setFieldValue('expiryDate', moment(e.target.value))}
                      />
                      {formik.touched.expiryDate && (
                        <div className="invalid-feedback d-block">{formik.errors.expiryDate}</div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md={12}>
                    <FormGroup className="mb-4">
                      <Label for="college" className="form-label  ">
                        College
                      </Label>
                      <CollegeAutoComplete
                        isNotClearable={true}
                        selectedCollege={formik.values.college}
                        onSelectCollege={(college) => {
                          formik.setFieldValue('college', college);
                        }}
                      />
                      {formik.touched.college && (
                        <div className="invalid-feedback d-block">{formik.errors.college}</div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <FormGroup className="mb-4">
                      <Label for="college_country" className="form-label">
                        Country
                      </Label>
                      <Select
                        isClearable
                        id="college_country"
                        options={countries}
                        value={formik?.values?.country}
                        onChange={onCountrySelect}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <FormGroup className="mb-4">
                      <Label for="college_state" className="form-label  ">
                        Region/State
                      </Label>

                      <Select
                        isClearable
                        id="college_state"
                        placeholder="Select..."
                        value={formik?.values?.region}
                        onChange={onRegionSelect}
                        options={regions}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={4}>
                    <FormGroup className="mb-4">
                      <Label for="college_city" className="form-label  ">
                        City
                      </Label>
                      <Select
                        isClearable
                        id="college_city"
                        placeholder="Select..."
                        value={formik?.values?.city}
                        onChange={onCitySelect}
                        options={cities}
                      />
                    </FormGroup>
                  </Col>
                  {/* <Col md={3}>
                                        <FormGroup className="mb-4">
                                            <Label for="college_area" className="form-label  ">
                                                SubArea
                                            </Label>
                                            <Select
                                                id="college_area"
                                                placeholder="Select..."
                                                value={formik.values.subArea}
                                                onChange={onSubAreaSelect}
                                                options={subAreas}
                                            />
                                            {formik.touched.subArea && (
                                                <div className="invalid-feedback d-block">{formik.errors.subArea}</div>
                                            )}
                                        </FormGroup>
                                    </Col>
                                    */}

                  <Col md={12}>
                    <FormGroup className="mb-4">
                      <Label for="metaData" className="form-label  ">
                        Meta Data
                      </Label>
                      <Input
                        id="metaData"
                        name="metaData"
                        style={{ height: '150px' }}
                        type="textarea"
                        cols={50}
                        rows={5}
                        className="form-control"
                        placeholder="Enter Meta Data JSON..."
                        invalid={!!(formik.touched.metaData && formik.errors.metaData)}
                        {...formik.getFieldProps('metaData')}
                      />
                      <FormFeedback className="d-block">{formik.errors.metaData} </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="justify-content-start my-4 pb-2">
                  <Col lg="4" className="ps-0">
                    <Button type="submit" color="primary" className="mx-3">
                      {collegeFeaturedID ? 'Save' : 'Add'} College Featured
                    </Button>

                    <Button
                      className="ms-2"
                      color="danger"
                      onClick={() => {
                        history.replace(SMKLinks.COLLEGE_FEATURED);
                      }}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default UpdateCollegeFeatured;
