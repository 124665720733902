import TableError from 'components/TableError';
import { showImageError } from 'containers/blog/helper';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
} from 'reactstrap';
import { ACTIVE_AND_DELETED_ARRAY, MODULE_TYPE, SORT_VALUES } from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { checkIfValueExist, showImage, titleCaseIfExists } from '../../../utils/helper';
import Select from 'react-select';
import AuthService from 'common/auth.service';

const MediaList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const auth = new AuthService();

  const isTpzRoute = auth.getAppSourceCookie() !== 'schoolmykids';
  const [activeTab, setActiveTab] = useState('article');

  const forTypeEnumList = {
    ARTICLE: 3,
    METADATA: 26,
    ...(isTpzRoute
      ? {
          BABY_NAMES: 9,
          MEMORIES: 19,
        }
      : {
          SCHOOL: 7,
          COLLEGE: 25,
          WORKSHEET: 8,
        }),
  };

  const moduleForTypeList = Object.keys(forTypeEnumList)?.map((e) => ({
    label: titleCaseIfExists(e),
    value: forTypeEnumList[e],
  }));

  const [mediaList, setMediaList] = useState([]);
  const [mediaPagination, setMediaPagination] = useState({});

  const defaultFilters = {
    active: { value: true, label: 'Yes' },
    deleted: { value: false, label: 'No' },
    // startDate: moment().subtract(10, 'years'),
    // endDate: moment(),
    name: '',
    forType: moduleForTypeList[0],
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [getMediaListRequest, { loading: getMediaListLoading }] = useRequest({
    method: 'POST',
    url: '/asset/admin/search',
    onSuccess: (data) => {
      setMediaList(data?.results);
      setMediaPagination(data?.pagination);
    },
  });

  const [deleteAssetRequest, { loading: deleteAssetLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: () => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Asset has been deleted`);
    },
  });

  const setPageInUrl = (page) => {
    const params = new URLSearchParams({ page });
    history.push({
      pathname: router.pathname,
      search: `?${params.toString()}`,
    });
  };

  const handleGetMediaList = (data, page) => {
    setPageInUrl(page);
    getMediaListRequest({
      body: {
        page,
        size: 20,
        forType: data?.forType?.value,
        active: checkIfValueExist(data?.active?.value),
        deleted: checkIfValueExist(data?.deleted?.value),
        name: checkIfValueExist(data?.name),
        // startDate: data?.startDate,
        // endDate: data?.endDate,
        ...SORT_VALUES,
      },
    });
  };

  const handleChangePage = (page) => {
    handleGetMediaList(filters, page);
  };
  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setFilters({ ...defaultFilters });
    handleGetMediaList(filters, 1);
  };

  useEffect(() => {
    if (!router?.query?.type && !router.query.page) {
      handleChangePage(1);
    }
  }, []);

  useEffect(() => {
    const { type, page } = router.query;
    if (type && page) {
      const current_type = moduleForTypeList?.find((e) => e?.label?.toLowerCase() === type?.toLowerCase());
      setActiveTab(current_type?.label?.toLowerCase());
      let updatedFilters = { ...filters, forType: current_type };
      setFilters(updatedFilters);
      handleGetMediaList(updatedFilters, router.query.page);

      const queryParams = new URLSearchParams();
      if (page) queryParams.set('page', page);
      if (current_type) queryParams.set('type', current_type?.label?.toLowerCase());

      const newUrl = `${router.pathname}?${queryParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
    }
  }, [router.query.type, router.query.page]);

  useEffect(() => {
    const currentSearchParams = new URLSearchParams(window.location.search);
    if (activeTab && currentSearchParams.get('type') !== activeTab) {
      currentSearchParams.set('type', activeTab);
      const newUrl = `${window.location.pathname}?${currentSearchParams.toString()}`;
      window.history.replaceState(null, '', newUrl);
    }
  }, [activeTab]);

  const handleDelete = (data) => {
    deleteAssetRequest({
      url: `/asset/admin/${data?.id}`,
    });
  };

  const handleSetCurrentTab = (data) => {
    setActiveTab(String(data?.label?.toLowerCase()));
    const currentFilters = { ...filters, forType: data };
    setFilters(currentFilters);
    handleGetMediaList(currentFilters, 1);
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getMediaListLoading || deleteAssetLoading} />

        <Container fluid>
          <Breadcrumbs title="Media List" breadcrumbItem="Media List" />

          <div>
            <Nav tabs>
              {moduleForTypeList?.map((elem, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      className={activeTab === elem?.label?.toLowerCase() ? 'active' : ''}
                      onClick={() => {
                        handleSetCurrentTab(elem);
                      }}>
                      {elem?.label}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>
                <Card>
                  <CardBody>
                    <div className="row mt-2">
                      <div className="col-lg-2 mb-4">
                        <label className="form-label">Active</label>
                        <Select
                          options={ACTIVE_AND_DELETED_ARRAY}
                          value={filters.active}
                          onChange={(value) => setFilters({ ...filters, active: value })}
                        />
                      </div>
                      <div className="col-lg-2 mb-4">
                        <label className="form-label">Deleted</label>
                        <Select
                          options={ACTIVE_AND_DELETED_ARRAY}
                          value={filters.deleted}
                          onChange={(value) => setFilters({ ...filters, deleted: value })}
                        />
                      </div>

                      {/* <div className="col-lg-3 mb-4">
                        <label htmlFor="startDate" className="form-label">
                          Created Date - From
                        </label>
                        <Input
                          type="date"
                          id="startDate"
                          placeholder="From Date"
                          value={filters.startDate.format('YYYY-MM-DD')}
                          max={moment().format('YYYY-MM-DD')}
                          onChange={(e) => setFilters({ ...filters, startDate: moment(e.target.value) })}
                        />
                      </div>
                      <div className="col-lg-3 mb-4">
                        <label htmlFor="endDate" className="form-label">
                          Created Date - To
                        </label>
                        <Input
                          type="date"
                          id="endDate"
                          placeholder="To Date"
                          value={filters.endDate.format('YYYY-MM-DD')}
                          max={moment().format('YYYY-MM-DD')}
                          onChange={(e) => setFilters({ ...filters, endDate: moment(e.target.value) })}
                        />
                      </div> */}

                      <div className="col-lg-4 mb-4">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Name..."
                          value={filters.name}
                          onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                        />
                      </div>
                    </div>
                    <div className="mt-4 pt-2">
                      <a className="btn btn-primary me-4" onClick={applyFilters}>
                        Apply Filters
                      </a>
                      <a className="" onClick={clearFilters}>
                        Clear
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent>
          </div>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{mediaPagination?.totalCount} Media Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_MEDIA + `?type=${activeTab}`}>
                  <i className="mdi mdi-plus me-1" />
                  Add Media
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '60px' }}>
                          Id
                        </th>
                        <th scope="col">Image</th>
                        <th scope="col">Name</th>
                        <th scope="col">Type</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(mediaList) ? (
                        mediaList?.map((elem, index) => (
                          <tr key={index}>
                            <td>{elem?.id}</td>
                            <td>
                              <a href={showImage(elem?.original)} target="_blank">
                                <img
                                  src={showImage(elem?.original)}
                                  width={50}
                                  height={50}
                                  onError={(e) => {
                                    showImageError(e);
                                  }}
                                />
                              </a>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">{elem?.name}</div>
                              <div className="mt-2">
                                <a target="_blank" href={showImage(elem?.original)}>
                                  {showImage(elem?.original)}
                                </a>
                              </div>
                            </td>
                            <td>{elem?.type}</td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleDelete(elem)}>
                                    <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {!isEmpty(mediaList) && <Paginate pageInfo={mediaPagination} onChangePage={handleChangePage} />}
        </Container>
      </div>
    </>
  );
};

export default MediaList;
