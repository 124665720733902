import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { API_BASE_URL } from 'utils/constants';

const CategoriesSearch = ({ onChange, className, filters, initialValues = [] }) => {
  const [categories, setCategories] = useState(initialValues || []);

  useEffect(() => {
    if (!isEmpty(initialValues)) {
      setCategories(initialValues);
    }
  }, [initialValues]);

  const updateStateAndNotify = (updatedData) => {
    setCategories(updatedData);
    onChange(updatedData);
  };

  const handleSelectChange = (res) => {
    if (!categories?.some((item) => item?.id === res?.id)) {
      const updatedData = [...categories, { name: res.name, id: res.id, slug: res?.slug }];
      updateStateAndNotify(updatedData);
    }
  };

  const handleRemoveItem = (id) => {
    const updatedData = categories.filter((item) => item.id !== id);
    updateStateAndNotify(updatedData);
  };

  return (
    <Card className={className || ''}>
      <CardBody>
        <div className="mb-3">
          <CardTitle className="mb-3">Categories</CardTitle>
          <SmkAsyncSelect
            acceptedKey={'name'}
            acceptedValue={'id'}
            placeholder="Type name.."
            onChange={handleSelectChange}
            isTaxonomyCategory={true}
            fetchUrl={`${API_BASE_URL}/common/admin/taxonomy/search`}
            filters={{
              autoComplete: true,
              active: true,
              page: 1,
              size: 500,
              sortBy: 'id',
              sortOrder: 0,
              ...filters,
            }}
            searchKeyName="name"
          />
        </div>

        {!isEmpty(categories) &&
          categories.map((item, index) => (
            <div
              key={item.id}
              className="bg-white rounded-2 p-3 d-flex justify-content-between align-items-center mt-3">
              <h6 className="mb-0">
                {index + 1}. {item.name}
                {!isEmpty(item.slug) && ` - ( ${item.slug} )`}
              </h6>
              <button className="btn btn-md btn-danger" type="button" onClick={() => handleRemoveItem(item.id)}>
                Remove
              </button>
            </div>
          ))}
      </CardBody>
    </Card>
  );
};

export default CategoriesSearch;
