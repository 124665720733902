import { USER_ROLE } from '../../utils/constants';
import AddUpdateCalculators from './addUpdateCalculators';
import CalculatorsList from './calculatorsList';
import CALCULATOR_LINKS from './links';

export default [
  {
    path: CALCULATOR_LINKS.ADD_CALCULATOR,
    exact: true,
    component: AddUpdateCalculators,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: CALCULATOR_LINKS.EDIT_CALCULATOR,
    exact: true,
    component: AddUpdateCalculators,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: CALCULATOR_LINKS.CALCULATOR_LISTING,
    exact: true,
    component: CalculatorsList,
    role: [USER_ROLE.ADMIN],
  },
];
