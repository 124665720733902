import { PUBLIC_URL } from '../../utils/constants';

export default {
  ADD_COLLEGE: `${PUBLIC_URL}/college/add`,
  EDIT_COLLEGE: `${PUBLIC_URL}/college/edit/:id`,
  EDIT_COLLEGE_SECTION: `${PUBLIC_URL}/college/edit/:id/:section`,

  COLLEGE_LISTING: `${PUBLIC_URL}/colleges/list`,
  COLLEGE_REVIEWS: `${PUBLIC_URL}/colleges/reviews`,
  COLLEGE_ADMISSION_INQUIRY: `${PUBLIC_URL}/colleges/admission-inquiry`,
  COLLEGE_COURSES: `${PUBLIC_URL}/colleges/courses`,
  EDIT_COLLEGE_COURSE: `${PUBLIC_URL}/colleges/courses/edit/:id`,
  ADD_COLLEGE_COURSE: `${PUBLIC_URL}/colleges/courses/add`,
  COLLEGE_GROUPS: `${PUBLIC_URL}/colleges/groups`,
  EDIT_COLLEGE_GROUP: `${PUBLIC_URL}/colleges/groups/edit/:id`,
  ADD_COLLEGE_GROUP: `${PUBLIC_URL}/colleges/groups/add`,

  COLLEGE_META_CONTENT: `${PUBLIC_URL}/colleges/meta-content`,
  EDIT_COLLEGE_META_CONTENT: `${PUBLIC_URL}/colleges/meta-content/edit/:id/:refId`,
  ADD_COLLEGE_META_CONTENT: `${PUBLIC_URL}/colleges/meta-content/add`,

  COLLEGE_FEATURED: `${PUBLIC_URL}/colleges/featured`,
  ADD_COLLEGE_FEATURED: `${PUBLIC_URL}/colleges/featured/add`,
  EDIT_COLLEGE_FEATURED: `${PUBLIC_URL}/colleges/featured/edit/:id`,
};
