import { useLazyQuery, useMutation } from '@apollo/client';
import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import SmkAsyncSelectGraphql from 'components/SmkAsyncSelect/customSelectGraphql';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import SeoMetaData from 'containers/blog/BlogList/components/SeoMetaData';
import { useFormik } from 'formik';
import { NUTRITIONS_GRAPHQL_URL } from 'lib/apollo-constant';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { LANGUAGE_ARRAY_WITH_STRING, MODULE_TYPE } from 'utils/constants';
import { getTranslationLabel, graphqlRemoveTypeNameKey, titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { SMKLinks } from '../../../utils/links';
import IngredientsCard from '../components/IngredientsCard';
import InstructionsCard from '../components/InstructionsCard';
import NutritionCard from '../components/nutritionCard';
import { ADD_RECIPE, UPDATE_RECIPE } from '../graphql/recipe.mutation';
import { GET_ALL_INGREDIENTS, RECIPE_GET_BY_ID, RECIPE_SEARCH } from '../graphql/recipe.query';
import CategoriesSearch from './components/categoriesSearch';
import { isNumber } from 'lodash';

const AddUpdateRecipe = () => {
  const history = useHistory();
  const { id: recipeId } = useParams();

  toast.configure();

  const [activeTab, setActiveTab] = useState('1');
  const [instructionsValues, setInstructionsValues] = useState([]);
  const [nutritionValues, setNutritionValues] = useState({});
  const [recipeDetails, setRecipeDetails] = useState();

  const [allIngredients, setAllIngredients] = useState([]);

  const [getAllIngredientsRequest, { loading: getAllIngredientsLoading }] = useLazyQuery(GET_ALL_INGREDIENTS, {
    ...NUTRITIONS_GRAPHQL_URL,
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_nutrition_recipe_ingredient_getAll)) {
        const updatedData = data?.admin_nutrition_recipe_ingredient_getAll;
        setAllIngredients(updatedData);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });
  const [getRecipeDetailsRequest, { loading: getRecipeDetailsLoading }] = useLazyQuery(RECIPE_GET_BY_ID, {
    ...NUTRITIONS_GRAPHQL_URL,
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_nutrition_recipe_getById)) {
        const updatedData = graphqlRemoveTypeNameKey(data?.admin_nutrition_recipe_getById);
        setRecipeDetails(updatedData);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [addRecipeRequest, { loading: addRecipeLoading }] = useMutation(ADD_RECIPE, {
    ...NUTRITIONS_GRAPHQL_URL,
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_nutrition_recipe_add)) {
        toast.success(`Success! Recipe has been added.`);
        history.replace(SMKLinks.RECIPE_LIST);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const [updateRecipeRequest, { loading: updateRecipeLoading }] = useMutation(UPDATE_RECIPE, {
    ...NUTRITIONS_GRAPHQL_URL,
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_nutrition_recipe_update)) {
        toast.success(`Success! Recipe has been updated.`);
        window.location.reload();
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const editInitialValues = () => {
    setNutritionValues(recipeDetails?.nutritions);
    setInstructionsValues(recipeDetails?.instructions);

    return {
      id: recipeDetails?.id,
      active: recipeDetails?.active,
      deleted: recipeDetails?.deleted,
      isFeatured: recipeDetails?.isFeatured || false,
      name: recipeDetails?.name,
      slug: recipeDetails?.slug,
      description: recipeDetails?.description,

      servesCount: recipeDetails?.servesCount,
      prepTime: recipeDetails?.prepTime,
      cookTime: recipeDetails?.cookTime,
      difficulty: recipeDetails?.difficulty,
      categories: recipeDetails?.categories,
      nutritions: recipeDetails?.nutritions,
      instructions: recipeDetails?.instructions,

      language: recipeDetails?.language || LANGUAGE_ARRAY_WITH_STRING[0]?.value,
      translations: !isEmpty(recipeDetails?.translations) ? recipeDetails?.translations[0] : {},
      seoTitle: recipeDetails?.seo?.title || '',
      seoDescription: recipeDetails?.seo?.description || '',
      seoKeywords: !isEmpty(recipeDetails?.seo?.keywords)
        ? recipeDetails?.seo?.keywords?.split(', ')?.map((e) => ({
            label: e,
            value: e,
          }))
        : '',
      ogImage: recipeDetails?.seo?.ogImage || {},
      image: recipeDetails?.image || {},
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    isFeatured: false,
    name: '',
    slug: '',
    description: '',
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,

    servesCount: 1,
    prepTime: 0,
    cookTime: 0,
    difficulty: '',
    categories: null,
    nutritions: null,
    instructions: null,

    seoTitle: '',
    seoDescription: '',
    seoKeywords: '',
    ogImage: {},

    image: {},
    translations: '',
  });

  const handleAddRecipe = (values) => {
    const addPayload = {
      active: values?.active,
      deleted: values?.deleted,
      isFeatured: values?.isFeatured,
      name: values?.name,
      slug: values?.slug,
      description: values?.description,
      categories: !isEmpty(values?.categories)
        ? values?.categories?.map((e) => ({
            id: e?.id,
          }))
        : [],
      servesCount: values?.servesCount,
      prepTime: values?.prepTime,
      cookTime: values?.cookTime,
      difficulty: values?.difficulty,

      language: values?.language,
      translations: !isEmpty(values?.translations) ? [values?.translations?.id] : [],

      // For SEO Object
      seo: {
        title: !isEmpty(values?.seoTitle) ? values?.seoTitle : '',
        description: !isEmpty(values?.seoDescription) ? values?.seoDescription : '',
        keywords: !isEmpty(values?.seoKeywords) ? values?.seoKeywords?.map((e) => e?.value).join(', ') : '',
        ...(!isEmpty(values?.ogImage)
          ? {
              ogImageId: values?.ogImage?.id,
              ogImage: undefined,
            }
          : { ogImageId: null }),
      },
      ...(!isEmpty(values?.image)
        ? {
            imageId: values?.image?.id,
            image: undefined,
          }
        : { imageId: null, image: undefined }),

      seoTitle: undefined,
      seoDescription: undefined,
      seoKeywords: undefined,
      ogImage: undefined,
    };

    addRecipeRequest({
      variables: {
        recipeDTO: {
          ...addPayload,
        },
      },
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialValues(),
    onSubmit: (values) => {
      const updatePayload = {
        active: values?.active,
        deleted: values?.deleted,
        isFeatured: values?.isFeatured,
        name: values?.name,
        slug: values?.slug,
        description: values?.description,

        categories: !isEmpty(values?.categories)
          ? values?.categories?.map((e) => ({
              id: e?.id,
            }))
          : [],
        servesCount: values?.servesCount,
        prepTime: values?.prepTime,
        cookTime: values?.cookTime,
        difficulty: values?.difficulty,

        instructions: {
          ...values?.instructions,
          directions: !isEmpty(values?.instructions?.directions)
            ? values?.instructions?.directions?.map((elem) => ({
                ...elem,
                ingredientIds: !isEmpty(elem?.ingredientIds) ? elem?.ingredientIds?.map((e) => e?.value) : [],
                utensils: !isEmpty(elem?.utensils) ? elem?.utensils?.map((e) => e?.label) : [],
              }))
            : [],
        },
        nutritions: {
          ...values?.nutritions,
          calories: !isEmpty(String(values?.nutritions?.calories)) ? values?.nutritions?.calories : undefined,
          glycemicIndex: !isEmpty(String(values?.nutritions?.glycemicIndex))
            ? values?.nutritions?.glycemicIndex
            : undefined,
        },

        language: values?.language,
        translations: !isEmpty(values?.translations) ? [values?.translations?.id] : [],

        // for SEO Object
        seo: {
          title: !isEmpty(values?.seoTitle) ? values?.seoTitle : '',
          description: !isEmpty(values?.seoDescription) ? values?.seoDescription : '',
          keywords: !isEmpty(values?.seoKeywords) ? values?.seoKeywords?.map((e) => e?.value).join(', ') : '',
          ...(!isEmpty(values?.ogImage)
            ? {
                ogImageId: values?.ogImage?.id,
                ogImage: undefined,
              }
            : { ogImageId: null }),
        },
        ...(!isEmpty(values?.image)
          ? {
              imageId: values?.image?.id,
              image: undefined,
            }
          : { imageId: null, image: undefined }),

        seoTitle: undefined,
        seoDescription: undefined,
        seoKeywords: undefined,
        ogImage: undefined,
      };

      if (isEmpty(recipeId)) {
        handleAddRecipe(values);
      } else {
        updateRecipeRequest({
          variables: {
            id: recipeId,
            recipeDTO: {
              ...updatePayload,
            },
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
      slug: Yup.string().required('Please provide slug'),
      servesCount: Yup.number()
        .min(1, 'Serve Count must be at least 1')
        .required('Please provide Serve Count'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(recipeDetails)) {
      formik.setValues(editInitialValues());
    }
  }, [recipeDetails]);

  useEffect(() => {
    if (!isEmpty(recipeId)) {
      getRecipeDetailsRequest({
        variables: {
          id: recipeId,
        },
      });
      getAllIngredientsRequest({
        variables: {
          recipeId,
        },
      });
    }
  }, [recipeId]);

  function CalculateTime(min1, min2) {
    if (isNaN(+min1) || isNaN(+min2)) {
      return '0 minutes';
    }

    const totalMinutes = +min1 + +min2;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours > 0) {
      return `${hours} hour${hours !== 1 ? `s` : ``} ${
        minutes > 0 ? `and ${minutes} minute${minutes !== 1 ? `s` : ``}` : ''
      } `;
    } else {
      return `${minutes} minute${minutes !== 1 ? `s` : ``}`;
    }
  }

  return (
    <div className="page-content">
      <Loader
        isActive={addRecipeLoading || getRecipeDetailsLoading || updateRecipeLoading || getAllIngredientsLoading}
      />
      <Container fluid>
        <Breadcrumbs title="Recipe" breadcrumbItem={`${isEmpty(recipeId) ? 'Add' : 'Edit'}  Recipe`} />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="name" className="form-label  ">
                          Name
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name.."
                          invalid={!!(formik.touched.name && formik.errors.name)}
                          {...formik.getFieldProps('name')}
                        />
                        <FormFeedback>{formik.errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12} className="mb-4">
                      <SlugInput
                        formik={formik}
                        ID={recipeId}
                        nameValue={formik.values.name}
                        formGroupClassName={'mt-0'}
                        showResetRegenerate={true}
                        currentSlug={recipeDetails?.slug}
                      />
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="description" className="form-label  ">
                          Description
                        </Label>
                        <Input
                          id="description"
                          name="description"
                          style={{ height: '100px' }}
                          type="textarea"
                          className="form-control"
                          placeholder="Description.."
                          invalid={!!(formik.touched.description && formik.errors.description)}
                          {...formik.getFieldProps('description')}
                        />
                        <FormFeedback>{formik.errors.description}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="servesCount" className="form-label  ">
                          Serves Count
                        </Label>
                        <Input
                          id="servesCount"
                          name="servesCount"
                          type="number"
                          className="form-control"
                          placeholder="Serves Count.."
                          invalid={!!(formik.touched.servesCount && formik.errors.servesCount)}
                          {...formik.getFieldProps('servesCount')}
                        />
                        <FormFeedback>{formik.errors.servesCount}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="difficulty" className="form-label">
                          Difficulty
                        </Label>
                        <Input
                          id="difficulty"
                          name="difficulty"
                          type="select"
                          className="form-select"
                          invalid={!!(formik.touched.difficulty && formik.errors.difficulty)}
                          {...formik.getFieldProps('difficulty')}>
                          <option value="">Select Difficulty</option>
                          <option value="Easy">Easy</option>
                          <option value="Medium">Medium</option>
                          <option value="Hard">Hard</option>
                        </Input>
                        <FormFeedback>{formik.errors.difficulty}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={3}>
                      <FormGroup className="mb-4">
                        <Label for="prepTime" className="form-label  ">
                          Prep Time
                        </Label>
                        <InputGroup>
                          <Input
                            id="prepTime"
                            name="prepTime"
                            type="number"
                            className="form-control"
                            placeholder="Prep Time.."
                            invalid={!!(formik.touched.prepTime && formik.errors.prepTime)}
                            {...formik.getFieldProps('prepTime')}
                          />
                          <InputGroupText>minutes</InputGroupText>
                        </InputGroup>
                        <FormFeedback>{formik.errors.prepTime}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={1} className="text-center px-0">
                      <h3 className="mb-0">+</h3>
                    </Col>
                    <Col md={3}>
                      <FormGroup className="mb-4">
                        <Label for="cookTime" className="form-label  ">
                          Cook Time
                        </Label>
                        <InputGroup>
                          <Input
                            id="cookTime"
                            name="cookTime"
                            type="number"
                            className="form-control"
                            placeholder="Prep Time.."
                            invalid={!!(formik.touched.cookTime && formik.errors.cookTime)}
                            {...formik.getFieldProps('cookTime')}
                          />
                          <InputGroupText>minutes</InputGroupText>
                        </InputGroup>
                        <FormFeedback>{formik.errors.cookTime}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={1} className="text-center px-0">
                      <h3 className="mb-0">=</h3>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="mb-4">
                        <Label for="totalTime" className="form-label  ">
                          Total Time
                        </Label>
                        <h5>{CalculateTime(formik.values.prepTime, formik.values.cookTime)}</h5>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <CategoriesSearch
                        className={'bg-light'}
                        onChange={(data) => formik.setFieldValue('categories', data)}
                        initialValues={formik.values.categories}
                        filters={{
                          language: formik.values.language,
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              {!isEmpty(recipeId) && (
                <div className="mt-5">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === '1' ? 'active text-primary' : ''}
                        onClick={() => {
                          setActiveTab('1');
                        }}>
                        <h5 className="mb-0">Ingredients</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === '2' ? 'active text-primary' : ''}
                        onClick={() => {
                          setActiveTab('2');
                        }}>
                        <h5 className="mb-0">Instructions</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === '3' ? 'active text-primary' : ''}
                        onClick={() => {
                          setActiveTab('3');
                        }}>
                        <h5 className="mb-0">Nutrition</h5>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <div className="mt-4">
                        <IngredientsCard formik={formik} allIngredients={allIngredients} recipeId={recipeId} />
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="mt-4">
                        <InstructionsCard
                          formik={formik}
                          allIngredients={allIngredients}
                          formikLabel={'instructions'}
                          values={instructionsValues}
                        />
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div className="mt-4">
                        <NutritionCard formik={formik} formikLabel={'nutritions'} values={nutritionValues} />
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              )}

              <SeoMetaData formik={formik} forType={MODULE_TYPE.RECIPE} />
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <Button type="submit" color="primary" className="mb-3 w-100">
                    {recipeId ? 'Save' : 'Add'}
                  </Button>

                  <Button
                    className="w-100"
                    color="light"
                    onClick={() => {
                      history.replace(SMKLinks.RECIPE_LIST);
                    }}>
                    Cancel
                  </Button>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  {['active', 'deleted', 'isFeatured']?.map((elem, index) => (
                    <FormGroup className={`mb-3`} key={index}>
                      <Label className="mb-3">{elem === 'isFeatured' ? 'is Featured' : titleCaseIfExists(elem)}</Label>
                      <div className="d-flex align-items-center">
                        {[true, false].map((option, i) => (
                          <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                            <input
                              type="radio"
                              id={`${elem}_${option}`}
                              name={elem}
                              className="form-check-input"
                              checked={formik.values[elem] === option}
                              onChange={() => formik.setFieldValue(`${elem}`, option)}
                            />
                            <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                  ))}
                </CardBody>
              </Card>

              <Card className="border">
                <CardBody>
                  <Row>
                    <Col md={12} className="mb-4">
                      <CardTitle className="mb-2">Translation </CardTitle>
                      <SmkAsyncSelectGraphql
                        isDisabled={formik.values.language === 'hi'}
                        acceptedKey={'name'}
                        acceptedValue={'id'}
                        placeholder="Type Recipe name.."
                        searchKeyName="name"
                        query={RECIPE_SEARCH}
                        queryName={'admin_nutrition_recipe_search'}
                        dtoName={'searchDTO'}
                        filters={{
                          autoComplete: true,
                          active: true,
                          page: 1,
                          size: 500,
                          sortBy: 'id',
                          sortOrder: 0,
                          language: formik.values.language === 'en' ? 'hi' : 'en',
                        }}
                        onChange={(res) => {
                          formik.setFieldValue('translations', res);
                        }}
                        value={
                          !isEmpty(formik?.values?.translations)
                            ? {
                                label: getTranslationLabel(formik?.values?.translations),
                                value: formik?.values?.translations?.id,
                              }
                            : ''
                        }
                      />

                      {!isEmpty(formik?.values?.translations) && (
                        <div className="mt-3">
                          <div className="card bg-light">
                            <div className="card-body">
                              <a target="_blank" href={`/blog/edit/${formik?.values?.translations?.id}`}>
                                <p className="mb-1">
                                  <span className="text-dark">Language:</span> {formik?.values?.translations?.language}
                                </p>
                                <p className="mb-1">
                                  <span className="text-dark">ID:</span> {formik?.values?.translations?.id}
                                </p>
                                <p className="mb-1">
                                  <span className="text-dark">Title:</span>{' '}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: getTranslationLabel(formik?.values?.translations),
                                    }}
                                  />
                                </p>
                              </a>
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                    <Col md={12}>
                      <CardTitle className="mb-3">Language </CardTitle>
                      {LANGUAGE_ARRAY_WITH_STRING?.map((option, i) => (
                        <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`language_${option?.value}`}
                            name="language"
                            className="form-check-input"
                            checked={formik.values.language === option?.value}
                            onChange={() => formik.setFieldValue('language', option?.value)}
                          />
                          <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                            {option?.label}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <AssetImageUploader
                forType={MODULE_TYPE.RECIPE}
                formik={formik}
                fieldName={'image'}
                label={'Select Image'}
              />
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default AddUpdateRecipe;
