// ACTION TYPES
export const SCHOOL_LIST = 'SMK_ADMIN/SCHOOL_LIST';

export const GET_SCHOOL_REVIEWS = 'SMK_ADMIN/GET_SCHOOL_REVIEWS';
export const GET_SCHOOL_GOOGLE_OBJECTS = 'SMK_ADMIN/GET_SCHOOL_GOOGLE_OBJECTS';
export const GET_SCHOOL_GOOGLE_OBJECT_FOR_APPROVAL = 'SMK_ADMIN/GET_SCHOOL_GOOGLE_OBJECT_FOR_APPROVAL';
export const GET_SCHOOL_GOOGLE_OBJECT_FOR_REVIEWS_APPROVAL = 'SMK_ADMIN/GET_SCHOOL_GOOGLE_OBJECT_FOR_REVIEWS_APPROVAL';

export const GET_COUNTRIES = 'SMK_ADMIN/GET_COUNTRIES';
export const GET_REGIONS = 'SMK_ADMIN/GET_REGIONS';
export const GET_CITIES = 'SMK_ADMIN/GET_CITIES';
export const GET_SUB_AREAS = 'SMK_ADMIN/GET_SUB_AREAS';

export const GET_ADMISSION_INQUIRIES = 'SMK_ADMIN/GET_ADMISSION_INQUIRIES';
export const ADMISSION_INQUIRIES_MARK_ACTION_TAKEN = 'SMK_ADMIN/ADMISSION_INQUIRIES_MARK_ACTION_TAKEN';
export const ADMISSION_INQUIRIES_MARK_DELETED = 'SMK_ADMIN/ADMISSION_INQUIRIES_MARK_DELETED';

export const SCHOOL_REVIEW_APPROVE = 'SMK_ADMIN/SCHOOL_REVIEW_APPROVE';
export const SCHOOL_REVIEW_DELETE = 'SMK_ADMIN/SCHOOL_REVIEW_DELETE';
export const ADD_SCHOOL = 'SMK_ADMIN/ADD_SCHOOL';
export const GET_SCHOOL_DETAIL = 'SMK_ADMIN/GET_SCHOOL_DETAIL';

export const GET_SCHOOLS_MASTER_DATA = 'smk/school/GET_SCHOOLS_MASTER_DATA';
export const GET_SCHOOL_BOARDS = 'smk/school/GET_SCHOOL_BOARDS';
export const GET_SCHOOL_LEVELS = 'smk/school/GET_SCHOOL_LEVELS';
export const GET_SCHOOLS_INFRA_LIST = 'SMK_ADMIN/GET_SCHOOLS_INFRA_LIST';
export const GET_SCHOOLS_LEVEL_MASTER = 'SMK_ADMIN/GET_SCHOOLS_LEVEL_MASTER';
export const GET_SCHOOLS_GROUPS = 'SMK_ADMIN/GET_SCHOOLS_GROUP';

// NOTE: Remove later
// export const UPDATE_SCHOOL_FACILITY = 'SMK_ADMIN/UPDATE_SCHOOL_FACILITY';
// export const UPDATE_SCHOOL_ACADEMIC = 'SMK_ADMIN/UPDATE_SCHOOL_ACADEMIC';

export const UPDATE_SCHOOL_BASIC_INFO = 'SMK_ADMIN/UPDATE_SCHOOL_BASIC_INFO';
export const UPDATE_SCHOOL_CONTACT_DETAILS = 'SMK_ADMIN/UPDATE_SCHOOL_CONTACT_DETAILS';
export const UPDATE_SCHOOL_ADMISSION_INFO = 'SMK_ADMIN/UPDATE_SCHOOL_ADMISSION_INFO';
export const UPDATE_SCHOOL_FEE_DETAILS = 'SMK_ADMIN/UPDATE_SCHOOL_FEE_DETAILS';
export const UPDATE_SCHOOL_ACADEMIC_DETAILS = 'SMK_ADMIN/UPDATE_SCHOOL_ACADEMIC_DETAILS';
export const UPDATE_SCHOOL_FACILITY_DETAILS = 'SMK_ADMIN/UPDATE_SCHOOL_FACILITY_DETAILS';
export const UPDATE_SCHOOL_EXTRA_CURRICULAR_DETAILS = 'SMK_ADMIN/UPDATE_SCHOOL_EXTRA_CURRICULAR_DETAILS';
export const UPDATE_SCHOOL_ADDITIONAL_INFO = 'SMK_ADMIN/UPDATE_SCHOOL_ADDITIONAL_INFO';
export const UPDATE_SCHOOL_SYSTEM_INFO = 'SMK_ADMIN/UPDATE_SCHOOL_SYSTEM_INFO';
