import { USER_ROLE } from '../../utils/constants';
import LOCATION_LINKS from './links';
import LocationMasterList from './LocationMasterList';
import UpdateLocation from './UpdateLocation';

export default [
  {
    path: LOCATION_LINKS.ADD_LOCATION,
    exact: true,
    component: UpdateLocation,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: LOCATION_LINKS.EDIT_LOCATION,
    exact: true,
    component: UpdateLocation,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: LOCATION_LINKS.LOCATION_LISTING,
    exact: true,
    component: LocationMasterList,
    role: [USER_ROLE.ADMIN],
  },
];
