import Dashboard from './index';
import DASHBOARD_LINKS from './links';

export default [
  {
    path: DASHBOARD_LINKS.DASHBOARD,
    exact: true,
    component: Dashboard,
  },
];
