import { push } from 'connected-react-router';
import { call, put, takeLatest } from 'redux-saga/effects';
import { setGlobalError } from '../../common/globalComponents/actions';
import config from '../../config/config';
import { api } from '../../utils/axios';
import { GET, POST } from '../../utils/httpMethods';
import { SMKLinks } from '../../utils/links';
import {
  addCollege,
  getCollegeCourses,
  getCollegeCoursesSearchData,
  getCollegeDetail,
  getCollegeLevels,
  getColleges,
  getCollegesCoursesMasterData,
  getCollegesGroups,
  getCollegesInfraList,
  getCollegesMasterData,
  updateCollegeAcademicDetails,
  updateCollegeAdditionalDetails,
  updateCollegeAdmissionDetails,
  updateCollegeBasicDetails,
  updateCollegeContactDetails,
  updateCollegeExtraCurricularDetails,
  updateCollegeFacilityDetails,
  updateCollegeFeeDetails,
  updateCollegeSystemDetails,
} from './actions';
import { isEmpty } from 'lodash';

export const ADD_COLLEGE_API_URL = `${config.apiEndpoint}/college/admin`;
export const GET_COLLEGE_API_URL = `${config.apiEndpoint}/college/admin/`;

export const GET_COLLEGES_API_URL = `${config.apiEndpoint}/college/admin/search`;
export const GET_COLLEGE_COURSES_MASTER_DATA_API_URL = `${config.apiEndpoint}/college/collegeCoursesMasterData/getAllMetaData`;
export const GET_COLLEGE_COURSES_SEARCH_DATA_API_URL = `${config.apiEndpoint}/college/admin/course/search`;

export const GET_COLLEGE_MASTER_DATA_API_URL = `${config.apiEndpoint}/college/collegeMasterData/search`;
export const GET_COLLEGES_INFRA_LIST_API_URL = `${config.apiEndpoint}/college/collegeMasterData/getAllInfra`;
export const GET_COLLEGES_GROUPS_URL = `${config.apiEndpoint}/college/admin/collegeGroups/search`;

export const UPDATE_COLLEGE_BASIC_DETAILS_API_URL = `${config.apiEndpoint}/college/admin/edit/updateBasicDetails`;
export const UPDATE_COLLEGE_CONTACT_DETAILS_API_URL = `${config.apiEndpoint}/college/admin/edit/updateContactDetails`;
export const UPDATE_COLLEGE_ADMISSION_DETAILS_API_URL = `${config.apiEndpoint}/college/admin/edit/updateAdmissionDetails`;
export const UPDATE_COLLEGE_FEE_DETAILS_API_URL = `${config.apiEndpoint}/college/admin/edit/updateFeeDetails`;
export const UPDATE_COLLEGE_ACADEMIC_DETAILS_API_URL = `${config.apiEndpoint}/college/admin/edit/updateAcademicDetails`;
export const UPDATE_COLLEGE_FACILITY_DETAILS_API_URL = `${config.apiEndpoint}/college/admin/edit/updateFacilityDetails`;
export const UPDATE_COLLEGE_EXTRA_CURRICULAR_DETAILS_API_URL = `${config.apiEndpoint}/college/admin/edit/updateExtraCurricularDetails`;
export const UPDATE_COLLEGE_ADDITIONAL_DETAILS_API_URL = `${config.apiEndpoint}/college/admin/edit/updateAdditionalDetails`;
export const UPDATE_COLLEGE_SYSTEM_DETAILS_API_URL = `${config.apiEndpoint}/college/admin/edit/updateSystemDetails`;

const getCollegesCall = (filters) =>
  api({
    method: POST,
    url: GET_COLLEGES_API_URL,
    data: filters,
  });

const addCollegeCall = (college) =>
  api({
    method: POST,
    url: ADD_COLLEGE_API_URL,
    data: college,
  });

const getCollegeDetailCall = (id) =>
  api({
    method: GET,
    url: GET_COLLEGE_API_URL + id,
  });

const getCollegesCoursesMasterDataCall = () =>
  api({
    method: GET,
    url: GET_COLLEGE_COURSES_MASTER_DATA_API_URL,
  });
const getCollegeCoursesSearchDataCall = (filters) =>
  api({
    method: POST,
    url: GET_COLLEGE_COURSES_SEARCH_DATA_API_URL,
    data: filters,
  });
const getCollegesGroupsCall = (data) =>
  api({
    method: POST,
    url: GET_COLLEGES_GROUPS_URL,
    data: data,
  });

const getCollegesInfraListCall = () =>
  api({
    method: GET,
    url: GET_COLLEGES_INFRA_LIST_API_URL,
    data: {},
  });
const getCollegesMasterDataCall = () =>
  api({
    method: GET,
    url: GET_COLLEGE_MASTER_DATA_API_URL,
    data: {},
  });

const updateCollegeBasicDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_COLLEGE_BASIC_DETAILS_API_URL,
    data,
  });

const updateCollegeContactDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_COLLEGE_CONTACT_DETAILS_API_URL,
    data,
  });
const updateCollegeAdmissionDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_COLLEGE_ADMISSION_DETAILS_API_URL,
    data,
  });

const updateCollegeFeeDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_COLLEGE_FEE_DETAILS_API_URL,
    data,
  });

const updateCollegeAcademicDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_COLLEGE_ACADEMIC_DETAILS_API_URL,
    data,
  });

const updateCollegeFacilityDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_COLLEGE_FACILITY_DETAILS_API_URL,
    data,
  });
const updateCollegeExtraCurricularDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_COLLEGE_EXTRA_CURRICULAR_DETAILS_API_URL,
    data,
  });
const updateCollegeAdditionalDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_COLLEGE_ADDITIONAL_DETAILS_API_URL,
    data,
  });
const updateCollegeSystemDetailsCall = (data) =>
  api({
    method: POST,
    url: UPDATE_COLLEGE_SYSTEM_DETAILS_API_URL,
    data,
  });

function* getCollegesRequest(action) {
  try {
    yield put(getColleges.request({ isLoading: true }));
    const response = yield call(getCollegesCall, action.payload);
    if (isEmpty(response?.message)) {
      yield put(getColleges.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getColleges.failure(error));
  } finally {
    yield put(getColleges.fulfill({ isLoading: false }));
  }
}

function* addCollegeRequest(action) {
  try {
    yield put(addCollege.request({ isLoading: true }));

    const { college } = action.payload;

    const response = yield call(addCollegeCall, college);

    if (isEmpty(response?.message)) {
      const { addedcollege } = response;
      yield put(addCollege.success({ college: addedcollege }));
      yield put(push(SMKLinks.COLLEGE_LISTING));
    }
  } catch (error) {
    yield put(addCollege.failure(error));
  } finally {
    yield put(addCollege.fulfill({ isLoading: false }));
  }
}

function* getCollegeDetailRequest(action) {
  try {
    yield put(getCollegeDetail.request({ isLoading: true }));

    const { id } = action.payload;

    const response = yield call(getCollegeDetailCall, id);

    if (isEmpty(response?.message)) {
      yield put(getCollegeDetail.success(response));
    }
  } catch (error) {
    yield put(getCollegeDetail.failure(error));
  } finally {
    yield put(getCollegeDetail.fulfill({ isLoading: false }));
  }
}
function* getCollegesGroupsRequest(action) {
  try {
    yield put(getCollegesGroups.request({ isLoading: true }));

    // check if data already in state - skip if so
    const response = yield call(getCollegesGroupsCall, action?.payload);
    if (isEmpty(response?.message)) {
      yield put(getCollegesGroups.success(response));
    }
  } catch (error) {
    yield put(getCollegesGroups.failure(error));
  } finally {
    yield put(getCollegesGroups.fulfill({ isLoading: false }));
  }
}
function* getCollegesCoursesMasterDataRequest(action) {
  try {
    yield put(getCollegesCoursesMasterData.request({ isLoading: true }));

    const response = yield call(getCollegesCoursesMasterDataCall, action?.payload);
    if (isEmpty(response?.message)) {
      yield put(getCollegesCoursesMasterData.success(response));
    }
  } catch (error) {
    yield put(getCollegesCoursesMasterData.failure(error));
  } finally {
    yield put(getCollegesCoursesMasterData.fulfill({ isLoading: false }));
  }
}
function* getCollegeCoursesSearchDataRequest(action) {
  try {
    yield put(getCollegeCoursesSearchData.request({ isLoading: true }));

    const response = yield call(getCollegeCoursesSearchDataCall, action?.payload);
    if (isEmpty(response?.message)) {
      yield put(getCollegeCoursesSearchData.success(response));
    }
  } catch (error) {
    yield put(getCollegeCoursesSearchData.failure(error));
  } finally {
    yield put(getCollegeCoursesSearchData.fulfill({ isLoading: false }));
  }
}

function* getCollegesInfraListRequest(action) {
  try {
    yield put(getCollegesInfraList.request({ isLoading: true }));

    const response = yield call(getCollegesInfraListCall, action?.payload);

    if (isEmpty(response?.message)) {
      yield put(getCollegesInfraList.success(response));
    }
  } catch (error) {
    yield put(getCollegesInfraList.failure(error));
  } finally {
    yield put(getCollegesInfraList.fulfill({ isLoading: false }));
  }
}
function* getCollegesMasterDataRequest(action) {
  try {
    yield put(getCollegesMasterData.request());

    const response = yield call(getCollegesMasterDataCall, action?.payload);

    if (isEmpty(response?.message)) {
      yield put(getCollegesMasterData.success(response));
    }
  } catch (error) {
    yield put(getCollegesMasterData.failure(error));
  } finally {
    yield put(getCollegesMasterData.fulfill());
  }
}

function* updateCollegeBasicDetailsRequest(action) {
  try {
    yield put(updateCollegeBasicDetails.request({ isLoading: true }));

    const response = yield call(updateCollegeBasicDetailsCall, action.payload);

    if (isEmpty(response?.message)) {
      yield put(updateCollegeBasicDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateCollegeBasicDetails.failure(error));
  } finally {
    yield put(updateCollegeBasicDetails.fulfill({ isLoading: false }));
  }
}
function* updateCollegeContactDetailsRequest(action) {
  try {
    yield put(updateCollegeContactDetails.request({ isLoading: true }));

    const response = yield call(updateCollegeContactDetailsCall, action.payload);

    if (isEmpty(response?.message)) {
      yield put(updateCollegeContactDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateCollegeContactDetails.failure(error));
  } finally {
    yield put(updateCollegeContactDetails.fulfill({ isLoading: false }));
  }
}

function* updateCollegeAdmissionDetailsRequest(action) {
  try {
    yield put(updateCollegeAdmissionDetails.request({ isLoading: true }));

    const response = yield call(updateCollegeAdmissionDetailsCall, action.payload);

    if (isEmpty(response?.message)) {
      yield put(updateCollegeAdmissionDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateCollegeAdmissionDetails.failure(error));
  } finally {
    yield put(updateCollegeAdmissionDetails.fulfill({ isLoading: false }));
  }
}

function* updateCollegeFeeDetailsRequest(action) {
  try {
    yield put(updateCollegeFeeDetails.request({ isLoading: true }));

    const response = yield call(updateCollegeFeeDetailsCall, action.payload);

    if (isEmpty(response?.message)) {
      yield put(updateCollegeFeeDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateCollegeFeeDetails.failure(error));
  } finally {
    yield put(updateCollegeFeeDetails.fulfill({ isLoading: false }));
  }
}

function* updateCollegeAcademicDetailsRequest(action) {
  try {
    yield put(updateCollegeAcademicDetails.request({ isLoading: true }));

    const response = yield call(updateCollegeAcademicDetailsCall, action.payload);

    if (isEmpty(response?.message)) {
      yield put(updateCollegeAcademicDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateCollegeAcademicDetails.failure(error));
  } finally {
    yield put(updateCollegeAcademicDetails.fulfill({ isLoading: false }));
  }
}

function* updateCollegeFacilityDetailsRequest(action) {
  try {
    yield put(updateCollegeFacilityDetails.request({ isLoading: true }));

    const response = yield call(updateCollegeFacilityDetailsCall, action.payload);

    if (isEmpty(response?.message)) {
      yield put(updateCollegeFacilityDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateCollegeFacilityDetails.failure(error));
  } finally {
    yield put(updateCollegeFacilityDetails.fulfill({ isLoading: false }));
  }
}

function* updateCollegeExtraCurricularDetailsRequest(action) {
  try {
    yield put(updateCollegeExtraCurricularDetails.request({ isLoading: true }));

    const response = yield call(updateCollegeExtraCurricularDetailsCall, action.payload);

    if (isEmpty(response?.message)) {
      yield put(updateCollegeExtraCurricularDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateCollegeExtraCurricularDetails.failure(error));
  } finally {
    yield put(updateCollegeExtraCurricularDetails.fulfill({ isLoading: false }));
  }
}
function* updateCollegeSystemDetailsRequest(action) {
  try {
    yield put(updateCollegeSystemDetails.request({ isLoading: true }));

    const response = yield call(updateCollegeSystemDetailsCall, action.payload);

    if (isEmpty(response?.message)) {
      yield put(updateCollegeSystemDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateCollegeSystemDetails.failure(error));
  } finally {
    yield put(updateCollegeSystemDetails.fulfill({ isLoading: false }));
  }
}
function* updateCollegeAdditionalDetailsRequest(action) {
  try {
    yield put(updateCollegeAdditionalDetails.request({ isLoading: true }));

    const response = yield call(updateCollegeAdditionalDetailsCall, action.payload);

    if (isEmpty(response?.message)) {
      yield put(updateCollegeAdditionalDetails.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(updateCollegeAdditionalDetails.failure(error));
  } finally {
    yield put(updateCollegeAdditionalDetails.fulfill({ isLoading: false }));
  }
}

export default function* collegeSaga() {
  yield takeLatest(addCollege.TRIGGER, addCollegeRequest);
  yield takeLatest(getCollegeDetail.TRIGGER, getCollegeDetailRequest);

  yield takeLatest(getColleges.TRIGGER, getCollegesRequest);

  yield takeLatest(getCollegesMasterData.TRIGGER, getCollegesMasterDataRequest);
  yield takeLatest(getCollegesInfraList.TRIGGER, getCollegesInfraListRequest);
  yield takeLatest(getCollegesGroups.TRIGGER, getCollegesGroupsRequest);
  yield takeLatest(getCollegesCoursesMasterData.TRIGGER, getCollegesCoursesMasterDataRequest);
  yield takeLatest(getCollegeCoursesSearchData.TRIGGER, getCollegeCoursesSearchDataRequest);

  // NOTE:
  yield takeLatest(updateCollegeBasicDetails.TRIGGER, updateCollegeBasicDetailsRequest);
  yield takeLatest(updateCollegeContactDetails.TRIGGER, updateCollegeContactDetailsRequest);
  yield takeLatest(updateCollegeAdmissionDetails.TRIGGER, updateCollegeAdmissionDetailsRequest);
  yield takeLatest(updateCollegeFeeDetails.TRIGGER, updateCollegeFeeDetailsRequest);
  yield takeLatest(updateCollegeAcademicDetails.TRIGGER, updateCollegeAcademicDetailsRequest);
  yield takeLatest(updateCollegeFacilityDetails.TRIGGER, updateCollegeFacilityDetailsRequest);
  yield takeLatest(updateCollegeExtraCurricularDetails.TRIGGER, updateCollegeExtraCurricularDetailsRequest);
  yield takeLatest(updateCollegeAdditionalDetails.TRIGGER, updateCollegeAdditionalDetailsRequest);
  yield takeLatest(updateCollegeSystemDetails.TRIGGER, updateCollegeSystemDetailsRequest);
}
