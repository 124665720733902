import { gql } from '@apollo/client';

// add recipe
export const ADD_RECIPE = gql`
  mutation Admin_nutrition_recipe_add($recipeDTO: CreateRecipeDTO!) {
    admin_nutrition_recipe_add(recipeDTO: $recipeDTO) {
      id
      active
      deleted
    }
  }
`;

// update recipe
export const UPDATE_RECIPE = gql`
  mutation Admin_nutrition_recipe_update($id: String!, $recipeDTO: CreateRecipeDTO!) {
    admin_nutrition_recipe_update(id: $id, recipeDTO: $recipeDTO) {
      id
      active
      deleted
    }
  }
`;

// delete recipe
export const DELETE_RECIPE = gql`
  mutation Admin_nutrition_recipe_delete($id: String!) {
    admin_nutrition_recipe_delete(id: $id) {
      id
      active
      deleted
    }
  }
`;

// add recipe ingredients
export const ADD_RECIPE_INGREDIENTS = gql`
  mutation Admin_nutrition_recipe_ingredient_add($recipeIngredient: CreateRecipeIngredientsDTO!) {
    admin_nutrition_recipe_ingredient_add(recipeIngredient: $recipeIngredient) {
      id
      active
      deleted
    }
  }
`;

// update recipe ingredients
export const UPDATE_RECIPE_INGREDIENTS = gql`
  mutation admin_nutrition_recipe_ingredient_getAlldmin_nutrition_recipe_ingredient_update(
    $recipeIngredientId: String!
    $recipeIngredient: CreateRecipeIngredientsDTO!
  ) {
    admin_nutrition_recipe_ingredient_update(
      recipeIngredientId: $recipeIngredientId
      recipeIngredient: $recipeIngredient
    ) {
      id
      active
      deleted
    }
  }
`;
// update recipe ingredients
export const DELETE_RECIPE_INGREDIENTS = gql`
  mutation Admin_nutrition_recipe_ingredient_delete($recipeIngredientId: String!) {
    admin_nutrition_recipe_ingredient_delete(recipeIngredientId: $recipeIngredientId)
  }
`;
