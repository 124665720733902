import produce from 'immer';
import {
  getContactInquiryList,
  getContactInquirySearchList,
  contactInquiryActionTaken,
  contactInquiryDelete,
} from './actions';

export const initialState = {
  contactInquiries: [],
  // contactInquirySearchList: [],
  contactInquiryPagination: {},
};

const contactInquiryReducer = produce((draft, action) => {
  switch (action.type) {
    case getContactInquiryList.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.contactInquiries = results;
      draft.contactInquiryPagination = pagination;
      break;
    }

    // case getContactInquirySearchList.SUCCESS: {
    //   const { results } = action.payload;
    //   draft.contactInquirySearchList = results;
    //   break;
    // }

    case contactInquiryActionTaken.SUCCESS: {
      const { contactInquiry } = action.payload;

      const list = Object.assign([], draft.contactInquiries);

      const filteredItems = list.map((item) => {
        return item.id !== contactInquiry.id ? item : contactInquiry;
      });

      draft.contactInquiries = filteredItems;

      break;
    }

    case contactInquiryDelete.SUCCESS: {
      const { contactInquiry } = action.payload;

      const list = Object.assign([], draft.contactInquiries);

      const filteredItems = list.map((item) => {
        return item.id !== contactInquiry.id ? item : contactInquiry;
      });
      draft.contactInquiries = filteredItems;

      break;
    }

    // case '@@router/LOCATION_CHANGE':
    //   return initialState;

    default:
      break;
  }
}, initialState);

export default contactInquiryReducer;
