import { Divider } from '@material-ui/core';
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableError from 'components/TableError';
import { showImageError } from 'containers/blog/helper';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { buildURL, checkIfValueExist, printDate, printSerialNumbers, showImage, titleCaseIfExists } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import {
  ACTIVE_AND_DELETED_ARRAY,
  LANGUAGE_ARRAY_WITH_STRING,
  PRINT_LANGUAGE,
  PRINT_LANGUAGE_COLOR,
  sortBySearchData,
  taxonomyType,
} from '../../../utils/constants';

const TpzTaxonomyListing = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const { query } = router;

  const [taxonomyList, setTaxonomyList] = useState([]);
  const [taxonomyPagination, setTaxonomyPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    sortBy: { value: 'createdDate', label: 'Created Date' },
    language: LANGUAGE_ARRAY_WITH_STRING[0],
    type: !isEmpty(query?.type)
      ? {
          value: query?.type,
        }
      : null,
    name: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [gettaxonomyListRequestDefault, { loading: gettaxonomyListLoadingDefault }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setTaxonomyList(data?.results);
      setTaxonomyPagination(data?.pagination || data?.paginator);
    },
  });
  const [gettaxonomyListRequest, { loading: gettaxonomyListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setTaxonomyList(data?.results);
      setTaxonomyPagination(data?.pagination || data?.paginator);
    },
  });

  const [updateTaxonomyRequest, { loading: updateTaxonomyLoading }] = useRequest({
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success(`Success! Taxonomy has been updated.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${query?.type ? `type=${query?.type}&` : ''}${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    gettaxonomyListRequest({
      body: {
        page,
        size: 50,
        sortOrder: 1,
        language: filters?.language?.value,
        sortBy: checkIfValueExist(filters.sortBy?.value),
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        type: checkIfValueExist(filters.type?.value),
        name: checkIfValueExist(filters.name),
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    gettaxonomyListRequestDefault({
      body: {
        page: 1,
        size: 50,
        sortOrder: 1,
        language: defaultFilters?.language?.value,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        sortBy: checkIfValueExist(defaultFilters.sortBy?.value),
        name: checkIfValueExist(defaultFilters.name),
      },
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const handleUpdateTaxonomy = (data, key) => {
    if (data[key]) {
      updateTaxonomyRequest({
        method: 'PUT',
        url: `/common/admin/taxonomy/${data?.id}`,
        body: {
          [key]: false,
        },
      });
    } else {
      updateTaxonomyRequest({
        method: 'PUT',
        url: `/common/admin/taxonomy/${data?.id}`,
        body: {
          [key]: true,
          ...(key === 'deleted' ? { active: false } : {}),
        },
      });
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={gettaxonomyListLoading || updateTaxonomyLoading || gettaxonomyListLoadingDefault} />
        <Container fluid>
          <Breadcrumbs title="Taxonomy" breadcrumbItem={`TPZ Taxonomy List`} />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Sort By</label>
                    <Select
                      options={sortBySearchData}
                      placeholder="Select sortBy..."
                      value={filters.sortBy}
                      onChange={(value) => setFilters({ ...filters, sortBy: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY_WITH_STRING}
                      value={filters.language}
                      onChange={(value) => setFilters({ ...filters, language: value })}
                    />
                  </div>

                  {isEmpty(query?.type) && (
                    <div className="col-lg-3 mb-4">
                      <label className="form-label">Taxonomy Type</label>
                      <Select
                        options={Object.keys(taxonomyType)
                          ?.filter((e) => e?.toLowerCase() !== taxonomyType?.CALCULATOR_CATEGORY)
                          ?.map((e) => ({
                            label: titleCaseIfExists(e),
                            value: taxonomyType[e],
                          }))}
                        placeholder="Select Taxonomy type..."
                        value={filters.type}
                        onChange={(value) => setFilters({ ...filters, type: value })}
                      />
                    </div>
                  )}

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Name</label>
                    <input
                      className="form-control"
                      placeholder="Enter name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{taxonomyPagination?.totalCount} Taxonomy Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_TPZ_TAXONOMY}>
                  <i className="mdi mdi-plus me-1" />
                  Add Taxonomy
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table  table-borderless ">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">S.No.</th>
                        <th scope="col"></th>
                        <th scope="col">Name/Slug</th>
                        <th scope="col">Type </th>
                        <th scope="col">Translation</th>
                        <th scope="col">Language</th>
                        <th scope="col">Approve Status</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">isPopular</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(taxonomyList) ? (
                        taxonomyList
                          ?.sort((a, b) => a?.order - b?.order)
                          ?.map((data, index) => {
                            return (
                              <tr key={index} className={data?.active ? '' : 'bg-danger text-emphasis-warning'}>
                                <td>{printSerialNumbers(taxonomyPagination)[index]}</td>
                                <td>
                                  {!isEmpty(data?.image) && (
                                    <a href={showImage(data?.image?.original)} rel="noreferrer" target="_blank">
                                      <img
                                        src={showImage(data?.image?.original)}
                                        width={40}
                                        height={40}
                                        onError={(e) => {
                                          showImageError(e);
                                        }}
                                      />
                                    </a>
                                  )}
                                </td>
                                <td>
                                  <Link
                                    to={buildURL(SMKLinks.EDIT_TPZ_TAXONOMY, {
                                      id: data?.id,
                                    })}>
                                    {data?.name}
                                  </Link>
                                  <br />
                                  {data?.slug}
                                </td>
                                <td>{titleCaseIfExists(data?.type)}</td>
                                <td>
                                  {!isEmpty(data.translations) && (
                                    <Link
                                      to={buildURL(SMKLinks.EDIT_TPZ_TAXONOMY, {
                                        id: data?.translations[0]?.id,
                                      })}>
                                      Hindi Translation
                                    </Link>
                                  )}
                                </td>
                                <td>
                                  <Badge className={PRINT_LANGUAGE_COLOR[data?.language]}>
                                    {PRINT_LANGUAGE[data?.language]}
                                  </Badge>
                                </td>
                                <td className="text-center">
                                  <i
                                    className={`fas  ${
                                      data?.approved ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'
                                    }   m-1 pe-3 fs-4`}
                                  />
                                </td>
                                <td>{printDate(data?.createdDate)}</td>
                                <td>
                                  <Badge className={data?.isPopular ? 'bg-success' : 'bg-danger'}>
                                    {data?.isPopular ? 'Yes' : 'No'}
                                  </Badge>
                                </td>

                                <td>
                                  <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                    {data?.active ? 'Yes' : 'No'}
                                  </Badge>
                                </td>
                                <td>
                                  <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                    {data?.deleted ? 'Yes' : 'No'}
                                  </Badge>
                                </td>
                                <td>
                                  <UncontrolledDropdown>
                                    <DropdownToggle href="#" className="card-drop " tag="i">
                                      <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-end">
                                      <DropdownItem onClick={() => handleUpdateTaxonomy(data, 'approved')}>
                                        <i
                                          className={`fas  ${
                                            data?.approved ? 'fa-thumbs-down text-danger' : 'fa-thumbs-up text-primary'
                                          }   m-1 pe-3`}
                                        />
                                        {data?.approved ? 'UnApprove' : 'Approve'}
                                      </DropdownItem>
                                      <DropdownItem onClick={() => handleUpdateTaxonomy(data, 'active')}>
                                        <i
                                          className={`fas  ${
                                            data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                          }   m-1 pe-3`}
                                        />
                                        {data?.active ? 'Disable' : 'Enable (Active)'}
                                      </DropdownItem>
                                      <DropdownItem onClick={() => handleUpdateTaxonomy(data, 'deleted')}>
                                        <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                        {data?.deleted ? 'Undelete' : 'Delete'}
                                      </DropdownItem>
                                      <Divider className="my-2" />

                                      <Link
                                        to={buildURL(SMKLinks.EDIT_TPZ_TAXONOMY, {
                                          id: data?.id,
                                        })}>
                                        <DropdownItem>
                                          <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                          Edit
                                        </DropdownItem>
                                      </Link>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            );
                          })
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={taxonomyPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default TpzTaxonomyListing;
