import { collegeClearCache } from 'containers/college/collegeDetailsHelper';
import { useFormik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Badge, Card, CardBody, Form, FormGroup, Input, Label, Table } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import * as Yup from 'yup';
import Loader from '../../../../components/Loader';
import useRequest from '../../../../hooks/useRequest';
import { collegeDetailSelector } from '../../selectors';
import { COLLEGE_SEAT_CATEGORY, COLLEGE_SEAT_TYPE, CURRENT_YEAR } from '../../../../utils/constants';

const stateSelector = createStructuredSelector({
  collegeDetail: collegeDetailSelector,
});

const SeatMatrix = () => {
  const { collegeDetail } = useSelector(stateSelector);
  const [academicYear, setAcademicYear] = useState({ value: CURRENT_YEAR, label: CURRENT_YEAR });
  const [offerredCourses, setOfferredCourses] = useState([]);

  const [collegeAcademicYearOptions, setCollegeAcademicYearOptions] = useState([]);
  const [updateSeatMatrixRequest, { loading: updateSeatMatrixLoading }] = useRequest({
    url: '/college/admin/edit/updateSeatMatrix',
    method: 'POST',
    onSuccess: (data) => {
      collegeClearCache({
        urlSlug: collegeDetail?.slug,
        collegeId: collegeDetail?.id,
      });
      window.location.reload();
    },
  });

  useEffect(() => {
    if (!isEmpty(collegeDetail)) {
      const { coursesOffered } = collegeDetail;
      setCollegeAcademicYearOptions([...new Set(coursesOffered?.map((e) => e?.academicYear))]);

      let offerredCourses = coursesOffered.filter((t) => t.academicYear === academicYear?.value);
      if (isEmpty(offerredCourses)) {
        offerredCourses = coursesOffered.map((t) => ({ ...t, seatInfo: [], academicYear: academicYear?.value }));
      }
      setOfferredCourses((prev) => offerredCourses);

      const seatMatrix = {};
      offerredCourses
        ?.filter((t) => t.academicYear === academicYear?.value)
        ?.forEach((course) => {
          if (course.seatInfo.length > 0) {
            seatMatrix[course.courseId] = { seatInfo: course?.seatInfo, seatDetails: course?.seatDetails };
          } else {
            seatMatrix[course.courseId] = {
              seatInfo: [
                {
                  type: '',
                  category: '',
                  numberOfSeats: 0,
                  comments: '',
                },
              ],
              seatDetails: '',
            };
          }
        });
      // console.log(seatMatrix);

      formik.setFieldValue('seatMatrix', seatMatrix);
    }
  }, [collegeDetail, academicYear]);

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    enableReinitialize: true,
    initialValues: {
      courseId: '',
      seatMatrix: {},
    },
    onSubmit(values) {
      if (values.seatMatrix?.length < 1 || values.seatMatrix?.length > 10) {
        toast.error('Number of seatMatrix must be between 1 and 10');
        return;
      }
      const data = {
        collegeId: collegeDetail?.id,
        courseId: values.courseId,
        academicYear: academicYear?.value,
        seatInfo: values.seatMatrix[values.courseId]?.seatInfo,
        seatDetails: values.seatMatrix[values.courseId]?.seatDetails,
      };
      // console.log(data);
      updateSeatMatrixRequest({ data });
    },
    validationSchema: Yup.object().shape({
      // seatMatrix: Yup.array().of(
      //   Yup.object().shape({
      //     type: Yup.string().required('Type is required'),
      //     category: Yup.string().required('Category is required'),
      //     numberOfSeats: Yup.number()
      //       .positive()
      //       .required('Number of Seats is required'),
      //   })
      // ),
    }),
  });

  const startYear = new Date().getFullYear() + 1;
  const years = Array.from({ length: 16 }, (_, index) => startYear - index);

  const options = years
    .sort((a, b) => a - b)
    ?.map((year) => ({
      value: year,
      label: year.toString(),
      isYearEdited: collegeAcademicYearOptions?.includes(year),
    }));

  const addRow = (id) => {
    if (!isEmpty(formik.values.seatMatrix[id]) && formik.values.seatMatrix[id].seatInfo.length < 10) {
      const updatedSeatMatrix = {
        ...formik.values.seatMatrix,
        [id]: {
          seatInfo: [
            ...(formik.values.seatMatrix[id].seatInfo || []),
            {
              type: '',
              category: '',
              numberOfSeats: 0,
              comments: '',
            },
          ],
          feeDetails: formik.values.seatMatrix[id].feeDetails,
        },
      };
      formik.setFieldValue('seatMatrix', updatedSeatMatrix);
    } else {
      const updatedSeatMatrix = {
        [id]: {
          seatInfo: [
            ...(formik.values.seatMatrix[id].seatInfo || []),
            {
              type: '',
              category: '',
              numberOfSeats: 0,
              comments: '',
            },
          ],
          feeDetails: '',
        },
      };
      formik.setFieldValue('seatMatrix', updatedSeatMatrix);
    }
  };

  const removeRow = (id, index) => {
    if (formik.values.seatMatrix[id]?.seatInfo?.length > 1) {
      const updatedSeatMatrix = {
        ...formik.values.seatMatrix,
        [id]: {
          seatInfo: formik.values.seatMatrix[id].seatInfo.filter((_, i) => i !== index),
          feeDetails: formik.values.seatMatrix[id].feeDetails,
        },
      };
      formik.setFieldValue('seatMatrix', updatedSeatMatrix);
    }
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateSeatMatrixLoading} />

      <h5 className="mb-4">Seat Matrix</h5>

      <Card>
        <CardBody>
          <h6 className="mb-4 text-end">Available Years: {collegeAcademicYearOptions?.join(',')}</h6>
          <FormGroup className="mb-4">
            <Label htmlFor="year" className="form-label ">
              Seat Matrix
            </Label>
            <div className="mb-5">
              <Select
                placeholder="Please Select Year"
                id="year"
                options={options}
                value={academicYear}
                onChange={(year) => setAcademicYear(year)}
              />
            </div>
            {!isEmpty(academicYear) && (
              <div className="mt-4">
                {!isEmpty(offerredCourses) &&
                  offerredCourses
                    ?.filter((t) => t.academicYear === academicYear?.value)
                    ?.map((elem, index) => (
                      <div className="college-matrix p-4 pb-3 mt-3 mb-4" key={index}>
                        <h5 className="mb-0">
                          {index + 1}.) {elem?.course?.name} -{' '}
                          <span>
                            <Badge className="bg-secondary">{academicYear?.value}</Badge>
                          </span>
                        </h5>
                        <div className="table-responsive mt-2">
                          <Table className="project-list-table table-centered ">
                            <thead>
                              <tr className="table-warning">
                                <th scope="col">Seat Type</th>
                                <th scope="col">Seat Category</th>
                                <th scope="col">Number of Seats</th>
                                <th scope="col">Comments</th>
                                <th scope="col" />
                              </tr>
                            </thead>
                            <tbody>
                              {formik.values.seatMatrix[elem.courseId]?.seatInfo?.map((row, index) => (
                                <tr key={index}>
                                  <td>
                                    <Select
                                      placeholder="Type"
                                      id="type"
                                      options={Object.entries(COLLEGE_SEAT_TYPE).map(([key, value]) => ({
                                        value,
                                        label: key,
                                      }))}
                                      value={Object.entries(COLLEGE_SEAT_TYPE)
                                        .filter(([key, value]) => value === row.type)
                                        .map(([key, value]) => ({
                                          value,
                                          label: key,
                                        }))}
                                      onChange={(type) =>
                                        formik.setFieldValue(
                                          `seatMatrix[${elem.courseId}][seatInfo][${index}].type`,
                                          type.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Select
                                      placeholder="Category"
                                      id="category"
                                      options={Object.entries(COLLEGE_SEAT_CATEGORY).map(([key, value]) => ({
                                        value,
                                        label: key,
                                      }))}
                                      value={Object.entries(COLLEGE_SEAT_CATEGORY)
                                        .filter(([key, value]) => value === row.category)
                                        .map(([key, value]) => ({
                                          value,
                                          label: key,
                                        }))}
                                      onChange={(category) =>
                                        formik.setFieldValue(
                                          `seatMatrix[${elem.courseId}][seatInfo][${index}].category`,
                                          category.value
                                        )
                                      }
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      type="number"
                                      value={row.numberOfSeats}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          `seatMatrix[${elem.courseId}][seatInfo][${index}].numberOfSeats`,
                                          e.target.value
                                        );
                                      }}
                                      placeholder="Number of Seats"
                                    />
                                  </td>
                                  <td>
                                    <Input
                                      type="text"
                                      value={row.comments}
                                      onChange={(e) =>
                                        formik.setFieldValue(
                                          `seatMatrix[${elem.courseId}][seatInfo][${index}].comments`,
                                          e.target.value
                                        )
                                      }
                                      placeholder="Comments"
                                    />
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-danger w-100"
                                      onClick={() => removeRow(elem?.courseId, index)}
                                      type="button">
                                      <i className="bx bx-trash" />{' '}
                                    </button>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan={12} className="p-0">
                                  <button
                                    type="button"
                                    className="btn btn-outline-dark w-100"
                                    onClick={() => {
                                      addRow(elem?.courseId);
                                    }}>
                                    + Add
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        <div className="mt-3">
                          <Label className="mb-2"> Seat Details</Label>
                          <Input
                            type="textarea"
                            rows={3}
                            value={formik.values.seatMatrix[elem.courseId]?.seatDetails}
                            onChange={(e) =>
                              formik.setFieldValue(`seatMatrix[${elem.courseId}][seatDetails]`, e.target.value)
                            }
                            placeholder="Seat Details"
                          />
                        </div>

                        <div className="text-end">
                          <button
                            type="submit"
                            className="btn btn-primary mt-2 mb-4 px-5"
                            onClick={() => {
                              formik.setFieldValue('courseId', elem.courseId);
                              formik.handleSubmit();
                            }}>
                            Submit
                          </button>
                        </div>
                      </div>
                    ))}
              </div>
            )}
            {offerredCourses && <div> Kindly add the courses in Academic Details to proceed</div>}
          </FormGroup>
        </CardBody>
      </Card>
    </Form>
  );
};

export default SeatMatrix;
