import { createRoutine } from 'redux-saga-routines';
import {
  WORKSHEET_SUBJECTS_TOPICS,
  WORKSHEET_CREATE,
  WORKSHEET_LIST,
  WORKSHEET_SEARCH,
  WORKSHEET_DETAIL,
  WORKSHEET_PUBLISH,
  WORKSHEET_DELETE,
  WORKSHEET_REJECT,
  WORKSHEET_CATEGORY_CREATE,
  WORKSHEET_DETAILS_CLEAR,
} from './actionTypes';

export const getWorksheetSubjectTopics = createRoutine(WORKSHEET_SUBJECTS_TOPICS);

export const getWorksheetList = createRoutine(WORKSHEET_LIST);
export const getWorksheetSearchList = createRoutine(WORKSHEET_SEARCH);
export const getWorksheetDetail = createRoutine(WORKSHEET_DETAIL);

export const setWorksheetDelete = createRoutine(WORKSHEET_DELETE);
export const setWorksheetPublish = createRoutine(WORKSHEET_PUBLISH);
export const setWorksheetReject = createRoutine(WORKSHEET_REJECT);

export const createWorksheet = createRoutine(WORKSHEET_CREATE);
export const createWorksheetCategory = createRoutine(WORKSHEET_CATEGORY_CREATE);
export const clearWorksheetDetails = createRoutine(WORKSHEET_DETAILS_CLEAR);
