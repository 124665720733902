import { createRoutine } from 'redux-saga-routines';
import {
  CONTACT_INQUIRY_LIST,
  CONTACT_INQUIRY_SEARCH,
  CONTACT_INQUIRY_ACTION_TAKEN,
  CONTACT_INQUIRY_DELETE,
} from './actionTypes';

export const getContactInquiryList = createRoutine(CONTACT_INQUIRY_LIST);
export const getContactInquirySearchList = createRoutine(CONTACT_INQUIRY_SEARCH);

export const contactInquiryActionTaken = createRoutine(CONTACT_INQUIRY_ACTION_TAKEN);
export const contactInquiryDelete = createRoutine(CONTACT_INQUIRY_DELETE);
