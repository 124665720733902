import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../../store/actions';
import { doLogout } from './actions';
import Loader from '../../components/Loader';

const Logout = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(doLogout.trigger());
  });

  return <Loader isActive />;
};

Logout.propTypes = {};

export default withRouter(connect(null, { logoutUser })(Logout));
