// ACTION TYPES
export const LOCATION_LIST = 'SMK_ADMIN/LOCATION_LIST';
export const ADD_LOCATION = 'SMK_ADMIN/ADD_LOCATION';
export const EDIT_LOCATION = 'SMK_ADMIN/EDIT_LOCATION';
export const GET_LOCATION_DETAIL = 'SMK_ADMIN/GET_LOCATION_DETAIL';

export const GET_COUNTRIES = 'SMK_ADMIN/GET_COUNTRIES';
export const GET_REGIONS = 'SMK_ADMIN/GET_REGIONS';
export const GET_DISTRICTS = 'SMK_ADMIN/GET_DISTRICTS';
export const GET_CITIES = 'SMK_ADMIN/GET_CITIES';
export const GET_SUB_AREAS = 'SMK_ADMIN/GET_SUB_AREAS';
