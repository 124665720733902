import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import Select from 'react-select';
import queryString from 'qs';
import {
  DEFAULT_RANGES,
  DATE_RANGE_LABELS,
  DEFAULT_DATERANGE_VALUES,
  handleSetPrecedingPeriod,
  handleSetSamePeriodLastYear,
  formatMomentAsDateTime,
  printDates,
} from './helpers';

const DateRangeSelector = ({ dateRange, setDateRange, disabled = false, align = false }) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [dateLabel, setDateLabel] = useState(dateRange ? 'Custom' : 'This month');
  const [dateData, setDateData] = useState(DEFAULT_DATERANGE_VALUES);
  const [errors, setErrors] = useState({});
  const componentRef = useRef(null);

  const toggleCalendar = () => {
    if (!disabled) {
      setErrors({});
      setShowCalendar((prev) => !prev);
    }
  };

  const handleDateChange = (startDate, endDate) => {
    if (startDate && endDate) {
      setErrors({});
      setDateLabel('Custom');
      setDateData({ ...dateData, dateRange: { startDate, endDate } });
    } else {
      setErrors({
        dateRange_startDate: !startDate ? 'Please select start date' : '',
        dateRange_endDate: !endDate ? 'Please select end date' : '',
      });
    }
  };

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search, { ignoreQueryPrefix: true });
    if (!isEmpty(queryParams?.start_date) && !isEmpty(queryParams?.end_date)) {
      const savedRange = {
        dateRange: {
          label: queryParams?.label?.replaceAll('_', ' '),
          startDate: queryParams?.start_date,
          endDate: queryParams?.end_date,
        },
      };
      setDateRange(savedRange);
      setDateData(savedRange);
    }
    if (disabled) {
      setDateData(dateRange);
    }
  }, [dateRange, disabled, setDateRange]);

  const handleSetLFLDates = (item, range) => {
    setDateLabel(item?.label);
    handleSetSamePeriodLastYear({ dateRange: { ...range?.range } }, setDateData);
  };

  const handleApply = () => {
    if (isEmpty(errors)) {
      setDateRange(dateData);
      toggleCalendar();
    }
  };

  const handleOutsideClick = (event) => {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    if (window.innerWidth > 768) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  const getSelectOptions = () => DATE_RANGE_LABELS.map((label) => ({ label, value: label }));

  return (
    <div className="dropbottom position-relative" style={{ width: '350px', height: '50px', zIndex: '99' }}>
      <div className="d-flex align-items-center shadow-md border justify-between bg-white rounded-2 px-3">
        <button
          type="button"
          className={`custom-dropdown bg-white ${disabled ? 'disabled' : ''}`}
          onClick={toggleCalendar}>
          <h6 className="my-1">
            <span className="p-1 me-2 fw-500">{dateLabel}:</span>
            <strong>{printDates(dateData?.dateRange?.startDate, dateData?.dateRange?.endDate)}</strong>
          </h6>
        </button>
        <i className="bx bx-chevron-down fs-4" />
      </div>

      {showCalendar && (
        <div
          ref={componentRef}
          className={`date-range-picker-wrapper w-300 shadow-lg mt-2 px-4 ${align ? 'end-0' : ''}`}>
          <div className="d-flex align-items-center justify-between mt-3 mb-4">
            <h6 className="me-3">Select Date Range</h6>
            <Select
              styles={{
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
              onChange={(item) => {
                const rangeObj = DEFAULT_RANGES.find((range) => range.label === item.value);
                setDateLabel(item.value);
                if (item.label === 'Custom') {
                  setDateData({ dateRange: { label: rangeObj.label, startDate: null, endDate: null } });
                } else if (item.label === 'LFL MTD' || item.label === 'LFL YTD') {
                  handleSetLFLDates(item, rangeObj);
                } else if (!isEmpty(rangeObj.range)) {
                  setDateData({
                    dateRange: {
                      label: rangeObj.label,
                      startDate: rangeObj.range.startDate,
                      endDate: rangeObj.range.endDate,
                    },
                  });
                }
              }}
              options={getSelectOptions()}
              value={getSelectOptions().find((opt) => opt.value === dateLabel)}
            />
          </div>
          <RangeDatePicker
            dateFormat="DD-MMM-YYYY"
            maxDate={formatMomentAsDateTime(moment())}
            onChange={(startDate, endDate) =>
              handleDateChange(formatMomentAsDateTime(moment(startDate)), formatMomentAsDateTime(moment(endDate)))
            }
            startDate={dateData?.dateRange?.startDate || null}
            endDate={dateData?.dateRange?.endDate || null}
          />
          {Object.keys(errors).length > 0 && (
            <div className="text-danger mt-2">{errors?.dateRange_startDate || errors?.dateRange_endDate}</div>
          )}
          <div className="text-end pt-2 border-top">
            <button type="button" className="btn border-0 bg-white me-2" onClick={toggleCalendar}>
              Cancel
            </button>
            <button className="btn text-primary" onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangeSelector;
