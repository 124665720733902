import { gql } from '@apollo/client';

// add food items
export const ADD_FOOD_ITEMS = gql`
  mutation Admin_nutrition_foodItem_add($foodItemDTO: CreateFoodItemDTO!) {
    admin_nutrition_foodItem_add(foodItemDTO: $foodItemDTO) {
      id
      active
      deleted
    }
  }
`;

// update food items
export const UPDATE_FOOD_ITEMS = gql`
  mutation Admin_nutrition_foodItem_update($id: String!, $foodItemDTO: CreateFoodItemDTO!) {
    admin_nutrition_foodItem_update(id: $id, foodItemDTO: $foodItemDTO) {
      id
      active
      deleted
    }
  }
`;

// delete food items
export const DELETE_FOOD_ITEMS = gql`
  mutation Admin_nutrition_foodItem_delete($id: String!) {
    admin_nutrition_foodItem_delete(id: $id) {
      id
      active
      deleted
    }
  }
`;
