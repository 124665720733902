// components
import Loader from 'components/Loader';
import TableError from 'components/TableError';
import isEmpty from 'lodash/isEmpty';
import * as moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import {
  ACTIVE_AND_DELETED_ARRAY,
  API_BASE_URL,
  AppSourceEnum,
  SORT_VALUES,
  USER_ROLE,
  YES_AND_NO_ARRAY,
  createdByTypeSelect,
} from 'utils/constants';
import { userSelector } from '../../../common/globalComponents/selectors';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
// helpers
import {
  buildURL,
  checkIfValueExist,
  convertHtmlToText,
  formatInputDate,
  printDateTimeTwoLines,
} from '../../../utils/helper';
// links
import { SMKLinks } from '../../../utils/links';
// actions
import {
  getCities,
  getCountries,
  getRegions,
  getSchoolBoards,
  getSchools,
  getSchoolsGroups,
  getSubAreas,
} from '../actions';
import {
  citiesSelector,
  countriesSelector,
  regionsSelector,
  schoolListSelector,
  schoolsPaginationSelector,
  selectSchoolBoards,
  selectSchoolsGroups,
  subAreasSelector,
} from '../selectors';
import AssignOwnerModal from './modals/AssignOwnerModal';
import { toast } from 'react-toastify';
import config from 'config/config';

const stateSelector = createStructuredSelector({
  schools: schoolListSelector,
  schoolsPagination: schoolsPaginationSelector,

  countries: countriesSelector,
  regions: regionsSelector,
  cities: citiesSelector,
  subAreas: subAreasSelector,
  user: userSelector,
  schoolBoards: selectSchoolBoards,
  schoolsGroups: selectSchoolsGroups,
});

const SchoolsList = () => {
  const dispatch = useDispatch();

  const router = useRouter();
  const history = useHistory();

  const {
    schools,
    schoolsPagination,
    countries,
    regions,
    cities,
    subAreas,
    user,
    schoolBoards,
    schoolsGroups,
  } = useSelector(stateSelector);

  // modals
  const [showUserOwnerModal, setShowUserOwnerModal] = useState(null);
  const [showUserCreatedByModal, setShowUserCreatedByModal] = useState(null);

  const [currentSchoolId, setCurrentSchoolId] = useState(null);
  // const [selectedSchool, setSelectedSchool] = useState(null);

  const [staffUsers, setStaffUsers] = useState([]);
  const [createdByType, setCreatedByType] = useState({
    value: undefined,
    label: 'All',
  });

  const [getStaffUsersRequest, { loading: getStaffUsersLoading }] = useRequest({
    url: `/user/admin/search`,
    body: {
      active: true,
      deleted: false,
      staffOnly: true,
      type: createdByType?.value,
      page: 1,
      size: 500,
      appSource: AppSourceEnum.SCHOOLYKIDS,
      ...SORT_VALUES,
    },
    method: 'POST',
    onSuccess: (data) => {
      setStaffUsers(
        data?.results
          ?.filter((e) => e?.role === USER_ROLE.STAFF)
          ?.map((d) => {
            return {
              ...d,
              label: `${d.firstName} ${d.lastName}\n${d.email}`,
              value: d.id,
            };
          })
      );
    },
    onError: (err) => {
      console.log(`Error occurred: ${err}`);
    },
  });

  useEffect(() => {
    getStaffUsersRequest();
    dispatch(getSchoolBoards.trigger());
    dispatch(
      getSchoolsGroups.trigger({
        page: 1,
        size: 1000,
        sortBy: 'id',
        sortOrder: 0,
        active: true,
        deleted: false,
      })
    );
  }, []);

  useEffect(() => {
    getStaffUsersRequest();
  }, [createdByType]);

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    claimed: { value: null, label: 'All' },
    preschool: { value: false, label: 'No' },
    showDetailedView: { value: null, label: 'All' },
    startDate: formatInputDate(moment().subtract(25, 'years')),
    endDate: formatInputDate(moment()),
    id: undefined,
    name: undefined,
    createdBy: undefined,

    board: undefined,
    school_group: undefined,

    countryId: undefined,
    regionId: undefined,
    cityId: undefined,
    subAreaId: undefined,
  };
  const [filters, setFilters] = useState(defaultFilters);

  const [sort, setSort] = useState(SORT_VALUES);

  useEffect(() => {
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));
  }, []);

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const data = {
      page,
      size: 25,
      ...filters,
      active: checkIfValueExist(filters.active?.value),
      deleted: checkIfValueExist(filters.deleted?.value),
      claimed: checkIfValueExist(filters.claimed?.value),
      showDetailedView: checkIfValueExist(filters.showDetailedView?.value),
      preschool: checkIfValueExist(filters.preschool?.value),
      id: Number(filters?.id),
      // id: filters.schoolId ? parseInt(filters.schoolId, 10) : 0,
      ...sort,
    };
    if (filters.board === '') {
      data.board = undefined;
    } else if (filters.board) {
      data.board = filters.board.slug;
    }

    if (filters.school_group == '') {
      data.school_group = undefined;
    } else if (filters.school_group) {
      data.school_group = filters.school_group.slug;
    }

    if (filters.countryId == '') {
      data.countryId = undefined;
    } else if (filters.countryId) {
      data.countryId = filters.countryId.id;
    }
    if (filters.regionId == '') {
      data.regionId = undefined;
    } else if (filters.regionId) {
      data.regionId = filters.regionId.id;
    }
    if (filters.cityId == '') {
      data.cityId = undefined;
    } else if (filters.cityId) {
      data.cityId = filters.cityId.id;
    }
    if (filters.subAreaId == '') {
      data.subAreaId = undefined;
    } else if (filters.subAreaId) {
      data.subAreaId = filters.subAreaId.id;
    }

    if (filters.createdBy) {
      delete data.createdBy;
      data.userId = filters.createdBy.id;
      data.createdByUser = true;
    }

    dispatch(getSchools.trigger(data));
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);
    setCreatedByType({ value: undefined, label: 'All' });
    setFilters({
      ...defaultFilters,
      id: '',
      name: '',
      createdBy: '',
      countryId: '',
      regionId: '',
      cityId: '',
      subAreaId: '',
      board: '',
      school_group: '',
    });
    dispatch(
      getSchools.trigger({
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        claimed: checkIfValueExist(defaultFilters.claimed?.value),
        showDetailedView: checkIfValueExist(defaultFilters.showDetailedView?.value),
        preschool: checkIfValueExist(defaultFilters.preschool?.value),
        id: defaultFilters.id,
        ...sort,
      })
    );
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const onCreatedBySelect = (createdBy) => {
    if (!isEmpty(createdBy)) {
      setFilters({ ...filters, createdBy });
    }
  };

  const onSchoolsGroupsSelect = (school_group) => {
    if (!isEmpty(school_group)) {
      setFilters({ ...filters, school_group });
    } else {
      setFilters({ ...filters, school_group: undefined });
    }
  };
  const onBoardSelect = (board) => {
    if (!isEmpty(board)) {
      setFilters({ ...filters, board });
    } else {
      setFilters({ ...filters, board: undefined });
    }
  };

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      setFilters({ ...filters, countryId: country });
    } else {
      setFilters({ ...filters, countryId: undefined });
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getCities.trigger({
          level: 3,
          cityOnly: true,
          region: region.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );

      setFilters({ ...filters, regionId: region });
    } else {
      setFilters({ ...filters, regionId: undefined });
    }
  };

  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      dispatch(getSubAreas.trigger({ level: 4, city: city.name, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));

      setFilters({ ...filters, cityId: city });
    } else {
      setFilters({ ...filters, cityId: undefined });
    }
  };

  const onSubAreaSelect = (subArea) => {
    if (!isEmpty(subArea)) {
      setFilters({ ...filters, subAreaId: subArea });
    } else {
      setFilters({ ...filters, subAreaId: undefined });
    }
  };

  useEffect(() => {
    applyFilters();
  }, [sort]);

  const [setSchoolClaimedUnclaimed, { loading: setSchoolClaimedUnclaimedLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      toast.success(`Success!  School ( ${data?.id} ) has been updated ${data?.claimed ? 'claimed' : 'Unclaimed'}`);
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
    },
    onError: (err) => {
      toast.error(err?.message?.message?.replace('_', ' ') || err?.message);
      console.log(`Error occurred: ${err}`);
    },
  });
  const [removeSchoolOwner, { loading: removeSchoolOwnerLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      toast.success(`Success! School ${data?.id} Owner has been removed`);
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
    },
    onError: (err) => {
      toast.error(err?.message?.message?.replace('_', ' ') || err?.message);
      console.log(`Error occurred: ${err}`);
    },
  });

  const handleClaimed = (data) => {
    if (!data?.claimed) {
      setSchoolClaimedUnclaimed({
        url: `/school/admin/mark-claimed/${data?.id}`,
      });
    } else {
      setSchoolClaimedUnclaimed({
        url: `/school/admin/unmark-claimed/${data?.id}`,
      });
    }
  };
  const handleRemoveOwner = (data) => {
    if (!isEmpty(data?.owner)) {
      removeSchoolOwner({
        url: `/school/admin/remove-owner/${data?.id}`,
      });
    }
  };
  return (
    <>
      <div className="page-content">
        <Loader isActive={getStaffUsersLoading || setSchoolClaimedUnclaimedLoading || removeSchoolOwnerLoading} />

        <Container fluid>
          <Breadcrumbs title="Schools" breadcrumbItem="Schools List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Published</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">is PreSchool</label>
                    <Select
                      options={YES_AND_NO_ARRAY}
                      value={filters.preschool}
                      onChange={(value) => setFilters({ ...filters, preschool: value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Claimed</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.claimed}
                      onChange={(value) => setFilters({ ...filters, claimed: value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Show Detailed View</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.showDetailedView}
                      onChange={(value) => setFilters({ ...filters, showDetailedView: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label htmlFor="schoolId" className="form-label">
                      School Id
                    </label>
                    <Input
                      type="number"
                      id="schoolId"
                      placeholder="School Id"
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: e.target.value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">School Name</label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="School Name"
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">School Group </label>
                    <Select
                      id="board"
                      options={schoolsGroups}
                      value={filters.school_group}
                      onChange={onSchoolsGroupsSelect}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Board</label>
                    <Select id="board" options={schoolBoards} value={filters.board} onChange={onBoardSelect} />
                  </div>

                  {user?.entityPermissions?.school?.admin && (
                    <Col md={3}>
                      <FormGroup className="mb-4">
                        <Label for="school_createdByType" className="form-label">
                          Created By Type
                        </Label>
                        <Select
                          id="school_createdByType"
                          options={createdByTypeSelect}
                          value={createdByType}
                          onChange={(value) => setCreatedByType(value)}
                        />
                      </FormGroup>
                    </Col>
                  )}

                  {user?.entityPermissions?.school?.admin && (
                    <Col md={3}>
                      <FormGroup className="mb-4">
                        <Label for="school_createdBy" className="form-label">
                          Created By
                        </Label>
                        <Select
                          id="school_createdBy"
                          options={staffUsers}
                          value={filters.createdBy}
                          onChange={onCreatedBySelect}
                        />
                      </FormGroup>
                    </Col>
                  )}

                  <div className="col-lg-3 mb-4">
                    <label htmlFor="startDate" className="form-label">
                      Created Date - From
                    </label>
                    <Input
                      type="date"
                      id="startDate"
                      placeholder="From Date"
                      value={filters.startDate}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          startDate: formatInputDate(e.target.value),
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label htmlFor="endDate" className="form-label">
                      Created Date - To
                    </label>
                    <Input
                      type="date"
                      id="endDate"
                      placeholder="To Date"
                      value={filters.endDate}
                      max={moment().format('YYYY-MM-DD')}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          endDate: formatInputDate(e.target.value),
                        })
                      }
                    />
                  </div>

                  {/* <div className="col-lg-3 mb-4">
                    <label className="form-label">School</label>

                    <SchoolAutoComplete
                      selectedSchool={selectedSchool}
                      onSelectSchool={(school) => {
                        if (school?.id > 0) {
                          setFilters({ ...filters, schoolId: school.id });
                        } else {
                          const newFilters = { ...filters };
                          delete newFilters.schoolId;
                          setFilters(newFilters);
                        }
                        setSelectedSchool(school);
                      }}
                    />
                  </div> */}
                </div>

                <Row>
                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="school_country" className="form-label">
                        Country
                      </Label>
                      <Select
                        isClearable
                        id="school_country"
                        options={countries}
                        value={filters.countryId}
                        onChange={onCountrySelect}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="school_state" className="form-label  ">
                        Region/State
                      </Label>

                      <Select
                        isClearable
                        id="school_state"
                        placeholder="Select..."
                        value={filters.regionId}
                        onChange={onRegionSelect}
                        options={regions}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="school_city" className="form-label  ">
                        City
                      </Label>
                      <Select
                        isClearable
                        id="school_city"
                        placeholder="Select..."
                        value={filters.cityId}
                        onChange={onCitySelect}
                        options={cities}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="school_area" className="form-label  ">
                        SubArea
                      </Label>
                      <Select
                        isClearable
                        id="school_area"
                        placeholder="Select..."
                        value={filters.subAreaId}
                        onChange={onSubAreaSelect}
                        options={subAreas}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{schoolsPagination?.totalCount} Schools Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                {user?.entityPermissions?.school?.create && (
                  <Link
                    replace
                    className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                    to={SMKLinks.ADD_SCHOOL}>
                    <i className="mdi mdi-plus me-1" />
                    Add School
                  </Link>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">
                          <a
                            onClick={() => {
                              setSort({
                                sortBy: 'id',
                                sortOrder: sort.sortBy === 'id' ? sort.sortOrder * -1 : -1,
                              });
                            }}>
                            #Id
                          </a>
                        </th>
                        <th scope="col" />
                        <th scope="col">Logo </th>
                        <th scope="col">
                          <a
                            onClick={() => {
                              setSort({
                                sortBy: 'name',
                                sortOrder: sort.sortBy === 'name' ? sort.sortOrder * -1 : -1,
                              });
                            }}>
                            School Name
                          </a>
                        </th>
                        <th scope="col">Created By</th>
                        {/* <th scope="col">Owner</th> */}
                        {/* <th scope="col">Country</th> */}
                        <th scope="col">
                          <a
                            onClick={() => {
                              setSort({
                                sortBy: 'createdDate',
                                sortOrder: sort.sortBy === 'createdDate' ? sort.sortOrder * -1 : -1,
                              });
                            }}>
                            Created Date
                          </a>
                        </th>
                        {/* <th scope="col">Reviews</th> */}
                        {/* <th scope="col">Version</th> */}
                        {/* <th scope="col">Claimed</th> */}
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(schools) ? (
                        schools?.map((data) => (
                          <>
                            <tr>
                              <td>{data.id}</td>
                              <td>
                                {data.active ? (
                                  <i title="Active" className=" bx bx-check-shield font-size-24 text-success" />
                                ) : (
                                  <i title="InActive" className=" bx bx-error-alt font-size-24 text-warning ms-1" />
                                )}
                                {data.claimed && (
                                  <i
                                    title="Claimed"
                                    className=" bx bx-shield-quarter  font-size-24 text-primary ms-1"
                                  />
                                )}
                                {data?.address?.location?.latitude && (
                                  <i className=" bx bxs-map-pin font-size-24 text-primary ms-1" />
                                )}
                              </td>
                              <td>
                                {data?.logoUrl ? (
                                  <a target="_blank" href={data?.logoUrl} rel="noreferrer">
                                    <img src={data?.logoUrl} height="35" className="d-block m-auto" />
                                  </a>
                                ) : (
                                  ''
                                )}
                              </td>
                              <td>
                                <div>
                                  <Link
                                    to={buildURL(SMKLinks.EDIT_SCHOOL, {
                                      id: data.id,
                                    })}>
                                    {data.displayName
                                      ? convertHtmlToText(data.displayName)
                                      : convertHtmlToText(data.name)}
                                  </Link>

                                  {!isEmpty(data.slug) && (
                                    <span className="ms-2">
                                      <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={`${config.frontendBaseUrl}/school/${data.slug}`}>
                                        <i className="bx bx-link-external fs-4" />
                                      </a>
                                    </span>
                                  )}
                                </div>

                                {data?.slug}

                                {/* <p className="text-wrap">
                                {data?.address?.subArea?.displayName} {' '} {data?.address?.city?.displayName},{' '}
                                {data?.address?.region?.displayName}, <b>{data?.address?.country?.displayName}</b>
                              </p> */}
                              </td>
                              <td>
                                {!isEmpty(data?.createdBy) && (
                                  <>
                                    {`${data?.createdBy?.firstName} ${data?.createdBy?.lastName}`}
                                    <br />
                                    {data?.createdBy?.email}
                                  </>
                                )}
                              </td>

                              {/* <td>{data?.address?.country?.displayName}</td> */}

                              <td>{printDateTimeTwoLines(data.createdDate)}</td>

                              <td>
                                <Badge className={data.active ? 'bg-success' : 'bg-danger'}>
                                  {data?.active ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                              <td>
                                <Badge className={data.deleted ? 'bg-success' : 'bg-danger'}>
                                  {data?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>

                              <td>
                                <UncontrolledDropdown>
                                  <DropdownToggle href="#" className="card-drop" tag="i">
                                    <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <Link
                                      to={buildURL(SMKLinks.EDIT_SCHOOL, {
                                        id: data.id,
                                      })}>
                                      <DropdownItem>
                                        <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                        Edit
                                      </DropdownItem>
                                    </Link>

                                    {data?.active && (
                                      <>
                                        <DropdownItem onClick={() => handleClaimed(data)}>
                                          <i
                                            className={`fas  ${
                                              data?.claimed ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                            }   m-1 pe-3`}
                                          />
                                          {data?.claimed ? 'Unmark Claimed' : 'Mark Claimed'}
                                        </DropdownItem>

                                        <DropdownItem
                                          onClick={() => {
                                            setShowUserCreatedByModal(true);
                                            setCurrentSchoolId(data?.id);
                                          }}>
                                          <i className="fas fa-user-cog text-dark m-1 pe-3 " />
                                          Assign createdBy
                                        </DropdownItem>
                                      </>
                                    )}

                                    {data?.claimed && data?.active && (
                                      <>
                                        {isEmpty(data?.owner) ? (
                                          <DropdownItem
                                            onClick={() => {
                                              setShowUserOwnerModal(true);
                                              setCurrentSchoolId(data?.id);
                                            }}>
                                            <i className="fas fa-user-cog text-dark m-1 pe-3 " />
                                            Assign owner
                                          </DropdownItem>
                                        ) : (
                                          <DropdownItem onClick={() => handleRemoveOwner(data)}>
                                            <i className="fas fa-user-cog text-danger m-1 pe-3 " />
                                            Remove owner
                                          </DropdownItem>
                                        )}
                                      </>
                                    )}
                                    {/* <DropdownItem href="#" className="text-danger" onClick={() => handleDeleteClick(data)}>
                                      <i className="fas fa-trash m-1 pe-3 " />
                                      {data?.deleted ? "Undelete" : "Delete"}
                                    </DropdownItem> */}
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={2}>
                                {!isEmpty(data?.boards) &&
                                  data?.boards?.map((elem) => {
                                    const curriculumObj = data.academicInfo?.curriculumDetails?.find(
                                      (e) => e?.curriculum === elem?.name
                                    );

                                    return (
                                      <>
                                        <span>
                                          {elem?.name}
                                          {curriculumObj?.affiliationNumber &&
                                            ` - ${curriculumObj?.affiliationNumber}`}{' '}
                                        </span>
                                        {data?.boards?.length > 1 ? <br /> : ''}
                                      </>
                                    );
                                  })}
                              </td>
                              <td colSpan={2}>
                                {!isEmpty(data?.schoolIds) &&
                                  data?.schoolIds?.map((elem, index) => (
                                    <>
                                      {elem?.id} <br /> {elem?.name}
                                    </>
                                  ))}
                              </td>
                              <td>
                                <Link
                                  to={buildURL(SMKLinks.EDIT_USER, {
                                    id: data?.owner?.id,
                                  })}>
                                  {!isEmpty(data?.owner) && (
                                    <>
                                      {`${data?.owner?.firstName} ${data?.owner?.lastName}`}
                                      <br />
                                      {data?.owner?.email}
                                    </>
                                  )}
                                </Link>
                                <br /> <b>{`Owner`}</b>
                              </td>
                              <td>
                                {data.averageRating} ({data.totalReviews}) <br /> <b>{`Reviews`}</b>
                              </td>
                              <td>
                                {data?.version} <br /> <b>{`Version`}</b>
                              </td>
                              <td colSpan={2}>
                                <Badge className={data.claimed ? 'bg-success' : 'bg-danger'}>
                                  {data?.claimed ? 'Yes' : 'No'}
                                </Badge>
                                <br /> <b>{`Claimed`}</b>
                              </td>
                            </tr>
                          </>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <AssignOwnerModal
            handleChangePage={handleChangePage}
            staffUsersList={staffUsers}
            assignCreatedBy={true}
            currentSchoolId={currentSchoolId}
            showModal={showUserCreatedByModal}
            setShowModal={setShowUserCreatedByModal}
          />
          <AssignOwnerModal
            handleChangePage={handleChangePage}
            assignOwner={true}
            currentSchoolId={currentSchoolId}
            showModal={showUserOwnerModal}
            setShowModal={setShowUserOwnerModal}
          />
          {/* <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle me-2" />
                  Load more
                </Link>
              </div>
            </Col>
          </Row> */}

          <Paginate pageInfo={schoolsPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default SchoolsList;
