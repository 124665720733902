import React, { useRef, useState } from 'react';
import { Button, Input } from 'reactstrap';
import confirm from 'reactstrap-confirm';
import './input.css';
const InputSlug = ({ formik, className, onEnabled }) => {
  const first = useRef(null);
  const [disable, setDisable] = useState(true);

  const handleActionInquiry = async () => {
    const result = await confirm({
      title: 'Edit Text',
      message: 'Are you sure, edit this text?',
      confirmText: 'Confirm',
      confirmColor: 'primary',
      cancelColor: 'link text-danger',
    });
    if (result) {
      first.current.blur();
      setDisable(false);

      if (onEnabled) {
        onEnabled(true);
      }
    }
  };

  return (
    <div className="edit">
      <Input
        innerRef={first}
        disabled={disable}
        className={`${className} ${disable ? 'bg-light' : ''}`}
        invalid={!!(formik.touched.slug && formik.errors.slug)}
        {...formik.getFieldProps('slug')}
      />
      <Button onClick={handleActionInquiry}>edit</Button>
    </div>
  );
};

export default InputSlug;
