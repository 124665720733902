import produce from 'immer';
import remove from 'lodash/remove';
import {
  clearWorksheetDetails,
  createWorksheetCategory,
  getWorksheetDetail,
  getWorksheetList,
  getWorksheetSearchList,
  getWorksheetSubjectTopics,
  setWorksheetDelete,
  setWorksheetPublish,
  setWorksheetReject,
} from './actions';

export const initialState = {
  subjects: [],
  topics: [],
  worksheetDetail: {},
  worksheets: [],
  worksheetSearchList: [],
  worksheetPagination: {},
  createCategoryDone: false,
};

const worksheetReducer = produce((draft, action) => {
  switch (action.type) {
    case getWorksheetSubjectTopics.SUCCESS: {
      const { subjects, topics } = action.payload;
      draft.subjects = subjects.map((subject) => ({
        ...subject,
        label: subject.name,
        value: subject.slug,
      }));
      draft.topics = topics.map((topic) => ({
        ...topic,
        label: topic.name,
        value: topic.slug,
      }));
      break;
    }

    case getWorksheetList.SUCCESS: {
      const { results, pagination } = action.payload;
      draft.worksheets = results;
      draft.worksheetPagination = pagination;
      break;
    }

    case getWorksheetSearchList.SUCCESS: {
      const { results } = action.payload;
      draft.worksheetSearchList = results;
      break;
    }

    case getWorksheetDetail.SUCCESS: {
      draft.worksheetDetail = action.payload;
      // const { worksheet } = action.payload;
      // if (worksheet) {
      //   draft.worksheetDetail = worksheet;
      // }
      break;
    }
    case clearWorksheetDetails.TRIGGER: {
      draft.worksheetDetail = {};
      break;
    }

    case setWorksheetDelete.SUCCESS: {
      const { worksheet, detailPage } = action.payload;
      if (!detailPage) {
        draft.worksheets = remove(draft.worksheets, (n) => n.id !== worksheet.id);
      } else {
        draft.worksheetDetail = worksheet;
      }
      break;
    }

    case setWorksheetPublish.SUCCESS: {
      const { worksheet, detailPage } = action.payload;
      if (!detailPage) {
        draft.worksheets = remove(draft.worksheets, (n) => n.id !== worksheet.id);
      } else {
        draft.worksheetDetail = worksheet;
      }
      break;
    }

    case setWorksheetReject.SUCCESS: {
      const { worksheet, detailPage } = action.payload;
      if (!detailPage) {
        draft.worksheets = remove(draft.worksheets, (n) => n.id !== worksheet.id);
      } else {
        draft.worksheetDetail = worksheet;
      }
      break;
    }

    case createWorksheetCategory.TRIGGER: {
      draft.createCategoryDone = false;
      break;
    }

    case createWorksheetCategory.SUCCESS: {
      draft.createCategoryDone = true;
      break;
    }

    // case '@@router/LOCATION_CHANGE':
    //   return initialState;

    default:
      break;
  }
}, initialState);

export default worksheetReducer;
