import { useLazyQuery } from '@apollo/client';
import { useRouter } from 'hooks/useRouter';
import { NUTRITIONS_GRAPHQL_URL } from 'lib/apollo-constant';
import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { urlSlugify } from 'utils/helper';

const SmkAsyncSelectGraphql = (props) => {
  const {
    isClearable = true,
    isValueSlugify = false,
    query,
    queryName,
    filters,
    searchKeyName,
    acceptedKey,
    acceptedValue,
    creatable,
    multi,
    placeholder,
    isDisabled,
    id,
    value,
    onChange,
    onBlur,
    dtoName,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const router = useRouter();

  const [fetchOptions, { loading, data, error }] = useLazyQuery(query, NUTRITIONS_GRAPHQL_URL);

  const handleQuery = (searchValue) => {
    fetchOptions({
      variables: {
        [dtoName]: {
          [searchKeyName]: isValueSlugify ? urlSlugify(searchValue) : searchValue,
          ...filters,
        },
      },
    });
  };

  const promiseOptions = (inputValue) => {
    if (inputValue.length > 2) {
      handleQuery(inputValue);

      return new Promise((resolve) => {
        if (loading) return resolve([]);
        if (error) {
          console.error('GraphQL error:', error);
          return resolve([]);
        }

        const instances = data?.[queryName]?.results?.filter((e) => e?.id !== router?.query?.id) || [];
        const formattedOptions = instances.map((instance) => ({
          ...instance,
          label: instance[acceptedKey] || instance.title,
          value: instance[acceptedValue] || instance.id,
        }));

        resolve(formattedOptions);
      });
    }

    return Promise.resolve([]);
  };

  const handleChange = (newValue) => {
    if (onChange) onChange(newValue);
  };

  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    return newValue;
  };

  const IndicatorSeparator = ({ innerProps }) => (
    <span style={{ alignSelf: 'stretch', backgroundColor: 'transparent', margin: '8px 0', width: 1 }} {...innerProps} />
  );

  const handleBlur = () => {
    if (onBlur) onBlur(id, true);
  };

  return (
    <>
      {creatable ? (
        <AsyncCreatableSelect
          components={{ DropdownIndicator: null, IndicatorSeparator }}
          inputValue={inputValue}
          isClearable={isClearable}
          isMulti={multi}
          onChange={handleChange}
          onBlur={handleBlur}
          onInputChange={handleInputChange}
          loadOptions={promiseOptions}
          placeholder={placeholder}
          value={value || null}
          noOptionsMessage={() => (loading ? 'Loading...' : 'No options')}
          menuPlacement="top"
        />
      ) : (
        <AsyncSelect
          id={id}
          isMulti={multi}
          components={{ DropdownIndicator: null, IndicatorSeparator }}
          isClearable={isClearable}
          onChange={handleChange}
          styles={{
            control: (baseStyles) => ({ ...baseStyles, border: '1px solid #ced4da' }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
          }}
          onBlur={handleBlur}
          loadOptions={promiseOptions}
          placeholder={placeholder}
          isDisabled={isDisabled}
          noOptionsMessage={() => (loading ? 'Loading...' : 'No options')}
          value={value || null}
        />
      )}
    </>
  );
};

export default SmkAsyncSelectGraphql;
