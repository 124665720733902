import { createSelector } from 'reselect';
import { initialState } from './reducer';

export const GLOBAL_STORE_KEY = 'global';

/**
 * Direct selector to the loginPage state domain
 */

const selectGlobalSubStore = (store) => store[GLOBAL_STORE_KEY] || initialState;

/**
 * Other specific selectors
 */

const isLoadingSelector = createSelector(selectGlobalSubStore, (globalState) => globalState.isLoading);
const isLoggedInSelector = createSelector(selectGlobalSubStore, (globalState) => globalState.isLoggedIn);
const userSelector = createSelector(selectGlobalSubStore, (globalState) => globalState.user);

const userSearchListSelector = createSelector(selectGlobalSubStore, (globalState) => globalState.searchUserList);

const showCircularLoaderSelector = createSelector(
  selectGlobalSubStore,
  (globalState) => globalState.showCircularLoader
);

const showLinearLoaderSelector = createSelector(selectGlobalSubStore, (globalState) => globalState.showLinearLoader);

export {
  isLoadingSelector,
  userSelector,
  isLoggedInSelector,
  showCircularLoaderSelector,
  showLinearLoaderSelector,
  userSearchListSelector,
};
