import Loader from 'components/Loader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';
import TextEditor from 'components/TextEditor';
import { useRouter } from 'hooks/useRouter';
import { titleCaseIfExists } from 'utils/helper';

const UpdateSchoolMetaData = () => {
  toast.configure();
  const router = useRouter();
  const history = useHistory();
  const { id: schoolMetaDataID, refId: schoolMetaDataRefID } = useParams();
  const { query } = router;
  const [activeTab, setActiveTab] = useState('1');

  const toggleTabs = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [schoolMetaDataDetails, setSchoolMetaDataDetails] = useState();
  const [schoolMetaDataAttributeDetails, setSchoolMetaDataAttributeDetails] = useState();

  const [getSchoolMetaDataSearchRequest, { loading: getSchoolMetaDataSearchLoading }] = useRequest({
    url: `/school/admin/schoolMetaDataContent/search`,
    method: 'POST',
    onSuccess: (data) => {
      setSchoolMetaDataAttributeDetails(data?.results);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [getSchoolMetaDataRequest, { loading: getSchoolMetaDataLoading }] = useRequest({
    url: `/school/admin/schoolMetaDataContent/${schoolMetaDataID}`,
    method: 'GET',
    onSuccess: (data) => {
      setSchoolMetaDataDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addSchoolMetaDataRequest, { loading: addSchoolMetaDataLoading }] = useRequest({
    url: `/school/admin/schoolMetaDataContent`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.SCHOOL_META_CONTENT;
      history.replace(url);
      toast.success('Success! School meta data has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateSchoolMetaDataRequest, { loading: updateSchoolMetaDataLoading }] = useRequest({
    url: `/school/admin/schoolMetaDataContent/${schoolMetaDataRefID}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.SCHOOL_META_CONTENT;
      history.replace(url);
      toast.success('Success! School meta data has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const getMetaDataValue = (attribute) => {
    return schoolMetaDataAttributeDetails?.find((e) => e?.attribute === attribute)?.value;
  };

  const editInitialValues = () => {
    return {
      active: schoolMetaDataDetails?.active,
      deleted: schoolMetaDataDetails?.deleted,
      refId: schoolMetaDataDetails?.refId,
      slug: schoolMetaDataDetails?.slug,
      about: getMetaDataValue('about'),
      admission: getMetaDataValue('admission'),
      fee: getMetaDataValue('fee'),
      facilities: getMetaDataValue('facilities'),
      syllabus: getMetaDataValue('syllabus'),
      curricular: getMetaDataValue('curricular'),

      summary: getMetaDataValue('summary'),
      description: getMetaDataValue('description'),
      content: getMetaDataValue('content'),
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    refId: '',
    slug: '',
    about: '',
    admission: '',
    fee: '',
    facilities: '',
    curricular: '',
    syllabus: '',
    summary: '',
    description: '',
    content: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(schoolMetaDataID) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: async (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        refId: values?.refId,
        slug: values?.slug,
        attirbuteValues: [
          ...(!isEmpty(values?.about) ? [{ attribute: 'about', value: values.about }] : []),
          ...(!isEmpty(values?.admission) ? [{ attribute: 'admission', value: values?.admission }] : []),
          ...(!isEmpty(values?.fee) ? [{ attribute: 'fee', value: values?.fee }] : []),
          ...(!isEmpty(values?.facilities) ? [{ attribute: 'facilities', value: values?.facilities }] : []),
          ...(!isEmpty(values?.curricular) ? [{ attribute: 'curricular', value: values?.curricular }] : []),
          ...(!isEmpty(values?.syllabus) ? [{ attribute: 'syllabus', value: values?.syllabus }] : []),
          ...(!isEmpty(values?.summary) ? [{ attribute: 'summary', value: values?.summary }] : []),
          ...(!isEmpty(values?.description) ? [{ attribute: 'description', value: values?.description }] : []),
          ...(!isEmpty(values?.content) ? [{ attribute: 'content', value: values?.content }] : []),
        ],
      };

      const updatedData = data?.attirbuteValues?.map((elem) => ({
        ...elem,
        id: schoolMetaDataAttributeDetails?.find((e) => e?.attribute === elem?.attribute)?.id,
      }));

      isEmpty(schoolMetaDataID)
        ? addSchoolMetaDataRequest({
            body: data,
          })
        : updateSchoolMetaDataRequest({
            body: {
              ...data,
              attirbuteValues: updatedData,
            },
          });
    },
    validationSchema: Yup.object().shape({
      refId: Yup.string().required('Please provide Ref ID'),
      slug: Yup.string().required('Please provide slug'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(schoolMetaDataID) && !isEmpty(schoolMetaDataRefID)) {
      getSchoolMetaDataRequest();
      getSchoolMetaDataSearchRequest({
        body: {
          page: 1,
          size: 50,
          sortBy: 'id',
          sortOrder: 0,
          refId: schoolMetaDataRefID,
        },
      });
    }
  }, [schoolMetaDataID, schoolMetaDataRefID]);

  useEffect(() => {
    if (!isEmpty(query?.slug) && !isEmpty(query?.id)) {
      formik.setFieldValue('slug', query?.slug);
      formik.setFieldValue('refId', query?.type + '_' + query?.id);
    }
  }, [query]);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            addSchoolMetaDataLoading ||
            getSchoolMetaDataLoading ||
            updateSchoolMetaDataLoading ||
            getSchoolMetaDataSearchLoading
          }
        />
        <Container fluid>
          <Breadcrumbs
            title="School Meta Data"
            breadcrumbItem={`${isEmpty(schoolMetaDataID) ? 'Add' : 'Edit'}  School Meta Data`}
          />
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="refId" className="form-label  ">
                            Ref ID
                          </Label>
                          <Input
                            disabled={schoolMetaDataID || query?.id}
                            id="refId"
                            name="refId"
                            type="text"
                            className="form-control"
                            placeholder="Ref Id..."
                            invalid={!!(formik.touched.refId && formik.errors.refId)}
                            {...formik.getFieldProps('refId')}
                          />
                          <FormFeedback>{formik.errors.refId}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="slug" className="form-label  ">
                            Slug
                          </Label>
                          <Input
                            disabled={schoolMetaDataID || query?.slug}
                            id="slug"
                            name="slug"
                            type="text"
                            className="form-control"
                            placeholder="slug..."
                            invalid={!!(formik.touched.slug && formik.errors.slug)}
                            {...formik.getFieldProps('slug')}
                          />
                          <FormFeedback>{formik.errors.slug}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <div className="mt-4 pt-3">
                  <Nav tabs>
                    {['About', 'Admission', 'Fee', 'Curricular', 'Syllabus', 'Facilities']?.map((tab, index) => (
                      <NavItem>
                        <NavLink
                          className={activeTab === String(index + 1) ? 'active' : ''}
                          onClick={() => toggleTabs(String(index + 1))}>
                          <div className="d-flex justify-content-between">
                            <div> {tab}</div>

                            {formik.values[tab.toLowerCase()]?.length > 0 && (
                              <i className="bx bx-check fs-4 ms-1 text-success" />
                            )}
                          </div>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    {['about', 'admission', 'fee', 'curricular', 'syllabus', 'facilities']?.map((elem, index) => (
                      <TabPane tabId={String(index + 1)}>
                        <Card>
                          <CardBody>
                            <TextEditor
                              simple
                              height={400}
                              initialValue={formik.values[elem]}
                              onChange={(val) => formik.setFieldValue(elem, val)}
                            />
                          </CardBody>
                        </Card>
                      </TabPane>
                    ))}
                  </TabContent>
                </div>
                <h4>For Listing Pages</h4>
                <div className="mt-4 pt-3">
                  {['summary', 'description', 'content']?.map((elem, index) => (
                    <div>
                      <Card tabId={String(index)}>
                        <CardTitle className="m-3 mb-0 pt-3 h4">{titleCaseIfExists(elem)}</CardTitle>
                        <CardBody>
                          <TextEditor
                            simple
                            height={400}
                            initialValue={formik.values[elem]}
                            onChange={(val) => formik.setFieldValue(elem, val)}
                          />
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </div>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <div>
                      <Button type="submit" color="primary" className="w-100">
                        {isEmpty(schoolMetaDataID) ? 'Add' : 'Update'}
                      </Button>
                    </div>
                    <Button
                      className="w-100 mt-3"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.SCHOOL_META_CONTENT);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div>
                      <CardTitle className="mb-3">Active </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="babyname_active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>

                    <div>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="babyname_deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateSchoolMetaData;
