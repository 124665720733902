// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, UncontrolledCollapse } from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { userSelector } from '../../../common/globalComponents/selectors';
import { useRouter } from '../../../hooks/useRouter';
import { getCollegeDetail, getCollegesMasterData } from '../actions';
import { collegeDetailSelector } from '../selectors';
import CollegeSummaryBlock from './collegeSummaryBlock';
import AdditionalDetails from './components/additionalDetails';
import AdmissionDetails from './components/admissionDetails';
import BasicDetails from './components/basicDetails';
import ContactDetails from './components/contactDetails';
import ExtraCurricularDetails from './components/extraCurricularDetails';
import FacilityInfraDetails from './components/facilityInfraDetails';
import SystemDetails from './components/systemDetails';
import FeesMatrix from './components/feesMatrix';
import SeatMatrix from './components/seatMatrix';
import AcademicDetails from './components/academicDetails';
import CoursesDetails from './components/coursesDetails';
import MedicineCourseType from './components/medicineCourseType';
import DentalCourseType from './components/dentalCourseType';
import NursingCourseType from './components/nursingCourseType';
import ParamedicalCourseType from './components/paramedicalCourseType ';
import HospitalAdministrationCourseType from './components/hospitalAdministrationCourseType';

const stateSelector = createStructuredSelector({
  collegeDetail: collegeDetailSelector,
  user: userSelector,
});

const EditCollege = () => {
  const router = useRouter();
  const { collegeDetail, user } = useSelector(stateSelector);
  const [selectedTab, setSelectedTab] = useState(1);

  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCollegeDetail.trigger({ id }));
    dispatch(getCollegesMasterData.trigger());

    if (router?.query?.section) {
      switch (router.query.section) {
        case 'basic-details':
          setSelectedTab(1);
          break;

        case 'contact-details':
          setSelectedTab(2);
          break;

        case 'admission-details':
          setSelectedTab(3);
          break;

        case 'courses-details':
          setSelectedTab(4);
          break;

        case 'academic-details':
          setSelectedTab(5);
          break;

        case 'infra-details':
          setSelectedTab(6);
          break;

        case 'extra-curricular-details':
          setSelectedTab(7);
          break;

        case 'additional-details':
          setSelectedTab(8);
          break;

        case 'seat-matrix':
          setSelectedTab(9);
          break;

        case 'fees-matrix':
          setSelectedTab(10);
          break;

        case 'system-details':
          setSelectedTab(11);
          break;

        case 'medicine':
          setSelectedTab(21);
          break;

        case 'dental':
          setSelectedTab(22);
          break;

        case 'nursing':
          setSelectedTab(23);
          break;

        case 'paramedical':
          setSelectedTab(24);
          break;

        case 'hospital-admin':
          setSelectedTab(25);
          break;

        default:
          // basic
          setSelectedTab(1);
          break;
      }
    } else {
      // basic
      setSelectedTab(1);
    }
  }, []);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Colleges" breadcrumbItem="Edit College" />

          {!isEmpty(collegeDetail) && <CollegeSummaryBlock />}

          <Row>
            <Col lg={3}>
              <Card>
                <CardBody className="p-4">
                  <div>
                    <h5 className="text-muted">College Details</h5>

                    <ul className="list-unstyled font-weight-medium">
                      <li>
                        <Link
                          to={`/college/edit/${collegeDetail?.id}/basic-details`}
                          replace
                          onClick={() => setSelectedTab(1)}
                          className={`${selectedTab === 1 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Basic Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/college/edit/${collegeDetail?.id}/contact-details`}
                          replace
                          onClick={() => setSelectedTab(2)}
                          className={`${selectedTab === 2 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Contact Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/college/edit/${collegeDetail?.id}/admission-details`}
                          replace
                          onClick={() => setSelectedTab(3)}
                          className={`${selectedTab === 3 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Admission Details
                        </Link>
                      </li>
                      <li id="academic_collapse" style={{ cursor: 'pointer' }}>
                        <div className="d-flex align-items-center justify-content-between">
                          <Link
                            to={`/college/edit/${collegeDetail?.id}/academic-details`}
                            replace
                            onClick={() => setSelectedTab(5)}
                            className={`${selectedTab === 5 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                            <i className="mdi mdi-chevron-right me-1" /> Academic Details
                          </Link>

                          <i class="bx bx-chevron-down text-dark fs-4" />
                        </div>
                      </li>
                      <UncontrolledCollapse toggler="#academic_collapse">
                        <ul className="font-weight-medium pb-3" style={{ listStyle: 'none', paddingLeft: '18px' }}>
                          <li key="courseType_medicine">
                            <Link
                              to={`/college/edit/${collegeDetail?.id}/medicine`}
                              replace
                              onClick={() => setSelectedTab(21)}
                              className={`${selectedTab === 21 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                              <i className="mdi mdi-chevron-right me-1" /> Courses - Medicine
                            </Link>
                          </li>
                          <li key="courseType_dental">
                            <Link
                              to={`/college/edit/${collegeDetail?.id}/dental`}
                              replace
                              onClick={() => setSelectedTab(22)}
                              className={`${selectedTab === 22 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                              <i className="mdi mdi-chevron-right me-1" /> Courses - Dental
                            </Link>
                          </li>
                          <li key="courseType_nursing">
                            <Link
                              to={`/college/edit/${collegeDetail?.id}/nursing`}
                              replace
                              onClick={() => setSelectedTab(23)}
                              className={`${selectedTab === 23 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                              <i className="mdi mdi-chevron-right me-1" /> Courses - Nursing
                            </Link>
                          </li>
                          <li key="courseType_paramedical">
                            <Link
                              to={`/college/edit/${collegeDetail?.id}/paramedical`}
                              replace
                              onClick={() => setSelectedTab(24)}
                              className={`${selectedTab === 24 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                              <i className="mdi mdi-chevron-right me-1" /> Courses - Paramedical
                            </Link>
                          </li>
                          <li key={`courseType_hospital_admin`}>
                            <Link
                              to={`/college/edit/${collegeDetail?.id}/hospital-admin`}
                              replace
                              onClick={() => setSelectedTab(25)}
                              className={`${selectedTab === 25 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                              <i className="mdi mdi-chevron-right me-1" /> Courses - Hospital Administration
                            </Link>
                          </li>
                        </ul>
                      </UncontrolledCollapse>
                      <li>
                        {!isEmpty(collegeDetail?.coursesOffered) ? (
                          <Link
                            to={`/college/edit/${collegeDetail?.id}/courses-details`}
                            replace
                            onClick={() => setSelectedTab(4)}
                            className={`${selectedTab === 4 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                            <i className="mdi mdi-chevron-right me-1" /> Courses Details
                          </Link>
                        ) : (
                          <span className="text-body-tertiary py-2 d-block">
                            <i className="mdi mdi-chevron-right me-1" /> Courses Details
                          </span>
                        )}
                      </li>
                      <li>
                        <Link
                          to={`/college/edit/${collegeDetail?.id}/infra-details`}
                          replace
                          onClick={() => setSelectedTab(6)}
                          className={`${selectedTab === 6 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Facility & Infra Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/college/edit/${collegeDetail?.id}/extra-curricular-details`}
                          replace
                          onClick={() => setSelectedTab(7)}
                          className={`${selectedTab === 7 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Extra Curricular Details
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`/college/edit/${collegeDetail?.id}/additional-details`}
                          replace
                          onClick={() => setSelectedTab(8)}
                          className={`${selectedTab === 8 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                          <i className="mdi mdi-chevron-right me-1" /> Additional Details
                        </Link>
                      </li>
                      <li>
                        {!isEmpty(collegeDetail?.coursesOffered) ? (
                          <Link
                            to={`/college/edit/${collegeDetail?.id}/seat-matrix`}
                            replace
                            onClick={() => setSelectedTab(9)}
                            className={`${selectedTab === 9 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                            <i className="mdi mdi-chevron-right me-1" /> Seat Matrix
                          </Link>
                        ) : (
                          <span className="text-body-tertiary py-2 d-block">
                            <i className="mdi mdi-chevron-right me-1" /> Seat Matrix
                          </span>
                        )}
                      </li>
                      <li>
                        {!isEmpty(collegeDetail?.coursesOffered) ? (
                          <Link
                            to={`/college/edit/${collegeDetail?.id}/fees-matrix`}
                            replace
                            onClick={() => setSelectedTab(10)}
                            className={`${selectedTab === 10 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                            <i className="mdi mdi-chevron-right me-1" /> Fees Matrix
                          </Link>
                        ) : (
                          <span className="text-body-tertiary py-2 d-block">
                            <i className="mdi mdi-chevron-right me-1" /> Fees Matrix
                          </span>
                        )}
                      </li>
                      {user.entityPermissions?.college?.publish && (
                        <li>
                          <Link
                            to={`/college/edit/${collegeDetail?.id}/system-details`}
                            replace
                            onClick={() => setSelectedTab(11)}
                            className={`${selectedTab === 11 ? 'text-primary' : 'text-muted'} py-2 d-block `}>
                            <i className="mdi mdi-chevron-right me-1" /> System Details
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>Additional data goes here, like Google Object mapping, etc...</CardBody>
              </Card>
            </Col>

            {!isEmpty(collegeDetail) && (
              <Col lg={9}>
                {selectedTab === 1 && <BasicDetails />}
                {selectedTab === 2 && <ContactDetails />}
                {selectedTab === 3 && <AdmissionDetails />}
                {selectedTab === 4 && <CoursesDetails />}
                {selectedTab === 5 && <AcademicDetails />}
                {selectedTab === 6 && <FacilityInfraDetails />}
                {selectedTab === 7 && <ExtraCurricularDetails />}
                {selectedTab === 8 && <AdditionalDetails />}
                {selectedTab === 9 && <SeatMatrix />}
                {selectedTab === 10 && <FeesMatrix />}
                {selectedTab === 11 && user.entityPermissions?.college?.publish && <SystemDetails />}
                {selectedTab === 21 && <MedicineCourseType />}
                {selectedTab === 22 && <DentalCourseType />}
                {selectedTab === 23 && <NursingCourseType />}
                {selectedTab === 24 && <ParamedicalCourseType />}
                {selectedTab === 25 && <HospitalAdministrationCourseType />}
              </Col>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EditCollege;
