import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { LANGUAGE_ARRAY_WITH_STRING, LANGUAGE_VALUES_STRING } from 'utils/constants';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';

const UpdateCategory = () => {
  const history = useHistory();
  const { id: categoryId } = useParams();

  toast.configure();

  const [parentCategoryList, setParentCategoryList] = useState();
  const [rootCategoryList, setRootCategoryList] = useState();
  const [categorydetails, setCategorydetails] = useState();

  const [categoryListRequest, { loading: categoryListLoading }] = useRequest({
    method: 'POST',
    url: `/forum/admin/forum-category/search`,
    onSuccess: (data) => {
      if (!isEmpty(data?.results)) {
        setRootCategoryList(
          data?.results
            ?.filter((e) => e?.parentCategory === null)
            ?.map((e) => ({
              ...e,
              value: e?.id,
              label: e?.name,
            }))
        );
        setParentCategoryList(
          data?.results?.map((e) => ({
            ...e,
            value: e?.id,
            label: e?.name,
          }))
        );
      } else {
        setRootCategoryList([]);
        setParentCategoryList([]);
      }
    },
  });

  const [getCategoryRequest, { loading: getCategoryLoading }] = useRequest({
    url: `/forum/admin/forum-category/${categoryId}`,
    method: 'GET',
    onSuccess: (data) => {
      setCategorydetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addCategoryRequest, { loading: addCategoryLoading }] = useRequest({
    url: `/forum/admin/forum-category`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.CATEGORY_LIST;
      history.replace(url);
      toast.success(`Success! Category ( ${data?.id} ) has been added.`);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateCategoryRequest, { loading: updateCategoryLoading }] = useRequest({
    url: `/forum/admin/forum-category/${categoryId}`,
    method: 'PUT',
    onSuccess: (data) => {
      toast.success(`Success! Category ( ${data?.id} ) has been updated.`);
      window.location.reload();
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const formikInitialValues = () => {
    return {
      id: categorydetails?.id,
      active: categorydetails?.active,
      deleted: categorydetails?.deleted,
      name: categorydetails?.name || '',
      slug: categorydetails?.slug || '',
      description: categorydetails?.description || '',
      language: categorydetails?.language,

      parentCategory: categorydetails?.parentCategoryId
        ? parentCategoryList?.find((e) => e?.value === categorydetails?.parentCategoryId)
        : null,

      rootCategory: categorydetails?.rootCategoryId
        ? rootCategoryList?.find((e) => e?.value === categorydetails?.rootCategoryId)
        : null,
    };
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: formikInitialValues(),
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        language: values?.language,
        name: values?.name,
        slug: values?.slug,
        description: values?.description,
        parentCategory: !isEmpty(values?.parentCategory) ? values?.parentCategory : null,
        rootCategory: !isEmpty(values?.rootCategory) ? values?.rootCategory : null,
      };

      categoryId
        ? updateCategoryRequest({
            body: {
              ...data,
            },
          })
        : addCategoryRequest({
            body: {
              ...data,
            },
          });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
      slug: Yup.string().required('Please provide slug'),
    }),
  });

  useEffect(() => {
    if (categoryId) {
      getCategoryRequest();
    }
  }, [categoryId]);

  useEffect(() => {
    if (formik.values.language) {
      categoryListRequest({
        body: {
          page: 1,
          size: 500,
          sortBy: 'id',
          sortOrder: 0,
          language: formik.values.language,
        },
      });
    }
  }, [formik.values.language]);

  return (
    <div className="page-content">
      <Loader isActive={addCategoryLoading || getCategoryLoading || updateCategoryLoading || categoryListLoading} />
      <Container fluid>
        <Breadcrumbs title="Location" breadcrumbItem={`${categoryId ? 'Edit' : 'Add'}  Category`} />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="rootCategory" className="form-label  ">
                          Root Category
                        </Label>
                        <Select
                          id="rootCategory"
                          options={rootCategoryList}
                          value={formik?.values?.rootCategory}
                          onChange={(rootCategory) => formik.setFieldValue('rootCategory', rootCategory)}
                        />
                        <FormFeedback>{formik.errors.rootCategory}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label for="parentCategory" className="form-label  ">
                          Parent Category
                        </Label>
                        <Select
                          id="parentCategory"
                          options={parentCategoryList}
                          value={formik?.values?.parentCategory}
                          onChange={(parentCategory) => formik.setFieldValue('parentCategory', parentCategory)}
                        />
                        <FormFeedback>{formik.errors.parentCategory}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="name" className="form-label  ">
                          Name
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name.."
                          invalid={!!(formik.touched.name && formik.errors.name)}
                          {...formik.getFieldProps('name')}
                        />
                        <FormFeedback>{formik.errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12} style={{ marginBottom: '30px' }}>
                      <SlugInput
                        formik={formik}
                        ID={categoryId}
                        nameValue={formik.values.name}
                        formGroupClassName={'mt-0'}
                      />
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="description" className="form-label  ">
                          Description
                        </Label>
                        <Input
                          id="description"
                          name="description"
                          style={{ height: '100px' }}
                          type="textarea"
                          className="form-control"
                          placeholder="Description.."
                          invalid={!!(formik.touched.description && formik.errors.description)}
                          {...formik.getFieldProps('description')}
                        />
                        <FormFeedback>{formik.errors.description}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div>
                    <Button type="submit" color="primary" className="mb-3 w-100">
                      {categoryId ? 'Save' : 'Add'}
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.CATEGORY_LIST);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </CardBody>
              </Card>

              <Card className="border">
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <CardTitle className="mb-3">Language </CardTitle>
                      {LANGUAGE_ARRAY_WITH_STRING?.map((option, i) => (
                        <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`language_${option?.value}`}
                            name="language"
                            className="form-check-input"
                            checked={formik.values.language === option?.value}
                            onChange={() => {
                              formik.setFieldValue('language', option?.value);
                              formik.setFieldValue('rootCategory', '');
                              formik.setFieldValue('parentCategory', '');
                            }}
                          />
                          <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                            {option?.label}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <CardTitle className="mb-3">Active </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>

                    <Col md={12}>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default UpdateCategory;
