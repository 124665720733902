import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
// components
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import config from '../../../config/config';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { ACTIVE_AND_DELETED_ARRAY, SORT_VALUES } from '../../../utils/constants';
import { buildURL, checkIfValueExist, printSerialNumbers } from '../../../utils/helper';
import { getCollegesCoursesMasterData } from '../actions';
import { selectCollegesCoursesMasterData } from '../selectors';
import { SMKLinks } from 'utils/links';

const stateSelector = createStructuredSelector({
  collegeCoursesMasterData: selectCollegesCoursesMasterData,
});

const CollegeCoursesList = () => {
  const router = useRouter();
  const history = useHistory();
  const dispatch = useDispatch();
  toast.configure();

  const { collegeCoursesMasterData } = useSelector(stateSelector);

  const [collegeCourses, setCollegeCourses] = useState([]);
  const [collegeCoursesPagination, setCollegeCoursesPagination] = useState({});

  const [courseTypeList, setCourseTypeList] = useState([]);
  const [courseLevelsList, setcourseLevelsList] = useState([]);
  const [courseDegreesList, setCourseDegreesList] = useState([]);

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    id: undefined,
    name: '',
    courseType: '',
    courseLevel: '',
    courseDegree: '',
  };
  const [filters, setFilters] = useState(defaultFilters);
  const sort = SORT_VALUES;

  const [getCollegeCoursesRequest, { loading: getCollegeCoursesLoading }] = useRequest({
    url: `/college/admin/course/search`,
    method: 'POST',
    onSuccess: (data) => {
      setCollegeCoursesPagination(data?.pagination);
      setCollegeCourses(data?.results);
    },
  });

  const [deleteCourseRequest, { loading: deleteCourseLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success('Success! College Course has been deleted.');
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const data = {
      page,
      size: 50,
      ...filters,
      active: checkIfValueExist(filters.active?.value),
      deleted: checkIfValueExist(filters.deleted?.value),
      id: checkIfValueExist(filters.id),
      typeId: checkIfValueExist(filters.courseType?.value),
      levelId: checkIfValueExist(filters.courseLevel?.value),
      degreeTypeId: checkIfValueExist(filters.courseDegree?.value),
      courseType: undefined,
      courseLevel: undefined,
      courseDegree: undefined,
      ...sort,
    };

    getCollegeCoursesRequest({
      body: data,
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getCollegeCoursesRequest({
      body: {
        page: 1,
        size: 50,
        ...defaultFilters,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        id: checkIfValueExist(defaultFilters.id),
        name: checkIfValueExist(defaultFilters.name),
        typeId: checkIfValueExist(defaultFilters.courseType?.value),
        levelId: checkIfValueExist(defaultFilters.courseLevel?.value),
        degreeTypeId: checkIfValueExist(defaultFilters.courseDegree?.value),
        courseType: undefined,
        courseLevel: undefined,
        courseDegree: undefined,
        ...sort,
      },
    });
  };

  useEffect(() => {
    applyFilters();
  }, [sort]);

  useEffect(() => {
    dispatch(getCollegesCoursesMasterData.trigger({}));
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  useEffect(() => {
    if (!isEmpty(collegeCoursesMasterData)) {
      setCourseTypeList(
        collegeCoursesMasterData?.map((e) => ({
          label: e.displayName,
          value: e?.id,
        }))
      );
    }
  }, [collegeCoursesMasterData]);

  useEffect(() => {
    if (!isEmpty(filters.courseType)) {
      setFilters({ ...filters, courseLevel: '', courseDegree: '' });
      setcourseLevelsList(
        collegeCoursesMasterData
          ?.find((e) => e?.id === filters?.courseType?.value)
          ?.course_level?.map((e) => ({
            label: e.displayName,
            value: e?.id,
          }))
      );
    }
  }, [filters.courseType]);

  useEffect(() => {
    if (!isEmpty(filters.courseType) && !isEmpty(filters.courseLevel)) {
      setFilters({ ...filters, courseDegree: '' });
      setCourseDegreesList(
        collegeCoursesMasterData
          ?.find((e) => e?.id === filters?.courseType?.value)
          ?.course_level?.find((e) => e?.id === filters?.courseLevel?.value)
          ?.course_degree?.map((e) => ({
            label: e.displayName,
            value: e?.id,
          }))
      );
    }
  }, [filters.courseLevel]);

  const handleDelete = (data) => {
    deleteCourseRequest({
      url: `/college/admin/course/${data?.id}`,
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getCollegeCoursesLoading || deleteCourseLoading} />

        <Container fluid>
          <Breadcrumbs title="Colleges" breadcrumbItem="College Courses List" />

          <Card>
            <CardBody className="mt-4">
              <Row>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label htmlFor="id" className="form-label">
                      Course ID
                    </label>
                    <Input
                      type="text"
                      id="id"
                      placeholder="Course ID"
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label htmlFor="name" className="form-label">
                      Course Name
                    </label>
                    <Input
                      type="text"
                      id="name"
                      placeholder="Course Name"
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label className="form-label">Course Type</label>
                    <Select
                      options={courseTypeList}
                      value={filters.courseType}
                      onChange={(value) => setFilters({ ...filters, courseType: value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label className="form-label">Course Level</label>
                    <Select
                      options={courseLevelsList}
                      value={filters.courseLevel}
                      onChange={(value) => setFilters({ ...filters, courseLevel: value })}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup className="mb-4">
                    <label className="form-label">Course Degree</label>
                    <Select
                      options={courseDegreesList}
                      value={filters.courseDegree}
                      onChange={(value) => setFilters({ ...filters, courseDegree: value })}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {collegeCoursesPagination?.totalCount} Colleges Courses Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_COLLEGE_COURSE}>
                  <i className="mdi mdi-plus me-1" />
                  Add Course
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">Id</th>
                        <th scope="col">Course Name</th>
                        <th scope="col">Course Type</th>
                        <th scope="col">Level Type</th>
                        <th scope="col">Degree Type</th>
                        <th scope="col">Department</th>
                        <th scope="col">Status</th>
                        <th scope="col">Active</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(collegeCourses) ? (
                        collegeCourses?.map((data, index) => (
                          <tr key={index}>
                            <td>{data.id}</td>
                            <td>
                              {data?.active ? (
                                <Link
                                  to={buildURL(SMKLinks.EDIT_COLLEGE_COURSE, {
                                    id: data?.id,
                                  })}>
                                  {data?.name}
                                </Link>
                              ) : (
                                <span>{data?.name}</span>
                              )}
                            </td>
                            <td className="text-nowrap">{data.type.displayName}</td>
                            <td className="text-nowrap">{data.level.displayName}</td>
                            <td className="text-nowrap">{data.degreeType.displayName}</td>
                            <td>{data?.department}</td>
                            <td className="text-nowrap">
                              {data?.active ? (
                                <Badge className="bg-success m-2">Approved</Badge>
                              ) : data?.deleted ? (
                                <>
                                  <Badge className="bg-danger m-2">Rejected</Badge>
                                  <Badge className="bg-light m-2"></Badge>
                                </>
                              ) : (
                                <Badge className="bg-warning m-2">Pending</Badge>
                              )}
                            </td>
                            <td>
                              <Badge className={`${data?.active ? 'bg-success' : 'bg-danger'} mb-2`}>
                                {data?.active ? 'Active' : ' Inactive'}
                              </Badge>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <Link
                                    to={buildURL(SMKLinks.EDIT_COLLEGE_COURSE, {
                                      id: data?.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>

                                  {!data?.deleted && (
                                    <DropdownItem
                                      onClick={() => {
                                        handleDelete(data);
                                      }}>
                                      <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                      Delete
                                    </DropdownItem>
                                  )}
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={collegeCoursesPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default CollegeCoursesList;
