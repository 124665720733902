import { useLazyQuery } from '@apollo/client';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import MiniCards from 'components/MiniCard/mini-card';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';
import map from 'lodash/map';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
} from 'reactstrap';
import { buildURL, showImage } from 'utils/helper';
import { COMMUNITY_GROUPS_GET_BY_ID, COMMUNITY_POSTS_SEARCH } from '../graphql/community.query';
import TableError from 'components/TableError';
import { COMMUNITY_GRAPHQL_URL } from 'lib/apollo-constant';

const CommunityGroupDetails = () => {
  const { query } = useRouter();
  toast.configure();

  const tabsList = ['Post', 'Blocked Comments'];

  const [activeTab, setActiveTab] = useState('1');
  const [groupDetails, setGroupDetails] = useState({});

  const [postsList, setPostsList] = useState([]);

  const [getCommunityDetails, { loading: getCommunityDetailsLoading }] = useLazyQuery(COMMUNITY_GROUPS_GET_BY_ID, {
    ...COMMUNITY_GRAPHQL_URL,
    onCompleted: (data) => {
      if (!isEmpty(data?.admin_community_group_getById)) {
        setGroupDetails(data?.admin_community_group_getById);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });
  const [getPostsRequest, { loading: getPostsRequestLoading }] = useLazyQuery(COMMUNITY_POSTS_SEARCH, {
    ...COMMUNITY_GRAPHQL_URL,
    onCompleted: (data) => {
      if (!isEmpty(data?.community_post_search)) {
        setPostsList(data?.community_post_search);
      }
    },
    onError: () => {
      toast.error('Something went wrong!');
    },
  });

  const miniCards = [
    {
      title: 'Posts',
      text: `${groupDetails?.stats?.postCount} `,
    },
    {
      title: 'Comments',
      text: `${groupDetails?.stats?.commentCount}`,
    },
    {
      title: 'Reactions',
      text: `${groupDetails?.stats?.reactionCount}`,
    },
    {
      title: 'View',
      text: `${groupDetails?.stats?.viewCount}`,
    },
  ];

  useEffect(() => {
    getCommunityDetails({
      variables: {
        groupId: query?.id,
      },
    });
    getPostsRequest({
      variables: {
        searchDTO: {
          page: 1,
          size: 20,
          sortBy: 'id',
          sortOrder: 1,
          groupId: query?.id,
        },
      },
    });
  }, []);

  const showPostList = () => {
    return (
      <div className="table-responsive mt-4" style={{ minHeight: 300 }}>
        <Table className="project-list-table   table-centered table-borderless">
          <thead>
            <tr className="table-warning">
              <th scope="col">S.No.</th>
              <th scope="col">Name</th>
              <th scope="col">Language</th>
              <th scope="col">Active</th>
              <th scope="col">Deleted</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {!isEmpty(postsList?.results) ? (
              postsList?.results?.map((post, index) => {
                return (
                  <tr key={`_test_${post?.name}_`} className={!post?.active ? 'bg-danger' : ''}>
                    <td>{index + 1}</td>
                    <td>
                      {
                        <Link
                          to={buildURL(SMKLinks.COMMUNITY_GROUPS_DETAILS, {
                            id: post?.id,
                          })}>
                          {post?.name}
                        </Link>
                      }
                      <br />
                      {post?.slug}
                    </td>
                    <td>
                      <Badge className={PRINT_LANGUAGE_COLOR[post?.language]}>{PRINT_LANGUAGE[post?.language]}</Badge>
                    </td>
                    <td>
                      <Badge className={post?.active ? 'bg-success' : 'bg-danger'}>{post?.active ? 'Yes' : 'No'}</Badge>
                    </td>
                    <td>
                      <Badge className={post?.deleted ? 'bg-success' : 'bg-danger'}>
                        {post?.deleted ? 'Yes' : 'No'}
                      </Badge>
                    </td>
                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle href="#" className="card-drop" tag="i">
                          <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <Link
                            to={buildURL(SMKLinks.COMMUNITY_GROUPS_EDIT, {
                              id: post?.id,
                            })}>
                            <DropdownItem>
                              <i className="fas fa-pencil-alt text-success m-1 pe-3" />
                              Edit
                            </DropdownItem>
                          </Link>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                );
              })
            ) : (
              <TableError />
            )}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <div className="page-content">
      <Loader isActive={getCommunityDetailsLoading || getPostsRequestLoading} />

      <Container fluid>
        <Breadcrumbs title="Community Group" breadcrumbItem="Community Group Details" />

        <Row>
          <Col xl="4">
            <Card className="overflow-hidden">
              <CardBody className="p-3">
                <Row className="align-items-center">
                  <Col sm="3">
                    <div className="avatar-md profile-user-wid m-auto d-flex align-items-center justify-content-center">
                      {!isEmpty(groupDetails?.image) ? (
                        <img src={showImage(groupDetails?.image?.original)} className="img-thumbnail rounded-circle" />
                      ) : (
                        <i className="bx bxs-message-square-detail" style={{ fontSize: '50px', color: '#3460BB' }} />
                      )}
                    </div>
                  </Col>
                  <Col sm="9">
                    <h5 className="font-size-15 text-truncate mb-1">{groupDetails?.name}</h5>
                    <p className="text-muted mb-0 text-truncate">{groupDetails?.slug}</p>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xl="8">
            <Row>
              {map(miniCards, (card, key) => (
                <MiniCards
                  mdCol={3}
                  title={card?.title}
                  text={card?.text}
                  textColor={card?.textColor}
                  iconClass={card?.iconClass}
                  key={`_card_${key}`}
                  removeIcon={true}
                />
              ))}
            </Row>
          </Col>

          <div>
            <Nav tabs>
              {tabsList?.map((tab, index) => (
                <NavItem>
                  <NavLink
                    className={activeTab === (index + 1).toString() ? 'active' : ''}
                    onClick={() => {
                      setActiveTab((index + 1).toString());
                    }}>
                    {tab}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">{showPostList()}</TabPane>
              <TabPane tabId="2">{showPostList()}</TabPane>
              <TabPane tabId="3">{showPostList()}</TabPane>
            </TabContent>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CommunityGroupDetails;
