import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import {
  ACTIVE_AND_DELETED_ARRAY,
  FAQ_MODULES_SUB_MODULES,
  LANGUAGE_ARRAY_WITH_STRING,
  SORT_VALUES_DATE,
} from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { buildURL, checkIfValueExist, printDate, printSerialNumbers, urlSlugify } from '../../../utils/helper';

const FAQListing = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [faqList, setFaqList] = useState([]);
  const [faqListPagination, setFaqListPagination] = useState({});

  const defaultFilters = {
    active: null,
    deleted: null,
    name: '',
    module: null,
    subModule: null,
    category: null,
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [updateFAQRequest, { loading: updateFAQLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success('FAQ has been updated');
    },
  });

  const [getfaqListRequest, { loading: getfaqListLoading }] = useRequest({
    url: '/cms/admin/faq/search',
    method: 'POST',
    onSuccess: (data) => {
      setFaqList(data?.results);
      setFaqListPagination(data?.pagination);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const payload = {
      page,
      size: 20,
      ...SORT_VALUES_DATE,
      ...filters,
      active: checkIfValueExist(filters?.active),
      deleted: checkIfValueExist(filters?.deleted),
      language: checkIfValueExist(filters?.language),
      name: checkIfValueExist(filters?.name),
      module: checkIfValueExist(filters?.module?.value),
      subModule: checkIfValueExist(filters?.subModule?.value),
      category: checkIfValueExist(filters?.category?.value),
    };
    getfaqListRequest({
      body: payload,
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    const defaultPayload = {
      page: 1,
      size: 20,
      ...SORT_VALUES_DATE,
      ...defaultFilters,
      active: checkIfValueExist(defaultFilters?.active),
      deleted: checkIfValueExist(defaultFilters?.deleted),
      language: checkIfValueExist(defaultFilters?.language),
      module: checkIfValueExist(defaultFilters?.module?.value),
      subModule: checkIfValueExist(defaultFilters?.subModule?.value),
      category: checkIfValueExist(defaultFilters?.category?.value),
    };
    getfaqListRequest({
      body: defaultPayload,
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const handleDeleteFAQ = (data) => {
    updateFAQRequest({
      url: `/cms/admin/faq/${data?.id}`,
      data: {
        ...data,
        deleted: !data?.deleted,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getfaqListLoading || updateFAQLoading} />

        <Container fluid>
          <Breadcrumbs title="FAQ" breadcrumbItem="FAQs List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <Row>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={ACTIVE_AND_DELETED_ARRAY?.find((e) => e?.value === filters?.active) || null}
                      placeholder="Select Status..."
                      onChange={(e) => setFilters({ ...filters, active: e?.value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={ACTIVE_AND_DELETED_ARRAY?.find((e) => e?.value === filters?.deleted) || null}
                      placeholder="Select Status..."
                      onChange={(e) => setFilters({ ...filters, deleted: e?.value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY_WITH_STRING}
                      value={LANGUAGE_ARRAY_WITH_STRING?.find((e) => e?.value === filters?.language) || null}
                      onChange={(e) => setFilters({ ...filters, language: e?.value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Module</label>
                    <Select
                      options={FAQ_MODULES_SUB_MODULES?.map((e) => ({
                        label: e.name,
                        value: urlSlugify(e.name),
                      }))}
                      value={filters?.module}
                      onChange={(e) => setFilters({ ...filters, module: e })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">SubModule</label>
                    <Select
                      options={FAQ_MODULES_SUB_MODULES?.find(
                        (e) => urlSlugify(e?.name) === filters.module?.value
                      )?.submodules?.map((e) => ({
                        label: e,
                        value: urlSlugify(e),
                      }))}
                      value={filters?.subModule}
                      onChange={(e) => setFilters({ ...filters, subModule: e })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Category</label>
                    <Select
                      options={FAQ_MODULES_SUB_MODULES?.find(
                        (e) => urlSlugify(e?.name) === filters.module?.value
                      )?.categories?.map((e) => ({
                        label: e,
                        value: urlSlugify(e),
                      }))}
                      value={filters?.category}
                      onChange={(e) => setFilters({ ...filters, category: e })}
                    />
                  </div>

                  <div className="col-lg-4 mb-4">
                    <label className="form-label">Name</label>
                    <Input
                      type="text"
                      placeholder="FAQ Name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e?.target?.value })}
                    />
                  </div>
                </Row>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{faqListPagination?.totalCount || 0} faqs Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.FAQ_ADD}>
                  <i className="mdi mdi-plus me-1" />
                  Add FAQ
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '60px' }}>
                          S.No.
                        </th>
                        <th scope="col">Title/Slug</th>
                        <th scope="col">Module</th>
                        <th scope="col">Sub Module</th>
                        <th scope="col">Category</th>
                        <th scope="col">Language</th>
                        <th scope="col">Updated Date</th>
                        <th scope="col">isDynamic</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(faqList) ? (
                        faqList?.map((elem, index) => (
                          <tr key={`_temp_${elem.id}_`} className={elem?.deleted ? 'bg-danger' : ''}>
                            <td>{printSerialNumbers(faqListPagination)[index]}</td>
                            <td>
                              {
                                <Link
                                  to={buildURL(SMKLinks.FAQ_EDIT, {
                                    id: elem?.id,
                                  })}>
                                  {elem?.title}
                                </Link>
                              }
                              <br />
                              {elem?.slug}
                            </td>
                            <td>{elem?.module}</td>
                            <td>{elem?.subModule}</td>
                            <td>{elem?.category}</td>
                            <td>{elem?.language === 'en' ? 'English' : 'Hindi'}</td>
                            <td>{printDate(elem?.updatedDate)}</td>
                            <td>
                              <Badge className={elem?.isDynamic ? 'bg-success' : 'bg-danger'}>
                                {elem?.isDynamic ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={elem?.active ? 'bg-success' : 'bg-danger'}>
                                {elem?.active ? 'Active' : 'Disabled'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={elem?.deleted ? 'bg-success' : 'bg-danger'}>
                                {elem?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td className="text-center">
                              <UncontrolledDropdown>
                                <DropdownToggle className="card-drop" tag="a">
                                  <i className="mdi mdi-dots-vertical font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem className="border-bottom">
                                    <Link
                                      to={buildURL(SMKLinks.FAQ_EDIT, {
                                        id: elem?.id,
                                      })}>
                                      <div>
                                        <i className="mdi mdi-pencil font-size-16 text-success me-1" />{' '}
                                        <span style={{ color: '#212529' }}>Edit</span>
                                      </div>
                                    </Link>
                                  </DropdownItem>

                                  <DropdownItem onClick={() => handleDeleteFAQ(elem)}>
                                    <div>
                                      <i
                                        className={`mdi mdi-trash-can-outline font-size-16 ${
                                          elem?.deleted ? 'text-success' : 'text-danger'
                                        } me-1`}
                                      />{' '}
                                      <span style={{ color: '#212529' }}>{elem?.deleted ? 'UnDelete' : 'Delete'}</span>
                                    </div>
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {!isEmpty(faqList) && <Paginate pageInfo={faqListPagination} onChangePage={handleChangePage} />}
        </Container>
      </div>
    </>
  );
};

export default FAQListing;
