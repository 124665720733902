import { gql } from '@apollo/client';

export const FOOD_ITEMS_SEARCH = gql`
  query Admin_nutrition_foodItem_search($searchDTO: SearchFoodItemDTO!) {
    admin_nutrition_foodItem_search(searchDTO: $searchDTO) {
      success
      results {
        id
        deleted
        active
        createdDate
        updatedDate
        approved
        approvedDate
        name
        slug
        language
        description
        isAllergen
        substitutes
        nutritions
        translations
        order
        isSystemDefined
        createdBy {
          displayName
          designation
          bio
        }
        updatedBy {
          displayName
          designation
          bio
        }
        image {
          name
          type
          size
          filename
          forType
          original
          thumbnailSmall
          thumbnailMedium
          thumbnailLarge
        }
        type {
          name
          slug
          language
          description
        }
      }
      pagination {
        totalCount
        currentPage
        size
      }
    }
  }
`;

export const FOOD_ITEMS_GET_BY_ID = gql`
  query Admin_nutrition_foodItem_getById($id: String!) {
    admin_nutrition_foodItem_getById(id: $id) {
      id
      deleted
      active
      createdById
      updatedById
      createdDate
      updatedDate
      notes
      approved
      approvedDate
      approvedById
      typeId
      name
      slug
      language
      description
      imageId
      isAllergen
      substitutes
      nutritions
      translations
      order
      isSystemDefined
      createdBy {
        displayName
        designation
        bio
      }
      updatedBy {
        displayName
        designation
        bio
      }
      type {
        deleted
        active
        name
        slug
        language
      }
      image {
        name
        type
        filename
        forType
        original
        thumbnailSmall
        thumbnailMedium
        thumbnailLarge
      }
    }
  }
`;
