import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import Select from 'react-select';

import { buildURL, checkIfValueExist, printDate } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import { Divider } from '@material-ui/core';
import { toast } from 'react-toastify';
import TableError from 'components/TableError';
import { filter, invert, isEmpty, isMap } from 'lodash';
import Loader from '../../../components/Loader';
import { useRouter } from '../../../hooks/useRouter';
import useRequest from '../../../hooks/useRequest';
import Paginate from '../../../components/Paginate';
import { ACTIVE_AND_DELETED_ARRAY, LANGUAGE_VALUES, sortBySearchData, taxonomyType } from '../../../utils/constants';

const VaccinationListing = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [vaccinationList, setvaccinationList] = useState([]);
  const [vaccinationPagination, setvaccinationPagination] = useState({});

  // filters states
  const [regionsList, setregionsList] = useState([]);
  const [ageGroupsList, setageGroupsList] = useState([]);

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    sortBy: { value: 'createdDate', label: 'Created Date' },
    regionId: null,
    ageGroupId: null,
  };

  const [filters, setFilters] = useState(defaultFilters);

  // Dropdown Api Call
  const [getregionsListRequest, { loading: getregionsListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,

    method: 'POST',
    onSuccess: (data) => {
      setregionsList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getageGroupsListRequest, { loading: getageGroupsListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,

    method: 'POST',
    onSuccess: (data) => {
      setageGroupsList(
        data?.results
          ?.sort((a, b) => a.metaData?.value - b.metaData?.value)
          ?.map((e) => ({
            label: e.name,
            value: e.id,
          }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getvaccinationListRequest, { loading: getvaccinationListLoading }] = useRequest({
    url: `/vaccination/admin/search`,

    method: 'POST',
    onSuccess: (data) => {
      setvaccinationList(data?.results);
      setvaccinationPagination(data?.pagination);
    },
  });

  const [updateVaccinationRequest, { loading: updateVaccinationLoading }] = useRequest({
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success(`Success! Vaccination has been updated.`);
    },
  });

  const setPageInUrl = (page, regionId) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}${regionId ? `&regionId=${regionId}` : ''}`,
    });
  };

  const handleChangePage = (page, regionId) => {
    setPageInUrl(page, checkIfValueExist(filters.regionId?.value) || regionId);
    getvaccinationListRequest({
      body: {
        page,
        size: 100,
        sortOrder: 1,
        sortBy: checkIfValueExist(filters.sortBy?.value),
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        regionId: checkIfValueExist(filters.regionId?.value) || regionId,
        ageGroupId: checkIfValueExist(filters.ageGroupId?.value),
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);
    setFilters({ ...defaultFilters });
    getvaccinationListRequest({
      body: {
        page: 1,
        size: 100,
        sortOrder: 1,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        sortBy: checkIfValueExist(defaultFilters.sortBy?.value),
        regionId: checkIfValueExist(defaultFilters.regionId?.value),
        ageGroupId: checkIfValueExist(defaultFilters.ageGroupId?.value),
      },
    });
  };

  //  Api calls
  useEffect(() => {
    const searchPayload = {
      page: 1,
      size: 1000,
      sortBy: 'name',
      sortOrder: 0,
    };
    getregionsListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.VACCINATION_COUNTRY,
      },
    });
    getageGroupsListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.AGE_GROUP,
      },
    });

    handleChangePage(parseInt(router?.query?.page, 10) || 1, router?.query?.regionId);
  }, []);

  useEffect(() => {
    if (!isEmpty(router?.query?.regionId) && !isEmpty(regionsList)) {
      const regionObj = regionsList?.find((region) => region.value === router?.query?.regionId);
      setFilters({ ...filters, regionId: regionObj });
    } else if (!isEmpty(regionsList)) {
      setFilters((filters) => ({
        ...filters,
        regionId: regionsList?.find((region) => region.label === 'India'),
      }));
      handleChangePage(1, regionsList?.find((region) => region.label === 'India')?.value);
    }
  }, [regionsList, router?.query?.regionId]);

  const handleUpdateVaccination = (data, key) => {
    if (data[key]) {
      updateVaccinationRequest({
        method: 'PUT',
        url: `/vaccination/admin/${data?.id}`,
        body: {
          [key]: false,
        },
      });
    } else {
      updateVaccinationRequest({
        method: key === 'deleted' ? 'DELETE' : 'PUT',
        url: `/vaccination/admin/${data?.id}`,
        body: {
          [key]: true,
        },
      });
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getvaccinationListLoading || updateVaccinationLoading || getregionsListLoading || getageGroupsListLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="Vaccinations" breadcrumbItem="Vaccinations List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Sort By</label>
                    <Select
                      options={sortBySearchData}
                      placeholder="Select sortBy..."
                      value={filters.sortBy}
                      onChange={(value) => setFilters({ ...filters, sortBy: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Regions</label>
                    <Select
                      options={regionsList}
                      placeholder="Select regions..."
                      value={filters.regionId}
                      onChange={(region) => {
                        setFilters({ ...filters, regionId: region });
                      }}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">AgeGroup</label>
                    <Select
                      options={ageGroupsList}
                      placeholder="Select Age Group"
                      value={filters.ageGroupId}
                      onChange={(value) => setFilters({ ...filters, ageGroupId: value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {vaccinationPagination?.totalCount} Vaccinations Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_VACCINATION}>
                  <i className="mdi mdi-plus me-1" />
                  Add Vaccination
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table  table-borderless ">
                    <thead>
                      <tr className="table-warning">
                        {/* <th scope="col">ID</th> */}
                        <th scope="col"></th>
                        <th scope="col">Title/slug</th>
                        <th scope="col">Approved status</th>
                        <th scope="col">Vaccination usual site</th>
                        <th scope="col">Age groups</th>
                        <th scope="col">Vaccination against</th>
                        <th scope="col">Region</th>
                        <th scope="col">Order</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Language</th>
                        <th scope="col">hasArticles</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(vaccinationList) ? (
                        vaccinationList
                          ?.slice()
                          ?.sort((a, b) => {
                            return (a?.ageGroups[0]?.metaData?.value || 0) - (b?.ageGroups[0]?.metaData?.value || 0);
                          })
                          ?.map((data, index) => {
                            return (
                              <>
                                <tr className={data?.deleted || !data?.active ? 'bg-danger' : ''}>
                                  {/* <td>{data?.id}</td> */}
                                  <td>
                                    {data?.image && (
                                      <a href={data?.image} target="_blank">
                                        <img src={data?.image} width={40} height={40} />
                                      </a>
                                    )}
                                  </td>
                                  <td style={{ minWidth: 200 }}>
                                    <Link
                                      to={buildURL(SMKLinks.EDIT_VACCINATION, {
                                        id: data?.id,
                                      })}>
                                      {data?.title}
                                    </Link>
                                    <br />
                                    {data?.slug}
                                  </td>
                                  <td align="center">
                                    <i
                                      className={`fas  ${
                                        data?.approved ? 'fa-check-circle text-success' : 'fa-times-circle text-danger'
                                      }   m-1 pe-3 fs-4`}
                                    />
                                  </td>
                                  <td>
                                    <Badge className="p-2 bg-light">{data?.vaccinationUsualSite}</Badge>
                                  </td>
                                  <td>
                                    {data?.ageGroups
                                      ?.slice()
                                      ?.sort((a, b) => {
                                        return (a?.metaData?.value || 0) - (b?.metaData?.value || 0);
                                      })
                                      ?.map((e) => (
                                        <Badge className="p-2 bg-light m-1">{e?.name}</Badge>
                                      ))}
                                  </td>
                                  <td>
                                    {data?.vaccinationAgainst?.map((e) => (
                                      <Badge className="p-2 bg-light m-1">{e?.name}</Badge>
                                    ))}
                                  </td>
                                  <td>{data?.region?.name}</td>

                                  <td>{data?.order}</td>
                                  <td>{printDate(data?.createdDate)}</td>

                                  <td>
                                    <Badge
                                      className={`${data?.language == 0 ? 'bg-info' : 'bg-warning'} p-1`}
                                      style={{ fontSize: '10px' }}>
                                      {LANGUAGE_VALUES[data?.language]}
                                    </Badge>
                                  </td>
                                  <td>
                                    <Badge className={data?.hasArticles ? 'bg-success' : 'bg-danger'}>
                                      {data?.hasArticles ? 'Yes' : 'No'}
                                    </Badge>
                                  </td>

                                  <td>
                                    <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                      {data?.active ? 'Yes' : 'No'}
                                    </Badge>
                                  </td>
                                  <td>
                                    <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                      {data?.deleted ? 'Yes' : 'No'}
                                    </Badge>
                                  </td>
                                  <td>
                                    <UncontrolledDropdown>
                                      <DropdownToggle href="#" className="card-drop " tag="i">
                                        <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                      </DropdownToggle>
                                      <DropdownMenu className="dropdown-menu-end">
                                        <DropdownItem onClick={() => handleUpdateVaccination(data, 'approved')}>
                                          <i
                                            className={`fas  ${
                                              data?.approved
                                                ? 'fa-thumbs-down text-danger'
                                                : 'fa-thumbs-up text-primary'
                                            }   m-1 pe-3`}
                                          />
                                          {data?.approved ? 'UnApprove' : 'Approve'}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => handleUpdateVaccination(data, 'active')}>
                                          <i
                                            className={`fas  ${
                                              data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                            }   m-1 pe-3`}
                                          />
                                          {data?.active ? 'Disable' : 'Enable (Active)'}
                                        </DropdownItem>
                                        <DropdownItem onClick={() => handleUpdateVaccination(data, 'deleted')}>
                                          <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                          {data?.deleted ? 'Undelete' : 'Delete'}
                                        </DropdownItem>
                                        <Divider className="my-2" />

                                        <Link
                                          to={buildURL(SMKLinks.EDIT_VACCINATION, {
                                            id: data?.id,
                                          })}>
                                          <DropdownItem>
                                            <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                            Edit
                                          </DropdownItem>
                                        </Link>
                                        <Link to={`/tpz/vaccination/add?duplicateId=${data?.id}`}>
                                          <DropdownItem>
                                            <i className="fas fa-plus text-success m-1 pe-3 " />
                                            Duplicate
                                          </DropdownItem>
                                        </Link>
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>

                                {(!isEmpty(data?.vaccineBrand) || !isEmpty(data?.description)) && (
                                  <tr className={data?.deleted || !data?.active ? 'bg-danger' : ''}>
                                    <td colSpan={20}>
                                      <b>Vaccine brand : </b> {data?.vaccineBrand}
                                      <br />
                                      <div className="mt-2">
                                        <b>Description : </b> {data?.description}
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            );
                          })
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={vaccinationPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default VaccinationListing;
