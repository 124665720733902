import Loader from 'components/Loader';
import { useFormik } from 'formik';
import useRequest from 'hooks/useRequest';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { useRouter } from 'hooks/useRouter';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { API_BASE_URL } from 'utils/constants';

const AssignOwnerModal = ({
  handleChangePage,
  assignOwner,
  assignCreatedBy,
  showModal,
  setShowModal,
  selectedOwner,
  currentSchoolId,
  staffUsersList,
}) => {
  toast.configure();
  const router = useRouter();

  const [setSchoolOwnerApiCall, { loading: setSchoolOwnerLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      toast.success(
        `Success! Assign ${assignOwner ? 'owner' : assignCreatedBy ? 'createdBy' : ''} has been added on ${data?.id}`
      );
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      setShowModal(false);
    },
    onError: (err) => {
      toast.error(err?.message?.message?.replace('_', ' ') || err?.message);
      console.log(`Error occurred: ${err}`);
    },
  });

  const formik = useFormik({
    initialValues: {
      createdBy: '',
      ownerId: '',
    },
    onSubmit: (values, { resetForm }) => {
      if (assignOwner) {
        setSchoolOwnerApiCall({
          url: `/school/admin/assign-owner/${currentSchoolId}/${values?.ownerId}`,
        });
      }
      if (assignCreatedBy) {
        setSchoolOwnerApiCall({
          url: `/school/admin/assign-createdBy/${currentSchoolId}/${
            !isEmpty(values?.createdBy) ? values?.createdBy?.id : values?.ownerId
          }`,
        });
      }
      resetForm();
    },
    validationSchema: () => {
      return Yup.object().shape({
        createdBy: Yup.object()
          .nullable()
          .test('createdBy-ownerId-test', 'Please select a staff user', function(value) {
            const { ownerId } = this.parent;
            if (assignCreatedBy) {
              return !isEmpty(ownerId) || !isEmpty(value);
            }
            return true;
          }),
        ownerId: Yup.string()
          .nullable()
          .test('ownerId-createdBy-test', 'Please enter other user ID', function(value) {
            const { createdBy } = this.parent;
            if (!assignCreatedBy) {
              return !isEmpty(createdBy) || !isEmpty(value);
            }
            return true;
          }),
      });
    },
  });

  useEffect(() => {
    if (showModal) {
      formik.setFieldValue('createdBy', '');
      formik.setFieldValue('ownerId', '');
    }
  }, [showModal]);

  return (
    <>
      <Loader isActive={setSchoolOwnerLoading} />
      <Modal
        isOpen={showModal}
        role="dialog"
        autoFocus
        centered
        className="exampleModal"
        tabIndex="-1"
        toggle={() => {
          setShowModal(false);
        }}>
        <div className="modal-content">
          <ModalHeader
            className="modalHeader"
            toggle={() => {
              setShowModal(false);
            }}>
            Select Owner
          </ModalHeader>
          <ModalBody>
            <Form onSubmit={formik.handleSubmit}>
              {assignCreatedBy && (
                <FormGroup className="mb-3">
                  <Label className="form-label "> Staff Users</Label>
                  <Select
                    styles={{
                      menu: (provided) => ({ ...provided, zIndex: 999 }),
                    }}
                    id="createdBy"
                    options={staffUsersList}
                    value={formik.values.createdBy}
                    onChange={(e) => formik.setFieldValue('createdBy', e)}
                  />
                  {formik.touched.createdBy && (
                    <div className="invalid-feedback d-block">{formik.errors.createdBy}</div>
                  )}
                </FormGroup>
              )}

              <FormGroup className="my-3">
                <Label className="form-label ">Search by user email</Label>
                <SmkAsyncSelect
                  acceptedKey={'displayName'}
                  acceptedValue={'id'}
                  placeholder="Type user email.."
                  onChange={(res) => {
                    formik.setFieldValue('ownerId', res?.id);
                  }}
                  fetchUrl={`${API_BASE_URL}/user/admin/search`}
                  filters={{
                    autoComplete: true,
                    page: 1,
                    size: 10,
                    sortBy: 'id',
                    sortOrder: 0,
                    appSource: 'schoolmykids',
                  }}
                  searchKeyName="email"
                  value={formik.values.ownerId}
                />

                {formik.touched.ownerId && <div className="invalid-feedback d-block">{formik.errors.ownerId}</div>}
              </FormGroup>

              <div className="d-flex justify-content-end mt-4 mb-3">
                <Button type="submit" color="success">
                  Save
                </Button>
              </div>
            </Form>
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};

export default AssignOwnerModal;
