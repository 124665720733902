// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import Loader from '../../../components/Loader';
import useRequest from '../../../hooks/useRequest';
import { printDate } from '../../../utils/helper';

const PropertyMasterList = (props) => {
  const [ptable, setPtable] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPropertyEdit, setShowPropertyEdit] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const [getPtablePropertyMasterListRequest, { loading: getPtablePropertyMasterListLoading }] = useRequest({
    url: `/ptable/admin/getPropertyMaster`,
    method: 'GET',
    onSuccess: (data) => {
      // console.log(data);
      setPtable(data?.sort((a, b) => a.property_key.localeCompare(b.property_key)));
    },
  });

  const [putPropertyMasterListRequest, { loading: putPropertyMasterListLoading }] = useRequest({
    url: `/ptable/admin/updatePropertyMaster/${selectedProperty?.id}`,
    method: 'PUT',
    onSuccess: (data) => {
      setShowPropertyEdit(false);
      console.log(data);
      getPtablePropertyMasterListRequest();
      // const result = [...ptable.filter((it) => it.id === data.id), ...ptable];
      // setPtable(result);
    },
  });

  useEffect(() => {
    getPtablePropertyMasterListRequest();
  }, []);

  const handleEditProperty = (e) => {
    e.preventDefault();
    const category = e.target.category.value;
    const subCategory = e.target.subCategory.value;
    const propertyUnit = e.target.propertyUnit.value;
    const property = e.target.property.value;
    const numeric = !!e.target.numeric.checked;
    const propertyKey = e.target.propertyKey.value;
    // console.log(category, subCategory);
    putPropertyMasterListRequest({
      body: {
        category,
        sub_category: subCategory,
        property_unit: propertyUnit,
        property,
        numeric,
        property_key: propertyKey,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getPtablePropertyMasterListLoading || putPropertyMasterListLoading} />

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="PTable" breadcrumbItem="Properties List" />

          <Card>
            <CardBody>Filters for main category and category</CardBody>
          </Card>

          <Row className="mb-2  align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2">
                <div className="position-relative font-bold">{ptable?.length} Properties Found</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div>
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table table-nowrap table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '100px' }}>
                          #Id
                        </th>
                        <th scope="col">Property Key</th>
                        <th scope="col">Property</th>
                        <th scope="col">Property Unit</th>
                        <th scope="col">Main Category</th>
                        <th scope="col">Category</th>
                        <th scope="col">Sub Category</th>
                        <th scope="col">Numeric</th>
                        <th scope="col">Active</th>
                        <th scope="col">Created Date</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(ptable) ? (
                        ptable?.map((data, index) => (
                          <tr key={index}>
                            <td>{data?.id}</td>
                            <td>
                              <Link to={`/ptable/property/${data?.property_key}`}>{data?.property_key}</Link>
                            </td>
                            <td>{data?.property}</td>
                            <td>{data?.property_unit}</td>
                            <td>{data?.main_category}</td>
                            <td>{data?.category}</td>
                            <td>{data?.sub_category}</td>
                            <td>
                              <Badge className={data?.numeric ? 'bg-success' : 'bg-danger'}>
                                {data?.numeric ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>{printDate(data?.createdDate)}</td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop" tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem
                                    onClick={() => {
                                      setShowPropertyEdit(true);
                                      setSelectedProperty(data);
                                    }}>
                                    <i className="fas fa-edit me-2 text-success fa-fw" />
                                    Edit Property
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Modal
            isOpen={showPropertyEdit}
            role="dialog"
            autoFocus
            centered
            className="exampleModal"
            tabIndex="-1"
            toggle={() => {
              setShowPropertyEdit(false);
              setSelectedProperty(null);
            }}>
            <div className="modal-content">
              <ModalHeader
                className="modalHeader"
                toggle={() => {
                  setShowPropertyEdit(false);
                  setSelectedProperty(null);
                }}>
                Edit Property Master
              </ModalHeader>
              <Form onSubmit={(e) => handleEditProperty(e)}>
                <ModalBody>
                  <FormGroup className="mb-4">
                    <Label htmlFor="category" className="form-label  ">
                      Category
                    </Label>
                    <Input
                      id="category"
                      type="text"
                      className="form-control"
                      placeholder="Category"
                      defaultValue={selectedProperty?.category}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label htmlFor="subCategory" className="form-label  ">
                      Sub Category
                    </Label>
                    <Input
                      id="subCategory"
                      type="text"
                      className="form-control"
                      placeholder="Sub Category"
                      defaultValue={selectedProperty?.sub_category}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label htmlFor="propertyUnit" className="form-label  ">
                      Property Unit
                    </Label>
                    <Input
                      id="propertyUnit"
                      type="text"
                      className="form-control"
                      placeholder="Property Unit"
                      defaultValue={selectedProperty?.property_unit}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label htmlFor="property" className="form-label  ">
                      Property
                    </Label>
                    <Input
                      id="property"
                      type="text"
                      className="form-control"
                      placeholder="Property"
                      defaultValue={selectedProperty?.property}
                    />
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <div className="d-flex ">
                      <Label htmlFor="numeric" className="form-label  ">
                        Numeric
                      </Label>
                      <Input
                        id="numeric"
                        type="checkbox"
                        className="form-control ms-0 ps-0"
                        placeholder="Numeric"
                        defaultChecked={selectedProperty?.numeric}
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="mb-4">
                    <Label htmlFor="propertyKey" className="form-label  ">
                      Property Key
                    </Label>
                    <Input
                      type="textarea"
                      className="form-control"
                      id="propertyKey"
                      rows="3"
                      placeholder="Property Key..."
                      defaultValue={selectedProperty?.property_key}
                    />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button type="submit" color="success">
                    Save
                  </Button>
                </ModalFooter>
              </Form>
            </div>
          </Modal>
        </Container>
      </div>
    </>
  );
};

export default PropertyMasterList;
