import { createSelector } from 'reselect';

import { initialState } from './reducer';

export const FORUM_KEY = 'forum';

const selectForumSubStore = (store) => store[FORUM_KEY] || initialState;

const topicListSelector = createSelector(selectForumSubStore, (subState) => subState.topicList);
const categoryListSelector = createSelector(selectForumSubStore, (subState) => subState.forumCategoriesList);
const categoryListPaginationSelector = createSelector(
  selectForumSubStore,
  (subState) => subState.forumCategoriesPagination
);

const topicListPaginationSelector = createSelector(selectForumSubStore, (subState) => subState.topicListPagination);

const topicSearchListSelector = createSelector(selectForumSubStore, (subState) => subState.topicSearchList);

const topicDetailSelector = createSelector(selectForumSubStore, (subState) => subState.topicDetail);

const forumSubCategoriesSelector = createSelector(selectForumSubStore, (subState) => subState.forumSubCategories);

const postListSelector = createSelector(selectForumSubStore, (subState) => subState.postList);

const postListPaginationSelector = createSelector(selectForumSubStore, (subState) => subState.postListPagination);

const postDetailSelector = createSelector(selectForumSubStore, (subState) => subState.postDetail);

export {
  categoryListSelector,
  categoryListPaginationSelector,
  topicListSelector,
  postListSelector,
  topicListPaginationSelector,
  postListPaginationSelector,
  topicSearchListSelector,
  forumSubCategoriesSelector,
  topicDetailSelector,
  postDetailSelector,
};
