import { createSelector } from 'reselect';

import { initialState } from './reducer';

export const CONTACT_INQUIRY_KEY = 'contactInquiry';

const selectForumSubStore = (store) => store[CONTACT_INQUIRY_KEY] || initialState;

const contactInquiryListSelector = createSelector(selectForumSubStore, (subState) => subState.contactInquiries);

const contactInquirySearchListSelector = createSelector(
  selectForumSubStore,
  (subState) => subState.contactInquirySearchList
);

const contactInquiryPaginationSelector = createSelector(
  selectForumSubStore,
  (subState) => subState.contactInquiryPagination
);

export { contactInquiryListSelector, contactInquirySearchListSelector, contactInquiryPaginationSelector };
