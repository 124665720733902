import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import profileImg from '../../assets/images/profile-img.png';
import avatar2 from '../../assets/images/users/avatar-2.jpg';
import { titleCaseIfExists } from 'utils/helper';
import { useMsal } from '@azure/msal-react';
import { isEmpty } from 'lodash';

const HrWelcomeComp = () => {
  const { accounts } = useMsal();
  const accessToken = localStorage.getItem('accessToken');
  return (
    <>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">Welcome Back !</h5>
                <p>HR Dashboard</p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="Profile Image" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          <Row>
            <Col sm="12">
              <div className="avatar-md profile-user-wid mb-3">
                <img src={avatar2} alt="" className="img-thumbnail rounded-circle" />
              </div>
              <h5 className="font-size-15 text-truncate">{!isEmpty(accessToken) ? `${accounts[0]?.name}` : ''}</h5>
              <p className="text-muted mb-0 text-truncate">{accounts[0]?.username}</p>
              <p className="text-muted mb-0 mt-4 text-truncate">
                <b>App source :</b> HR
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};
export default HrWelcomeComp;
