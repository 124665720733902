import { PUBLIC_URL } from '../../utils/constants';

export default {
  DOCTORS_LISTING: `${PUBLIC_URL}/doctors/list`,
  ADD_DOCTOR: `${PUBLIC_URL}/doctor/add`,
  EDIT_DOCTOR: `${PUBLIC_URL}/doctor/edit/:id`,
  DOCTOR_DETAILS: `${PUBLIC_URL}/doctor/details/:id`,
  HEALTH_CARE_LISTING: `${PUBLIC_URL}/healthcareFacility/list`,
  ADD_HEALTH_CARE: `${PUBLIC_URL}/healthcareFacility/add`,
  EDIT_HEALTH_CARE: `${PUBLIC_URL}/healthcarFacility/edit/:id`,
  HEALTH_CARE_DETAILS: `${PUBLIC_URL}/healthcareFacility/details/:id`,
  CLINIC_LISTING: `${PUBLIC_URL}/doctors/location/list`,
  ADD_CLINIC: `${PUBLIC_URL}/doctor/:doctorId/location/add`,
  EDIT_CLINIC: `${PUBLIC_URL}/doctor/:doctorId/location/edit/:id`,
};
