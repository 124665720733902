import { Link } from '@material-ui/core';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import useRequest from 'hooks/useRequest';
import { invert, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  PopoverBody,
  PopoverHeader,
  Row,
  Table,
  UncontrolledPopover,
} from 'reactstrap';
import { SUBSCRIPTION_MODULE_TYPE } from 'utils/constants';
import { buildURL, titleCaseIfExists } from 'utils/helper';
import { SMKLinks } from 'utils/links';

const UserSubscriptionDetails = () => {
  const { id: userSubscriptionId } = useParams();

  const [userSubscriptionDetail, setuserSubscriptionDetail] = useState([]);

  const [getUserSubscriptionDetail, { loading: getUserSubscriptionLoading }] = useRequest({
    url: `/subscription/admin/user-subscription/${userSubscriptionId}`,
    method: 'GET',
    onSuccess: (data) => {
      setuserSubscriptionDetail(data);
    },
    onError: (err) => {
      console.warn(err);
    },
  });

  useEffect(() => {
    getUserSubscriptionDetail();
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getUserSubscriptionLoading} />
        <Container fluid>
          <Breadcrumbs title="User Subscription" breadcrumbItem="User Subscription Detail" />

          <Card>
            <CardBody>
              <CardTitle className="py-3 card-title text-center bg-light mb-2">
                User Subscription Information{' '}
              </CardTitle>
              <Row>
                <Col xl="6">
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0 table-striped table-white">
                      <thead>
                        <tr>
                          <th>Payment Done By </th>
                          <td>{userSubscriptionDetail?.payment?.paymentDetails?.payment?.email}</td>
                        </tr>
                        <tr>
                          <th>Active</th>
                          <td>
                            <Badge className={userSubscriptionDetail?.active ? 'bg-success' : 'bg-danger'}>
                              {userSubscriptionDetail?.active ? 'Yes' : 'No'}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th>Start Date</th>
                          <td>{moment(userSubscriptionDetail?.startDate)?.format('DD-MM-YYYY')}</td>
                        </tr>
                        <tr>
                          <th>End Date</th>
                          <td>{moment(userSubscriptionDetail?.endDate)?.format('DD-MM-YYYY')}</td>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </Table>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0 table-striped table-white">
                      <thead>
                        <tr>
                          <th>Payment Currency </th>
                          <td>{userSubscriptionDetail?.payment?.paymentDetails?.payment?.currency}</td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>
                            <Badge
                              className={
                                userSubscriptionDetail?.status === 'active'
                                  ? 'bg-success'
                                  : userSubscriptionDetail?.status === 'payment_pending'
                                  ? 'bg-warning'
                                  : 'bg-danger'
                              }>
                              {titleCaseIfExists(userSubscriptionDetail?.status)}
                            </Badge>
                          </td>
                        </tr>
                        <tr>
                          <th>Payment Amount</th>
                          <td>{userSubscriptionDetail?.payment?.amount}</td>
                        </tr>
                        <tr>
                          <th>Payment Paid On</th>
                          <td>{moment(userSubscriptionDetail?.payment?.paidOn)?.format('DD-MM-YYYY')}</td>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <Row>
            <Col md="6">
              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-4">
                    Subscription Plan Information{' '}
                  </CardTitle>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive" style={{ minHeight: 200 }}>
                        <Table className="table-nowrap mb-0 table-striped table-white">
                          <tbody>
                            <tr>
                              <th>Name :</th>
                              <td>
                                <div className="d-flex align-items-center">
                                  {!userSubscriptionDetail?.subscriptionPlan?.deleted ? (
                                    <Link
                                      to={buildURL(SMKLinks.EDIT_SUBSCRIPTION_PLAN, {
                                        id: userSubscriptionDetail?.subscriptionPlan?.id,
                                      })}>
                                      {userSubscriptionDetail?.subscriptionPlan?.name}
                                    </Link>
                                  ) : (
                                    userSubscriptionDetail?.subscriptionPlan?.name
                                  )}

                                  {!isEmpty(userSubscriptionDetail?.subscriptionPlan?.limits) && (
                                    <span className="ms-3">
                                      <Button
                                        type="button"
                                        className="widget-info-icon text-secondary fs-5 p-0"
                                        id={`popover-1`}
                                        style={{ background: 'white', border: '0px' }}>
                                        <i className="mdi mdi-information" />
                                      </Button>
                                      <UncontrolledPopover placement="right" target={`popover-1`} trigger={'focus'}>
                                        <PopoverHeader>Limits </PopoverHeader>
                                        <PopoverBody>
                                          {[
                                            {
                                              label: 'Whiteboards',
                                              value: userSubscriptionDetail?.subscriptionPlan?.limits?.whiteboards,
                                            },
                                            {
                                              label: 'Whiteboard Pages',
                                              value: userSubscriptionDetail?.subscriptionPlan?.limits?.whiteboardPages,
                                            },
                                            {
                                              label: 'Whiteboard Invitees',
                                              value:
                                                userSubscriptionDetail?.subscriptionPlan?.limits?.whiteboardInvitees,
                                            },
                                            {
                                              label: 'Worksheet Generate Daily',
                                              value:
                                                userSubscriptionDetail?.subscriptionPlan?.limits
                                                  ?.worksheetGenerateDaily,
                                            },
                                            {
                                              label: 'Worksheet Download Daily',
                                              value:
                                                userSubscriptionDetail?.subscriptionPlan?.limits
                                                  ?.worksheetDownloadDaily,
                                            },
                                            {
                                              label: 'Worksheet Download Monthly',
                                              value:
                                                userSubscriptionDetail?.subscriptionPlan?.limits
                                                  ?.worksheetDownloadMonthly,
                                            },
                                            {
                                              label: 'Claim Schools',
                                              value: userSubscriptionDetail?.subscriptionPlan?.limits?.claimSchools,
                                            },
                                            {
                                              label: 'Memories',
                                              value: userSubscriptionDetail?.subscriptionPlan?.limits?.memories,
                                            },
                                            {
                                              label: 'Memory Images',
                                              value: userSubscriptionDetail?.subscriptionPlan?.limits?.memoriesImages,
                                            },
                                            {
                                              label: 'Caregivers',
                                              value: userSubscriptionDetail?.subscriptionPlan?.limits?.caregivers,
                                            },
                                          ]?.map((elem) => (
                                            <div className="mb-2">
                                              {userSubscriptionDetail?.subscriptionPlan?.label} :{' '}
                                              <span>
                                                <strong>{userSubscriptionDetail?.subscriptionPlan?.value}</strong>
                                              </span>
                                            </div>
                                          ))}
                                        </PopoverBody>
                                      </UncontrolledPopover>
                                    </span>
                                  )}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <th>Slug :</th>
                              <td>{userSubscriptionDetail?.subscriptionPlan?.slug}</td>
                            </tr>
                            <tr>
                              <th>Primary Module</th>
                              <td>
                                {titleCaseIfExists(
                                  invert(SUBSCRIPTION_MODULE_TYPE)[
                                    userSubscriptionDetail?.subscriptionPlan?.primaryModule
                                  ]
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Level</th>
                              <td>{userSubscriptionDetail?.subscriptionPlan?.level}</td>
                            </tr>
                            <tr>
                              <th>Currency</th>
                              <td>{userSubscriptionDetail?.subscriptionPlan?.currency}</td>
                            </tr>
                            <tr>
                              <th>Price</th>
                              <td>{userSubscriptionDetail?.subscriptionPlan?.price}</td>
                            </tr>
                            <tr>
                              <th>Payment Interval</th>
                              <td>{titleCaseIfExists(userSubscriptionDetail?.subscriptionPlan?.paymentInterval)}</td>
                            </tr>
                            <tr>
                              <th>Payment Duration</th>
                              <td>
                                {userSubscriptionDetail?.subscriptionPlan?.duration}{' '}
                                {userSubscriptionDetail?.subscriptionPlan?.paymentInterval === 'monthly'
                                  ? 'Months'
                                  : 'Years'}
                              </td>{' '}
                            </tr>
                            <tr>
                              <th>Payment Expiry Date</th>
                              <td>
                                {moment(userSubscriptionDetail?.subscriptionPlan?.expiryDate)?.format('DD-MM-YYYY')}
                              </td>
                            </tr>
                            <tr>
                              <th>Analytics Allowed</th>

                              <td>
                                <Badge
                                  className={
                                    userSubscriptionDetail?.subscriptionPlan?.limits?.analyticsAllowed
                                      ? 'bg-success'
                                      : 'bg-danger'
                                  }>
                                  {userSubscriptionDetail?.subscriptionPlan?.limits?.analyticsAllowed ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                            </tr>
                            <tr>
                              <th>Deleted</th>
                              <td>
                                <Badge
                                  className={
                                    userSubscriptionDetail?.subscriptionPlan?.deleted ? 'bg-success' : 'bg-danger'
                                  }>
                                  {userSubscriptionDetail?.subscriptionPlan?.deleted ? 'Yes' : 'No'}
                                </Badge>
                              </td>
                            </tr>
                            <tr>
                              <th>Active</th>
                              <td>
                                <Badge
                                  className={
                                    userSubscriptionDetail?.subscriptionPlan?.active ? 'bg-success' : 'bg-danger'
                                  }>
                                  {userSubscriptionDetail?.subscriptionPlan?.active ? 'Active' : 'Disabled'}
                                </Badge>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card>
                <CardBody>
                  <CardTitle className="py-3 card-title text-center bg-light mb-4">Payment Information </CardTitle>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive" style={{ minHeight: 200 }}>
                        <Table className="table-nowrap mb-0 table-striped table-white">
                          <tbody>
                            <tr>
                              <th>Active :</th>
                              <td>
                                <Badge className={userSubscriptionDetail?.payment?.active ? 'bg-success' : 'bg-danger'}>
                                  {userSubscriptionDetail?.payment?.active ? 'Active' : 'Disabled'}
                                </Badge>
                              </td>
                            </tr>
                            <tr>
                              <th>Payment Amount :</th>
                              <td>{userSubscriptionDetail?.payment?.amount}</td>
                            </tr>
                            <tr>
                              <th>Paid On :</th>
                              <td>{moment(userSubscriptionDetail?.payment?.paidOn)?.format('DD-MM-YYYY')}</td>
                            </tr>
                            <tr>
                              <th>Payment Start Date :</th>
                              <td>{moment(userSubscriptionDetail?.payment?.startDate)?.format('DD-MM-YYYY')}</td>
                            </tr>
                            <tr>
                              <th>Payment End Date :</th>
                              <td>{moment(userSubscriptionDetail?.payment?.endDate)?.format('DD-MM-YYYY')}</td>
                            </tr>

                            <tr>
                              <th>Status</th>
                              <td>{userSubscriptionDetail?.payment?.status}</td>
                            </tr>
                            <tr>
                              <th>Tax Amount</th>
                              <td>{userSubscriptionDetail?.payment?.taxAmount}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserSubscriptionDetails;
