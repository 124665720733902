import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Row,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import * as Yup from 'yup';

// actions
import { useHistory } from 'react-router-dom';
import { SMKLinks } from 'utils/links';
// selectors
import { collegeDetailSelector, selectCollegesGroups, selectCollegesMasterData } from '../../selectors';
// constants
import {
  API_BASE_URL,
  CollegeMasterType,
  EMAIL_REGEX,
  MODULE_TYPE,
  NAME_REGEX,
  PHONE_REGEX,
  SCHOOL_GENDER_VALUES,
  schoolAndCollegeImagesTypes,
} from '../../../../utils/constants';
// helpers
import { convertHtmlToText, htmlDecode, titleCaseIfExists } from '../../../../utils/helper';
// components
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { userSelector } from '../../../../common/globalComponents/selectors';
import Loader from '../../../../components/Loader';
import TextEditor from '../../../../components/TextEditor';
import useRequest from '../../../../hooks/useRequest';

const stateSelector = createStructuredSelector({
  collegesMasterData: selectCollegesMasterData,
  collegesGroups: selectCollegesGroups,
  collegeDetail: collegeDetailSelector,
  user: userSelector,
});

const BasicDetails = () => {
  const { user, collegesMasterData, collegesGroups, collegeDetail } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const collegeGroupsPayload = {
    page: 1,
    size: 500,
    sortBy: 'id',
    sortOrder: 0,
  };

  // useEffect(() => {
  //   dispatch(getCollegesGroups.trigger(collegeGroupsPayload));
  // }, []);

  const getInitialFormValues = () => {
    const {
      name,
      shortName,
      basicDetails,
      masterData,
      featuredImageUrl,
      admissionDetails,
      collegeGroups,
      studentGender,
      images,
    } = collegeDetail;

    const values = {};

    // Basic Details
    values.name = convertHtmlToText(name) || '';
    values.shortName = convertHtmlToText(shortName) || '';
    values.establishedIn = basicDetails?.establishedIn ? moment(basicDetails.establishedIn).toDate() : '';

    const parent = collegeGroups?.find((sc) => !sc.collegeGroup?.isAssociation);

    values.parentCollege = !isEmpty(parent)
      ? {
          ...parent.collegeGroup,
          value: parent.collegeGroup?.id,
          label: parent.collegeGroup?.name,
        }
      : null;

    values.about = htmlDecode(basicDetails?.about) || '';

    const gender = Object.entries(SCHOOL_GENDER_VALUES).find(([k, v]) => v === studentGender);
    values.studentGender = {
      label: titleCaseIfExists(gender[0]),
      value: gender[1],
    };

    // prepopulate boarding type
    const boarding = masterData?.find((b) => b.type === CollegeMasterType.BOARDING_TYPE);
    values.boardingType =
      (boarding &&
        collegesMasterData?.find((d) => d.type === CollegeMasterType.BOARDING_TYPE && d.id === boarding.id)) ||
      {};

    // prepopulate boarding gender
    const boardingGender = masterData?.find((b) => b.type === CollegeMasterType.BOARDING_GENDER_TYPE);
    values.boardingGenderType =
      (boardingGender &&
        collegesMasterData.find(
          (d) => d.type === CollegeMasterType.BOARDING_GENDER_TYPE && boardingGender.id === d.id
        )) ||
      {};

    // prepopulate boarding sub type
    // const boardingSubType = masterData?.find((b) => b.type === CollegeMasterType.BOARDING_SUB_TYPE);
    values.boardingSubTypes =
      collegesMasterData?.filter((d) =>
        masterData?.find((b) => b.id === d.id && d.type === b.type && b.type === CollegeMasterType.BOARDING_SUB_TYPE)
      ) || {};

    values.twitter = basicDetails?.socialProfiles?.find((sp) => sp.type === 'twitter')?.handle || '';
    values.facebook = basicDetails?.socialProfiles?.find((sp) => sp.type === 'facebook')?.handle || '';

    // Logo & Images
    values.featuredImageUrl = featuredImageUrl || '';
    values.brochure = admissionDetails?.collegeBrochureUrl || '';

    // Management Details
    const management = masterData?.find((m) => m.type === CollegeMasterType.MANAGEMENT_TYPE);
    values.managementType =
      (management &&
        collegesMasterData?.find((d) => d.type === CollegeMasterType.MANAGEMENT_TYPE && d.id === management.id)) ||
      {};
    values.managementName = basicDetails?.managementName || '';
    const principal = basicDetails?.management.find((m) => m.type === 'Principal');
    values.principalName = principal?.name || '';
    values.principalEmail = principal?.email || '';
    values.principalPhone = principal?.phone || '';

    const chairman = basicDetails?.management.find((m) => m.type === 'Chairman');
    values.chairmanName = chairman?.name || '';
    values.chairmanEmail = chairman?.email || '';
    values.chairmanPhone = chairman?.phone || '';
    values.officeHours = basicDetails?.officeHours || '';
    values.committee = basicDetails?.managementCommittee || '';
    values.about = basicDetails?.about || '';

    const recognizedBy = collegesMasterData?.filter((m) => m.type === CollegeMasterType.RECOGNIZING_BODY);
    values.recognizedById = (recognizedBy && recognizedBy?.find((d) => d.id === collegeDetail?.recognizedById)) || {};

    values.recognitionStatus = collegeDetail?.recognitionStatus || '';

    // Logo & Images
    values.featuredImages = !isEmpty(images)
      ? images?.map((elem) => ({
          type: elem?.type || '',
          file: {
            filename: elem?.url || '',
            id: elem?.id || '',
            name: elem?.name || '',
            url: elem?.url || '',
          },
        }))
      : [{ type: '', url: '' }];

    return values;
  };

  const [updateBasicDetailsRequest, { loading: updateBasicDetailsLoading }] = useRequest({
    url: '/college/admin/edit/updateBasicDetails',
    method: 'POST',
    onSuccess: (data) => {
      window.location.reload();
    },
  });

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: getInitialFormValues(),
    onSubmit(values) {
      let masterData = [values.managementType, values.boardingType];
      if (values.boardingType?.name?.includes('Boarding')) {
        masterData = [...masterData, ...values.boardingSubTypes, values.boardingGenderType];
      }
      const restCollegeGroups = collegeDetail?.collegeGroups?.filter((elem) => elem?.collegeGroup?.isAssociation);
      const data = {
        collegeId: collegeDetail.id,
        name: values.name,
        shortName: values.shortName,
        // collegeGroups: values.parentCollege ? [...restCollegeGroups, values.parentCollege] : restCollegeGroups,
        // imageUrl: values.imageUrl,
        studentGender: values.studentGender.value,
        recognizedById: values.recognizedById?.value,
        recognitionStatus: values.recognitionStatus,
        managementTypeId: values.managementType.value,
        basicDetails: {
          establishedIn: !isEmpty(values.establishedIn) ? moment(values.establishedIn).format('YYYY-MM-DD') : '',
          about: values.about,
          managementName: values.managementName,
          management: [
            {
              email: values.principalEmail,
              name: values.principalName,
              phone: values.principalPhone,
              type: 'Principal',
            },
            {
              email: values.chairmanEmail,
              name: values.chairmanName,
              phone: values.chairmanPhone,
              type: 'Chairman',
            },
          ],
          officeHours: values.officeHours,
          socialProfiles: [
            { type: 'twitter', handle: values.twitter },
            { type: 'facebook', handle: values.facebook },
          ],
        },
        masterData,
        admissionDetails: {
          collegeBrochureUrl: values.brochure,
        },
        images: !isEmpty(values?.featuredImages)
          ? values?.featuredImages
              ?.filter((e) => !isEmpty(e?.type) && !isEmpty(e?.file))
              ?.map((elem) => {
                const { file, type } = elem;
                const { id, name, original, url } = file;
                return {
                  type,
                  url: url ? url : original,
                  id,
                  name,
                };
              })
          : [],
      };

      updateBasicDetailsRequest({
        data,
      });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Please provide college name')
        .typeError('Please provide college name')
        .min(8, 'Title should be at least 8 characters long')
        .max(150, 'Title should be at most 100 characters long'),
      shortName: Yup.string()
        .nullable()
        // eslint-disable-next-line func-names
        .test('shortNameTest', 'Short name should be smaller than name', function(value) {
          // eslint-disable-next-line react/no-this-in-sfc
          return !value || (value?.length > 0 && this.parent.name?.length > value?.length);
        }),
      // Basic Details
      establishedIn: Yup.string().nullable(),
      // parentCollege: Yup.object().nullable(),
      // .test('groupTest', 'Please select an option', (val) => !isEmpty(val)),
      boardingType: Yup.object().test('boardingTest', 'Please select boarding', (val) => !isEmpty(val)),
      // boardingSubTypes: Yup.array().when('boardingType', {
      //   is: (val) => !isEmpty(val) && val.name.includes('Boarding'),
      //   then: Yup.array().test('boardingSubTypeTest', 'Please select an option', (val) => !isEmpty(val)),
      // }),
      boardingGenderType: Yup.object().when('boardingType', {
        is: (val) => !isEmpty(val) && val.name.includes('Boarding'),
        then: Yup.object().test('boardingGenderTest', 'Please select an option', (val) => !isEmpty(val)),
      }),
      twitter: Yup.string().nullable(),
      facebook: Yup.string().nullable(),
      // Logo & Images
      featuredImageUrl: Yup.string().nullable(),
      brochure: Yup.string().nullable(),
      // Management Details
      managementType: Yup.object().test('managTest', 'Please select management type', (val) => !isEmpty(val)),
      managementName: Yup.string().nullable(),
      chairmanName: Yup.string()
        .nullable()
        // .required('Please provide chairman name')
        .matches(NAME_REGEX, 'Please provide valid chairman name'),
      chairmanEmail: Yup.string()
        .matches(EMAIL_REGEX, 'Please provide valid email')
        .nullable(),
      chairmanPhone: Yup.string()
        .matches(PHONE_REGEX, 'Please provide valid number')
        .nullable(),
      principalName: Yup.string()
        .nullable()
        // .required('Please provide principal name')
        .matches(NAME_REGEX, 'Please provide valid principal name'),
      principalEmail: Yup.string()
        .matches(EMAIL_REGEX, 'Please provide valid email')
        .nullable(),
      principalPhone: Yup.string()
        .matches(PHONE_REGEX, 'Please provide valid number')
        .nullable(),
      officeHours: Yup.string().nullable(),
      // .required('Please provide office hours'),
      committee: Yup.string().nullable(),
      about: Yup.string().nullable(),
      // .required('Please provide few words about college'),
    }),
  });

  const handleAddField = () => {
    const { featuredImages = [], errors = {} } = formik.values;
    if (featuredImages.length > 0 && !isEmpty(errors.featuredImages)) {
      return;
    }
    formik.setFieldValue('featuredImages', [...featuredImages, { type: '', url: '' }]);
  };

  const handleRemoveField = (index) => {
    const values = formik.values.featuredImages.slice();
    values.splice(index, 1);
    formik.setFieldValue('featuredImages', values);
  };

  const handleChangeImages = (index, key, value) => {
    const array = [...formik.values.featuredImages];
    array[index] = {
      ...array[index],
      [key]: value,
    };
    formik.setFieldValue('featuredImages', array);
  };

  const renderFeatureImageURL = () => {
    const types = Object.keys(schoolAndCollegeImagesTypes).map((key) => ({
      label: titleCaseIfExists(key),
      value: schoolAndCollegeImagesTypes[key],
    }));

    return formik.values.featuredImages.map((elem, i) => {
      return (
        <Row key={i}>
          <Col sm={3}>
            <FormGroup>
              <Select
                options={types}
                value={types.find((t) => t.value === elem.type)}
                onChange={(t) => handleChangeImages(i, 'type', t.value)}
              />
              {!isEmpty(formik.errors.featuredImages) && (
                <div className="invalid-feedback d-block">{formik.errors.featuredImages[i]?.type}</div>
              )}
            </FormGroup>
          </Col>
          <Col sm={8}>
            <FormGroup className="mb-4">
              <SmkAsyncSelect
                acceptedKey={'name'}
                acceptedValue={'name'}
                placeholder="Type media name.."
                onChange={(res) => {
                  handleChangeImages(i, 'file', res);
                }}
                fetchUrl={`${API_BASE_URL}/asset/admin/search`}
                filters={{
                  autoComplete: true,
                  page: 1,
                  size: 1000,
                  sortBy: 'id',
                  sortOrder: 0,
                  forType: MODULE_TYPE.COLLEGE,
                }}
                searchKeyName="name"
                value={
                  !isEmpty(elem?.file?.name)
                    ? {
                        label: elem?.file?.name,
                        value: elem?.file?.name,
                      }
                    : null
                }
              />

              {!isEmpty(formik.errors.featuredImages) && (
                <div className="invalid-feedback d-block">{formik.errors.featuredImages[i]?.file}</div>
              )}
            </FormGroup>
          </Col>
          <Col sm={1}>
            <FormGroup>
              <Button color="danger" className="w-100" onClick={() => handleRemoveField(i, 'number')}>
                <i className="bx bx-trash" />
              </Button>
            </FormGroup>
          </Col>
        </Row>
      );
    });
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateBasicDetailsLoading} />

      <h5 className="mb-4">Basic Details</h5>

      <Card>
        <CardBody>
          <Row>
            <Col md={8}>
              <FormGroup className="mb-4">
                <Label for="collegename" className="form-label  ">
                  College Name
                </Label>
                <Input
                  id="collegename"
                  type="text"
                  className="form-control"
                  placeholder="College Name..."
                  label="College Name"
                  variant="outlined"
                  invalid={!!(formik.touched.name && formik.errors.name)}
                  {...formik.getFieldProps('name')}
                />
                <FormFeedback>{formik.errors.name}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup className="mb-4">
                <Label for="college_shortname" className="form-label  ">
                  College Short Name
                </Label>
                <Input
                  id="college_shortname"
                  name="college_shortname"
                  type="text"
                  className="form-control"
                  placeholder="College Short Name..."
                  invalid={!!(formik.touched.shortName && formik.errors.shortName)}
                  {...formik.getFieldProps('shortName')}
                />
                <FormFeedback>{formik.errors.shortName}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="establishedIn">Established In</Label>
                <InputGroup id="establishedIn">
                  <DatePicker
                    selected={formik.values?.establishedIn ? formik.values?.establishedIn : null}
                    className="form-control"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Select date"
                    autoComplete="off"
                    minDate={moment('1200-01-01').toDate()}
                    maxDate={moment().toDate()}
                    onChange={(date) => formik.setFieldValue('establishedIn', date)}
                    invalid={!!(formik.touched.establishedIn && formik.errors.establishedIn)}
                  />
                </InputGroup>
                <FormFeedback className="d-block">{formik.errors.establishedIn}</FormFeedback>
              </FormGroup>

              {/* <FormGroup className="mb-4">
                <Label for="college_parent" className="form-label">
                  Parent College
                </Label>
                <Select
                  isClearable
                  id="college_parent"
                  placeholder="Please select an option"
                  options={collegesGroups.filter((gr) => !gr.isAssociation)}
                  value={formik.values.parentCollege}
                  onChange={(val) => formik.setFieldValue('parentCollege', val)}
                />
                {formik.touched.parentCollege && (
                  <div className="invalid-feedback d-block">{formik.errors.parentCollege}</div>
                )}
              </FormGroup> */}
            </Col>
          </Row>

          <hr className="mb-4" />

          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="college_gender" className="form-label  ">
                  Student Gender Type
                </Label>
                <Select
                  id="college_gender"
                  placeholder="Please select an option"
                  options={Object.entries(SCHOOL_GENDER_VALUES).map(([key, value]) => ({
                    value,
                    label: titleCaseIfExists(key),
                  }))}
                  value={formik.values.studentGender}
                  onChange={(val) => formik.setFieldValue('studentGender', val)}
                />
                {formik.touched.studentGender && (
                  <div className="invalid-feedback d-block">{formik.errors.studentGender}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="college_boarding" className="form-label  ">
                  Boarding Type
                </Label>
                <Select
                  id="college_boarding"
                  placeholder="Please select an option"
                  options={collegesMasterData.filter((d) => d.type === CollegeMasterType.BOARDING_TYPE)}
                  value={formik.values.boardingType}
                  onChange={(val) => formik.setFieldValue('boardingType', val)}
                />
                {formik.touched.boardingType && (
                  <div className="invalid-feedback d-block">{formik.errors.boardingType}</div>
                )}
              </FormGroup>
            </Col>
            {formik.values?.boardingType?.name?.includes('Boarding') && (
              <>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label for="college_sub_boarding" className="form-label  ">
                      Sub Boarding Type
                    </Label>
                    <Select
                      isMulti
                      id="college_sub_boarding"
                      placeholder="Please select an option"
                      options={collegesMasterData.filter((d) => d.type === CollegeMasterType.BOARDING_SUB_TYPE)}
                      value={formik.values.boardingSubTypes}
                      onChange={(val) => {
                        console.log(val);
                        formik.setFieldValue('boardingSubTypes', val);
                      }}
                    />
                    {formik.touched.boardingSubTypes && (
                      <div className="invalid-feedback d-block">{formik.errors.boardingSubTypes}</div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-4">
                    <Label for="college_boarding_gender_type" className="form-label  ">
                      Boarding Gender Type
                    </Label>
                    <Select
                      id="college_boarding_gender_type"
                      placeholder="Please select an option"
                      options={collegesMasterData.filter((d) => d.type === CollegeMasterType.BOARDING_GENDER_TYPE)}
                      value={formik.values.boardingGenderType}
                      onChange={(val) => formik.setFieldValue('boardingGenderType', val)}
                    />
                    {formik.touched.boardingGenderType && (
                      <div className="invalid-feedback d-block">{formik.errors.boardingGenderType}</div>
                    )}
                  </FormGroup>
                </Col>
              </>
            )}
          </Row>

          <hr className="mb-4" />

          <CardTitle>Social Profile</CardTitle>
          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="college_twitter" className="form-label  ">
                  Twitter Handle
                </Label>
                <Input
                  id="college_twitter"
                  type="text"
                  className="form-control"
                  placeholder=""
                  label="Twitter Handle"
                  invalid={!!(formik.touched.twitter && formik.errors.twitter)}
                  {...formik.getFieldProps('twitter')}
                />
                <FormFeedback>{formik.errors.twitter}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="college_facebook" className="form-label  ">
                  Facebook Handle
                </Label>
                <Input
                  id="college_facebook"
                  type="text"
                  className="form-control"
                  placeholder=""
                  label="Facebook Handle"
                  invalid={!!(formik.touched.facebook && formik.errors.facebook)}
                  {...formik.getFieldProps('facebook')}
                />
                <FormFeedback>{formik.errors.facebook}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Logo & Images</CardTitle>

          <Row className="mt-3">
            <Col md={12}>
              <FormGroup className="mb-4">
                <Label for="school_image" className="form-label  ">
                  Image Assets
                </Label>
                {renderFeatureImageURL()}

                <Button className="mt-2 mb-4 w-100" color="light" onClick={() => handleAddField()}>
                  <i className="bx bx-plus me-2" />
                  Add Asset
                </Button>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <FormGroup className="mb-4">
                <Label for="college_image" className="form-label  ">
                  Featured Image URL
                </Label>
                <Input
                  id="college_image"
                  type="text"
                  className="form-control"
                  placeholder="URL..."
                  label="Featured Image URL"
                  invalid={!!(formik.touched.featuredImageUrl && formik.errors.featuredImageUrl)}
                  {...formik.getFieldProps('featuredImageUrl')}
                />
                <FormFeedback>{formik.errors.featuredImageUrl}</FormFeedback>
              </FormGroup>
            </Col>

            <Col md={12}>
              <FormGroup className="mb-4">
                <Label for="college_brochure" className="form-label  ">
                  College Brochure
                </Label>
                <Input
                  id="college_brochure"
                  type="text"
                  className="form-control"
                  rows="3"
                  placeholder="URL..."
                  invalid={!!(formik.touched.brochure && formik.errors.brochure)}
                  {...formik.getFieldProps('brochure')}
                />
                <FormFeedback>{formik.errors.brochure}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>Recognized By</CardTitle>

          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="recognized_by_id" className="form-label  ">
                  Recognized By
                </Label>
                <Select
                  id="recognized_by_id"
                  placeholder="Please select an option"
                  options={collegesMasterData.filter((d) => d.type === CollegeMasterType.RECOGNIZING_BODY)}
                  value={formik.values.recognizedById}
                  onChange={(val) => formik.setFieldValue('recognizedById', val)}
                />
                {/* {formik.touched.recognizedById && (
                  <div className="invalid-feedback d-block">{formik.errors.recognizedById}</div>
                )} */}
                <FormFeedback>{formik.errors.recognizedById}</FormFeedback>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="recognition_status" className="form-label  ">
                  Recongnition Status
                </Label>
                <Input
                  id="recognition_status"
                  type="text"
                  className="form-control"
                  placeholder="URL..."
                  label="Recognition Status"
                  invalid={!!(formik.touched.recognitionStatus && formik.errors.recognitionStatus)}
                  {...formik.getFieldProps('recognitionStatus')}
                />
                <FormFeedback>{formik.errors.recognitionStatus}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <CardTitle>Management Details</CardTitle>

          <Row>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="college_manag_type" className="form-label  ">
                  Management Type
                </Label>
                <Select
                  id="college_manag_type"
                  placeholder="Select an option"
                  options={collegesMasterData.filter((d) => d.type === CollegeMasterType.MANAGEMENT_TYPE)}
                  value={formik.values.managementType}
                  onChange={(val) => formik.setFieldValue('managementType', val)}
                />
                {formik.touched.managementType && (
                  <div className="invalid-feedback d-block">{formik.errors.managementType}</div>
                )}
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="mb-4">
                <Label for="college_manag_name" className="form-label  ">
                  Management Name
                </Label>
                <Input
                  id="college_manag_name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  label="Management Name"
                  invalid={!!(formik.touched.managementName && formik.errors.managementName)}
                  {...formik.getFieldProps('managementName')}
                />
                <FormFeedback>{formik.errors.managementName}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <CardTitle>Chairman Details</CardTitle>
          <Row>
            <Col>
              <FormGroup className="mb-4">
                <Label for="college_chairname_name" className="form-label  ">
                  Name
                </Label>
                <Input
                  id="college_chairname_name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  invalid={!!(formik.touched.chairmanName && formik.errors.chairmanName)}
                  {...formik.getFieldProps('chairmanName')}
                />
                <FormFeedback>{formik.errors.chairmanName}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-4">
                <Label for="college_chairname_phone" className="form-label  ">
                  Phone Number
                </Label>
                <PhoneInput
                  country="in"
                  isValid={!(formik.touched.chairmanPhone && formik.errors.chairmanPhone)}
                  enableSearch
                  countryCodeEditable={false}
                  preferredCountries={['in', 'us', 'sg', 'uk']}
                  value={formik.values.chairmanPhone}
                  onChange={(value) => formik.setFieldValue('chairmanPhone', value)}
                />
                {formik.touched.chairmanPhone && (
                  <div className="invalid-feedback d-block">{formik.errors.chairmanPhone}</div>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-4">
                <Label for="college_chairname_email" className="form-label  ">
                  Email
                </Label>
                <Input
                  id="college_chairname_email"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  invalid={!!(formik.touched.chairmanEmail && formik.errors.chairmanEmail)}
                  {...formik.getFieldProps('chairmanEmail')}
                />
                <FormFeedback>{formik.errors.chairmanEmail}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <CardTitle>Principal / Head Details</CardTitle>
          <Row>
            <Col>
              <FormGroup className="mb-4">
                <Label for="college_principal_name" className="form-label  ">
                  Name
                </Label>
                <Input
                  id="college_principal_name"
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  invalid={!!(formik.touched.principalName && formik.errors.principalName)}
                  {...formik.getFieldProps('principalName')}
                />
                <FormFeedback>{formik.errors.principalName}</FormFeedback>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-4">
                <Label for="college_principal_phone" className="form-label  ">
                  Phone Number
                </Label>
                <PhoneInput
                  country="in"
                  isValid={!(formik.touched.principalPhone && formik.errors.principalPhone)}
                  enableSearch
                  countryCodeEditable={false}
                  preferredCountries={['in', 'us', 'sg', 'uk']}
                  value={formik.values.principalPhone}
                  onChange={(value) => formik.setFieldValue('principalPhone', value)}
                />
                {formik.touched.principalPhone && (
                  <div className="invalid-feedback d-block">{formik.errors.principalPhone}</div>
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup className="mb-4">
                <Label for="college_principal_email" className="form-label  ">
                  Email
                </Label>
                <Input
                  id="college_principal_email"
                  type="text"
                  className="form-control"
                  placeholder="Email..."
                  invalid={!!(formik.touched.principalEmail && formik.errors.principalEmail)}
                  {...formik.getFieldProps('principalEmail')}
                />
                <FormFeedback>{formik.errors.principalEmail}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup className="mb-4">
                <Label for="college_hours" className="form-label  ">
                  Office Hours
                </Label>
                <Input
                  id="college_hours"
                  type="textarea"
                  className="form-control"
                  placeholder="9:00 AM to 5:00 PM"
                  invalid={!!(formik.touched.officeHours && formik.errors.officeHours)}
                  {...formik.getFieldProps('officeHours')}
                />
                <FormFeedback>{formik.errors.officeHours}</FormFeedback>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
      {/* <Card>
        <CardBody>
          <Row>
            <Col md={12}>
              <FormGroup className="mb-4">
                <Label for="college_committee" className="form-label  ">
                  Management Committee
                </Label>
                <TextEditor
                  simple
                  initialValue={formik.values.committee}
                  onChange={(val) => formik.setFieldValue('committee', val)}
                />
                {formik.touched.committee && <div className="invalid-feedback d-block">{formik.errors.committee}</div>}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card> */}

      <Card>
        <CardBody>
          <Row>
            <Col md={12}>
              <FormGroup className="mb-4">
                <Label for="college_about" className="form-label  ">
                  About College
                </Label>
                <TextEditor
                  simple
                  initialValue={formik.values.about}
                  onChange={(val) => formik.setFieldValue('about', val)}
                />
                {formik.touched.about && <div className="invalid-feedback d-block">{formik.errors.about}</div>}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>

      {(user?.entityPermissions?.school.admin ||
        (collegeDetail.editable &&
          (collegeDetail?.createdBy?.id === user.id || collegeDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Details
          </Button>
          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.COLLEGE_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

BasicDetails.propTypes = {};

export default BasicDetails;
