import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
  UncontrolledDropdown,
} from 'reactstrap';
import Breadcrumbs from 'components/Common/Breadcrumb';
import Select from 'react-select';
import { Divider } from '@material-ui/core';
import AuthService from 'common/auth.service';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { buildURL, checkIfValueExist, fetchJobApplications, showImage, titleCaseIfExists } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { ACTIVE_AND_DELETED_ARRAY, appBannerSections, BannerScreenEnum } from '../../../utils/constants';
import axios from 'axios';

const JobApplicationsListing = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const auth = new AuthService();
  const source = auth.getAppSourceCookie();

  const bannerScreenList = Object.keys(BannerScreenEnum)?.map((name) => ({
    label: titleCaseIfExists(name),
    value: BannerScreenEnum[name],
  }));

  const [appBannerScreenList, setAppBannerScreenList] = useState([]);
  const [appBannerList, setAppBannerList] = useState([]);
  const [appBannerPagination, setAppBannerPagination] = useState({});

  const [jobApplications, setJobApplications] = useState([]);

  const defaultFilters = {
    active: true,
    deleted: false,
    placement: '',
    type: '',
    screen: null,
    appSource: source,
  };

  const [activeTab, setActiveTab] = useState('published');

  const APP_FILTERS = [
    {
      name: 'Published',
      filters: {
        active: true,
        deleted: false,
      },
    },
    {
      name: 'Inactive',
      filters: {
        active: false,
        deleted: false,
      },
    },
    {
      name: 'Trash',
      filters: {
        active: false,
        deleted: true,
      },
    },
    {
      name: 'All',
      filters: {
        active: null,
        deleted: null,
      },
    },
  ];

  const [filters, setFilters] = useState(defaultFilters);
  const [getAppBannerListRequest, { loading: getAppBannerListLoading }] = useRequest({
    url: `/common/appBanner/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      const screenslist = [...new Set(data?.results?.map((e) => e?.screen))];
      setAppBannerScreenList(screenslist);
      setAppBannerList(data?.results);
      setAppBannerPagination(data?.pagination || data?.paginator);
    },
  });
  const [updateTaxonomyRequest, { loading: updateTaxonomyLoading }] = useRequest({
    onSuccess: (data) => {
      toast.success(`Success! App Banner has been updated.`);
      window.location.reload();
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page, data) => {
    setPageInUrl(page);

    getAppBannerListRequest({
      body: {
        page,
        size: 500,
        sortOrder: 0,
        sortBy: 'updatedDate',
        placement: checkIfValueExist(data?.placement),
        screen: data?.screen,
        ...data,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1, filters);
  };

  const clearFilters = () => {
    setPageInUrl(1);
    setFilters({ ...defaultFilters });
    handleChangePage(1, defaultFilters);
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1, filters);
  }, []);

  const handleUpdateTaxonomy = (data, key) => {
    if (data[key]) {
      updateTaxonomyRequest({
        method: 'PUT',
        url: `/common/appBanner/admin/${data?.id}`,
        body: {
          [key]: false,
        },
      });
    } else {
      updateTaxonomyRequest({
        method: 'PUT',
        url: `/common/appBanner/admin/${data?.id}`,
        body: {
          [key]: true,
        },
      });
    }
  };

  const handleCVDownload = (currentApplication) => {
    const pdfUrl = `${process.env.REACT_APP_SMK_AWS_S3_CLOUDFRONT_URL}/${currentApplication?.cvURL}`;
    window.open(pdfUrl, '_blank');
  };

  const getJobApplications = async () => {
    try {
      const data = await fetchJobApplications();
      setJobApplications(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getJobApplications();
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getAppBannerListLoading || updateTaxonomyLoading} />
        <Container fluid>
          <Breadcrumbs title="App Applications" breadcrumbItem="Job Applications List" />

          <div>
            {/* <Nav tabs>
              {APP_FILTERS?.map((elem, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      className={activeTab === elem?.name?.toLowerCase() ? 'active' : ''}
                      onClick={() => {
                        setActiveTab(elem?.name?.toLowerCase());
                        setPageInUrl(1);

                        const updatedFilters = { ...filters, ...elem?.filters };
                        setFilters(updatedFilters);
                        handleChangePage(1, updatedFilters);
                      }}>
                      {elem?.name}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav> */}
            {/* <TabContent activeTab={activeTab}>
              <TabPane tabId={activeTab}>
                <Card>
                  <CardBody>
                    <div className="mt-4">
                      <div className="row">
                        <div className="col-lg-2 mb-4">
                          <label className="form-label">Active</label>
                          <Select
                            isDisabled={activeTab !== 'all'}
                            options={ACTIVE_AND_DELETED_ARRAY}
                            value={ACTIVE_AND_DELETED_ARRAY?.find((e) => String(e?.value) === String(filters.active))}
                            onChange={(e) => setFilters({ ...filters, active: e?.value })}
                          />
                        </div>
                        <div className="col-lg-2 mb-4">
                          <label className="form-label">Deleted</label>
                          <Select
                            isDisabled={activeTab !== 'all'}
                            options={ACTIVE_AND_DELETED_ARRAY}
                            value={ACTIVE_AND_DELETED_ARRAY?.find((e) => String(e?.value) === String(filters.deleted))}
                            onChange={(e) => setFilters({ ...filters, deleted: e?.value })}
                          />
                        </div>

                        <div className="col-lg-3 mb-4">
                          <label className="form-label">Placement</label>
                          <Select
                            options={appBannerSections}
                            placeholder="Select Placement..."
                            value={
                              isEmpty(filters.placement)
                                ? ''
                                : appBannerSections?.find((e) => e?.value === filters.placement)
                            }
                            onChange={(e) => setFilters({ ...filters, placement: e?.value })}
                          />
                        </div>

                        <div className="col-lg-3 mb-4">
                          <label className="form-label">Screen</label>
                          <Select
                            options={bannerScreenList}
                            placeholder="Select Screen..."
                            value={
                              isEmpty(filters.screen) ? '' : bannerScreenList?.find((e) => e?.value === filters.screen)
                            }
                            onChange={(e) => setFilters({ ...filters, screen: e?.value })}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 pt-2">
                      <a className="btn btn-primary me-4" onClick={applyFilters}>
                        Apply Filters
                      </a>
                      <a className="" onClick={clearFilters}>
                        Clear
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </TabPane>
            </TabContent> */}
          </div>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{jobApplications?.length} Job Applications Found</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="table-responsive mt-2 mb-4">
                <Table className="project-list-table  table-borderless ">
                  <thead>
                    <tr className="table-warning">
                      <th scope="col" style={{ width: '60px' }}>
                        S.No.
                      </th>
                      <th scope="col"></th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Phone No.</th>
                      <th scope="col">YOE</th>
                      {/* <th scope="col">Current CTC</th> */}
                      <th scope="col">Status</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {!isEmpty(jobApplications) ? (
                      jobApplications?.map((data, index) => {
                        return (
                          <tr
                            key={index}
                            // className={!data?.active || data?.deleted ? 'bg-danger text-emphasis-warning' : ''}
                          >
                            <td>{[index + 1]}</td>
                            <td>
                              {!isEmpty(data?.image) && (
                                <a href={showImage(data?.image?.original)} target="_blank">
                                  <img src={showImage(data?.image?.original)} width={40} height={40} />
                                </a>
                              )}
                            </td>
                            <td>
                              <Link
                                to={buildURL(SMKLinks.JOB_APPLICATIONS_EDIT, {
                                  id: data?.id,
                                })}>
                                {data?.name}
                              </Link>
                              <br />
                              {data?.slug}
                            </td>
                            <td>{titleCaseIfExists(data?.email)}</td>
                            <td>{data?.phoneNumber}</td>
                            <td>{data?.yearsOfExperience}</td>
                            {/* <td>{data?.currentCTC}</td> */}
                            <td>{data?.applicationStatus}</td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <Link
                                    to={buildURL(SMKLinks.JOB_APPLICATIONS_EDIT, {
                                      id: data?.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>
                                  <DropdownItem onClick={() => handleCVDownload(data)}>
                                    <i className="fas fa-download text-success m-1 pe-3" />
                                    Download CV
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <TableError />
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
          {/* <Paginate pageInfo={appBannerPagination} onChangePage={handleChangePage} /> */}
        </Container>
      </div>
    </>
  );
};

export default JobApplicationsListing;
