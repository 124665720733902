import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { useFormik } from 'formik';
import { useRouter } from 'hooks/useRouter';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { API_BASE_URL, LANGUAGE_ARRAY, VaccinationUsualSiteArray, taxonomyType } from 'utils/constants';
import * as Yup from 'yup';
import TextEditor from '../../../../components/TextEditor';
import useRequest from '../../../../hooks/useRequest';
import { SMKLinks } from '../../../../utils/links';

const UpdateVaccination = () => {
  const history = useHistory();
  const { id: vaccinationID } = useParams();
  const router = useRouter();

  const { query } = router;
  const { duplicateId } = query;

  toast.configure();

  const [vaccinationDetails, setvaccinationDetails] = useState();
  const [regionsList, setregionsList] = useState([]);
  const [vaccinationAgainstList, setvaccinationAgainstList] = useState([]);
  const [ageGroupsList, setageGroupsList] = useState([]);

  // Dropdown Api Call
  const [getregionsListRequest, { loading: getregionsListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,

    method: 'POST',
    onSuccess: (data) => {
      setregionsList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [getvaccinationAgainstListRequest, { loading: getvaccinationAgainstListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,

    method: 'POST',
    onSuccess: (data) => {
      setvaccinationAgainstList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [getageGroupsListRequest, { loading: getageGroupsListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,

    method: 'POST',
    onSuccess: (data) => {
      setageGroupsList(
        data?.results
          ?.sort((a, b) => a.metaData?.value - b.metaData?.value)
          ?.map((e) => ({
            label: e.name,
            value: e.id,
          }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getVaccinationRequest, { loading: getVaccinationLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setvaccinationDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [addVaccinationRequest, { loading: addVaccinationLoading }] = useRequest({
    url: `/vaccination/admin`,

    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.VACCINATION_LISTING;
      history.replace(url);
      toast.success('Success! Vaccination has been added.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [UpdateVaccinationRequest, { loading: UpdateVaccinationLoading }] = useRequest({
    url: `/vaccination/admin/${vaccinationID}`,
    method: 'PUT',

    onSuccess: (data) => {
      const url = SMKLinks.VACCINATION_LISTING;
      history.replace(url);
      toast.success('Success! Vaccination has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const editInitialValues = () => {
    return {
      active: vaccinationDetails?.active,
      deleted: vaccinationDetails?.deleted,
      hasArticles: vaccinationDetails?.hasArticles,
      combinationDoseAvailable: vaccinationDetails?.combinationDoseAvailable,
      isCombinationVaccine: vaccinationDetails?.isCombinationVaccine,
      title: vaccinationDetails?.title,
      slug: vaccinationDetails?.slug,
      language: LANGUAGE_ARRAY?.find((e) => e?.value === vaccinationDetails?.language),
      description: vaccinationDetails?.description,
      content: vaccinationDetails?.content,
      order: vaccinationDetails?.order,
      vaccinationUsualSite: VaccinationUsualSiteArray?.find(
        (e) => e?.value === vaccinationDetails?.vaccinationUsualSite
      ),
      vaccineBrand: vaccinationDetails?.vaccineBrand,
      combinationVaccineId: {
        title: vaccinationDetails?.combinationVaccine?.title,
        id: vaccinationDetails?.combinationVaccine?.id,
      },
      region: regionsList?.find((e) => e?.value === vaccinationDetails?.region?.id),
      vaccinationAgainst: vaccinationAgainstList?.filter((elem) =>
        vaccinationDetails?.vaccinationAgainst?.some((e) => e?.id === elem?.value)
      ),
      ageGroups: ageGroupsList?.filter((elem) => vaccinationDetails?.ageGroups?.some((e) => e?.id === elem?.value)),
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    hasArticles: false,
    combinationDoseAvailable: false,
    isCombinationVaccine: false,
    title: '',
    slug: '',
    language: LANGUAGE_ARRAY[0],
    description: '',
    content: '',
    order: 0,
    vaccinationUsualSite: null,
    vaccineBrand: '',
    combinationVaccineId: {},
    region: '',
    vaccinationAgainst: '',
    ageGroups: '',
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues:
      !isEmpty(vaccinationID) || !isEmpty(duplicateId) ? { ...editInitialValues() } : { ...getInitialValues() },
    onSubmit: async (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        hasArticles: values?.hasArticles,
        combinationDoseAvailable: values?.combinationDoseAvailable,
        isCombinationVaccine: values?.isCombinationVaccine,
        title: values?.title,
        slug: values?.slug,
        language: values?.language?.value,
        description: values?.description,
        content: values?.content,
        order: values?.order,
        vaccinationUsualSite: !isEmpty(values?.vaccinationUsualSite) ? values?.vaccinationUsualSite?.value : null,
        vaccineBrand: values?.vaccineBrand,
        combinationVaccine:
          !values?.combinationDoseAvailable || values?.combinationVaccineId == null ? null : undefined,
        combinationVaccineId: values?.combinationDoseAvailable ? values?.combinationVaccineId?.id : null,
        region: !isEmpty(values?.region) ? { id: values?.region?.value } : {},
        vaccinationAgainst: !isEmpty(values?.vaccinationAgainst)
          ? values?.vaccinationAgainst?.map((e) => ({
              id: e?.value,
            }))
          : [],
        ageGroups: !isEmpty(values?.ageGroups)
          ? values?.ageGroups?.map((e) => ({
              id: e?.value,
            }))
          : [],
      };

      if (isEmpty(vaccinationID) || !isEmpty(duplicateId)) {
        addVaccinationRequest({
          body: {
            ...data,
          },
        });
      } else {
        UpdateVaccinationRequest({
          body: {
            ...data,
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Please provide Title'),
      ageGroups: Yup.array()
        .test('is-non-empty', 'Please provide Age groups', (value) => {
          return value && value.length > 0;
        })
        .nullable(true),
      region: Yup.object()
        .required('Please provide Region')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(vaccinationID) || !isEmpty(duplicateId)) {
      getVaccinationRequest({
        url: `/vaccination/admin/${vaccinationID || duplicateId}`,
      });
    }
  }, [vaccinationID, duplicateId]);

  // Dropdowns data api call
  useEffect(() => {
    const searchPayload = {
      page: 1,
      size: 1000,
      sortBy: 'name',
      sortOrder: 0,
    };
    getregionsListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.VACCINATION_COUNTRY,
      },
    });
    getvaccinationAgainstListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.VACCINATION_AGAINST,
      },
    });
    getageGroupsListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.AGE_GROUP,
      },
    });
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getregionsListLoading ||
            getvaccinationAgainstListLoading ||
            getageGroupsListLoading ||
            addVaccinationLoading ||
            getVaccinationLoading ||
            UpdateVaccinationLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="Vaccination" breadcrumbItem={`${isEmpty(vaccinationID) ? 'Add' : 'Edit'}  Vaccination`} />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="name" className="form-label  ">
                            Title <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="name"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="title..."
                            invalid={!!(formik.touched.title && formik.errors.title)}
                            {...formik.getFieldProps('title')}
                          />
                          <FormFeedback className="d-block">{formik.errors.title}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12} className="mb-4">
                        <SlugInput
                          formik={formik}
                          ID={vaccinationID}
                          nameValue={formik.values.title}
                          formGroupClassName="mb-0"
                        />
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="vaccinationAgainst" className="form-label  ">
                            Vaccination against
                          </Label>
                          <Select
                            isMulti={true}
                            placeholder="Select vaccination against..."
                            options={vaccinationAgainstList}
                            value={formik.values.vaccinationAgainst}
                            onChange={(val) => formik.setFieldValue('vaccinationAgainst', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.vaccinationAgainst}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="ageGroups" className="form-label  ">
                            Age groups
                          </Label>
                          <Select
                            isMulti={true}
                            placeholder="Select age groups..."
                            options={ageGroupsList}
                            value={formik.values.ageGroups}
                            onChange={(val) => formik.setFieldValue('ageGroups', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.ageGroups}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="region" className="form-label  ">
                            Applicable region
                          </Label>
                          <Select
                            isClearable
                            placeholder="Select region..."
                            options={regionsList}
                            value={formik.values.region}
                            onChange={(val) => formik.setFieldValue('region', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.region}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <CardTitle className="mb-3">is Combination Vaccine </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`isCombinationVaccine_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`isCombinationVaccine_${option}`}
                              name="isCombinationVaccine_"
                              className="form-check-input"
                              checked={formik.values.isCombinationVaccine === option}
                              onChange={() => formik.setFieldValue('isCombinationVaccine', option)}
                            />
                            <label className="form-check-label" htmlFor={`isCombinationVaccine_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="vaccineBrand" className="form-label  ">
                            Vaccine brand
                          </Label>
                          <Input
                            id="vaccineBrand"
                            name="vaccineBrand"
                            type="textarea"
                            rows={5}
                            className="form-control"
                            placeholder="Enter vaccine brand..."
                            invalid={!!(formik.touched.vaccineBrand && formik.errors.vaccineBrand)}
                            {...formik.getFieldProps('vaccineBrand')}
                          />
                          <FormFeedback className="d-block">{formik.errors.vaccineBrand}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="vaccinationUsualSite" className="form-label  ">
                            Vaccination usual site
                          </Label>
                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 999 }),
                            }}
                            isClearable
                            placeholder="Select vaccination usual site..."
                            options={VaccinationUsualSiteArray}
                            value={formik.values.vaccinationUsualSite}
                            onChange={(val) => formik.setFieldValue('vaccinationUsualSite', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.vaccinationUsualSite}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <CardTitle className="mb-3">Combination dose available </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`combinationDoseAvailable_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`combinationDoseAvailable_${option}`}
                              name="combinationDoseAvailable"
                              className="form-check-input"
                              checked={formik.values.combinationDoseAvailable === option}
                              onChange={() => formik.setFieldValue('combinationDoseAvailable', option)}
                            />
                            <label className="form-check-label" htmlFor={`combinationDoseAvailable_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>

                      {formik.values.combinationDoseAvailable && (
                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label className="col-form-label ">Combination vaccine ID </Label>
                            <SmkAsyncSelect
                              acceptedKey={'title'}
                              acceptedValue={'id'}
                              placeholder="Type combination vaccine.."
                              onChange={(val) => formik.setFieldValue('combinationVaccineId', val)}
                              fetchUrl={`${API_BASE_URL}/vaccination/admin/search`}
                              filters={{
                                autoComplete: true,
                                page: 1,
                                size: 50,
                                sortBy: 'slug',
                                sortOrder: 1,
                                regionId: !isEmpty(formik?.values?.region?.value)
                                  ? formik?.values?.region?.value
                                  : null,
                              }}
                              searchKeyName="name"
                              value={formik.values.combinationVaccineId?.title}
                            />
                            <FormFeedback className="d-block">{formik.errors.combinationVaccineId}</FormFeedback>
                          </FormGroup>
                        </Col>
                      )}

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="description" className="form-label">
                            Description
                          </Label>
                          <Input
                            id="description"
                            name="description"
                            type="textarea"
                            rows={5}
                            className="form-control"
                            placeholder="Enter description..."
                            invalid={!!(formik.touched.description && formik.errors.description)}
                            {...formik.getFieldProps('description')}
                          />
                          <FormFeedback className="d-block">{formik.errors.description}</FormFeedback>
                        </FormGroup>
                      </Col>

                      {formik.values.content !== null && formik.values.content !== undefined && (
                        <Col md={12}>
                          <FormGroup className="mb-4">
                            <Label for="content" className="form-label  ">
                              Content
                            </Label>
                            <TextEditor
                              simple
                              height={300}
                              initialValue={formik.values.content}
                              onChange={(val) => formik.setFieldValue('content', val)}
                            />

                            <FormFeedback className="d-block">{formik.errors.content}</FormFeedback>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <Button type="submit" color="primary" className="mb-3 w-100">
                          {vaccinationID ? 'Save Vaccination' : 'Add Vaccination'}
                        </Button>

                        <Button
                          className="w-100"
                          color="light"
                          onClick={() => {
                            history.replace(SMKLinks.VACCINATION_LISTING);
                          }}>
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <CardTitle className="mb-3">Active </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`active_${option}`}
                              name="active"
                              className="form-check-input"
                              checked={formik.values.active === option}
                              onChange={() => formik.setFieldValue('active', option)}
                            />
                            <label className="form-check-label" htmlFor={`active_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>

                      <Col md={12}>
                        <CardTitle className="mb-3">Delete </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`deleted_${option}`}
                              name="deleted"
                              className="form-check-input"
                              checked={formik.values.deleted === option}
                              onChange={() => formik.setFieldValue('deleted', option)}
                            />
                            <label className="form-check-label" htmlFor={`deleted_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>

                      <Col md={12}>
                        <CardTitle className="mb-3">hasArticles </CardTitle>
                        {[true, false].map((option, i) => (
                          <div key={`hasArticles_${i}`} className="form-check mb-3 d-inline-block me-4">
                            <input
                              type="radio"
                              id={`hasArticles_${option}`}
                              name="hasArticles"
                              className="form-check-input"
                              checked={formik.values.hasArticles === option}
                              onChange={() => formik.setFieldValue('hasArticles', option)}
                            />
                            <label className="form-check-label" htmlFor={`hasArticles_${option}`}>
                              {option ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ))}
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="language" className="form-label">
                            Language
                          </Label>
                          <Select
                            id="language"
                            options={LANGUAGE_ARRAY}
                            value={formik.values.language}
                            onChange={(language) => formik.setFieldValue('language', language)}
                          />
                          <FormFeedback className="d-block">{formik.errors.language}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="order" className="form-label  ">
                            Order
                          </Label>
                          <Input
                            id="order"
                            name="order"
                            type="number"
                            className="form-control"
                            placeholder="Enter order..."
                            invalid={!!(formik.touched.order && formik.errors.order)}
                            {...formik.getFieldProps('order')}
                          />
                          <FormFeedback className="d-block">{formik.errors.order}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateVaccination;
