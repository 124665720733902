import { createRoutine } from 'redux-saga-routines';
import {
  ADD_COLLEGE,
  GET_CITIES,
  GET_COUNTRIES,
  GET_REGIONS,
  GET_COLLEGE_COURSES,
  GET_COLLEGE_DETAIL,
  GET_COLLEGES_INFRA_LIST,
  GET_COLLEGE_MASTER_DATA,
  GET_SUB_AREAS,
  COLLEGE_LIST,
  GET_COLLEGES_GROUPS,
  GET_COLLEGE_COURSES_SEARCH_DATA,
  GET_COLLEGE_COURSES_MASTER_DATA,
  UPDATE_COLLEGE_BASIC_DETAILS,
  UPDATE_COLLEGE_CONTACT_DETAILS,
  UPDATE_COLLEGE_ADMISSION_DETAILS,
  UPDATE_COLLEGE_FEE_DETAILS,
  UPDATE_COLLEGE_ACADEMIC_DETAILS,
  UPDATE_COLLEGE_FACILITY_DETAILS,
  UPDATE_COLLEGE_EXTRA_CURRICULAR_DETAILS,
  UPDATE_COLLEGE_ADDITIONAL_DETAILS,
  UPDATE_COLLEGE_SYSTEM_DETAILS,
  GET_COLLEGE_ADMISSION_INQUIRIES,
  COLLEGE_ADMISSION_INQUIRIES_MARK_ACTION_TAKEN,
  COLLEGE_ADMISSION_INQUIRIES_MARK_DELETED,
  COLLEGE_REVIEW_APPROVE,
  COLLEGE_REVIEW_DELETE,
  GET_COLLEGE_REVIEWS,
} from './actionTypes';

// ACTIONS
export const getColleges = createRoutine(COLLEGE_LIST);

export const addCollege = createRoutine(ADD_COLLEGE);
export const getCollegeDetail = createRoutine(GET_COLLEGE_DETAIL);

// NOTE:
export const getCollegeCourses = createRoutine(GET_COLLEGE_COURSES);
export const getCollegesMasterData = createRoutine(GET_COLLEGE_MASTER_DATA);

export const getCollegesInfraList = createRoutine(GET_COLLEGES_INFRA_LIST);
export const getCollegesGroups = createRoutine(GET_COLLEGES_GROUPS);

export const getCollegesCoursesMasterData = createRoutine(GET_COLLEGE_COURSES_MASTER_DATA);
export const getCollegeCoursesSearchData = createRoutine(GET_COLLEGE_COURSES_SEARCH_DATA);

export const updateCollegeBasicDetails = createRoutine(UPDATE_COLLEGE_BASIC_DETAILS);
export const updateCollegeContactDetails = createRoutine(UPDATE_COLLEGE_CONTACT_DETAILS);
export const updateCollegeAdmissionDetails = createRoutine(UPDATE_COLLEGE_ADMISSION_DETAILS);
export const updateCollegeFeeDetails = createRoutine(UPDATE_COLLEGE_FEE_DETAILS);
export const updateCollegeAcademicDetails = createRoutine(UPDATE_COLLEGE_ACADEMIC_DETAILS);
export const updateCollegeFacilityDetails = createRoutine(UPDATE_COLLEGE_FACILITY_DETAILS);
export const updateCollegeExtraCurricularDetails = createRoutine(UPDATE_COLLEGE_EXTRA_CURRICULAR_DETAILS);
export const updateCollegeAdditionalDetails = createRoutine(UPDATE_COLLEGE_ADDITIONAL_DETAILS);
export const updateCollegeSystemDetails = createRoutine(UPDATE_COLLEGE_SYSTEM_DETAILS);

export const getCountries = createRoutine(GET_COUNTRIES);
export const getRegions = createRoutine(GET_REGIONS);
export const getCities = createRoutine(GET_CITIES);
export const getSubAreas = createRoutine(GET_SUB_AREAS);

export const getCollegeAdmissionInquiries = createRoutine(GET_COLLEGE_ADMISSION_INQUIRIES);
export const collegeAdmissionInquiriesMarkActionTaken = createRoutine(COLLEGE_ADMISSION_INQUIRIES_MARK_ACTION_TAKEN);
export const collegeAdmissionInquiriesMarkDeleted = createRoutine(COLLEGE_ADMISSION_INQUIRIES_MARK_DELETED);

export const getCollegeReviews = createRoutine(GET_COLLEGE_REVIEWS);
export const collegeReviewApprove = createRoutine(COLLEGE_REVIEW_APPROVE);
export const collegeReviewDelete = createRoutine(COLLEGE_REVIEW_DELETE);
