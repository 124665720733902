import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import { useFormik } from 'formik';
import { invert } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { LANGUAGE_ARRAY_WITH_STRING, LANGUAGE_VALUES_STRING, MODULE_TYPE, TOPIC_STATUS_STING } from 'utils/constants';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';

const TopicEdit = () => {
  const history = useHistory();
  const { id: topicId } = useParams();

  toast.configure();

  const [topicdetails, settopicdetails] = useState();
  const [categorySelectList, setcategorySelectList] = useState();
  const [subCategorySelectList, setSubCategorySelectList] = useState();

  const selectStatusOptions = [];

  for (const [key, value] of Object.entries(invert(TOPIC_STATUS_STING))) {
    selectStatusOptions?.push({
      label: key,
      value,
    });
  }

  const [getTopicRequest, { loading: getTopicLoading }] = useRequest({
    url: `/forum/admin/topic/search`,
    method: 'POST',
    onSuccess: (data) => {
      settopicdetails(data?.results[0]);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [categoryListSelectRequest, { loading: categoryListSelectLoading }] = useRequest({
    method: 'POST',
    url: `/forum/admin/forum-category/search`,
    onSuccess: (data) => {
      setcategorySelectList(data.results?.filter((c) => c?.parentCategory == null));
      setSubCategorySelectList(data.results?.filter((c) => c?.parentCategory !== null));
    },
  });

  const [updateTopicRequest, { loading: updateTopicLoading }] = useRequest({
    url: `/forum/admin/topic/update`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.TOPIC_LIST;
      history.replace(url);
      toast.success(`Success! Topic ( ${data?.id} ) has been updated.`);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => {
    const statusObj = selectStatusOptions?.find((e) => e?.value == topicdetails?.status);
    const languageObj = LANGUAGE_ARRAY_WITH_STRING?.find((e) => e?.value === topicdetails?.language);
    const categoryObj = categorySelectList?.find((e) => e?.id == topicdetails?.category?.id);
    const subCategoriesObj = topicdetails?.subCategories?.map((elem) => elem);
    const json = !isEmpty(topicdetails?.jsonContent)
      ? JSON.stringify(JSON.parse(topicdetails?.jsonContent), null, 2)
      : '';

    return {
      id: topicdetails?.id,
      isFeatured: topicdetails?.isFeatured,
      active: topicdetails?.active,
      deleted: topicdetails?.deleted,

      title: topicdetails?.title,
      slug: topicdetails?.slug,
      comments: topicdetails?.comments,
      jsonContent: json,

      language: !isEmpty(languageObj) ? languageObj : LANGUAGE_ARRAY_WITH_STRING[0],
      status: !isEmpty(statusObj) ? statusObj : null,
      category: !isEmpty(categoryObj)
        ? {
            label: categoryObj?.name,
            value: categoryObj?.id,
          }
        : null,

      subCategories: !isEmpty(subCategoriesObj)
        ? subCategoriesObj?.map((e) => {
            return {
              ...e,
              label: e?.name,
              value: e,
            };
          })
        : null,
    };
  };

  const convertJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return parseObj;
    } catch (err) {
      toast.error(err);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: { ...editInitialValues() },
    onSubmit: (values) => {
      const subCategoriesArray = [];
      values?.subCategories?.map((e) => {
        subCategoriesArray?.push(e?.value);
        return subCategoriesArray;
      });

      const data = {
        id: topicdetails?.id,
        active: values?.active,
        isFeatured: values?.isFeatured,
        deleted: values?.deleted,
        language: values?.language?.value,
        category: !isEmpty(values?.category)
          ? {
              id: values?.category?.value,
            }
          : undefined,
        subCategories: !isEmpty(values?.subCategories) ? subCategoriesArray : undefined,
        status: Number(values?.status?.value),
        title: values?.title,
        slug: values?.slug,
        comments: values?.comments,
        jsonContent: convertJSON(values?.jsonContent),
      };

      updateTopicRequest({
        body: {
          ...data,
        },
      });
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Please provide title'),
      slug: Yup.string().required('Please provide slug'),
      status: Yup.object()
        .required('Please provide for status')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(topicId)) {
      getTopicRequest({
        body: {
          page: 1,
          size: 20,
          sortBy: 'id',
          id: topicId,
        },
      });

      categoryListSelectRequest({
        body: {
          page: 1,
          size: 500,
          sortBy: 'id',
          sortOrder: 0,
          forType: MODULE_TYPE?.ARTICLE,
          language: formik.values.language?.value,
          // parentCategoryOnly: true,
        },
      });
    }
  }, [topicId]);

  return (
    <div className="page-content">
      <Loader isActive={getTopicLoading || updateTopicLoading || categoryListSelectLoading} />
      <Container fluid>
        <Breadcrumbs title="Location" breadcrumbItem={`${isEmpty(topicId) ? 'Add' : 'Edit'}  Topic`} />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    {topicId && (
                      <Col md={topicId ? 6 : 12}>
                        <FormGroup className="mb-4">
                          <Label className="form-label  ">Topic ID</Label>
                          <Input
                            id="id"
                            style={{ background: 'whitesmoke' }}
                            disabled
                            className="form-control"
                            {...formik.getFieldProps('id')}
                          />
                        </FormGroup>
                      </Col>
                    )}

                    <Col md={topicId ? 6 : 12}>
                      <FormGroup className="mb-4">
                        <Label for="language" className="form-label  ">
                          Language
                        </Label>
                        <Select
                          id="language"
                          options={LANGUAGE_ARRAY_WITH_STRING}
                          value={formik?.values?.language}
                          onChange={(language) => formik.setFieldValue('language', language)}
                        />
                        <FormFeedback>{formik.errors.language}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="title" className="form-label  ">
                          Title
                        </Label>
                        <Input
                          id="title"
                          name="title"
                          type="text"
                          className="form-control"
                          placeholder="Title.."
                          invalid={!!(formik.touched.title && formik.errors.title)}
                          {...formik.getFieldProps('title')}
                        />
                        <FormFeedback>{formik.errors.title}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12} style={{ marginBottom: '20px' }}>
                      <FormGroup className="mb-3">
                        <SlugInput
                          formik={formik}
                          ID={topicId}
                          nameValue={formik.values.name}
                          formGroupClassName={'mt-0'}
                        />
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="status" className="form-label ">
                          Status
                        </Label>
                        <Select
                          isClearable
                          options={selectStatusOptions}
                          value={formik.values.status}
                          onChange={(status) => formik.setFieldValue('status', status)}
                        />
                        {formik.touched.status && (
                          <div className="invalid-feedback d-block">{formik.errors.status}</div>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="category" className="form-label ">
                          Category
                        </Label>
                        <Select
                          isClearable
                          options={categorySelectList?.map((s) => ({
                            value: s.id,
                            label: s.name,
                          }))}
                          value={formik.values.category}
                          onChange={(category) => formik.setFieldValue('category', category)}
                        />
                        {formik.touched.category && (
                          <div className="invalid-feedback d-block">{formik.errors.category}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="mb-4">
                        <Label htmlFor="category" className="form-label ">
                          Sub Category
                        </Label>
                        <Select
                          isClearable
                          isMulti
                          options={subCategorySelectList?.map((s) => ({
                            value: s,
                            label: s.name,
                          }))}
                          value={formik.values.subCategories}
                          onChange={(subCategories) => formik.setFieldValue('subCategories', subCategories)}
                        />
                        {formik.touched.subCategories && (
                          <div className="invalid-feedback d-block">{formik.errors.subCategories}</div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="jsonContent" className="form-label  ">
                          Json Content
                        </Label>
                        <Input
                          id="jsonContent"
                          name="jsonContent"
                          rows={5}
                          type="textarea"
                          className="form-control"
                          placeholder="Json Content.."
                          invalid={!!(formik.touched.jsonContent && formik.errors.jsonContent)}
                          {...formik.getFieldProps('jsonContent')}
                        />
                        <FormFeedback>{formik.errors.jsonContent}</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="comments" className="form-label  ">
                          Comments
                        </Label>
                        <Input
                          id="comments"
                          name="comments"
                          style={{ height: '100px' }}
                          type="textarea"
                          className="form-control"
                          placeholder="Comments.."
                          invalid={!!(formik.touched.comments && formik.errors.comments)}
                          {...formik.getFieldProps('comments')}
                        />
                        <FormFeedback>{formik.errors.comments}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <div>
                    <Button type="submit" color="primary" className="mb-3 w-100">
                      {topicId ? 'Save' : 'Add'}
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.TOPIC_LIST);
                      }}>
                      Cancel
                    </Button>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <CardTitle className="mb-3">Active </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>
                    <Col md={12}>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>
                    <Col md={12}>
                      <CardTitle className="mb-3">Is Featured </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`isFeatured_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`isFeatured_${option}`}
                            name="isFeatured"
                            className="form-check-input"
                            checked={formik.values.isFeatured === option}
                            onChange={() => formik.setFieldValue('isFeatured', option)}
                          />
                          <label className="form-check-label" htmlFor={`isFeatured_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default TopicEdit;
