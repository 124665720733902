import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { api } from '../../utils/axios';
import config from '../../config/config';
import { SMKLinks } from '../../utils/links';
import {
  addLocation,
  updateLocation,
  getLocations,
  getCities,
  getCountries,
  getRegions,
  getSubAreas,
  getDistricts,
} from './actions';
import { POST, PUT, GET } from '../../utils/httpMethods';
import { setGlobalError } from '../../common/globalComponents/actions';

export const ADD_LOCATION_API_URL = `${config.apiEndpoint}/place/`;
export const UPDATE_LOCATION_API_URL = `${config.apiEndpoint}/place/:id`;
export const GET_PLACES = `${config.apiEndpoint}/place/admin/search`;

const getPlaceCall = (filter) =>
  api({
    method: POST,
    url: GET_PLACES,
    data: filter,
  });

const addLocationCall = (place) =>
  api({
    method: POST,
    url: ADD_LOCATION_API_URL,
    data: place,
  });

const updateLocationCall = (place) =>
  api({
    method: POST,
    url: ADD_LOCATION_API_URL,
    data: place,
  });

function* getLocationsRequest(action) {
  try {
    yield put(getLocations.request({ isLoading: true }));
    const response = yield call(getPlaceCall, action.payload);
    if (response) {
      yield put(getLocations.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getLocations.failure(error));
  } finally {
    yield put(getLocations.fulfill({ isLoading: false }));
  }
}

function* getCountriesRequest(action) {
  try {
    yield put(getCountries.request({ isLoading: true }));
    const response = yield call(getPlaceCall, action.payload);
    if (response) {
      yield put(getCountries.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getCountries.failure(error));
  } finally {
    yield put(getCountries.fulfill({ isLoading: false }));
  }
}

function* getRegionsRequest(action) {
  try {
    yield put(getRegions.request({ isLoading: true }));
    const response = yield call(getPlaceCall, action.payload);
    if (response) {
      yield put(getRegions.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getRegions.failure(error));
  } finally {
    yield put(getRegions.fulfill({ isLoading: false }));
  }
}
function* getDistrcitsRequest(action) {
  try {
    yield put(getDistricts.request({ isLoading: true }));
    const response = yield call(getPlaceCall, action.payload);
    if (response) {
      yield put(getDistricts.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getDistricts.failure(error));
  } finally {
    yield put(getDistricts.fulfill({ isLoading: false }));
  }
}

function* getCitiesRequest(action) {
  try {
    yield put(getCities.request({ isLoading: true }));
    const response = yield call(getPlaceCall, action.payload);
    if (response) {
      yield put(getCities.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getCities.failure(error));
  } finally {
    yield put(getCities.fulfill({ isLoading: false }));
  }
}

function* getSubAreasRequest(action) {
  try {
    yield put(getSubAreas.request({ isLoading: true }));
    const response = yield call(getPlaceCall, action.payload);
    if (response) {
      yield put(getSubAreas.success({ ...response }));
    } else {
      yield put(setGlobalError.success(response));
    }
  } catch (error) {
    yield put(getSubAreas.failure(error));
  } finally {
    yield put(getSubAreas.fulfill({ isLoading: false }));
  }
}

function* addLocationRequest(action) {
  try {
    yield put(addLocation.request({ isLoading: true }));

    const { location } = action.payload;

    const response = yield call(addLocationCall, location);

    if (response) {
      const { addedLocation } = response;
      yield put(addLocation.success({ location: addedLocation }));
      yield put(push(SMKLinks.LOCATION_LISTING));
    }
  } catch (error) {
    yield put(addLocation.failure(error));
  } finally {
    yield put(addLocation.fulfill({ isLoading: false }));
  }
}

function* updateLocationRequest(action) {
  try {
    yield put(updateLocation.request({ isLoading: true }));

    const { location } = action.payload;

    const response = yield call(updateLocationCall, location);

    if (response) {
      const { addedLocation } = response;
      yield put(addLocation.success({ location: addedLocation }));
      yield put(push(SMKLinks.LOCATION_LISTING));
    }
  } catch (error) {
    yield put(addLocation.failure(error));
  } finally {
    yield put(addLocation.fulfill({ isLoading: false }));
  }
}

export default function* locationSaga() {
  yield takeLatest(addLocation.TRIGGER, addLocationRequest);
  yield takeLatest(updateLocation.TRIGGER, updateLocationRequest);
  yield takeLatest(getLocations.TRIGGER, getLocationsRequest);

  yield takeLatest(getCountries.TRIGGER, getCountriesRequest);
  yield takeLatest(getRegions.TRIGGER, getRegionsRequest);
  yield takeLatest(getDistricts.TRIGGER, getDistrcitsRequest);
  yield takeLatest(getCities.TRIGGER, getCitiesRequest);
  yield takeLatest(getSubAreas.TRIGGER, getSubAreasRequest);
}
