import { useAccount, useMsal } from '@azure/msal-react';
import { useRouter } from 'hooks/useRouter';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { doSocialLogin } from './actions';
import { toast } from 'react-toastify';
import { admins_list } from 'utils/constants';

function MicrosoftLoginRedirect() {
  const { instance, accounts } = useMsal();
  toast.configure();

  const account = useAccount(accounts[0] || {});
  const dispatch = useDispatch();
  const router = useRouter();
  const userType = router?.query?.userType;

  useEffect(() => {
    const getProfileData = async () => {
      await instance.initialize();
      try {
        const response = await instance.acquireTokenSilent({
          scopes: ['User.Read'],
          account: account,
        });

        if (response?.accessToken) {
          localStorage.setItem('accessToken', response.accessToken);
        }

        if (userType === 'hr') {
          const isAdmin = admins_list?.includes(response?.account?.username);

          if (!isAdmin) {
            router?.replace('/auth/login');
            toast.error('Unauthorize Access');
            console.error('Unauthorize Access', error);
            return;
          } else {
            // router?.push('/hr');
            window.location.href = '/hr';
          }
        } else {
          if (response && response.accessToken) {
            const userObj = {
              name: response.account?.name,
              email: response.account?.username,
              googleId: response?.account?.homeAccountId || '',
              imageUrl: '',
            };
            dispatch(doSocialLogin.trigger({ userObj, userType: userType ? userType : 'schoolmykids' }));
          }
        }
      } catch (error) {
        router?.replace('/auth/login');
        console.error('Error acquiring token silently or fetching data', error);
      }
    };

    getProfileData();
  }, [account, instance]);

  return <h4>Redirecting...</h4>;
}

export default MicrosoftLoginRedirect;
