import { BLOG_STATUS_ENUM } from 'containers/blog/helper';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { titleCaseIfExists } from 'utils/helper';
import useRequest from '../../../hooks/useRequest';
import { bloggerEmailsList } from 'utils/constants';
import Loader from '../../../components/Loader';

function ArticleTable() {
  const [months, setMonths] = useState([]);
  const [data, setData] = useState([]);

  const [
    getMonthlyPublishedArticlesByUsersRequest,
    { loading: getMonthlyPublishedArticlesByUsersLoading },
  ] = useRequest({
    url: `/article/admin/monthly-published-articles-count`,
    method: 'POST',
    onSuccess: (data) => {
      setData(data.filter((e) => bloggerEmailsList.includes(e.email)));
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getMonthlyPublishedArticlesByUsersRequest();
  }, []);

  const processAndFormatData = () => {
    // Get months starting from the current month going backwards
    const currentMonth = new Date();
    const monthsToShow = 4; // Number of months to display
    const monthsArray = Array.from({ length: monthsToShow }, (_, index) =>
      moment(currentMonth)
        .subtract(index, 'months')
        .toDate()
    );

    setMonths(monthsArray);
  };

  useEffect(() => {
    processAndFormatData();
  }, []);

  return (
    <Card>
      <Loader isActive={getMonthlyPublishedArticlesByUsersLoading} />
      <CardBody>
        <CardTitle>Articles Count</CardTitle>
        <div className=" ">
          <table className="table  table-bordered  " id="articles-table">
            <thead className="">
              <tr>
                <th>Writer</th> <th>Total</th>
                {Object.keys(BLOG_STATUS_ENUM)?.map((e, index) => (
                  <th key={index}>{titleCaseIfExists(e)}</th>
                  // BLOG_STATUS_ENUM[e]
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.map((user) => (
                <>
                  <tr key={user?.user_id} className="h5 bg-success">
                    <th>{`${user?.first_name} ${user?.last_name}`}</th> <td></td>
                    {Object.keys(BLOG_STATUS_ENUM)?.map((e, index) => {
                      return (
                        <td key={index}>
                          {Object.values(user?.monthlyData)
                            .map((t) => t[e.toLowerCase()])
                            ?.reduce((a, b) => a + b, 0)}
                        </td>
                      );
                    })}
                  </tr>
                  {months?.map((month, index) => {
                    const monthKey = moment(month).format('MMM yyyy');
                    return (
                      <tr key={user?.user_id}>
                        <td>{monthKey}</td>
                        <td key={index}>{user?.monthlyData[monthKey]?.total || '-'}</td>
                        {Object.keys(BLOG_STATUS_ENUM)?.map((e, index) => {
                          const monthKey = moment(month).format('MMM yyyy');
                          return <td key={index}>{user?.monthlyData[monthKey]?.[BLOG_STATUS_ENUM[e]] || '-'}</td>;
                        })}
                      </tr>
                    );
                  })}
                </>
              ))}
              {isEmpty(data) && (
                <>
                  <tr>
                    <td colSpan={9} style={{ textAlign: 'center' }}>
                      No Data
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
}

export default ArticleTable;
