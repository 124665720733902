import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import {
  ACTIVE_AND_DELETED_ARRAY,
  LANGUAGE_ARRAY_WITH_STRING,
  SORT_VALUES_SLUG,
  YES_AND_NO_ARRAY,
} from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { buildURL, checkIfValueExist, printSerialNumbers, showImage } from '../../../utils/helper';

const StaticPagesList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [staticPagesList, setstaticPagesList] = useState([]);
  const [staticPagesListPagination, setstaticPagesListPagination] = useState({});

  const defaultFilters = {
    active: null,
    deleted: null,
    language: LANGUAGE_ARRAY_WITH_STRING[0]?.value,
    isStaticContent: false,
    slug: '',
    module: '',
    subModule: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [updatePageRequest, { loading: updatePageLoading }] = useRequest({
    method: 'PUT',
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success('Page has been updated');
    },
  });

  const [getStaticPagesListRequest, { loading: getStaticPagesListLoading }] = useRequest({
    url: '/cms/admin/page/search',
    method: 'POST',
    onSuccess: (data) => {
      setstaticPagesList(data?.results);
      setstaticPagesListPagination(data?.pagination);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const payload = {
      page,
      size: 20,
      ...SORT_VALUES_SLUG,
      ...filters,
      active: checkIfValueExist(filters?.active),
      deleted: checkIfValueExist(filters?.deleted),
      isStaticContent: checkIfValueExist(filters?.isStaticContent),
      language: checkIfValueExist(filters?.language),
      slug: checkIfValueExist(filters?.slug?.trim()),
      module: checkIfValueExist(filters?.module?.trim()),
      subModule: checkIfValueExist(filters?.subModule?.trim()),
    };
    getStaticPagesListRequest({
      body: payload,
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    const defaultPayload = {
      page: 1,
      size: 20,
      ...SORT_VALUES_SLUG,
      ...defaultFilters,
      active: checkIfValueExist(defaultFilters?.active),
      deleted: checkIfValueExist(defaultFilters?.deleted),
      isStaticContent: checkIfValueExist(defaultFilters?.isStaticContent),
      language: checkIfValueExist(defaultFilters?.language),
      slug: checkIfValueExist(defaultFilters?.slug?.trim()),
      module: checkIfValueExist(defaultFilters?.module?.trim()),
      subModule: checkIfValueExist(defaultFilters?.subModule?.trim()),
    };
    getStaticPagesListRequest({
      body: defaultPayload,
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  const handleDeletePage = (data) => {
    updatePageRequest({
      url: `/cms/admin/page/${data?.id}`,
      data: {
        ...data,
        active: !data.active,
        deleted: !data?.deleted,
      },
    });
  };

  return (
    <>
      <div className="page-content">
        <Loader isActive={getStaticPagesListLoading || updatePageLoading} />

        <Container fluid>
          <Breadcrumbs title="Pages" breadcrumbItem="Pages List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <Row>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={ACTIVE_AND_DELETED_ARRAY?.find((e) => e?.value === filters?.active) || null}
                      placeholder="Select Status..."
                      onChange={(e) => setFilters({ ...filters, active: e?.value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={ACTIVE_AND_DELETED_ARRAY?.find((e) => e?.value === filters?.deleted) || null}
                      placeholder="Select Status..."
                      onChange={(e) => setFilters({ ...filters, deleted: e?.value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">isStaticContent</label>
                    <Select
                      options={YES_AND_NO_ARRAY}
                      value={YES_AND_NO_ARRAY?.find((e) => e?.value === filters?.isStaticContent) || null}
                      onChange={(e) => setFilters({ ...filters, isStaticContent: e?.value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Language</label>
                    <Select
                      options={LANGUAGE_ARRAY_WITH_STRING}
                      value={LANGUAGE_ARRAY_WITH_STRING?.find((e) => e?.value === filters?.language) || null}
                      onChange={(e) => setFilters({ ...filters, language: e?.value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Slug</label>
                    <Input
                      type="text"
                      placeholder="slug..."
                      value={filters.slug}
                      onChange={(e) => setFilters({ ...filters, slug: e?.target?.value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Module</label>
                    <Input
                      type="text"
                      placeholder="module..."
                      value={filters.module}
                      onChange={(e) => setFilters({ ...filters, module: e?.target?.value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">SubModule</label>
                    <Input
                      type="text"
                      placeholder="subModule..."
                      value={filters.subModule}
                      onChange={(e) => setFilters({ ...filters, subModule: e?.target?.value })}
                    />
                  </div>
                </Row>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {staticPagesListPagination?.totalCount || 0} Pages Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.STATIC_PAGES_ADD}>
                  <i className="mdi mdi-plus me-1" />
                  Add Pages
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '60px' }}>
                          S.No.
                        </th>
                        <th scope="col"></th>
                        <th scope="col">Title / Slug</th>
                        <th scope="col">Translation</th>
                        <th scope="col">Module</th>
                        <th scope="col">Sub Module</th>
                        <th scope="col">Language</th>
                        <th scope="col">Approved</th>
                        <th scope="col">isStaticContent</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(staticPagesList) ? (
                        staticPagesList?.map((elem, index) => (
                          <tr key={`_inde_${elem.id}`}>
                            <td>{printSerialNumbers(staticPagesListPagination)[index]}</td>
                            <td>
                              {!isEmpty(elem?.image) && (
                                <a href={showImage(elem?.image?.original)} target="_blank">
                                  <img src={showImage(elem?.image?.original)} width={40} height={40} />
                                </a>
                              )}
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <Link
                                  to={buildURL(SMKLinks.STATIC_PAGES_EDIT, {
                                    id: elem?.id,
                                  })}>
                                  {elem?.title}
                                </Link>
                              </div>
                              {elem?.slug}
                            </td>
                            <td>
                              {!isEmpty(elem.translations) && (
                                <div>
                                  {
                                    LANGUAGE_ARRAY_WITH_STRING?.find(
                                      (e) => e?.value === elem?.translations[0]?.language
                                    )?.label
                                  }{' '}
                                  -
                                  <Link
                                    to={buildURL(SMKLinks.STATIC_PAGES_EDIT, {
                                      id: elem?.translations[0]?.id,
                                    })}>
                                    {elem?.translations[0]?.title}
                                  </Link>
                                </div>
                              )}
                            </td>
                            <td>{elem?.module}</td>
                            <td>{elem?.subModule}</td>
                            <td>{elem?.language === 'en' ? 'English' : 'Hindi'}</td>
                            <td>
                              <Badge className={elem?.isStaticContent ? 'bg-success' : 'bg-danger'}>
                                {elem?.isStaticContent ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={elem?.approved ? 'bg-success' : 'bg-danger'}>
                                {elem?.approved ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={elem?.active ? 'bg-success' : 'bg-danger'}>
                                {elem?.active ? 'Active' : 'Disabled'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={elem?.deleted ? 'bg-success' : 'bg-danger'}>
                                {elem?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>

                            <td className="text-center">
                              {
                                <UncontrolledDropdown>
                                  <DropdownToggle className="card-drop" tag="a">
                                    <i className="mdi mdi-dots-vertical font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    <DropdownItem className="border-bottom">
                                      <Link
                                        to={buildURL(SMKLinks.STATIC_PAGES_EDIT, {
                                          id: elem?.id,
                                        })}>
                                        <div>
                                          <i className="mdi mdi-pencil font-size-16 text-success me-1" />{' '}
                                          <span style={{ color: '#212529' }}>Edit</span>
                                        </div>
                                      </Link>
                                    </DropdownItem>

                                    <DropdownItem onClick={() => handleDeletePage(elem)}>
                                      <div>
                                        <i
                                          className={`mdi mdi-trash-can-outline font-size-16 ${
                                            elem?.deleted ? 'text-success' : 'text-danger'
                                          } me-1`}
                                        />
                                        <span style={{ color: '#212529' }}>
                                          {elem?.deleted ? 'Undelete' : 'Delete'}
                                        </span>
                                      </div>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              }
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {!isEmpty(staticPagesList) && (
            <Paginate pageInfo={staticPagesListPagination} onChangePage={handleChangePage} />
          )}
        </Container>
      </div>
    </>
  );
};

export default StaticPagesList;
