import React from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';

export const formatMomentAsDateTime = (momentObj) => {
  if (isEmpty(momentObj)) return '';
  return momentObj?.format('YYYY-MM-DD HH:mm:ss');
};

export const DATE_RANGE_LABELS = [
  'Today',
  'Yesterday',
  'This Week',
  'This month',
  'Last Week',
  'Last week (Same day)',
  'Last month',
  'Last month (Same day)',
  'Last 7 days',
  'Last 28 days',
  'Last 30 days',
  'Last 90 days',
  'Last 12 months',
  'Last calendar year',
  'This year (Jan - Today)',
  'Preceding period',
  'Same period last year',
  'LFL MTD',
  'LFL YTD',
  'Custom',
];

export const handleSetPrecedingMonth = (dateRange, setData) => {
  const dateRangeStartDate = moment(dateRange?.dateRange?.startDate);
  const dateRangeEndDate = moment(dateRange?.dateRange?.endDate);

  if (dateRangeStartDate?.isSame(dateRangeEndDate, 'day')) {
    setData({
      ...dateRange,
    });
  } else {
    setData({
      ...dateRange,
    });
  }
};

export const handleSetPrecedingPeriodWithDateRange = (startDate, endDate) => {
  const dateRangeStartDate = moment(startDate);
  const dateRangeEndDate = moment(endDate);
  const numberOfDays = dateRangeEndDate?.diff(dateRangeStartDate, 'days');
  return {
    startDate: formatMomentAsDateTime(moment(dateRangeStartDate)?.subtract(numberOfDays + 1, 'day')),
    endDate: formatMomentAsDateTime(moment(dateRangeStartDate)?.subtract(1, 'day')),
  };
};
export const handleSetPrecedingPeriod = (dateRange, setData) => {
  const dateRangeStartDate = moment(dateRange?.dateRange?.startDate);
  const dateRangeEndDate = moment(dateRange?.dateRange?.endDate);
  const numberOfDays = dateRangeEndDate?.diff(dateRangeStartDate, 'days');
  setData({
    dateRange: {
      startDate: formatMomentAsDateTime(moment(dateRangeStartDate)?.subtract(numberOfDays + 1, 'day')),
      endDate: formatMomentAsDateTime(moment(dateRangeStartDate)?.subtract(1, 'day')),
    },
  });
};

export const handleSetLFL_MTD = (dateRange, setData) => {
  setData({
    dateRange: {
      startDate: formatMomentAsDateTime(moment(dateRange?.dateRange?.startDate)?.subtract(1, 'month')),
      endDate: formatMomentAsDateTime(moment(dateRange?.dateRange?.endDate)?.subtract(1, 'month')),
    },
  });
};

export const handleSetSamePeriodLastYear = (dateRange, setData) => {
  setData({
    dateRange: {
      startDate: formatMomentAsDateTime(moment(dateRange?.dateRange?.startDate)?.subtract(1, 'year')),
      endDate: formatMomentAsDateTime(moment(dateRange?.dateRange?.endDate)?.subtract(1, 'year')),
    },
  });
};

export const DEFAULT_DATERANGE_VALUES = {
  dateRange: {
    startDate: formatMomentAsDateTime(moment().startOf('month')),
    endDate: formatMomentAsDateTime(moment()),
  },
};

export const DEFAULT_RANGES = [
  {
    label: 'Today',
    range: {
      startDate: formatMomentAsDateTime(moment().startOf('day')),
      endDate: formatMomentAsDateTime(moment().startOf('day')),
    },
  },
  {
    label: 'Yesterday',
    range: {
      startDate: formatMomentAsDateTime(
        moment()
          .subtract(1, 'days')
          .startOf('day')
      ),
      endDate: formatMomentAsDateTime(
        moment()
          .subtract(1, 'days')
          .startOf('day')
      ),
    },
  },
  {
    label: 'This Week',
    range: {
      startDate: formatMomentAsDateTime(moment().startOf('week')),
      endDate: formatMomentAsDateTime(moment()),
    },
  },
  {
    label: 'This month',
    range: {
      startDate: formatMomentAsDateTime(moment().startOf('month')),
      endDate: formatMomentAsDateTime(moment()),
    },
  },
  {
    label: 'Last Week',
    range: {
      startDate: formatMomentAsDateTime(
        moment()
          .subtract(1, 'week')
          .startOf('week')
      ),
      endDate: formatMomentAsDateTime(
        moment()
          .subtract(1, 'week')
          .endOf('week')
      ),
    },
  },
  {
    label: 'Last week (Same day)',
    range: {
      startDate: formatMomentAsDateTime(moment().subtract(1, 'week')),
      endDate: formatMomentAsDateTime(moment().subtract(1, 'week')),
    },
  },
  {
    label: 'Last month',
    range: {
      startDate: formatMomentAsDateTime(
        moment()
          .subtract(1, 'months')
          .startOf('month')
      ),
      endDate: formatMomentAsDateTime(
        moment()
          .subtract(1, 'months')
          .endOf('month')
      ),
    },
  },
  {
    label: 'Last month (Same day)',
    range: {
      startDate: formatMomentAsDateTime(moment().subtract(1, 'months')),
      endDate: formatMomentAsDateTime(moment().subtract(1, 'months')),
    },
  },
  {
    label: 'Last 7 days',
    range: {
      startDate: formatMomentAsDateTime(
        moment()
          .subtract(6, 'days')
          .startOf('day')
      ),
      endDate: formatMomentAsDateTime(moment().endOf('day')),
    },
  },
  {
    label: 'Last 28 days',
    range: {
      startDate: formatMomentAsDateTime(
        moment()
          .subtract(27, 'days')
          .startOf('day')
      ),
      endDate: formatMomentAsDateTime(moment().endOf('day')),
    },
  },
  {
    label: 'Last 30 days',
    range: {
      startDate: formatMomentAsDateTime(
        moment()
          .subtract(29, 'days')
          .startOf('day')
      ),
      endDate: formatMomentAsDateTime(moment().endOf('day')),
    },
  },
  {
    label: 'Last 90 days',
    range: {
      startDate: formatMomentAsDateTime(
        moment()
          .subtract(89, 'days')
          .startOf('day')
      ),
      endDate: formatMomentAsDateTime(moment().endOf('day')),
    },
  },
  {
    label: 'Last 12 months',
    range: {
      startDate: formatMomentAsDateTime(
        moment()
          .subtract(12, 'months')
          .startOf('month')
      ),
      endDate: formatMomentAsDateTime(moment().endOf('month')),
    },
  },
  {
    label: 'Last calendar year',
    range: {
      startDate: formatMomentAsDateTime(
        moment()
          .subtract(1, 'year')
          .startOf('year')
      ),
      endDate: formatMomentAsDateTime(
        moment()
          .subtract(1, 'year')
          .endOf('year')
      ),
    },
  },
  {
    label: 'This year (Jan - Today)',
    range: {
      startDate: formatMomentAsDateTime(moment().startOf('year')),
      endDate: formatMomentAsDateTime(moment().endOf('day')),
    },
  },
  {
    label: 'Preceding period',
    range: {
      startDate: formatMomentAsDateTime(moment()),
      endDate: formatMomentAsDateTime(moment()),
    },
  },
  {
    label: 'Same period last year',
    range: {
      startDate: formatMomentAsDateTime(moment()),
      endDate: formatMomentAsDateTime(moment()),
    },
  },
  {
    label: 'LFL MTD',
    range: {
      startDate: formatMomentAsDateTime(moment().startOf('month')),
      endDate: formatMomentAsDateTime(moment()),
    },
  },
  {
    label: 'LFL YTD',
    range: {
      startDate: formatMomentAsDateTime(moment().startOf('year')),
      endDate: formatMomentAsDateTime(moment()),
    },
  },
  {
    label: 'Custom',
    range: {
      startDate: null,
      endDate: null,
    },
  },
];

export const queryFormatDateRange = (dateRangeData) => {
  const formatDate = (date) => moment(date)?.format('YYYY-MM-DD');

  const {
    dateRange: { startDate: start_date, endDate: end_date, label } = {},
    isCompare,
    compareDateRange: { startDate: c_start_date, endDate: c_end_date } = {},
  } = dateRangeData || {};

  const formattedDateRange = {
    start_date: start_date ? formatDate(start_date) : undefined,
    end_date: end_date ? formatDate(end_date) : undefined,
    label: label ? label?.replaceAll(' ', '_') : undefined,
    ...(isCompare
      ? {
          c_start_date: c_start_date ? formatDate(c_start_date) : undefined,
          c_end_date: c_end_date ? formatDate(c_end_date) : undefined,
        }
      : {}),
  };

  return formattedDateRange;
};

export const printDates = (date1, date2, full = false) => {
  let dateString = '';

  let isSeparateYears = new Date(date1).getFullYear() !== new Date(date2).getFullYear();

  dateString += moment(date1).format(
    full || isSeparateYears || moment(date1).isSame(moment(date2), 'day') ? 'DD-MMM-YYYY' : 'DD-MMM'
  );

  if (!moment(date1).isSame(moment(date2), 'day')) {
    dateString += ' - ' + moment(date2).format('DD-MMM-YYYY');
  }

  return dateString;
};
