import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import AuthService from 'common/auth.service';
import { getCurrentUserData } from 'utils/helper';

// Create an Apollo server
export const createApolloServer = () => {
  const httpLink = new HttpLink({
    credentials: 'same-origin',
  });

  const authLink = setContext((_, { headers }) => {
    const { apiKey } = getCurrentUserData();
    const auth = new AuthService();
    const token = auth.getSessionCookie();
    return {
      headers: {
        ...headers,
        'x-api-key': apiKey,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return new ApolloClient({
    link: ApolloLink.from([authLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
    },
  });
};
