import SmkAsyncSelect from 'components/SmkAsyncSelect';
import { isEmpty } from 'lodash';
import React from 'react';
import { API_BASE_URL } from 'utils/constants';
import { htmlDecode } from 'utils/helper';

const CustomArticleSearch = ({ onChange, value }) => {
  const handleSelectChange = (res) => {
    const currentArticleMetaData = !isEmpty(value) ? value : [];

    const isAlreadySelected = currentArticleMetaData?.some((item) => item?.id === res?.id);

    if (!isAlreadySelected) {
      const updatedArticleMetaData = [
        ...currentArticleMetaData,
        {
          title: res?.title,
          id: res?.id,
        },
      ];
      onChange(updatedArticleMetaData);
    }
  };

  const handleRemoveItem = (id) => {
    const updatedArticleMetaData = value?.filter((item) => item?.id !== id);
    onChange(updatedArticleMetaData);
  };

  return (
    <>
      <div className="mb-3">
        <SmkAsyncSelect
          acceptedKey={'title'}
          acceptedValue={'id'}
          placeholder="Type article name.."
          onChange={handleSelectChange}
          fetchUrl={`${API_BASE_URL}/article/admin/search`}
          filters={{
            autoComplete: true,
            page: 1,
            size: 20,
            sortBy: 'id',
            sortOrder: 0,
            dateType: 'createdDate',
            language: 'en',
            status: 'published',
            active: true,
          }}
          searchKeyName="name"
        />
      </div>

      {!isEmpty(value) &&
        value?.map((item, index) => (
          <div
            key={item?.id}
            className="bg-light rounded-2 p-3 d-flex justify-content-between align-items-center mt-3  ">
            <h6 className="mb-0">
              {index + 1}. {htmlDecode(item?.title)}
            </h6>
            <button className="btn btn-md btn-danger" type="button" onClick={() => handleRemoveItem(item.id)}>
              Remove
            </button>
          </div>
        ))}
    </>
  );
};

export default CustomArticleSearch;
