import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';

// Import Breadcrumb
import Breadcrumbs from 'components/Common/Breadcrumb';
import Select from 'react-select';

import { Divider } from '@material-ui/core';
import TableError from 'components/TableError';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { buildURL, checkIfValueExist, printDateTime } from 'utils/helper';
import { SMKLinks } from 'utils/links';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { ACTIVE_AND_DELETED_ARRAY, LANGUAGE_VALUES, sortBySearchData, taxonomyType } from '../../../utils/constants';

const MilestoneListing = (props) => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [milestoneList, setmilestoneList] = useState([]);
  const [milestonePagination, setmilestonePagination] = useState({});

  // filters
  const [milestoneCategoryList, setmilestoneCategoryList] = useState([]);
  const [milestoneAgeGroupList, setmilestoneAgeGroupList] = useState([]);

  // Dropdown Api Call
  const [getmilestoneCategoryListRequest, { loading: getmilestoneCategoryListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setmilestoneCategoryList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [getmilestoneAgeGroupListRequest, { loading: getmilestoneAgeGroupListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,
    method: 'POST',
    onSuccess: (data) => {
      setmilestoneAgeGroupList(
        data?.results
          ?.sort((a, b) => a.metaData?.value - b.metaData?.value)
          .map((e) => ({
            label: e.name,
            value: e.id,
          }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    sortBy: { value: 'ageGroup', label: 'Age group' },
    categoryId: null,
    ageGroupId: null,
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getmilestoneListRequest, { loading: getmilestoneListLoading }] = useRequest({
    url: `/milestone/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setmilestoneList(data?.results);
      setmilestonePagination(data?.pagination);
    },
  });

  const [updateMilestoneRequest, { loading: updateMilestoneLoading }] = useRequest({
    onSuccess: (data) => {
      handleChangePage(1);
      toast.success(`Success! Milestone has been updated.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getmilestoneListRequest({
      body: {
        page,
        size: 500,
        sortOrder: 1,
        isDevelopmentMilestone: true,
        sortBy: checkIfValueExist(filters.sortBy?.value),
        active: checkIfValueExist(filters.active?.value),
        deleted: checkIfValueExist(filters.deleted?.value),
        categoryId: checkIfValueExist(filters.categoryId?.value),
        ageGroupId: checkIfValueExist(filters.ageGroupId?.value),
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    getmilestoneListRequest({
      body: {
        page: 1,
        size: 500,
        sortOrder: 1,
        isDevelopmentMilestone: true,
        active: checkIfValueExist(defaultFilters.active?.value),
        deleted: checkIfValueExist(defaultFilters.deleted?.value),
        sortBy: checkIfValueExist(defaultFilters.sortBy?.value),
      },
    });
  };
  const milstoneCategoryLists = ['Social', 'Communication', 'Cognitive', 'Movement'];
  // Dropdowns data api call
  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);

    const searchPayload = {
      page: 1,
      size: 1000,
      sortBy: 'name',
      sortOrder: 0,
    };

    getmilestoneCategoryListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.MILESTONE_CATEGORY,
      },
    });
    getmilestoneAgeGroupListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.AGE_GROUP,
      },
    });
  }, []);

  const handleUpdateMilestone = (data, key) => {
    if (data[key]) {
      updateMilestoneRequest({
        method: 'PUT',
        url: `/milestone/admin/${data?.id}`,
        body: {
          [key]: false,
        },
      });
    } else {
      updateMilestoneRequest({
        method: key === 'deleted' ? 'DELETE' : 'PUT',
        url: `/milestone/admin/${data?.id}`,
        body: {
          [key]: true,
        },
      });
    }
  };

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getmilestoneListLoading ||
            updateMilestoneLoading ||
            getmilestoneCategoryListLoading ||
            getmilestoneAgeGroupListLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="Milestones" breadcrumbItem="Milestones List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => {
                        setFilters({ ...filters, active: value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Sort By</label>
                    <Select
                      options={sortBySearchData}
                      placeholder="Select sortBy..."
                      value={filters.sortBy}
                      onChange={(value) => setFilters({ ...filters, sortBy: value })}
                    />
                  </div>

                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Category</label>
                    <Select
                      placeholder="Select category..."
                      options={milestoneCategoryList}
                      value={filters.categoryId}
                      onChange={(value) => setFilters({ ...filters, categoryId: value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Age Group</label>
                    <Select
                      placeholder="Select age group..."
                      options={milestoneAgeGroupList}
                      value={filters.ageGroupId}
                      onChange={(value) => setFilters({ ...filters, ageGroupId: value })}
                    />
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">{milestonePagination?.totalCount} Milestones Found</div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_MILESTONE}>
                  <i className="mdi mdi-plus me-1" />
                  Add Milestone
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table  table-borderless ">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">Image</th>
                        <th scope="col">Title/slug</th>
                        <th scope="col">Approved status</th>
                        {/* <th scope="col">Category </th> */}
                        <th scope="col">AgeGroup</th>
                        <th scope="col">Order</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Language</th>
                        <th scope="col">hasArticles</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>

                    <tbody>
                      {milstoneCategoryLists?.map((milstoneCategoryList) => {
                        return (
                          <>
                            {milestoneList?.filter((data) => data?.category?.name == milstoneCategoryList).length >
                              0 && <tr className="h4">{milstoneCategoryList} </tr>}

                            {!isEmpty(milestoneList) ? (
                              milestoneList
                                ?.filter((data) => data?.category?.name == milstoneCategoryList)
                                ?.sort((a, b) => a.order - b.order)
                                ?.map((data, index) => {
                                  return (
                                    <tr key={index} className={data?.deleted || !data?.active ? 'bg-danger' : ''}>
                                      <td>
                                        {data?.image && (
                                          <a href={data?.image} target="_blank">
                                            <img src={data?.image} width={40} height={40} />
                                          </a>
                                        )}
                                      </td>
                                      <td>
                                        <Link
                                          to={buildURL(SMKLinks.EDIT_MILESTONE, {
                                            id: data?.id,
                                          })}>
                                          {data?.title}
                                        </Link>
                                        <br />
                                        {data?.slug}
                                      </td>
                                      <td align="center">
                                        <i
                                          className={`fas  ${
                                            data?.approved
                                              ? 'fa-check-circle text-success'
                                              : 'fa-times-circle text-danger'
                                          }   m-1 pe-3 fs-4`}
                                        />
                                      </td>
                                      {/* <td>
                                        {milestoneCategoryList?.find((e) => e?.value === data?.categoryId)?.label}
                                      </td> */}
                                      <td>
                                        {milestoneAgeGroupList?.find((e) => e?.value === data?.ageGroupId)?.label}
                                      </td>
                                      <td>{data?.order}</td>
                                      <td>{printDateTime(data?.createdDate)}</td>
                                      <td>
                                        <Badge
                                          className={`${data?.language == 0 ? 'bg-info' : 'bg-warning'} p-1`}
                                          style={{ fontSize: '10px' }}>
                                          {LANGUAGE_VALUES[data?.language]}
                                        </Badge>
                                      </td>
                                      <td>
                                        <Badge className={data?.hasArticles ? 'bg-success' : 'bg-danger'}>
                                          {data?.hasArticles ? 'Yes' : 'No'}
                                        </Badge>
                                      </td>

                                      <td>
                                        <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                          {data?.active ? 'Yes' : 'No'}
                                        </Badge>
                                      </td>
                                      <td>
                                        <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                          {data?.deleted ? 'Yes' : 'No'}
                                        </Badge>
                                      </td>
                                      <td>
                                        <UncontrolledDropdown>
                                          <DropdownToggle href="#" className="card-drop " tag="i">
                                            <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                          </DropdownToggle>
                                          <DropdownMenu className="dropdown-menu-end">
                                            <DropdownItem onClick={() => handleUpdateMilestone(data, 'approved')}>
                                              <i
                                                className={`fas  ${
                                                  data?.approved
                                                    ? 'fa-thumbs-down text-danger'
                                                    : 'fa-thumbs-up text-primary'
                                                }   m-1 pe-3`}
                                              />
                                              {data?.approved ? 'UnApprove' : 'Approve'}
                                            </DropdownItem>
                                            <DropdownItem onClick={() => handleUpdateMilestone(data, 'active')}>
                                              <i
                                                className={`fas  ${
                                                  data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                                }   m-1 pe-3`}
                                              />
                                              {data?.active ? 'Disable' : 'Enable (Active)'}
                                            </DropdownItem>
                                            <DropdownItem onClick={() => handleUpdateMilestone(data, 'deleted')}>
                                              <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                              {data?.deleted ? 'Undelete' : 'Delete'}
                                            </DropdownItem>
                                            <Divider className="my-2" />

                                            <Link
                                              to={buildURL(SMKLinks.EDIT_MILESTONE, {
                                                id: data?.id,
                                              })}>
                                              <DropdownItem>
                                                <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                                Edit
                                              </DropdownItem>
                                            </Link>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </td>
                                    </tr>
                                  );
                                })
                            ) : (
                              <TableError />
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>
          <Paginate pageInfo={milestonePagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default MilestoneListing;
