import { USER_ROLE } from '../../utils/constants';
import SchoolAdd from './AddSchool';
import AdmissionInquiryList from './AdmissionInquiry';
// import GoogleObjectApproval from './googleObjects/GoogleObjectApproval';
// import AddSchool from './AddSchool';
// import AdmissionInquiryList from './AdmissionInquiry';
import EditSchool from './EditSchool';
// import AdmissionInquiry from './admissionInquiry/AdmissionInquiry';
//
import SCHOOL_LINKS from './links';
import SchoolBoardsList from './SchoolBoards';
import SchoolsFeatured from './SchoolFeatured';
import UpdateSchoolFeatured from './SchoolFeatured/UpdateSchoolFeatured';
import SchoolGroupsList from './SchoolGroups';
import UpdateSchoolGroups from './SchoolGroups/UpdateSchoolGroups';
import SchoolMetaContentList from './SchoolsMetaData';
import UpdateSchoolMetaData from './SchoolsMetaData/UpdateSchoolMetaData';
import SchoolList from './SchoolList';
import SchoolReviewsList from './SchoolReviews';

export default [
  {
    path: SCHOOL_LINKS.ADD_SCHOOL,
    exact: true,
    component: SchoolAdd,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.EDIT_SCHOOL_SECTION,
    exact: true,
    component: EditSchool,
    role: [USER_ROLE.ADMIN],
    hideLeftSide: true,
  },
  {
    path: SCHOOL_LINKS.EDIT_SCHOOL,
    exact: true,
    component: EditSchool,
    role: [USER_ROLE.ADMIN],
    hideLeftSide: true,
  },
  {
    path: SCHOOL_LINKS.SCHOOL_LISTING,
    exact: true,
    component: SchoolList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.ADMISSION_INQUIRY,
    exact: true,
    component: AdmissionInquiryList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.SCHOOL_REVIEWS,
    exact: true,
    component: SchoolReviewsList,
    role: [USER_ROLE.ADMIN],
  },
  // {
  //   path: SCHOOL_LINKS.SCHOOL_GOOGLE_OBJECTS,
  //   exact: true,
  //   component: GoogleObjectList,
  //   role: [USER_ROLE.ADMIN],
  // },
  // {
  //   path: SCHOOL_LINKS.SCHOOL_GOOGLE_OBJECT_APPROVAL,
  //   exact: true,
  //   component: GoogleObjectApproval,
  //   role: [USER_ROLE.ADMIN],
  // },
  {
    path: SCHOOL_LINKS.SCHOOL_GROUPS,
    exact: true,
    component: SchoolGroupsList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.ADD_SCHOOL_GROUPS,
    exact: true,
    component: UpdateSchoolGroups,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.EDIT_SCHOOL_GROUPS,
    exact: true,
    component: UpdateSchoolGroups,
    role: [USER_ROLE.ADMIN],
  },

  {
    path: SCHOOL_LINKS.SCHOOL_META_CONTENT,
    exact: true,
    component: SchoolMetaContentList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.ADD_SCHOOL_META_CONTENT,
    exact: true,
    component: UpdateSchoolMetaData,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.EDIT_SCHOOL_META_CONTENT,
    exact: true,
    component: UpdateSchoolMetaData,
    role: [USER_ROLE.ADMIN],
  },

  {
    path: SCHOOL_LINKS.SCHOOL_FEATURED,
    exact: true,
    component: SchoolsFeatured,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.ADD_SCHOOL_FEATURED,
    exact: true,
    component: UpdateSchoolFeatured,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.EDIT_SCHOOL_FEATURED,
    exact: true,
    component: UpdateSchoolFeatured,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: SCHOOL_LINKS.SCHOOL_BOARDS,
    exact: true,
    component: SchoolBoardsList,
    role: [USER_ROLE.ADMIN],
  },
];
