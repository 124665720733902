import { createRoutine } from 'redux-saga-routines';
import {
  ADD_SCHOOL,
  ADMISSION_INQUIRIES_MARK_ACTION_TAKEN,
  ADMISSION_INQUIRIES_MARK_DELETED,
  GET_ADMISSION_INQUIRIES,
  GET_CITIES,
  GET_COUNTRIES,
  GET_REGIONS,
  GET_SCHOOL_BOARDS,
  GET_SCHOOL_DETAIL,
  GET_SCHOOL_GOOGLE_OBJECT_FOR_APPROVAL,
  GET_SCHOOL_GOOGLE_OBJECT_FOR_REVIEWS_APPROVAL,
  GET_SCHOOL_GOOGLE_OBJECTS,
  GET_SCHOOL_LEVELS,
  GET_SCHOOL_REVIEWS,
  GET_SCHOOLS_INFRA_LIST,
  GET_SCHOOLS_LEVEL_MASTER,
  GET_SCHOOLS_MASTER_DATA,
  GET_SCHOOLS_GROUPS,
  GET_SUB_AREAS,
  SCHOOL_LIST,
  SCHOOL_REVIEW_APPROVE,
  SCHOOL_REVIEW_DELETE,
  // NOTE: Remove later
  // UPDATE_SCHOOL_ACADEMIC,
  // UPDATE_SCHOOL_FACILITY,
  UPDATE_SCHOOL_BASIC_INFO,
  UPDATE_SCHOOL_CONTACT_DETAILS,
  UPDATE_SCHOOL_ADMISSION_INFO,
  UPDATE_SCHOOL_FEE_DETAILS,
  UPDATE_SCHOOL_ACADEMIC_DETAILS,
  UPDATE_SCHOOL_FACILITY_DETAILS,
  UPDATE_SCHOOL_EXTRA_CURRICULAR_DETAILS,
  UPDATE_SCHOOL_ADDITIONAL_INFO,
  UPDATE_SCHOOL_SYSTEM_INFO,
} from './actionTypes';

// ACTIONS
export const getSchools = createRoutine(SCHOOL_LIST);

export const getSchoolReviews = createRoutine(GET_SCHOOL_REVIEWS);
export const getSchoolGoogleObjects = createRoutine(GET_SCHOOL_GOOGLE_OBJECTS);
export const getSchoolGoogleObjectForApproval = createRoutine(GET_SCHOOL_GOOGLE_OBJECT_FOR_APPROVAL);
export const getSchoolGoogleObjectForReviewsApproval = createRoutine(GET_SCHOOL_GOOGLE_OBJECT_FOR_REVIEWS_APPROVAL);

export const getCountries = createRoutine(GET_COUNTRIES);
export const getRegions = createRoutine(GET_REGIONS);
export const getCities = createRoutine(GET_CITIES);
export const getSubAreas = createRoutine(GET_SUB_AREAS);

export const getAdmissionInquiries = createRoutine(GET_ADMISSION_INQUIRIES);
export const admissionInquiriesMarkActionTaken = createRoutine(ADMISSION_INQUIRIES_MARK_ACTION_TAKEN);
export const admissionInquiriesMarkDeleted = createRoutine(ADMISSION_INQUIRIES_MARK_DELETED);

export const schoolReviewApprove = createRoutine(SCHOOL_REVIEW_APPROVE);
export const schoolReviewDelete = createRoutine(SCHOOL_REVIEW_DELETE);

export const addSchool = createRoutine(ADD_SCHOOL);
export const getSchoolDetail = createRoutine(GET_SCHOOL_DETAIL);

// NOTE: Remove later
// export const updateSchoolFacilityDetails = createRoutine(UPDATE_SCHOOL_FACILITY);
// export const updateSchoolAcademicDetails = createRoutine(UPDATE_SCHOOL_ACADEMIC);

export const getSchoolBoards = createRoutine(GET_SCHOOL_BOARDS);
export const getSchoolLevels = createRoutine(GET_SCHOOL_LEVELS);
export const getSchoolsMasterData = createRoutine(GET_SCHOOLS_MASTER_DATA);
export const getSchoolsLevelMaster = createRoutine(GET_SCHOOLS_LEVEL_MASTER);
export const getSchoolsGroups = createRoutine(GET_SCHOOLS_GROUPS);

export const getSchoolsInfraList = createRoutine(GET_SCHOOLS_INFRA_LIST);

export const updateSchoolBasicInfo = createRoutine(UPDATE_SCHOOL_BASIC_INFO);
export const updateSchoolContactDetails = createRoutine(UPDATE_SCHOOL_CONTACT_DETAILS);
export const updateSchoolAdmissionInfo = createRoutine(UPDATE_SCHOOL_ADMISSION_INFO);
export const updateSchoolFeeDetails = createRoutine(UPDATE_SCHOOL_FEE_DETAILS);
export const updateSchoolAcademicDetails = createRoutine(UPDATE_SCHOOL_ACADEMIC_DETAILS);
export const updateSchoolFacilityDetails = createRoutine(UPDATE_SCHOOL_FACILITY_DETAILS);
export const updateSchoolExtraCurricularDetails = createRoutine(UPDATE_SCHOOL_EXTRA_CURRICULAR_DETAILS);
export const updateSchoolAdditionalInfo = createRoutine(UPDATE_SCHOOL_ADDITIONAL_INFO);
export const updateSchoolSystemInfo = createRoutine(UPDATE_SCHOOL_SYSTEM_INFO);
