import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  toggleLeftmenu,
} from '../../store/actions';

// Layout Related Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Rightbar from '../CommonForBoth/Rightbar';
import HrSidebar from './HrSidebar';
import HrHeader from './hrHeader';

const HrLayout = (props) => {
  const [isMobile, setIsMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    isPreloader,
    leftSideBarTheme,
    topbarTheme,
    leftSideBarType,
    layoutWidth,
    showRightSidebar,
    leftMenu,
  } = useSelector((state) => state.Layout);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  useEffect(() => {
    if (isPreloader) {
      document.getElementById('preloader').style.display = 'block';
      document.getElementById('status').style.display = 'block';

      setTimeout(() => {
        document.getElementById('preloader').style.display = 'none';
        document.getElementById('status').style.display = 'none';
      }, 2500);
    } else {
      document.getElementById('preloader').style.display = 'none';
      document.getElementById('status').style.display = 'none';
    }

    // Scroll to top
    window.scrollTo(0, 0);
    const currentage = capitalizeFirstLetter(location.pathname);
    document.title = `${currentage} | SchoolMyKids - Admin Dashboard`;

    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }

    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }

    const collapsedSidebarURLs = ['/add', '/edit', '/translate', 'view'];
    const isDesktop = window.innerWidth > 1200;

    if (collapsedSidebarURLs.some((url) => location.pathname.includes(url)) && isDesktop) {
      dispatch(changeSidebarType('condensed', isMobile));
      dispatch(toggleLeftmenu(true));
    } else {
      dispatch(toggleLeftmenu(false));

      if (leftSideBarType) {
        dispatch(changeSidebarType(leftSideBarType));
      }

      if (layoutWidth) {
        dispatch(changeLayoutWidth(layoutWidth));
      }
    }
  }, [isPreloader, location.pathname, leftSideBarTheme, topbarTheme, leftSideBarType, layoutWidth, isMobile, dispatch]);

  const toggleMenuCallback = () => {
    if (leftSideBarType === 'default') {
      dispatch(changeSidebarType('condensed', isMobile));
    } else if (leftSideBarType === 'condensed') {
      dispatch(changeSidebarType('default', isMobile));
    }
  };

  return (
    <>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div>

      <div id="layout-wrapper">
        <HrHeader toggleMenuCallback={toggleMenuCallback} />
        <HrSidebar theme={leftSideBarTheme} type={leftSideBarType} isMobile={isMobile} leftMenu={leftMenu} />
        <div className="main-content">{props.children}</div>
        <Footer />
      </div>
      {showRightSidebar && <Rightbar />}
    </>
  );
};

HrLayout.propTypes = {
  children: PropTypes.any,
};

export default HrLayout;
