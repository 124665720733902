import React, { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { GENDER } from 'utils/constants';
import { htmlDecode, titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';

const UpdateDoctor = () => {
  const history = useHistory();
  const { id: doctorId } = useParams();

  const [doctorDetails, setdoctorDetails] = useState();
  const [medicalSpeciality, setMedicalSpeciality] = useState();
  const genderArray = Object.keys(GENDER)?.map((elem) => ({
    label: titleCaseIfExists(elem),
    value: GENDER[elem],
  }));

  const [addDoctorRequest, { loading: addDoctorLoading }] = useRequest({
    url: `/doctor/admin`,
    method: 'POST',
    onSuccess: (data) => {
      history.replace(SMKLinks.DOCTORS_LISTING);
      toast.success('Success! Doctor has been added.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });
  const [getDoctorRequest, { loading: getDoctorLoading }] = useRequest({
    url: `/doctor/admin/${doctorId}`,
    method: 'GET',
    onSuccess: (data) => {
      setdoctorDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateDoctorRequest, { loading: updateDoctorLoading }] = useRequest({
    url: `/doctor/admin/${doctorId}`,
    method: 'PUT',
    onSuccess: (data) => {
      history.replace(SMKLinks.DOCTORS_LISTING);
      toast.success('Success! Doctor has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [getMedicalSpeciality, { loading: getMedicalSpecialityLoading }] = useRequest({
    url: `/doctor/medical-specialities`,
    method: 'GET',
    onSuccess: (data) => {
      setMedicalSpeciality(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => {
    return {
      active: doctorDetails?.active,
      deleted: doctorDetails?.deleted,
      firstName: doctorDetails?.firstName,
      lastName: doctorDetails?.lastName,
      description: doctorDetails?.description,
      gender: doctorDetails?.gender,
      email: doctorDetails?.email,
      phoneNumber: doctorDetails?.phoneNumber,
      areaOfExpertise: doctorDetails?.areaOfExpertise,
      profileImage: doctorDetails?.profileImage,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    firstName: '',
    lastName: '',
    description: '',
    gender: '',
    email: '',
    phoneNumber: '',
    areaOfExpertise: '',
    profileImage: {},
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(doctorDetails) ? getInitialValues() : editInitialValues(),
    onSubmit: async (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        firstName: values?.firstName,
        lastName: values?.lastName,
        description: htmlDecode(values?.description),
        gender: values?.gender,
        email: values?.email,
        phoneNumber: values?.phoneNumber,
        areaOfExpertise: values?.areaOfExpertise,
        profileImage: values.profileImage,
      };
      isEmpty(doctorId)
        ? addDoctorRequest({
            body: data,
          })
        : updateDoctorRequest({
            body: data,
          });
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required('Please provide First name')
        .min(2, 'First name should be at least 2 characters long')
        .max(128, 'First name should be at most 128 characters long'),
      lastName: Yup.string()
        .required('Please provide Last name')
        .min(2, 'Last name should be at least 2 characters long')
        .max(128, 'Last name should be at most 128 characters long'),

      areaOfExpertise: Yup.string().required('Please provide Area of expertise'),
      // email: Yup.string().required('Please provide email'),
      // phoneNumber: Yup.string().required('Please provide Phone number'),
      gender: Yup.string().required('Please provide gender'),
      // description: Yup.object().required('Please provide description'),
    }),
  });

  useEffect(() => {
    if (!isEmpty(doctorId)) {
      getDoctorRequest();
    }
  }, [doctorId]);

  useEffect(() => {
    getMedicalSpeciality();
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={addDoctorLoading || getDoctorLoading || updateDoctorLoading || getMedicalSpecialityLoading} />
        <Container fluid>
          <Breadcrumbs title="Doctor" breadcrumbItem={`${isEmpty(doctorId) ? 'Add' : 'Edit'}  Doctor`} />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      {!isEmpty(doctorId) && (
                        <Col md={6}>
                          <FormGroup className="mb-4">
                            <Label for="doctorId" className="form-label  ">
                              Doctor ID
                            </Label>
                            <Input
                              id="doctorId"
                              type="text"
                              className="bg-light form-control"
                              disabled
                              value={doctorDetails?.id}
                            />
                          </FormGroup>
                        </Col>
                      )}

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="firstName" className="form-label  ">
                            First Name
                          </Label>
                          <Input
                            id="firstName"
                            type="text"
                            className="form-control"
                            placeholder="First Name..."
                            invalid={!!(formik.touched.firstName && formik.errors.firstName)}
                            {...formik.getFieldProps('firstName')}
                            error={!isEmpty(formik.errors.firstName)}
                          />
                          <FormFeedback className="d-block">{formik.errors.firstName}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="lastName" className="form-label  ">
                            Last Name
                          </Label>
                          <Input
                            id="lastName"
                            type="text"
                            className="form-control"
                            placeholder="Last Name..."
                            invalid={!!(formik.touched.lastName && formik.errors.lastName)}
                            {...formik.getFieldProps('lastName')}
                            error={!isEmpty(formik.errors.lastName)}
                          />
                          <FormFeedback className="d-block">{formik.errors.lastName}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="email" className="form-label  ">
                            Email
                          </Label>
                          <Input
                            id="email"
                            type="text"
                            className="form-control"
                            placeholder="Email..."
                            invalid={!!(formik.touched.email && formik.errors.email)}
                            {...formik.getFieldProps('email')}
                            error={!isEmpty(formik.errors.email)}
                          />
                          <FormFeedback className="d-block">{formik.errors.email}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="phoneNumber" className="form-label  ">
                            Phone Number
                          </Label>
                          <Input
                            id="phoneNumber"
                            type="number"
                            className="form-control"
                            placeholder="Phone Number..."
                            invalid={!!(formik.touched.phoneNumber && formik.errors.phoneNumber)}
                            {...formik.getFieldProps('phoneNumber')}
                            error={!isEmpty(formik.errors.phoneNumber)}
                          />
                          <FormFeedback className="d-block">{formik.errors.phoneNumber}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup className="mb-4">
                          <Label for="gender" className="form-label">
                            Gender
                          </Label>
                          <Select
                            id="gender"
                            options={genderArray}
                            value={genderArray?.find((e) => e?.value === formik.values.gender)}
                            onChange={(obj) => formik.setFieldValue('gender', obj?.value)}
                          />

                          <FormFeedback className="d-block">{formik.errors.gender}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={!isEmpty(doctorId) ? 12 : 6}>
                        <FormGroup className="mb-4">
                          <Label for="areaOfExpertise" className="form-label  ">
                            Area of expertise
                          </Label>
                          <Select
                            id="areaOfExpertise"
                            options={medicalSpeciality?.map((e) => ({
                              label: e?.name,
                              value: e?.slug,
                            }))}
                            value={medicalSpeciality
                              ?.filter((e) => e?.slug === formik.values.areaOfExpertise)
                              ?.map((e) => ({
                                label: e?.name,
                                value: e?.slug,
                              }))}
                            onChange={(obj) => formik.setFieldValue('areaOfExpertise', obj?.value)}
                          />
                          <FormFeedback className="d-block">{formik.errors.areaOfExpertise}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="description" className="form-label  ">
                            Description
                          </Label>
                          <Input
                            id="description"
                            type="textarea"
                            rows={10}
                            className="form-control"
                            placeholder="Description..."
                            invalid={!!(formik.touched.description && formik.errors.description)}
                            {...formik.getFieldProps('description')}
                            error={!isEmpty(formik.errors.description)}
                          />
                          <FormFeedback className="d-block">{formik.errors.description}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <Button type="submit" color="primary" className="w-100 mb-3">
                      {`${isEmpty(doctorId) ? 'Add' : 'Update'}`} Doctor
                    </Button>

                    <Button
                      className="w-100"
                      color="light"
                      onClick={() => {
                        history.replace(SMKLinks.DOCTORS_LISTING);
                      }}>
                      Cancel
                    </Button>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <div>
                      <CardTitle className="mb-3">Active</CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`active_${option}`}
                            name="active"
                            className="form-check-input"
                            checked={formik.values.active === option}
                            onChange={() => formik.setFieldValue('active', option)}
                          />
                          <label className="form-check-label" htmlFor={`active_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                    <div>
                      <CardTitle className="mb-3">Delete </CardTitle>
                      {[true, false].map((option, i) => (
                        <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`deleted_${option}`}
                            name="deleted"
                            className="form-check-input"
                            checked={formik.values.deleted === option}
                            onChange={() => formik.setFieldValue('deleted', option)}
                          />
                          <label className="form-check-label" htmlFor={`deleted_${option}`}>
                            {option ? 'Yes' : 'No'}
                          </label>
                        </div>
                      ))}
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateDoctor;
