import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useMsal } from '@azure/msal-react';

// i18n
import { withTranslation } from 'react-i18next';
// Redux
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import avatar2 from '../../../assets/images/users/avatar-2.jpg';
import { useRouter } from 'hooks/useRouter';
import { isEmpty } from 'lodash';

const HRProfileMenu = (props) => {
  const [menu, setMenu] = useState(false);
  const router = useRouter();
  const { accounts } = useMsal();

  const accessToken = localStorage.getItem('accessToken');

  const handleLogout = () => {
    router.push('/auth/hr');
    localStorage.removeItem('accessToken');
  };

  return (
    <>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle
          className="btn header-item waves-effect d-flex align-items-center"
          id="page-header-user-dropdown"
          tag="button">
          <img className="rounded-circle header-profile-user" src={avatar2} />
          <span className="d-none d-xl-inline-block ms-2 me-1 text-start">
            {!isEmpty(accessToken) ? `${accounts[0]?.name}` : ''}
            <br />
            HR
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <button className="dropdown-item" onClick={() => handleLogout()}>
            <i className="bx bx-power-off font-size-16 align-middle me-2 text-danger" />
            <span>{props.t('Logout')}</span>
          </button>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

HRProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  // const { error, success } = state.Profile
  const { error, success } = {};
  return { error, success };
};

export default withRouter(connect(mapStatetoProps, {})(withTranslation()(HRProfileMenu)));
