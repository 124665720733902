import React from 'react';
import { Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createStructuredSelector } from 'reselect';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

// constants
import { CollegeMasterType } from 'utils/constants';
// actions
import { useHistory } from 'react-router-dom';
import { SMKLinks } from 'utils/links';
import { collegeClearCache } from 'containers/college/collegeDetailsHelper';
// selectors
import { selectCollegesMasterData, collegeDetailSelector } from '../../selectors';
// components
import TextEditor from '../../../../components/TextEditor';
import ToggleButton from '../../../../components/ToggleButton';
import useRequest from '../../../../hooks/useRequest';
import Loader from '../../../../components/Loader';
import { userSelector } from '../../../../common/globalComponents/selectors';

const stateSelector = createStructuredSelector({
  collegesMasterData: selectCollegesMasterData,
  collegeDetail: collegeDetailSelector,
  user: userSelector,
});

const ExtraCurricularDetails = (props) => {
  const { user, collegesMasterData, collegeDetail } = useSelector(stateSelector);

  const dispatch = useDispatch();
  const history = useHistory();

  const [updateExtraCurricularDetailsRequest, { loading: updateExtraCurricularDetailsLoading }] = useRequest({
    url: '/college/admin/edit/updateExtraCurricularDetails',
    method: 'POST',
    onSuccess: (data) => {
      collegeClearCache({
        urlSlug: collegeDetail?.slug,
        collegeId: collegeDetail?.id,
      });

      window.location.reload();
    },
  });

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      activityDetails: collegeDetail?.facilityDetails?.activityDetails || '',
      houseDetails: collegeDetail?.facilityDetails?.houseDetails || '',
      clubs: collegeDetail?.facilityDetails?.clubs || [],
    },
    onSubmit(values) {
      updateExtraCurricularDetailsRequest({
        data: {
          collegeId: collegeDetail.id,
          facilityDetails: {
            activityDetails: values.activityDetails,
            houseDetails: values.houseDetails,
            clubs: values.clubs,
          },
        },
      });
    },
    validationSchema: Yup.object().shape({
      activityDetails: Yup.string().nullable(),
      houseDetails: Yup.string().nullable(),
      clubs: Yup.array(),
    }),
  });

  const handleToggleClub = (checked, club) => {
    let array = formik.values.clubs;
    if (checked) {
      array = [...array, { name: club.name }];
    } else {
      array = array.filter((c) => c.name.toLowerCase() !== club.name.toLowerCase());
    }
    formik.setFieldValue('clubs', array);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Loader isActive={updateExtraCurricularDetailsLoading} />

      <h5 className="mb-4">Extra Curricular Details</h5>

      <Card>
        <CardBody>
          <FormGroup className="mb-4">
            <Label htmlFor="college_act_details" className="form-label ">
              Activity Details
            </Label>
            <TextEditor
              simple
              initialValue={formik.values.activityDetails}
              onChange={(val) => formik.setFieldValue('activityDetails', val)}
            />
          </FormGroup>

          <FormGroup className="mb-4">
            <Label htmlFor="collegedesc" className="form-label ">
              College Houses
            </Label>
            <TextEditor
              simple
              initialValue={formik.values.houseDetails}
              onChange={(val) => formik.setFieldValue('houseDetails', val)}
            />
          </FormGroup>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle className="mb-4">College Clubs</CardTitle>

          <Row>
            {!isEmpty(collegesMasterData) &&
              collegesMasterData
                .filter((d) => d.type === CollegeMasterType.COLLEGE_CLUB)
                .map((club, i) => (
                  <Col key={`club_${i}`} sm={3}>
                    <ToggleButton
                      label={club.name}
                      onChange={(e) => handleToggleClub(e.target.checked, club)}
                      checked={formik.values.clubs.some((c) => c.name.toLowerCase() === club.name.toLowerCase())}
                    />
                  </Col>
                ))}
          </Row>
        </CardBody>
      </Card>

      {(user?.entityPermissions?.college.admin ||
        (collegeDetail.editable &&
          (collegeDetail?.createdBy?.id === user.id || collegeDetail?.createdBy?.teamLead?.id === user.id))) && (
        <div className="mb-4">
          <Button type="submit" color="primary" className="me-3 ">
            Update Details
          </Button>
          <a
            href="#"
            className="ms-4 text-dark"
            onClick={() => {
              history.replace(SMKLinks.COLLEGE_LISTING);
            }}>
            Cancel
          </a>
        </div>
      )}
    </Form>
  );
};

ExtraCurricularDetails.propTypes = {};

export default ExtraCurricularDetails;
