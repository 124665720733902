import { isEmpty } from 'lodash';
import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { printDate } from 'utils/helper';

const PageDetailCard = ({ data }) => {
  return (
    <div>
      {!isEmpty(data) && (
        <Card className="border">
          <CardBody>
            <h6 className="mb-0">
              CreatedBy: <b>{data?.createdBy?.displayName || data?.createdById}</b>{' '}
            </h6>
            <h6 className="mt-2 mb-0">
              UpdatedBy: <b>{data?.updatedBy?.displayName || data?.updatedById}</b>{' '}
            </h6>
            <h6 className="mt-2 mb-0">
              Created Date: <b>{printDate(data?.createdDate)}</b>{' '}
            </h6>
            <h6 className="mt-2 mb-0">
              Modified Date: <b>{printDate(data?.updatedDate)} </b>
            </h6>
            <h6 className="mt-2 mb-2">
              Current Version: <b>{data?.version} </b>
            </h6>
            <a target="_blank" href={`/pages/${data?.id}/previous-version-view/${data?.version}`}>
              View previous version
            </a>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default PageDetailCard;
