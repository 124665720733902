import HrPage from './index';
import HR from './links';

export default [
  {
    path: HR.HR_PAGE,
    exact: true,
    component: HrPage,
  },
];
