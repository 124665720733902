import Loader from 'components/Loader';
import SmkAsyncSelect from 'components/SmkAsyncSelect';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { api } from 'utils/axios';
import { API_BASE_URL } from 'utils/constants';
import { getCurrentUserData, handleSaveAsset, showImage, urlSlugify } from 'utils/helper';
import { showImageError } from '../../../helper';
import BlogSmkAsyncSelect from 'components/SmkAsyncSelect/blogAsyncSelect';

export const ImageUploadModal = ({ isOpen, toggle, onUpload, forType }) => {
  const [showLoading, setShowLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [imageUploadError, setImageUploadError] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const filename = file.name;
      const validFilenameRegex = /^[a-zA-Z0-9-_]+$/;

      const [baseFilename, ...extensionParts] = filename.split('.');
      const extension = extensionParts.length > 0 ? '.' + extensionParts.join('.') : '';

      if (!validFilenameRegex.test(baseFilename)) {
        const updatedBaseFilename = baseFilename
          .toLowerCase()
          .replace(/[^a-zA-Z0-9-_]/g, '_')
          .replace(/^[-_]+|[-_]+$/g, '');

        const updatedFileName = updatedBaseFilename + extension;
        alert(`Invalid filename. Use this filename: ${updatedFileName}`);
        setImageUploadError(true);
        return;
      }
      setImageUploadError(false);
      setFile(file);
    }
  };

  const handleUpload = () => {
    if (file) {
      setShowLoading(true);
      const payload = {
        isAsset: true,
      };

      const formData = new FormData();
      formData.append('file', file);

      api({
        method: 'POST',
        url: `${API_BASE_URL}/attachment/upload?config=${btoa(JSON.stringify(payload))}`,
        data: formData,
      })
        .then(async (file) => {
          const assetSavedFile = await handleSaveAsset(file, forType);
          onUpload(assetSavedFile);
          toggle();
        })
        .catch((err) => {
          console.warn('Error uploading file:', err);
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  };

  return (
    <>
      <Loader isActive={showLoading} />
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Upload Image</ModalHeader>
        <ModalBody className="p-4">
          <input type="file" onChange={handleFileChange} />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpload} disabled={!file || imageUploadError}>
            Upload
          </Button>
          <Button color="light" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const ShowModal = ({ isOpen, toggle, onImageSelect, onImageUpload, currentImage, forType }) => {
  const fetchUrl = `${API_BASE_URL}/asset/admin/search`;

  const [uploadedImage, setUploadedImage] = useState({});
  const [imageSize, setImageSize] = useState('original');

  useEffect(() => {
    if (!isEmpty(currentImage)) {
      setUploadedImage(currentImage);
      setImageSize('original');
    }
  }, [currentImage]);

  const handleSizeChange = (e) => {
    const value = e.target.value;
    setImageSize(value);

    // Update the URL with the selected size
    const resURL = uploadedImage[value];
    const url = uploadedImage ? showImage(resURL) : '';

    // Update uploaded image with new URL
    setUploadedImage({
      ...uploadedImage,
      url,
    });
  };

  return (
    <Modal size="md" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Select or Upload Image</ModalHeader>
      <ModalBody className="p-4">
        <div className="row">
          <div className="col-md-12">
            <Label className="form-label">Select Image</Label>
            <BlogSmkAsyncSelect
              acceptedKey="name"
              acceptedValue="name"
              placeholder="Type media name..."
              onChange={(res) => {
                if (res !== null) {
                  const resURL = res[imageSize] || res?.original;
                  const url = res ? showImage(resURL) : '';
                  setUploadedImage({
                    ...res,
                    url,
                  });
                } else {
                  setUploadedImage({
                    url: '',
                  });
                }
              }}
              fetchUrl={fetchUrl}
              filters={{
                autoComplete: true,
                page: 1,
                size: 1000,
                sortBy: 'id',
                sortOrder: 0,
                forType,
              }}
              searchKeyName="name"
              value={
                !isEmpty(uploadedImage.name)
                  ? {
                      label: uploadedImage.name,
                      value: uploadedImage.name,
                    }
                  : null
              }
            />
          </div>

          {!isEmpty(uploadedImage?.original) && (
            <div className="col-md-12 mt-3">
              <Label className="form-label">Select Image Size</Label>
              <select className="form-select" value={imageSize} onChange={handleSizeChange}>
                <option value="original">Original</option>
                {uploadedImage['thumbnailSmall'] && <option value="thumbnailSmall">Thumbnail Small</option>}
                {uploadedImage['thumbnailMedium'] && <option value="thumbnailMedium">Thumbnail Medium</option>}
                {uploadedImage['thumbnailLarge'] && <option value="thumbnailLarge">Thumbnail Large</option>}
              </select>
            </div>
          )}

          <div className="py-3 h5 mb-0 text-center">OR</div>
          <div className="col-md-12 mt-4">
            <Button className="w-100 py-3" outline color="info" onClick={onImageUpload}>
              Upload Image
            </Button>

            {!isEmpty(uploadedImage?.url) && (
              <div className="mt-4">
                <img
                  className="mt-4"
                  alt={uploadedImage?.name}
                  src={uploadedImage.url}
                  style={{
                    maxWidth: '100%',
                    objectFit: 'contain',
                  }}
                  onError={(e) => {
                    showImageError(e);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </ModalBody>

      <ModalFooter>
        <Button
          disabled={isEmpty(uploadedImage?.url)}
          color="primary"
          onClick={() => {
            onImageSelect(uploadedImage);
            toggle();
          }}>
          Upload
        </Button>
        <Button
          className="ms-3"
          color="light"
          onClick={() => {
            toggle();
          }}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default class EditorImageUploader {
  constructor({ data, config }) {
    this.data = data || {};
    this.config = config || {};
    this.showModal = false;
    this.isUpdateModal = false;
    this.reactContainer = null;
    this.imageContainer = null;
    this.imageElement = null;
  }

  static get toolbox() {
    return {
      title: 'Image',
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="108" height="86" viewBox="0 0 108 86" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M97.2023 5.375H10.8003C7.81785 5.375 5.40013 7.78147 5.40013 10.75V75.25C5.40013 78.2185 7.81785 80.625 10.8003 80.625H97.2023C100.185 80.625 102.602 78.2185 102.602 75.25V10.75C102.602 7.78147 100.185 5.375 97.2023 5.375ZM10.8003 0C4.83544 0 0 4.81294 0 10.75V75.25C0 81.1871 4.83544 86 10.8003 86H97.2023C103.167 86 108.003 81.1871 108.003 75.25V10.75C108.003 4.81294 103.167 0 97.2023 0H10.8003Z" fill="#797979"/>
<path d="M35.1019 43C42.5579 43 48.6022 36.9838 48.6022 29.5625C48.6022 22.1412 42.5579 16.125 35.1019 16.125C27.6459 16.125 21.6016 22.1412 21.6016 29.5625C21.6016 36.9838 27.6459 43 35.1019 43Z" fill="#797979"/>
<path d="M78.6855 37.625C66.3433 37.625 60.1721 56.4375 50.9154 56.4375C41.6587 56.4375 41.6649 50.1667 29.3165 50.1667C16.9742 50.1667 10.7969 75.25 10.7969 75.25H97.199C97.199 75.25 91.0278 37.625 78.6855 37.625Z" fill="#797979"/>
</svg>`,
    };
  }

  render() {
    const container = document.createElement('div');
    this.reactContainer = document.createElement('div');
    container.appendChild(this.reactContainer);
    this.renderReactComponent();
    if (this.data.url) {
      this.renderImage(this.data.url);
    }
    return container;
  }

  renderReactComponent() {
    ReactDOM.render(
      <div className="my-4">
        {isEmpty(this.data.url) && (
          <button
            type="button"
            className="btn btn-outline-dark w-100 py-3"
            onClick={() => {
              this.handleShowUpdateModal(true);
            }}>
            Add Image
          </button>
        )}
        <ShowModal
          isOpen={this.isUpdateModal}
          toggle={() => this.handleShowModal(false)}
          onImageSelect={(item) => this.handleImageSelect(item)}
          onImageUpload={() => this.handleShowModal(true)}
          currentImage={this.data}
          forType={this.config.forType}
        />
        <ImageUploadModal
          forType={this.config.forType}
          isOpen={this.showModal}
          toggle={() => this.handleShowModal(false)}
          onUpload={(file) => this.handleImageSelect(file)}
        />
      </div>,
      this.reactContainer
    );
  }

  handleImageSelect(selectedItem) {
    let url = '';
    const name = selectedItem?.name ? selectedItem.name : '';

    if (selectedItem?.url) {
      url = selectedItem?.url;
    } else {
      const resURL = selectedItem?.original;
      url = selectedItem ? showImage(resURL) : '';
    }

    this.data = {
      ...this.data,
      name: name,
      url: url,
    };

    if (url) {
      this.renderImage(url);
    } else {
      if (this.imageElement) {
        this.imageContainer.removeChild(this.imageElement);
        this.imageElement = null;
      }
    }

    this.renderReactComponent();
  }

  renderImage(url) {
    if (!this.imageContainer) {
      this.imageContainer = document.createElement('div');
      this.reactContainer.insertBefore(this.imageContainer, this.reactContainer.firstChild);
    }

    if (!this.imageElement) {
      this.imageElement = document.createElement('img');
      this.imageElement.style.maxWidth = '100%';
      this.imageElement.style.marginTop = '20px';
      this.imageElement.style.marginBottom = '20px';
      this.imageElement.style.cursor = 'pointer';
      this.imageElement.addEventListener('click', () => this.handleShowUpdateModal(true));
      this.imageContainer.appendChild(this.imageElement);
    }

    this.imageElement.src = url;

    this.imageElement.onerror = () => {
      if (url) {
        const { s3CloudfrontURL, prodURL } = getCurrentUserData();
        const img_url = this.imageElement.src.replace(s3CloudfrontURL, prodURL);

        this.imageElement.onerror = null;
        this.imageElement.src = img_url;
      }
    };
  }

  handleShowModal(show) {
    this.showModal = show;
    this.isUpdateModal = false;
    this.renderReactComponent();
  }

  handleShowUpdateModal(show) {
    this.isUpdateModal = show;
    this.showModal = false;
    this.renderReactComponent();
  }

  save() {
    return {
      name: this.data.name || '',
      url: this.data.url || '',
    };
  }
}
