import { isEmpty } from 'lodash';
import { api } from 'utils/axios';
import config from '../../config/config';
import { convertHtmlToText, htmlDecode } from '../../utils/helper';

export const compareCollegeClassLevel = (a, b) => {
  // a should come before b in the sorted order
  if (a.level < b.level) {
    return -1;
    // a should come after b in the sorted order
  }
  if (a.level > b.level) {
    return 1;
    // and and b are the same
  }
  return 0;
};

export const getCollegeClassesSorted = (currentCollege) => {
  const classes = [];
  if (currentCollege && currentCollege.collegeLevels) {
    currentCollege.collegeLevels.map((s) => classes.push(...s.classes));
    classes.sort(compareCollegeClassLevel);
  }

  return classes;
};

export const getCollegeLevelsSorted = (currentCollege) => {
  const levels = [];
  if (currentCollege && currentCollege.collegeLevels) {
    currentCollege.collegeLevels.map((s) => levels.push(s.collegeLevel));
    levels.sort(compareCollegeClassLevel);
  }

  return levels;
};

export const getCollegeLowestGrade = (currentCollege) => {
  const classes = getCollegeClassesSorted(currentCollege);
  return currentCollege && classes && classes.length > 0 && classes[0] && classes[0].name;
};

export const getCollegeHighestGrade = (currentCollege) => {
  const classes = getCollegeClassesSorted(currentCollege);
  return (
    currentCollege && classes && classes.length > 1 && classes[classes.length - 1] && classes[classes.length - 1].name
  );
};

export const getCollegeLowestLevel = (currentCollege) => {
  const collegeLevels = getCollegeLevelsSorted(currentCollege);
  return currentCollege && collegeLevels && collegeLevels.length > 0 && collegeLevels[0].displayName;
};

export const getCollegeHighestLevel = (currentCollege) => {
  const collegeLevels = getCollegeLevelsSorted(currentCollege);
  return currentCollege && collegeLevels && collegeLevels.length > 0 && collegeLevels[collegeLevels.length - 1].displayName;
};

export const getCollegeDisplayName = (currentCollege) => {
  return currentCollege && convertHtmlToText(currentCollege.displayName);
};

export const getCollegeName = (currentCollege) => {
  return currentCollege && convertHtmlToText(currentCollege.name);
};

export const getCollegeCountry = (currentCollege) => {
  return currentCollege.address && currentCollege.address.country && currentCollege.address.country.displayName;
};
export const getCollegeState = (currentCollege) => {
  return currentCollege.address && currentCollege.address.region && currentCollege.address.region.displayName
    ? currentCollege.address.region.displayName
    : '';
};
export const getCollegeDistrict = (currentCollege) => {
  return currentCollege.address && currentCollege.address.district && currentCollege.address.district.displayName
    ? currentCollege.address.district.displayName
    : '';
};
export const getCollegeCity = (currentCollege) => {
  return currentCollege.address && currentCollege.address.city && currentCollege.address.city.displayName;
};
export const getCollegeMetro = (currentCollege) => {
  return currentCollege.address && currentCollege.address.metro && currentCollege.address.metro.displayName
    ? currentCollege.address.metro.displayName
    : '';
};
export const getCollegeSubArea = (currentCollege) => {
  return currentCollege.address && currentCollege.address.subArea && currentCollege.address.subArea.displayName
    ? currentCollege.address.subArea.displayName
    : '';
};
export const getCollegeLocality = (currentCollege) => {
  return currentCollege.address && currentCollege.address.locality && currentCollege.address.locality.displayName
    ? currentCollege.address.locality.displayName
    : '';
};
export const getCollegeStudentGender = (currentCollege) => {
  let gender = 'Coed';

  if (currentCollege.studentGender !== 0) {
    gender = currentCollege.studentGender === 1 ? 'Girls' : 'Boys';
  }

  return gender;
};

export const getCollegeBoardDisplayNames = (currentCollege) => {
  return (
    currentCollege.boards &&
    currentCollege.boards
      .filter((b) => !b.precollege)
      .map((m) => `${m.displayName} (${m.name})`)
      .join(', ')
  );
};

export const getCollegeAllBoardDisplayNames = (currentCollege) => {
  return currentCollege.boards && currentCollege.boards.map((m) => `${m.displayName} (${m.name})`).join(', ');
};

export const getCollegePrecollegeBoardDisplayNames = (currentCollege) => {
  return (
    currentCollege.boards &&
    currentCollege.boards
      .filter((b) => b.precollege)
      .map((m) => `${m.displayName} (${m.name})`)
      .join(', ')
  );
};

export const getCollegeBoardNames = (currentCollege) => {
  return (
    currentCollege.boards &&
    currentCollege.boards
      .filter((b) => !b.precollege)
      .map((m) => m.name)
      .join(', ')
  );
};

export const getCollegePrecollegeBoardNames = (currentCollege) => {
  return (
    currentCollege.boards &&
    currentCollege.boards
      .filter((b) => b.precollege)
      .map((m) => m.name)
      .join(', ')
  );
};

export const getCollegeManagementName = (currentCollege) => {
  return currentCollege.basicInfo && currentCollege.basicInfo.managementName;
};

export const getCollegeMotto = (currentCollege) => {
  return currentCollege.basicInfo && htmlDecode(currentCollege.basicInfo.collegeMotto);
};

export const getCollegeTotalStudents = (currentCollege) => {
  return currentCollege.additionalInfo && currentCollege.additionalInfo.studentCount;
};
export const getCollegeAcademicYear = (currentCollege) => {
  return currentCollege.admissionInfo && currentCollege.admissionInfo.academicYear;
};

export const getCollegeParentGroupNames = (currentCollege) => {
  return (
    currentCollege &&
    currentCollege.collegeGroups &&
    currentCollege.collegeGroups
      .filter((s) => s.collegeGroup && !s.collegeGroup.isAssociation)
      .map((m) => m.collegeGroup.name.trim())
      .join('')
  );
};

export const getCollegeAssociationGroups = (currentCollege) => {
  return currentCollege.collegeGroups.filter((s) => s.collegeGroup && s.collegeGroup.isAssociation);
};

export const getCollegeManagementType = (currentCollege) => {
  return (
    currentCollege.masterData &&
    currentCollege.masterData
      .filter((m) => m.type === 2)
      .map((m) => m.name)
      .join('')
  );
};

export const getCollegeBoardingType = (currentCollege) => {
  return (
    currentCollege.masterData &&
    currentCollege.masterData
      .filter((m) => m.type === 0)
      .map((m) => m.name)
      .join(', ')
  );
};

export const getCollegeLanguages = (currentCollege) => {
  return (
    currentCollege &&
    currentCollege.academicInfo &&
    currentCollege.academicInfo.languages &&
    currentCollege.academicInfo.languages.map((m) => m.name).join(', ')
  );
};

export const getCollegeEstablishedYear = (currentCollege) => {
  return currentCollege.basicInfo.establishedIn ? new Date(currentCollege.basicInfo.establishedIn).getFullYear() : '';
};

export const getCollegeStudentTeacherRatio = (currentCollege) => {
  return currentCollege.additionalInfo.studentCount > 0 && currentCollege.additionalInfo.teacherCount > 0
    ? Math.floor(currentCollege.additionalInfo.studentCount / currentCollege.additionalInfo.teacherCount)
    : 0;
};

export const getCollegeBoardsMap = (currentCollege) => {
  const boardsMap = {};
  if (currentCollege.academicInfo && currentCollege.academicInfo.curriculumDetails) {
    currentCollege.academicInfo.curriculumDetails.forEach((c) => {
      const key = c.curriculum;
      key.replace(' ', '');
      boardsMap[key] = c;
    });
  }
  return boardsMap;
};

export const isCollegeKVSSoeDel = (currentCollege) => {
  return (
    currentCollege.collegeGroups &&
    currentCollege.collegeGroups.filter(
      (sg) => sg.collegeGroup.shortName.toLowerCase() === 'kvs' || sg.collegeGroup.shortName.toLowerCase() === 'soedel'
    ).length > 0
  );
};

export const isCollegePrimaryCollege = (currentCollege) => {
  return currentCollege.collegeLevels.find((sl) => sl.collegeLevel.name === 'Primary College (I-V)');
};

export const getCollegeSubBardingType = (currentCollege) => {
  return (
    currentCollege.masterData &&
    currentCollege.masterData
      .filter((m) => m.type === 1)
      .map((m) => m.name)
      .join(', ')
  );
};

export const getCollegeNationalInternational = (currentCollege) => {
  // const country = getCollegeCountry(currentCollege);

  const curriculumType = 0;

  // currentCollege.boards &&
  //   currentCollege.boards.map((b) => {
  //     // b.country
  //   });

  return curriculumType;
};

export const collegeClearCache = ({ key, urlSlug, collegeId, removeBodyParam, babyNameMeaningOriginCache, babyName }) => {
  // api end points
  const searchCacheApiUrl = `${config.apiEndpoint}/admin-tools/searchCache`;
  const clearCDNCacheApiUrl = `${config.apiEndpoint}/admin-tools/clearCDNCache`;
  const clearCacheApiUrl = `${config.apiEndpoint}/admin-tools/clearCache`;

  // details page
  const babyNameDetailPage = `${process.env.REACT_APP_THEPARENTZ_WEB_URL}/baby-names/${babyName}`;
  const collegeDetailPage = `${config.frontendBaseUrl}/college/${urlSlug}`;

  const urls = [babyNameMeaningOriginCache ? babyNameDetailPage : collegeDetailPage];

  // // search cache and clear
  // api({
  //   method: 'POST',
  //   url: searchCacheApiUrl,
  //   data: {
  //     key: key ? key : 'SCHOOLMYKIDS_',
  //     bodyParam: !removeBodyParam ? 'collegeId' : undefined,
  //     bodyParamValue: !removeBodyParam ? collegeId : undefined,
  //     url: babyNameMeaningOriginCache ? `/api/v1/baby-name/meaningOrigin/${babyName}` : `/api/v1/college/${collegeId}`,
  //     useOr: true,
  //     page: 1,
  //     size: 1000,
  //     sortBy: 'id',
  //     sortOrder: 0,
  //   },
  // })?.then((data) => {
  //   if (!isEmpty(data?.results)) {
  //     let keys = [];
  //     data?.results?.map((e) => keys?.push(e.key));
  //     api({
  //       method: 'POST',
  //       url: clearCacheApiUrl,
  //       data: {
  //         keys,
  //       },
  //     })?.then(() => {
  //       api({
  //         method: 'POST',
  //         url: clearCDNCacheApiUrl,
  //         data: {
  //           urls,
  //         },
  //       });
  //     });
  //   }
  // });
};
