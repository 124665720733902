import Breadcrumbs from 'components/Common/Breadcrumb';
import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import AssetImageUploader from 'containers/blog/BlogList/components/AssetImageUploader';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Form, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import { LANGUAGE_ARRAY, MODULE_TYPE, taxonomyType } from 'utils/constants';
import * as Yup from 'yup';
import useRequest from '../../../../hooks/useRequest';
import { SMKLinks } from '../../../../utils/links';
import { showImage, titleCaseIfExists } from 'utils/helper';

const UpdateSolidFood = () => {
  const history = useHistory();
  const { id: solidFoodID } = useParams();

  toast.configure();

  const [solidFoodDetails, setsolidFoodDetails] = useState();
  const [foodTypeList, setFoodTypeList] = useState([]);

  // Dropdown Api Call
  const [getSolidFoodTypeListRequest, { loading: getSolidFoodTypeListLoading }] = useRequest({
    url: `/common/admin/taxonomy/search`,

    method: 'POST',
    onSuccess: (data) => {
      setFoodTypeList(
        data?.results?.map((e) => ({
          label: e.name,
          value: e.id,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getSolidFoodRequest, { loading: getSolidFoodLoading }] = useRequest({
    url: `/solid-food/admin/${solidFoodID}`,

    method: 'GET',
    onSuccess: (data) => {
      setsolidFoodDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [addSolidFoodRequest, { loading: addSolidFoodLoading }] = useRequest({
    url: `/solid-food/admin`,

    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.SOLID_FOOD_LISTING;
      history.replace(url);
      toast.success('Success! Solid Food has been added.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [UpdateSolidFoodRequest, { loading: UpdateSolidFoodLoading }] = useRequest({
    url: `/solid-food/admin/${solidFoodID}`,
    method: 'PUT',

    onSuccess: (data) => {
      const url = SMKLinks.SOLID_FOOD_LISTING;
      history.replace(url);
      toast.success('Success! Solid Food has been updated.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const editInitialValues = () => {
    return {
      active: solidFoodDetails?.active,
      deleted: solidFoodDetails?.deleted,
      hasArticles: solidFoodDetails?.hasArticles,
      name: solidFoodDetails?.name,
      slug: solidFoodDetails?.slug,
      solidFoodTypeId: foodTypeList?.find((e) => e?.value === solidFoodDetails?.solidFoodTypeId),
      language: LANGUAGE_ARRAY?.find((e) => e?.value === solidFoodDetails?.language),
      order: solidFoodDetails?.order,
      description: solidFoodDetails?.description,
      photo: solidFoodDetails?.photo,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    hasArticles: false,
    name: '',
    slug: '',
    solidFoodTypeId: null,
    language: { value: 0, label: 'English' },
    order: '',
    description: '',
    photo: {},
  });

  const isEmptyValue = (val) => (val !== null || val !== undefined ? val : undefined);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(solidFoodID) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: async (values) => {
      const data = {
        active: values?.active || false,
        deleted: values?.deleted || false,
        hasArticles: values?.hasArticles || false,
        name: isEmptyValue(values?.name),
        slug: values?.slug,
        solidFoodTypeId: isEmptyValue(values?.solidFoodTypeId?.value),
        language: values?.language?.value || 0,
        order: values?.order || 0,
        description: isEmptyValue(values?.description),
        photo: !isEmpty(values?.photo) ? values?.photo : null,
      };

      if (isEmpty(solidFoodID)) {
        addSolidFoodRequest({
          body: {
            ...data,
          },
        });
      } else {
        UpdateSolidFoodRequest({
          body: {
            ...data,
          },
        });
      }
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
      solidFoodTypeId: Yup.object()
        .required('Please provide solid food typ')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(solidFoodID)) {
      getSolidFoodRequest();
    }
  }, [solidFoodID]);

  // Dropdowns data api call
  useEffect(() => {
    const searchPayload = {
      page: 1,
      size: 1000,
      sortBy: 'name',
      sortOrder: 0,
    };
    getSolidFoodTypeListRequest({
      body: {
        ...searchPayload,
        type: taxonomyType.SOLID_FOOD_TYPE,
      },
    });
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={getSolidFoodTypeListLoading || addSolidFoodLoading || getSolidFoodLoading || UpdateSolidFoodLoading}
        />
        <Container fluid>
          <Breadcrumbs title="Solid Food" breadcrumbItem={`${isEmpty(solidFoodID) ? 'Add' : 'Edit'}  Solid Food`} />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="name" className="form-label  ">
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="name"
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter name..."
                            invalid={!!(formik.touched.name && formik.errors.name)}
                            {...formik.getFieldProps('name')}
                          />
                          <FormFeedback className="d-block">{formik.errors.name}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12} className="mb-4">
                        <SlugInput
                          formik={formik}
                          ID={solidFoodID}
                          nameValue={formik.values.name}
                          formGroupClassName="mb-0"
                        />
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="solidFoodTypeId" className="form-label  ">
                            Solid Food Type <span className="text-danger">*</span>
                          </Label>

                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 999 }),
                            }}
                            placeholder="Select category..."
                            options={foodTypeList}
                            value={formik.values.solidFoodTypeId}
                            onChange={(val) => formik.setFieldValue('solidFoodTypeId', val)}
                          />
                          <FormFeedback className="d-block">{formik.errors.solidFoodTypeId}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="description" className="form-label">
                            Description
                          </Label>
                          <Input
                            id="description"
                            name="description"
                            type="textarea"
                            rows={5}
                            className="form-control"
                            placeholder="Enter description..."
                            invalid={!!(formik.touched.description && formik.errors.description)}
                            {...formik.getFieldProps('description')}
                          />
                          <FormFeedback className="d-block">{formik.errors.description}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <div>
                      <Button type="submit" color="primary" className="mb-3 w-100">
                        {solidFoodID ? 'Save Solid Food' : 'Add Solid Food'}
                      </Button>

                      <Button
                        className="w-100"
                        color="light"
                        onClick={() => {
                          history.replace(SMKLinks.SOLID_FOOD_LISTING);
                        }}>
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    {['active', 'deleted']?.map((elem, index) => (
                      <FormGroup className={`${'mb-3'}`} key={index}>
                        <Label className="mb-3">{titleCaseIfExists(elem)} </Label>
                        <div className="d-flex align-items-center">
                          {[true, false].map((option, i) => (
                            <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                              <input
                                type="radio"
                                id={`${elem}_${option}`}
                                name={elem}
                                className="form-check-input"
                                checked={formik.values[elem] === option}
                                onChange={() => formik.setFieldValue(`${elem}`, option)}
                              />
                              <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                                {option ? 'Yes' : 'No'}
                              </label>
                            </div>
                          ))}
                        </div>
                      </FormGroup>
                    ))}
                  </CardBody>
                </Card>

                <AssetImageUploader
                  forType={MODULE_TYPE.METADATA}
                  formik={formik}
                  fieldName={'photo'}
                  label={'Select Image'}
                />
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default UpdateSolidFood;
