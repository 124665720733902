import { api } from 'utils/axios';
import config from '../../config/config';

export const clearCDNCacheApiCall = (slug) => {
  const urls = [`${config.frontendBaseUrl}/worksheet/${slug}`, `${config.frontendBaseUrl}/worksheets`];
  api({
    method: 'POST',
    url: `${config.apiEndpoint}/admin-tools/clearCDNCache`,
    data: {
      urls,
    },
  });
};
