import { createRoutine } from 'redux-saga-routines';
import { GET_CITIES, GET_COUNTRIES, GET_REGIONS, GET_DISTRICTS, GET_SUB_AREAS } from './actionTypes';

// ACTIONS

export const getCountries = createRoutine(GET_COUNTRIES);
export const getRegions = createRoutine(GET_REGIONS);
export const getDistricts = createRoutine(GET_DISTRICTS);
export const getCities = createRoutine(GET_CITIES);
export const getSubAreas = createRoutine(GET_SUB_AREAS);
