import { USER_ROLE } from '../../utils/constants';
import AddUser from './AddUser/addUser';
import USER_LINKS from './links';
import UserSubscriptionsDetails from './subscription/details';
import UserSubscriptionsList from './subscription/list';

import UserList from './UserList/usersList';
import UserProfile from './UserList/UsersProfile/usersProfile';

const USER_ROUTES = [
  // {
  //   path: USER_LINKS.USERS_LISTING,
  //   exact: true,
  //   component: UserList,
  //   role: [USER_ROLE.ADMIN],
  // },
  {
    path: USER_LINKS.USER_PROFILE,
    exact: true,
    component: UserProfile,
    role: [USER_ROLE.ADMIN],
  },
  // {
  //   path: USER_LINKS.ADD_USER,
  //   exact: true,
  //   component: AddUser,
  //   role: [USER_ROLE.ADMIN],
  // },
  {
    path: USER_LINKS.USER_SUBSCRIPTIONS_LISTING,
    exact: true,
    component: UserSubscriptionsList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: USER_LINKS.USER_SUBSCRIPTION_DETAILS,
    exact: true,
    component: UserSubscriptionsDetails,
    role: [USER_ROLE.ADMIN],
  },

  // SMK
  {
    path: USER_LINKS.SMK_USERS_LISTING,
    exact: true,
    component: UserList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: USER_LINKS.SMK_ADD_USER,
    exact: true,
    component: AddUser,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: USER_LINKS.SMK_EDIT_USER,
    exact: true,
    component: AddUser,
    role: [USER_ROLE.ADMIN],
  },
  // TPZ
  {
    path: USER_LINKS.TPZ_USERS_LISTING,
    exact: true,
    component: UserList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: USER_LINKS.TPZ_ADD_USER,
    exact: true,
    component: AddUser,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: USER_LINKS.TPZ_EDIT_USER,
    exact: true,
    component: AddUser,
    role: [USER_ROLE.ADMIN],
  },

  // ---------------------------- Mediwatcher --------------------------------
  {
    path: USER_LINKS.MEDIWATCHER_USERS_LISTING,
    exact: true,
    component: UserList,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: USER_LINKS.MEDIWATCHER_ADD_USER,
    exact: true,
    component: AddUser,
    role: [USER_ROLE.ADMIN],
  },
  {
    path: USER_LINKS.MEDIWATCHER_EDIT_USER,
    exact: true,
    component: AddUser,
    role: [USER_ROLE.ADMIN],
  },
];

export { USER_ROUTES, USER_LINKS };
