import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const ToggleButton = (props) => {
  const { label, onChange, checked, disabled } = props;

  const toggleId = _.uniqueId('toggle_');

  return (
    <div className="form-check form-switch form-switch-lg mb-3 d-flex" dir="ltr" style={{ zIndex: '0' }}>
      <input
        type="checkbox"
        className="form-check-input"
        id={toggleId}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className={`form-check-label `} htmlFor={toggleId}>
        {label}
      </label>
    </div>
  );
};

ToggleButton.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ToggleButton;
