import Loader from 'components/Loader';
import SlugInput from 'components/SlugInput';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { LANGUAGE_ARRAY_WITH_STRING, LANGUAGE_VALUES_STRING, MODULE_TYPE } from 'utils/constants';
import { titleCaseIfExists } from 'utils/helper';
import * as Yup from 'yup';
import Breadcrumbs from '../../../../components/Common/Breadcrumb';
import useRequest from '../../../../hooks/useRequest';
import { SMKLinks } from '../../../../utils/links';

const UpdateTags = () => {
  const history = useHistory();
  const { id: tagId } = useParams();

  toast.configure();

  const [tagDetails, settagDetails] = useState();
  const selectTypeOptions = Object.keys(MODULE_TYPE)?.map((e) => ({
    label: titleCaseIfExists(e),
    value: MODULE_TYPE[e],
  }));

  const [getTagRequest, { loading: getTagLoading }] = useRequest({
    url: `/common/admin/tag/${tagId}`,
    method: 'GET',
    onSuccess: (data) => {
      settagDetails(data);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [addTagRequest, { loading: addTagLoading }] = useRequest({
    url: `/common/admin/tag`,
    method: 'POST',
    onSuccess: (data) => {
      const url = SMKLinks.TAGS_LIST;
      history.replace(url);
      toast.success(`Success! Tag ( ${data?.id} ) has been added.`);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const [updateTagRequest, { loading: updateTagLoading }] = useRequest({
    url: `/common/admin/tag/${tagId}`,
    method: 'PUT',
    onSuccess: (data) => {
      const url = SMKLinks.TAGS_LIST;
      history.replace(url);
      toast.success(`Success! Tag ( ${data?.id} ) has been added.`);
    },
    onError: (err) => {
      toast.error(err?.message);
      console.log(err);
    },
  });

  const editInitialValues = () => {
    return {
      id: tagDetails?.id,
      active: tagDetails?.active,
      deleted: tagDetails?.deleted,

      name: tagDetails?.name,
      slug: tagDetails?.slug,
      description: tagDetails?.description,
      language: tagDetails?.language,

      forType: tagDetails?.forType ? selectTypeOptions?.filter((e) => e?.value === tagDetails?.forType) : null,
    };
  };

  const getInitialValues = () => ({
    active: true,
    deleted: false,
    name: '',
    slug: '',
    description: '',
    forType: '',
    language: LANGUAGE_VALUES_STRING.english,
  });

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: isEmpty(tagId) ? { ...getInitialValues() } : { ...editInitialValues() },
    onSubmit: (values) => {
      const data = {
        active: values?.active,
        deleted: values?.deleted,
        language: values?.language,
        forType: values?.forType?.value,

        name: values?.name,
        slug: values?.slug,
        description: values?.description,
      };

      isEmpty(tagId)
        ? addTagRequest({
            body: {
              ...data,
            },
          })
        : updateTagRequest({
            body: {
              ...data,
            },
          });
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Please provide name'),
      slug: Yup.string().required('Please provide slug'),
      forType: Yup.object()
        .required('Please provide for type')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(tagId)) {
      getTagRequest();
    }
  }, [tagId]);

  return (
    <div className="page-content">
      <Loader isActive={addTagLoading || getTagLoading || updateTagLoading} />
      <Container fluid>
        <Breadcrumbs title="Location" breadcrumbItem={`${isEmpty(tagId) ? 'Add' : 'Edit'}  Tag`} />

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col md={9}>
              <Card>
                <CardBody>
                  <Row className="align-items-center">
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="name" className="form-label  ">
                          Name
                        </Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          className="form-control"
                          placeholder="Name.."
                          invalid={!!(formik.touched.name && formik.errors.name)}
                          {...formik.getFieldProps('name')}
                        />
                        <FormFeedback>{formik.errors.name}</FormFeedback>
                      </FormGroup>
                    </Col>

                    <Col md={12} className="mb-4">
                      <SlugInput
                        formik={formik}
                        ID={tagId}
                        nameValue={formik.values.name}
                        formGroupClassName={'mt-0'}
                      />
                    </Col>

                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="description" className="form-label  ">
                          Description
                        </Label>
                        <Input
                          id="description"
                          name="description"
                          rows={8}
                          type="textarea"
                          className="form-control"
                          placeholder="Description.."
                          invalid={!!(formik.touched.description && formik.errors.description)}
                          {...formik.getFieldProps('description')}
                        />
                        <FormFeedback>{formik.errors.description}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={3}>
              <Card>
                <CardBody>
                  <Button type="submit" color="primary" className="mb-3 w-100">
                    {tagId ? 'Save' : 'Add'}
                  </Button>

                  <Button
                    className="w-100"
                    color="light"
                    onClick={() => {
                      history.replace(SMKLinks.TAGS_LIST);
                    }}>
                    Cancel
                  </Button>
                </CardBody>
              </Card>

              <Card className="border">
                <CardBody>
                  <FormGroup>
                    <Label htmlFor="type" className="form-label  ">
                      For Type
                    </Label>
                    <Select
                      id="type"
                      options={selectTypeOptions}
                      value={formik.values.forType}
                      onChange={(forType) => formik.setFieldValue('forType', forType)}
                    />
                    {formik.touched.forType && <div className="invalid-feedback d-block">{formik.errors.forType}</div>}
                  </FormGroup>
                </CardBody>
              </Card>
              <Card className="border">
                <CardBody>
                  <CardTitle className="mb-3">Language </CardTitle>
                  {LANGUAGE_ARRAY_WITH_STRING?.map((option, i) => (
                    <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                      <input
                        type="radio"
                        id={`language_${option?.value}`}
                        name="language"
                        className="form-check-input"
                        checked={formik.values.language === option?.value}
                        onChange={() => {
                          formik.setFieldValue('language', option?.value);
                        }}
                      />
                      <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                        {option?.label}
                      </label>
                    </div>
                  ))}
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div>
                    <CardTitle className="mb-3">Active </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`active_${i}`} className="form-check mb-3 d-inline-block me-4">
                        <input
                          type="radio"
                          id={`active_${option}`}
                          name="active"
                          className="form-check-input"
                          checked={formik.values.active === option}
                          onChange={() => formik.setFieldValue('active', option)}
                        />
                        <label className="form-check-label" htmlFor={`active_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </div>

                  <div>
                    <CardTitle className="mb-3">Delete </CardTitle>
                    {[true, false].map((option, i) => (
                      <div key={`deleted_${i}`} className="form-check mb-3 d-inline-block me-4">
                        <input
                          type="radio"
                          id={`deleted_${option}`}
                          name="deleted"
                          className="form-check-input"
                          checked={formik.values.deleted === option}
                          onChange={() => formik.setFieldValue('deleted', option)}
                        />
                        <label className="form-check-label" htmlFor={`deleted_${option}`}>
                          {option ? 'Yes' : 'No'}
                        </label>
                      </div>
                    ))}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default UpdateTags;
