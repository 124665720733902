import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import useRequest from '../../../hooks/useRequest';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader';

const ActivityCount = ({ startDate, endDate }) => {
  const [activities, setActivities] = useState([]);
  const [getActivitiesCountListRequest, { loading: getActivitiesCountListLoading }] = useRequest({
    url: `/patient-tracking/admin-tools/app-activity-counts`,
    method: 'POST',
    onSuccess: (data) => {
      setActivities(data.results);
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  useEffect(() => {
    getActivitiesCountListRequest({
      body: {
        startDate,
        endDate,
      },
    });
  }, [startDate, endDate]);
  return (
    <>
      <Loader isActive={getActivitiesCountListLoading} />
      <Card>
        <CardBody>
          <CardTitle className="float-sm-left">Activity Count</CardTitle>
          <div className="table-responsive" style={{ minHeight: 300, maxHeight: 300 }}>
            <table className="table table-centered table-striped">
              <thead className="thead-light">
                <tr className="table-dark">
                  <th>Activity Name</th>
                  <th>Count</th>
                </tr>
              </thead>
              <tbody>
                {activities.map((transaction, key) => (
                  <tr key={`_tr_${key}`}>
                    <td className="table-warning">
                      <Link
                        to={{ pathname: `/tpz/activity-history`, query: { activityType: transaction.activity } }}
                        className="text-body font-weight-bold">
                        {transaction.activity}
                      </Link>
                    </td>
                    <td className="table-info">{transaction.count}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default ActivityCount;
