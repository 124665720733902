import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { SMKLinks } from 'utils/links';
import { Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { isEmpty } from 'lodash';
import TableError from 'components/TableError';
import { ACTIVE_AND_DELETED_ARRAY, LOCATION_LEVEL_ARRAY, SORT_VALUES } from 'utils/constants';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { buildURL, checkIfValueExist, printDate, urlSlugify } from '../../../utils/helper';
import { getCities, getCountries, getDistricts, getRegions } from '../actions';
import { countriesSelector, regionsSelector, citiesSelector, districtsSelector } from '../selectors';

const stateSelector = createStructuredSelector({
  countries: countriesSelector,
  regions: regionsSelector,
  districts: districtsSelector,
  cities: citiesSelector,
});

const LocationMasterList = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();
  const dispatch = useDispatch();
  const { countries, regions, cities, districts } = useSelector(stateSelector);

  const [locationMeteDataList, setlocationMeteDataList] = useState([]);
  const [locationMeteDataListPagination, setlocationMeteDataListPagination] = useState({});

  const defaultFilters = {
    active: { value: null, label: 'All' },
    deleted: { value: null, label: 'All' },
    id: undefined,
    name: undefined,
    level: undefined,

    country: '',
    region: '',
    city_district: '',
    city: '',
  };
  const [filters, setFilters] = useState(defaultFilters);

  const locationAddParams = `${!isEmpty(filters?.level) ? `?level=${filters?.level?.value}` : ''}${
    !isEmpty(filters?.country) ? `&country=${filters?.country?.value}` : ''
  }${!isEmpty(filters?.region) ? `&region=${filters?.region?.value}` : ''}${
    !isEmpty(filters?.city_district) ? `&district=${filters?.city_district?.value}` : ''
  }${!isEmpty(filters?.city) ? `&city=${filters?.city?.value}` : ''}`;

  const [getlocationMeteDataListRequest, { loading: getlocationMeteDataListLoading }] = useRequest({
    url: `/place/admin/search`,
    method: 'POST',
    onSuccess: (data) => {
      setlocationMeteDataListPagination(data?.pagination);
      setlocationMeteDataList(data?.results);
    },
  });

  const [markActiveInActiveRequest, { loading: markActiveInActiveLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Location Meta Data has been ${data?.active ? 'active' : 'Inactive'}.`);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'POST',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Location Meta Data has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    getlocationMeteDataListRequest({
      body: {
        page,
        size: 100,
        ...filters,
        active: checkIfValueExist(filters?.active?.value),
        deleted: checkIfValueExist(filters?.deleted?.value),
        id: checkIfValueExist(filters?.id),
        name: checkIfValueExist(filters?.name),
        level: checkIfValueExist(filters?.level?.value),

        country: filters?.country?.label,
        region: filters?.region?.label,
        city_district: filters?.city_district?.label,
        city: filters?.city?.label,
        ...SORT_VALUES,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters, name: '', id: '', level: '' });
    getlocationMeteDataListRequest({
      body: {
        page: 1,
        size: 100,
        ...defaultFilters,
        level: defaultFilters.level?.value,
        active: checkIfValueExist(defaultFilters?.active?.value),
        deleted: checkIfValueExist(defaultFilters?.deleted?.value),
        ...SORT_VALUES,
      },
    });
  };

  const handleActive = (data) => {
    data?.active
      ? markActiveInActiveRequest({
          url: `/place/admin/markInactive/${data?.id}`,
        })
      : markActiveInActiveRequest({
          url: `/place/admin/markActive/${data?.id}`,
        });
  };
  const handleDelete = (data) => {
    data?.deleted
      ? deleteUnDeleteRequest({
          url: `/place/admin/undelete/${data?.id}`,
        })
      : deleteUnDeleteRequest({
          url: `/place/admin/delete/${data?.id}`,
        });
  };

  const onCountrySelect = (country) => {
    if (!isEmpty(country)) {
      dispatch(
        getRegions.trigger({
          level: 1,
          country: country.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      setFilters({ ...filters, country });
    } else {
      setFilters({ ...filters, country: '' });
    }
  };
  const onRegionSelect = (region) => {
    if (!isEmpty(region)) {
      dispatch(
        getDistricts.trigger({
          level: 3,
          region: region.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      dispatch(
        getCities.trigger({
          level: 2,
          region: region.name,
          page: 1,
          size: 500,
          sortBy: 'slug',
          sortOrder: 0,
        })
      );
      setFilters({ ...filters, region });
    } else {
      setFilters({ ...filters, region: '' });
    }
  };
  const onDistrictSelect = (district) => {
    if (!isEmpty(district)) {
      setFilters({ ...filters, city_district: district });
    } else {
      setFilters({ ...filters, city_district: '' });
    }
  };
  const onCitySelect = (city) => {
    if (!isEmpty(city)) {
      setFilters({ ...filters, city });
    } else {
      setFilters({ ...filters, city: '' });
    }
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
    dispatch(getCountries.trigger({ level: 0, page: 1, size: 500, sortBy: 'slug', sortOrder: 0 }));
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getlocationMeteDataListLoading || markActiveInActiveLoading || deleteUnDeleteLoading} />

        <Container fluid>
          <Breadcrumbs title="Locations" breadcrumbItem="Locations meta data list" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <div className="row">
                  <div className="col-lg-2">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.active}
                      onChange={(value) => setFilters({ ...filters, active: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={filters.deleted}
                      onChange={(value) => setFilters({ ...filters, deleted: value })}
                    />
                  </div>
                  <div className="col-lg-2">
                    <label className="form-label">Level</label>
                    <Select
                      options={LOCATION_LEVEL_ARRAY}
                      value={filters.level}
                      onChange={(value) => setFilters({ ...filters, level: value })}
                    />
                  </div>

                  <div className="col-lg-2">
                    <label htmlFor="Id" className="form-label">
                      ID
                    </label>
                    <Input
                      type="number"
                      name="Id"
                      placeholder="Location ID..."
                      value={filters.id}
                      onChange={(e) => setFilters({ ...filters, id: Number(e.target.value) })}
                    />
                  </div>
                  <div className="col-lg-3">
                    <label className="form-label">Name</label>
                    <Input
                      type="text"
                      name="name"
                      placeholder="name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e.target.value })}
                    />
                  </div>
                </div>
                <Row className="mt-4">
                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="school_country" className="form-label">
                        Country
                      </Label>
                      <Select
                        isClearable
                        id="school_country"
                        options={countries}
                        value={filters?.country}
                        onChange={onCountrySelect}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="school_state" className="form-label  ">
                        Region/State
                      </Label>

                      <Select
                        isClearable
                        id="school_state"
                        placeholder="Select..."
                        value={filters?.region}
                        onChange={onRegionSelect}
                        options={regions}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="district" className="form-label  ">
                        District
                      </Label>
                      <Select
                        isClearable
                        id="district"
                        placeholder="Select..."
                        value={filters?.city_district}
                        onChange={onDistrictSelect}
                        options={districts}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup className="mb-4">
                      <Label for="school_city" className="form-label  ">
                        City
                      </Label>
                      <Select
                        isClearable
                        id="school_city"
                        placeholder="Select..."
                        value={filters?.city}
                        onChange={onCitySelect}
                        options={cities}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {locationMeteDataListPagination?.totalCount} Locations Meta Data Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_LOCATION}>
                  <i className="mdi mdi-plus me-1" />
                  Add Location
                </Link>
              </div>
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={{
                    pathname: SMKLinks.ADD_LOCATION,
                    search: locationAddParams,
                  }}>
                  <i className="mdi mdi-plus me-1" />
                  Add Location with current filters
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col">ID </th>
                        <th scope="col">Name</th>
                        <th scope="col">Display Name</th>
                        <th scope="col">Short Code</th>
                        <th scope="col">Alternate Name</th>
                        <th scope="col">Country</th>
                        <th scope="col">Region</th>
                        <th scope="col">City</th>
                        <th scope="col">Level</th>
                        <th scope="col">Created Date</th>
                        <th scope="col">Version</th>
                        <th scope="col">has States</th>
                        <th scope="col">Active</th>
                        <th scope="col">Deleted</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(locationMeteDataList) ? (
                        locationMeteDataList?.map((data, index) => (
                          <tr key={index}>
                            <td className={`${urlSlugify(data?.name) !== data?.slug ? 'bg-danger' : ''}`}>
                              {data?.id}
                            </td>
                            <td>
                              <Link
                                to={buildURL(SMKLinks.EDIT_LOCATION, {
                                  id: data?.id,
                                })}>
                                {data?.name}
                              </Link>
                              <br />
                              {data?.slug}
                            </td>
                            <td>{data?.displayName}</td>
                            <td>{data?.shortCode}</td>
                            <td>{data?.alternateName}</td>
                            <td>{data?.country?.displayName}</td>
                            <td>{data?.region?.displayName}</td>
                            <td>
                              {data?.city?.displayName} - {data?.city?.id && `[ ${data?.city?.id} ]`}
                            </td>
                            <td>{data?.level}</td>
                            <td>{printDate(data?.createdDate)}</td>
                            <td>{data?.version}</td>
                            <td>
                              <Badge className={data?.hasStates ? 'bg-success' : 'bg-danger'}>
                                {data?.hasStates ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.active ? 'bg-success' : 'bg-danger'}>
                                {data?.active ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={data?.deleted ? 'bg-success' : 'bg-danger'}>
                                {data?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <UncontrolledDropdown>
                                <DropdownToggle href="#" className="card-drop " tag="i">
                                  <i role="button" className="mdi mdi-dots-vertical font-size-18 " />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem onClick={() => handleActive(data)}>
                                    <i
                                      className={`fas  ${
                                        data?.active ? 'fa-ban text-danger' : 'fa-power-off text-success'
                                      }   m-1 pe-3`}
                                    />
                                    {data?.active ? 'Disable' : 'Enable'}
                                  </DropdownItem>
                                  <DropdownItem onClick={() => handleDelete(data)}>
                                    <i className="fas fa-trash-alt text-danger m-1 pe-3" />
                                    {data?.deleted ? 'Undelete' : 'Delete'}
                                  </DropdownItem>
                                  <Divider className="my-2" />

                                  <Link
                                    to={buildURL(SMKLinks.EDIT_LOCATION, {
                                      id: data?.id,
                                    })}>
                                    <DropdownItem>
                                      <i className="fas fa-pencil-alt text-success m-1 pe-3 " />
                                      Edit
                                    </DropdownItem>
                                  </Link>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          <Paginate pageInfo={locationMeteDataListPagination} onChangePage={handleChangePage} />
        </Container>
      </div>
    </>
  );
};

export default LocationMasterList;
