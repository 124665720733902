import { userSelector } from 'common/globalComponents/selectors';
import Breadcrumbs from 'components/Common/Breadcrumb';
import { convertEditorBlocksToHTML } from 'components/ContentCompiler';
import Loader from 'components/Loader';
import PageDetailCard from 'components/PageDetailsCard';
import SlugInput from 'components/SlugInput';
import ContentEditor from 'containers/blog/BlogList/editor';
import { useFormik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { createStructuredSelector } from 'reselect';
import { FAQ_MODULES_SUB_MODULES, LANGUAGE_ARRAY_WITH_STRING, MODULE_TYPE } from 'utils/constants';
import { titleCaseIfExists, urlSlugify } from 'utils/helper';
import * as Yup from 'yup';
import useRequest from '../../../hooks/useRequest';
import { SMKLinks } from '../../../utils/links';

const stateSelector = createStructuredSelector({
  userDetails: userSelector,
});

const AddUpdateFAQ = () => {
  const history = useHistory();
  const { id: faqID } = useParams();
  const { userDetails } = useSelector(stateSelector);

  toast.configure();

  const editorInstance = useRef(null);

  const [faqDetails, setFaqDetails] = useState();

  const [faqModuleList, setFaqModuleList] = useState([]);
  const [faqSubModuleList, setFaqSubModuleList] = useState([]);
  const [faqCategoriesList, setFaqCategoriesList] = useState([]);

  const [getFAQModule, { loading: getFAQModuleLoading }] = useRequest({
    url: `/cms/admin/faq/modules`,
    method: 'GET',
    onSuccess: (data) => {
      setFaqModuleList(
        data?.map((e) => ({
          label: e.module,
          value: e.module,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [getFAQSubModule, { loading: getFAQSubModuleLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setFaqSubModuleList(
        data?.map((e) => ({
          label: e.subModule,
          value: e.subModule,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [getFAQCategories, { loading: getFAQCategoriesLoading }] = useRequest({
    method: 'GET',
    onSuccess: (data) => {
      setFaqCategoriesList(
        data?.map((e) => ({
          label: e.category,
          value: e.category,
        }))
      );
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [getFaqRequest, { loading: getFaqLoading }] = useRequest({
    url: `/cms/admin/faq/${faqID}`,
    method: 'GET',
    onSuccess: async (data) => {
      setFaqDetails(data);

      if (!editorInstance.current || !editorInstance.current.isReady) {
        return;
      }

      await editorInstance.current.isReady;
      if (!isEmpty(data?.contentJSON)) {
        await editorInstance.current.blocks.render({ blocks: data.contentJSON?.blocks });
      }

      // if (data?.content) {
      //   const content = data.content.replaceAll('/n', ' ');
      //   if (!isEmpty(content)) {
      //     await editorInstance.current.blocks.renderFromHTML(content);
      //   }
      // }
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const [addFaqRequest, { loading: addFaqLoading }] = useRequest({
    url: `/cms/admin/faq`,
    method: 'POST',
    onSuccess: () => {
      const url = SMKLinks.FAQ_LIST;
      history.replace(url);
      toast.success('Success! FAQ has been added.');
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });
  const [updateFaqRequest, { loading: updateFaqLoading }] = useRequest({
    url: `/cms/admin/faq/${faqID}`,
    method: 'PUT',
    onSuccess: () => {
      toast.success('Success! FAQ has been updated.');
      window.location.reload();
    },
    onError: (err) => {
      toast.error(err?.message?.message || err?.message);
    },
  });

  const getFormikValues = (data) => {
    return {
      active: data?.active !== undefined ? data.active : false,
      deleted: data?.deleted !== undefined ? data.deleted : false,
      isDynamic: data?.isDynamic !== undefined ? data.isDynamic : true,
      webOnly: data?.webOnly !== undefined ? data.webOnly : false,
      isDynamic: data?.isDynamic || false,
      title: data?.title || '',
      slug: data?.slug || '',
      order: data?.order || '0',
      language: data?.language || LANGUAGE_ARRAY_WITH_STRING[0]?.value,
      content: data?.content || '',
      contentJSON: data?.contentJSON || {},
      module: !isEmpty(data?.module)
        ? {
            label: data?.module,
            value: data?.module,
          }
        : null,
      subModule: !isEmpty(data?.subModule)
        ? {
            label: data?.subModule,
            value: data?.subModule,
          }
        : null,
      category: !isEmpty(data?.category)
        ? {
            label: data?.category,
            value: data?.category,
          }
        : null,
      metadata: !isEmpty(data?.metadata) ? JSON.stringify(data?.metadata, null, 2) : '{}',
    };
  };

  const isJSON = (val) => {
    try {
      return typeof val === 'string' && JSON.parse(val);
    } catch (err) {
      toast.error(err);
      return false;
    }
  };

  const convertJSON = (val) => {
    try {
      const parseObj = JSON.parse(val);
      return parseObj;
    } catch (err) {
      toast.error(err);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    initialValues: !isEmpty(faqDetails) ? { ...getFormikValues(faqDetails) } : { ...getFormikValues() },
    onSubmit: async (values) => {
      if (!isJSON(values?.metadata)) {
        toast.error('Please enter a valid metadata JSON');
        return;
      }
      const updatedContentJSON = await editorInstance.current.save();

      const payload = {
        ...values,
        module: values?.module?.label,
        moduleSlug: values?.module?.value,

        subModule: values?.subModule?.label,
        subModuleSlug: values?.subModule?.value,

        category: values?.category?.label,
        categorySlug: values?.category?.value,

        content: convertEditorBlocksToHTML(updatedContentJSON),
        contentJSON: updatedContentJSON,
        metadata: convertJSON(values?.metadata),
      };

      if (isEmpty(faqID)) {
        addFaqRequest({
          body: payload,
        });
      } else {
        updateFaqRequest({
          body: payload,
        });
      }
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Please provide title '),
      slug: Yup.string().required('Please provide slug'),
      module: Yup.object()
        .required('Please provide module ')
        .nullable(true),
      subModule: Yup.object()
        .required('Please provide subModule ')
        .nullable(true),
      category: Yup.object()
        .required('Please provide category ')
        .nullable(true),
    }),
  });

  useEffect(() => {
    if (!isEmpty(faqID)) {
      getFaqRequest();
    }
  }, [faqID]);

  useEffect(() => {
    getFAQModule();
  }, []);

  useEffect(() => {
    const moduleName = formik?.values?.module?.label;
    if (!isEmpty(moduleName) && !formik.values.module?.__isNew__) {
      getFAQSubModule({
        url: `/cms/admin/faq/${moduleName}/sub-modules`,
      });
      getFAQCategories({
        url: `/cms/admin/faq/${moduleName}/categories`,
      });
    }
  }, [formik.values.module?.label]);

  return (
    <>
      <div className="page-content">
        <Loader
          isActive={
            getFaqLoading ||
            addFaqLoading ||
            updateFaqLoading ||
            getFAQModuleLoading ||
            getFAQSubModuleLoading ||
            getFAQCategoriesLoading
          }
        />
        <Container fluid>
          <Breadcrumbs title="FAQ" breadcrumbItem={`${isEmpty(faqID) ? 'Add' : 'Edit'}  FAQ`} />

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col md={9}>
                <Card>
                  <CardBody>
                    <Row className="align-items-center">
                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="title" className="form-label  ">
                            Question <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="title"
                            name="title"
                            type="text"
                            className="form-control"
                            placeholder="Enter question..."
                            invalid={!!(formik.touched.title && formik.errors.title)}
                            {...formik.getFieldProps('title')}
                          />
                          <FormFeedback>{formik.errors.title}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <SlugInput
                            required={true}
                            formik={formik}
                            ID={faqID}
                            nameValue={formik.values.title}
                            formGroupClassName={'mt-0'}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="order" className="form-label  ">
                            Order
                          </Label>
                          <Input
                            id="order"
                            name="order"
                            type="number"
                            className="form-control"
                            placeholder="Enter question..."
                            invalid={!!(formik.touched.order && formik.errors.order)}
                            {...formik.getFieldProps('order')}
                          />
                          <FormFeedback>{formik.errors.order}</FormFeedback>
                        </FormGroup>
                      </Col>

                      <Col md={12}>
                        <FormGroup className="mb-4">
                          <Label for="content" className="form-label  ">
                            Answer <span className="text-danger">*</span>
                          </Label>
                          <div className="form-control p-3 rounded-2">
                            <ContentEditor editorInstance={editorInstance} forType={MODULE_TYPE.METADATA} />
                          </div>
                          {formik.errors.content && formik.touched.content && (
                            <FormFeedback className="d-block">{formik.errors.content}</FormFeedback>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="metadata" className="form-label  ">
                          Meta Data
                        </Label>
                        <Input
                          rows={6}
                          id="metadata"
                          name="metadata"
                          type="textarea"
                          className="form-control"
                          placeholder="Meta Data..."
                          invalid={!!(formik.touched.metadata && formik.errors.metadata)}
                          {...formik.getFieldProps('metadata')}
                        />
                        <FormFeedback>{formik.errors.metadata}</FormFeedback>
                      </FormGroup>
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card>
                  <CardBody>
                    <div>
                      <Button type="submit" color="primary" className="mb-3 w-100">
                        {faqID ? 'Save FAQ' : 'Add FAQ'}
                      </Button>

                      <Button
                        className="w-100"
                        color="light"
                        onClick={() => {
                          history.replace(SMKLinks.FAQ_LIST);
                        }}>
                        Cancel
                      </Button>
                    </div>
                  </CardBody>
                </Card>

                <PageDetailCard data={faqDetails} />

                <Card>
                  <CardBody>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="module" className="form-label  ">
                          Module <span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="module"
                          options={FAQ_MODULES_SUB_MODULES?.map((e) => ({
                            label: e.name,
                            value: urlSlugify(e?.name),
                          }))}
                          value={formik.values.module}
                          onChange={(e) => formik.setFieldValue('module', e)}
                        />
                        {formik.errors.module && formik.touched.module && (
                          <FormFeedback className="d-block">{formik.errors.module}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="subModule" className="form-label  ">
                          Sub module <span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="subModule"
                          options={FAQ_MODULES_SUB_MODULES?.find(
                            (e) => urlSlugify(e?.name) === urlSlugify(formik.values.module?.value)
                          )?.submodules?.map((e) => ({
                            label: e,
                            value: urlSlugify(e),
                          }))}
                          value={formik.values.subModule}
                          onChange={(e) => formik.setFieldValue('subModule', e)}
                        />
                        {formik.errors.subModule && formik.touched.subModule && (
                          <FormFeedback className="d-block">{formik.errors.subModule}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={12}>
                      <FormGroup className="mb-4">
                        <Label for="category" className="form-label  ">
                          Category (Question Group) <span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="category"
                          options={FAQ_MODULES_SUB_MODULES?.find(
                            (e) => urlSlugify(e?.name) === urlSlugify(formik.values.module?.value)
                          )?.categories?.map((e) => ({
                            label: e,
                            value: urlSlugify(e),
                          }))}
                          value={formik.values.category}
                          onChange={(e) => formik.setFieldValue('category', e)}
                        />
                        {formik.errors.category && formik.touched.category && (
                          <FormFeedback className="d-block">{formik.errors.category}</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>

                    <Col md={12}>
                      {['webOnly']?.map((elem, index) => (
                        <FormGroup key={index}>
                          <Label className="mb-3">{titleCaseIfExists(elem)} </Label>
                          <div className="d-flex align-items-center">
                            {[true, false].map((option, i) => (
                              <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                                <input
                                  type="radio"
                                  id={`${elem}_${option}`}
                                  name={elem}
                                  className="form-check-input"
                                  checked={formik.values[elem] === option}
                                  onChange={() => formik.setFieldValue(`${elem}`, option)}
                                />
                                <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                                  {option ? 'Yes' : 'No'}
                                </label>
                              </div>
                            ))}
                          </div>
                        </FormGroup>
                      ))}
                    </Col>
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    {['active', 'deleted', 'isDynamic']?.map((elem, index) => (
                      <FormGroup className={`mb-3`} key={index}>
                        <Label className="mb-3">{titleCaseIfExists(elem)} </Label>
                        <div className="d-flex align-items-center">
                          {[true, false].map((option, i) => (
                            <div key={`${elem}_${i}`} className="form-check d-inline-block me-4">
                              <input
                                type="radio"
                                id={`${elem}_${option}`}
                                name={elem}
                                className="form-check-input"
                                checked={formik.values[elem] === option}
                                onChange={() => formik.setFieldValue(`${elem}`, option)}
                              />
                              <label className="form-check-label" htmlFor={`${elem}_${option}`}>
                                {option ? 'Yes' : 'No'}
                              </label>
                            </div>
                          ))}
                        </div>
                      </FormGroup>
                    ))}
                  </CardBody>
                </Card>

                <Card>
                  <CardBody>
                    <Col md={12}>
                      <CardTitle className="mb-3">Language </CardTitle>
                      {LANGUAGE_ARRAY_WITH_STRING?.map((option, i) => (
                        <div key={`language_${i}`} className="form-check mb-3 d-inline-block me-4">
                          <input
                            type="radio"
                            id={`language_${option?.value}`}
                            name="language"
                            className="form-check-input"
                            checked={formik.values.language === option?.value}
                            onChange={() => formik.setFieldValue('language', option?.value)}
                          />
                          <label className="form-check-label" htmlFor={`language_${option?.value}`}>
                            {option?.label}
                          </label>
                        </div>
                      ))}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </>
  );
};

export default AddUpdateFAQ;
