import TableError from 'components/TableError';
import { invert, isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  PopoverBody,
  PopoverHeader,
  Row,
  Table,
  UncontrolledDropdown,
  UncontrolledPopover,
} from 'reactstrap';
import { ACTIVE_AND_DELETED_ARRAY, PAYMENT_INTERVAL, SORT_VALUES, SUBSCRIPTION_MODULE_TYPE } from 'utils/constants';
import { SMKLinks } from 'utils/links';
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import Loader from '../../../components/Loader';
import Paginate from '../../../components/Paginate';
import useRequest from '../../../hooks/useRequest';
import { useRouter } from '../../../hooks/useRouter';
import { buildURL, checkIfValueExist, printSerialNumbers, titleCaseIfExists } from '../../../utils/helper';

const SubscriptionPlans = () => {
  const router = useRouter();
  const history = useHistory();
  toast.configure();

  const [subscriptionPlansList, setSubscriptionPlansList] = useState([]);
  const [subscriptionPlansPagination, setSubscriptionPlansPagination] = useState({});

  const paymentIntervals = Object.keys(PAYMENT_INTERVAL)?.map((elem) => ({
    label: titleCaseIfExists(elem),
    value: PAYMENT_INTERVAL[elem],
  }));

  const primaryModuleTypes = Object.keys(SUBSCRIPTION_MODULE_TYPE)?.map((elem) => ({
    label: titleCaseIfExists(elem),
    value: SUBSCRIPTION_MODULE_TYPE[elem],
  }));

  const currencyTypes = [
    { label: 'INR', value: 'INR' },
    { label: 'USD', value: 'USD' },
  ];

  const defaultFilters = {
    active: null,
    deleted: null,
    currency: '',
    primaryModule: '',
    paymentInterval: '',
    name: '',
  };

  const [filters, setFilters] = useState(defaultFilters);

  const [getSubscriptionPlansListRequest, { loading: getSubscriptionPlansListLoading }] = useRequest({
    url: '/subscription/admin/subscription-plan/search',
    method: 'POST',
    onSuccess: (data) => {
      setSubscriptionPlansList(data?.results);
      setSubscriptionPlansPagination(data?.paginator);
    },
  });

  const [deleteUnDeleteRequest, { loading: deleteUnDeleteLoading }] = useRequest({
    method: 'DELETE',
    onSuccess: (data) => {
      handleChangePage(parseInt(router?.query?.page, 10) || 1);
      toast.success(`Success! Subscription Plan has been ${data?.deleted ? 'deleted' : 'Undeleted'}.`);
    },
  });

  const setPageInUrl = (page) => {
    history.push({
      pathname: router.pathname,
      search: `?${new URLSearchParams({ page }).toString()}`,
    });
  };

  const handleChangePage = (page) => {
    setPageInUrl(page);

    const payload = {
      page,
      size: 20,
      ...SORT_VALUES,
      ...filters,
      active: checkIfValueExist(filters?.active),
      deleted: checkIfValueExist(filters?.deleted),
      currency: checkIfValueExist(filters?.currency),
      primaryModule: checkIfValueExist(filters?.primaryModule),
      paymentInterval: checkIfValueExist(filters?.paymentInterval),
      name: checkIfValueExist(filters?.name?.trim()),
    };
    getSubscriptionPlansListRequest({
      body: {
        ...payload,
      },
    });
  };

  const applyFilters = () => {
    handleChangePage(1);
  };

  const clearFilters = () => {
    setPageInUrl(1);

    setFilters({ ...defaultFilters });
    const defaultPayload = {
      page: 1,
      size: 20,
      ...SORT_VALUES,
      ...defaultFilters,
      active: checkIfValueExist(defaultFilters?.active),
      deleted: checkIfValueExist(defaultFilters?.deleted),
      currency: checkIfValueExist(defaultFilters?.currency),
      primaryModule: checkIfValueExist(defaultFilters?.primaryModule),
      paymentInterval: checkIfValueExist(defaultFilters?.paymentInterval),
      name: checkIfValueExist(defaultFilters?.name?.trim()),
    };
    getSubscriptionPlansListRequest({
      body: {
        ...defaultPayload,
      },
    });
  };

  const handleDeletePlan = (data) => {
    deleteUnDeleteRequest({
      url: `/subscription/admin/subscription-plan/${data?.id}`,
    });
  };

  useEffect(() => {
    handleChangePage(parseInt(router?.query?.page, 10) || 1);
  }, []);

  return (
    <>
      <div className="page-content">
        <Loader isActive={getSubscriptionPlansListLoading || deleteUnDeleteLoading} />

        <Container fluid>
          <Breadcrumbs title="Subscription Plans" breadcrumbItem="Subscription Plans List" />

          <Card>
            <CardBody>
              <div className="mt-4">
                <Row>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Active</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={ACTIVE_AND_DELETED_ARRAY?.find((e) => e?.value === filters?.active) || null}
                      placeholder="Select Status..."
                      onChange={(e) => setFilters({ ...filters, active: e?.value })}
                    />
                  </div>
                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Deleted</label>
                    <Select
                      options={ACTIVE_AND_DELETED_ARRAY}
                      value={ACTIVE_AND_DELETED_ARRAY?.find((e) => e?.value === filters?.deleted) || null}
                      placeholder="Select Status..."
                      onChange={(e) => setFilters({ ...filters, deleted: e?.value })}
                    />
                  </div>

                  <div className="col-lg-2 mb-4">
                    <label className="form-label">Currency</label>
                    <Select
                      options={currencyTypes}
                      value={currencyTypes?.find((e) => e?.value === filters?.currency) || null}
                      placeholder="Currency..."
                      onChange={(e) => setFilters({ ...filters, currency: e?.value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Primary module</label>
                    <Select
                      options={primaryModuleTypes}
                      value={primaryModuleTypes?.find((e) => e?.value === filters?.primaryModule) || null}
                      placeholder="Select Primary Module..."
                      onChange={(e) => setFilters({ ...filters, primaryModule: e?.value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Payment Intervals</label>
                    <Select
                      options={paymentIntervals}
                      value={paymentIntervals?.find((e) => e?.value === filters?.paymentInterval) || null}
                      placeholder="Select Payment Interval..."
                      onChange={(e) => setFilters({ ...filters, paymentInterval: e?.value })}
                    />
                  </div>
                  <div className="col-lg-3 mb-4">
                    <label className="form-label">Name</label>
                    <Input
                      type="text"
                      placeholder="Subscription Plan Name..."
                      value={filters.name}
                      onChange={(e) => setFilters({ ...filters, name: e?.target?.value })}
                    />
                  </div>
                </Row>
              </div>
              <div className="mt-4 pt-2">
                <a className="btn btn-primary me-4" onClick={applyFilters}>
                  Apply Filters
                </a>
                <a className="" onClick={clearFilters}>
                  Clear
                </a>
              </div>
            </CardBody>
          </Card>

          <Row className="mb-2 align-items-center">
            <Col sm="4">
              <div className="search-box me-2 mb-2 d-inline-block">
                <div className="position-relative font-bold">
                  {subscriptionPlansPagination?.totalCount} Subscription Plans Found
                </div>
              </div>
            </Col>
            <Col sm="8">
              <div className="text-sm-end">
                <Link
                  replace
                  className="btn btn-success btn-rounded waves-effect waves-light mb-2 me-2"
                  to={SMKLinks.ADD_SUBSCRIPTION_PLAN}>
                  <i className="mdi mdi-plus me-1" />
                  Add Subscription Plan
                </Link>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg="12">
              <div className="">
                <div className="table-responsive" style={{ minHeight: 300 }}>
                  <Table className="project-list-table   table-centered table-borderless">
                    <thead>
                      <tr className="table-warning">
                        <th scope="col" style={{ width: '60px' }}>
                          S.No.
                        </th>
                        <th scope="col">Name</th>
                        <th scope="col">Slug</th>
                        <th scope="col">Primary Module</th>
                        <th scope="col">Level</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Price</th>
                        <th scope="col">Payment Interval</th>
                        <th scope="col">Payment Duration</th>
                        <th scope="col">Payment Expiry Date</th>
                        <th scope="col">Analytics Allowed</th>
                        <th scope="col">Deleted</th>
                        <th scope="col">Active</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(subscriptionPlansList) ? (
                        subscriptionPlansList?.map((elem, index) => (
                          <tr>
                            <td>{printSerialNumbers(subscriptionPlansPagination)[index]}</td>
                            <td>
                              <div className="d-flex align-items-center">
                                {!elem?.deleted ? (
                                  <Link
                                    to={buildURL(SMKLinks.EDIT_SUBSCRIPTION_PLAN, {
                                      id: elem?.id,
                                    })}>
                                    {elem?.name}
                                  </Link>
                                ) : (
                                  elem?.name
                                )}

                                {!isEmpty(elem?.limits) && (
                                  <span className="ms-3">
                                    <Button
                                      type="button"
                                      className="widget-info-icon text-secondary fs-5 p-0"
                                      id={`popover-${index}`}
                                      style={{ background: 'white', border: '0px' }}>
                                      <i className="mdi mdi-information" />
                                    </Button>
                                    <UncontrolledPopover
                                      placement="right"
                                      target={`popover-${index}`}
                                      trigger={'focus'}>
                                      <PopoverHeader>Limits </PopoverHeader>
                                      <PopoverBody>
                                        {[
                                          {
                                            label: 'Whiteboards',
                                            value: elem?.limits?.whiteboards,
                                          },
                                          {
                                            label: 'Whiteboard Pages',
                                            value: elem?.limits?.whiteboardPages,
                                          },
                                          {
                                            label: 'Whiteboard Invitees',
                                            value: elem?.limits?.whiteboardInvitees,
                                          },
                                          {
                                            label: 'Worksheet Generate Daily',
                                            value: elem?.limits?.worksheetGenerateDaily,
                                          },
                                          {
                                            label: 'Worksheet Download Daily',
                                            value: elem?.limits?.worksheetDownloadDaily,
                                          },
                                          {
                                            label: 'Worksheet Download Monthly',
                                            value: elem?.limits?.worksheetDownloadMonthly,
                                          },
                                          { label: 'Claim Schools', value: elem?.limits?.claimSchools },
                                          { label: 'Memories', value: elem?.limits?.memories },
                                          {
                                            label: 'Memory Images',
                                            value: elem?.limits?.memoriesImages,
                                          },
                                          { label: 'Caregivers', value: elem?.limits?.caregivers },
                                        ]?.map((elem) => (
                                          <div className="mb-2">
                                            {elem?.label} :{' '}
                                            <span>
                                              <strong>{elem?.value}</strong>
                                            </span>
                                          </div>
                                        ))}
                                      </PopoverBody>
                                    </UncontrolledPopover>
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>{elem?.slug}</td>
                            <td>{titleCaseIfExists(invert(SUBSCRIPTION_MODULE_TYPE)[elem?.primaryModule])}</td>
                            <td>{elem?.level}</td>
                            <td>{elem?.currency}</td>
                            <td>{elem?.price}</td>
                            <td>{titleCaseIfExists(elem?.paymentInterval)}</td>
                            <td>
                              {elem?.duration} {elem?.paymentInterval === 'monthly' ? 'Months' : 'Years'}
                            </td>
                            <td>{moment(elem?.expiryDate)?.format('DD-MM-YYYY')}</td>
                            <td>
                              <Badge className={elem?.limits?.analyticsAllowed ? 'bg-success' : 'bg-danger'}>
                                {elem?.limits?.analyticsAllowed ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={elem?.deleted ? 'bg-success' : 'bg-danger'}>
                                {elem?.deleted ? 'Yes' : 'No'}
                              </Badge>
                            </td>
                            <td>
                              <Badge className={elem?.active ? 'bg-success' : 'bg-danger'}>
                                {elem?.active ? 'Active' : 'Disabled'}
                              </Badge>
                            </td>

                            <td className="text-center">
                              {!elem?.deleted && (
                                <UncontrolledDropdown>
                                  <DropdownToggle className="card-drop" tag="a">
                                    <i className="mdi mdi-dots-vertical font-size-18" />
                                  </DropdownToggle>
                                  <DropdownMenu className="dropdown-menu-end">
                                    {elem?.active && (
                                      <DropdownItem className="border-bottom">
                                        <Link
                                          to={buildURL(SMKLinks.EDIT_SUBSCRIPTION_PLAN, {
                                            id: elem?.id,
                                          })}>
                                          <div>
                                            <i className="mdi mdi-pencil font-size-16 text-success me-1" />{' '}
                                            <span style={{ color: '#212529' }}>Edit</span>
                                          </div>
                                        </Link>
                                      </DropdownItem>
                                    )}

                                    <DropdownItem onClick={() => handleDeletePlan(elem)}>
                                      <div>
                                        <i className="mdi mdi-trash-can-outline font-size-16 text-danger me-1" />{' '}
                                        <span style={{ color: '#212529' }}>Delete</span>
                                      </div>
                                    </DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <TableError />
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Col>
          </Row>

          {!isEmpty(subscriptionPlansList) && (
            <Paginate pageInfo={subscriptionPlansPagination} onChangePage={handleChangePage} />
          )}
        </Container>
      </div>
    </>
  );
};

export default SubscriptionPlans;
